<!-- [class.is-anon]="userName === 'anoz  nymous'" -->
<div class="c-avatar__inner h-object-fit"
  [class.is-empty]="color"
  [class.is-brand]="isBrand"
  [ngStyle]="{'background-color': color}"
  aria-label="user's profile picture" role="profile-picture">
  <img *ngIf="avatar" alt="user's profile picture" [src]="avatar">

  <span *ngIf="!avatar && userName !== 'anonymous'">{{letters}}</span>
  <span *ngIf="userName === 'anonymous'">A</span>
  <!-- <svg *ngIf="userName === 'anonymous'" class="icon icon-anonymous"><use xlink:href="#icon-anonymous"></use></svg> -->
</div>
