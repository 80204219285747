<div class="c-comments__webview-wrap">
  <app-quest-comments
    *ngIf="loaded"
    [questUserId]="userId"
    [questId]="questId"
    [webviewMode]="webviewMode"
    (navigateToEmit)="navigateTo($event)"
    class="c-quest-tabs__tab">
  </app-quest-comments>
</div>
