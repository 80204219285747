<button type="button" class="c-leaderboard-score-editor__trig" (click)="showForm()" *ngIf="!visibleForm && member.unit" title="Edit">
  <svg class="icon icon-pencil"><use xlink:href="#icon-pencil"></use></svg>
</button>
<form class="c-leaderboard-score-editor__form" [class.is-active]="visibleForm" [formGroup]="scoreForm" (ngSubmit)="onSubmit()">
  <input class="c-leaderboard-score-editor__input"
    formControlName="score"
    placeholder="Score"
    type="text">
  <div class="c-leaderboard-score-editor__tools">
    <button type="button" class="c-leaderboard-score-editor__cancel" (click)="hideForm()">
      <svg class="icon icon-cancel"><use xlink:href="#icon-cancel"></use></svg>
    </button>
    <button type="submit" class="c-leaderboard-score-editor__submit" [disabled]="progress">
      <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
    </button>
    <button type="button" class="c-leaderboard-score-editor__dots" (click)="toggleDropdown()" title="Change status">
      <svg class="icon icon-more"><use xlink:href="#icon-more"></use></svg>
    </button>
    <div class="c-leaderboard-score-editor__dropdown" [class.is-active]="visibleDropdown">
      <ul class="c-leaderboard-score-editor__dropdown__list">
        <li class="c-leaderboard-score-editor__dropdown__item"
          [class.is-active]="statusData.currentStatus?.value === item?.value"
          *ngFor="let item of statusData.list"
          (click)="selectStatus(item.value)">{{item.viewText}}</li>
      </ul>
    </div>
  </div>
</form>
