<div class="terms--top-mod"><h1 class="terms--top-mod-title">Terms Of Service</h1></div>

<div class="terms--bottom">
  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <!--<h3 class="terms&#45;&#45;mod-title">Terms Of Service</h3>-->
      <p>
        Thank you for using DIEMlife!
      </p>
      <p>
        Welcome to DIEMlife. By accessing and using DIEMlife’s website, resources, content, services, products, and
        tools (collectively, “Services”), you agree to accept and be bound by these legally binding terms (“Terms”),
        so please read these Terms carefully. You also agree to comply with our Privacy Policy and Community
        Guidelines.
      </p>
      <p>
        We may amend these Terms at any time by posting the most current version on our website. If a revision is
        material, we will provide you with advanced notice by posting notice on the Policy Update page of our website
        or by sending you an email. By continuing to access or use the Services, you agree to be bound by the revised
        Terms.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Using DIEMlife</h3>
      <p>
        You agree to use the Services only for the purposes intended and permitted by these Terms and in compliance
        with any and all applicable laws, regulations and generally accepted online practices or guidelines.
      </p>
      <p>
        You can browse DIEMlife without signing up for an account. But to access and use some of our Services, you
        will need to register by providing certain information about yourself and set a password. You agree that any
        information you provide will be accurate, complete and current.
      </p>
      <p>
        You are responsible for all activity on your account and keeping your password confidential. If you find out
        someone has used your account without your permission, you should report it immediately to
        diemlife@diemlife.com.
      </p>
      <p>
        To register for an account, you must be 18 years old [or old enough to form a binding contract in your
        jurisdiction.
      </p>
      <p>
        You must access the Services only through the means we provide and not interfere, harm or damage the functions
        of our Services or infrastructure in any manner whatsoever. You will not attempt to copy, duplicate,
        reproduce, trade, or resell our Services.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Your Content</h3>
      <p>
        Any material that you post on DIEMlife or otherwise make available using the Services is “Your Content”. You
        retain all rights in and are solely responsible for all of Your Content posted. We do not make any claim to
        Your Content, but you grant us and our users a worldwide, non-exclusive, perpetual, irrevocable, royalty-free,
        transferable, sublicensable right and license to access, use, store, reproduce, save, transmit, adapt, modify,
        perform, display, distribute, translate, reformat, publish and create derivative works, in whole or in part,
        for the purpose of operating, developing and providing the Services.
      </p>
      <p>
        You represent and warrant that you have all the authority, rights and power to grant the foregoing license and
        that Your Content does not infringe upon, violate or conflict with any third party’s proprietary rights (and
        you have any and all necessary permissions from the legally rightful owner to post Your Content and for
        DIEMlife and its Services to use Your Content in accordance with the license granted by you).
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Third-Party Sites, Links and Other Material</h3>
      <p>
        DIEMlife and the Services may contain links to third-party websites, advertisers, users, services or other
        content that are not operated or controlled by DIEMlife. We do not endorse and are not responsible for any
        such third-party sites, links or other material. When you access any such third-party websites, services or
        content, you do so at your own risk and agree that DIEMlife has no liability resulting from your access to or
        use of such websites, services or content.
      </p>
      <p>
        DIEMlife partners with <a *ngIf="!webViewMode" href="https://stripe.com/us/legal" target="_blank">Stripe</a><a *ngIf="webViewMode" href="javascript:void(0)" (click)="goToPage('https://stripe.com/us/legal', 'external')">Stripe</a> for payment processing
        services. When you access or use our
        partners’ services, you agree to their <a *ngIf="!webViewMode" href="https://stripe.com/us/connect-account/legal" target="_blank">Stripe
        Connected Accounts Agreement</a><a *ngIf="webViewMode" href="javascript:void(0)" (click)="goToPage('https://stripe.com/us/connect-account/legal', 'external')">Stripe
          Connected Accounts Agreement</a>, which includes the <a *ngIf="!webViewMode" href="https://stripe.com/us/connect-account/legal"
                                                                target="_blank">Stripe Terms of
        Service</a><a *ngIf="webViewMode" href="javascript:void(0)" (click)="goToPage('https://stripe.com/us/connect-account/legal', 'external')">Stripe Terms of
          Service</a> (collectively, the "Stripe Services Agreement"). By using Services, you agree to provide Stripe
        and
        DIEMlife, as applicable, accurate and complete information, and you authorize us to share such information
        only as necessary to enable the payment processing services provided by Stripe.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Our Intellectual Property</h3>
      <p>
        Content and materials available on and created by DIEMlife, including but not limited to the Services, text,
        graphics, website name, code, images and logos are the intellectual property of DIEMlife, Inc., and are
        legally protected by applicable intellectual property and other rights and laws.
      </p>
      <p>
        Subject to these Terms and our other policies, we grant you a limited, non-exclusive, non-transferable,
        non-sublicensable and revocable license to use the Services for personal use only. You agree not to modify,
        translate or otherwise create derivative works of the Services. If you want to use, store, modify, distribute,
        display or reproduce any of the Services for a commercial purpose, you must obtain advanced written permission
        from DIEMlife.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Copyright Policy</h3>
      <p>
        We have adopted and comply with the Digital Millennium Copyright Act (DMCA) and other applicable copyright
        laws. We will respond to any reports of alleged copyright infringement. When a DMCA notice of alleged
        infringement is filed, we reserve the right to take whatever action we deem appropriate, including removing or
        disabling the reported content and terminating the alleged infringer’s account.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Indemnification</h3>
      <p>
        You agree to defend, indemnify and hold harmless DIEMlife, Inc. and its parent company and affiliates, and
        their respective directors, officers, managers, employees, donors, agents, and licensors, from and against any
        and all liabilities, claims, suits, losses, expenses, damages and costs, including without limitation
        reasonable attorneys' and accounting fees, resulting in any way from your access to or use of the Services,
        Your Content, or breach of any of these Terms. We reserve the right to assume the exclusive defense and
        control of any claim or matter subject to this indemnification clause, in which case, you agree to cooperate
        and assist as reasonably requested by us.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Warranty Disclaimers</h3>
      <p>
        Our Services are provided "as is" and "as available" and without warranty of any kind, express or implied. You
        agree to use our Services solely at your own risk.
      </p>
      <p>
        DIEMLIFE SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, NON-INFRINGEMENT,
        AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY OR ARISING OUT OF ANY COURSE OF DEALING,
        COURSE OF PERFORMANCE, OR USAGE OF TRADE. NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM
        DIEMLIFE SHALL CREATE ANY WARRANTY.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Limitation of Liability</h3>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL DIEMLIFE, ITS DIRECTORS, OFFICERS, EMPLOYEES,
        PARTNERS OR CONTENT PROVIDERS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY LOSS
        OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO DAMGES, WHETHER INCURRED DIRECTLY OR INDIRECTLY,
        (I) RESULTING FROM YOUR ACCESS TO, USE OF, OR INABILITY TO ACCES OR USE THE SERVICES; (II) FOR ANY LOST OF
        PROFITS OR REVENUES, DATA LOSS OR CORRUPTION, OR COST OF SUBSTITUTE SERVICES, OR (III) FOR ANY CONDUCT OR
        CONTENT OF ANY THIRD PARTY ON THE SERVICES. IN NO EVENT SHALL DIEMLIFE’S AGGREGATE LIABILITY FOR ALL CLAIMS
        RELATING TO THE SERVICES EXCEED ONE HUNDRED U.S. DOLLARS ($100.00)
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Termination</h3>
      <p>
        DIEMlife may, at our sole discretion, suspend or terminate your right to access or use the Services at any
        time without notice for good reason, including if you violate these Terms or any of our policies or
        guidelines, misuse or abuse the Services, if your access or use of the Services violate any laws. We may also
        suspend or terminate your access or use for any reason with appropriate notice. Any suspected illegal,
        fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to
        appropriate law enforcement authorities.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Governing Law</h3>
      <p>
        If you have a dispute with DIEMlife, we encourage to first contact us and try to resolve the dispute. In the
        event that we are unable to resolve the dispute with you, and you seek legal action, these Terms and any other
        policies or guidelines incorporated by reference, will be governed by and construed in accordance with the
        laws of the State of New York and the United States, without giving effect to any principles of conflicts of
        law, and without application of the United Nations Convention of Controls for International Sale of Goods.
      </p>
      <p>
        You agree that DIEMlife and the Services are deemed a passive website that does not give rise to jurisdiction
        over DIEMlife or its parents, affiliates, assigns, officers, employees, agents, directors, officers, or
        shareholders, either specific or general, in any jurisdiction other than the State of New York. You agree that
        any action at law or in equity arising out of or relating to these Terms, shall be brought in the federal or
        state located in New York County in the State of New York, and you hereby agree and submit to the personal
        jurisdiction of these courts, and waive any jurisdictional, venue, or inconvenient forum objections to such
        courts. You hereby irrevocably waive any right you may have to trial by jury in any dispute, action, or
        proceeding.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Other Terms</h3>
      <p>
        Any notices may be provided to you by email or by posting on our website.
      </p>
      <p>
        DIEMlife may freely assign, transfer or delegate these Terms and any other rights and obligations at any time
        without restriction. You may not assign these Terms without DIEMlife’s prior written consent.
      </p>
      <p>
        These Terms, together with any of our other policies and guidelines, constitute the entire agreement between
        you and DIEMlife in regard to the Services. If any provision of these Terms is found to be invalid, that
        provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these
        Terms will remain in full force and effect. The failure of DIEMlife to exercise any right or provision under
        these Terms in any way shall not constitute a waiver of such right or any other rights.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Contact Information</h3>
      <p>If you have any questions, comments or feedback about these Terms, please contact us at:</p>
      <dl>
        <dt>DIEMlife, Inc.</dt>
        <dt>85 East 10th St Ste. M</dt>
        <dt>New York, NY 10003</dt>
        <dt>United States</dt>
        <dt>diemlife@diemlife.com</dt>
      </dl>
    </div>
  </div>
</div>