import {LocalStorageService} from '../../../../_services/localstorage.service';
import * as UserFriendsActions from './profile-friends.actions';
import {User} from 'diemlife-commons/dist/diemlife-commons-model';

export interface State extends User {
  profilePictureURL?: string;
  userFullName?: string;
  status?: string;
  memberStatus?: string[];
  percentage?: number;
  anonymous?: boolean;
  amountBacked?: number[];
  amountBackedSum?: number;
}

const initialState: State[] = [];

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateUserFriends';

export function UserFriendsReducer(
  state = localStorageService.getInitialState(LOCAL_STATE_KEY, initialState),
  action: UserFriendsActions.UserFriendsActions
) {
  switch (action.type) {
    case (UserFriendsActions.GET_USER_FRIENDS):
      return localStorageService.set(LOCAL_STATE_KEY, [
        ...action.payload
      ]);
    case (UserFriendsActions.CLEAR_USER_FRIENDS):
      localStorageService.clear(LOCAL_STATE_KEY);
      return [
        ...initialState
      ];
    default:
      return state;
  }
}
