import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  HostBinding
} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {WindowRef} from '../../../_global/window-ref.module';
import {PaymentsService} from '../payments/payments.service';
import {AccountService} from '../../pages/account/account.service';
import {ModalService} from '../modal/modal.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../_services/snotify-config.service';
import * as PaymentsActions from '../../main/payments/store/payments.actions';
import * as fromPayments from '../../main/payments/store/payments.reducer';
import * as fromAuth from '../../main/auth/store/auth.reducer';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import {BankAccount, CreditCard} from '../payments/store/payments.reducer';
import {stripeToken} from '../../../app.config';
import {StripeErrorCodesEnum} from 'diemlife-commons/dist/diemlife-commons-model';
import {AuthService} from '../auth/auth.service';
import {CREDIT_CARD_OPTIONS, CreditCardOptions} from '../payments/credit-card-options.model';
import { AppState } from 'src/app/_store/app.reducers';

interface SubmittedPayment {
  paymentName: string;
  paymentData: any;
}

export interface StructuredPayment {
  paymentMode: string;
  lastFour: string;
  token: string;
  save: boolean;
}

@Component({
  selector: 'app-pay-dialog',
  templateUrl: './pay-dialog.component.html',
  styleUrls: ['./pay-dialog.component.styl']
})
export class PayDialogComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.h-pulse-animation') isHighlighted = false;
  @Output() selectedPayment: any = new EventEmitter<StructuredPayment>();
  @ViewChild('cardInfo', {static: false}) cardInfo: ElementRef;
  paymentsState: Observable<fromPayments.State>;
  authState: Observable<fromAuth.State>;
  profileState: Observable<fromProfile.State>;
  authStateSubscription: Subscription;
  stripe: any;
  elements: any;
  elemntsOptions = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:800'
      }
    ]
  };
  card: any;
  cardInFocus = false;
  cardOptions: CreditCardOptions = CREDIT_CARD_OPTIONS;
  cardHandler = this.onChange.bind(this);
  cardValidation: { error: string | null, complete: boolean } = {
    error: null,
    complete: false
  };
  error: string;
  bankAccount: any = null;
  creditCardList: CreditCard[] = [];
  collapseState = 'creditCard';
  saveNewCard = true;
  payment: any = {
    selected: null,
    index: 0
  };
  isloading = {
    payment: 'creditCard',
    index: null
  };
  isloadingSubmit = false;
  isContentLoaded = false;
  submittedPayment: SubmittedPayment = {
    paymentName: null,
    paymentData: null
  };
  isAnonymous: boolean;

  // openedDialog: string;
  constructor(
    private cd: ChangeDetectorRef,
    private winRef: WindowRef,
    private paymentsService: PaymentsService,
    private accountService: AccountService,
    private modalService: ModalService,
    private store: Store<AppState>,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private authService: AuthService
  ) {
    this.paymentsState = this.store.select('paymentsInfo');
    this.profileState = this.store.select('userInfo');
    this.authState = this.store.select('auth');

    const Stripe = winRef.nativeWindow.Stripe;
    this.stripe = Stripe(stripeToken); // use your test publishable key
    this.elements = this.stripe.elements(this.elemntsOptions);
  }

  ngAfterViewInit() {
    this.card = this.elements.create('card', this.cardOptions);
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
    this.card.addEventListener('focus', () => {
      this.cardInFocus = true;
      this.selectPayment('newCreditCard', 0);
    });
    this.card.addEventListener('blur', () => {
      this.cardInFocus = false;
    });
    this.loadPaymentsInfo();
    this.authStateSubscription = this.authState.subscribe((state: fromAuth.State) => {
      this.isAnonymous = !state.authenticated;
      this.profileState.pipe(take(1)).subscribe((fromProfileState: fromProfile.State) => {
        this.saveNewCard = !this.isAnonymous && Boolean(fromProfileState.stripeEntityId);
      });
    });
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.removeEventListener('focus', this.cardHandler);
      this.card.removeEventListener('blur', this.cardHandler);
      this.card.unmount();
    }
    this.modalService.remove('pay-modal');

    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  loadPaymentsInfo() {
    // tslint:disable-next-line:max-line-length
    this.accountService.getFullyPaymentsInfo().subscribe(([[bankInfoPayments, bankInfoPayouts], creditCardsInfo]: [BankAccount[][], CreditCard[]]): void => {
      this.bankAccount = bankInfoPayouts;
      this.creditCardList = creditCardsInfo;
      this.store.dispatch(new PaymentsActions.SetPaymentsInfo({
        bankAccounts: this.bankAccount,
        creditCards: this.creditCardList,
        hasBankAccountForPayments: !!bankInfoPayments.length,
        hasBankAccountForPayouts: !!bankInfoPayouts.length
      }));
      this.isContentLoaded = true;
      this.autoFillChoice();
    }, (err) => {
      // * if nothing to auto select
      this.highlightField();
    });
  }
  autoFillChoice() {
    if (this.creditCardList.length > 0) {
      this.selectPayment('creditCard', this.creditCardList.length - 1);
      this.onSubmit();
    } else {
      this.paymentsState.pipe(take(1)).subscribe((state: fromPayments.State) => {
        if (state.hasBankAccountForPayments) {
          this.selectPayment('bankAccount', 0);
          this.onSubmit();
        } else {
          this.highlightField();
        }
      });
    }
  }
  // * if nothing to auto select
  highlightField() {
    this.isHighlighted = true;
  }

  removeCreditCard(index: number) {
    this.isloading.payment = 'creditCard';
    this.isloading.index = index;

    this.paymentsState.pipe(take(1)).subscribe((paymentsState: fromPayments.State) => {
      const lastFourDigits: string = paymentsState.creditCards[index].lastFourDigits;

      this.paymentsService.removeCreditCard(lastFourDigits).subscribe(res => {
        this.store.dispatch(new PaymentsActions.RemoveCreditCard(index));
        this.creditCardList.splice(index, 1);
        this.isloading.index = null;
      });
    });
  }

  removeBankAccount(index: number) {
    this.isloading.payment = 'bankAccount';
    this.isloading.index = index;

    this.paymentsService.removeBankAccount().subscribe(res => {
      this.store.dispatch(new PaymentsActions.RemoveBankAccount(index));

      this.isloading.index = null;
    });
  }

  onChange({error, complete}) {
    this.cardValidation.complete = complete;
    if (error) {
      this.cardValidation.error = error.message;
    } else {
      this.cardValidation.error = null;
    }
    this.cd.detectChanges();
  }

  toggleCollapse(newState: string) {
    this.collapseState = newState;
  }

  selectPayment(payment: string, index: number) {
    this.payment.selected = payment;
    this.payment.index = index;
  }

  openModal(id: string) {
    setTimeout(() => {
      this.modalService.open(id);
    });
  }

  closeModal(id: string) {
    setTimeout(() => {
      this.modalService.close('pay-modal');
    });
  }

  onCloseModal() {
    this.card.clear();
  }

  onSubmit() {
    switch (this.payment.selected) {
      case 'creditCard':
        this.onSubmitCreditCard(this.payment.selected);
        break;
      case 'newCreditCard':
        this.onSubmitNewCreditCard(this.payment.selected);
        break;
      case 'bankAccount':
        this.onSubmitBankAccount(this.payment.selected);
        break;
      default:
        this.snotifyService.warning(
          'Please choose a payment method!',
          null,
          this.snotifyConfigService.getConfig()
        );
    }
  }

  async onSubmitNewCreditCard(paymentName) {
    this.isloadingSubmit = true;
    const {token, error} = await this.stripe.createToken(this.card);

    if (error) {
      this.cardValidation.error = error.message;
      this.isloadingSubmit = false;
      this.snotifyService.error(
        this.cardValidation.error,
        null,
        this.snotifyConfigService.getConfig()
      );
    } else {
      const newCardInfo: fromPayments.CreditCard = {
        cardType: token.card.brand,
        expiryMonth: token.card.exp_month,
        expiryYear: token.card.exp_year,
        lastFourDigits: token.card.last4,
        save: this.saveNewCard,
        token: token.id
      };
      if (this.saveNewCard) {
        let alreadyExists = false;
        this.creditCardList.forEach((card: fromPayments.CreditCard) => {
          if (card.lastFourDigits === token.card.last4) {
            alreadyExists = true;
            return false;
          }
        });
        if (alreadyExists) {
          this.snotifyService.error(
            'This card already exists please add another',
            null,
            this.snotifyConfigService.getConfig()
          );
          this.card.clear();
          this.isloadingSubmit = false;
          return false;
        }
        this.paymentsService.saveCreditCard(token.id).subscribe((res) => {
          this.store.dispatch(new PaymentsActions.AddCreditCard(newCardInfo));
          this.submittedPayment = {
            paymentName: paymentName,
            paymentData: newCardInfo
          };
          this.creditCardList.push(newCardInfo);
          // const newStruncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo(this.submittedPayment);
          // this.selectedPayment.emit(newStruncturedPayment);
          setTimeout(() => {
            this.selectPayment('creditCard', this.creditCardList.length - 1);
            this.onSubmitCreditCard('creditCard');
            this.isloadingSubmit = false;
            this.isHighlighted = false;
          });
        }, err => {
          let isError = false;
          let errorValue = '';
          for (const errorCode in StripeErrorCodesEnum) {
            if (err.error && err.error.description.search(errorCode) !== -1) {
              isError = true;
              errorValue = StripeErrorCodesEnum[errorCode];
            }
          }
          if (isError) {
            this.snotifyService.error('There was an issue processing your request due to: ' + errorValue,
              null, this.snotifyConfigService.getConfig());
          } else {
            this.snotifyService.error('There was an issue processing your request. Please try again.',
              null, this.snotifyConfigService.getConfig());
          }
          this.closeModal('pay-modal');
          this.isloadingSubmit = false;
        });
      } else {
        this.submittedPayment = {
          paymentName: paymentName,
          paymentData: newCardInfo
        };
        const struncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo(this.submittedPayment);
        this.selectedPayment.emit(struncturedPayment);
        this.isloadingSubmit = false;
        this.isHighlighted = false;
        this.closeModal('pay-modal');
      }
    }
  }

  getStructuredEmittedPaymentInfo(submittedPayment: SubmittedPayment): StructuredPayment {
    let payload: StructuredPayment = {
      paymentMode: '',
      lastFour: '',
      token: '',
      save: true
    };
    switch (submittedPayment.paymentName) {
      case 'newCreditCard':
        payload = {
          paymentMode: 'creditCardToken',
          lastFour: submittedPayment.paymentData.lastFourDigits,
          token: submittedPayment.paymentData.token,
          save: submittedPayment.paymentData.save
        };
        break;
      case 'creditCard':
        payload = {
          paymentMode: 'creditCard',
          lastFour: submittedPayment.paymentData.lastFourDigits,
          token: null,
          save: null
        };
        break;
      case 'bankAccount':
        payload = {
          paymentMode: 'bankAccount',
          lastFour: null,
          token: null,
          save: null
        };
        break;
    }
    return payload;
  }

  onSubmitCreditCard(paymentName) {
    const selectedCard = this.creditCardList[this.payment.index];
    this.submittedPayment = {
      paymentName: paymentName,
      paymentData: selectedCard
    };
    const struncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo(this.submittedPayment);
    this.selectedPayment.emit(struncturedPayment);
    this.isHighlighted = false;
    this.closeModal('pay-modal');
  }

  onSubmitBankAccount(paymentName) {
    const selectedBank = this.bankAccount[this.payment.index];
    this.submittedPayment = {
      paymentName: paymentName,
      paymentData: selectedBank
    };
    const struncturedPayment: StructuredPayment = this.getStructuredEmittedPaymentInfo(this.submittedPayment);
    this.selectedPayment.emit(struncturedPayment);
    this.isHighlighted = false;
    this.closeModal('pay-modal');
  }

}
