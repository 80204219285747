<div class="c-quest-profile__inner" *ngIf="isCreatorsQuest && isLoaded">
    <app-profile-avatar
        [routerLink]="creator.userName ? ['/profile', creator.userName] : null"
        class="c-quest-profile__img c-avatar"
        [avatar]="creator.profilePictureURL || creator.avatarUrl"
        [firstName]="creator.firstName"
        [isBrand]="creator.isUserBrand === 'N' ? false : true"
        [lastName]="creator.lastName"
        [userName]="creator.userName"
        tabindex="-1">
    </app-profile-avatar>
    <div [routerLink]="creator.userName ? ['/profile', creator.userName] : null" class="c-quest-profile__ttl" tabindex="-1"><span>{{ creator.firstName }}</span><span>{{ creator.lastName }}</span></div>
    <!-- <div class="c-quest-profile__subTtl" (click)="showProfile('creator', creator.id, creator.userName)">
        <svg class="icon icon-freinds-group"><use xlink:href="#icon-freinds-group"></use></svg>
        <span>{{ creator.name }}</span>
    </div> -->
    <!-- <span *ngIf="quest.dateModified" class="c-quest-profile__subTtl">Created {{ quest.dateCreated | timeAgo }}</span> -->
</div>
<div class="c-quest-profile__inner" *ngIf="!isCreatorsQuest && isLoaded">
    <app-profile-avatar
        class="c-quest-profile__img c-avatar"
        [routerLink]="['/profile', doerInfo.userName]"
        [avatar]="doerInfo.profilePictureURL || doerInfo.avatarUrl"
        [firstName]="doerInfo.firstName"
        [isBrand]="doerInfo.isUserBrand === 'N' ? false : true"
        [lastName]="doerInfo.lastName"
        [userName]="doerInfo.userName">
    </app-profile-avatar>
    <div class="c-quest-profile__ttl" tabindex="-1" [routerLink]="doerInfo.userName ? ['/profile', doerInfo.userName] : null"><span>{{ doerInfo.firstName }}</span><span>{{ doerInfo.lastName }}</span></div>
    <div class="c-quest-profile__subTtl" [routerLink]="creator.userName ? ['/profile', creator.userName] : null" tabindex="-1">
        <svg class="icon icon-freinds-group"><use xlink:href="#icon-freinds-group"></use></svg>
        <span>{{ creator.name }}</span>
    </div>
</div>
