import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {Transaction} from 'diemlife-commons/dist/diemlife-commons-model';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-profile-transaction',
  templateUrl: './profile-transaction.component.html',
  styleUrls: ['./profile-transaction.component.styl']
})
export class ProfileTransactionComponent implements OnInit, OnChanges {
  @Output() emitGoToQuestlink: EventEmitter<{questId: number; userId: number}> = new EventEmitter<{questId: number; userId: number}>();
  @Output() emitGoToProfileLink: EventEmitter<{userId: number}> = new EventEmitter<{userId: number}>();
  @Input() data: Transaction;
  currentUserId: number;
  userInfoState: Observable<fromProfile.State>;
  path: string;
  meta: { failed: any; free: any; } = { failed: null, free: null };
  currencyHasFees: boolean;
  hideFees: boolean;
  fee: number;
  constructor(
    private store: Store<AppState>,
    // private modalService: ModalService
  ) {
    this.userInfoState = this.store.select('userInfo');
  }

  ngOnInit() {
    this.meta['failed'] = this.data.status === 'failed';
    this.meta['free'] = this.data.amount === 0 && this.data.from.isMyself;
    this.currencyHasFees = this.data.type !== 'PAYOUT' && this.data.outgoing && !this.meta.free;
    this.path = this.data.incoming ? 'from' : 'to';
    this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.currentUserId = state.id;
      this.hideFees = this.currentUserId !== this.data.to.userId && this.data.absorbFees;
    });
  }
  ngOnChanges() {
    if (typeof this.data.fee === 'number') {
      this.fee = this.data.fee;
    } else {
      this.fee = parseInt(this.data.fee, 10);
    }
  }
  goToQuestLink() {
    this.emitGoToQuestlink.emit({ questId: this.data.questId, userId: this.data.to.userId });
  }
  goToProfileLink() {
    if (this.data.to.userId > 0) {
      this.emitGoToProfileLink.emit({ userId: this.data.to.userId });
    }
  }
  getPaymentDirection() {
    if (this.data.type === 'PAYOUT' || (this.data.type === 'FUNDRAISING' && this.data.to.isMyself) || this.meta.free) {
      return 'IN';
    } else if (this.data.incoming && !(this.data.type === 'FUNDRAISING' && this.data.to.isMyself)) {
      return 'IN';
    } else if (this.data.type !== 'PAYOUT' && this.data.outgoing && !this.meta.free) {
      return 'OUT';
    }
  }

}
