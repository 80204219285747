import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromApp from './../_store/app.reducers';
import * as fromAuth from './../components/main/auth/store/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class ReaquestHeadersService {
  authState: Observable<fromAuth.State>;
  stateSubscriber: Subscription;
  headers: HttpHeaders;

  constructor(private store: Store<fromApp.AppState>) {
    this.authState = this.store.select('auth');
  }

  getHeaders() {
    this.stateSubscriber = this.authState.subscribe(
      (authState: fromAuth.State) => {
        if (authState.authenticated) {
          this.headers = new HttpHeaders({'Authorization': 'Bearer ' + authState.token});
        } else {
          this.headers = new HttpHeaders();
        }
      }
    );
    this.stateSubscriber.unsubscribe();
    return this.headers;
  }
}
