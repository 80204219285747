import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { PaymentsService } from '../../../main/payments/payments.service';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SnotifyService } from 'ng-snotify';
import { SnotifyConfigService } from '../../../../_services/snotify-config.service';
import * as fromDonate from '../store/donate.reducer';
import * as donateActions from '../store/donate.actions';
import * as Constants from '../../../../app.constants';
import { AppState } from 'src/app/_store/app.reducers';
import { UdpCurrencyMaskV2Pipe } from '../../../../_pipes/udp-currency-mask-v2.pipe';
import { QuestTeam, FundraisingLinkType } from 'diemlife-commons/dist/diemlife-commons-model';
import {UiSelectTeamService} from 'src/app/modules/shared-module/elements/ui-select-team.service';

@Component({
  selector: 'app-donate-amount',
  templateUrl: './donate-amount.component.html',
  styleUrls: ['./donate-amount.component.styl']
})
export class DonateAmountComponent implements OnInit, OnDestroy {
  @Output() teamChanged = new EventEmitter();
  donateState: Observable<fromDonate.State>;
  donateFormSubscription: Subscription;
  messages = Constants.VALIDATION_MESSAGES;
  donateFormSubmitted = false;
  prices: number[] = [25, 50, 75, 100, 250, 500];
  enteredAmount: number = null;
  donateForm: FormGroup;
  breakdown: any;
  componentReady = false;
  teamsList: QuestTeam[];
  questId: number;
  userId: number;
  selectedTeamCreatorId: number;
  fundraisingLink: FundraisingLinkType;
  touchedTeamSelect = false;
  initialTeamId: number;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private paymentsSevice: PaymentsService,
    private uiSelectTeamService: UiSelectTeamService,
    private store: Store<AppState>,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    public currencyMask: UdpCurrencyMaskV2Pipe) {
    this.donateState = this.store.select('donate');
    this.donateForm = new FormGroup({
      'amount': new FormControl(0, [
        Validators.required,
        (c: AbstractControl) => c.value === undefined || c.value === null || (c.value || '').trim() === '$' ? null
          : this.currencyMask.formatString(c.value) > 999999 ? { max: true }
          : this.currencyMask.formatString(c.value) < 1 ? { min: true } : null
        ]),
      'message': new FormControl('')
    });
  }

  ngOnInit() {
    this.route.parent.params.pipe(take(1)).subscribe( params => {
      this.questId = params.questId;
      this.userId = params.userId;

      this.loadQuestTeams(this.questId);
    });
    this.donateState.pipe(take(1)).subscribe((res: fromDonate.State) => {
      if (res.amount > 0) {
        this.enteredAmount = res.amount;
      }
      this.donateForm.patchValue({
        amount: res.amount,
        message: res.message
      });
    });
    this.donateFormSubscription = this.donateForm.valueChanges.subscribe(val => {
      this.donateState.pipe(take(1)).subscribe((res: fromDonate.State) => {
        const steps: fromDonate.Steps = {
          ...res.steps,
          amount: this.donateForm.valid,
          confirm: this.donateForm.valid ? res.steps.confirm : false
        };
        setTimeout(() => {
          this.store.dispatch(new donateActions.SetNavigationStep(steps));
        });
      });
      const maskedVal = this.currencyMask.transform(val.amount);
      this.donateForm.patchValue({amount: maskedVal}, {emitEvent: false});
    });
  }
  ngOnDestroy() {
    this.donateFormSubscription.unsubscribe();
  }
  setPrice(price) {
    this.enteredAmount = price;
    const maskedVal = this.currencyMask.transform(price);
    this.donateForm.patchValue({amount: maskedVal}, {emitEvent: false});
  }
  onSubmitDonateForm() {
    this.donateFormSubmitted = true;

    if (this.donateForm.invalid) {
      this.snotifyService.error('Please enter an amount to continue!', null, this.snotifyConfigService.getConfig());
      if (!this.touchedTeamSelect) {
        this.snotifyService.error('Please choose a team to donate to or choose No team.', null, this.snotifyConfigService.getConfig());
      }
      return false;
    }
    if (!this.touchedTeamSelect) {
      this.snotifyService.error('Please choose a team to donate to or choose No team.', null, this.snotifyConfigService.getConfig());
      return false;
    }

    this.donateState.pipe(take(1)).subscribe((res: fromDonate.State) => {
      const steps: fromDonate.Steps = {
        ...res.steps,
        amount: this.donateForm.valid
      };
      this.store.dispatch(new donateActions.SetAmount(this.currencyMask.formatString(this.donateForm.value.amount)));
      this.store.dispatch(new donateActions.SetMessage(this.donateForm.value.message));
      this.store.dispatch(new donateActions.SetNavigationStep(steps));
      this.route.parent.params.pipe(take(1)).subscribe( params => {
        // this.router.navigate([`/donate/${params.questId}/${params.userId}/confirm`]);
        this.router.navigate([`/donate/${params.questId}/${this.selectedTeamCreatorId ? this.selectedTeamCreatorId : params.userId}/confirm`]);
      });
    });
  }

  private loadQuestTeams(questId: number): void {
    this.uiSelectTeamService.loadQuestTeams(questId)
    .subscribe(res => {
      this.teamsList = res;
      console.log(this.teamsList);

      let initialTeam = this.teamsList.find(team => team.creatorId == this.userId);
      if (initialTeam && initialTeam.teamId) {  
        this.initialTeamId = initialTeam.teamId;
      }
    });
  }

  onTeamSelect(selectedId: number) {
    this.onTeamTouch();
    let selectedTeam = this.teamsList.find((team) => team.teamId == selectedId);
    this.selectedTeamCreatorId = selectedTeam ? selectedTeam.creatorId : null;
    this.teamChanged.emit(this.selectedTeamCreatorId);

    console.log(selectedTeam);
    console.log(this.selectedTeamCreatorId);
  }

  onTeamTouch() {
    this.touchedTeamSelect = true;
  }
}
