import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {QuestTask} from 'diemlife-commons/dist/diemlife-commons-model';
import {ADD_QUEST_LINK, AddQuestLink, PatchMilestone} from './dl-milestones.actions';
import {AppState} from '../../../../_store/app.reducers';
import {QuestService} from '../../../../_services/quest.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';

@Injectable()
export class DlMilestonesEffects {

  constructor(private actions: Actions,
              private store: Store<AppState>,
              private questService: QuestService,
              private notifyService: SnotifyService,
              private notifyConfig: SnotifyConfigService) {
  }

  @Effect()
  doLinkQuestsByTask = this.actions.pipe(
    ofType<AddQuestLink>(ADD_QUEST_LINK),
    switchMap((action: AddQuestLink) => this.questService.linkQuest(
      action.taskId,
      action.linkedQuestId
    ).pipe(
      map(task => {
        this.notifyService.success('Quest successfully linked', null, this.notifyConfig.getConfig());
        return {index: action.taskIndex, task: task} as IndexedTask;
      }),
      catchError(() => {
        this.notifyService.error('Error linking Quest!', null, this.notifyConfig.getConfig());
        return EMPTY;
      })
    )),
    switchMap((result: IndexedTask) => result
      ? [new PatchMilestone({index: result.index, value: result.task})]
      : [])
  );

}

interface IndexedTask {
  index: number;
  task: QuestTask;
}
