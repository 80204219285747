import * as NotificationsActions from './notifications.actions';

export interface State {
  active: boolean;
  notEmpty: boolean;
  count: number;
}

export const initialState: State = {
  active: null,
  notEmpty: false,
  count: 0
};

export function NotificationsReducer (state = initialState, action: NotificationsActions.NotificationsActions) {
  switch (action.type) {
    case (NotificationsActions.SET_NOTIFICATIONS_INFO):
      return {
        ...state,
        notEmpty: !!action.payload,
        count: action.payload
      };
    case (NotificationsActions.SHOW_NOTIFICATIONS):
      return {
        ...state,
        active: true
      };
    case (NotificationsActions.HIDE_NOTIFICATIONS):
      return {
        ...state,
        active: false
      };
    case (NotificationsActions.RESET_NOTIFICATIONS):
      return {
        ...initialState
      };
    default:
      return state;
  }
}
