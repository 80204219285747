import { AbstractControl } from '@angular/forms';
import { SignUpService } from './sign-up.service';
import { map } from 'rxjs/operators';

export class ValidateEmailNotTaken {
  static createValidator(signupService: SignUpService) {
    return (control: AbstractControl) => {
      return signupService.checkEmail(control.value).pipe(map(res => {
        return !res ? null : { emailTaken: true };
      }));
    };
  }
}
