import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, Subscription, timer} from 'rxjs';
import {debounce, distinctUntilChanged, take} from 'rxjs/operators';
import {PaymentsService} from '../../../main/payments/payments.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';
import {DonateService} from '../donate.service';
import {QuestUserInfo, StripeErrorCodesEnum} from 'diemlife-commons/dist/diemlife-commons-model';
import {TicketFlowFormTemplate} from 'src/app/modules/ticket-flow/pages/ticket-flow/ticket-flow.form.template';
import {ReferenceDataService} from 'src/app/_services/reference-data.service';
import {TicketFlowService} from 'src/app/modules/ticket-flow/pages/ticket-flow/ticket-flow.service';
import {AccountService} from '../../account/account.service';
import {SignUpService} from '../../../main/auth/sign-up/sign-up.service';
import {ModalService} from '../../../main/modal/modal.service';
import * as config from '../../../../app.config';
import * as Constants from '../../../../app.constants';
import * as fromDonate from '../store/donate.reducer';
import * as fromAuth from '../../../main/auth/store/auth.reducer';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import * as donateActions from '../store/donate.actions';
import {AppState} from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-donate-confirm',
  templateUrl: './donate-confirm.component.html',
  styleUrls: ['./donate-confirm.component.styl']
})
export class DonateConfirmComponent extends TicketFlowFormTemplate implements OnInit, OnDestroy {
  questId: number;
  userId: number;
  authenticated = false;
  donateState: Observable<fromDonate.State>;
  donateStateSubscription: Subscription;
  authState: Observable<fromAuth.State>;
  authStateSubscription: Subscription;
  profileState: Observable<fromProfile.State>;
  profileStateSubscription: Subscription;
  donateFormSubscription: Subscription;
  messages = Constants.VALIDATION_MESSAGES;
  donateFormSubmitted = false;
  donateForm: FormGroup;
  selectedPayment: any;
  paymentName = 'creditCard';
  breakdown: any;
  componentReady = false;
  recurrentOptions = {
    absorbFees: false,
    payLater: false
  };
  isLoading = false;
  breakdownQuestIsLoading = false;
  creatorAbsorbFees = false;
  stripeLogo = '../../../../assets/static/powered_by_stripe.png';
  wrongPassword = false;
  emailChangeSubscription: Subscription;
  passwordChangeSubscription: Subscription;
  infoIsCleared = true;

  private paymentInfoPatched = false;
  private profileInfoPatched = false;
  private emailWithForceLogin: string = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private paymentsSevice: PaymentsService,
    private store: Store<AppState>,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private donateService: DonateService,
    private accountService: AccountService,
    private signUpService: SignUpService,
    private modalService: ModalService,
    protected ticketFlowService: TicketFlowService,
    protected referenceDataService: ReferenceDataService
  ) {
    super(referenceDataService, ticketFlowService);
    this.donateState = this.store.select('donate');
    this.authState = this.store.select('auth');
    this.profileState = this.store.select('userInfo');
  }

  ngOnInit() {
    this.loadCountries();
    this.questId = Number(this.route.parent.snapshot.paramMap.get('questId'));
    this.userId = Number(this.route.parent.snapshot.paramMap.get('userId'));

    this.accountService.getUserInfoById(this.userId).subscribe((userInfo: QuestUserInfo) => {
      this.creatorAbsorbFees = userInfo.absorbFees;
    });

    this.donateForm = this.fb.group({
      selectedPayment: ['', [Validators.required]],
      anonymous: [false],
      billingInfo: this.fb.group({
        firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
        lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
        email: ['', [Validators.required, Validators.email]]
      }),
      address: this.fb.group({
        country: ['US', [Validators.required]],
        zip: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/), Validators.minLength(1), Validators.maxLength(255)]]
      })
    });

    this.authStateSubscription = this.authState.subscribe((state: fromAuth.State) => {
      this.resetAllSubscriptions();
      this.authenticated = state.authenticated;

      this.donateFormSubscription = this.donateForm.get('selectedPayment').valueChanges.pipe(
        debounce(() => timer(1000))
      ).subscribe(() => {
        if (this.donateForm.get('selectedPayment').valid && this.paymentInfoPatched) {
          this.paymentName = this.selectedPayment.paymentMode;
          this.breakdownQuest(this.paymentName);
        }
      });

      if (this.authenticated) {
        this.donateForm.removeControl('signUp');
        this.donateForm.addControl('recurrent', new FormControl(false));
        this.donateForm.addControl('absorbFees', new FormControl(false));
        this.donateForm.addControl('payLater', new FormControl(false));
        this.donateForm.addControl('password', new FormControl('', [
          Validators.required,
          Validators.minLength(config.password.minLength),
          Validators.maxLength(config.password.maxLength)
        ]));

        this.passwordChangeSubscription = this.donateForm.get('password').valueChanges.subscribe(val => {
          this.wrongPassword = this.donateFormSubmitted && val.length === 0;
        });

        if (this.emailWithForceLogin) {
          this.emailWithForceLogin = null;
          this.profileInfoPatched = true;
          this.infoIsCleared = false;
        } else if (!this.profileInfoPatched) {
          this.profileStateSubscription = this.profileState.subscribe((profileState: fromProfile.State) => {
            if (profileState.id) {
              const patchedValue: any = {
                billingInfo: {
                  firstName: profileState.firstName,
                  lastName: profileState.lastName,
                  email: profileState.email
                },
                address: {}
              };
              if (profileState.country) {
                patchedValue.address.country = profileState.country;
              }
              if (profileState.zip) {
                patchedValue.address.zip = profileState.zip;
              }

              this.donateForm.patchValue(patchedValue);

              this.infoIsCleared = false;
              if (this.profileStateSubscription) {
                this.profileStateSubscription.unsubscribe();
              }
            }
          });
          this.profileInfoPatched = true;
        }
      } else {
        this.donateForm.removeControl('recurrent');
        this.donateForm.removeControl('absorbFees');
        this.donateForm.removeControl('payLater');
        this.donateForm.removeControl('password');
        this.donateForm.addControl('signUp', new FormControl(false));

        this.emailChangeSubscription = this.donateForm.get('billingInfo').get('email').valueChanges.pipe(
          debounce(() => timer(333)),
          distinctUntilChanged()
        ).subscribe(value => {
          this.signUpService.checkEmail(value).subscribe(result => this.emailWithForceLogin = result ? value : null);
        });
      }

      this.donateStateSubscription = this.donateState.pipe(take(1)).subscribe((res: fromDonate.State) => {
        if (res.amount === 0) {
          this.snotifyService.warning('Please, complete previous step!', null, this.snotifyConfigService.getConfig(null, {maxOnScreen: 1}));
          this.route.parent.params.pipe(take(1)).subscribe(params => {
            this.router.navigate([`/donate/${params.questId}/${params.userId}/enter-amount`]);
          });
        } else {
          this.donateForm.patchValue({
            'anonymous': res.billing.anonymous
          });
          if (this.authenticated) {
            this.donateForm.patchValue({
              'recurrent': res.billing.recurrent,
            });
          }
        }
      });

      this.breakdownQuest(this.paymentName);
    });
  }

  private breakdownQuest(paymentName) {
    let preparedPaymentName: string;
    this.breakdownQuestIsLoading = true;
    switch (paymentName) {
      case 'creditCard':
        preparedPaymentName = 'CreditCard';
        break;
      case 'bankAccount':
        preparedPaymentName = 'BankAccount';
        break;
      default:
        preparedPaymentName = 'CreditCard';
        break;
    }
    this.donateState.pipe(take(1)).subscribe((result: fromDonate.State) => {
      const amountInCents: number = this.paymentsSevice.getAmountInCents(result.amount);
      let backerAbsorbsFees = true;
      if (this.authenticated) {
        backerAbsorbsFees = this.donateForm.get('recurrent').value ? this.donateForm.get('absorbFees').value : true;
      }
      this.route.parent.params.pipe(take(1)).subscribe(params => {
        this.paymentsSevice.breakdownQuest(
          params.questId,
          params.userId,
          amountInCents,
          preparedPaymentName,
          backerAbsorbsFees
        ).subscribe(breakdown => {
          this.breakdown = breakdown;
          this.componentReady = true;
          this.breakdownQuestIsLoading = false;
          this.store.dispatch(new donateActions.SetBrutTotal(this.breakdown.brutTotal));
        });
      });
    });
  }

  private resetAllSubscriptions() {
    if (this.donateStateSubscription) {
      this.donateStateSubscription.unsubscribe();
    }
    if (this.emailChangeSubscription) {
      this.emailChangeSubscription.unsubscribe();
    }
    if (this.passwordChangeSubscription) {
      this.passwordChangeSubscription.unsubscribe();
    }
    if (this.donateFormSubscription) {
      this.donateFormSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.resetAllSubscriptions();
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    if (this.profileStateSubscription) {
      this.profileStateSubscription.unsubscribe();
    }
  }

  onClearBillingInfo() {
    if (!this.infoIsCleared) {
      this.snotifyService.confirm(
        'Clear billing info?',
        null,
        this.snotifyConfigService.getConfig({
          timeout: 5000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            {
              text: 'Yes',
              bold: true,
              action: (toast) => {
                this.clearBillingInfo();
                this.snotifyService.remove(toast.id);
              }
            },
            {
              text: 'No',
              bold: true,
              action: (toast) => {
                this.snotifyService.remove(toast.id);
              }
            }
          ]
        })
      );
    }
  }

  private clearBillingInfo() {
    this.donateForm.patchValue({
      billingInfo: {
        firstName: '',
        lastName: '',
        email: ''
      },
      address: {
        zip: ''
      }
    });
    this.infoIsCleared = true;
  }

  onSelectedPayment(payment) {
    this.selectedPayment = payment;
    this.donateForm.patchValue({
      selectedPayment: payment.paymentMode
    });
    this.paymentInfoPatched = true;
  }

  onChangeRecurrentControl(event) {
    this.store.dispatch(new donateActions.SetRecurrentCheck(event.target.checked));
  }

  onChangeAnonymousControl(event) {
    this.store.dispatch(new donateActions.SetAnonCheck(event.target.checked));
  }

  onSubmitDonateForm() {
    if (this.isLoading) {
      return false;
    }
    if (this.emailWithForceLogin && !this.authenticated) {
      this.snotifyService.info('This email exists, please login', null, this.snotifyConfigService.getConfig());
      this.modalService.open('auth-modal', {formMode: 'logIn', defaultEmail: this.emailWithForceLogin});
      return false;
    }
    this.wrongPassword = false;
    this.donateFormSubmitted = true;
    if (this.donateForm.invalid) {
      this.snotifyService.error('Please enter valid information', null, this.snotifyConfigService.getConfig());
      this.isLoading = false;
      return false;
    } else {
      this.isLoading = true;
      this.donateState.pipe(take(1)).subscribe((result: fromDonate.State) => {
        const amountInCents: number = this.paymentsSevice.getAmountInCents(result.amount);
        let absorbFees = this.recurrentOptions.absorbFees;
        if (this.creatorAbsorbFees) {
          absorbFees = false;
        }
        const payload: any = {
          ...this.selectedPayment,
          amount: amountInCents,
          currency: 'usd',
          message: result.message,
          anonymous: result.billing.anonymous,
          billingInfo: {
            personalInfo: this.donateForm.value.billingInfo,
            address: this.donateForm.value.address
          }
        };
        if (this.authenticated) {
          payload['password'] = this.donateForm.value.password;
          payload['recurrent'] = result.billing.recurrent;
          payload['absorbFees'] = absorbFees;
          payload['payNow'] = this.recurrentOptions.payLater;
        } else {
          payload['signUp'] = this.donateForm.value.signUp;
        }
        this.route.parent.params.pipe(take(1)).subscribe(params => {
          this.donateService.backQuest(params.questId, params.userId, payload).subscribe(() => {
            const steps: fromDonate.Steps = {
              ...result.steps,
              confirm: true
            };
            setTimeout(() => {
              this.store.dispatch(new donateActions.SetNavigationStep(steps));
              this.store.dispatch(new donateActions.DonateSetSuccessInfo(payload));

              this.router.navigate([`/donate/${params.questId}/${params.userId}/success`]);
              this.isLoading = false;
            });
          }, err => {
            let errorValue: string = null;
            let errorMessage = '';
            for (const errorCode in StripeErrorCodesEnum) {
              if (err.error && err.error.message && err.error.message.search(errorCode) !== -1) {
                errorValue = StripeErrorCodesEnum[errorCode];
              }
            }
            if (err.status === 403) {
              this.wrongPassword = true;
              errorMessage = 'Invalid Password. Please re-enter and try again.';
            } else if (errorValue) {
              errorMessage = 'There was an issue processing your request due to: ' + errorValue;
            } else {
              errorMessage = 'There was an issue processing your request. Please try again.';
            }
            this.snotifyService.error(errorMessage, null, this.snotifyConfigService.getConfig());
            this.isLoading = false;
          });
        });
      });
    }
  }

  openModal(id: string, mode: string) {
    this.modalService.open(id, {formMode: mode});
  }
}
