<div class="c-dropdown" [ngClass]="{'is-active': isOpen}" toggle-directive (toggle-directive-event)="closeDropdown()">
  <div class="c-dropdown__header" (click)="toggleDropdown()">
      <ng-content></ng-content>
  </div>
  <div class="c-dropdown__body">
    <div class="c-dropdown__header__cancel" (click)="toggleDropdown()">
      <svg class="icon icon-cancel">
          <use xlink:href="#icon-cancel"></use>
      </svg>
    </div>
    <div>
      <div class="c-dropdown__row">
        <a *ngIf="privacyLevel !== 'PRIVATE'" (appAuthGuard)="openModal('share-modal')" class="c-dropdown__item is-flex" href="javascript:void(null)">
            <svg class="icon icon-share">
                <use xlink:href="#icon-share"></use>
            </svg>
            <span>Share</span>
        </a>
        <a (appAuthGuard)="followQuest()" *ngIf="!questInfo.followed" class="c-dropdown__item is-flex" href="javascript:void(null)">
            <svg class="icon icon-double-angle">
                <use xlink:href="#icon-double-angle"></use>
            </svg>
            <span>Follow</span>
        </a>
        <a class="c-dropdown__item is-flex" *ngIf="questInfo.followed" href="javascript:void(null)" (click)="unFollowQuest()">
            <svg class="icon icon-double-angle icon-danger">
                <use xlink:href="#icon-double-angle"></use>
            </svg>
            <span>Unfollow</span>
        </a>
        <a class="c-dropdown__item is-flex" *ngIf="questInfo.editable" [routerLink]="['/edit', questInfo.id]">
          <svg class="icon icon-pencil">
            <use xlink:href="#icon-pencil"></use>
          </svg>
          <span>Edit</span>
        </a>
      </div>
      <div class="c-dropdown__row" *ngIf="questInfo.copyable || !questInfo.active">
        <a (appAuthGuard)="saveQuest()" *ngIf="!questInfo.saved && !questInfo.active" class="c-dropdown__item is-flex" href="javascript:void(null)">
          <svg class="icon icon-save">
            <use xlink:href="#icon-save"></use>
          </svg>
          <span>Save</span>
        </a>
        <a class="c-dropdown__item is-flex" *ngIf="questInfo.saved && !questInfo.active" href="javascript:void(null)" (click)="unsaveQuest()">
          <svg class="icon icon-save icon-danger">
              <use xlink:href="#icon-save"></use>
          </svg>
            <span>Unsave</span>
        </a>
        <a (appAuthGuard)="copyQuest()" *ngIf="questInfo.copyable" class="c-dropdown__item is-flex" href="javascript:void(null)">
          <svg class="icon icon-copy">
            <use xlink:href="#icon-copy"></use>
          </svg>
          <span>Copy</span>
        </a>
      </div>
      <div class="c-dropdown__row" *ngIf="status === 'active' && doerInfo.id === (profileState | async).id">
        <a class="c-dropdown__item is-flex" (click)="cancelQuest()" href="javascript:void(null)">
            <svg class="icon icon-trash icon-danger">
                <use xlink:href="#icon-trash"></use>
            </svg>
            <span>Cancel</span>
        </a>
      </div>
      <div class="c-dropdown__row" *ngIf="showStartTrigger">
        <a [ngSwitch]="questInfo.mode" class="c-dropdown__item is-flex" (click)="doQuest()" href="javascript:void(null)">
          <svg class="icon icon-update"><use xlink:href="#icon-update"></use></svg>
          <span *ngSwitchCase="'diyMode'">Join</span>
          <span *ngSwitchDefault>Start</span>
        </a>
      </div>
      <div class="c-dropdown__row" *ngIf="showSupportTrigger">
        <a class="c-dropdown__item is-flex" (click)="supportQuest()" href="javascript:void(null)">
          <svg class="icon icon-update"><use xlink:href="#icon-update"></use></svg>
          <span>Support</span>
        </a>
      </div>
    </div>
  </div>
</div>

<app-modal id="share-modal" *ngIf="openedModal === 'share-modal'" [ngStyle]="{'display': 'block'}" (closeModal)="closeModal()">
    <h3 class="modal-title">Select share method</h3>
    <app-share-widjet [questUserId]="doerInfo.id" [questId]="questInfo.id" [shareUrl]="questInfo.shareUrl" class="c-share-widjet"></app-share-widjet>
</app-modal>
<app-modal *ngIf="openedModal === 'comment-modal'" id="comment-modal">
    <h3 class="modal-title">Comments</h3>
</app-modal>
