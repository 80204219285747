import {Component, OnInit, AfterViewChecked} from '@angular/core';
import {MenuService} from './components/main/menu/menu.service';
import {AuthService} from './components/main/auth/auth.service';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {take, distinctUntilChanged, distinctUntilKeyChanged, map, tap} from 'rxjs/operators';
import tools from './_tools';
import * as fromNotifications from './components/main/notifications/store/notifications.reducer';
import * as fromApp from './_store/app.reducers';
import * as fromAuth from './components/main/auth/store/auth.reducer';
import {FormMode} from './components/main/auth/store/auth.reducer';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthGuardService} from './directives/auth-guard/auth-gurad.service';
import {SelectFormMode, SetDefaultEmail} from './components/main/auth/store/auth.actions';
import { ModalService } from './components/main/modal/modal.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { WindowRef } from './_global/window-ref.module';
import {Location} from '@angular/common'; 

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit, AfterViewChecked {
  authState: Observable<fromAuth.State>;
  notificationsInfoState: Observable<fromNotifications.State>;
  hideHeader = true;
  showNotifications = false;

  constructor(
    private winRef: WindowRef,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    public menuService: MenuService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authGuardService: AuthGuardService,
    private modalService: ModalService,
    private ngbTooltipConfig: NgbTooltipConfig
  ) {
  }

  ngOnInit() {
    this.authState = this.store.select('auth');
    this.authState.pipe(take(1)).subscribe(
      tools.debounce((authState: fromAuth.State) => {
        if (authState.authenticated) {
          this.authService.checkTokenValid(authState);
        }
      }, 0)
    );
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {

        $('html').scrollTop(0);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        document.body.classList.remove('modal-open');
        this.hideHeader = this.route.snapshot.queryParams['_web-view'] === 'true' ? true : (
          this.route.snapshot.firstChild.data[0]
            ? this.route.snapshot.firstChild.data[0].hideHeader
            : false
        );
      }
    });

    this.store
      .pipe(
        select('notifications'),
        distinctUntilKeyChanged('active'),
        map(state => state.active),
        tap((isActive) => {
          this.showNotifications = isActive;
        })
      )
      .subscribe();
  }
  ngAfterViewChecked() {
    if ($(this.winRef.nativeWindow).width() < 768) {
      this.ngbTooltipConfig.triggers = 'click';
    }
  }

  setupFormOptions(event: { formMode: string, defaultEmail: string }) {
    this.store.dispatch(new SelectFormMode(FormMode[event ? event.formMode : 'logIn']));
    this.store.dispatch(new SetDefaultEmail(event ? event.defaultEmail : null));
  }

  onCloseModal() {
    this.authGuardService.update();
    this.store.dispatch(new SelectFormMode(null));
    this.store.dispatch(new SetDefaultEmail(null));
  }
  closeAuthModal() {
    this.modalService.close('auth-modal');
  }
  closeContactModal() {
    if (this.router.url == "/contact") {
      this.location.go('');
    }
  }

}
