import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'urlify'})
export class UrlifyPipe implements PipeTransform {
  transform(value: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return value.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
    });
  }
}
