<ul class="c-modal-tabs__header"
    *ngIf="!(activeState === 'recoveryPassword')"
    title="Login sign up switch">
  <li
    *ngFor="let tab of tabs | keys"
    class="c-modal-tabs__header__item"
    [class.is-active]="activeState === tab.key">
    <a href="javascript:void(0)"
       (click)="activateTab(tab.key)">{{tab.value}}</a>
  </li>
</ul>

<form
  title="Login form"
  name="loginForm"
  autocomplete="new-password"
  class="c-modal-tabs__body"
  [formGroup]="signinForm"
  #ngForm="ngForm"
  (ngSubmit)="onSignin()"
  *ngIf="activeState === 'logIn'"
  [ngClass]="{'form-is-submitted': signinFormSubmitted}">

  <div class="c-forms__group">
    <div class="c-forms__control is-required">
      <input
      class="c-forms__input"
      type="email"
      autocomplete="new-password"
      formControlName="email"
      name="email"
      placeholder="Email"
      aria-label="Enter Email for login"
      appInputFocusTrigger>
    </div>
  </div>
  <div
    *ngIf="signinForm.get('email').invalid && (signinForm.get('email').touched || ngForm.submitted)"
    class="c-forms__message is-red"
    role="alert">
    <div *ngIf="signinForm.get('email').errors.required">{{ messages.required }}</div>
    <div *ngIf="signinForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
  </div>
  <div class="c-forms__group">
    <div class="c-forms__control is-required">
      <input
        class="c-forms__input"
        type="password"
        formControlName="password"
        autocomplete="new-password"
        name="password"
        aria-label="Enter Password for login"
        autocomplete="current-password"
        placeholder="Password">
    </div>
  </div>
  <div
    *ngIf="signinForm.get('password').invalid && (signinForm.get('password').touched || ngForm.submitted)"
    class="c-forms__message is-red"
    role="alert">
    <div *ngIf="signinForm.get('password').errors.required">{{ messages.required }}</div>
    <div *ngIf="signinForm.get('password').errors.minlength">{{ messages.password.minlength(signinForm.get('password').errors.minlength.requiredLength) }}</div>
    <div *ngIf="signinForm.get('password').errors.maxlength">{{ messages.password.maxlength(signinForm.get('password').errors.maxlength.requiredLength) }}</div>
  </div>
  <div class="c-forms__group c-modal-reset__link">
    <a href="javascript:void(0)"
       (click)="showRecovery(signinForm.get('email').value)">Forgot password?</a>
  </div>
  <div class="c-forms__group">
    <button
      [attr.disabled]="(authState | async)?.isLoading ? true : null"
      type="submit"
      class="c-btn c-btn_md c-btn_dark c-btn_full"
      aria-label="Submit">
      <span>Submit</span>
    </button>
  </div>
</form>

<app-sign-up *ngIf="activeState === 'signUp'"></app-sign-up>

<div class="c-modal-tabs__header" *ngIf="activeState === 'recoveryPassword'">
  <div class="c-modal-tabs__header__item is-full">
    <span>Forgot your password?</span>
  </div>
</div>
<form
  autocomplete="new-password"
  class="c-modal-tabs__body"
  [formGroup]="recoveryForm"
  #ngForm="ngForm"
  (ngSubmit)="onRecoveryPass()"
  *ngIf="activeState === 'recoveryPassword'"
  [ngClass]="{'form-is-submitted': recoveryFormSubmitted}">
  <div class="c-forms__group">
    <div class="c-modal-reset__desc">
      <p>Enter your email and we'll send a recovery link.</p>
    </div>
  </div>
  <div class="c-forms__group">
    <div class="c-forms__control is-required">
      <input
        class="c-forms__input"
        type="email"
        formControlName="email"
        name="email"
        autocomplete="new-password"
        placeholder="Email"
        aria-label="Enter email to recover password"
        appInputFocusTrigger>
    </div>
  </div>
  <div
    *ngIf="recoveryForm.get('email').invalid && (recoveryForm.get('email').touched || ngForm.submitted)"
    class="c-forms__message is-red"
    role="alert">
    <div *ngIf="recoveryForm.get('email').errors.required">{{ messages.required }}</div>
    <div *ngIf="recoveryForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
  </div>
  <div class="c-forms__group">
    <button
      [hidden]="recoveryForm.invalid"
      [attr.disabled]="(authState | async)?.isLoading ? true : null"
      type="submit"
      class="c-btn c-btn_md c-btn_dark c-btn_full"
      aria-label="Submit">
      <span>Submit</span>
    </button>
  </div>
  <div class="c-forms__group c-modal-reset__back">
    <button class="h-btn-reset" (click)="activateTab('logIn')" type="button">Back to the Login</button>
  </div>
</form>
