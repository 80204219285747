<br/>
<form
  class="c-modal-tabs__body c-container"
  [formGroup]="passwordResetForm"
  #ngForm="ngForm"
  (ngSubmit)="resetPassword()"
  [ngClass]="{'form-is-submitted': passwordResetFormSubmitted}">
  <div class="c-forms__group">
    <div class="c-modal-reset__desc">
      <h1>Update password</h1>
    </div>
  </div>
  <div class="c-forms__group">
    <div class="c-forms__control is-required">
      <input
        [attr.disabled]="activityStatus"
        class="c-forms__input"
        type="password"
        formControlName="newPassword"
        placeholder="New password"
        aria-label="New Password">
    </div>
  </div>
  <div
    *ngIf="passwordResetForm.get('newPassword').invalid && (passwordResetForm.get('newPassword').touched || ngForm.submitted)"
    class="c-forms__message is-red"
    role="alert">
    <div *ngIf="passwordResetForm.get('newPassword').errors['required']">{{ messages.required }}</div>
    <div *ngIf="passwordResetForm.get('newPassword').errors['minlength']">{{ messages.password.minlength(passwordResetForm.get('newPassword').errors['minlength'].requiredLength) }}</div>
    <div *ngIf="passwordResetForm.get('newPassword').errors['maxlength']">{{ messages.password.maxlength(passwordResetForm.get('newPassword').errors['maxlength'].requiredLength) }}</div>
  </div>

  <div class="c-forms__group">
    <div class="c-forms__control is-required">
      <input
        [attr.disabled]="activityStatus"
        class="c-forms__input"
        type="password"
        formControlName="newPasswordConfirmation"
        placeholder="Confirm password"
        aria-label="Confirm Password">
    </div>
  </div>
  <div
    *ngIf="passwordResetForm.get('newPasswordConfirmation').invalid && (passwordResetForm.get('newPasswordConfirmation').touched || ngForm.submitted)"
    class="c-forms__message is-red"
    role="alert">
    <div *ngIf="passwordResetForm.get('newPasswordConfirmation').errors['required']">{{ messages.required }}</div>
    <div *ngIf="passwordResetForm.get('newPasswordConfirmation').errors['minlength']">{{ messages.password.minlength(passwordResetForm.get('newPasswordConfirmation').errors['minlength'].requiredLength) }}</div>
    <div *ngIf="passwordResetForm.get('newPasswordConfirmation').errors['maxlength']">{{ messages.password.maxlength(passwordResetForm.get('newPasswordConfirmation').errors['maxlength'].requiredLength) }}</div>
    <div *ngIf="passwordResetForm.get('newPasswordConfirmation').errors['passwordsMatch']">Passwords do not match</div>
  </div>

  <div class="c-forms__group">
    <button
      [attr.disabled]="activityStatus"
      type="submit"
      class="c-btn c-btn_md c-btn_dark c-btn_full"
      aria-label="Submit">
      <span>Submit</span>
    </button>
  </div>
</form>
