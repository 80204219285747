<ul class="c-leaderboard-list__container">
  <li class="c-leaderboard-list__item" *ngFor="let member of members">
    <app-leaderboard-list-item class="c-leaderboard-list__item-wrapper"
                               (updateMember)="onUpdateMember($event)"
                               [allowEdit]="allowEdit"
                               [questId]="questId"
                               [selectedAttributeId]="selectedAttributeId"
                               [member]="member"
                               [mode]="podiumMode"
                               [teams]="teams"></app-leaderboard-list-item>
  </li>
</ul>
