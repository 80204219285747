import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.styl']
})
export class FriendsComponent implements OnInit {

  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(private meta: Meta, private titleService: Title) { }

  ngOnInit() {
    this.setMetaTags();
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | Friends');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | Friends'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'Manage and view your friends in DIEMlife'});
  }

}
