<div class="test" id="top">
  <!-- #################################### -->


  <div class="bubble-cont">
    <div class="st--top-mod">
      <h1 class="st--top-mod-title">Product Guide</h1>
      <div class="c-profile-new__tabs" *ngIf="tabs.active">
        <!--<ul class="c-profile-new__tabs__nav">-->
        <!--<li class="c-profile-new__tabs__nav__item" *ngFor="let tab of tabs.list" [class.is-active]="tab.id === tabs.active.id">-->
        <!--<a href="javascript:void(0)" (click)="onTabChange(tab)">{{tab.caption}}</a>-->
        <!--</li>-->
        <!--</ul>-->
        <div class="c-profile-new__tabs__content" [ngSwitch]="tabs.active.id">
          <div class="c-profile-new__tabs__pane" *ngSwitchCase="'personal'">

            <div class="st--top-mod-vid">
              <div class="fpo-div1">
                <video
                  autoplay
                  class=""
                  id="hero-video"
                  loop
                  muted=""
                  oncanplay="this.play()"
                  onloadedmetadata="this.muted=true"
                  playsinline
                  controls>
                  <source
                    [src]="'https://s3.amazonaws.com/diemlife-assets/OVERVIEW+VIDEO+FINAL.mp4' | safe: 'url'"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>

            <div class="st--nav ">
              <div class="st--nav-outer d-fixed-nav-inner">
                <ul class="st--nav-inner">
                  <li><a href="/product#lnk1">SIGN UP</a></li>
                  <li><a href="/product#lnk2">SET UP YOUR PROFILE </a></li>
                  <li><a href="/product#lnk3">MANAGE YOUR ACCOUNT</a></li>
                  <li><a href="/product#lnk4">ADD FRIENDS</a></li>
                  <li><a href="/product#lnk5">CREATE A QUEST®</a></li>
                  <li><a href="/product#lnk6">EXPLORE</a></li>
                  <li><a href="/product#lnk7">MANAGING YOUR QUEST®</a></li>
                  <li><a href="/product#lnk8">PURCHASING EVENT TICKETS</a></li>
                  <li><a href="/product#lnk9">SETTING UP EVENT TICKETS</a></li>
                  <li><a href="/product#lnk10">ALLOW OTHERS TO FUNDRAISE FOR YOU</a></li>
                  <li><a href="/product#lnk11">HELP OTHERS FUNDRAISE</a></li>
                  <li><a href="/product#lnk12">SENDING BACKING</a></li>
                  <li><a href="/product#lnk13">RECEIVING PAYOUTS</a></li>
                  <li><a href="/product#lnk14">SUPPORT AND SOCIAL MEDIA</a></li>
                </ul>
              </div>
            </div>
            <div id="lnk1"></div>
            <div class="st--bottom">
              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">SIGN UP</h3>
                  <dl>
                    <dt>Ready to start your DIEMlife®?</dt>
                    &nbsp;
                    <dt>1. Go to diem.life and click “Get Started”.</dt>
                    <dt>
                      2. Enter your name, email address, zip or postal code, and create
                      a password.
                    </dt>
                    <dt>3. Click “Submit”.</dt>
                    <dt>
                      4. Check your email and activate your account with the pin code.
                    </dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/SIGN-UP-NOBG-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk2"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">SET UP YOUR PROFILE</h3>
                  <dl>
                    <!-- <dt>SET UP YOUR PROFILE</dt> -->
                    <dt>
                      Your profile is where you can tell your story and share each Quest® you want to achieve.
                    </dt>
                    &nbsp;
                    <dt>
                      1. Click the camera icons to add your profile cover photos.
                    </dt>
                    <dt>
                      2. Share a little bit about who you are in the headline section. It can be your personal mission
                      statement or a list of your favorite hobbies and passions.
                    </dt>
                    <dt>
                      3. Choose your interests. Are you looking to complete physical, mental, social, environmental,
                      occupational, or financial Quests?
                    </dt>
                    <dt>4. Click “Save Profile”.</dt>
                    <dt>
                      5. To make changes, click “Edit Profile”. Click “Save Profile” to
                      save changes.
                    </dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/SET-UP-YOUR-PROFILE-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk3"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">MANAGE YOUR ACCOUNT</h3>
                  <dl>
                    <dt>In the account settings, you can change your login information and add payment options. Add a
                      credit card to send money. Add a bank account to send money and receive payouts.
                    </dt>
                    &nbsp;
                    <dt>1. To add a credit card, click “Add Credit Card” and fill in your card information. Click
                      “Submit”.
                    </dt>
                    <dt>
                      2. To add a bank account, click “Add Bank Account” and fill in your bank information. Click
                      “Submit”.
                    </dt>
                    <dd>a. When adding a bank account, you'll be asked to verify two micro-deposits for security
                      purposes.
                      Note: it takes 2-3 business days to show up in your bank account.
                    </dd>
                    <dt>
                      Tip: Use a bank account for lower fees, especially if you’re making smaller transactions.
                    </dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/MANAGE-YOUR-ACCOUNT-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk4"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">ADD FRIENDS</h3>
                  <dl>
                    <dt>Quests are much more fun when you do them with friends. Build your community by adding friends
                      who can cheer you on.
                    </dt>
                    &nbsp;
                    <dt>1. Go to "Friends" and click the tab “Find Friends”. Search their name in the search bar.</dt>
                    <dt>
                      2. Click + to request them.
                    </dt>
                    <dt>3. To accept requests, click the tab “Requests” and click the check mark.</dt>
                    <dt>
                      4. Find and add the Founders of DIEMlife: Timothy Midgley, Yoo-Sun Park, and Andrew Coleman.
                    </dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/ADD-FRIENDS-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk5"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">CREATE A QUEST®</h3>
                  <dl>
                    <dt>Have a goal in mind that you want to achieve? Create your own Quest that you can share with
                      others.
                    </dt>
                    &nbsp;
                    <dt>1. Go to "My Quests" and click "New Quest".</dt>
                    <dt>
                      2. Fill in the category, title, and description.
                    </dt>
                    <dt>3. Add "Milestones" to break up a Quest into smaller steps.</dt>
                    <dt>
                      4. The “Settings” tab will allow you do the following:
                    </dt>
                    <dd>a. Allow others to copy your Quest.</dd>
                    <dd>b. Set the privacy of the Quest. When set to “Invite,” enter the names of the people you want to
                      invite.
                    </dd>
                    <dd>c. Add administrators by entering their name in "Add Quest admins".</dd>
                    <dt>5. Click the camera icon to add a cover photo.</dt>
                    <dt>6. Click "Start New Quest".</dt>
                    <dt>7. To make changes, click "Edit Quest". Click "Done Editing" to save your changes.</dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/CREATE-A-QUEST-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk6"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">EXPLORE</h3>
                  <dl>
                    <dt>You can see what Quests other people are doing and discover new Quests to join.</dt>
                    &nbsp;
                    <dt>1. Click "Explore" in the main menu to see other Quests.</dt>
                    <dt>
                      2. Look for a Quest in the search bar.
                    </dt>
                    <dt>3. Clicking "Options" will allow you to do the following:</dt>
                    <dd>a. Save the Quest for later.</dd>
                    <dd>b. Share the Quest with a friend..</dd>
                    <dd>c. Copy the Quest.</dd>
                    <dd>d. Follow the Quest.</dd>
                    <dt>4. To join a Quest, click "Start Quest".</dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/EXPLORE-QUESTS-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk7"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">MANAGING YOUR QUEST®</h3>
                  <dl>
                    <dt>Keep track of your progress and make updates in your active Quests.</dt>
                    &nbsp;
                    <dt>1. Check off each milestone by clicking the corresponding grey box.</dt>
                    <dt>
                      2. To edit your milestones, click “Edit” with the pencil icon and click “Done” when you want to
                      save changes. If you do not see this, the Quest creator has opted to make the milestones not
                      editable.
                    </dt>
                    <dt>3. When you’ve completed all the milestones and the Quest, click “Complete Quest”.</dt>
                    <dt>4. You have the option to repeat any completed Quest.</dt>
                    <dd>a. Go to “My Quests” and click the tab “Completed”.</dd>
                    <dd>b. Click the corresponding DIEMlife icon and the Quest will become active again.</dd>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/MANAGE-YOUR-QUESTS.gif"
                    />
                  </div>
                </div>
                <div id="lnk8"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">PURCHASING EVENT TICKETS</h3>
                  <dl>
                    <dt>If you want to attend an event, such as a workshop, training, or race, you’ll need to buy a
                      ticket.
                    </dt>
                    &nbsp;
                    <dt>1. On the Quest event page, click “Tickets”.</dt>
                    <dt>2. Select the quantity.</dt>
                    <dt>3. If you have a discount code, enter it in the discount code field and click “Apply Code”.</dt>
                    <dt>4. Click “To Payment” and fill in your payment information and billing address. To make a
                      purchase, you will need to add a credit card or bank account. Follow the steps in “Manage your
                      Account”.
                    </dt>
                    <dt>5. Click “To Registration” and fill in your holder information.</dt>
                    <dt>6. Enter your password and click “Buy Tickets”. Check your email for a confirmation. Please send
                      any event-related inquiries to the Quest creator.
                    </dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/PURCHASING-EVENT-TICKETS-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk9"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">SETTING UP EVENT TICKETS</h3>
                  <dl>
                    <dt>If you are interested in hosting ticketed events on DIEMlife, please contact us at
                      diemlife@diemlife.com.
                    </dt>
                  </dl>
                </div>
                <div id="lnk10"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">ALLOW OTHERS TO FUNDRAISE FOR YOU</h3>
                  <dl>
                    <dt>Start a fundraiser and receive money to help your non-profit or organization.</dt>
                    &nbsp;
                    <dt>1. Follow the steps of creating a new Quest®.</dt>
                    <dt>2. Turn on the fundraising switch.</dt>
                    <dd>
                      a. Note: once the switch is turned on, fundraising cannot be turned
                      off.
                    </dd>
                    <dt>3. Enter the target amount and click “Start”.</dt>
                    <dd>a. Any funds raised by others will go directly to you.</dd>
                    <dd>
                      b. To receive payouts, add your bank account information. Follow
                      the steps in “Manage Your Account”.
                    </dd>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/ALLOW-OTHERS-TO-FUNDRAISE-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk11"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">HELP OTHERS FUNDRAISE</h3>
                  <dl>
                    <dt>
                      You can help someone else fundraise by creating your own version
                      of their Quest®.
                    </dt>
                    &nbsp;
                    <dt>1. Go to their Quest® and click “Fundraise”.</dt>
                    <dt>2. Enter the target amount and click “Start”.</dt>
                    <dt>3. Share the Quest® to let others know about it.</dt>
                    <dt>4. All proceeds go directly to the original Quest®.</dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt=""
                      src="https://s3.amazonaws.com/diemlife-assets/FUNDRAISE-OTHERS.gif"
                    />
                  </div>
                </div>
                <div id="lnk12"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">SENDING BACKING</h3>
                  <dl>
                    <dt>
                      If you want to financially support someone on their Quest®, you
                      can send them money.
                    </dt>
                    &nbsp;
                    <dt>1. On a Quest®, click “Back Me”.</dt>
                    <dt>2. Choose the amount of money you want to give.</dt>
                    <dt>3. Add a payment method.</dt>
                    <dt>4. Enter your password and click “Confirm”. Check your email for a confirmation</dt>
                    <dd>
                      a. Note: if the Quest® creator cannot receive your payment, then
                      they did not add a bank account. Please notify the creator.
                    </dd>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/SENDING-BACKING-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk13"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">Receiving Payouts</h3>
                  <dl>
                    <dt>1. Go to “Profile” and click the dollar amount.</dt>
                    <dt>2. Confirm the amount.</dt>
                    <dt>3. Request the transfer</dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/RECEIVING-PAYOUTS-F.gif"
                    />
                  </div>
                </div>
                <div id="lnk14"></div>
              </div>

              <div class="st--mod clearfix">
                <div class="st--mod-txt">
                  <h3 class="st--mod-title">SUPPORT AND SOCIAL MEDIA</h3>
                  <dl>
                    <dt>If you have any questions or concerns, reach out to us! We are always here to help.</dt>
                    &nbsp;
                    <dt>1. Click “Help” to read our frequently asked questions.</dt>
                    <dt>2. Have a specific question? Click “Contact Us” or email us at diemlife@diemlife.com.</dt>
                    <dt>3. Follow us on <a href="https://www.facebook.com/DIEMlifers" target="_blank">Facebook</a> and <a
                      href="https://www.instagram.com/diemlife" target="_blank">Instagram</a></dt>
                  </dl>
                </div>
                <div class="st--mod-asset">
                  <div class="fpo-div2">
                    <img
                      alt="SIGN UP"
                      src="https://s3.amazonaws.com/diemlife-assets/SUPPORT-F.gif"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="c-profile-new__tabs__pane" *ngSwitchCase="'business'">-->
          <!--<div class="st&#45;&#45;top-mod-vid">-->
          <!--<div class="fpo-div1">-->
          <!--<video-->
          <!--autoplay-->
          <!--class=""-->
          <!--id="hero-video"-->
          <!--loop-->
          <!--muted=""-->
          <!--oncanplay="this.play()"-->
          <!--onloadedmetadata="this.muted=true"-->
          <!--playsinline-->
          <!--controls>-->
          <!--<source-->
          <!--[src]="'https://s3.amazonaws.com/diemlife-assets/brand-video-final.mp4' | safe: 'url'"-->
          <!--type="video/mp4"-->
          <!--/>-->
          <!--</video>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;nav ">-->
          <!--<div class="st&#45;&#45;nav-outer d-fixed-nav-inner">-->
          <!--<ul class="st&#45;&#45;nav-inner">-->
          <!--<li><a href="#">SIGN UP</a></li>-->
          <!--<li><a href="#">SET UP YOUR PROFILE </a></li>-->
          <!--<li><a href="#">MANAGE YOUR ACCOUNT</a></li>-->
          <!--<li><a href="#">ADD FRIENDS</a></li>-->
          <!--<li><a href="#">CREATE A QUEST®</a></li>-->
          <!--<li><a href="#">EXPLORE</a></li>-->
          <!--<li><a href="#">MANAGING YOUR QUEST®</a></li>-->
          <!--<li><a href="#">PURCHASING EVENT TICKETS</a></li>-->
          <!--<li><a href="#">SETTING UP EVENT TICKETS</a></li>-->
          <!--<li><a href="#">ALLOW OTHERS TO FUNDRAISE FOR YOU</a></li>-->
          <!--<li><a href="#">HELP OTHERS FUNDRAISE</a></li>-->
          <!--<li><a href="#">SENDING BACKING</a></li>-->
          <!--<li><a href="#">SUPPORT AND SOCIAL MEDIA</a></li>-->
          <!--</ul>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;bottom">-->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/SIGN-UP-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt" name="" id="">-->
          <!--<h3 class="st&#45;&#45;mod-title">SIGN UP</h3>-->
          <!--<dl>-->
          <!--<dt>Ready to start your DIEMlife®?</dt>-->
          <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
          <!--<dt>-->
          <!--2. Enter your name, email address, zip or postal code, and create-->
          <!--a password.-->
          <!--</dt>-->
          <!--<dt>3. Click “Submit”.</dt>-->
          <!--<dt>-->
          <!--4. Check your email and activate your account with the pin code.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/SET-UP-YOUR-PROFILE-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">SET UP YOUR PROFILE</h3>-->
          <!--<dl>-->
          <!--&lt;!&ndash; <dt>SET UP YOUR PROFILE</dt> &ndash;&gt;-->
          <!--<dt>-->
          <!--Your profile is where you can tell your story and share the goals-->
          <!--you want to achieve.-->
          <!--</dt>-->
          <!--<dt>-->
          <!--1. Click the white camera icon to add a profile photo. Click the-->
          <!--yellow camera icon to add a cover photo.-->
          <!--</dt>-->
          <!--<dt>-->
          <!--2. In “About Me,” share a little bit about who you are. It can be-->
          <!--your personal mission statement or a list of your favorite hobbies-->
          <!--and passions.-->
          <!--</dt>-->
          <!--<dt>-->
          <!--3. Choose your interests. Are you looking to complete physical,-->
          <!--mental, social, environmental, occupational, or financial Quests?-->
          <!--</dt>-->
          <!--<dt>4. Click “Save Profile”.</dt>-->
          <!--<dt>-->
          <!--5. To make changes, click “Edit Profile”. Click “Save Profile” to-->
          <!--save changes.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/MANAGE-YOUR-ACCOUNT-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">MANAGE YOUR ACCOUNT</h3>-->
          <!--<dl>-->
          <!--<dt>Ready to start your DIEMlife®?</dt>-->
          <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
          <!--<dt>-->
          <!--2. Enter your name, email address, zip or postal code, and create-->
          <!--a password.-->
          <!--</dt>-->
          <!--<dt>3. Click “Submit”.</dt>-->
          <!--<dt>-->
          <!--4. Check your email and activate your account with the pin code.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/ADD-FRIENDS-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">ADD FRIENDS</h3>-->
          <!--<dl>-->
          <!--<dt>Ready to start your DIEMlife®?</dt>-->
          <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
          <!--<dt>-->
          <!--2. Enter your name, email address, zip or postal code, and create-->
          <!--a password.-->
          <!--</dt>-->
          <!--<dt>3. Click “Submit”.</dt>-->
          <!--<dt>-->
          <!--4. Check your email and activate your account with the pin code.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/CREATE-A-QUEST-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">CREATE A QUEST®</h3>-->
          <!--<dl>-->
          <!--<dt>Ready to start your DIEMlife®?</dt>-->
          <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
          <!--<dt>-->
          <!--2. Enter your name, email address, zip or postal code, and create-->
          <!--a password.-->
          <!--</dt>-->
          <!--<dt>3. Click “Submit”.</dt>-->
          <!--<dt>-->
          <!--4. Check your email and activate your account with the pin code.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/EXPLORE-QUESTS-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">EXPLORE</h3>-->
          <!--<dl>-->
          <!--<dt>Ready to start your DIEMlife®?</dt>-->
          <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
          <!--<dt>-->
          <!--2. Enter your name, email address, zip or postal code, and create-->
          <!--a password.-->
          <!--</dt>-->
          <!--<dt>3. Click “Submit”.</dt>-->
          <!--<dt>-->
          <!--4. Check your email and activate your account with the pin code.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/MANAGE-YOUR-QUESTS.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">MANAGING YOUR QUEST®</h3>-->
          <!--<dl>-->
          <!--<dt>Ready to start your DIEMlife®?</dt>-->
          <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
          <!--<dt>-->
          <!--2. Enter your name, email address, zip or postal code, and create-->
          <!--a password.-->
          <!--</dt>-->
          <!--<dt>3. Click “Submit”.</dt>-->
          <!--<dt>-->
          <!--4. Check your email and activate your account with the pin code.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/PURCHASING-EVENT-TICKETS-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">PURCHASING EVENT TICKETS</h3>-->
          <!--<dl>-->
          <!--<dt>Ready to start your DIEMlife®?</dt>-->
          <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
          <!--<dt>-->
          <!--2. Enter your name, email address, zip or postal code, and create-->
          <!--a password.-->
          <!--</dt>-->
          <!--<dt>3. Click “Submit”.</dt>-->
          <!--<dt>-->
          <!--4. Check your email and activate your account with the pin code.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">SETTING UP EVENT TICKETS</h3>-->
          <!--<dl>-->
          <!--<dt>SETTING UP EVENT TICKETS</dt>-->
          <!--<dt>-->
          <!--If you are interested in hosting ticketed events on DIEMlife®,-->
          <!--please contact us at diemlife@diemlife.com.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/ALLOW-OTHERS-TO-FUNDRAISE-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">ALLOW OTHERS TO FUNDRAISE FOR YOU</h3>-->
          <!--<dl>-->
          <!--<dt>ALLOW OTHERS TO FUNDRAISE FOR YOU</dt>-->
          <!--<dt>-->
          <!--Start a fundraiser and receive money to help your non-profit or-->
          <!--organization.-->
          <!--</dt>-->
          <!--<dt>1. Follow the steps of creating a new Quest®.</dt>-->
          <!--<dt>2. Turn on the fundraising switch.</dt>-->
          <!--<dt>-->
          <!--*Note: once the switch is turned on, fundraising cannot be turned-->
          <!--off.-->
          <!--</dt>-->
          <!--<dt>3. Enter the target amount and click “Start”.</dt>-->
          <!--<dt>a. Any funds raised by others will go directly to you.</dt>-->
          <!--<dt>-->
          <!--b. To receive payouts, add your bank account information. Follow-->
          <!--the steps in “Manage Your Account”.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">HELP OTHERS FUNDRAISE</h3>-->
          <!--<dl>-->
          <!--<dt>HELP OTHERS FUNDRAISE</dt>-->
          <!--<dt>-->
          <!--You can help someone else fundraise by creating your own version-->
          <!--of their Quest®.-->
          <!--</dt>-->
          <!--<dt>1. Go to their Quest® and click “Fundraise”.</dt>-->
          <!--<dt>2. Enter the target amount and click “Start”.</dt>-->
          <!--<dt>3. Share the Quest® to let others know about it.</dt>-->
          <!--<dt>4. All proceeds go directly to the original Quest®.</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/FUNDRAISE-OTHERS.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">SENDING BACKING</h3>-->
          <!--<dl>-->
          <!--<dt>SENDING BACKING</dt>-->
          <!--<dt>-->
          <!--If you want to financially support someone on their Quest®, you-->
          <!--can send them money.-->
          <!--</dt>-->
          <!--<dt>1. On a Quest®, click “Back Me”.</dt>-->
          <!--<dt>2. Choose the amount of money you want to give.</dt>-->
          <!--<dt>3. Add a payment method.</dt>-->
          <!--<dt>-->
          <!--*Note: if the Quest® creator cannot receive your payment, then-->
          <!--they did not add a bank account. Please notify the creator.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!---->
          <!--<div class="st&#45;&#45;mod clearfix">-->
          <!--<div class="st&#45;&#45;mod-asset">-->
          <!--<div class="fpo-div2">-->
          <!--<img-->
          <!--alt="SIGN UP"-->
          <!--src="https://s3.amazonaws.com/diemlife-assets/SUPPORT-F.gif"-->
          <!--/>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="st&#45;&#45;mod-txt">-->
          <!--<h3 class="st&#45;&#45;mod-title">SUPPORT AND SOCIAL MEDIA</h3>-->
          <!--<dl>-->
          <!--<dt>SENDING BACKING</dt>-->
          <!--<dt>-->
          <!--If you want to financially support someone on their Quest®, you-->
          <!--can send them money.-->
          <!--</dt>-->
          <!--<dt>1. On a Quest®, click “Back Me”.</dt>-->
          <!--<dt>2. Choose the amount of money you want to give.dt></dt>-->
          <!--<dt>3. Add a payment method.</dt>-->
          <!--<dt>-->
          <!--4. Enter your password and click “Confirm”. Check your email for a-->
          <!--confirmation.-->
          <!--</dt>-->
          <!--<dt>-->
          <!--*Note: if the Quest® creator cannot receive your payment, then-->
          <!--they did not add a bank account. Please notify the creator.-->
          <!--</dt>-->
          <!--</dl>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
        </div>
      </div>
    </div>

    <!--<div class="st&#45;&#45;bottom">-->
    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/SIGN-UP-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt" name="" id="">-->
    <!--<h3 class="st&#45;&#45;mod-title">SIGN UP</h3>-->
    <!--<dl>-->
    <!--<dt>Ready to start your DIEMlife®?</dt>-->
    <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
    <!--<dt>-->
    <!--2. Enter your name, email address, zip or postal code, and create-->
    <!--a password.-->
    <!--</dt>-->
    <!--<dt>3. Click “Submit”.</dt>-->
    <!--<dt>-->
    <!--4. Check your email and activate your account with the pin code.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/SET-UP-YOUR-PROFILE-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">SET UP YOUR PROFILE</h3>-->
    <!--<dl>-->
    <!--&lt;!&ndash; <dt>SET UP YOUR PROFILE</dt> &ndash;&gt;-->
    <!--<dt>-->
    <!--Your profile is where you can tell your story and share the goals-->
    <!--you want to achieve.-->
    <!--</dt>-->
    <!--<dt>-->
    <!--1. Click the white camera icon to add a profile photo. Click the-->
    <!--yellow camera icon to add a cover photo.-->
    <!--</dt>-->
    <!--<dt>-->
    <!--2. In “About Me,” share a little bit about who you are. It can be-->
    <!--your personal mission statement or a list of your favorite hobbies-->
    <!--and passions.-->
    <!--</dt>-->
    <!--<dt>-->
    <!--3. Choose your interests. Are you looking to complete physical,-->
    <!--mental, social, environmental, occupational, or financial Quests?-->
    <!--</dt>-->
    <!--<dt>4. Click “Save Profile”.</dt>-->
    <!--<dt>-->
    <!--5. To make changes, click “Edit Profile”. Click “Save Profile” to-->
    <!--save changes.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/MANAGE-YOUR-ACCOUNT-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">MANAGE YOUR ACCOUNT</h3>-->
    <!--<dl>-->
    <!--<dt>Ready to start your DIEMlife®?</dt>-->
    <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
    <!--<dt>-->
    <!--2. Enter your name, email address, zip or postal code, and create-->
    <!--a password.-->
    <!--</dt>-->
    <!--<dt>3. Click “Submit”.</dt>-->
    <!--<dt>-->
    <!--4. Check your email and activate your account with the pin code.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/ADD-FRIENDS-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">ADD FRIENDS</h3>-->
    <!--<dl>-->
    <!--<dt>Ready to start your DIEMlife®?</dt>-->
    <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
    <!--<dt>-->
    <!--2. Enter your name, email address, zip or postal code, and create-->
    <!--a password.-->
    <!--</dt>-->
    <!--<dt>3. Click “Submit”.</dt>-->
    <!--<dt>-->
    <!--4. Check your email and activate your account with the pin code.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/CREATE-A-QUEST-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">CREATE A QUEST®</h3>-->
    <!--<dl>-->
    <!--<dt>Ready to start your DIEMlife®?</dt>-->
    <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
    <!--<dt>-->
    <!--2. Enter your name, email address, zip or postal code, and create-->
    <!--a password.-->
    <!--</dt>-->
    <!--<dt>3. Click “Submit”.</dt>-->
    <!--<dt>-->
    <!--4. Check your email and activate your account with the pin code.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/EXPLORE-QUESTS-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">EXPLORE</h3>-->
    <!--<dl>-->
    <!--<dt>Ready to start your DIEMlife®?</dt>-->
    <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
    <!--<dt>-->
    <!--2. Enter your name, email address, zip or postal code, and create-->
    <!--a password.-->
    <!--</dt>-->
    <!--<dt>3. Click “Submit”.</dt>-->
    <!--<dt>-->
    <!--4. Check your email and activate your account with the pin code.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/MANAGE-YOUR-QUESTS.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">MANAGING YOUR QUEST®</h3>-->
    <!--<dl>-->
    <!--<dt>Ready to start your DIEMlife®?</dt>-->
    <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
    <!--<dt>-->
    <!--2. Enter your name, email address, zip or postal code, and create-->
    <!--a password.-->
    <!--</dt>-->
    <!--<dt>3. Click “Submit”.</dt>-->
    <!--<dt>-->
    <!--4. Check your email and activate your account with the pin code.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/PURCHASING-EVENT-TICKETS-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">PURCHASING EVENT TICKETS</h3>-->
    <!--<dl>-->
    <!--<dt>Ready to start your DIEMlife®?</dt>-->
    <!--<dt>1. Go to diem.life and click “Get Started”.</dt>-->
    <!--<dt>-->
    <!--2. Enter your name, email address, zip or postal code, and create-->
    <!--a password.-->
    <!--</dt>-->
    <!--<dt>3. Click “Submit”.</dt>-->
    <!--<dt>-->
    <!--4. Check your email and activate your account with the pin code.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">SETTING UP EVENT TICKETS</h3>-->
    <!--<dl>-->
    <!--<dt>SETTING UP EVENT TICKETS</dt>-->
    <!--<dt>-->
    <!--If you are interested in hosting ticketed events on DIEMlife®,-->
    <!--please contact us at diemlife@diemlife.com.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/ALLOW-OTHERS-TO-FUNDRAISE-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">ALLOW OTHERS TO FUNDRAISE FOR YOU</h3>-->
    <!--<dl>-->
    <!--<dt>ALLOW OTHERS TO FUNDRAISE FOR YOU</dt>-->
    <!--<dt>-->
    <!--Start a fundraiser and receive money to help your non-profit or-->
    <!--organization.-->
    <!--</dt>-->
    <!--<dt>1. Follow the steps of creating a new Quest®.</dt>-->
    <!--<dt>2. Turn on the fundraising switch.</dt>-->
    <!--<dt>-->
    <!--*Note: once the switch is turned on, fundraising cannot be turned-->
    <!--off.-->
    <!--</dt>-->
    <!--<dt>3. Enter the target amount and click “Start”.</dt>-->
    <!--<dt>a. Any funds raised by others will go directly to you.</dt>-->
    <!--<dt>-->
    <!--b. To receive payouts, add your bank account information. Follow-->
    <!--the steps in “Manage Your Account”.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">HELP OTHERS FUNDRAISE</h3>-->
    <!--<dl>-->
    <!--<dt>HELP OTHERS FUNDRAISE</dt>-->
    <!--<dt>-->
    <!--You can help someone else fundraise by creating your own version-->
    <!--of their Quest®.-->
    <!--</dt>-->
    <!--<dt>1. Go to their Quest® and click “Fundraise”.</dt>-->
    <!--<dt>2. Enter the target amount and click “Start”.</dt>-->
    <!--<dt>3. Share the Quest® to let others know about it.</dt>-->
    <!--<dt>4. All proceeds go directly to the original Quest®.</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/FUNDRAISE-OTHERS.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">SENDING BACKING</h3>-->
    <!--<dl>-->
    <!--<dt>SENDING BACKING</dt>-->
    <!--<dt>-->
    <!--If you want to financially support someone on their Quest®, you-->
    <!--can send them money.-->
    <!--</dt>-->
    <!--<dt>1. On a Quest®, click “Back Me”.</dt>-->
    <!--<dt>2. Choose the amount of money you want to give.</dt>-->
    <!--<dt>3. Add a payment method.</dt>-->
    <!--<dt>-->
    <!--*Note: if the Quest® creator cannot receive your payment, then-->
    <!--they did not add a bank account. Please notify the creator.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="st&#45;&#45;mod clearfix">-->
    <!--<div class="st&#45;&#45;mod-asset">-->
    <!--<div class="fpo-div2">-->
    <!--<img-->
    <!--alt="SIGN UP"-->
    <!--src="https://s3.amazonaws.com/diemlife-assets/SUPPORT-F.gif"-->
    <!--/>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="st&#45;&#45;mod-txt">-->
    <!--<h3 class="st&#45;&#45;mod-title">SUPPORT AND SOCIAL MEDIA</h3>-->
    <!--<dl>-->
    <!--<dt>SENDING BACKING</dt>-->
    <!--<dt>-->
    <!--If you want to financially support someone on their Quest®, you-->
    <!--can send them money.-->
    <!--</dt>-->
    <!--<dt>1. On a Quest®, click “Back Me”.</dt>-->
    <!--<dt>2. Choose the amount of money you want to give.dt></dt>-->
    <!--<dt>3. Add a payment method.</dt>-->
    <!--<dt>-->
    <!--4. Enter your password and click “Confirm”. Check your email for a-->
    <!--confirmation.-->
    <!--</dt>-->
    <!--<dt>-->
    <!--*Note: if the Quest® creator cannot receive your payment, then-->
    <!--they did not add a bank account. Please notify the creator.-->
    <!--</dt>-->
    <!--</dl>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <button (click)="scrollTop(500)" class="scroll-btn" type="button"></button>
  </div>
</div>
<!-- #################################### -->
