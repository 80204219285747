// tslint:disable-next-line:max-line-length
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { QuestTask } from 'diemlife-commons/dist/diemlife-commons-model';
import { TaskGroup } from '../dl-milestones.typings';
import * as Actions from './dl-milestones.actions';


export interface State {
  milestones: TaskGroup[];
  selectedMilestoneForPreview: {index: number, isEditMode: boolean};
  newMilestoneForPreview: QuestTask;
}

const initialState: State = {
  milestones: [],
  selectedMilestoneForPreview: null,
  newMilestoneForPreview: null
};

export function dlMilestonesReducer(state = initialState, action: Actions.DlMilestoneActions) {
  switch (action.type) {
    case (Actions.SET_MILESTONES):
      return {
        ...state,
        milestones: action.payload
      };
    case (Actions.ADD_MILESTONE):
      let preparedMilestones;
      if (state.milestones.length > 0) {
        preparedMilestones = [...state.milestones];
        preparedMilestones[0].questTasks.push(action.payload);
      } else {
        preparedMilestones = [{
          id: null,
          name: null,
          questTasks: [action.payload]
        }];
      }
      return {
        ...state,
        milestones: preparedMilestones
      };
    case (Actions.ADD_MILESTONE_IMAGE_URL):
      const milestonesWithNewImageUrl = [...state.milestones];
      milestonesWithNewImageUrl[0].questTasks[action.payload.index].imageUrl = action.payload.value;
      return {
        ...state,
        milestones: milestonesWithNewImageUrl
      };
    case (Actions.ADD_MILESTONE_VIDEO_URL):
      const milestonesWithNewVideoUrl = [...state.milestones];
      milestonesWithNewVideoUrl[0].questTasks[action.payload.index].embeddedVideo = action.payload.value;
      return {
        ...state,
        milestones: milestonesWithNewVideoUrl
      };
    case (Actions.REMOVE_MILESTONE):
      const updatedMilestones = [...state.milestones];
      updatedMilestones[0].questTasks.splice(action.payload, 1);
      return {
        ...state,
        milestones: updatedMilestones
      };
    case (Actions.MOVE_MILESTONE):
      const milestones = [...state.milestones];
      moveItemInArray(milestones[0].questTasks, action.payload.previousIndex, action.payload.currentIndex);
      return {
        ...state,
        milestones: milestones
      };
    case (Actions.UPDATE_MILESTONE_TASK):
      const umtMilestones = [...state.milestones];
      umtMilestones[0].questTasks[action.payload.index].task = action.payload.task;
      return {
        ...state,
        milestones: [...umtMilestones]
      };
    case (Actions.UPDATE_MILESTONE_TASK_COMPLETION):
      const umtcMilestones = [...state.milestones];
      umtcMilestones[0].questTasks[action.payload.index].isTaskCompleted = action.payload.value;
      return {
        ...state,
        milestones: [...umtcMilestones]
      };
    case (Actions.UPDATE_MILESTONE_GROUP_NAME):

      let milestonesWithNewGroupName;
      if (state.milestones.length > 0) {
        milestonesWithNewGroupName = [...state.milestones];
        milestonesWithNewGroupName[0].name = action.payload.value;
      } else {
        milestonesWithNewGroupName = [{
          id: null,
          name: action.payload.value,
          questTasks: []
        }];
      }
      return {
        ...state,
        milestones: [...milestonesWithNewGroupName]
      };
    case (Actions.PATCH_MILESTONE):
      const milestonesWithPatchUpdate = [...state.milestones];
      milestonesWithPatchUpdate[0].questTasks[action.payload.index] = {
        ...milestonesWithPatchUpdate[0].questTasks[action.payload.index],
        ...action.payload.value
      };
      return {
        ...state,
        milestones: [...milestonesWithPatchUpdate]
      };
    case (Actions.SET_MILESTONE_FOR_PREVIEW):
      return {
        ...state,
        selectedMilestoneForPreview: action.payload
      };
    case (Actions.CLEAR_MILESTONE_FOR_PREVIEW):
      return {
        ...state,
        selectedMilestoneForPreview: null
      };
    case (Actions.DL_MILESTONES_CLEAR_DATA):
      return {
        milestones: [],
        selectedMilestoneForPreview: null,
        newMilestoneForPreview: null
      };
    case (Actions.SET_NEW_MILESTONE):
      return {
        ...state,
        newMilestoneForPreview: action.payload
      };
    case (Actions.PATCH_NEW_MILESTONE):
      return {
        ...state,
        newMilestoneForPreview: {
          ...state.newMilestoneForPreview,
          ...action.payload
        },
      };
    case (Actions.REMOVE_NEW_MILESTONE):
      return {
        ...state,
        newMilestoneForPreview: null
      };
    default:
      return state;
  }
}
