import {environment} from '../environments/environment';
import {DropzoneConfigInterface} from "ngx-dropzone-wrapper";

export const password = {
  minLength: 8,
  maxLength: 40
};
export const shareMessage = {
  minLength: 3,
  maxLength: 300
};
export const timeBeforeTokenExpired = 1800000; // 30 min

export const stripeToken = environment.stripeKey;
export const reCaptchaToken = '6Lfodl4UAAAAAEWx0PU-FxQ4wzyZqa-sYITWz4k8';

export const QUILL_CONFIG = {
  toolbar: [
    ['bold', 'italic', {header: 1}, {header: 2}, {list: 'ordered'}, {list: 'bullet'}, 'link', 'video', 'clean']
  ],
  clipboard: {
    matchVisual: false
  },
  counter: null
};

export const DROP_ZONE_IMAGE_CONFIG: DropzoneConfigInterface = {
  url: environment.target + environment.context + '/image/upload',
  paramName: 'imageFile',
  acceptedFiles: 'image/*',
  parallelUploads: 1,
  addRemoveLinks: true,
  uploadMultiple: false,
  withCredentials: false,
  dictCancelUpload: 'Cancel',
  dictRemoveFile: 'Remove'
};

export const DEFAULT_SELECT_COUNTRY = 'US';

export const MILESTONE_TITLE_HINTS = ['Milestones', 'Tasks', 'Days', 'Activities'];

export const API_URL = 'https://api.diem.life/';
