<div class="c-container">
  <form class="c-explore__filter__inner friends-search-filter" [formGroup]="searchForm">
    <div class="c-explore__search">
      <svg class="fa icon icon-search"><use xlink:href="#icon-search"></use></svg>
      <input
        class="c-explore__search__control"
        formControlName="search"
        aria-label="Search for friends"
        placeholder="Search for Friends"/>
    </div>
  </form>
</div>
<div class="c-my-friends__inner h-loader" [ngClass]="{'is-loading': isLoading && results.length === 0}">
  <div class="c-my-friends__content h-loader__caption"
    id="skip-nav-target"
    appLoadmoreTrigger
    [innerEl]="inner"
    [newContentLoaded]="newContentLoaded"
    (triggerLoadMore)="onTriggerLoadMore($event)">
    <div class="c-container c-container_md"
      #inner
      infiniteScroll
      [infiniteScrollDistance]="1.5"
      [infiniteScrollThrottle]="100"
      [immediateCheck]="true"
      [alwaysCallback]="true"
      [infiniteScrollContainer]="'.c-my-friends__content'"
      [fromRoot]="true"
      (scrolled)="loadMore()">
      <app-friends-item
        class="c-my-friends__item"
        *ngFor="let item of results"
        [friendInfo]="item"
        [point]="'globalSearch'">
      </app-friends-item>
      <div class="c-my-friends__empty" *ngIf="results.length === 0 && !isLoading">
        <span>There is no search results</span>
      </div>
      <div class="c-search__more h-xs-loader" *ngIf="loadmoreEnabled" [ngClass]="{'is-loading': results.length > 0 && isLoading}">
        <i class="h-xs-loader__control"></i>
      </div>
    </div>
  </div>
  <div class="h-loader__control is-currentColor"></div>
</div>
