import {Component, forwardRef, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {QuestTeam} from 'diemlife-commons/dist/diemlife-commons-model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-ui-select-team',
  templateUrl: './ui-select-team.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectTeamComponent),
      multi: true
    }
  ]
})
export class UiSelectTeamComponent implements OnChanges, ControlValueAccessor {
  @Input() teamsList: QuestTeam[];
  @Input() emitChanges?: boolean = false;
  @Input() initialTeamId: number;
  @Output() teamSelect = new EventEmitter();
  eventsSubject: Subject<void> = new Subject<void>();
  selectedId: number;
  touched = false;

  propagateChange = (_: any) => {};

  onTouched: any = () => {
    this.touched = true;
  };

  constructor() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!simpleChanges.teamsList) { return; }

    if (simpleChanges.initialTeamId.currentValue && !simpleChanges.initialTeamId.previousValue) {
      this.selectTeam(this.initialTeamId.toString());
    }

    if (simpleChanges.teamsList.previousValue !== simpleChanges.teamsList.currentValue) {
      this.eventsSubject.next();
    }
  }
  regTouch() {
    this.onTouched();
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.selectedId = parseInt(value, 10);
      setTimeout(() => {
        this.eventsSubject.next();
      });
    }
  }
  selectTeam(value: string) {
    this.onTouched();
    this.selectedId = parseInt(value, 10);
    this.propagateChange(this.selectedId);
    if (this.emitChanges) {
      this.teamSelect.emit(this.selectedId);
    }
  }

  buildTeamOption(index: number): string {
    const team = this.teamsList[index];

    return `
      <div class="c-dl-team-control__view">
        <div class="h-object-fit">
          <img alt="" src="${team.teamLogoUrl ? team.teamLogoUrl : '/assets/images/logo-black-white.png'}">
        </div>
        <i class="c-dl-team-control__count">${team.memberIds.length}</i>
      </div>
      <span>${team.teamName}</span>
    `;
  }
  buildEmptyTeamOption() {
    // tslint:disable-next-line: max-line-length
    return '<div class="c-dl-team-control__view is-empty"><img src="/assets/images/svg/SVG/freinds-group.svg"></div><span style="margin-left:30px;">No team</span>';
  }
  buildBlankOption() {
    // tslint:disable-next-line: max-line-length
    return '<div class="c-dl-team-control__view is-empty"><img src="/assets/images/svg/SVG/freinds-group.svg"></div><span style="margin-left:30px; height: 20px"></span>';
  }
}
