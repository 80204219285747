<div class="a-form">
    <div>
        <h5 class="af-title">Create a post</h5>
    </div>
    <div class="af-body">
        <div class="af-profile-avatar">
            <div class="af-avatar-div">
                <a class="c-quest__doer"
                    class="c-quest__doer af-avatar"
                    href="javascript:void(0)"
                    [routerLink]='["/profile", "andrewting"]'>
                    <app-profile-avatar
                        class="avatar"
                        [avatar]="userInfo.profilePictureURL"
                        [isBrand]="userInfo.isUserBrand"
                        [firstName]="userInfo.firstName"
                        [lastName]="userInfo.lastName"
                        [userName]="userInfo.userName">
                    </app-profile-avatar>   
                </a>
            </div>
            <div class="avatar-text">
                <div class="name">{{userInfo.firstName + ' ' + userInfo.lastName}}</div>
                <div class="small-light">{{ timeString }}</div>
            </div>
        </div>  
        <form
            [formGroup]="form"
            (ngSubmit)="onSubmit(form.value)">
            <div class="af-text-area">
                <textarea
                    class="c-forms__input form-input"
                    rows="4"
                    maxlength="2000"
                    name="message"
                    id="message"
                    formControlName="message"
                    placeholder="What do you want to share?"></textarea>
            </div>
            <div class="form-hashtags">
                <span class="hashtag">#</span>
                <textarea class="c-forms__input hashtag-input"
                    rows="1"
                    name="hashtags"
                    id="hashtags"
                    formControlName="hashtags"
                    placeholder="Add tags to help people see your post"></textarea>
            </div>
            <div class="form-buttons">
                <a href="javascript:void(0)" class="af-upload-video">📹 Video</a>
                <a href="javascript:void(0)" class="af-upload-photo">📷 Photos</a>
                <div class="c-forms__group af-post">
                    <button
                      type="submit"
                      class="c-btn c-btn_md c-btn-dark c-btn_full af-button"
                      >
                      Post
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>