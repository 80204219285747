import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from 'src/app/components/pages/account/account.service';
import { QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.styl']
})
export class ActivityCardComponent implements OnInit {
  @Input() questId?: number;
  @Input() userId: number;
  @Input() imgUrl: string;
  @Input() hasAnnouncement: boolean;
  @Input() announcementText;
  @Input() postText;
  @Input() hashtags: string[];
  @Input() cheers: number;
  activeTab: number = 0;
  firstName: string = "Andrew";
  lastName: string = "Ting";
  location: string = "New York";
  timePosted = Date();
  timeString: string;
  userInfo: QuestUserInfo;

  constructor(    
    private accountService: AccountService
    ) { 
    
  }

  ngOnInit() {
    this.timeString = this.timePosted.substring(0, this.timePosted.length - "GMT-0400 (Eastern Daylight Time)".length);
    this.accountService.getUserInfo().subscribe((userInfo: QuestUserInfo) => {
      this.userInfo = userInfo;
    });
  }

  getBorderTop() {
    return !this.postText && this.hasAnnouncement && this.announcementText ? '1px solid #BEC1C1' : 'none';
  }

  getCheers() {
    return this.cheers == 1 ? this.cheers + ' person' : this.cheers + ' people';
  }

}
