<div class="c-my-friends__inner h-loader" [class.is-loading]="isLoading">
  <div class="c-my-friends__content c-container c-container_md h-loader__inner" id="skip-nav-target">
    <div class="c-my-friends__row" *ngIf="!isLoading && friendsNeededAccept.length > 0">
      <div class="c-my-friends__row__body">
        <app-friends-item
          class="c-my-friends__item"
          *ngFor="let item of friendsNeededAccept; let i = index"
          (removeItem)="removeItemFromList('friendsNeededAccept', i)"
          [friendInfo]="item"
          [point]="'friendsNeededAccept'">
        </app-friends-item>
      </div>
    </div>
    <div class="c-my-friends__row" *ngIf="!isLoading && friendsNoAction.length > 0">
      <div class="c-my-friends__row__body">
        <app-friends-item
          class="c-my-friends__item"
          *ngFor="let item of friendsNoAction; let i = index"
          (removeItem)="removeItemFromList('friendsNoAction', i)"
          [friendInfo]="item"
          [point]="'friendsNoAction'">
        </app-friends-item>
      </div>
    </div>
    <div class="c-my-quests__empty c-my-friends__empty"
      *ngIf="!isLoading && friendsNoAction.length === 0 && friendsNeededAccept.length === 0">
      <svg class="c-my-quests__empty__icon-friends-list icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
      <div class="c-my-quests__empty__caption">
        <span>Find your friends <a routerLink="search">here</a></span>
      </div>
    </div>
  </div>
  <div class="h-loader__control"></div>
</div>
