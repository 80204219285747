import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ReaquestHeadersService} from '../../../_services/requestHeaders.service';
import {MyFriendsInfo, SearchParams, SearchResponse} from 'diemlife-commons/dist/diemlife-commons-model';

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  constructor(
    private http: HttpClient,
    private requestHeadersService: ReaquestHeadersService,
  ) {
  }

  getSearchResults(searchData: SearchParams): Observable<SearchResponse> {
    const headers = this.requestHeadersService.getHeaders();
    return this.http.get<SearchResponse>(
      environment.target + environment.context + `/search/${searchData.type}`,
      {
        headers: headers,
        params: {
          q: searchData.q ? searchData.q : '',
          _start: String(searchData._start),
          _limit: String(searchData._limit)
        }
      }
    );
  }

  getFriendsMapping(): Observable<MyFriendsInfo> {
    return this.http.get<MyFriendsInfo>(
      environment.target + environment.context + `/search/friends`,
      {
        headers: this.requestHeadersService.getHeaders()
      }
    );
  }

}
