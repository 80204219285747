import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

import * as UserFriendsActions from './profile-friends.actions';
import * as fromUserFriends from './profile-friends.reducer';
import { ReaquestHeadersService } from '../../../../_services/requestHeaders.service';

@Injectable()
export class UserFriendsEffects {

  @Effect()
  profileUserFriends = this.actions$
    .pipe(
      ofType(UserFriendsActions.TRY_GET_USER_FRIENDS),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + '/getcurrentfriends',
          { headers: headers}
        );
      }),
      mergeMap((data: fromUserFriends.State[]) => {
        return [
          {
            type: UserFriendsActions.GET_USER_FRIENDS,
            payload: data
          }
        ];
      })
    );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}
}
