import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';
import {SnotifyService} from 'ng-snotify';
import {forkJoin, Observable, Subscription, Subject} from 'rxjs';
import {ModalService} from '../../../main/modal/modal.service';
import {WindowRef} from '../../../../_global/window-ref.module';
import {PaymentsService} from '../../../main/payments/payments.service';
import {stripeToken} from '../../../../app.config';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';
import * as fromApp from '../../../../_store/app.reducers';
import * as fromPayments from '../../../main/payments/store/payments.reducer';
import * as PaymentsActions from '../../../main/payments/store/payments.actions';
import * as Constants from '../../../../app.constants';

@Component({
  selector: 'app-bank-account-control-dialog',
  templateUrl: './bank-account-control-dialog.component.html',
  styleUrls: ['./bank-account-control-dialog.component.styl']
})
export class BankAccountControlDialogComponent implements OnInit, OnDestroy {
  eventsSubject: Subject<void> = new Subject<void>();
  userInfoSubscriber: Subscription;
  stripe: any;
  bankAccountForm: FormGroup;
  messages = Constants.VALIDATION_MESSAGES;
  formSubmitted = false;
  openedModal = false;

  constructor(
    private winRef: WindowRef,
    private store: Store<fromApp.AppState>,
    private modalService: ModalService,
    private paymentsService: PaymentsService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.bankAccountForm = this.fb.group({
      'routingNumber': ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      'accountNumber': ['', [Validators.required, Validators.min(10)]],
      'accountHolderName': ['', [Validators.required]],
      'accountHolderType': ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    if (this.userInfoSubscriber) {
      this.userInfoSubscriber.unsubscribe();
    }
  }

  stripeBankAccountTokenFactory(): Observable<any> {
    const StripePlugin = this.winRef.nativeWindow.Stripe;
    const stripe = StripePlugin(stripeToken); // use your test publishable key
    return new Observable(observer => {
      const bankAccountData = {
        country: 'US',
        currency: 'usd',
        routing_number: this.bankAccountForm.value.routingNumber,
        account_number: this.bankAccountForm.value.accountNumber,
        account_holder_name: this.bankAccountForm.value.accountHolderName,
        account_holder_type: this.bankAccountForm.value.accountHolderType
      };
      console.log(bankAccountData);
      stripe.createToken('bank_account', bankAccountData).then(res => {
        observer.next(res);
        observer.complete();
      });
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  openModalBankAccountModal() {
    this.openedModal = true;
    setTimeout(() => {
      this.openModal('bank-account-modal');
      this.eventsSubject.next();
    });
  }

  onCloseModal() {
    this.openedModal = false;
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  saveBankAccounts() {
    this.formSubmitted = true;
    if (this.bankAccountForm.invalid) {
      return false;
    }

    forkJoin([
      this.stripeBankAccountTokenFactory(),
      this.stripeBankAccountTokenFactory()
    ]).subscribe(([
                    payoutsTokenResponse,
                    paymentsTokenResponse
                  ]) => {
      if (payoutsTokenResponse.error) {
        this.snotifyService.error(payoutsTokenResponse.error.message);
      } else if (paymentsTokenResponse.error) {
        this.snotifyService.error(paymentsTokenResponse.error.message);
      } else {
        forkJoin([
          this.paymentsService.saveBankAccount('payouts', payoutsTokenResponse.token.id),
          this.paymentsService.saveBankAccount('payments', paymentsTokenResponse.token.id)
        ]).subscribe(([
                        payoutsAccount,
                        paymentsAccount
                      ]) => {
          if (payoutsAccount && paymentsAccount) {
            const bankAccount: fromPayments.BankAccount = {
              id: paymentsAccount.id,
              name: paymentsAccount.name,
              verified: paymentsAccount.verified,
              customer: paymentsAccount.customer,
            };
            this.store.dispatch(new PaymentsActions.AddBankAccount(bankAccount));
            this.closeModal('bank-account-modal');
            this.snotifyService.success(
              // tslint:disable-next-line:max-line-length
              'We will make two micro-deposits to your account within 1-2 business days. Please verify them to activate this payment method',
              'Thank you for linking your bank account!',
              this.snotifyConfigService.getConfig({
                timeout: 10000
              })
            );
          } else {
            this.snotifyService.error(
              'Oops, something went wrong, please try again.',
              this.snotifyConfigService.getConfig({
                timeout: 10000,
                buttons: [
                  {text: 'Ok', action: (toast) => {this.snotifyService.remove(toast.id); }}
                ]
              })
            );
          }
        });
      }
    });
  }
}
