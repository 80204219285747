import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/_store/app.reducers';
import { MainDlNavService, MAIN_NAV_ACTIONS } from './main-dl-nav.service';
import { MainDlNavStructure } from './main-dl-nav.types';
import { Subject, fromEvent } from 'rxjs';
import { distinctUntilKeyChanged, map, tap, startWith, takeUntil, distinctUntilChanged, debounceTime, takeWhile, filter } from 'rxjs/operators';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import { TryLogout, TRY_LOGOUT } from '../auth/store/auth.actions';
import { Router } from '@angular/router';
import { SelectedSearchResult } from 'src/app/modules/shared-module/components/dl-search/dl-search.component';
import { ShowNotifications } from '../notifications/store/notifications.actions';
import { ShowContact } from '../contact-us/store/contact-us.actions';

@Component({
  selector: 'app-main-dl-nav',
  templateUrl: './main-dl-nav.component.html',
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'c-dl-main-nav__inner' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainDlNavComponent implements OnInit, OnDestroy {
  structure: MainDlNavStructure;
  authenticated: boolean;
  userInfo: fromProfile.State;
  isScreenLarge: boolean;
  searchIsOpened = false;
  notificationsCount = 0;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private modalService: ModalService,
    private mainDlNavService: MainDlNavService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    // * get info about navigation links and other stuff
    this.structure = this.mainDlNavService.getStructure();

    // * get authentication info
    this.store
      .pipe(
        select('auth'),
        distinctUntilKeyChanged('authenticated'),
        map((state: fromAuth.State) => {
          return state.authenticated;
        }),
        takeUntil(this.destroyed$),
        tap(authenticated => {
          this.authenticated = authenticated;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    // * get user info
    this.store
      .pipe(
        select('userInfo'),
        distinctUntilKeyChanged('id'),
        takeUntil(this.destroyed$),
        filter(user => user.id > 0),
        tap((state: fromProfile.State) => {
          this.userInfo = state;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    // * get user info
    this.store
      .pipe(
        select('notifications'),
        distinctUntilKeyChanged('count'),
        takeUntil(this.destroyed$),
        map((state) => state.count),
        tap(count => {
          this.notificationsCount = count;
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    // * Checks if screen size is bigger than 768 pixels
    this.initScreenWidthCheck();
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }
  doAction(action: string) {
    this.closeModal();
    switch (action) {
      case TRY_LOGOUT:
        this.store.dispatch(new TryLogout());
        break;
      case MAIN_NAV_ACTIONS.OPEN_MODAL_AUTH:
        this.modalService.open('auth-modal');
        break;
      case MAIN_NAV_ACTIONS.OPEN_MODAL_NOTIFICATIONS:
        this.store.dispatch(new ShowNotifications());
        this.modalService.open('notifications-modal');
        break;
      case MAIN_NAV_ACTIONS.OPEN_MODAL_CONTACT:
        this.store.dispatch(new ShowContact());
        this.modalService.open('contact-modal');
        break;
    }
  }
  closeModal() {
    this.modalService.close('main-nav-modal');
    this.cdr.detectChanges();
  }
  navigateFromSearch(res: SelectedSearchResult) {
    this.searchIsOpened = false;
    this.cdr.detectChanges();

    if (res.preparedUrl) {
      this.router.navigate(res.preparedUrl);
    }

    this.closeModal();
  }
  onSearchIsOpened(val: boolean) {
    this.searchIsOpened = val;
  }
  private initScreenWidthCheck() {
    const checkScreenSize = () => window.innerWidth >= 768;
    fromEvent(window, 'resize')
      .pipe(
        startWith(checkScreenSize()),
        debounceTime(500),
        map(checkScreenSize),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        tap((isScreenLarge) => {
          this.isScreenLarge = isScreenLarge;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

}
