import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FundraisingLinkType, QuestDoer, QuestTeam} from 'diemlife-commons/dist/diemlife-commons-model';
import { updateArrayBindingPattern } from 'typescript';

@Component({
  selector: 'app-quest-donate',
  templateUrl: './quest-donate.component.html',
  styleUrls: ['./quest-donate.component.styl']
})
export class QuestDonateComponent implements OnInit {
  @Output() showStartFundraisingModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() showEditFundraisingModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() private questId: number;
  @Input() private userId: number;
  @Input() data: FundraisingLinkType;
  @Input() teams: QuestTeam[];
  @Input() doers: QuestDoer[]; //FYI this will be null bc I moved getMembers() into members.component.ts instead of quest-dtail.component.ts
  @Input() userNonProfit?: boolean;
  @Input() requiresBackingConfirmation?: boolean;
  @Input() isProgress?: boolean;
  @Input() includeFundraiseButton = false;
  @Input() disableFundraiseButton = false;
  @Input() enableFundraiseEditLink = false;
  @Input() enableBackingButton = false;
  @Input() enableJoinTeamButton: boolean;
  @Input() enableDetailedTotals: boolean;
  @Input() isMainQuest: boolean;
  openedDialog: string;
  isLoaded = false;
  raisedByTeamMembers = 0;
  raisedByDirectDonations = 0;
  fundraisingLink: FundraisingLinkType;

  constructor(private router: Router) {
  }

  ngOnInit() {
    // The following if statement is a hack for user who messed up her donation. Delete later
    if (this.questId == 943 && this.userId == 66350) {
      this.data.currentAmount += 900 * 100;
      this.data.timesBacked += 1;
    }
    this.raisedByTeamMembers = this.teams.map(team => team.amountBacked).reduce((acc, val) => acc + val, 0);
    this.raisedByDirectDonations = this.data.currentAmount - this.raisedByTeamMembers;
    this.isLoaded = true;
  }

  navigateToBacking() {
    this.router.navigate(['/donate', this.questId, this.userId]);
  }

  triggerConfirmation(mode: string) {
    this.showStartFundraisingModal.emit(mode);
  }

  triggerEdit() {
    this.showEditFundraisingModal.emit();
  }

  checkFundraisingEndDateIsInFuture(): boolean {
    if (this.data && !this.data.endDate) {
      return true;
    }
    return (this.data && this.data.endDate) > Date.now();
  }

  doNothing(): void {
  }

  getQuestId() {
    return this.questId;
  }

  getUserId() {
    return this.userId;
  }

}
