import { Component, OnInit } from '@angular/core';
import { FriendsService } from '../friends.service';
import { forkJoin } from 'rxjs';
import * as fromProfileFriends from './../../../separate/profile-friends/store/profile-friends.reducer';


@Component({
  selector: 'app-friends-requests',
  templateUrl: './friends-requests.component.html',
  styleUrls: ['./friends-requests.component.styl']
})
export class FriendsRequestsComponent implements OnInit {
  isLoading = true;
  friendsNeededAccept: fromProfileFriends.State[] = [];
  friendsNoAction: fromProfileFriends.State[] = [];

  constructor(private friendsService: FriendsService) {}

  ngOnInit() {
    forkJoin([
      this.friendsService.getPendingFriendsToAccept(),
      this.friendsService.getPendingFriendsNoAction()
    ]).subscribe(([friendsNeededAccept, friendsNoAction]) => {
      this.friendsNeededAccept = friendsNeededAccept;
      this.friendsNoAction = friendsNoAction;
      this.isLoading = false;
    });
  }

  removeItemFromList(point: string, i: number) {
    this[point].splice(i, 1);
  }

}
