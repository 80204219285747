import {Component, OnInit, OnDestroy, ElementRef} from '@angular/core';
import {FundraisingLinkType, QuestUserInfo, Quest} from 'diemlife-commons/dist/diemlife-commons-model';
import {NavigationEnd, ActivatedRoute, Router} from '@angular/router';
import {Subscription, Observable} from 'rxjs';
import {WindowRef} from 'src/app/_global/window-ref.module';
import {take} from 'rxjs/operators';
import {QuestService} from 'src/app/_services/quest.service';
// import {Quest, QuestUserInfo} from '../../main/quest/quest.type';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/_store/app.reducers';
import * as fromAuth from '../../main/auth/store/auth.reducer';
import * as AuthActions from './../../main/auth/store/auth.actions';
import * as fromProfile from '../profile/store/profile.reducer';
import * as ProfileActions from './../profile/store/profile.actions';
import { AuthService } from '../../main/auth/auth.service';

declare var $: any;

@Component({
  selector: 'app-quest-detail-description',
  templateUrl: './quest-detail-description.component.html',
  styleUrls: ['./quest-detail-description.component.styl']
})
export class QuestDetailDescriptionComponent implements OnInit, OnDestroy {
  authState: Observable<fromAuth.State>;
  routerEventsSubscription: Subscription;
  authSubscription: Subscription;
  fundraisingLink: FundraisingLinkType = null;
  currentUser: QuestUserInfo;
  data: Quest;
  viewStatus: number;
  questId: number;
  userId: number;
  isLoaded = false;
  jqueryLinks: any;
  constructor(
    private winRef: WindowRef,
    private elRef: ElementRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private questService: QuestService
  ) {
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    const vm = this;
    this.winRef.nativeWindow.nsWebViewInterface.emit('QuestDescriptionInited', null);
    this.winRef.nativeWindow.loginInsideWebView = function(authData: fromAuth.State, userData: fromProfile.State) {
      if (authData && userData) {
        vm.store.dispatch(new AuthActions.SetToken({token: authData.token, tokenUpdateTime: authData.tokenUpdateTime}));
        vm.store.dispatch(new AuthActions.SetExpires(authData.expires));
        vm.store.dispatch(new ProfileActions.GetUserInfo(userData));
        vm.store.dispatch(new AuthActions.Signin());
        setTimeout(() => {
          vm.authService.tryStartAutoRefreshTokenTimer(authData);
        }, 0);
        return true;
      } else {
        return false;
      }
    };
    this.getQuestInfo();
    this.routerEventsSubscription = this.router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        this.getQuestInfo();
      }
    });
    // only used for p
    this.authSubscription = this.authState.subscribe((state: fromAuth.State) => {
      if (this.viewStatus && state.authenticated) {
        this.viewStatus = null;
        this.getQuestInfo();
      }
    });
  }
  initLinksClicker() {
    const that = this;
    this.jqueryLinks = $(this.elRef.nativeElement).find('a');
    this.jqueryLinks.on('click.questDescription', function (event) {
      event.preventDefault();
      const href = $(this).attr('href');
      if (href) {
        that.winRef.nativeWindow.nsWebViewInterface.emit('LinkOutside', href);
      }
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.jqueryLinks && this.jqueryLinks.length > 0) {
      this.jqueryLinks.off('click.questDescription');
    }
  }

  getQuestInfo() {
    this.isLoaded = false;
    this.fundraisingLink = null;
    this.route.params.pipe(take(1)).subscribe(params => {
      this.questId = Number(params.questId);
      this.userId = Number(params.userId);
      this.questService.getQuestDetail(this.questId, this.userId).subscribe((quest: Quest) => {
        this.data = quest;
        if (this.data.quest.fundraising) {
          this.initFundraising();
        } else {
          this.isLoaded = true;
          setTimeout(() => {
            this.initLinksClicker();
          });
        }
      });
    });
  }

  initFundraising() {
    this.questService.getFundraisingLink(this.questId, this.userId).subscribe((fundraisingLink) => {
      this.fundraisingLink = fundraisingLink;
      this.isLoaded = true;
    }, (error) => {
      console.log('Unable to get fundraising link', error);
      this.isLoaded = true;
      setTimeout(() => {
        this.initLinksClicker();
      });
    });
  }

  isQuestInTeamMode() {
    return this.data && this.data.quest && this.data.quest.mode === 'diyMode';
  }

}
