import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../modal/modal.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';
import {QuestService} from '../../../../_services/quest.service';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';
import {QuestDetailState, SetQuestId, QuestUserInfo} from 'diemlife-commons/dist/diemlife-commons-model';
import * as fromApp from '../../../../_store/app.reducers';
import * as fromAuth from '../../../main/auth/store/auth.reducer';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
// import {QuestUserInfo} from '../quest.type';
import {MyQuestsSevice} from 'src/app/components/pages/my-quests/my-quests.service';

@Component({
  selector: 'app-quest-dropdown',
  templateUrl: './quest-dropdown.component.html',
  styleUrls: ['./quest-dropdown.component.styl']
})
export class QuestDropdownComponent implements OnInit {
  @Input() questInfo: QuestDropdownContext;
  @Input() doerInfo: QuestUserInfo;
  @Input() showStartTrigger?: boolean;
  @Input() showSupportTrigger?: boolean;
  @Input() exploreQuestIndex?: number;
  questState: Observable<QuestDetailState>;
  authState: Observable<fromAuth.State>;
  profileState: Observable<fromProfile.State>;
  isOpen = false;
  openedModal = '';
  status: string;
  privacyLevel: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    private questService: QuestService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private myQuestsSevice: MyQuestsSevice
  ) {
    this.questState = this.store.select('quest');
    this.authState = this.store.select('auth');
    this.profileState = this.store.select('userInfo');
  }

  ngOnInit() {
    if (Boolean(this.questInfo.privacyLevel)) {
      this.privacyLevel = this.questInfo.privacyLevel;
    }
    if (Boolean(this.route.snapshot.data[0])) {
      this.status = this.route.snapshot.data[0].status;
    } else {
      this.status = 'public';
    }
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.store.dispatch(new SetQuestId(this.questInfo.id));
  }

  closeDropdown() {
    this.isOpen = false;
  }

  openModal(id: string) {
    this.openedModal = id;
    setTimeout(() => {
      this.modalService.open(id);
    });
  }

  closeModal() {
    this.openedModal = '';
  }

  copyQuest() {
    this.authState.pipe(take(1)).subscribe((state: fromAuth.State) => {
      if (!state.authenticated) {
        this.snotifyService.error('Please login to DIEMlife to save this quest', null, this.snotifyConfigService.getConfig());
        return false;
      }
      this.questService.copyQuest(this.questInfo.id).subscribe((res: any) => {
        this.snotifyService.success('Quest "' + this.questInfo.title + '" has been copied successfully!',
          null, this.snotifyConfigService.getConfig());
          this.router.navigate(['/edit', res]);
      }, (err: any) => {
        this.snotifyService.error('Oops, something went wrong', null, this.snotifyConfigService.getConfig());
      });
    });
  }
  doQuest() {
    this.questService.callUpdateForQuest({status: 'doQuest', index: this.exploreQuestIndex});
    this.closeDropdown();
  }
  supportQuest() {
    this.questService.callUpdateForQuest({status: 'supportQuest', index: this.exploreQuestIndex});
    this.closeDropdown();
  }
  saveQuest() {
    this.authState.pipe(take(1)).subscribe((state: fromAuth.State) => {
      if (!state.authenticated) {
        this.snotifyService.error('Please login to DIEMlife to save this quest', null, this.snotifyConfigService.getConfig());
        return false;
      }
      this.questService.saveQuest(this.questInfo.id).subscribe((res: any) => {
        if (res.error) {
          this.snotifyService.error(res.error.message, null, this.snotifyConfigService.getConfig());
        }
        if (res.success) {
          this.questInfo.saved = true;
          this.profileState.pipe(take(1)).subscribe((fromProfileState: fromProfile.State) => {

            this.questService.callUpdateForQuest('saved');

            this.snotifyService.success(
              `'${this.questInfo.title}' to ${fromProfileState.firstName} is now saved in your Quests.`,
              null,
              this.snotifyConfigService.getConfig()
            );
          });
        }
      });
      this.closeDropdown();
    });
  }

  unsaveQuest() {
    this.snotifyService.confirm(
      `Remove saved ${this.questInfo.title} quest?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.myQuestsSevice.unsaveQuest(this.questInfo.id).subscribe(() => {
                this.questInfo.saved = false;
                this.snotifyService.info(`Successfully removed saved Quest '${this.questInfo.title}'`,
                  null, this.snotifyConfigService.getConfig());
              });
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  followQuest() {
    this.questService.followQuest(this.questInfo.id, true).subscribe(() => {
      this.questInfo.followed = true;
      this.snotifyService.success(`You are now following '${this.questInfo.title}'`, null, this.snotifyConfigService.getConfig());
    }, () => {
      this.snotifyService.warning('Failed to follow Quest', null, this.snotifyConfigService.getConfig());
    });
    this.closeDropdown();
  }

  unFollowQuest() {
    this.questService.followQuest(this.questInfo.id, false).subscribe(() => {
      this.questInfo.followed = false;
      this.snotifyService.info(`Stopped following '${this.questInfo.title}'`, null, this.snotifyConfigService.getConfig());
    }, () => {
      this.snotifyService.warning('Failed to follow Quest', null, this.snotifyConfigService.getConfig());
    });
    this.closeDropdown();
  }

  cancelQuest() {
    this.snotifyService.confirm(
      `Are you sure you want to remove '${this.questInfo.title}'?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.questService.cancelQuest(this.questInfo.id).subscribe(res => {
                this.snotifyService.success(`You removed '${this.questInfo.title}'`,
                  null, this.snotifyConfigService.getConfig());
                this.router.navigate(['/my-quests']);
              });
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

}

export interface QuestDropdownContext {
  id: number;
  title: string;
  shareUrl: string;
  copyable: boolean;
  followed: boolean;
  saved: boolean;
  active: boolean;
  editable?: boolean;
  starred?: boolean;
  privacyLevel?: string;
  mode?: string;
}
