<div class="c-forms__group is-flexed">
  <div class="e-toggle__wrap no-marg-bottom">
    <label class="e-toggle" >
      <input type="checkbox" [(ngModel)]="fundraisingActivated" (change)="onChange()" [disabled]="!hasValidStripeAccount" name="fundraise" class="e-toggle__input">
      <span class="e-toggle__label"></span>
    </label>
    <span class="e-toggle__caption">{{fundraisingActivated ? ('Enabled (' + (fundraisingInput.targetAmount / 100 | number) + ' ' + (fundraisingInput.currency | uppercase) + ')') : 'Disabled'}}</span>
  </div>
  <i *ngIf="!hasValidStripeAccount" class="e-question" placement="top-center" ngbTooltip="Please activate payouts from your account page before starting a fundraiser" tooltipClass="c-dl-tooltip"></i>
</div>
<app-modal *ngIf="openedDialog === 'fund_modal'" [ngStyle]="{'display': 'block'}" (closeModal)="closeDialog()" id="fund_modal">
  <h3 class="modal-title">Start fundraising</h3>
  <form [formGroup]="fundForm" class="modal-body c-modal-funds-info" (ngSubmit)="enableFundraising()">
    <div class="c-forms__group">
      <label for="targetAmountControl" class="c-forms__legend">Target amount</label>
      <div class="c-forms__control">
        <input type="hidden" formControlName="currency">
        <input class="c-forms__input" id="targetAmountControl" formControlName="amount" prefix="$" mask="0000000" [validation]="false" type="text">
      </div>
    </div>
    <div class="c-forms__group">
      <label for="disclaimerControl" class="c-forms__legend">Disclaimer</label>
      <div class="c-forms__control is-textarea">
        <textarea id="disclaimerControl" class="c-forms__input" name="disclaimer" cols="30" rows="5" readonly>By clicking "Start", you are allowing others to start a fundraiser for this Quest to help you raise money. Donations they receive will go directly to your Quest.</textarea>
      </div>
    </div>
    <div class="c-forms__group">
      <label role="checkbox" class="e-checkbox">
        <input aria-label="Do you agree to the waiver?" formControlName="termsAccepted" type="checkbox"/>
        <span class="e-checkbox__icon">
          <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
        </span>
        <span class="e-checkbox__caption">I agree with the above disclaimer.</span>
      </label>
    </div>
    <div *ngIf="fundForm.controls['termsAccepted'].invalid && submitted" class="c-forms__message is-red">You have to agree with the disclaimer</div>
    <div class="modal__confirm">
      <div class="modal__confirm__group">
        <div class="modal__confirm__col">
          <button class="c-btn c-btn_md c-btn_dark c-btn_full" (click)="closeDialog()" type="button">
            <span>Cancel</span>
          </button>
        </div>
        <div class="modal__confirm__col">
          <button class="c-btn c-btn_md c-btn_dark-yellow c-btn_full" [disabled]="fundForm.invalid" type="submit">
            <span>Start</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</app-modal>
