<article class="c-thumbnail">
    <div class="c-thumbnail__image h-object-fit">
        <div class="c-thumbnail__image__bg">
          <picture class="h-object-fit">
            <source *ngIf="photo.webp" [srcset]="photo.webp" type="image/webp">
            <img [alt]="quest.title" [src]="photo.jpg">
          </picture>
        </div>
    </div>
    <div class="c-thumbnail__inner">
        <div class="c-thumbnail__header">
            <div class="c-thumbnail__interactions">
                <div class="c-thumbnail__interactions__item">
                    <svg class="icon icon-eye">
                        <use xlink:href="#icon-eye"></use>
                    </svg>
                    <span>{{ quest.views }}</span>
                </div>
            </div>
            <app-quest-dropdown class="c-thumbnail__dropdown" [questInfo]="dropdownContext" [doerInfo]="quest.user"
                [showStartTrigger]="quest.mode !== 'viewOnlyMode' && !quest.hasEvent && !quest.isUserDoing && !quest.startBtnDisabled"
                [showSupportTrigger]="quest.mode === 'viewOnlyMode' && !quest.hasEvent && !quest.isUserDoing"
                [exploreQuestIndex]="index">
                <svg class="icon icon-dots"><use xlink:href="#icon-dots"></use></svg>
            </app-quest-dropdown>
        </div>
        <div class="c-thumbnail__caption">
            <strong class="c-thumbnail__category" [ngSwitch]="quest.category">
                <div class="c-thumbnail__category__inner">
                    <svg *ngSwitchCase="'PHYSICAL'" class="icon icon-physical"><use xlink:href="#icon-physical"></use></svg>
                    <svg *ngSwitchCase="'ENVIRONMENTAL'" class="icon icon-environmental"><use xlink:href="#icon-environmental"></use></svg>
                    <svg *ngSwitchCase="'OCCUPATIONAL'" class="icon icon-occupational"><use xlink:href="#icon-occupational"></use></svg>
                    <svg *ngSwitchCase="'MENTAL'" class="icon icon-mental"><use xlink:href="#icon-mental"></use></svg>
                    <svg *ngSwitchCase="'SOCIAL'" class="icon icon-friends"><use xlink:href="#icon-friends"></use></svg>
                    <svg *ngSwitchCase="'FINANCIAL'" class="icon icon-piggybank"><use xlink:href="#icon-piggybank"></use></svg>
                    <!-- <span>{{ quest.category }}</span> -->
                </div>
            </strong>
            <h3 class="c-thumbnail__title"><a href="#" tabindex="-1">{{ quest.title }}</a></h3>
            <div class="c-thumbnail__description">
                <div *ngIf="!stringStyle" class="c-thumbnail__description__inner h-typography" [innerHTML]="text | safe: 'html'"></div>
                <div *ngIf="stringStyle" class="c-thumbnail__description__inner h-typography">
                  <p style="white-space: pre-line;">{{ text }}</p>
                </div>
            </div>
            <div class="c-thumbnail__footer">
                <div class="c-thumbnail__progress">
                  <span [ngStyle]="{'width': quest.completionPercentage + '%'}"></span>
                </div>
                <div class="c-thumbnail__doer" [ngStyle]="{'max-width': quest.isUserDoing ? null : '100%'}">
                    <app-quest-profile
                        *ngIf="quest"
                        [questInfo]="quest"
                        [doerInfo]="doerInfo ? doerInfo : quest.user"
                        class="c-quest__view__full"
                        tabindex="-1">
                    </app-quest-profile>
                </div>
                <!-- Event -->
                <!-- <a *ngIf="quest.hasEvent && !quest.isUserDoing"
                    href="javascript:void(null)"
                    (appAuthGuard)="navigateToTicketFlow()"
                    class="c-thumbnail__trigger c-btn c-btn_ms c-btn_yellow"
                    tabindex="-1">
                    <span>Register</span>
                </a> -->
                <!-- Support -->
                <!-- <a *ngIf="quest.mode === 'viewOnlyMode' && !quest.hasEvent && !quest.isUserDoing"
                    (appAuthGuard)="confirmSupportQuest()"
                    href="javascript:void(null)"
                    class="c-thumbnail__trigger c-btn c-btn_ms c-btn_yellow"
                    tabindex="-1">
                    <span>Support</span>
                </a> -->
                <!-- Start -->
                <!-- <a *ngIf="quest.mode !== 'viewOnlyMode' && !quest.hasEvent && !quest.isUserDoing && !quest.startBtnDisabled"
                    (appAuthGuard)="confirmDoQuest(quest.mode)"
                    href="javascript:void(null)"
                    class="c-thumbnail__trigger e-circle-btn is-yellow is-md"
                    tabindex="-1"
                    [ngSwitch]="quest.mode">
                    <span *ngSwitchCase="'diyMode'">Join</span>
                    <span *ngSwitchDefault>Start</span>
                </a> -->

                <!-- Status -->
                <span *ngIf="quest.activityMode !== 'viewOnlyMode' && quest.isUserDoing"
                      class="c-thumbnail__trigger c-thumbnail__status">
                  <span>Doing</span>
                </span>
                <span *ngIf="quest.activityMode === 'viewOnlyMode' && quest.isUserDoing"
                      class="c-thumbnail__trigger">
                  <span>Supporting</span>
                </span>
            </div>
        </div>
    </div>
  <a
    class="c-thumbnail__link"
    [routerLink]="getLinkToQuestDetail()"
    >More details...</a>
</article>
