<!-- #################################### -->
<div class="bubble-cont">
  <div class="st--top-mod">
    <h1 class="st--top-mod-title">Frequently Asked Questions</h1>
    <h3 class="st--top-mod-subtitle">WE'RE HERE TO HELP</h3>
  </div>

  <div
    (stickyChange)="onStickyChange($event)"
    [class.sticky]="stickyNav"
    [ngStyle]="{ height: navPlaceholderHeight }"
    class="st--nav "
    id="sticko"
  >
    <div class="st--nav-outer d-fixed-nav-inner">
      <ul class="st--nav-inner">
        <li><a href="/faq#lnk1">What is DIEMlife?</a></li>
        <li><a href="/faq#lnk2">What is a Quest®?</a></li>
        <li><a href="/faq#lnk3">What do I do with a Quest®?</a></li>
        <li>
          <a href="/faq#lnk4"
            >I bought an event ticket through DIEMlife®. What happens next?</a
          >
        </li>
        <li>
          <a href="/faq#lnk5"
            >I'm having trouble buying an event ticket through DIEMlife. Can you
            help?</a
          >
        </li>
        <li><a href="/faq#lnk6">How are my Quests® organized?</a></li>
        <li>
          <a href="/faq#lnk7"
            >I just clicked Start on my Saved Quest®. Where did it go?</a
          >
        </li>
        <li>
          <a href="/faq#lnk8"
            >I completed all my Milestones but it didn't move to Done. How can I
            move it over?</a
          >
        </li>
        <li>
          <a href="/faq#lnk9"
            >Can I invite friends to use DIEMlife® and join me on a Quest®?</a
          >
        </li>
        <li>
          <a href="/faq#lnk10"
            >Can I get notifications on my or friend's Quests®?</a
          >
        </li>
        <li>
          <a href="/faq#lnk11">How do I manage my privacy in DIEMlife?</a>
        </li>
        <li>
          <a href="/faq#lnk12">How can I report inappropriate content?</a>
        </li>
        <li>
          <a href="/faq#lnk13">I have more questions, where can I get them answered?</a>
        </li>
        <li>
          <a href="/faq#lnk14"
            >How do I receive money for my Quests in DIEMlife?</a
          >
        </li>
        <li><a href="/faq#lnk15">How do I give money in DIEMlife?</a></li>
        <li>
          <a href="/faq#lnk16"
            >What if I receive Money but can’t finish my Quest®?</a
          >
        </li>
        <li><a href="/faq#lnk17">What are the fees?</a></li>
        <li><a href="/faq#lnk18">Can I get a refund?</a></li>
        <li>
          <a href="/faq#lnk19"
            >How does this work for taxes if I give or receive money?</a
          >
        </li>
        <li>
          <a href="/faq#lnk20"
            >Why do people financially back each other's Quests®?</a
          >
        </li>
        <li><a href="/faq#lnk21">What do backers get in return?</a></li>
        <li>
          <a href="/faq#lnk22"
            >How do I know if the person and their Quest® is genuine?</a
          >
        </li>
      </ul>
    </div>
    <div id="lnk1"></div>
  </div>

  <div class="st--bottom">
    <div class="st--mod clearfix">
      <!-- <div class="st--mod-asset">
        <div class="fpo-div2"><img alt="What is DIEMlife"
                                   src="https://s3.amazonaws.com/diemlife-assets/what-is-diemlife.jpg"></div>
      </div> -->
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">What is DIEMlife?</h3>
        <p>
          DIEMlife® is an online platform driving real world action. Our mission
          is to organize people and resources so communities achieve more. We
          build relationships that empower individuals to reach their goals.
          It’s a place where go-getters and goal-setters can track progress,
          connect with others for support and join a community of like-minded
          people who are also looking to live life to the fullest. You can also
          use the platform to raise money, manage event ticketing, and engage
          with your communities. Members achieve small and large goals ranging
          from improving health, personal relationships and business practices.
          <a href="https://blog.diem.life/about" *ngIf="!webViewMode"
            >Read more about us and our story</a
          ><a href="javascript:void(0)" *ngIf="webViewMode" (click)="goToPage('http://blog.diem.life/about', 'external')"
          >Read more about us and our story</a>
        </p>
      </div>
      <div id="lnk2"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">What is a Quest®?</h3>
        <p>
          In DIEMlife®, a Quest® can be a goal or a bucket list item or a work
          project or anything else you're working on and want to stay
          accountable for. You can have a Quest® to finish a trail race, to
          meditate daily, to raise money for a non-profit, to talk to your
          family 3x a week or to buy tickets and go to Tomorrowland. If you want
          more Quest® ideas, click
          <a href="https://diem.life/explore" *ngIf="!webViewMode">Explore</a><a *ngIf="webViewMode" href="javascript:void(0)" (click)="goToPage('/explore', 'internal')">Explore</a> in the menu.
        </p>
      </div>
      <div id="lnk3"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">What do I do with a Quest®?</h3>
        <p>
          You can do a lot. A Quest® gives you the ability to engage with others
          through conversation, photos, sending and receiving backing, and
          buying and selling event tickets and merchandise.
        </p>
      </div>
      <div id="lnk4"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          I bought an event ticket through DIEMlife®. What happens next?
        </h3>
        <p>
          Awesome! You should have received a ticket confirmation email from us.
          If you don't see it, check your spam folder or
          <a *ngIf="!webViewMode" href="mailto:diemlife@diemlife.com">contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">contact us</a>. If you need to
          make any changes to your ticket order, contact the event organizer
          directly.
        </p>
      </div>
      <div id="lnk5"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          I'm having trouble buying an event ticket through DIEMlife®. Can you
          help?
        </h3>
        <p>
          Most certainly.
          <a *ngIf="!webViewMode" href="mailto:diemlife@diemlife.com">Contact us.</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">Contact us</a> and let us know
          what issue you're having.
        </p>
      </div>
      <div id="lnk6"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">How are my Quests organized?</h3>
        <p>
          Go to “My Quests” from the menu. All your Quests are separated by
          timing. You can choose from currently Active, Saved, and Done. Active
          Quests are Quests you've started and are working on. Saved Quests are
          Quests you've saved but not yet started. Done Quests are Quests you've
          achieved.
        </p>
      </div>
      <div id="lnk7"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          I just clicked Start on my Saved Quest®. Where did it go?
        </h3>
        <p>
          When you click "Start" on a Quest®, it becomes active. If you click
          “Start” on a Saved Quest it transfers it from the Saved section to the
          Active section.
        </p>
      </div>
      <div id="lnk8"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          I completed all of my Milestones but the Quest® didn't move to Done.
          How can I move it over?
        </h3>
        <p>
          To move a Quest to Done you must click the "Complete Quest" button and
          answer the prompts. You can complete a Quest without having to check
          off all the milestones. Just answer the prompts after clicking
          “Complete Quest”.
        </p>
      </div>
      <div id="lnk9"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          Can I add friends or invite them to use DIEMlife® and join me on a
          Quest®?
        </h3>
        <p>
          Of course! If your friend is already a DIEMlife® user, go to “Friends”
          in the menu, search for your friend by name and click the + icon to
          send your friend request. If you want to invite them to join your
          Quest®, use the Share option in the Quest® to send it to them. Your
          friend will need to click “Start” in the Quest® to officially join
          you.
        </p>
      </div>
      <div id="lnk10"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt">
        <h3 class="st--mod-title">Can I get notifications on a Quest®?</h3>
        <p>Yes, tap the top bell icon to see recent activity.</p>
      </div>
      <div id="lnk11"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">How do I manage my privacy in DIEMlife®?</h3>
        <p>
          You can read our
          <a *ngIf="!webViewMode" href="https://diem.life/legal">privacy policy</a><a *ngIf="webViewMode" routerLink="/legal" queryParamsHandling="merge">privacy policy</a> to learn more
          about what and how we share. You can change Quest level privacy
          settings by clicking “Edit”, then “Settings”.
        </p>
      </div>
      <div id="lnk12"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">How can I report inappropriate content?</h3>
        <p>
          If you see anything offensive on DIEMlife®,
          <a *ngIf="!webViewMode" href="mailto:diemlife@diemlife.com">contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">contact us</a>
        </p>
      </div>
      <div id="lnk13"></div>
    </div>

    <div class="st--mod clearfix">
      <!-- <div class="st--mod-asset">
        <div class="fpo-div2"><img alt="What is DIEMlife"
                                   src="https://s3.amazonaws.com/diemlife-assets/more-questions-contact-us.jpg"></div>
      </div> -->
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          I have more questions, where can I get them answered?
        </h3>
        <p><a *ngIf="!webViewMode" href="mailto:diemlife@diemlife.com">contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">contact us</a></p>
      </div>
      <div id="lnk14"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          How do I receive money for my Quests in DIEMlife?
        </h3>
        <p>
          Yes. Just make sure that you have your bank account linked. Go to the
          menu and add your bank information in your “Account”. When you’re
          ready to transfer the money to your bank, go to your profile and click
          Transfer.
        </p>
      </div>
      <div id="lnk15"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          How do I give money in DIEMlife?
        </h3>
        <p>
          You will need to add a credit card or bank account first in “Account”.
          Then you can financially back any Quest® that has the “Back Me”
          button. Click the button and follow the prompts.
        </p>
      </div>
      <div id="lnk16"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          What if I receive money but can’t finish my Quest®?
        </h3>
        <p>
          We can't tell you what to do. In some cases, completing a Quest® is
          about accountability, integrity, and responsibility. But sometimes, a
          particular Quest® may no longer make sense in your life. If that's the
          case, be sure to update your Quest® backers so they're not left
          wondering what happened to their contribution. Also consider returning
          the money, using the money to pay it forward (coming soon!) or back
          someone else’s Quest®.
        </p>
      </div>
      <div id="lnk17"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">What are the fees?</h3>
        <p>
          For ticket purchases, we typically charge a 2% DIEMlife® platform fee
          + $0.99 per ticket plus a standard credit card processing fee of 2.9%
          + $0.30. For other financial backing transactions, we typically charge
          a standard 5% DIEMlife® platform fee plus a standard credit card
          processing fee of 2.9% + $0.30. If you would like to absorb your
          backer’s fees, <a *ngIf="!webViewMode" href="mailto:diemlife@diemlife.com">contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">contact us</a>
        </p>
      </div>
      <div id="lnk18"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">Can I get a refund?</h3>
        <p>
          No. DIEMlife® cannot issue any refunds. Because we don't hold onto any
          money that passes between you and the receiver, we cannot process
          refunds. If you’d like a refund on an event ticket you purchased, you
          need to contact the event organizer directly.
        </p>
      </div>
      <div id="lnk19"></div>
    </div>

    <div class="st--mod clearfix">
      <!-- <div class="st--mod-asset">
        <div class="fpo-div2"><img alt="Taxes" src="https://s3.amazonaws.com/diemlife-assets/taxes.jpg"></div>
      </div> -->
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          How does this work for taxes if I give or receive money?
        </h3>
        <p>
          Non-profits that receive money on a Quest® are responsible for
          corresponding directly with its backers. Financial backing of Quests
          for individuals and for-profit organizations is classified as
          nontaxable gifts, not income. DIEMlife® is not a charitable
          organization, so financial backings do not fall under charitable
          giving guidelines. It's best to ask your tax advisor or check out
          irs.gov.
        </p>
      </div>
      <div id="lnk20"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          Why do people financially back each other's Quests?
        </h3>
        <p>
          We asked our users why they have backed Quests and these are just some
          of their reasons: "I wanted to encourage my friend and finally see him
          achieve this Quest." "So I can make a difference and help people
          improve their lives." "I can see where my money is going and how it's
          making an impact."
        </p>
      </div>
      <div id="lnk21"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">What do backers get in return?</h3>
        <p>
          People who financially back others will get updates on Quest® progress
          and completion. It goes without saying, a sense of purpose, community,
          and impact being part of another person's Quest®.
        </p>
      </div>
      <div id="lnk22"></div>
    </div>

    <div class="st--mod clearfix">
      <div class="st--mod-txt st--mod-txt-full">
        <h3 class="st--mod-title">
          How do I know if the person and their Quest® is genuine?
        </h3>
        <p>
          We think it’s a good idea to only back people and companies you know
          and trust.
        </p>
      </div>
    </div>
  </div>
</div>
<button (click)="scrollTop(500)" class="scroll-btn" type="button"></button>
<!-- #################################### -->
