import {Action} from '@ngrx/store';

export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS';
export const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_INFO = 'SET_NOTIFICATIONS_INFO';

export class SetNotificationsInfo implements Action {
  readonly type = SET_NOTIFICATIONS_INFO;

  constructor(public payload: number) {}
}
export class ShowNotifications implements Action {
  readonly type = SHOW_NOTIFICATIONS;
}
export class HideNotifications implements Action {
  readonly type = HIDE_NOTIFICATIONS;
}
export class ResetNotifications implements Action {
  readonly type = RESET_NOTIFICATIONS;
}

export type NotificationsActions =  ShowNotifications |
                                    HideNotifications |
                                    ResetNotifications |
                                    SetNotificationsInfo;
