<div class="c-profile-new__header__photo">
  <div
    class="c-profile-new__header__photo__img h-object-fit"
    *ngIf="coverPictureURL">
    <img [alt]="userInfo.name" [src]="coverPictureURL" />
  </div>
  <div class="c-profile-new__header__photo__bg" *ngIf="!coverPictureURL">
    <picture class="h-object-fit">
      <source
        srcset="/assets/images/useful/categories/mental.webp"
        type="image/webp"
      />
      <img [alt]="userInfo.name" src="/assets/images/useful/categories/mental.jpg" />
    </picture>
  </div>
  <div
    (click)="openDialog('coverPictureURL')"
    *ngIf="mode === 'private' || mode === 'edit'"
    class="c-profile-new__header__photo__bg__upload">
    <svg class="icon icon-camera"><use xlink:href="#icon-camera"></use></svg>
  </div>
  <div class="c-profile-new__header__photo__avatar">
    <app-profile-avatar
      class="c-avatar"
      [avatar]="profilePictureURL"
      [firstName]="userInfo.firstName"
      [isBrand]="userInfo.isUserBrand !== 'N'"
      [lastName]="userInfo.lastName"
      [userName]="userInfo.userName">
    </app-profile-avatar>
    <i
      (click)="openDialog('profilePictureURL')"
      *ngIf="mode === 'private' || mode === 'edit'"
      class="c-profile-new__header__photo__image-upload">
      <svg class="icon icon-camera"><use xlink:href="#icon-camera"></use></svg>
    </i>
  </div>
</div>
<div class="c-profile-new__header__caption">
  <h2 class="c-profile-new__header__user">{{ userInfo.firstName }} {{ userInfo.lastName }}</h2>
  <div class="c-profile-new__header__country">
    <span>{{ country ? country : userInfo.country }}</span>
  </div>
  <div class="c-profile-new__header__tools" [ngSwitch]="viewerStatus" *ngIf="mode === 'public'">
    <button *ngSwitchCase="friendStatus.neededAccept" class="c-profile-new__header__trigger c-btn c-btn_sm c-btn_white" (click)="acceptFriendRequest()" type="button">
      <svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
      <span>Confirm friend request</span>
    </button>
    <button *ngSwitchCase="friendStatus.neededAccept" class="c-profile-new__header__trigger c-btn c-btn_sm c-btn_white" (click)="cancelFriendRequest()" type="button">
      <svg class="icon icon-remove"><use xlink:href="#icon-remove"></use></svg>
      <span>Cancel</span>
    </button>
    <button *ngSwitchCase="friendStatus.noAction" class="c-profile-new__header__trigger c-btn c-btn_sm c-btn_white" (click)="addFriend()" type="button">
      <svg class="icon icon-add-friend"><use xlink:href="#icon-add-friend"></use></svg>
      <span>Add friend</span>
    </button>
    <button *ngSwitchCase="friendStatus.isRequested" class="c-profile-new__header__trigger c-btn c-btn_sm c-btn_white" (click)="cancelFriendRequest()" type="button">
      <svg class="icon icon-remove"><use xlink:href="#icon-remove"></use></svg>
      <span>Cancel friend request</span>
    </button>
    <button *ngSwitchCase="friendStatus.isAccepted" class="c-profile-new__header__trigger c-btn c-btn_sm c-btn_white" (click)="removeFriend()" type="button">
      <svg class="icon icon-remove"><use xlink:href="#icon-remove"></use></svg>
      <span>Remove friend</span>
    </button>
  </div>
  <div class="c-profile-new__header__missionstatement">
    <div class="e-edit" [ngClass]="{'is-active': editActive, 'is-editable': mode === 'private'}">
      <div class="e-edit__group">
        <span class="e-edit__inner" *ngIf="userInfo.missionStatement">{{ userInfo.missionStatement }}</span>
        <span class="e-edit__inner is-underline" *ngIf="!userInfo.missionStatement && editActive">Add mission statement</span>

        <button class="e-edit__tool is-default h-btn-reset" type="button" (click)="editActive = true">
          <svg class="icon icon-pencil"><use xlink:href="#icon-pencil"></use></svg>
        </button>

        <textarea
          #missionStatementField
          class="c-forms__input e-edit__textarea"
          [minlength]="1"
          [maxlength]="missionstatementMaxLength"
          [(ngModel)]="updatedMissionstatement"
          (keydown)="validateWithMaxLength($event)"
          (input)="validateWithMaxLength($event)">
        </textarea>
        <button class="e-edit__tool is-submit h-btn-reset" type="button" [ngClass]="{'is-loading': isLoading === 'missionstatement'}" (click)="updateMissionStatement()">
          <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
        </button>
      </div>
      <div *ngIf="editActive" class="e-edit__message c-forms__message is-block"
        [ngClass]="{
          'is-red': isMissionStatementInvalid(),
          'is-default': !isMissionStatementInvalid()
        }">
        <span *ngIf="updatedMissionstatement.length === 0">This field requires information</span>
        <span *ngIf="updatedMissionstatement.length > 0">{{ missionstatementMaxLength - updatedMissionstatement.length }} characters left</span>
      </div>
    </div>
  </div>
</div>

<app-modal
  *ngIf="mode === 'private' || mode === 'edit'"
  id="cropper-modal"
  (closeModal)="closeModal()">
  <h3 class="modal-title">Upload a Photo:</h3>
  <app-cropper-dialog
    *ngIf="openedModal === 'cropper-modal' && userInfo.email"
    [hideCaption]="true"
    [profileEmail]="userInfo.email"
    [selectedArea]="selectedArea"
    (imageAdded)="updateProfilePhoto($event)">
  </app-cropper-dialog>
</app-modal>
