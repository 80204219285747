<h3 class="modal-title">{{mode === uploadTeamImageModalMode.TeamLogo ? 'Upload Team logo' : 'Upload Cover Image'}}</h3>
<div class="modal-body" [formGroup]="uploadImageFormGroup">
  <app-dropzone-upload formControlName="imageUrl"
    [placeholder]="mode === uploadTeamImageModalMode.TeamLogo ? 'Your team logo' : 'Your cover image'"
    [large]="imageLarge"
    (uploadProgress)="onImageUploadProgress($event)">
  </app-dropzone-upload>
  <button class="c-btn c-btn_md c-btn_dark c-btn_full"
          type="button"
          (click)="onSubmit()"
          [disabled]="progress || uploadProgress || uploadImageFormGroup.invalid">
    <span>Submit</span>
  </button>
</div>
