<form class="c-container c-container_sm" [formGroup]="donateForm" (ngSubmit)="onSubmitDonateForm()" [ngClass]="{'form-is-submitted': donateFormSubmitted || (donateForm.get('amount').errors?.max || donateForm.get('amount').errors?.min)}">
  <strong *ngIf="teamsList" class="c-forms__legend">Choose a team to donate to:</strong>
  <div *ngIf="teamsList">
    <app-ui-select-team
      class="c-dl-team-control"
      [teamsList]="teamsList"
      [emitChanges]="true"
      [initialTeamId]="initialTeamId"
      (teamSelect)="onTeamSelect($event)">
    </app-ui-select-team>
    <div *ngIf="donateFormSubmitted && !touchedTeamSelect" class="c-forms__message is-bottom is-red">
      <div>Please choose a team to donate to or choose No team.</div>
    </div>
  </div>
  
  <strong class="c-forms__legend">Select amount:</strong>
  <div class="c-donate__prices">
    <div class="c-donate__prices__col" *ngFor="let price of prices">
      <button
      (click)="setPrice(price)"
      [ngClass]="{'is-focus': price === enteredAmount}"
      class="c-forms__input c-donate__price"
      type="button"><span>${{price}}</span></button>
    </div>
  </div>
  <strong class="c-forms__legend no-top-pad">Or enter amount manually:</strong>
  <div class="c-forms__group mb-20">
      <div class="c-forms__control is-required">
          <input [(ngModel)]="enteredAmount" aria-label="Enter an amount" class="c-forms__input" formControlName="amount" placeholder="Enter an amount..."
            minlength="3" maxlength="9">
      </div>
      <div *ngIf="donateForm.get('amount').errors && (donateForm.get('amount').errors.max || donateForm.get('amount').errors.min)" class="c-forms__message is-bottom is-red">
        <div *ngIf="donateForm.get('amount').errors.max">Maximum donation amount is $999,999.00</div>
        <div *ngIf="donateForm.get('amount').value < 1">Minimum donation amount is $1.00</div>
      </div>
      <div *ngIf="(donateForm.get('amount').touched || donateFormSubmitted) && donateForm.get('amount').invalid" class="c-forms__message is-bottom is-red">
          <div *ngIf="donateForm.get('amount').errors.required">{{ messages.required }}</div>
          <div *ngIf="donateForm.get('amount').errors.pattern">Please enter only numeric value</div>
      </div>
  </div>
  <div class="c-forms__group mb-20">
      <strong class="c-forms__legend">Add a message:</strong>
      <div class="c-forms__control">
        <input aria-label="Enter a message" class="c-forms__input" formControlName="message" placeholder="Enter a message..." type="text">
      </div>
      <!-- <div *ngIf="(personalInfoForm.get('userName').touched || formSubmitted) && personalInfoForm.get('userName').invalid" class="c-forms__message is-bottom is-red">
          <div *ngIf="personalInfoForm.get('userName').errors.required">{{ messages.required }}</div>
      </div> -->
  </div>
  <div class="c-forms__group c-donate__submit">
    <button class="c-btn c-btn_dark c-btn_md" type="submit">
      <span>Continue</span>
    </button>
  </div>
</form>
