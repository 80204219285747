import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

import * as fromApp from './../_store/app.reducers';
import * as fromAuth from './../components/main/auth/store/auth.reducer';
import {AuthService} from '../components/main/auth/auth.service';
import {ModalService} from '../components/main/modal/modal.service';

@Injectable()
export class AuthGuard implements CanActivate {
  authState: Observable<fromAuth.State>;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private authService: AuthService,
    private modalService: ModalService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;
    return this.store.select('auth')
      .pipe(
        take(1),
        map((authState: fromAuth.State) => {
          if (authState.authenticated) {
            return authState.authenticated && this.authService.checkTokenValid(authState);
          } else {
            this.modalService.open('auth-modal');
            this.authService.redirectUrl = url;
          }
        })
      );
  }
  canBeActivated() {
    return this.store.select('auth')
    .pipe(
      take(1),
      map((authState: fromAuth.State) => {
        if (authState.authenticated) {
            return authState.authenticated && this.authService.checkTokenValid(authState);
          } else {
            return false;
          }
        })
      );
  }
}
