import {Pipe, PipeTransform} from '@angular/core';
import {QuestDoer, SortMembersUtils} from 'diemlife-commons/dist/diemlife-commons-model';

// import { QuestDoer } from '../quest/quest.type';

@Pipe({
  name: 'sortMembers'
})
export class SortMembersPipe implements PipeTransform {

  transform(value: QuestDoer[], options: { isListSort?: any, mode: string }): QuestDoer[] {
    if (options.mode !== 'doers') {
      return value;
    }

    return SortMembersUtils.sortDoers(value, options.isListSort);
  }

}
