import { Injectable } from '@angular/core';
import { ReaquestHeadersService } from 'src/app/_services/requestHeaders.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FriendsService } from '../../pages/friends/friends.service';
import {forkJoin, Observable} from 'rxjs';
// import { QuestDoer } from '../quest/quest.type';
import { map } from 'rxjs/operators';
import { QuestDoer } from 'diemlife-commons/dist/diemlife-commons-model';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private friendsService: FriendsService
  ) {}

  sendMessageAll(questId: number, message: string, subject?: string, plain: boolean = false): Observable<void> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<void>(
      environment.target + environment.context + `/quest/doers/message-all/${questId}`,
      { subject, message, plain },
      { headers: headers }
    );
  }

  setDoersStatusRelativeToUser(doers: QuestDoer[]) {

    return forkJoin([
      this.friendsService.getCurrentFriends(),
      this.friendsService.getPendingFriendsNoAction()
    ]).pipe(map(([currentFriends, pendingFriendsNoAction]) => {

      const currentFriendsIds: any = {};
      const pendingFriendsNoActionIds: any = {};

      if (currentFriends instanceof Array) {
        currentFriends.forEach(friend => {
          currentFriendsIds[friend.id] = true;
        });
      }

      if (pendingFriendsNoAction instanceof Array) {
        pendingFriendsNoAction.forEach(friend => {
          pendingFriendsNoActionIds[friend.id] = true;
        });
      }

      doers.forEach((doer: QuestDoer) => {
        if (currentFriendsIds[doer.userId]) {
          doer.status = 'isAccepted';
        } else if (pendingFriendsNoActionIds[doer.userId]) {
          doer.status = 'isRequested';
        } else {
          doer.status = 'noAction';
        }
      });
      return doers;
    }));
  }
}
