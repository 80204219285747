import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromQuestComponents from '../../store/quest-components.reducer';
import * as CommentsActions from '../../store/quest-components.actions';
import {QuestService} from '../../../../../../_services/quest.service';
import {QuestComment} from 'diemlife-commons/dist/diemlife-commons-model';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-comment-dropdown',
  templateUrl: './comment-dropdown.component.html',
  styleUrls: ['./comment-dropdown.component.styl']
})
export class CommentDropdownComponent implements OnInit {
  @Input() comment: QuestComment;
  @Output() updateRepliesCount: EventEmitter<void> = new EventEmitter<void>();
  isOpen = false;

  constructor(
    private questService: QuestService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  closeDropdown() {
    this.isOpen = false;
  }

  toggleEditComment() {
    const payload: CommentsActions.ToggleEditCommentState = {
      commentId: this.comment.id,
      isEditable: !this.comment.editable
    };
    this.store.dispatch(new CommentsActions.ToggleEditComment(payload));
  }

  removeComment() {
    this.questService.removeQuestComment(this.comment.id).subscribe(() => {
      this.store.dispatch(new CommentsActions.RemoveComment(this.comment.id));
      this.updateRepliesCount.emit();
    });
  }
}
