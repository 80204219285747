import {Component, OnDestroy, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {ExploreCard} from '../explore/explore-card/explore-card.type';
import {NewLandingService} from './new-landing.service';
import {ModalService} from '../../main/modal/modal.service';
// import 'three-dots.js';
import {Router, ActivatedRoute} from '@angular/router';
import {Brand, QuestLitePrepared} from 'diemlife-commons/dist/diemlife-commons-model';
import * as fromAuth from './../../main/auth/store/auth.reducer';
import { AppState } from 'src/app/_store/app.reducers';
import {Location} from '@angular/common'; 

declare var threeDots: any;

@Component({
  selector: 'app-new-landing',
  templateUrl: './new-landing.component.html',
  styleUrls: ['./new-landing.component.styl']
})
export class NewLandingComponent implements OnInit, OnDestroy {
  authState: Observable<fromAuth.State>;
  stateSubscriber: Subscription;
  quests: ExploreCard[] | {}[] = [{}, {}, {}, {}, {}, {}];
  isLoading = 'exploreCards';
  menuIsOpened = false;
  openedModal = '';
  brands: Brand[] = [];
  private metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private newLandingService: NewLandingService,
    private meta: Meta,
    private titleService: Title,
    private modalService: ModalService,
    private location: Location,
  ) {
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    this.setMetaTags();
    this.loadQuests();
    this.loadBrands();
    this.authState = this.store.select('auth');
    this.stateSubscriber = this.authState.subscribe(
      (authState: fromAuth.State) => {
        if (authState.authenticated) {
          this.router.navigate(['/my-quests']);
        }
      }
    );
    this.activatedRoute.data.subscribe(data => {
      if (data[0].openContact) {
        this.openModal('contact-modal', null);
      }
    });
  }

  ngOnDestroy() {
    this.stateSubscriber.unsubscribe();
  }
  private loadQuests() {
    this.newLandingService.getQuests(6).subscribe((res: QuestLitePrepared[]) => {
      this.quests = res;
      setTimeout(() => {
        threeDots('js-three-dots-container');
      });
      this.isLoading = null;
    });
  }
  private animateQuestsShowing(recommendedQuest: QuestLitePrepared[]) {
    const count =  recommendedQuest.length;
    let c = 0;

    const interval = setInterval(() => {
      this.quests[c] = recommendedQuest[c];
      c++;
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      threeDots('js-three-dots-container');
    }, count * 100);
  }
  private loadBrands() {
    this.newLandingService.getBrands().subscribe((res: Brand[]) => {
      this.brands = res;
    });
  }
  private setMetaTags() {
    this.titleService.setTitle('DIEMlife');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'Community enagement, amplified.  We enable organizations to effectively manage the wellness of their communities.'});
  }
  openModal(id: string, mode: string) {
    this.openedModal = id;
    setTimeout(() => {
      this.modalService.open(id, {formMode: mode});
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);                                                        
  }
}
