import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tempprofile',
  templateUrl: './tempprofile.component.html',
  styleUrls: ['./tempprofile.component.styl']
})
export class TempprofileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
