export class CreditCardOptions {
  hidePostalCode?: boolean;
  iconStyle?: string;
  hideIcon?: boolean;
  style?: {
    base?: {
      fontFamily?: string,
      fontSize?: string,
      color?: string,
      fontSmoothing?: string,
      '::placeholder'?: {
        color?: string
      }
    },
    invalid?: {
      color?: string,
      ':focus'?: {
        color?: string,
      },
      '::placeholder'?: {
        color?: string
      }
    }
  };
}
export const CREDIT_CARD_OPTIONS: CreditCardOptions = {
  hidePostalCode: true,
  iconStyle: 'solid',
  hideIcon: true,
  style: {
    base: {
      fontFamily: 'Montserrat',
      fontSize: '13px',
      color: '#005166',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: 'rgba(0, 81, 102, 0.5)',
      },
    },
    invalid: {
      color: '#005166',
      ':focus': {
        color: '#005166',
      },
      '::placeholder': {
        color: '#005166',
      },
    }
  }
};
