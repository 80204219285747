import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable, of, Subscription, timer} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {AppState, teamModalOpenState, teamModalProgressState} from '../../../../_store/app.reducers';
import {StartTeamQuestPayload} from '../team-quest-starter/team-quest-starter.component';
import {QuestService} from '../../../../_services/quest.service';

@Component({
  selector: 'app-team-form',
  templateUrl: './team-form.component.html',
  styleUrls: ['./team-form.component.styl']
})
export class TeamFormComponent implements OnInit, OnDestroy {
  @Input() questId: number;
  @Input() fundraisingEnabled: boolean = false;
  @Output() onCreateTeam: EventEmitter<StartTeamQuestPayload> = new EventEmitter<StartTeamQuestPayload>();

  teamFormGroup: FormGroup;
  progress = false;
  uploadProgress = false;
  checkProgress = false;
  teamModalOpenSubscription: Subscription;
  teamModalProgressSubscription: Subscription;
  formIsSubmitted = false;

  constructor(private store: Store<AppState>, private questService: QuestService) {
  }

  ngOnInit() {
    this.teamFormGroup = new FormGroup({
      'teamName': new FormControl(null, [
        Validators.required,
        Validators.maxLength(250)
      ], this.validateTeamNameAvailable.bind(this)),
      'teamLogoUrl': new FormControl(null, [
        Validators.maxLength(2000)
      ])
    });
    if (this.fundraisingEnabled) {
      this.teamFormGroup.addControl('targetAmount', new FormControl(null, [
        Validators.required
      ]));
    }
    this.teamModalOpenSubscription = this.store.pipe(select(teamModalOpenState)).subscribe(state => {
      if (state.open) {
        this.teamFormGroup.reset();
      }
    });
    this.teamModalProgressSubscription = this.store.pipe(select(teamModalProgressState)).subscribe(state => {
      this.progress = state.questProgress || state.fundProgress;
    });
  }

  ngOnDestroy(): void {
    this.teamModalOpenSubscription.unsubscribe();
    this.teamModalProgressSubscription.unsubscribe();
  }

  onLogoUploadProgress(progress: boolean) {
    this.uploadProgress = progress;
  }

  onSubmitTeamForm(): boolean {
    this.formIsSubmitted = true;
    if (this.teamFormGroup.invalid || this.progress) {
      return false;
    }
    this.progress = true;
    this.onCreateTeam.emit({
      teamName: this.teamFormGroup.controls['teamName'].value,
      teamLogoUrl: this.teamFormGroup.controls['teamLogoUrl'].value,
      targetAmount: this.fundraisingEnabled
        ? Number(this.teamFormGroup.controls['targetAmount'].value || 0) * 100
        : null
    });
    return true;
  }

  validateTeamNameAvailable(control: AbstractControl): Observable<ValidationErrors> {
    return timer(100).pipe(
      switchMap(() => {
        if (control.value) {
          this.checkProgress = true;
          return this.questService.checkQuestTeamName(this.questId, control.value).pipe(
            map(result => {
              this.checkProgress = false;
              return result ? {duplicateTeamName: true} : null;
            }),
            catchError((err: any) => {
              if (err.status === 500) {
                return of({invalid: true});
              }
              return of({notAllowed: true});
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

}
