<div class="c-comments__header">
  <a class="c-comments__profile" href="javascript:void(0)" (click)="onNavigate({routerLink:['/profile', comment.userName], type: 'LinkInside'})">
    <app-profile-avatar
      class="c-avatar"
      [avatar]="comment.userAvatarUrl"
      [firstName]="comment.userFirstName"
      [isBrand]="false"
      [lastName]="comment.userLastName"
      [userName]="comment.userName">
    </app-profile-avatar>
    <span>{{ comment.userFirstName }}&nbsp;{{ comment.userLastName }}</span>
  </a>
  <span class="c-comments__header__status" *ngIf="comment.edited">(edited)</span>
  <span class="c-comments__header__status is-right">{{comment.createdDate | timeAgo}}</span>
  <app-comment-dropdown
    *ngIf="comment.userId === viwerId"
    class="c-comments__header__drop c-comment__drop"
    [comment]="comment"
    (updateRepliesCount)="emitRepliesCount()">
    <svg class="icon icon-more"><use xlink:href="#icon-more"></use></svg>
  </app-comment-dropdown>
</div>
<div class="c-comments__caption">
  <div class="c-comments__body">
    <div class="c-comments__content h-typography no-pad-top"
      *ngIf="!comment.editable && comment.comment"
      [appMention]="comment.mentions"
      [webviewMode]="webviewMode"
      (navigateToEmit)="onNavigate($event)"
      [innerHTML]="prepareCommentCRLF(comment.formattedComment) || 'empty' | urlify | safe:'html'">
    </div>
    <form [formGroup]="form" *ngIf="comment.editable" (ngSubmit)="editComment()" class="c-comments__editor">
      <div class="c-n-form__control">
        <textarea class="c-comments__editor__input c-n-form__input"
                  formControlName="editor"
                  placeholder="Edit your comment..."
                  [disabled]="isLoading"
                  [ngxTribute]="tributeOptions"
                  autosize></textarea>
        <span class="c-n-form__border"></span>
      </div>
      <div class="c-n-form__message is-red" *ngIf="form.get('editor').invalid">
        <span *ngIf="form.get('editor').errors['maxLengthError']">{{ validationMessages.maxlength('Comment', form.get('editor').errors['maxLengthError'].maxLength) }}</span>
      </div>
      <div class="c-comments__editor__footer" *ngIf="comment.editable">
        <button class="c-comments__editor e-btn e-btn_sm e-btn_dark" [ngClass]="{'is-loading': isLoading}" [disabled]="isLoading || form.invalid" type="submit">
          <span>Submit</span>
        </button>
      </div>
    </form>
  </div>

  <div class="c-comments__footer" *ngIf="!comment.editable">
    <button class="c-comments__footer__item h-btn-reset" (click)="toggleLikeComment()" type="button">
      <div class="c-comment__footer-button h-xs-loader is-currentColor" [ngClass]="{'is-loading': isLikeLoading}">
        <span class="h-xs-loader__control"></span>
        <span class="c-comment__likes-caption h-xs-loader__caption">
          <span *ngIf="!hasCurrentUserLiked" class="icon icon-heart">🙌</span>
          <span *ngIf="hasCurrentUserLiked" class="icon icon-heart-filled">🙌</span>
        </span>
      </div>
      <span class="c-comment__footer-counter">{{comment.likes?.length || "Cheer!"}}</span>
      <span *ngIf="comment.likes?.length" class="c-comment__footer-unit">{{comment.likes?.length === 1 ? 'person cheered' : 'people cheered'}} </span>
    </button>
    <!-- TODO: should be unccomited after structural comment logic implementation -->
    <!-- <button *ngIf="!reply" class="c-comments__footer__item h-btn-reset" (click)="showReplies()" type="button">
      <svg class="icon"><use xlink:href="#icon-dl-comment"></use></svg>
      <span class="c-comment__footer-counter">{{comment.replies?.length}}</span>
      <span class="c-comment__footer-unit">{{comment.replies?.length === 1 ? 'comment' : 'comments'}} </span>
    </button> -->
    <button class="c-comments__footer__item h-btn-reset" (click)="toggleReplyComment()" type="button">
      <span>Reply</span>
    </button>
  </div>
</div>

<div class="c-comments__replies" *ngIf="comment.replies?.length && !reply" @animateList>
  <div class="c-comments__replies__more" *ngIf="comment.replies?.length > limit" @animateItems>
    <div class="c-comments__replies__more__inner">
      <a class="c-comments__replies__more__trigger" (click)="loadMoreReplies()" href="javascript:void(0)">
        <span>Load more</span>
      </a>
      <span class="c-comments__replies__more__pagination" (click)="loadMoreReplies()" href="javascript:void(0)">{{limit <= comment.replies.length ? limit : comment.replies.length}}&nbsp;/&nbsp;{{ comment.replies.length }}</span>
    </div>
  </div>
  <app-comment
    class="c-comments__item c-comment"
    *ngFor="let reply of comment.replies | slice:0:limit | reverse"
    @animateItems
    [webviewMode]="webviewMode"
    (navigateToEmit)="onNavigate($event)"
    (closeAnotherReplyForms)="onCloseAnotherReplyForms()"
    (increaseRepliesLimit)="loadMoreReplies(1)"
    (updateRepliesCount)="emitRepliesCount()"
    [closeReplyForm]="closeReplyForm"
    [parentCommentId]="comment.id"
    [comment]="reply"
    [reply]="true">
  </app-comment>
</div>

<div class="c-dl-ns-post__feedback" *ngIf="isReplyFormOpened" @animateItems>
  <div class="c-dl-ns-feedback">
    <div class="c-dl-ns-feedback__inner">
      <a class="c-dl-ns-feedback__profile" href="javascript:void(0)" (click)="onNavigate({routerLink:['/profile', comment.userName], type: 'LinkInside'})">
        <app-profile-avatar
          class="c-dl-ns-feedback__avatar c-avatar"
          *ngIf="profileState | async"
          [avatar]="(profileState | async)?.profilePictureURL"
          [firstName]="(profileState | async)?.firstName"
          [isBrand]="false"
          [lastName]="(profileState | async)?.lastName"
          [userName]="(profileState | async)?.userName">
        </app-profile-avatar>
      </a>
      <form class="c-dl-ns-feedback__ctrl" [formGroup]="replyForm" (ngSubmit)="replyToComment()">
        <textarea class="c-dl-ns-feedback__input js-reply-trigger"
                  formControlName="editor"
                  placeholder="Edit your comment..."
                  [disabled]="isLoading"
                  [ngxTribute]="tributeOptions"
                  placeholder="Add a reply..."
                  autosize></textarea>
        <div class="c-dl-ns-feedback__submit">
          <button
            class="c-dl-ns-feedback__oval e-dl-tool"
            ngbTooltip="Сancel"
            tooltipClass="c-dl-tooltip"
            (click)="cancelReply()"
            type="button">
            <svg class="icon icon-cancel"><use xlink:href="#icon-cancel"></use></svg>
          </button>
          <button
            class="c-dl-ns-feedback__oval e-dl-tool h-xs-loader"
            *ngIf="replyForm.get('editor').valid"
            [ngClass]="{'is-loading': isLoading === 'REPLY'}"
            [disabled]="isLoading"
            ngbTooltip="Reply"
            tooltipClass="c-dl-tooltip"
            type="submit">
            <svg class="icon icon-arrow_right h-xs-loader__caption"><use xlink:href="#icon-arrow_right"></use></svg>
            <i class="h-xs-loader__control"></i>
          </button>
        </div>
      </form>
    </div>
    <div class="c-n-form__message is-red" *ngIf="replyForm.get('editor').invalid">
        <span *ngIf="replyForm.get('editor').errors['maxLengthError']">{{validationMessages.maxlength('Comment', replyForm.get('editor').errors['maxLengthError'].maxLength)}}</span>
    </div>
  </div>
</div>
