import {Component, Input, OnInit} from '@angular/core';
import {ProfileAvatarService} from './profile-avatar.service';

@Component({
  selector: 'app-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.styl']
})
export class ProfileAvatarComponent implements OnInit {
  @Input() avatar: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() userName?: string;
  @Input() isBrand: boolean;
  letters: string = null;
  color: string = null;

  constructor(private profileAvatarService: ProfileAvatarService) {
  }

  getLetters() {
    if (this.isBrand || !this.lastName) {
      return this.firstName.substr(0, 1);
    }
    return this.firstName.substr(0, 1) + (this.lastName ? this.lastName.substr(0, 1) : '');
  }

  ngOnInit() {
    if (!this.avatar) {
      this.color = this.profileAvatarService.getColor();
      this.letters = this.getLetters();
    } else {
      // TODO: this code had only jpg and png types listed.  Added gif for now.  Why nothing else?
      const regexp = new RegExp('(.jpeg$)|(.png$)|(.jpg$)|(.gif$)', 'gs');
      if (!this.avatar.match(regexp)) {
        this.avatar = this.avatar + '.jpeg';
      }
    }
  }

}
