<!-- <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="c-payments"> -->
<form class="c-payments">
  <div class="c-payments__group">
    <div class="c-payments__group__body">
      <div class="c-payments__tab" *ngIf="paymentsState">
        <ul class="c-payments__list">
          <li class="c-payments__list__item">
            <span class="c-forms__legend">Credit cards:</span>
            <div class="c-forms__group" *ngFor="let card of (paymentsState | async).creditCards; let i = index">
              <div class="c-payments__card">
                <div class="c-payments__card__inner c-forms__control">
                  <span class="c-payments__card__caption c-forms__input has-icon">
                    <img *ngIf="card.cardType === 'Visa'" class="c-payments__card__icon" src="assets/images/useful/visa-card.svg" alt="">
                    <img *ngIf="card.cardType === 'MasterCard'" class="c-payments__card__icon" src="assets/images/useful/master-card.svg" alt="">
                    <span>XXXX XXXX XXXX {{card.lastFourDigits}}</span>
                  </span>
                </div>
                <button class="c-payments__card__remove"
                  [ngClass]="{'is-loading': isloading.payment === 'creditCard' && isloading.index === i}"
                  (click)="removeCreditCard(i)"
                  aria-label="Remove credit card">
                  <svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>
                </button>
              </div>
            </div>
            <div class="c-forms__group" *ngIf="(paymentsState | async).creditCards.length === 0">
              <div class="c-forms__input c-forms__empty-info">
                <span>You don't have any credit cards.</span>
              </div>
            </div>
          </li>
          <li class="c-payments__list__item">
            <div class="c-forms__group">
              <app-card-control-dialog></app-card-control-dialog>
            </div>
          </li>
        </ul>
      </div>
      <div class="c-payments__tab" *ngIf="hasValidStripeAccount === false && userCountrySupportsPayouts">
        <ul class="c-payments__list">
          <li class="c-payments__list__item">
            <span class="c-forms__legend">Payouts:</span>
            <div class="c-form__group">
              <button (click)="activatePayouts()" aria-label="Activate payouts" class="c-btn c-btn_dark c-btn_md c-btn_full" type="button">
                <span>Activate payouts</span>
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="c-payments__tab" *ngIf="hasValidStripeAccount === true">
        <ul class="c-payments__list">
          <li class="c-payments__list__item">
            <span class="c-forms__legend">Bank accounts:</span>
            <div class="c-forms__group" *ngFor="let bank of (paymentsState | async).bankAccounts; let i = index">
              <div class="c-payments__card">
                <div class="c-payments__card__inner c-forms__control" (click)="!bank.verified ? vefifyBankInfo(i) : null">
                  <span class="c-payments__card__caption c-forms__input has-icon has-two-icons-right">
                      <svg class="c-payments__card__icon icon icon-bank"><use xlink:href="#icon-bank"></use></svg>
                    <span>XXXX XXXX XXXX {{bank.id}}</span>
                  </span>
                </div>
                <button *ngIf="!bank.verified" class="c-payments__card__info" (click)="vefifyBankInfo(i)">
                  <svg class="icon icon-info_sign"><use xlink:href="#icon-info_sign"></use></svg>
                </button>
                <button class="c-payments__card__remove"
                  [ngClass]="{'is-loading': isloading.payment === 'bankAccount' && isloading.index === i}"
                  (click)="removeBankAccount(i)" aria-label="Remove bank account">
                  <svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>
                </button>
              </div>
            </div>
            <div class="c-forms__group" *ngIf="(paymentsState | async).bankAccounts.length === 0">
              <div class="c-forms__input c-forms__empty-info">
                <span>You don't have any bank accounts.</span>
              </div>
            </div>
          </li>
          <li class="c-payments__list__item" *ngIf="(paymentsState | async).bankAccounts.length === 0">
            <div class="c-form__group">
              <app-bank-account-control-dialog></app-bank-account-control-dialog>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>
<app-modal id="verify-bank-account-dialog" *ngIf="(paymentsState | async).bankAccounts.length > 0">
  <!-- (account)="paymentsState.bankAccounts[0]" -->
  <app-verify-bank-dialog class="modal-body"></app-verify-bank-dialog>
</app-modal>


