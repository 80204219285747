import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SearchService } from 'src/app/components/main/search/search.service';
import { SearchResult, MyFriendsInfo, SearchResponse } from 'diemlife-commons/dist/diemlife-commons-model';
import {Observable} from "rxjs";

@Component({
  selector: 'app-friends-search',
  templateUrl: './friends-search.component.html',
  styleUrls: ['./friends-search.component.styl']
})
export class FriendsSearchComponent implements OnInit {
  results: SearchResult[] = [];
  isLoading = true;
  limit = 10;
  startIndex = 0;
  searchText = '';
  searchForm: FormGroup;
  loadmoreEnabled = true;
  newContentLoaded = false;
  myFriendsInfo: MyFriendsInfo = null;

  constructor(
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      'search': new FormControl(null, [
        Validators.required
      ])
    });

    this.searchService.getFriendsMapping().subscribe((data: MyFriendsInfo) => {
      this.myFriendsInfo = data;
    }, (err) => {
      console.log(err);
      this.myFriendsInfo = {
        approved: [],
        sent: [],
        received: []
      };
    });

    this.searchForm.get('search').valueChanges.pipe(
      debounceTime(300),
      switchMap(val => {
        this.searchText = val;
        this.resetResults();
        this.isLoading = true;
        return this.forceLoadMore();
      })
    ).subscribe((searchResponse: SearchResponse) => {
      this.updateResults(searchResponse);
    }, (err) => {
      console.log(err);
      this.isLoading = false;
    });

  }

  private forceLoadMore(): Observable<SearchResponse> {
    return this.searchService.getSearchResults({
      q: this.searchText.toLocaleLowerCase(),
      _start: this.startIndex,
      _limit: this.limit,
      type: 'people'
    });
  }

  updateResults(searchResponse: SearchResponse) {
    if (searchResponse.data && this.myFriendsInfo) {
      this.loadmoreEnabled = searchResponse.more;
      this.startIndex = searchResponse.start + searchResponse.limit;
      this.results = [...this.results, ...this.setUserStatuses(searchResponse.data)];
      setTimeout(() => {
        this.newContentLoaded = true;
      });
    }
    this.isLoading = false;
  }

  resetResults() {
    this.isLoading = true;
    this.loadmoreEnabled = true;
    this.startIndex = 0;
    this.results = [];
  }

  setUserStatuses(data: SearchResult[]): SearchResult[] {
    return data.map((user) => {
      Object.keys(this.myFriendsInfo).forEach((key) => {
        user = this.setUserStatusByKey(user, key);
      });
      return user;
    });
  }

  setUserStatusByKey(user: SearchResult, key: string): SearchResult {
    this.myFriendsInfo[key].forEach((userId) => {
      if (userId === user.userId) {
        user.status = key;
      }
    });
    return user;
  }

  loadMore (resetResults?: boolean): void {
    if (resetResults) {
      this.resetResults();
    }
    if (this.loadmoreEnabled) {

      this.isLoading = true;

      this.forceLoadMore().subscribe((searchResponse: SearchResponse) => {
        this.updateResults(searchResponse);
      }, (err) => {
        console.log(err);
        this.isLoading = false;
      });
    }
  }

  onTriggerLoadMore(needToLoadMore) {
    this.newContentLoaded = false;
    if (needToLoadMore) {
      this.loadMore();
    }
  }
}
