import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ModalComponent} from 'src/app/components/main/modal/modal.component';
import {BsSelectDirective} from 'src/app/directives/bs-select/bs-select.directive';
import {PayDialogComponent} from 'src/app/components/main/pay-dialog/pay-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QuestService} from 'src/app/_services/quest.service';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {ReferenceDataService} from 'src/app/_services/reference-data.service';
import {NgxMaskModule} from 'ngx-mask';
import {InputFlatpickrDirective} from 'src/app/directives/input-flatpickr/input-flatpickr.directive';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ShareWidjetComponent} from 'src/app/components/main/share-widjet/share-widjet.component';
import {ShareEmailComponent} from 'src/app/components/main/share-widjet/share-email/share-email.component';
import {AuthGuardService} from 'src/app/directives/auth-guard/auth-gurad.service';
import {LandingCarouselComponent} from '../../components/main/landing/landing-carousel/landing-carousel.component';
import {LandingCarouselItemComponent} from '../../components/main/landing/landing-carousel-item/landing-carousel-item.component';
import {SlickModule} from 'ngx-slick';
import {RouterModule} from '@angular/router';
import {SearchService} from 'src/app/components/main/search/search.service';
import {DropdownModule} from 'angular-custom-dropdown';
import {NewLandingService} from 'src/app/components/pages/new-landing/new-landing.service';
import {CardComponent} from 'src/app/components/main/card/card.component';
import {GetInViewDirective} from 'src/app/directives/get-in-view/get-in-view.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BirthdayControlComponent} from 'src/app/components/main/birthday-control/birthday-control.component';
import {BirthdayControlService} from 'src/app/components/main/birthday-control/birthday-control.service';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {PhoneControlComponent} from 'src/app/components/main/phone-control/phone-control.component';
import {AuthGuardDirective} from 'src/app/directives/auth-guard/auth-guard.directive';
import {SmartEditDirective} from 'src/app/directives/smart-edit/smart-edit.directive';
import {DlMilestonesService} from 'src/app/components/main/dl-milestones/dl-milestones.service';
import {MilestonesMapComponent} from '../../components/main/quest/milestones-map/milestones-map.component';
import {QuestMapComponent} from '../../components/main/quest/quest-map/quest-map.component';
import {UiControlComponent} from './components/ui/ui-control/ui-control.component';
import {UiSelectTeamComponent} from './elements/ui-select-team/ui-select-team.component';
import {UiSelectTeamService} from './elements/ui-select-team.service';
import {DlSearchComponent} from './components/dl-search/dl-search.component';
import {LoadmoreTriggerDirective} from 'src/app/directives/loadmore-trigger/loadmore-trigger.directive';
import {FriendsItemComponent} from 'src/app/components/pages/friends/friends-item/friends-item.component';
import {ProfileAvatarComponent} from 'src/app/components/separate/profile-avatar/profile-avatar.component';
import {QuestProgressComponent} from 'src/app/components/main/quest/quest-progress/quest-progress.component';
import {MyQuestsSevice} from 'src/app/components/pages/my-quests/my-quests.service';
import {AccountService} from 'src/app/components/pages/account/account.service';
import {MapboxService} from 'diemlife-commons/dist/diemlife-commons-model';
import { MapPopoverComponent } from 'src/app/components/main/map-popover/map-popover.component';
import { TeamListItemComponent } from 'src/app/components/main/map-popover/team-list-item/team-list-item.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SlickModule.forRoot(),
    RouterModule,
    DropdownModule,
    DragDropModule,
    DropdownModule,
    Ng2TelInputModule,
    InfiniteScrollModule
  ],
  declarations: [
    ModalComponent,
    PayDialogComponent,
    BsSelectDirective,
    InputFlatpickrDirective,
    AuthGuardDirective,
    GetInViewDirective,
    ShareWidjetComponent,
    ShareEmailComponent,
    LandingCarouselComponent,
    LandingCarouselItemComponent,
    CardComponent,
    BirthdayControlComponent,
    PhoneControlComponent,
    SmartEditDirective,
    MilestonesMapComponent,
    QuestMapComponent,
    UiControlComponent,
    UiSelectTeamComponent,
    DlSearchComponent,
    LoadmoreTriggerDirective,
    FriendsItemComponent,
    ProfileAvatarComponent,
    QuestProgressComponent,
    MapPopoverComponent,
    TeamListItemComponent
  ],
  exports: [
    CommonModule,
    NgxMaskModule,
    ModalComponent,
    PayDialogComponent,
    BsSelectDirective,
    InputFlatpickrDirective,
    AuthGuardDirective,
    NgbTooltipModule,
    ShareWidjetComponent,
    ShareEmailComponent,
    LandingCarouselComponent,
    LandingCarouselItemComponent,
    SlickModule,
    DropdownModule,
    CardComponent,
    DragDropModule,
    CardComponent,
    BirthdayControlComponent,
    Ng2TelInputModule,
    PhoneControlComponent,
    SmartEditDirective,
    UiControlComponent,
    UiSelectTeamComponent,
    MilestonesMapComponent,
    QuestMapComponent,
    DlSearchComponent,
    LoadmoreTriggerDirective,
    FriendsItemComponent,
    ProfileAvatarComponent,
    QuestProgressComponent,
    MapPopoverComponent,
    TeamListItemComponent
  ],
  providers: [
    QuestService,
    ReaquestHeadersService,
    ReferenceDataService,
    AuthGuardService,
    SearchService,
    NewLandingService,
    BirthdayControlService,
    DlMilestonesService,
    UiSelectTeamService,
    MyQuestsSevice,
    AccountService,
    MapboxService
  ],
  entryComponents: [
    MapPopoverComponent
  ]
})

export class SharedModule { }
