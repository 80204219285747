import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestService} from '../../../_services/quest.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FundraiseSwitchModel} from '../../main/quest/manage/fundraise-switch/fundraise-switch.model';
import Quill from 'quill';
import {SnotifyService} from 'ng-snotify';
import {ModalService} from '../../main/modal/modal.service';
import {SnotifyConfigService} from '../../../_services/snotify-config.service';
import {VALIDATION_MESSAGES, ValidationMessages} from 'src/app/app.constants';
import {Counter} from '../../../_tools/counter';
import * as fromApp from '../../../_store/app.reducers';
import * as fromDlMilestones from '../../main/dl-milestones/store/dl-milestones.reducer';
import * as fromProfile from '../profile/store/profile.reducer';
import {ReferenceDataService} from 'src/app/_services/reference-data.service';
import {QUILL_CONFIG} from '../../../app.config';
import {Meta, Title} from '@angular/platform-browser';
import {getYoutubeId, youtubeUrlValidator} from 'src/app/directives/youtube-validation/youtube-validation.directive';
import {PaymentsService} from '../../main/payments/payments.service';
import {QuestCategory, QuestPreparedPhoto} from '../../main/quest/quest.type';
import {QuestInfo, QuestTask, Quest, SurveyType} from 'diemlife-commons/dist/diemlife-commons-model';
import {SurveyService} from '../../main/survey/survey.service';
import { DlMilestoneManageModes, TaskGroup } from '../../main/dl-milestones/dl-milestones.typings';
import { SetMilestones, DlMilestonesClearData } from '../../main/dl-milestones/store/dl-milestones.actions';
import { DlMilestonesService } from '../../main/dl-milestones/dl-milestones.service';

Quill.register('modules/counter', Counter);

declare var $: any;

interface CreateQuest {
  questName: string;
  pillar: string;
  questDescription: string;
  questRecurring: string;
  questTasks: QuestTask[];
  admins: string[];
  privacy: string;
  copyAllowed: boolean;
  editableMilestones: boolean;
  invites: string[];
  backBtnDisabled: boolean;
  fundraising: boolean;
  fundraisingConfig: any;
  mode: string;
}

@Component({
  selector: 'app-quest-manage',
  templateUrl: './quest-manage.component.html',
  styleUrls: ['./quest-manage.component.styl']
})
export class QuestManageComponent implements OnInit, OnDestroy {
  readonly payoutsValidationMassage = 'Please activate payouts from your account page to use this feature';
  userInfoState: Observable<fromProfile.State>;
  dlMilestonesState: Observable<fromDlMilestones.State>;
  dlMilestonesStateSubscription: Subscription;
  validationMessages: ValidationMessages = VALIDATION_MESSAGES;
  isLoading = false;
  editable = false;
  userInfo: fromProfile.State;
  eventsSubject: Subject<void> = new Subject<void>();
  tabs = {
    items: [
      {key: 'caption', value: 'Content'},
      {key: 'settings', value: 'settings'},
    ],
    selected: 'caption'
  };
  categories: QuestCategory[] = [];
  ngxConfig = {...QUILL_CONFIG};
  questMode = 'paceYourselfMode';
  questForm: FormGroup;
  formSubmitted = false;
  photo: QuestPreparedPhoto;
  openedDialog: string;
  otherUsers: string[] = [];
  newMilestones: QuestTask[] = [];
  fundraisingConfig: FundraiseSwitchModel = {
    currency: 'usd',
    targetAmount: null,
    enabled: false
  };
  questEditInfo: QuestInfo;
  questTasks: QuestTask[] = [];
  questId: number;
  isQuestSaved = false;
  infoIsChanged = false;
  questFormSubscription: Subscription;
  questAdmins: string[] = [];
  coverPicture = null;
  coverPictureFile: File = null;
  metaImage = '../../../../assets/images/logo-black-white.png';
  hasValidStripeAccount = false;
  hideFundraisingSwitch = true;
  leaderboardUpdateProgress = false;
  hasEvent = false;
  isAdmin = false;
  isCreator = false;
  surveyType = SurveyType;
  singleSurveyPushedToApp = false;
  milestoneModes = DlMilestoneManageModes;

  constructor(
    private route: ActivatedRoute,
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private fb: FormBuilder,
    public modalService: ModalService,
    private router: Router,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private referenceDataService: ReferenceDataService,
    private meta: Meta,
    private titleService: Title,
    private paymentsService: PaymentsService,
    private surveyService: SurveyService,
    private dlMilestonesService: DlMilestonesService
  ) {
    this.userInfoState = this.store.select('userInfo');
    this.dlMilestonesState = this.store.select('dlMilestones');
  }

  ngOnInit() {
    const paramsQuestId = this.route.snapshot.paramMap.get('questId');
    this.questId = paramsQuestId ? parseInt(paramsQuestId, 10) : null;
    this.ngxConfig.counter = {container: '#counter', unit: 'character', maxLength: 5000};
    this.editable = !!this.questId;

    this.questService.getOtherUsers().subscribe((users: string[]) => {
      this.otherUsers = users.map((user: any) => {
        return user.email;
      });
    });
    this.questForm = this.fb.group({
      'questName': [null, [Validators.required]],
      'questDescription': [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30000)]],
      'questVideoUrl': [null, [youtubeUrlValidator]],
      'pillar': [null, [Validators.required]],
      'privacy': ['public', [Validators.required]],
      'copyAllowed': [false, []],
      'backBtnEnabled': [false, []],
      'editableMilestones': [false, []],
      'leaderboardEnabled': [false, []],
      'admins': [''],
      'invites': [[], []],
    });
    this.questFormSubscription = this.questForm.valueChanges.subscribe(() => {
      if (this.questForm.touched) {
        this.infoIsChanged = true;
      }
    });

    this.questService.getQuestInterests().subscribe((res: QuestCategory[]) => {
      res.forEach((element: QuestCategory) => {
        let category = '';
        switch (element.value) {
          case 'PHYSICAL':
            category = 'physical';
            break;
          case 'ENVIRONMENTAL':
            category = 'environmental';
            break;
          case 'OCCUPATIONAL':
            category = 'occupational';
            break;
          case 'MENTAL':
            category = 'mental';
            break;
          case 'SOCIAL':
            category = 'friends';
            break;
          case 'FINANCIAL':
            category = 'piggybank';
            break;
        }
        element.content = `
          <svg class="icon icon-${category}"><use xlink:href="#icon-${category}"></use></svg>
          <span>${element.label}</span>
        `;
      });
      this.categories = res;
    });
    this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.userInfo = state;

      if (!Boolean(this.questId)) {
        this.hideFundraisingSwitch = false;
        return false;
      } // check if quest editable

      this.questService.getQuestEditInfo(this.questId).subscribe(([questInfo, questInvites, questAdmins]) => {
        this.questEditInfo = questInfo;
        this.questMode = questInfo.mode;
        this.photo = this.questService.prepareCoverPhoto(questInfo.photo, questInfo.pillar);
        this.questService.getQuestDetail(questInfo.id, questInfo.user.id).subscribe((questDetailInfo: Quest) => {
          this.hasEvent = questDetailInfo.hasEvent;
        });
        this.updateAdminsAndCreator();
        if (this.questEditInfo.fundraising) {
          // checking that doer is not fundraiser
          this.questService.getFundraisingLink(this.questEditInfo.id, this.questEditInfo.user.id).subscribe((res: any) => {
            if (res) {
              this.fundraisingConfig = {
                currency: res.currency,
                targetAmount: res.targetAmount,
                enabled: true
              };
            }
            this.hideFundraisingSwitch = this.questEditInfo.mode === 'diyMode' && this.fundraisingConfig.enabled;
          });
        } else {
          this.hideFundraisingSwitch = false;
        }
        this.dlMilestonesService.getQuestGroupTasks(this.questId, state.id).subscribe((taskGroups: TaskGroup[]) => {
          this.store.dispatch(new SetMilestones(taskGroups));
        });

        for (const admin of questAdmins) {
          this.questAdmins.push(admin.email);
        }
        const questEmailInvites = [];
        for (const invite of questInvites) {
          questEmailInvites.push(invite.email);
        }
        const questVideoId = this.prepareQuestVideoId(this.questEditInfo.questVideoUrl);
        this.questForm.setValue({
          'questName': this.questEditInfo.title,
          'questDescription': this.questEditInfo.questFeed,
          'pillar': this.questEditInfo.pillar,
          'questVideoUrl': questVideoId ? `https://www.youtube.com/embed/${questVideoId}` : null,
          'privacy': this.questEditInfo.privacyLevel.toLowerCase(),
          'copyAllowed': this.questEditInfo.copyAllowed,
          'backBtnEnabled': !this.questEditInfo.backBtnDisabled,
          'editableMilestones': this.questEditInfo.editableMilestones,
          'leaderboardEnabled': this.questEditInfo.leaderboardEnabled,
          'admins': this.questAdmins,
          'invites': questEmailInvites,
        });
        setTimeout(() => {
          this.eventsSubject.next();
        });
      });
      this.questService.checkIfUserQuestSaved(this.userInfo.id).subscribe((res: any) => {
        this.isQuestSaved = res.success.isQuestSaved === 'true';
      });
    });
    this.setMetaTags();

    this.paymentsService.testStripeAccount(this.userInfo.id).subscribe(() => {
      this.hasValidStripeAccount = true;
      this.questForm.get('backBtnEnabled').enable();
    }, () => {
      this.hasValidStripeAccount = false;
      this.questForm.get('backBtnEnabled').disable();
    });
  }

  ngOnDestroy() {
    if (this.questFormSubscription) {
      this.questFormSubscription.unsubscribe();
    }
    this.store.dispatch(new DlMilestonesClearData());
  }

  updateMilestones(res: any) {
    if (this.questId) {
      this.questTasks = res;
    } else {
      this.newMilestones = res;
    }
    this.infoIsChanged = true;
  }

  onFundraisingChange(switchModel: FundraiseSwitchModel) {
    this.fundraisingConfig.enabled = switchModel.enabled;
    this.fundraisingConfig.targetAmount = switchModel.targetAmount;
    this.infoIsChanged = true;

    if (switchModel.enabled && !this.hasValidStripeAccount) {
      this.snotifyService.info(
        this.payoutsValidationMassage,
        null,
        this.snotifyConfigService.getConfig()
      );
    }
  }

  onPrivacyChange() {
    this.questForm.get('privacy').markAsTouched();
  }

  markPillarAsTouched() {
    this.questForm.get('pillar').markAsTouched();
  }

  changePillar(event: any) {
    const category: string = event.target.value;
    let img: any;
    if (this.questEditInfo && this.questEditInfo.photo) {
      img = this.questEditInfo.photo;
    } else if (this.coverPicture) {
      img = this.coverPicture;
    } else {
      img = null;
    }
    this.photo = this.questService.prepareCoverPhoto(img, category);
  }

  openModal(id: string) {
    this.openedDialog = id;
    setTimeout(() => {
      this.modalService.open(id);
    });
  }

  closeDialog() {
    setTimeout(() => {
      this.openedDialog = null;
    });
  }

  selectQuestMode(mode: string) {
    this.questMode = mode;
  }

  buildCreateQuestPayload(questTasks: QuestTask[]) {
    const payload: CreateQuest = {
      ...this.questForm.value,
      mode: this.questMode,
      fundraising: this.fundraisingConfig && this.fundraisingConfig.enabled,
      fundraisingConfig: this.fundraisingConfig ? this.fundraisingConfig : null,
      questTasks: questTasks
    };
    payload.editableMilestones = payload.editableMilestones
      && (this.questMode === 'paceYourselfMode' || this.questMode === 'diyMode');
    payload.backBtnDisabled = !this.questForm.value.backBtnEnabled;

    return payload;
  }

  createQuest() {
    if (this.fundraisingConfig.enabled && !this.hasValidStripeAccount) {
      this.snotifyService.info(
        // tslint:disable-next-line:max-line-length
        'Please activate payouts from your account page before starting a fundraiser',
        null,
        this.snotifyConfigService.getConfig()
      );
      return this.questService.fundraisingSwitchReset;
    }

    this.formSubmitted = true;
    if (this.isLoading) {
      return false;
    }

    if (this.questForm.valid) {
      this.isLoading = true;
      this.dlMilestonesState.pipe(take(1)).subscribe((state: fromDlMilestones.State) => {
        const name = state.milestones.length ? state.milestones[0].name : null;
        const questTasks = state.milestones.length ? state.milestones[0].questTasks : [];
        this.questService.createQuest(this.buildCreateQuestPayload(questTasks), this.userInfo.id, name).subscribe(res => {
          this.questService.addQuestCoverPhoto(res.id, this.coverPictureFile).pipe(take(1)).subscribe(() => {
            this.questService.doQuest(res.id).subscribe(() => {
              this.snotifyService.success(
                'Successfully created a new Quest',
                null,
                this.snotifyConfigService.getConfig()
              );
              this.isLoading = false;
              this.navigateToQuest(res.id);
            });
          });
        }, err => {
          console.log(err);
          this.isLoading = false;
        });
      });
    }
  }

  updateAdminsAndCreator() {
    this.isAdmin = this.questEditInfo && this.questEditInfo.admins.indexOf(this.userInfo.email) >= 0;
    this.isCreator = this.questEditInfo && this.questEditInfo.createdBy === this.userInfo.id;
  }

  confirmEditQuest() {
    this.formSubmitted = true;
    if (this.isLoading) {
      return false;
    }

    if (this.questForm.valid) {
      this.updateAdminsAndCreator();
      // this.isAdmin = this.questEditInfo && this.questEditInfo.admins.indexOf(this.userInfo.email) >= 0;
      // this.isCreator = this.questEditInfo && this.questEditInfo.createdBy === this.userInfo.id;
      if (this.isAdmin || this.isCreator) {
        this.editQuest('Y');
      } else {
        this.snotifyService.confirm(
          'Editing this Quest will create a new copy of it. Would you like to keep the original one active as well, or remove it?',
          'Create New Quest?',
          this.snotifyConfigService.getConfig({
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: 'Keep',
                bold: true,
                action: (toast) => {
                  this.editQuest('Y');
                  this.snotifyService.remove(toast.id);
                }
              },
              {
                text: 'Remove',
                bold: true,
                action: (toast) => {
                  this.editQuest('N');
                  this.snotifyService.remove(toast.id);
                }
              }
            ]
          })
        );
      }
    } else {
      const firstInvalid = $('.c-forms__input.ng-invalid').eq(0);

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }

  }

  validateInvites() {
    if (this.questForm.value.privacy === 'invite' && this.questForm.value.invites.length === 0) {
      this.snotifyService.error('Please select people to invite to your Quest', null, this.snotifyConfigService.getConfig());
      return false;
    }
    return true;
  }

  prepareQuestVideoId(questVideoUrl: string): string | null {
    const res = getYoutubeId(questVideoUrl);
    return Boolean(res) ? `https://www.youtube.com/embed/${res}` : null;
  }

  buildEditQuestPayload(stayInOldQuest: 'Y' | 'N' | null) {
    const payload: CreateQuest = {
      ...this.questForm.value,
      questVideoUrl: this.questForm.value.questVideoUrl ? this.prepareQuestVideoId(this.questForm.value.questVideoUrl) : null,
      mode: this.questMode,
      fundraising: this.fundraisingConfig && this.fundraisingConfig.enabled,
      fundraisingConfig: this.fundraisingConfig || null,
      questTasks: null,
      questId: this.questId,
      stayInOldQuest: stayInOldQuest,
      backBtnDisabled: !this.questForm.value.backBtnEnabled
    };
    return payload;
  }

  editQuest(stayInOldQuest: 'Y' | 'N' | null) {
    if (this.isLoading || !this.validateInvites()) {
      return;
    } else {
      this.isLoading = true;
    }
    const payload = this.buildEditQuestPayload(stayInOldQuest);
    this.questService.editQuest(payload, this.userInfo.id).subscribe(data => {
      this.questService.addQuestCoverPhoto(data.success.questId, this.coverPictureFile).pipe(take(1)).subscribe(() => {
        this.snotifyService.success('Successfully edited Quest!', null, this.snotifyConfigService.getConfig());
        const userName = this.referenceDataService.slugify(this.userInfo.userName).toLowerCase();
        const questTitle = this.referenceDataService.slugify(payload.questName).toLowerCase();
        this.router.navigate(['/', userName, questTitle, this.questEditInfo.id, this.userInfo.id]);
      });
    });
  }

  navigateToQuest(questId: number) {
    this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.router.navigate(['/quest-detail', questId, state.id]);
    });
  }

  navToCaption() {
    this.tabs.selected = 'caption';
  }

  onSelectFile(event) {
    const reader = new FileReader();
    this.coverPictureFile = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (res: Event) => {
      this.coverPicture = reader.result;
      this.photo = this.questService.prepareCoverPhoto(reader.result, this.questForm.value.pillar);
    };
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | Create/Edit Quest');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | Create/Edit Quest'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'Create and edit Quests'});
  }

  updateLeaderboardMembers() {
    if (this.leaderboardUpdateProgress) {
      return;
    }
    this.leaderboardUpdateProgress = true;
    const notifyConfig = this.snotifyConfigService.getConfig();
    this.questService.updateLeaderBoardMembers(this.questId).subscribe(updatedCount => {
      this.leaderboardUpdateProgress = false;
      if (updatedCount) {
        this.snotifyService.success(`Successfully imported ${updatedCount} new leaderboard member(s)`, null, notifyConfig);
      } else {
        this.snotifyService.info(`No new members to import`, null, notifyConfig);
      }
    }, () => {
      this.leaderboardUpdateProgress = false;
      this.snotifyService.warning(`Error updating leaderboard members`, null, notifyConfig);
    });
  }

  // for test surveys with backend
  pushSurvey(questId: number, type?: SurveyType) {
    if (!this.singleSurveyPushedToApp) {
      this.surveyService.pushSurvey(questId, type).subscribe(() => {
        this.singleSurveyPushedToApp = true;
      },
      error => this.snotifyService.error(
        `There was an error on mark-unread survey. Please try again.`,
        null,
        this.snotifyConfigService.getConfig()
      ));
    }
  }

}
