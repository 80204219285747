<!-- <ul class="c-modal-tabs__header"
    *ngIf="!(activeState === 'recoveryPassword')"
    title="Login sign up switch">
  <li
    *ngFor="let tab of tabs | keys"
    class="c-modal-tabs__header__item"
    [class.is-active]="activeState === tab.key">
    <a href="javascript:void(0)"
       (click)="activateTab(tab.key)">{{tab.value}}</a>
  </li>
</ul> -->

<!-- <form
  title="Login form"
  name="loginForm"
  autocomplete="new-password"
  class="c-n-modal__body"
  [formGroup]="signinForm" 
  #ngForm="ngForm"
  (ngSubmit)="onSignin()"
  *ngIf="activeState === 'logIn'"
  [ngClass]="{'form-is-submitted': signinFormSubmitted}">

  <h5 class="c-n-modal__title t-typo__h3">Log In</h5>

  <div class="c-n-form__group">
    <div class="c-n-form__legend">Email address:</div>
    <div class="c-n-form__control is-required">
      <input
      class="c-n-form__input" 
      type="email"
      autocomplete="new-password"
      formControlName="email" 
      name="email" 
      placeholder="janedoe@diemlife.com"
      aria-label="Enter Email for login"
      appInputFocusTrigger>
      <span class="c-n-form__border"></span>
    </div>
  </div>
  <div 
    *ngIf="signinForm.get('email').invalid && (signinForm.get('email').touched || ngForm.submitted)"
    class="c-n-form__message is-red" 
    role="alert">
    <div *ngIf="signinForm.get('email').errors.required">{{ messages.required }}</div>
    <div *ngIf="signinForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
  </div>
  <div class="c-n-form__group">
    <div class="c-n-form__legend">Password:</div>
    <div class="c-n-form__control is-required">
      <input
        class="c-n-form__input"
        type="password"
        formControlName="password"
        autocomplete="new-password"
        name="password"
        aria-label="Enter Password for login"
        autocomplete="current-password"
        placeholder="PasswordPassword123">
      <span class="c-n-form__border"></span>
    </div>
  </div>
  <div 
    *ngIf="signinForm.get('password').invalid && (signinForm.get('password').touched || ngForm.submitted)"
    class="c-n-form__message is-red" 
    role="alert">
    <div *ngIf="signinForm.get('password').errors.required">{{ messages.required }}</div>
    <div *ngIf="signinForm.get('password').errors.minlength">{{ messages.password.minlength(signinForm.get('password').errors.minlength.requiredLength) }}</div>
    <div *ngIf="signinForm.get('password').errors.maxlength">{{ messages.password.maxlength(signinForm.get('password').errors.maxlength.requiredLength) }}</div>
  </div>
  <div class="c-n-form__under-control">
    <a class="t-typo__link" href="javascript:void(0)" (click)="showRecovery(signinForm.get('email').value)">Forgot password?</a>
  </div>
  <div class="c-n-modal__footer">
    <button
      class="e-btn e-btn_dark"
      [attr.disabled]="(authState | async)?.isLoading ? true : null"
      type="submit"
      aria-label="Submit">
      <span>Submit</span>
    </button>
  </div>
</form> -->
<app-dl-log-in *ngIf="activeState === 'logIn'"></app-dl-log-in>
<app-dl-sign-up *ngIf="activeState === 'signUp'"></app-dl-sign-up>
<app-dl-forgot-password *ngIf="activeState === 'recoveryPassword'"></app-dl-forgot-password>

