import {Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AuthGuard} from 'src/app/_guards/auth-guard.service';
import {ModalService} from 'src/app/components/main/modal/modal.service';
import {AuthGuardService} from './auth-gurad.service';

@Directive({
  selector: '[appAuthGuard]'
})
export class AuthGuardDirective implements OnInit, OnDestroy {
  @Output() appAuthGuard = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;
  private authGuardSubscription: Subscription;
  private isActivated = false;

  constructor(
    private authGuard: AuthGuard,
    private modalService: ModalService,
    private authGuardService: AuthGuardService
  ) {
  }

  ngOnInit() {
    this.authGuardSubscription = this.authGuardService.getSubject().subscribe(() => {
      if (this.isActivated) {
        setTimeout(() => {
          this.authGuard.canBeActivated().pipe(take(1)).subscribe((canBeActivated: boolean) => {
            if (canBeActivated) {
              this.appAuthGuard.emit();
            }
            this.isActivated = false;
          });
        });
      }
    });
    this.subscription = this.clicks.subscribe(e => {
      this.authGuard.canBeActivated().pipe(take(1)).subscribe((canBeActivated: boolean) => {
        if (canBeActivated) {
          this.appAuthGuard.emit(e);
        } else {
          this.modalService.open('auth-modal');
          this.isActivated = true;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.authGuardSubscription) {
      this.authGuardSubscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
