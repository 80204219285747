import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ReaquestHeadersService } from '../../../_services/requestHeaders.service';

@Injectable({
  providedIn: 'root'
})
export class DonateService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}
  backQuest (questId: string, userId: string, payload: any) {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/${userId}/back-quest`,
      payload,
      { headers: headers }
    );
  }
}
