<div class="c-quest-donate__inner" *ngIf="isLoaded && data">

  <div class="c-quest-donate__progress-block">
    <div class="c-quest-donate__ttl">
      <span class="c-quest__article__amount">
        <!-- <b> with ngIf questId 943 userId 66350 is a hack. To delete remove the first <b> and ngIfs -->
        <b>${{data.currentAmount / 100 }}</b> raised of <b>${{data.targetAmount / 100}}</b>
      </span>
      <a *ngIf="enableFundraiseEditLink"
         (click)="triggerEdit()"
         class="c-quest__article__tool"
         href="javascript:void(null)">
        <svg class="icon icon-pencil">
          <use xlink:href="#icon-pencil"></use>
        </svg>
        <span>Edit</span>
      </a>
    </div>
    <ul class="c-quest-donate__info" *ngIf="enableDetailedTotals">
      <li><b>{{'$' + raisedByDirectDonations / 100 }}</b> raised by direct donations</li>
      <li><b>{{'$' + raisedByTeamMembers / 100 }}</b> raised by team members</li>
    </ul>
    <div class="c-quest-donate__range">
      <div class="c-quest-donate__progress">
        <span [ngStyle]="{'width': data.currentAmount / (data.targetAmount / 100) + '%'}"></span>
      </div>
      <button class="c-quest-donate__range__trigger"
              *ngIf="enableBackingButton && (data.active && checkFundraisingEndDateIsInFuture())"
              (click)="requiresBackingConfirmation ? triggerConfirmation('backme') : navigateToBacking()"
              [disabled]="!data.active || !checkFundraisingEndDateIsInFuture()"
              type="button">
      </button>
    </div>
    <p class="c-quest-donate__desc">Backed
      <b>{{data.timesBacked}}</b>&nbsp;{{data.timesBacked === 1 ? 'time' : 'times'}}
    </p>
  </div>

  <div class="c-quest-donate__buttons-block">
    <button *ngIf="enableBackingButton"
            (click)="requiresBackingConfirmation ? triggerConfirmation('backme') : navigateToBacking()"
            [disabled]="!checkFundraisingEndDateIsInFuture()"
            class="c-btn c-btn_ms c-btn_dark c-btn_full"
            type="button">
      <span>{{(data.active && checkFundraisingEndDateIsInFuture() === true) ? (userNonProfit ? 'Donate' : 'Back me') : 'Backing closed'}}</span>
    </button>
    <button *ngIf="includeFundraiseButton && data.active && checkFundraisingEndDateIsInFuture()"
            (appAuthGuard)="disableFundraiseButton ? doNothing() : triggerConfirmation('fundraise')"
            [disabled]="disableFundraiseButton"
            class="c-btn c-btn_ms c-btn_dark c-btn_full"
            type="button">
      <span *ngIf="disableFundraiseButton">{{'FUNDRAISING'}}</span>
      <span *ngIf="!disableFundraiseButton">{{ isMainQuest ? 'CREATE TEAM/FUNDRAISE' : 'FUNDRAISE'}}</span>
    </button>
  </div>

</div>
