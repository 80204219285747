import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ModalService} from '../../../modal/modal.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FundraiseSwitchModel} from './fundraise-switch.model';
import {Subscription} from 'rxjs';
import {QuestService} from '../../../../../_services/quest.service';

@Component({
  selector: 'app-fundraise-switch',
  templateUrl: './fundraise-switch.component.html',
  styleUrls: ['./fundraise-switch.component.styl']
})
export class FundraiseSwitchComponent implements OnInit, OnChanges, OnDestroy {
  @Output() fundraisingOutput: EventEmitter<FundraiseSwitchModel> = new EventEmitter<FundraiseSwitchModel>();
  @Input() fundraisingInput: FundraiseSwitchModel;
  @Input() hasValidStripeAccount = false;
  openedDialog: string;
  fundraisingActivated: boolean;
  fundForm: FormGroup;
  submitted = false;
  subscription: Subscription;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private questService: QuestService
  ) {
  }

  private resetForm() {
    const amount = this.fundraisingInput && this.fundraisingInput.targetAmount
      ? String(Math.floor(this.fundraisingInput.targetAmount / 100.0).toString(10))
      : '100';
    const currency = this.fundraisingInput && this.fundraisingInput.currency
      ? String(this.fundraisingInput.currency)
      : 'usd';
    const termsAccepted = false;
    this.fundForm.setValue({
      currency: currency,
      amount: amount,
      termsAccepted: termsAccepted
    });
  }

  ngOnInit() {
    this.fundForm = this.formBuilder.group({
      currency: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      termsAccepted: [false, [Validators.requiredTrue]]
    });
    this.resetForm();
    this.subscription = this.questService.getFundraisingSwitchValue()
    .subscribe(item => this.fundraisingActivated = item);
  }

  ngOnChanges() {
    if (this.fundraisingInput) {
      this.fundraisingActivated = this.fundraisingInput.enabled && this.fundraisingInput.targetAmount > 0;
      if (this.fundraisingActivated) {
        this.resetForm();
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private openModal(id: string) {
    this.openedDialog = id;
    setTimeout(() => {
      this.modalService.open(id);
    });
  }

  onChange() {
    if (this.fundraisingActivated) {
      this.openModal('fund_modal');
    } else {
      this.fundraisingOutput.emit({
        currency: String(this.fundForm.get('currency').value),
        enabled: false,
        targetAmount: 0
      });
    }
  }

  closeDialog() {
    setTimeout(() => {
      this.submitted = false;
      this.modalService.close('fund_modal');
      setTimeout(() => {
        this.openedDialog = null;
      });
    });
  }

  enableFundraising() {
    this.submitted = true;
    if (this.fundForm.valid) {
      this.fundraisingOutput.emit({
        currency: String(this.fundForm.get('currency').value),
        enabled: Boolean(this.fundForm.get('termsAccepted').value),
        targetAmount: Math.floor(Number(this.fundForm.get('amount').value) * 100.0)
      });
      this.closeDialog();
    } else {
      this.fundraisingActivated = false;
    }
  }

}
