import {Component, Input, OnInit, EventEmitter, Output, Inject, OnDestroy, ElementRef, AfterViewInit, OnChanges, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {QuestService} from '../../../../../_services/quest.service';
import {VALIDATION_MESSAGES, ValidationMessages} from 'src/app/app.constants';
import {PreparedQuestComment} from './comment.type';
import {SearchService} from '../../../search/search.service';
import {ProfileAvatarService} from 'src/app/components/separate/profile-avatar/profile-avatar.service';
import {CommentService} from './comment.service';
import {WebviewNavigationDataType} from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import {AnimateList, AnimateItems, AddCommentToList} from 'src/app/animations/comments.animations';
import * as fromProfile from '../../../../pages/profile/store/profile.reducer';
import * as fromApp from '../../../../../_store/app.reducers';
import * as CommentsActions from '../store/quest-components.actions';

declare var $: any;

@Component({
  selector: 'app-comment',
  animations: [AnimateList, AnimateItems, AddCommentToList],
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.styl']
})
export class CommentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  @Output() closeAnotherReplyForms: EventEmitter<void> = new EventEmitter<void>();
  @Output() increaseRepliesLimit: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateRepliesCount: EventEmitter<void> = new EventEmitter<void>();
  @Input('webviewMode') webviewMode: boolean;
  @Input('comment') comment: PreparedQuestComment;
  @Input('reply') reply = false;
  @Input('parentCommentId') parentCommentId: number = null;
  @Input('closeReplyForm') closeReplyForm: boolean;
  validationMessages: ValidationMessages = VALIDATION_MESSAGES;
  profileState: Observable<fromProfile.State>;
  viwerId: number;
  form: FormGroup;
  replyForm: FormGroup;
  message = '';
  isLoading: string;
  isReplyFormOpened = false;
  isLikeLoading = false;
  hasCurrentUserLiked = false;
  tributeOptions: any;
  jqueryLinks: any;
  commentsViewLimit = 2;
  showRepliesList = false;
  addCommentToListState: string;
  get limit() {
    return this.reply ? this.comment.limit : this.comment.replies.length;
  }
  constructor(
    @Inject(DOCUMENT) private document: any,
    private elRef: ElementRef,
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private searchService: SearchService,
    private profileAvatarService: ProfileAvatarService,
    private router: Router,
    private commentService: CommentService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.profileState = this.store.select('userInfo');
    this.tributeOptions = this.commentService.getTributeOptions();
  }

  ngOnInit() {
    this.profileState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.viwerId = state.id;
      this.hasCurrentUserLiked = (this.comment.likes || []).filter(like => like.liker === this.viwerId).length > 0;
    });
    this.form = this.fb.group({
      editor: [this.comment.comment, [Validators.required]]
    });
    this.replyForm = this.fb.group({
      editor: []
    });
    this.addCommentToListState = 'in';
  }

  ngOnChanges() {
    if (this.closeReplyForm !== null) {
      this.isReplyFormOpened = this.closeReplyForm;
    }
  }

  prepareCommentCRLF(comment: string) {
    return comment.replace(/\n/gm, '<br>');
  }

  ngAfterViewInit(): void {
    if (this.webviewMode) {
      setTimeout(() => {
        this.initLinksClicker();
      }, 0);
    }
  }

  ngOnDestroy() {
    if (this.jqueryLinks && this.jqueryLinks.length > 0) {
      this.jqueryLinks.off('click.questComments');
    }
  }

  editComment() {
    if (!this.isLoading || this.form.valid) {
      this.isLoading = 'EDIT';
      this.questService.editQuestComment(this.comment.id, this.form.value.editor).subscribe((newComment: PreparedQuestComment) => {
        this.isLoading = null;
        this.store.dispatch(new CommentsActions.UpdateComment(newComment));
        this.toggleEditComment();
      });
    }
  }

  toggleEditComment() {
    const payload: CommentsActions.ToggleEditCommentState = {
      commentId: this.comment.id,
      isEditable: !this.comment.editable
    };
    this.store.dispatch(new CommentsActions.ToggleEditComment(payload));
  }

  toggleLikeComment() {
    this.isLikeLoading = true;
    this.questService.toggleQuestLike(this.comment.id).subscribe((liked: boolean) => {
      this.hasCurrentUserLiked = liked;
      this.comment.likes = liked
        ? [...(this.comment.likes || []), {
          id: null,
          liker: this.viwerId,
          createdOn: null
        }]
        : [...(this.comment.likes || [])].filter(like => like.liker !== this.viwerId);
      this.isLikeLoading = false;
    });
  }

  toggleReplyComment() {
    this.showReplies();
    if (!this.isReplyFormOpened) {
      this.closeAnotherReplyForms.emit();
      setTimeout(() => {
        if (!this.replyForm.get('editor').value) {
          this.replyForm.get('editor').patchValue('@' + this.comment.userName + ' ');
        }
        this.isReplyFormOpened = true;
        this.focusOnField();
      }, 0);
    } else {
      this.focusOnField();
    }
  }
  cancelReply() {
    this.isReplyFormOpened = false;
  }
  focusOnField() {
    setTimeout(() => {
      const $el = $(this.elRef.nativeElement);
      $('html, body').animate({
          scrollTop: $el.find('.c-dl-ns-post__feedback').offset().top - 100
      }, 350, () => {
        const $replyEl = $el.find('.js-reply-trigger');
        const $replyElValue = $replyEl.val();
        $replyEl.val('');
        $replyEl.focus().val($replyElValue);
      });
    }, 350);
  }

  onCloseAnotherReplyForms() {
    this.closeAnotherReplyForms.emit();
  }

  replyToComment() {
    if (!this.isLoading) {
      const replyId = this.reply && this.parentCommentId ? this.parentCommentId : this.comment.id;
      this.isLoading = 'REPLY';
      // tslint:disable-next-line:max-line-length
      this.questService.addNewQuestComment(this.comment.questId, this.viwerId, this.replyForm.value.editor, replyId).subscribe((res: PreparedQuestComment) => {
        this.isLoading = null;
        this.isReplyFormOpened = false;
        this.replyForm.get('editor').patchValue('');
        if (this.parentCommentId) {
          this.increaseRepliesLimit.emit();
        } else {
          this.loadMoreReplies(1);
        }
        this.store.dispatch(new CommentsActions.AddReply(res, replyId));
        this.emitRepliesCount();
      });
    }
  }
  onNavigate(navigateData: WebviewNavigationDataType) {
    if (this.webviewMode) {
      this.navigateToEmit.emit(navigateData);
    } else if (navigateData.type === 'LinkInside') {
      this.router.navigate(navigateData.routerLink);
    } else {
      this.document.location.href = navigateData.url;
    }
  }

  initLinksClicker() {
    const that = this;
    this.jqueryLinks = $(this.elRef.nativeElement).children('.c-comment__wrapper').find('a');
    this.jqueryLinks.off('click.questComments').on('click.questComments', function (event) {
      event.preventDefault();
      const href = $(this).attr('href');
      if (href && href.toLowerCase().indexOf('void(0)') === -1) {
        that.onNavigate({
          type: 'LinkOutside',
          url: href
        });
      }
    });
  }

  loadMoreReplies(count?: number) {
    this.comment.limit += count ? count : 3;
  }
  emitRepliesCount() {
    console.log('emitRepliesCount');
    this.updateRepliesCount.emit();
  }

  showReplies() {
    this.showRepliesList = true;
  }

}
