
<div class="c-header__container c-container">
  <a [routerLink]="['/']" aria-label="Back to My Quests" class="c-header__logo">
    <svg class="icon icon-logo"><use xlink:href="#icon-logo"></use></svg>
  </a>
  <div class="c-header__tools">
    <button class="c-header__tool h-btn-reset" (click)="openMainDlNav()" [ngClass]="{'has-notification': hasNotifications}" type="button">
      <div title="Menu" class="c-header__burger c-header__notify" aria-label="Open menu">
        <span></span>
      </div>
    </button>
  </div>
</div>
