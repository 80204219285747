<div class="c-quest-comments row-xm">
  <app-newsfeed-ctrl
    *ngIf="(authState | async)?.authenticated"
    class="c-dl-newsfeed-ctrl"
    [questId]="questId"
    [viewerId]="viewerId">
  </app-newsfeed-ctrl>

  <div class="c-quest-comments__body c-comments__list" @animateList>

    <ng-container *ngIf="isLoaded">
      <app-newsfeed-post
        class="c-dl-ns-post"
        *ngFor="let comment of comments | slice:0:limit"
        @animateItemsUp
        [viewerId]="viewerId"
        [questId]="questId"
        [comment]="comment"
        [webviewMode]="webviewMode"
        [closeReplyForm]="closeReplyForm"
        (closeAnotherReplyForms)="onCloseAnotherReplyForms()"
        (navigateToEmit)="onNavigate($event)">
      </app-newsfeed-post>
    </ng-container>

  </div>

  <div class="c-comment__show-all-replies" *ngIf="isLoaded && comments?.length > limit">
    <a href="javascript:void(null)" (click)="loadMore()">Load more</a>
  </div>
</div>
