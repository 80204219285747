<button class="c-btn c-btn_md c-btn_dark" (click)="openModalBankAccountModal()" type="button">
  <span>Add Bank account</span>
  <svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
</button>

<app-modal id="bank-account-modal" *ngIf="openedModal" (closeModal)="onCloseModal()">
  <h3 class="modal-title">Add payment method</h3>
  <form class="modal-body c-payments c-container" *ngIf="bankAccountForm" [formGroup]="bankAccountForm" (ngSubmit)="saveBankAccounts()" [ngClass]="{'form-is-submitted': formSubmitted}">
    <div class="c-payments__group">
      <div class="c-payments__group__body">
        <div class="c-payments__tab">
          <ul class="c-payments__list">
            <li class="c-payments__list__item">
              <span class="c-forms__legend">ROUTING NUMBER:</span>
              <div class="c-forms__group">
                <div class="c-forms__control is-required">
                  <input formControlName="routingNumber" type="text" placeholder="123456789" class="c-forms__input">
                </div>
                <div *ngIf="(bankAccountForm.get('routingNumber').touched || formSubmitted) && bankAccountForm.get('routingNumber').invalid" class="c-forms__message is-bottom is-red" role="alert">
                    <div *ngIf="bankAccountForm.get('routingNumber').errors.required">{{ messages.required }}</div>
                    <div *ngIf="bankAccountForm.get('routingNumber').errors.minlength || bankAccountForm.get('routingNumber').errors.maxlength">Routing number must have 9 digits</div>
                </div>
              </div>
            </li>
            <li class="c-payments__list__item">
              <span class="c-forms__legend">ACCOUNT NUMBER:</span>
              <div class="c-forms__group">
                <div class="c-forms__control is-required">
                  <input formControlName="accountNumber" type="text" placeholder="123456789" class="c-forms__input">
                </div>
                <div *ngIf="(bankAccountForm.get('accountNumber').touched || formSubmitted) && bankAccountForm.get('accountNumber').invalid" class="c-forms__message is-bottom is-red" role="alert">
                    <div *ngIf="bankAccountForm.get('accountNumber').errors.required">{{ messages.required }}</div>
                </div>
              </div>
            </li>
            <li class="c-payments__list__item">
              <span class="c-forms__legend">ACCOUNT HOLDER NAME:</span>
              <div class="c-forms__group">
                <div class="c-forms__control is-required">
                  <input formControlName="accountHolderName" type="text" placeholder="John Doe" class="c-forms__input">
                </div>
                <div *ngIf="(bankAccountForm.get('accountHolderName').touched || formSubmitted) && bankAccountForm.get('accountHolderName').invalid" class="c-forms__message is-bottom is-red" role="alert">
                    <div *ngIf="bankAccountForm.get('accountHolderName').errors.required">{{ messages.required }}</div>
                </div>
              </div>
            </li>
            <li class="c-payments__list__item">
              <span class="c-forms__legend">ACCOUNT HOLDER TYPE:</span>
              <div class="c-forms__group">
                <div class="c-forms__control is-required">
                  <select
                    formControlName="accountHolderType"
                    bs-select-directive
                    [events]="eventsSubject.asObservable()"
                    data-style="c-forms__input"
                    title="Nothing selected"
                    (close)="bankAccountForm.get('accountHolderType').markAsTouched()">
                    <option [value]="'individual'">Individual</option>
                    <option [value]="'company'">Company</option>
                  </select>
                </div>
                <div *ngIf="(bankAccountForm.get('accountHolderType').touched || formSubmitted) && bankAccountForm.get('accountHolderType').invalid" class="c-forms__message is-bottom is-red" role="alert">
                  <div *ngIf="bankAccountForm.get('accountHolderType').errors.required">{{ messages.required }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="c-forms__group">
      <button class="c-btn c-btn_full c-btn_md c-btn_dark" type="submit">Submit</button>
    </div>
  </form>
</app-modal>