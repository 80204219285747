import {Action} from '@ngrx/store';
import {FormMode, PincodeValidation} from './auth.reducer';

export const TRY_SIGNIN = 'TRY_SIGNIN';
export const SIGNIN = 'SIGNIN';
export const TRY_LOGOUT = 'TRY_LOGOUT';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const TRY_REFRESH_TOKEN = 'TRY_REFRESH_TOKEN';
export const SET_EXPIRES = 'SET_EXPIRES';
export const TRY_RECOVERY_PASS = 'TRY_RECOVERY_PASS';
export const PASS_RECOVERY_REQUESTED = 'PASS_RECOVERY_REQUESTED';
export const RECOVERY_PASS = 'RECOVERY_PASS';
export const SET_PIN_INFO = 'SET_PIN_INFO';
export const AUTH_UPDATE_FORM_STATE = 'AUTH_UPDATE_FORM_STATE';
export const SELECT_FORM_MODE = 'SELECT_FORM_MODE';
export const SET_DEFAULT_EMAIL = 'SET_DEFAULT_EMAIL';
export const AUTH_SET_LOADING_STATE = 'AUTH_SET_LOADING_STATE';

export class AuthSetLoadingState implements Action {
  readonly type = AUTH_SET_LOADING_STATE;

  constructor(public payload: boolean) {}
}

export class AuthUpdateFormState implements Action {
  readonly type = AUTH_UPDATE_FORM_STATE;

  constructor(public payload: boolean) {
  }
}

export class SetPinInfo implements Action {
  readonly type = SET_PIN_INFO;

  constructor(public payload: PincodeValidation) {
  }
}

export class TryRecoveryPass implements Action {
  readonly type = TRY_RECOVERY_PASS;

  constructor(public payload: { email: string }) {
  }
}

export class PassRecoveryRequested implements Action {
  readonly type = PASS_RECOVERY_REQUESTED;

  constructor() {}
}

export class RecoveryPass implements Action {
  readonly type = RECOVERY_PASS;

  constructor(public payload: PasswordRecoveryData) {
  }
}

export class TrySignin implements Action {
  readonly type = TRY_SIGNIN;

  constructor(public payload: { email: string, password: string }) {
  }
}

export class Signin implements Action {
  readonly type = SIGNIN;
}

export class TryLogout implements Action {
  readonly type = TRY_LOGOUT;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  constructor(public payload: { token: string, tokenUpdateTime: number }) {
  }
}

export class RefreshToken implements Action {
  readonly type = REFRESH_TOKEN;

  constructor(public payload: { token: string, tokenUpdateTime: number }) {
  }
}

export class TryRefreshToken implements Action {
  readonly type = TRY_REFRESH_TOKEN;
}

export class SetExpires implements Action {
  readonly type = SET_EXPIRES;

  constructor(public payload: number) {
  }
}

export class SelectFormMode implements Action {
  readonly type = SELECT_FORM_MODE;

  constructor(public mode: FormMode) {
    console.log('AuthActions::SelectFormMode', mode);
  }
}

export class SetDefaultEmail implements Action {
  readonly type = SET_DEFAULT_EMAIL;

  constructor(public email: string) {
    console.log('AuthActions::SetDefaultEmail', email);
  }
}

export type AuthActions = TrySignin |
  Signin |
  TryLogout |
  Logout |
  SetToken |
  TryRefreshToken |
  RefreshToken |
  SetExpires |
  TryRecoveryPass |
  PassRecoveryRequested |
  RecoveryPass |
  SetPinInfo |
  AuthUpdateFormState |
  SelectFormMode |
  SetDefaultEmail |
  AuthSetLoadingState;

export interface PasswordRecoveryData {
  token: string;
  password: string;
  repeatPassword: string;
}
