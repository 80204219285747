import * as TicketFlowActions from './ticket-flow.actions';
import { Participant } from '../../../ticket-flow.typings';

export interface TicketSteps {
  selectTickets: boolean;
  parcipiantsInfo: boolean;
  buyerInfo: boolean;
  confirmInfo: boolean;
  success: boolean;
}

export interface State {
  steps: TicketSteps;
  ticketsInfo: Participant[];
}

export const initialState: State = {
  steps: {
    selectTickets: false,
    parcipiantsInfo: false,
    buyerInfo: false,
    confirmInfo: false,
    success: false
  },
  ticketsInfo: []
};

export function TicketFlowReducer(state = initialState, action: TicketFlowActions.Actions) {
  switch (action.type) {
    case (TicketFlowActions.TICKET_FLOW_UPDATE_STEPS):
      return {
        ...state,
        steps: action.payload
      };
    case (TicketFlowActions.TICKET_FLOW_ADD_INFO):
      const newTicketInfo = [...state.ticketsInfo];
      newTicketInfo[action.payload.index] = action.payload.value;
      return {
        ...state,
        ticketsInfo: newTicketInfo
      };
    case (TicketFlowActions.TICKET_RESET_INFO):
      return {
        ...initialState
      };
    default:
      return state;
  }
}
