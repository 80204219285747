<div class="bubble-cont">
  <div class="terms--bottom">
    <div class="terms--mod clearfix">
      <div class="terms--mod-txt">
        <h3 class="terms--mod-title">Contacting Us</h3>
        <p>If there are any questions regarding this Privacy Policy, you may contact us at:</p>
        <dl>
          <dt>DIEMlife, Inc.</dt>
          <dt>85 East 10th St Ste. M</dt>
          <dt>New York, NY 10003</dt>
          <dt>United States</dt>
          <dt>help@diemlife.com</dt>
        </dl>
        <p>Effective December 18, 2018</p>
      </div>
    </div>
  </div>
</div>
