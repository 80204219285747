import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {NotificationsService} from './notifications.service';
import {ExploreCardListType, NotificationType} from 'diemlife-commons/dist/diemlife-commons-model';
import {HTTPStatus} from 'src/app/interceptors/loader.interceptor';
import {Router} from '@angular/router';
import tools from './../../../_tools';

import * as fromApp from './../../../_store/app.reducers';
import * as fromProfile from './../../pages/profile/store/profile.reducer';
import {MyQuestsSevice} from '../../pages/my-quests/my-quests.service';
import * as fromNotifications from './store/notifications.reducer';
import * as NotificationsActions from './store/notifications.actions';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.styl']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  activityStatusSubscriber: Subscription;
  activityStatus = false;
  userInfo: fromProfile.State = fromProfile.initialState;
  userInfoState: Observable<fromProfile.State>;
  userInfoSubscriber: Subscription;
  notificationsInfoState: Observable<fromNotifications.State>;
  notificationsInfoSubscriber: Subscription;
  notifications: NotificationType[] = [];
  reachedEnd = false;
  isLoaded = false;
  btnIsLoading = false;
  loadStep = 10;
  pagination = {
    from: 0,
    to: this.loadStep
  };
  usersQuests: { [key: number]: ExploreCardListType } = {};

  constructor(
    private httpStatus: HTTPStatus,
    private notificationsService: NotificationsService,
    private myQuestsService: MyQuestsSevice,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.activityStatusSubscriber = this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.activityStatus = status ? status : null;
    });
    this.userInfoState = this.store.select('userInfo');
    this.notificationsInfoState = this.store.select('notifications');

    this.userInfoSubscriber = this.userInfoState.subscribe(
      tools.debounce((userInfo: fromProfile.State) => {
        this.userInfo = userInfo;
        this.notificationsService.getNotifications(userInfo.id, 0, this.loadStep).subscribe((res: NotificationType[]) => {
          this.notifications = res;
          this.isLoaded = true;
        });
      }, 0)
    );
    forkJoin([
      this.myQuestsService.getActiveQuestsForUser(),
      this.myQuestsService.getQuestsCompletedForUser()
    ]).subscribe(([activeQuests, completedQuests]) => {
      [...activeQuests, ...completedQuests].forEach(quest => {
        if (!this.usersQuests.hasOwnProperty(quest.id)) {
          this.usersQuests[quest.id] = quest;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.userInfoSubscriber) { this.userInfoSubscriber.unsubscribe(); }
    if (this.notificationsInfoSubscriber) { this.notificationsInfoSubscriber.unsubscribe(); }
    if (this.activityStatusSubscriber) { this.activityStatusSubscriber.unsubscribe(); }
  }

  closeNotifications() {
    this.store.dispatch(new NotificationsActions.HideNotifications());
    this.modalService.close('notifications-modal');
  }

  goToThatPage(item: NotificationType) {
    this.notificationsService.readNotification(item).subscribe(() => {
      switch (item.type) {
        case 'FRIEND_REQUEST':
          this.router.navigate(['/friends']);
          break;
        case 'PROFILE_BACKED':
          this.router.navigate(['/profile']);
          break;
        case 'QUEST_ACHIEVED':
        case 'QUEST_SAVED':
        case 'COMMENT':
        case 'QUEST_STARTED':
        case 'COMMENT_LIKED':
        case 'MILESTONE_ACHIEVED':
        case 'PHOTO_VIDEO_ADDED':
        case 'QUEST_BACKED':
        case 'EVENT_STARTED':
        case 'FUNDRAISER_STARTED':
        case 'FOLLOWED':
        default:
          this.router.navigate(['/quest-detail', item.quest.seoSlugs.questId, (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId)
            ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
            : item.userId)]);
          break;
      }
      this.closeNotifications();
    });
  }

  loadMore() {
    if (!this.btnIsLoading) {
      this.btnIsLoading = true;
      this.pagination.from += this.loadStep;
      this.pagination.to += this.loadStep;
      this.notificationsService.getNotifications(this.userInfo.id, this.pagination.from, this.pagination.to)
        .subscribe((data: NotificationType[]) => {
          if (data.length) {
            this.notifications = [...this.notifications, ...data];
          } else {
            this.reachedEnd = true;
          }
          this.btnIsLoading = false;
        });
    }
  }

}
