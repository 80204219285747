import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {WindowRef} from 'src/app/_global/window-ref.module';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.styl']
})
export class HelpComponent implements OnInit, OnDestroy {
  webViewMode = false;
  routerSubscription: Subscription;
  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(
    private winRef: WindowRef,
    private route: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    private titleService: Title
  ) {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.webViewMode = this.route.snapshot.queryParams['_web-view'] === 'true';
      }
    });
  }

  ngOnInit() {
    this.setMetaTags();
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  goToPage(link, mode) {
    this.winRef.nativeWindow.nsWebViewInterface.emit('openLinkFromWebView', {link, mode});
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | Help');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | Help'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'We are here to help! Frequently asked questions about DIEMlife'});
  }

}
