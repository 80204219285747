import {Component, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
// import {Quest, QuestInfo, QuestPreparedPhoto, QuestUserInfo} from '../quest.type';
import {QuestService} from '../../../../_services/quest.service';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import {QuestDropdownContext} from '../quest-dropdown/quest-dropdown.component';
import {FundraisingLinkType, Quest, QuestInfo, QuestUserInfo} from 'diemlife-commons/dist/diemlife-commons-model';
import { QuestPreparedPhoto } from '../quest.type';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-quest-header',
  templateUrl: './quest-header.component.html',
  styleUrls: ['./quest-header.component.styl']
})
export class QuestHeaderComponent implements OnChanges {

  @Input() questData: Quest;
  @Input() fundraisingLink?: FundraisingLinkType;
  @Input() showStartTrigger?: string;
  @Input() completionPercentage: number;
  @Input() statusText?: string;
  @Input() status: string;
  @Input() allowEditCoverImage: boolean;
  @Input() showProgress: boolean;
  @Output() changeCoverImage: EventEmitter<void> = new EventEmitter<void>();

  profileState: Observable<fromProfile.State>;
  questInfo: QuestInfo;
  questUserInfo: QuestUserInfo;
  photo: QuestPreparedPhoto;
  dropdownContext: QuestDropdownContext;

  constructor(
    private questService: QuestService,
    private store: Store<AppState>
  ) {
    this.profileState = this.store.select('userInfo');
  }

  ngOnChanges() {
    this.questInfo = this.questData.quest;
    this.questUserInfo = this.questData.user;
    this.photo = this.questService.prepareCoverPhoto(this.questInfo.photo, this.questInfo.pillar);
    this.profileState.pipe(take(1)).subscribe((currentUser: fromProfile.State) => {
      this.dropdownContext = {
        id: this.questInfo.id,
        title: this.questInfo.title,
        mode: this.questData.quest.mode,
        shareUrl: this.questData.questShareUrl,
        copyable: this.questInfo.copyAllowed,
        followed: this.questData.userHasFollowed,
        saved: this.questData.userHasSaved,
        active: QuestService.hasUserActivity(this.questData.activityInfo, currentUser.id),
        editable: QuestService.isEditableByUser(this.questInfo, currentUser),
        privacyLevel: this.questData.quest.privacyLevel
      };
    });
  }

  onChangeCoverImage() {
    this.changeCoverImage.emit();
  }

}
