import { Action } from '@ngrx/store';
import * as fromProfile from './profile.reducer';

export const TRY_GET_USER_INFO = 'TRY_GET_USER_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';


export class UpdateUserInfo implements Action {
  readonly type = UPDATE_USER_INFO;

  constructor(public payload: fromProfile.State) {}
}
export class TryGetUserInfo implements Action {
  readonly type = TRY_GET_USER_INFO;
}

export class GetUserInfo implements Action {
  readonly type = GET_USER_INFO;

  constructor(public payload: fromProfile.State) {}
}

export class ClearUserInfo implements Action {
  readonly type = CLEAR_USER_INFO;
}

export type ProfileActions =  TryGetUserInfo |
                              GetUserInfo |
                              ClearUserInfo |
                              UpdateUserInfo;
