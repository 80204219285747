<div class="bubble-cont">
  <div class="terms--nav d-fixed-nav"
    id="sticko"
    appFixedNav
    (stickyChange)="onStickyChange($event)"
    [class.webViewMode]="webViewMode"
    [class.sticky]="stickyNav"
    [ngStyle]="{'height': navPlaceholderHeight}">
    <div class="terms--nav-outer d-fixed-nav-inner" >
      <ul class="terms--nav-inner">
        <li *ngFor="let tab of tabs.list">
          <a  [routerLink]="['/legal/' + tab.id]" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }"
              class="terms--nav-inner-a"
              queryParamsHandling="merge"
              href="javascript:void(0)">{{tab.caption}}</a>
        </li>
      </ul>
    </div>
  </div>

  <router-outlet></router-outlet>

  <button type="button" class="scroll-btn" (click)="scrollTop(500)"></button>

</div>
