import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromDonate from './store/donate.reducer';
import {Observable, Subscription} from 'rxjs';
import { QuestService } from 'src/app/_services/quest.service';
// import {Quest, QuestPreparedPhoto} from '../../main/quest/quest.type';
import {ActivatedRoute} from '@angular/router';
import { QuestPreparedPhoto } from '../../main/quest/quest.type';
import { Quest, QuestTeam, FundraisingLinkType } from 'diemlife-commons/dist/diemlife-commons-model';
import { AppState } from 'src/app/_store/app.reducers';
import {UiSelectTeamService} from 'src/app/modules/shared-module/elements/ui-select-team.service';


@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.styl']
})
export class DonateComponent implements OnInit, OnDestroy {
  donateState: Observable<fromDonate.State>;
  donateSubscriber: Subscription;
  photo: QuestPreparedPhoto;
  questInfo: Quest;
  questId: number;
  userId: number;
  teamsList: QuestTeam[];
  fundraisingLink: FundraisingLinkType = null;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private questService: QuestService,
  ) {
    this.donateState = this.store.select('donate');
  }

  ngOnInit() {
    this.donateSubscriber = this.route.params.subscribe(params => {
      this.questId = Number(params.questId);
      this.userId = Number(params.userId);

      this.questService.getQuestDetail(this.questId, this.userId).subscribe((res: Quest) => {
        this.questInfo = res;
        this.photo = this.questService.prepareCoverPhoto(this.questInfo.quest.photo, this.questInfo.quest.pillar);
      });

      this.questService.getFundraisingLink(this.questId, this.userId).subscribe((res: any) => {
        this.fundraisingLink = res;
      });
    });
  }

  ngOnDestroy() {
    if (this.donateSubscriber) {
      this.donateSubscriber.unsubscribe();
    }
  }

  onActivate(elementRef) {
    if (elementRef.teamChanged) {
      elementRef.teamChanged.subscribe(newUserId => {
        this.onTeamChanged(newUserId);
      })
    }
  }

  onTeamChanged(newUserId) {
    this.fundraisingLink = null;
    this.questService.getFundraisingLink(this.questId, newUserId).subscribe((res: any) => {
      this.fundraisingLink = res;
      console.log("TeamChanged : ", this.fundraisingLink);
    });
  }
}
