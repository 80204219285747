import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {NotificationsService} from './../notifications/notifications.service';
import {Store, select} from '@ngrx/store';
import {take, takeUntil, tap, takeWhile, filter} from 'rxjs/operators';
import {ModalService} from '../modal/modal.service';

import * as fromApp from './../../../_store/app.reducers';
import * as fromProfile from './../../pages/profile/store/profile.reducer';
import * as fromNotifications from './../notifications/store/notifications.reducer';
import * as NotificationsActions from './../notifications/store/notifications.actions';
import * as SearchActions from './../search/store/search.actions';

@Component({
  selector: '[id="header"]',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  notificationsInfo: fromNotifications.State = fromNotifications.initialState;
  hasNotifications = false;
  userId: number = null;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
    private notificationsService: NotificationsService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.store
      .pipe(
        select('userInfo'),
        // * with inclusive flag "true", the value causing the predicate to return false will also be emitted
        takeWhile((state) => typeof state.id !== 'number', true),
        filter(state => typeof state.id === 'number'),
        tap((state: fromProfile.State) => {
          this.userId = state.id;
          this.getNotificationsStatus(this.userId);
        })
      )
      .subscribe();

    this.store
      .pipe(
        select('notifications'),
        takeUntil(this.destroyed$),
        tap((res: fromNotifications.State) => {
          this.notificationsInfo = res;
        })
      )
      .subscribe();

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        tap((val) => {
          if (val instanceof NavigationEnd && this.userId) {
            this.getNotificationsStatus(this.userId);
          }
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.store.dispatch(new NotificationsActions.ResetNotifications());
  }

  getNotificationsStatus(userId: number): void {
    this.notificationsService
      .hasNotifications(userId)
      .subscribe((res: number) => {
        this.hasNotifications = !!res;
        this.store.dispatch(new NotificationsActions.SetNotificationsInfo(res));
      });
  }

  openMainDlNav(): void {
    this.modalService.open('main-nav-modal');
  }

  showNotifications(): void {
    this.store.dispatch(new NotificationsActions.ShowNotifications());
  }

  hideNotifications(): void {
    this.store.dispatch(new NotificationsActions.HideNotifications());
  }

  toggleNotifications(): void {
    if (this.notificationsInfo.active) {
      this.hideNotifications();
    } else {
      this.showNotifications();
    }
  }

  showSearch(): void {
    this.store.dispatch(new SearchActions.ShowSearch());
  }

  hideSearch(): void {
    this.store.dispatch(new SearchActions.HideSearch());
  }

  toggleSearch(): void {
    if (this.notificationsInfo.active) {
      this.hideSearch();
    } else {
      this.showSearch();
    }
  }
}
