import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.styl']
})
export class TermsComponent implements OnInit {
  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(private meta: Meta, private titleService: Title) { }

  ngOnInit() {
    this.setMetaTags();
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | Terms of Service');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | Terms of Service'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'DIEMlifes Terms of Service information'});
  }
}
