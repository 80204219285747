import { Component, OnInit, Input } from '@angular/core';
import * as fromPayments from '../store/payments.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentsService } from '../payments.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyConfigService } from '../../../../_services/snotify-config.service';
import { AccountService } from '../../../pages/account/account.service';
import { take } from 'rxjs/operators';
import * as PaymentsActions from '../store/payments.actions';
import * as Constants from '../../../../app.constants';
import { ModalService } from '../../modal/modal.service';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-verify-bank-dialog',
  templateUrl: './verify-bank-dialog.component.html',
  styleUrls: ['./verify-bank-dialog.component.styl']
})
export class VerifyBankDialogComponent implements OnInit {
  messages = Constants.VALIDATION_MESSAGES;
  paymentsState: Observable<fromPayments.State>;
  bankVerify: FormGroup;
  bankAccount: fromPayments.BankAccount;
  isLoading = false;
  submitted = false;
  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private paymentsService: PaymentsService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private accountService: AccountService,
    private modalService: ModalService
  ) {
    this.paymentsState = this.store.select('paymentsInfo');
  }

  ngOnInit() {
    this.bankVerify = this.fb.group({
      firstDebit: ['', [Validators.required]],
      secondDebit: ['', [Validators.required]]
    });
    this.paymentsState.subscribe((state: fromPayments.State) => {
      this.bankAccount = state.bankAccounts[0];
    });
  }
  verifyBankAccount = () => {
    this.submitted = true;
    if (this.bankVerify.invalid) {
      this.snotifyService.error(
        'Please fill in all fields',
        null,
        this.snotifyConfigService.getConfig()
      );
      return false;
    }
    this.isLoading = true;
    this.paymentsService.verifyBankAccount(this.bankVerify.value).subscribe(res => {
      if (res === false) {
        this.snotifyService.error(
          'There was an issue verifying your micro-deposits. Please ensure they are correct and try again',
          null,
          this.snotifyConfigService.getConfig()
        );
      } else {
        this.snotifyService.success(
          'Successfully verified your bank account',
          null,
          this.snotifyConfigService.getConfig()
        );
      }

      this.modalService.close('verify-bank-account-dialog');

      this.accountService.getBankAccount('payments').subscribe(bankInfoPayments => {
        this.paymentsState.pipe(take(1)).subscribe((state: fromPayments.State) => {
          this.store.dispatch(new PaymentsActions.SetPaymentsInfo({
            bankAccounts: bankInfoPayments,
            creditCards: state.creditCards,
            hasBankAccountForPayments: !!bankInfoPayments.length,
            hasBankAccountForPayouts: state.hasBankAccountForPayouts
          }));
        });
      });
      this.isLoading = false;
    });
  }

}
