import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

import * as ProfileActions from './profile.actions';
import * as fromProfile from './profile.reducer';
import { ReaquestHeadersService } from '../../../../_services/requestHeaders.service';



@Injectable()
export class ProfileEffects {

  @Effect()
  profileGetUserInfo = this.actions$
    .pipe(
      ofType(ProfileActions.TRY_GET_USER_INFO),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + '/getuserinfo',
          {
            headers: headers,
            withCredentials: true
          }
        );
      }),
      mergeMap((data: fromProfile.State) => {
        return [
          {
            type: ProfileActions.GET_USER_INFO,
            payload: data
          }
        ];
      })
    );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}
}
