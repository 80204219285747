import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ModalService} from '../modal/modal.service';
import * as Constants from './../../../app.constants';
import {FundraisingLinkType} from 'diemlife-commons/dist/diemlife-commons-model';
import {Observable, of, timer} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {QuestService} from "../../../_services/quest.service";

@Component({
  selector: 'app-funds-modal',
  templateUrl: './funds-modal.component.html',
  styleUrls: ['./funds-modal.component.styl']
})
export class FundsModalComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() fundSubmit: EventEmitter<SaveFundraiserPayload> = new EventEmitter<SaveFundraiserPayload>();
  @Input() fundraisingLink?: FundraisingLinkType;
  @Input() questId: number;
  @Input() doerId: number;
  @Input() mode: string;
  disclaimer: string;
  messages = Constants.VALIDATION_MESSAGES;

  fundsForm: FormGroup;
  fundsFormConfirmState = false;
  isLoaded = false;
  progress = false;
  uploadProgress: boolean = false;
  checkProgress: boolean = false;
  formSubmitted = false;

  constructor(private modalService: ModalService,
              private questService: QuestService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    if (this.mode === 'fundraise') {
      const initialAmount = this.fundraisingLink ? this.fundraisingLink.targetAmount / 100 : 0;
      const campaignName = this.fundraisingLink ? this.fundraisingLink.campaignName : null;
      const coverImageUrl = this.fundraisingLink ? this.fundraisingLink.coverImageUrl : null;
      this.fundsForm = this.fb.group({
        'fundsFormAmount': [initialAmount, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1)]],
        'fundsFormConfirm': [this.fundsFormConfirmState, [Validators.requiredTrue]],
        'fundsCampaignName': [campaignName, [Validators.maxLength(250)], this.validateCampaignNameAvailable.bind(this)],
        'fundsCoverImageUrl': [coverImageUrl, []],
      });
      this.disclaimer = 'By clicking "Fundraise", you are starting a fundraiser to help raise money for this Quest. Donations you receive will go directly to this Quest creator.';
    } else {
      this.fundsForm = this.fb.group({
        'fundsFormConfirm': [this.fundsFormConfirmState, [Validators.requiredTrue]]
      });
      this.disclaimer = 'By clicking "Back Me", you are acknowledging that your donation will go directly to the creator’s Quest.';
    }
    this.isLoaded = true;
  }

  startFundraising() {
    this.formSubmitted = true;
    this.progress = true;
    if (this.mode === 'fundraise') {
      this.fundSubmit.emit({
        targetAmount: Number(this.fundsForm.controls['fundsFormAmount'].value || 0) * 100,
        campaignName: this.fundsForm.controls['fundsCampaignName'].value,
        coverImageUrl: this.fundsForm.controls['fundsCoverImageUrl'].value
      });
    } else {
      this.fundSubmit.emit(null);
    }
    this.fundsForm.patchValue({
      fundsFormConfirm: false
    });
    this.progress = false;
  }

  closeThisDialog() {
    this.fundsForm.patchValue({
      fundsFormConfirm: false
    });
    this.progress = false;
    this.close.emit();
  }

  onCoverUploadProgress(progress: boolean) {
    this.uploadProgress = progress;
  }

  validateCampaignNameAvailable(control: AbstractControl): Observable<ValidationErrors> {
    return timer(100).pipe(
      switchMap(() => {
        if (control.value) {
          this.checkProgress = true;
          return this.questService.checkFundraiserCampaignName(control.value).pipe(
            map(result => {
              this.checkProgress = false;
              return result ? {duplicateCampaignName: true} : null;
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

}

export interface SaveFundraiserPayload {
  targetAmount: number;
  campaignName: string;
  coverImageUrl: string;
}
