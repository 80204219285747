import {LocalStorageService} from '../../../../_services/localstorage.service';
import * as RecentTransactionsActions from './recent-transactions.actions';
import {Transaction} from 'diemlife-commons/dist/diemlife-commons-model';

const initialState: Transaction[] = [];

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateRecentTransactions';

export function RecentTransactionsReducer(
  state = localStorageService.getInitialState(LOCAL_STATE_KEY, initialState),
  action: RecentTransactionsActions.RecentTransactionsActions
) {
  switch (action.type) {
    case (RecentTransactionsActions.GET_RECENT_TRANSACTIONS):
      return localStorageService.set(LOCAL_STATE_KEY, [
        ...action.payload
      ]);
    case (RecentTransactionsActions.CLEAR_RECENT_TRANSACTIONS):
      localStorageService.clear(LOCAL_STATE_KEY);
      return [
        ...initialState
      ];
    default:
      return state;
  }
}
