import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
// import { map } from 'rxjs/operators'
import * as fromApp from './../../../_store/app.reducers';
import * as UserFavoritesActions from './store/user-favorites.actions';
import * as RecentTransactionsActions from './store/recent-transactions.actions';


@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private store: Store<fromApp.AppState>
  ) {
  }

  // getUserFavorites() {
  //   this.store.dispatch(new UserFriendsActions.TryGetUserFriends());
  // }
  requestPayout(amountInCents: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      amount: amountInCents,
      currency: 'usd'
    };
    return this.http.post(
      environment.target + environment.context + '/accounts/bank-accounts/payout',
      payload,
      {headers: headers}
    );
  }

  getFirstBankAccountForPayouts() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/accounts/bank-accounts/list/payouts',
      {headers: headers}
    );
  }

  getCurrentFriends() {
    this.store.dispatch(new UserFavoritesActions.TryGetUserFavorites());
  }

  getRecentTransactions() {
    this.store.dispatch(new RecentTransactionsActions.TryGetRecentTransactions());
  }

  getUserInfoById(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getuserinfobyid',
      {userId: id},
      {headers: headers}
    );
  }

  getUserInfoByName(username: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getuserinfobyname',
      { username: username },
      { headers: headers }
    );
  }

  getUserInfo () {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/getuserinfo',
      {
        headers: headers,
        withCredentials: true
      }
    );
  }
  uploadProfilePictures(payload: { email: string, profPic?: any, coverPic?: any, contentType: string }) {
    const headers = this.reaquestHeadersService.getHeaders();
    const params = new HttpParams().set('format', 'base64');
    const data = new FormData();
    if (payload.profPic) {
      data.append('profPic', payload.profPic);
    } else {
      data.append('coverPic', payload.coverPic);
    }
    data.append('contentType', payload.contentType);
    data.append('email', payload.email);

    return this.http.post(
      environment.target + environment.context + '/uploadprofilepicture',
      data,
      {headers: headers, params: params}
    );
  }

  getUserFavoritesForUser(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getuserfavoritesforuser',
      {userId: id},
      {headers: headers}
    );
  }

  getUserCurrentFriends(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getcurrentfriendsforuser',
      {userId: id},
      {headers: headers}
    );
  }

  getSubscriptionTransactions(subscriptionId: string): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/subscriptions/${subscriptionId}/transactions`,
      {headers: headers}
    );
  }

  getSubscriptions(): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/subscriptions`,
      {headers: headers}
    );
  }

  cancelSubscription(subscriptionId: string): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/accounts/subscriptions/${subscriptionId}/cancel`,
      null,
      {headers: headers}
    );
  }

  getQuestsRorUser(id: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/active/user/${id}`,
      {headers: headers}
    );
  }

  getAvailableBalance() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/availablebalance`,
      {headers: headers}
    );
  }

  downloadTransactionsCSV() {
    const headers = this.reaquestHeadersService.getHeaders();
    const options = {headers, responseType: 'blob' as 'text'};
    return this.http.get(
      environment.target + environment.context + `/accounts/transactions/download/csv`,
      options,
    );
  }

  downloadQuestTransactions(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const options = {headers: headers, responseType: 'blob' as 'blob'};
    return this.http.get(environment.target + environment.context + `/quests/${questId}/participants`, options);
  }

}
