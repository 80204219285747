<form class="c-forms__group c-pincode" [formGroup]="pincodeForm" (ngSubmit)="onSubmit()">
  <div class="c-forms__control">
    <div class="c-pincode__control">
      <input  #control1 class="c-forms__input" (keyup)="onKeyUp($event, 1)" (keydown)="onKeyDown()" (input)="onInput($event)" formControlName="1" [maxLength]="1" type="number">
    </div>
  </div>
  <div class="c-forms__control">
    <div class="c-pincode__control">
      <input #control2 class="c-forms__input" (keyup)="onKeyUp($event, 2)" (keydown)="onKeyDown()" (input)="onInput($event)" formControlName="2" [maxLength]="1" type="number">
    </div>
  </div>
  <div class="c-forms__control">
    <div class="c-pincode__control">
      <input #control3 class="c-forms__input" (keyup)="onKeyUp($event, 3)" (keydown)="onKeyDown()" (input)="onInput($event)" formControlName="3" [maxLength]="1" type="number">
    </div>
  </div>
  <div class="c-forms__control">
    <div class="c-pincode__control">
      <input #control4 class="c-forms__input" (keyup)="onKeyUp($event, 4)" (keydown)="onKeyDown()" (input)="onInput($event)" formControlName="4" [maxLength]="1" type="number">
    </div>
  </div>
</form>
