import {Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
// import {QuestInfo} from '../quest.type';
import {FundraisingLinkType, QuestInfo} from 'diemlife-commons/dist/diemlife-commons-model';
import Readmore from 'readmore-js';
import { WindowRef } from '../../../../_global/window-ref.module';
import { MEDIA_BREAKPOINTS } from '../../../../app.constants';

@Component({
  selector: 'app-quest-description',
  templateUrl: './quest-description.component.html',
  styleUrls: ['./quest-description.component.styl']
})
export class QuestDescriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inner', {static: false}) inner: ElementRef;
  @ViewChild('caption', {static: false}) caption: ElementRef;
  @Input() questInfo: QuestInfo;
  @Input() fundraisingLink?: FundraisingLinkType;
  @Input() mode?: 'wrapped' | 'unwrapped' = 'wrapped';
  currentText: string;
  stringStyle = false;
  readMoreInstance: any;
  isMobileViewMode: boolean;

  constructor(
    private winRef: WindowRef
  ) { }

  ngOnInit(): void {
    let text: string;

    if (this.questInfo.questFeed ? this.questInfo.questFeed.length > 0 : false) {
      text = this.questInfo.questFeed;
    } else {
      this.stringStyle = true;
      text = this.questInfo.shortDescription;
    }

    this.currentText = text;
  }

  ngAfterViewInit(): void {
    this.checkViewMode();
    if (this.mode === 'wrapped') {
      this.initRearMorePlugin();
    }
  }
  ngOnDestroy() {
    if (this.readMoreInstance) {
      this.readMoreInstance.destroy();
      this.readMoreInstance = undefined;
    }
  }
  private initRearMorePlugin() {
    this.readMoreInstance = new Readmore(this.caption.nativeElement, {
      speed: 300,
      collapsedHeight: this.isMobileViewMode ? 200 : 320,
      lessLink: this.getBtnTemplate(`Less&nbsp;<span>&ndash;`),
      moreLink: this.getBtnTemplate(`More <span>+</span>`)
    });
  }
  private getBtnTemplate(template: string): string {
    return `<button class="c-quest-descption__trigger more-lnk h-btn-reset" type="button">${template}</button>`;
  }
  private checkViewMode() {
    this.isMobileViewMode = this.winRef.nativeWindow.innerWidth < MEDIA_BREAKPOINTS.md;
  }
}
