import { Action } from '@ngrx/store';
import { Steps } from './donate.reducer';

export const DONATE_SET_SUCCESS_INFO = 'DONATE_SET_SUCCESS_INFO';
export const SET_BRUT_TOTAL = 'SET_BRUT_TOTAL';
export const SET_AMOUNT = 'UPDATE_AMOUNT';
export const SET_MESSAGE = 'UPDATE_MESSAGE';
export const SET_RECURRENT_CHECK = 'SET_RECURRENT_CHECK';
export const SET_ANON_CHECK = 'SET_ANON_CHECK';
export const SET_NAVIGATION_STEP = 'SET_NAVIGATION_STEP';

export interface ToggleCategoryPayload {
  index: number;
  value: boolean;
}

export class SetBrutTotal implements Action {
  readonly type = SET_BRUT_TOTAL;
  constructor(public payload: number | string | null) {}
}
export class SetAmount implements Action {
  readonly type = SET_AMOUNT;
  constructor(public payload: number | null) {}
}
export class SetMessage implements Action {
  readonly type = SET_MESSAGE;
  constructor(public payload: string) {}
}
export class SetRecurrentCheck implements Action {
  readonly type = SET_RECURRENT_CHECK;
  constructor(public payload: boolean) {}
}
export class SetAnonCheck implements Action {
  readonly type = SET_ANON_CHECK;
  constructor(public payload: boolean) {}
}
export class SetNavigationStep implements Action {
  readonly type = SET_NAVIGATION_STEP;
  constructor(public payload: Steps) {}
}
export class DonateSetSuccessInfo implements Action {
  readonly type = DONATE_SET_SUCCESS_INFO;
  constructor(public payload: any) {}
}

export type DonateActions =   SetAmount |
                              SetMessage |
                              SetRecurrentCheck |
                              SetAnonCheck |
                              SetNavigationStep |
                              DonateSetSuccessInfo |
                              SetBrutTotal;
