import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ReaquestHeadersService} from './requestHeaders.service';
import {map, switchMap} from 'rxjs/operators';
import {forkJoin, Observable, of as observableOf, of} from 'rxjs';
import 'rxjs-compat/add/observable/of';
import {QuestEvent} from '../modules/ticket-flow/ticket-flow.typings';
import {
  CommentMention,
  FundraisingLinkType,
  LeaderboardMemberStatus,
  LeaderboardScore,
  MapLocation,
  Quest,
  QuestActivity,
  QuestComment,
  QuestDoer,
  QuestInfo,
  QuestServiceInterface,
  QuestTask,
  QuestMapData,
  QuestTeam,
  QuestUserInfo,
  SEOSlugs,
  StartQuestForm
} from 'diemlife-commons/dist/diemlife-commons-model';
import {NewQuestGalleryPhoto, QuestPreparedPhoto} from '../components/main/quest/quest.type';
import {Router} from '@angular/router';
import {PreparedQuestComment} from '../components/main/quest/quest-comments/comment/comment.type';
import {Utils} from '../_tools/utils';
import {DlMilestonesService} from '../components/main/dl-milestones/dl-milestones.service';
import {AddImageToMilestonePayload, TaskGroup} from '../components/main/dl-milestones/dl-milestones.typings';

export interface CreateQuestResponse {
  activityMode: string;
  backBtnDisabled: boolean;
  backingAllowed: boolean;
  category: string;
  commentCount: number;
  copyAllowed: boolean;
  createdBy: number;
  dateCreated: number;
  dateModified: number;
  description: string;
  followed: boolean;
  fundraising: boolean;
  geoTriggerEnabled: boolean;
  hasEvent: boolean;
  id: number;
  isUserDoing: boolean;
  mode: string;
  photo: string;
  privacyLevel: string;
  questFeed: string;
  repeatCount: number;
  repeatable: boolean;
  saved: boolean;
  savedCount: number;
  seoSlugs: SEOSlugs;
  sharedCount: number;
  shortDescription: string;
  starred: boolean;
  startBtnDisabled: boolean;
  status: string;
  title: string;
  user: {
    active: boolean;
    avatarUrl: string;
    country: string;
    coverPictureURL: string;
    email: string;
    firstName: string;
    id: number;
    isUserBrand: boolean;
    lastName: string;
    missionStatement: string;
    name: string;
    userBrand: boolean;
    userId: number;
    userName: string;
  };
  userDoing: boolean;
  views: number;
}

export const participantMaps: QuestMapData[] = [
  {
    id: 1023,
    geoPoint: {latitude: 40.71704, longitude: -73.987},
    geoMarker: 'https://diemlife-assets.s3.amazonaws.com/map-pins/pin-default.png',
    pinText: 'First Last',
    isCompleted: false,
    completedGeoMarker: ''
  },
  {
    id: 1025,
    geoPoint: {latitude: 40.763419, longitude: -73.973971},
    geoMarker: 'https://diemlife-assets.s3.amazonaws.com/map-pins/pin-default.png',
    pinText: 'Tim Cook',
    isCompleted: false,
    completedGeoMarker: ''
  }
];

@Injectable({
  providedIn: 'root',
})
export class QuestService implements QuestServiceInterface {
  questInfoChanged: any = new EventEmitter();
  private fundraising: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private router: Router,
    private dlMilestonesService: DlMilestonesService
  ) {
  }

  public static isEditableByUser(quest: QuestInfo, user: QuestUserInfo): boolean {
    return !!quest && !!user && (
      user.id === quest.createdBy || (!!quest.admins && quest.admins.indexOf(user.email) >= 0)
    );
  }

  public static hasUserActivity(questActivity: QuestActivity, userId: number) {
    return !!questActivity && !!userId && questActivity.userId === userId;
  }

  callUpdateForQuest(args?: any): void {
    this.questInfoChanged.emit(args);
  }

  getUpdateForQuest() {
    return this.questInfoChanged;
  }

  addMilestone(questId: number, payload: { task: string; video?: any }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/add-milestone`,
      payload,
      {headers: headers}
    );
  }

  removeMilestone(payload: { id: number; }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/remove',
      payload,
      {headers: headers}
    );
  }

  editMilestone(payload: { id: number; task: string; video: any }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/edit',
      payload,
      {headers: headers}
    );
  }

  getQuestForPost(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/getquestforpost',
      {questId: questId},
      {headers: headers}
    );
  }

  getQuestTasksForUser(questId: number): Observable<QuestTask[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<QuestTask[]>(
      environment.target + environment.context + '/getquesttasksforuser',
      {questId: questId},
      {headers: headers}
    ).pipe(
      map((tasks: any[]) => tasks.map(task => new QuestTask(task)))
    );
  }

  getQuestMapsByUserIds(userids: number[], questid: number): Observable<any[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    const params = {userids: userids.join(','), questid: questid.toString()};
    return this.http.post<any[]>(
      environment.apiurl+environment.dldb,
      null,
      {params}
    ).pipe(
      map((maps: any[]) => maps.map(part => part))
    );
    // return of(participantMaps).pipe(
    //   map((maps: QuestMapData[]) => maps.map(item => item))
    // );
  }

  getQuestMembers(questId: number, userId: number): Observable<QuestDoer[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-members/${questId}/${userId}`,
      {headers: headers}
    ).pipe(
      map((doers: QuestDoer[]): QuestDoer[] => {
        doers.forEach(doer => {
          if (doer.amountBacked && doer.amountBacked.length) {
            doer.amountBackedSum = doer.amountBacked.reduce((sum, delta) => sum + delta, 0);
          } else {
            doer.amountBackedSum = 0;
          }
          doer.memberStatusObj = {
            Creator: false,
            Admin: false,
            Backer: false,
            Donor: false,
            Supporter: false,
            Doer: false,
            Achiever: false,
            Interested: false
          };
          if (doer.memberStatus && doer.memberStatus.length) {
            doer.memberStatus.forEach(status => {
              if (doer.memberStatusObj.hasOwnProperty(status)) {
                doer.memberStatusObj[status] = true;
              }
            });
          }
        });
        return doers;
      })
    );
  }

  updateTeamInfo(payload: QuestTeam) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.put(
      environment.target + environment.context + `/quest/teams/${payload.teamId}`,
      {
        questTeamId: payload.teamId,
        questTeamName: payload.teamName,
        questTeamLogoUrl: payload.teamLogoUrl,
        questTeamAction: 'Update'
      },
      {headers: headers}
    );
  }

  getQuestTeams(questId: number): Observable<QuestTeam[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam[]>(
      environment.target + environment.context + `/quest-teams/${questId}`,
      {headers: headers}
    );
  }

  findTeam(questId: number, teamName: string): Observable<QuestTeam> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam>(
      environment.target + environment.context + `/quest-teams/${questId}/for-name`,
      {
        headers: headers,
        params: {'team-name': teamName}
      }
    );
  }

  getTeam(teamId: number): Observable<QuestTeam> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam>(
      environment.target + environment.context + `/quest-teams/${teamId}/for-id`,
      {headers: headers}
    );
  }

  checkQuestTeamName(questId: number, teamName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.target + environment.context + `/quest-teams/${questId}/check-name`,
      teamName,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  checkFundraiserCampaignName(campaignName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.target + environment.context + `/fundraising/link/check-name`,
      campaignName,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  getQuestLeaderboard(questId: number, attributeId: string): Observable<LeaderboardScore[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<LeaderboardScore[]>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/scores/${attributeId}`,
      {headers: headers}
    );
  }

  updateLeaderBoardMembers(questId: number): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<number>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/members/update`,
      null,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  updateLeaderBoardDoers(questId: number): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<number>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/doers/update`,
      null,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  patchLeaderboardScore(
    questId: number,
    attributeId: string,
    payload: {
      memberId: number;
      value: number;
      status: LeaderboardMemberStatus;
    }
  ) {
    return this.http.post(
      environment.target + environment.context + `/quest-leaderboard/${questId}/scores/${attributeId}`,
      {...payload},
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  getQuestsInvites(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/${questId}/invites`,
      {headers: headers}
    );
  }

  getQuestsAdmins(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/${questId}/admins`,
      {headers: headers}
    );
  }

  getQuestEditInfo(questId: number): any {
    return forkJoin([
      this.getQuestForPost(questId),
      this.getQuestsInvites(questId),
      this.getQuestsAdmins(questId)
    ]);
  }

  editQuest(payload: any, userId: number): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/editquest',
      payload,
      {headers: headers}
    ).pipe(
      switchMap((res) => {
        return this.generateQuestClassification(payload.questId).pipe(map(() => res));
      })
    );
  }

  checkIfUserQuestSaved(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/isuserquestsaved',
      {questId: questId},
      {headers: headers}
    );
  }

  cancelQuest(questId) {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.put(
      environment.target + environment.context + `/quest/${questId}/cancel`,
      null,
      {headers: headers}
    );
  }

  getEventForQuest(questId: number, withStripeInfo: boolean = false): Observable<QuestEvent> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestEvent>(
      environment.target + environment.context + `/quests/${questId}/event/${withStripeInfo}`,
      {headers: headers}
    );
  }

  getOtherUsers(): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/get-other-users',
      {headers: headers}
    );
  }

  completeQuest(questId: number, completeMilestones: boolean, withGeoLocation?: boolean): Observable<void> {
    return new Observable<void>(observer => {
      const doComplete = (point: MapLocation): void => {
        const completeHandle = this.completeQuestInternal(questId, completeMilestones, point).subscribe(
          () => observer.next(null),
          (error) => observer.error(error),
          () => completeHandle.unsubscribe());
      };
      if (withGeoLocation) {
        console.log('Asking for geo-location before completing Quest');
        const geoHandle = Utils.getLocation().subscribe(
          (point) => doComplete(point),
          () => doComplete(null),
          () => geoHandle.unsubscribe());
      } else {
        doComplete(null);
      }
      return observer;
    });
  }

  private completeQuestInternal(questId: number, completeMilestones: boolean, point?: MapLocation): Observable<void> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<void>(
      environment.target + environment.context + '/completequest',
      {questId, completeMilestones, point},
      {headers: headers}
    );
  }

  createQuest(payload, userId: number, defaultMilestoneGroupName: string): Observable<CreateQuestResponse> {
    // * get tasks with images
    const imageUrls: string[] = [...payload.questTasks].map((task: QuestTask) => {
      return task.imageUrl ? task.imageUrl : null;
    });

    // * clear images from payload for /newquest endpoint
    const preparedPayload = {...payload};
    preparedPayload.questTasks.map((task: QuestTask) => {
      return task.imageUrl = null;
    });

    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<CreateQuestResponse>(
      environment.target + environment.context + '/newquest',
      preparedPayload,
      {headers: headers}
    ).pipe(
      switchMap((res: CreateQuestResponse) => {
        // * check if images exists in milestones
        const imageURLexists = imageUrls.filter(item => item !== null);
        if (imageURLexists.length > 0) {
          return this.dlMilestonesService.getQuestGroupTasks(res.id, userId).pipe(
            switchMap((taskGroup: TaskGroup[]) => {
              if (taskGroup[0].questTasks.length > 0) {
                // * create array for requests that will add images to each milestone
                const observableBatch = [];
                taskGroup[0].questTasks.forEach((task: QuestTask, index: number) => {
                  if (imageUrls[index]) {
                    // Grab extension off of the image URL for content type

                    const addImageToMilestonePayload: AddImageToMilestonePayload = {
                      taskId: task.id,
                      taskImage: imageUrls[index],
                      contentType: ''
                    };
                    observableBatch.push(this.dlMilestonesService.addImageToMilestone(addImageToMilestonePayload));
                  }
                });

                return forkJoin(observableBatch).pipe(
                  switchMap(() => {
                    return of(res);
                  })
                );
              } else {
                return of(res);
              }
            }),
            switchMap((newQuestResponse) => {
              // * create default milestone group if group name changed
              if (defaultMilestoneGroupName) {
                return this.dlMilestonesService.createDefaultGroupWithTasks(newQuestResponse.id, defaultMilestoneGroupName).pipe(
                  switchMap(() => {
                    return of(res);
                  })
                );
              } else {
                return of(newQuestResponse);
              }
            })
          );
        } else {
          return of(res);
        }
      }),
      switchMap((res: CreateQuestResponse) => {
        return this.generateQuestClassification(res.id).pipe(map(() => res));
      })
    );
  }

  private generateQuestClassification(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quest-classification/${questId}`,
      null,
      {headers: headers}
    );
  }

  reorderQuestMilestones(newOrder: number[]) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/reorder',
      newOrder,
      {headers: headers}
    );
  }

  addQuestGalleryPhoto(payload: NewQuestGalleryPhoto) {
    const headers = this.reaquestHeadersService.getHeaders();
    const params = new HttpParams().set('format', 'base64');
    const data = new FormData();
    data.append('questImage', payload.questImage);
    data.append('questId', payload.questId.toString());
    data.append('caption', payload.caption);
    data.append('contentType', payload.contentType);
    return this.http.post(
      environment.target + environment.context + '/addphototoquest',
      data,
      {headers: headers, params: params}
    );
  }

  getQuestInterests() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/ref/profile-interests',
      {headers: headers}
    );
  }

  startQuest(startQuestForm: StartQuestForm): Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + '/doquest',
      startQuestForm,
      {headers: headers}
    );
  }

  doQuest(questId: number, referrerId?: number, questMode?: string, withGeoLocation?: boolean): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const doStart = (point: MapLocation): void => {
        const startHandle = this.startQuest({questId, referrerId, questMode, point}).subscribe(
          (started) => observer.next(started),
          (error) => observer.error(error),
          () => startHandle.unsubscribe());
      };
      if (withGeoLocation) {
        console.log('Asking for geo-location before starting Quest');
        const geoHandle = Utils.getLocation().subscribe(
          (point) => doStart(point),
          () => doStart(null),
          () => geoHandle.unsubscribe());
      } else {
        doStart(null);
      }
      return observer;
    });
  }

  updateQuestTask(taskId: number): Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + '/updatetask',
      {taskId: taskId},
      {headers: headers}
    );
  }

  getQuestDetail(questId, userId): Observable<Quest> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<Quest>(
      environment.target + environment.context + `/quest-page/${questId}/${userId}`,
      {headers: headers}
    ).pipe(
      map((questPage: Quest) => {
        questPage.questTasks = questPage.questTasks.map(task => new QuestTask(task));
        return questPage;
      })
    );
  }

  linkQuest(taskId: number, linkedQuestId: number): Observable<QuestTask> {
    return this.http.post<QuestTask>(
      environment.target + environment.context + `/quest-milestones/add-quest-link`,
      {taskId: taskId, linkedQuestId: linkedQuestId},
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  followQuest(questId: number, follow: boolean) {
    const payload = {
      questId: questId
    };
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.put(
      environment.target + environment.context + `/quest/follow/${questId}/${follow ? 'true' : 'false'}`,
      payload,
      {headers: headers}
    );
  }

  saveQuest(questId) {
    const payload = {
      questId: questId
    };
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/savequest`,
      payload,
      {headers: headers}
    );
  }

  copyQuest(questId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/copy`,
      null,
      {headers: headers}
    );
  }

  startFundraising(questId: number,
                   doerId: number,
                   targetAmount: number,
                   currency: string,
                   campaignName: string,
                   coverImageUrl: string): Observable<FundraisingLinkType> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {questId, doerId, targetAmount, currency, campaignName, coverImageUrl};
    return this.http.post<FundraisingLinkType>(
      environment.target + environment.context + '/fundraising/start',
      payload,
      {headers: headers}
    );
  }

  getFundraisingInfo(questId: number, userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/fundraising/parties/quest/${questId}/doer/${userId}`,
      {headers: headers}
    );
  }

  getFundraisingLink(questId: number, userId: number): Observable<FundraisingLinkType> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<FundraisingLinkType>(
      environment.target + environment.context + `/fundraising/link/quest/${questId}/doer/${userId}`,
      {headers: headers}
    );
  }

  getQuestCompletionPercent(questId: number, userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId
    };
    return this.http.post(
      environment.target + environment.context + `/getquestcompletionpercent/${questId}/${userId}`,
      payload,
      {headers: headers}
    ).pipe(map((res: any) => {
      res.completionPercentage = Number(res.completionPercentage.replace('%', '').trim());

      return res;
    }));
  }

  getQuestComments(questId: number, userId: number, viewerId: number): Observable<PreparedQuestComment[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId
    };
    return this.http.post(
      environment.target + environment.context + '/getallcommentsforquest',
      payload,
      {headers: headers}
    ).pipe(
      // map((comments: QuestComment[]): QuestComment[] => {
      //   // ? reverse comments
      //   comments.forEach(comment => comment.replies.reverse());
      //   return comments;
      // }),
      map((comments: QuestComment[]): PreparedQuestComment[] => {
        const shouldBeDeleted = [];
        let deletedCount = 0;
        comments.forEach((comment: PreparedQuestComment, index: number) => {
          comment.liked = false;
          comment.editable = false;
          if (comment.likes) {
            comment.liked = comment.likes.filter(like => like.liker === viewerId).length > 0;
          }
          comment.likesCount = comment.likes ? comment.likes.length : 0;
          if (comment.deleted !== 'N' && comment.deleted !== null) {
            shouldBeDeleted.push(index);
          }
          this.prepareComment(comment);
        });
        if (shouldBeDeleted.length > 0) {
          shouldBeDeleted.forEach(key => {
            comments.splice((key - deletedCount), 1);
            deletedCount++;
          });
        }
        return comments;
      })
    );
  }

  addNewQuestComment(questId: number, viewerId: number, comment: string, inReplyToId?: number, imageId?: number): Observable<PreparedQuestComment> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId,
      comments: comment,
      inReplyToId: inReplyToId,
      imageId: imageId
    };
    return this.http.post(
      environment.target + environment.context + '/addcomment',
      payload,
      {headers: headers}
    ).pipe(
      map((newComment: PreparedQuestComment): PreparedQuestComment => {
        return this.prepareComment(newComment);
      })
    );
  }

  prepareComment(comment: PreparedQuestComment): PreparedQuestComment {
    comment.limit = 2;

    const formattedStringArray = [];
    let regexSegment = '';

    comment.mentions.forEach((mention, index) => {
      regexSegment += `(@${mention.userName})`;
      if (comment.mentions.length > index + 1) {
        regexSegment += '|';
      }
    });

    const regexp = new RegExp(`\\B${regexSegment}\\b`, 'gmi');
    const commentArray = comment.comment.split(regexp).filter(el => el !== undefined);

    commentArray.forEach((item) => {
      const formattedStringArrayItem: any = {};
      formattedStringArrayItem.text = item;
      if (item.match(regexp)) {
        formattedStringArrayItem.mention = [...comment.mentions].find(mention => '@' + mention.userName === item.match(regexp)[0]);
      }
      formattedStringArray.push(formattedStringArrayItem);
    });

    let str = '';
    let mentionCount = 0;
    formattedStringArray.forEach(item => {
      if (!item.mention) {
        str += item.text;
      } else {
        str += this.buildCommentMention(item.mention, mentionCount);
        mentionCount++;
      }
    });

    comment.formattedComment = str;
    comment.replies = [...comment.replies].map(reply => this.prepareComment(reply));
    return comment;
  }

  buildCommentMention(mention: CommentMention, index: number) {
    // tslint:disable-next-line:max-line-length
    return `<a class="c-comment__mention js-mention" href="javascript:void(0);" data-index="${index}">${mention.firstName} ${mention.lastName}</a>`;
  }

  editQuestComment(commentId: number, value: string, imageId?: number): Observable<PreparedQuestComment> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      comment: value,
      imageId: imageId
    };
    return this.http.post(
      environment.target + environment.context + `/comment/${commentId}`,
      payload,
      {headers: headers}
    ).pipe(
      map((updatedComment: QuestComment): PreparedQuestComment => {
        return this.prepareComment(updatedComment);
      })
    );
  }

  toggleQuestLike(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/comment/${commentId}/like`,
      null,
      {headers: headers}
    );
  }

  removeQuestComment(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete(
      environment.target + environment.context + `/comment/${commentId}`,
      {headers: headers}
    );
  }

  addQuestCoverPhoto(questId: number, photo: File) {
    if (photo && questId) {
      const formData: FormData = new FormData();
      formData.append('questImage', photo, photo.name);
      formData.append('questId', questId.toString());
      const headers = this.reaquestHeadersService.getHeaders();
      return this.http.post(
        environment.target + environment.context + '/newquestimage',
        formData,
        {headers: headers}
      );
    }
    return observableOf([]);
  }

  removeQuestPhoto(photoId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete(
      environment.target + environment.context + `/quest/photo/${photoId}`,
      {headers: headers}
    );
  }

  prepareCoverPhoto(photo: any | null, category: string | null): QuestPreparedPhoto {
    let url = '/assets/images/useful/categories/';

    if (photo) {
      return {
        jpg: photo,
        webp: null
      };
    } else {
      switch (category) {
        case 'MENTAL':
          url += 'mental';
          break;
        case 'OCCUPATIONAL':
          url += 'occupational';
          break;
        case 'ENVIRONMENTAL':
          url += 'environmental';
          break;
        case 'SOCIAL':
          url += 'social';
          break;
        case 'PHYSICAL':
          url += 'physical';
          break;
        case 'FINANCIAL':
          url += 'financial';
          break;
        case null:
          url = '/assets/images/useful/tree-back';
          break;
        default:
          url += category;
      }
    }
    return {
      jpg: `${url}.jpg`,
      webp: `${url}.webp`
    };
  }

  getYoutubeId(url: string) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  fundraisingSwitchReset() {
    return this.fundraising.emit(false);
  }

  getFundraisingSwitchValue() {
    return this.fundraising;
  }
}
