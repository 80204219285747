import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {SnotifyService} from 'ng-snotify';
import {ModalService} from '../../../main/modal/modal.service';
import {WindowRef} from '../../../../_global/window-ref.module';
import {PaymentsService} from '../../../main/payments/payments.service';
import {stripeToken} from '../../../../app.config';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';
import * as CreditCard from '../../../main/payments/credit-card-options.model';
import * as fromApp from '../../../../_store/app.reducers';
import * as fromPayments from '../../../main/payments/store/payments.reducer';
import * as PaymentsActions from '../../../main/payments/store/payments.actions';
import {StripeErrorCodesEnum} from 'diemlife-commons/dist/diemlife-commons-model';

@Component({
  selector: 'app-card-control-dialog',
  templateUrl: './card-control-dialog.component.html',
  styleUrls: ['./card-control-dialog.component.styl']
})
export class CardControlDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardInfo', {static: false}) cardInfo: ElementRef;
  paymentsState: Observable<fromPayments.State>;
  stripe: any;
  elements: any;
  elemntsOptions = {fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:800' }]};
  card: any;
  cardInFocus = false;
  cardOptions: CreditCard.CreditCardOptions = CreditCard.CREDIT_CARD_OPTIONS;
  cardHandler = this.onChange.bind(this);
  cardValidation: {error: string | null, complete: boolean} = {
    error: null,
    complete: false
  };
  accountList: any;
  bankAccountsList: any;
  collapseState = 'creditCard';
  payment: any = {
    selected: null
  };
  isLoading = false;
  constructor(
    private cd: ChangeDetectorRef,
    private winRef: WindowRef,
    private store: Store<fromApp.AppState>,
    private modalService: ModalService,
    private paymentsService: PaymentsService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService
  ) {
    const Stripe = winRef.nativeWindow.Stripe;
    this.paymentsState = this.store.select('paymentsInfo');
    this.stripe = Stripe(stripeToken); // use your test publishable key
    this.elements = this.stripe.elements(this.elemntsOptions);
  }

  ngAfterViewInit() {
    this.card = this.elements.create('card', this.cardOptions);
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
    this.card.addEventListener('focus', () => {
      this.cardInFocus = true;
    });
    this.card.addEventListener('blur', () => {
      this.cardInFocus = false;
    });
    this.card.addEventListener('keydown', () => {
      console.log(this.card);
    });
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error, complete }) {
    this.cardValidation.complete = complete;

    if (error) {
      this.cardValidation.error = error.message;
    } else {
      this.cardValidation.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit() {
    this.isLoading = true;
    const { token, error } = await this.stripe.createToken(this.card);
    if (error) {
      this.isLoading = false;
      this.cardValidation.error = error.message;
      this.snotifyService.error(
        this.cardValidation.error,
        null,
        this.snotifyConfigService.getConfig()
      );
    } else {
      this.paymentsService.saveCreditCard(token.id).subscribe((res) => {
        const newCardInfo: fromPayments.CreditCard = {
          cardType: token.card.brand,
          expiryMonth: token.card.exp_month,
          expiryYear: token.card.exp_year,
          lastFourDigits: token.card.last4
        };
        this.store.dispatch(new PaymentsActions.AddCreditCard(newCardInfo));
        this.isLoading = false;
        this.closeModal('pay-modal');
        this.snotifyService.success(
          'Successfully updated credit card information!',
          this.snotifyConfigService.getConfig()
        );
      }, (res) => {
        let isError = false;
        let errorValue = '';
        for (const errorCode in StripeErrorCodesEnum) {
          if (res.error && res.error.message.search(errorCode) !== -1) {
            isError = true;
            errorValue = StripeErrorCodesEnum[errorCode];
          }
        }
        if (isError) {
          this.snotifyService.error('There was an issue processing your request due to: ' + errorValue,
            null, this.snotifyConfigService.getConfig());
        } else {
          this.snotifyService.error('There was an issue processing your request. Please try again.',
            null, this.snotifyConfigService.getConfig());
        }
      });
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.card.clear();
  }

}
