import {Component, Input, OnInit} from '@angular/core';
import {LeaderboardScore, QuestInfo, QuestTeam} from 'diemlife-commons/dist/diemlife-commons-model';
import {QuestService} from '../../../../_services/quest.service';
import {take} from 'rxjs/operators';
import {SnotifyConfigService} from "../../../../_services/snotify-config.service";
import {SnotifyService} from "ng-snotify";

@Component({
  selector: 'app-leaderboard-main',
  templateUrl: './leaderboard-main.component.html',
  styleUrls: ['./leaderboard-main.component.styl']
})
export class LeaderboardMainComponent implements OnInit {
  selectedAttributeId: string;
  isLoaded = false;
  updateProgress = false;

  @Input()
  teams: QuestTeam[];

  @Input()
  quest: QuestInfo;

  @Input()
  allowEdit: boolean;

  members: LeaderboardScore[];

  constructor(private questService: QuestService,
              private snotifyService: SnotifyService,
              private snotifyConfigService: SnotifyConfigService) {
    this.members = [];
  }

  ngOnInit() {
  }

  updateFromDoers(): void {
    if (this.updateProgress) {
      return;
    }
    this.updateProgress = true;
    this.questService.updateLeaderBoardDoers(this.quest.id).subscribe(updatedCount => {
      this.updateProgress = false;
      this.loadScoresForAttribute(this.selectedAttributeId);
      this.snotifyService.success(`Leaderboard now counts ${updatedCount} members`, 'Update done!', this.snotifyConfigService.getConfig());
    });
  }

  onSelectedIdChanged(attributeId: string) {
    this.selectedAttributeId = attributeId;
    this.loadScoresForAttribute(attributeId);
  }

  onUpdateMember(updatedMember: LeaderboardScore) {
    this.members[[...this.members].map((member: LeaderboardScore) => member.memberId).indexOf(updatedMember.memberId)] = {...updatedMember};
    this.loadScoresForAttribute(this.selectedAttributeId);
  }

  private loadScoresForAttribute(attributeId: string): void {
    this.questService.getQuestLeaderboard(this.quest.id, attributeId).pipe(take(1)).subscribe(score => {
      this.members.length = 0;
      this.members.push(...score);
      if (!this.isLoaded) {
        this.isLoaded = true;
      }
    });
  }

}
