import {Component, OnInit, OnDestroy} from '@angular/core';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {WindowRef} from 'src/app/_global/window-ref.module';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/_store/app.reducers';
import {AuthService} from '../../main/auth/auth.service';
import * as fromAuth from '../../main/auth/store/auth.reducer';
import * as fromProfile from '../profile/store/profile.reducer';
import * as AuthActions from './../../main/auth/store/auth.actions';
import * as ProfileActions from './../profile/store/profile.actions';
import * as fromQuestComponents from '../../main/quest/quest-comments/store/quest-components.reducer';

export interface WebviewNavigationDataType {
  url?: string;
  routerLink?: any[];
  type: string;
}

@Component({
  selector: 'app-quest-detail-comments',
  templateUrl: './quest-detail-comments.component.html',
  styleUrls: ['./quest-detail-comments.component.styl']
})
export class QuestDetailCommentsComponent implements OnInit, OnDestroy {
  questId: number;
  userId: number;
  loaded = false;
  authState: Observable<fromAuth.State>;
  commentsState: Observable<fromQuestComponents.State>;
  commentsStateSubscription: Subscription;
  webviewMode: boolean;

  constructor(
    private winRef: WindowRef,
    private store: Store<AppState>,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.authState = this.store.select('auth');
    this.commentsState = this.store.select('questComponents');
  }

  ngOnInit() {
    const vm = this;

    this.commentsStateSubscription = this.commentsState.subscribe(() => {
      this.winRef.nativeWindow.nsWebViewInterface.emit('NeedUpdateGallery', null);
    });

    this.route.params.pipe(take(1)).subscribe(params => {
      this.questId = Number(params.questId);
      this.userId = Number(params.userId);
      this.webviewMode = this.route.snapshot.queryParams['_web-view'] === 'true';
      this.loaded = true; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    });
    this.winRef.nativeWindow.nsWebViewInterface.emit('CommentsInited', null);
    // tslint:disable-next-line:only-arrow-functions
    this.winRef.nativeWindow.loginInsideWebView = function(authData: fromAuth.State, userData: fromProfile.State) {
      if (authData && userData) {
        vm.store.dispatch(new AuthActions.SetToken({token: authData.token, tokenUpdateTime: authData.tokenUpdateTime}));
        vm.store.dispatch(new AuthActions.SetExpires(authData.expires));
        vm.store.dispatch(new ProfileActions.GetUserInfo(userData));
        vm.store.dispatch(new AuthActions.Signin());
        vm.loaded = true;
        setTimeout(() => {
          vm.authService.tryStartAutoRefreshTokenTimer(authData);
        }, 0);
        return true;
      } else {
        return false;
      }
    };
  }
  ngOnDestroy() {
    if (this.commentsStateSubscription) {
      this.commentsStateSubscription.unsubscribe();
    }
  }

  navigateTo(navigateData: WebviewNavigationDataType) {
    this.winRef.nativeWindow.nsWebViewInterface.emit(navigateData.type, navigateData);
  }
  updateGallery(): void {
    this.winRef.nativeWindow.nsWebViewInterface.emit('UpdateGallery');
  }

}
