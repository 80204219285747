export interface Config {
  container: string;
  unit: 'word'|'characters';
  maxLength: number;
}

export interface QuillInstance {
  on: Function;
  getText: Function;
}

export class Counter {
  quill: QuillInstance;
  options: Config;

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    const container = document.querySelector(this.options.container);

    this.quill.on('text-change', () => {
      const length = this.calculate();
      const charsLeft = (this.options.maxLength - length);
      if (charsLeft < 0) {
        container.innerHTML = '0 ' + this.options.unit + 's left';
      } else {
        container.innerHTML = (this.options.maxLength - length) + ' ' + this.options.unit + 's left';
      }
    });
  }

  calculate() {
    const text = this.quill.getText().trim();

    if (this.options.unit === 'word') {
      return !text ? 0 : text.split(/\s+/).length;
    }
    return text.length;
  }
}
