<form class="c-container c-container_sm" [formGroup]="donateForm" (ngSubmit)="onSubmitDonateForm()" [ngClass]="{'form-is-submitted': donateFormSubmitted}">
  <div class="c-donate__login" *ngIf="!(authState | async)?.authenticated">
    <div class="c-donate__login__caption">
      <strong class="c-donate__login__ttl">Already use DIEMlife?</strong>
      <span class="c-donate__login__desc">Sign into your account</span>
    </div>
    <button class="c-donate__login__trigger c-btn c-btn_dark c-btn_md" (click)="openModal('auth-modal', 'logIn')" type="button">
      <span>Sign In</span>
    </button>
  </div>
  <div class="c-forms__tools">
    <strong class="c-forms__legend">Billing info</strong>
    <button *ngIf="(authState | async)?.authenticated" [disabled]="infoIsCleared" class="c-btn c-btn_xs c-btn_dark with-p" type="button" (click)="onClearBillingInfo()">Clear info</button>
  </div>
  <div formGroupName="billingInfo">
    <div class="c-forms__group">
      <label class="c-forms__control is-required">
        <input name="firstName" aria-label="First name" class="c-forms__input" formControlName="firstName" placeholder="First name" type="text"/>
      </label>
      <div *ngIf="(donateForm.get('billingInfo.firstName').touched || donateFormSubmitted) && donateForm.get('billingInfo.firstName').invalid" class="c-forms__message is-red" role="alert">
        <div *ngIf="donateForm.get('billingInfo.firstName').errors['required']">{{ messages.required }}</div>
      </div>
    </div>
    <div class="c-forms__group">
      <label class="c-forms__control is-required">
        <input name="lastName" aria-label="Last name" class="c-forms__input" formControlName="lastName" placeholder="Last name" type="text"/>
      </label>
      <div *ngIf="(donateForm.get('billingInfo.lastName').touched || donateFormSubmitted) && donateForm.get('billingInfo.lastName').invalid" class="c-forms__message is-red" role="alert">
        <div *ngIf="donateForm.get('billingInfo.lastName').errors['required']">{{ messages.required }}</div>
      </div>
    </div>
    <div class="c-forms__group">
      <label class="c-forms__control is-required">
        <input name="email" aria-label="Email" class="c-forms__input" formControlName="email" placeholder="Email" type="email"/>
      </label>
      <div *ngIf="(donateForm.get('billingInfo.email').touched || donateFormSubmitted) && donateForm.get('billingInfo.email').invalid" class="c-forms__message is-red" role="alert">
        <div *ngIf="donateForm.get('billingInfo.email').errors['email']">{{ messages.invalidEmail }}</div>
        <div *ngIf="donateForm.get('billingInfo.email').errors['required']">{{ messages.required }}</div>
      </div>
    </div>
  </div>
  <div formGroupName="address">
    <div class="c-forms__group" *ngIf="countries">
      <div class="c-forms__control is-required">
        <select
          formControlName="country"
          bs-select-directive
          [events]="eventsSubject.asObservable()"
          data-live-search="true"
          data-style="c-forms__input"
          title="Please select country..."
          (close)="donateForm.get('address.country').markAsTouched()"
          (change)="selectCountry($event.target.value)"
          name="country">
          <option
            *ngFor="let country of countries"
            [attr.data-content]="buildOption(country.key, country.value)"
            [attr.selected]="country.key === donateForm.get('address.country').value ? true : null"
            [value]="country.key">{{country.value}}</option>
        </select>
      </div>
      <div *ngIf="(donateForm.get('address.country').touched || donateFormSubmitted) && donateForm.get('address.country').invalid" class="c-forms__message is-red" role="alert">
        <div *ngIf="donateForm.get('address.country').errors['required']">{{ messages.required }}</div>
      </div>
    </div>
    <div class="c-forms__group">
      <label class="c-forms__control is-required">
        <input name="zip" aria-label="Zip/Postal code" class="c-forms__input" formControlName="zip" placeholder="Zip/Postal code" type="text"/>
      </label>
      <div *ngIf="(donateForm.get('address.zip').touched || donateFormSubmitted) && donateForm.get('address.zip').invalid" class="c-forms__message is-red" role="alert">
        <div *ngIf="donateForm.get('address.zip').errors['required']">{{ messages.required }}</div>
        <div *ngIf="donateForm.get('address.zip').errors.pattern">{{ messages.invalidZipcode }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="!(authState | async)?.authenticated" class="c-forms__group is-flexed">
    <label role="checkbox" class="c-checkbox">
      <input name="signUp" aria-label="create a new account on diemlife?" formControlName="signUp" type="checkbox"/>
      <span class="c-checkbox__icon">
          <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
      </span>
      <span class="c-checkbox__caption">
        <b>Sign me up</b>
      </span>
    </label>
    <ng-template #tipContent>
      <div class="c-dl-tooltip__content">
        <strong class="c-dl-tooltip__title">Join DIEMlife and you will get:</strong>
        <ul class="c-dl-tooltip__list h-list-reset">
          <li>Faster Checkout</li>
          <li>Progress Updates</li>
          <li>Community Support</li>
          <li>Faster Checkout</li>
        </ul>
      </div>
    </ng-template>
    <i class="e-question" placement="top-center" [ngbTooltip]="tipContent" tooltipClass="c-dl-tooltip"></i>
  </div>

  <strong class="c-forms__legend">Payment settings:</strong>
  <div class="c-donate__secured">
    <span class="c-donate__secured__icon">Secured&nbsp;<svg class="icon icon-lock"><use xlink:href="#icon-lock"></use></svg></span>
    <img [src]="stripeLogo" alt="Stripe Powered" class="c-donate__secured__stripe-powered"/>
  </div>
  <div class="c-donate__payDialog c-forms__group">
    <input name="selectedPayment" [hidden]="true" aria-label="Select payment method" class="c-donate__payDialog__input" formControlName="selectedPayment" type="hidden"/>
    <app-pay-dialog class="c-donate__payDialog__control" (selectedPayment)="onSelectedPayment($event)"></app-pay-dialog>
    <div *ngIf="donateForm.get('selectedPayment').invalid && (donateForm.get('selectedPayment').touched || donateFormSubmitted)" class="c-forms__message is-bottom is-red" role="alert">
      <div *ngIf="donateForm.get('selectedPayment').errors.required">{{ messages.required }}</div>
    </div>
  </div>
  <ul class="c-donate__list">
    <li *ngIf="(authState | async)?.authenticated && selectedPayment">
      <div class="c-forms__group is-flexed">
        <label role="checkbox" class="c-checkbox">
          <input name="recurrent" (change)="onChangeRecurrentControl($event)" aria-label="Recurring charge" formControlName="recurrent" type="checkbox"/>
          <span class="c-checkbox__icon">
            <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
          </span>
          <span class="c-checkbox__caption"><b>Pledge monthly</b></span>
        </label>
        <i class="e-question" placement="top-center" ngbTooltip="You will be charged every month."></i>
      </div>
      <ul class="c-donate__list" [hidden]="!donateForm.get('recurrent').value">
        <li class="c-forms__group is-flexed" *ngIf="!creatorAbsorbFees">
          <div class="e-toggle__wrap no-marg-bottom">
            <label class="e-toggle">
              <input name="absorbFees" [(ngModel)]="recurrentOptions.absorbFees" aria-label="Absorb fees?" class="e-toggle__input" formControlName="absorbFees" type="checkbox"/>
              <span class="e-toggle__label"></span>
            </label>
            <span class="e-toggle__caption">Absorb Fees</span>
          </div>
          <i class="e-question" *ngIf="!recurrentOptions.absorbFees" placement="top-center" ngbTooltip="You will cover all credit card transaction fees so 100% of your backing goes to this Quest."></i>
          <i class="e-question" *ngIf="recurrentOptions.absorbFees" placement="top-center" ngbTooltip="The Quest creator will cover all credit card transaction fees."></i>
        </li>
        <li class="c-forms__group is-flexed">
          <div class="e-toggle__wrap no-marg-bottom">
            <label class="e-toggle">
              <input name="payLater" [(ngModel)]="recurrentOptions.payLater" aria-label="Pay later?" class="e-toggle__input" formControlName="payLater" type="checkbox"/>
              <span class="e-toggle__label"></span>
            </label>
            <span *ngIf="!recurrentOptions.payLater" class="e-toggle__caption">Pay Now</span>
            <span *ngIf="recurrentOptions.payLater" class="e-toggle__caption">Pay On</span>
          </div>
          <ng-template #tipContent>
            <p *ngIf="!recurrentOptions.payLater"><b>Pay Now</b> - You will be charged on the same day every month, starting today.</p>
            <p *ngIf="recurrentOptions.payLater"><b>Pay On</b> - You will be charged on the 1st of each month, starting the 1st of next month.</p>
          </ng-template>
          <i class="e-question" placement="top-center" [ngbTooltip]="tipContent"></i>
        </li>
      </ul>
    </li>
    <li>
      <div class="c-forms__group mb-20">
        <label role="checkbox" class="c-checkbox">
          <input name="anonymous" (change)="onChangeAnonymousControl($event)" aria-label="Anonymous payment?" formControlName="anonymous" type="checkbox"/>
          <span class="c-checkbox__icon">
            <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
          </span>
          <span class="c-checkbox__caption"><b>Hide my name on the supporter list</b></span>
        </label>
      </div>
    </li>
  </ul>

  <p class="c-donate__info__message" *ngIf="(authState | async)?.authenticated ? donateForm.get('recurrent').value : false">You will be backing this Quest monthly</p>
  <div class="c-forms__group" *ngIf="breakdown" [ngStyle]="{'opacity': breakdownQuestIsLoading ? 0.5 : 1}">
    <strong class="c-forms__legend">Total:</strong>
    <div class="c-donate__infoRow" *ngIf="!creatorAbsorbFees">
      <dl class="c-donate__info">
        <dt class="c-donate__info__label">Selected pledge</dt>
        <dd class="c-donate__info__value">{{ (breakdown.netTotal || 0) / 100.0 | currency }}</dd>
      </dl>
      <dl class="c-donate__info">
        <dt class="c-donate__info__label is-fee-info">{{ donateForm.get('recurrent')?.value && !recurrentOptions.payLater ? 'Today\'s Fee' : 'Total transaction fee' }}</dt>
        <dd class="c-donate__info__value is-fee-info">{{ (breakdown.stripeFee + breakdown.platformFee) / 100 | currency }}</dd>
      </dl>
      <dl class="c-donate__info">
        <dt class="c-donate__info__label total-payment">{{ (donateForm.get('recurrent') ? donateForm.get('recurrent').value : false && !recurrentOptions.payLater) ? 'Today\'s Charge' : 'Total Payment' }}</dt>
        <dd class="c-donate__info__value total-payment">{{ (breakdown.brutTotal || 0) / 100 | currency }}</dd>
      </dl>
    </div>
    <div class="c-donate__infoRow" *ngIf="creatorAbsorbFees">
      <dl class="c-donate__info">
        <dt class="c-donate__info__label">Selected pledge</dt>
        <dd class="c-donate__info__value">{{ (breakdown.brutTotal || 0) / 100.0 | currency }}</dd>
      </dl>
      <dl class="c-donate__info">
        <dt class="c-donate__info__label total-payment">{{ donateForm.get('recurrent')?.value && !recurrentOptions.payLater ? 'Today\'s Charge': 'Total Payment' }}</dt>
        <dd class="c-donate__info__value total-payment">{{ (breakdown.brutTotal || 0) / 100 | currency }}</dd>
      </dl>
    </div>
  </div>
  <div *ngIf="(authState | async)?.authenticated">
    <strong class="c-forms__legend">Please enter password:</strong>
    <div class="c-forms__group">
      <div class="c-forms__control is-required">
        <input name="password" [class.is-invalid]="wrongPassword" aria-label="Password" class="c-forms__input" formControlName="password" placeholder="DIEMlife password..." type="password"/>
      </div>
      <div *ngIf="donateForm.get('password').invalid && (donateForm.get('password').touched || donateFormSubmitted)"
        class="c-forms__message is-bottom is-red" 
        role="alert">
        <div *ngIf="donateForm.get('password').errors.required">{{ messages.required }}</div>
        <div *ngIf="donateForm.get('password').errors.minlength">{{ messages.password.minlength(donateForm.get('password').errors.minlength.requiredLength) }}</div>
        <div *ngIf="donateForm.get('password').errors.maxlength">{{ messages.password.minlength(donateForm.get('password').errors.maxlength.requiredLength) }}</div>
      </div>
    </div>
  </div>
  <div class="c-forms__group c-donate__submit">
    <button class="c-btn c-btn_dark c-btn_md" [ngClass]="{'is-loading': isLoading}" type="submit">
      <span>Confirm & Pledge</span>
    </button>
  </div>
</form>
