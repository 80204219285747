import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, OnChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, Meta, SafeResourceUrl, Title} from '@angular/platform-browser';
// import {QuestUserInfo} from '../../quest/quest.type';
import {ModalService} from '../../modal/modal.service';
import {ProfileService} from 'src/app/components/pages/profile/profile.service';
import {AccountService} from 'src/app/components/pages/account/account.service';
import {Observable, forkJoin} from 'rxjs';
import {take, isEmpty} from 'rxjs/operators';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import { QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';
import { FriendsService } from 'src/app/components/pages/friends/friends.service';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/_store/app.reducers';
import { UpdateUserInfo } from 'src/app/components/pages/profile/store/profile.actions';
import { ImageService } from '../../../../_services/image.service';

export enum FriendStatus {
  isAccepted,
  neededAccept,
  isRequested,
  noAction
}

@Component({
  selector: 'app-profile-header-new',
  templateUrl: './profile-header-new.component.html',
  styleUrls: ['./profile-header-new.component.styl']
})
export class ProfileHeaderNewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userInfo: QuestUserInfo;
  @Input() mode: string;
  @Input() country: string;
  @ViewChild('missionStatementField', {static: false}) missionStatementField: ElementRef;
  openedModal = '';
  profilePictureURL = null;
  coverPictureURL = null;
  selectedArea: string = null;
  editActive = false;
  isLoading: string;
  updatedMissionstatement = '';
  missionstatementMaxLength = 250;
  viewerStatus: FriendStatus;
  friendStatus = FriendStatus;
  private profileState: Observable<fromProfile.State>;

  constructor(
    private profileService: ProfileService,
    private modalService: ModalService,
    private accountService: AccountService,
    private store: Store<AppState>,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private friendsService: FriendsService,
    private router: Router,
    private meta: Meta,
    private titleService: Title,
    private imageService: ImageService
  ) {
	this.meta.addTag({property: 'og:title', content: ''});
    this.meta.addTag({property: 'og:description', content: ''});
    this.meta.addTag({property: 'og:url', content: this.router.url});
    this.meta.addTag({property: 'og:type', content: 'website'});
    this.meta.addTag({property: 'og:image', content: ''});
    this.meta.addTag({property: 'og:image:width', content: ''});
    this.meta.addTag({property: 'og:image:height', content: ''}); 
  }

  ngOnInit() {
    const imgUrl = (this.userInfo.profilePictureURL || this.userInfo.coverPictureURL || '/assets/images/useful/categories/mental.jpg');
    const imageResult = this.imageService.getImageDimensions(imgUrl).subscribe((response) => {
      this.setMetaTags(this.userInfo, imgUrl, response.imageWidth, response.imageHeight);
      imageResult.unsubscribe();
    });

    this.profilePictureURL = this.userInfo.profilePictureURL;
    this.coverPictureURL = this.userInfo.coverPictureURL;
    this.updatedMissionstatement = this.userInfo.missionStatement;
    this.loadUserFavorites();
    if (this.mode === 'public') {
      this.setProfileStatusRelativeToUser();
    }
  }
  ngOnChanges() {
  }
  ngOnDestroy() {
    this.modalService.remove('cropper-modal');
  }

  closeModal() {
    this.openedModal = '';
  }
  openDialog(selectedArea: string) {
    this.selectedArea = selectedArea;
    this.openedModal = 'cropper-modal';
    this.modalService.open('cropper-modal');
  }
  updateProfilePhoto(res: {selectedArea: string, croppedImage: any}) {
    console.log(res);
    if (res.selectedArea === 'profilePictureURL') {
      this.profilePictureURL = res.croppedImage;
    } else if (res.selectedArea === 'coverPictureURL') {
      this.coverPictureURL = res.croppedImage;
    }

    this.store.select('userInfo').pipe(take(1)).subscribe((state: fromProfile.State) => {
      const userInfo: fromProfile.State = { ...state };
      userInfo.profilePictureURL = this.profilePictureURL;
      userInfo.coverPictureURL = this.coverPictureURL;

      this.store.dispatch(new UpdateUserInfo(userInfo));
    });
  }
  private loadUserFavorites() {
    return Observable.create((observer) => {
      this.profileService.getUserFavoritesForUser(this.userInfo.id)
      .subscribe((userFavorites: any) => {
        const interests = {
          favPhysical: false,
          favIntel: false,
          favSocial: false,
          favEnv: false,
          favOcc: false,
          favFin: false,
          favSpirit: true
        };
        userFavorites.forEach(interest => {
          switch (interest.favorite) {
            case 'PHYSICAL':
              interests.favPhysical = true;
              break;
            case 'MENTAL':
              interests.favIntel = true;
              break;
            case 'SOCIAL':
              interests.favSocial = true;
              break;
            case 'ENVIRONMENTAL':
              interests.favEnv = true;
              break;
            case 'OCCUPATIONAL':
              interests.favOcc = true;
              break;
            case 'FINANCIAL':
              interests.favFin = true;
              break;
          }
        });
        observer.next(interests);
      });
    });
  }
  updateMissionStatement() {
    if (this.updatedMissionstatement.length === 0) {
      this.missionStatementField.nativeElement.focus();
      this.snotifyService.error(
        'Please add information and submit again!',
        null,
        this.snotifyConfigService.getConfig()
      );
    } else {
      this.isLoading = 'missionstatement';
      this.loadUserFavorites().pipe(take(1)).subscribe(interests => {
        const payload = {
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          username: null,
          email: this.userInfo.email,
          zip: this.userInfo.zip,
          newpassword: null,
          receiveEmail: this.userInfo.receiveEmail,
          missionStatement: this.updatedMissionstatement,
          ...interests
        };
        this.accountService.editProfile(payload).subscribe(res => {
          this.isLoading = null;
          this.editActive = false;
          this.userInfo.missionStatement = this.updatedMissionstatement;
        }, err => {
          this.isLoading = null;
          this.editActive = false;
        });
      });
    }
  }
  isMissionStatementInvalid() {
    return this.updatedMissionstatement.length === this.missionstatementMaxLength || this.updatedMissionstatement.length === 0;
  }

  validateWithMaxLength(event) {
    // fix for enable maxLength validation on specific gadgets
    const currentValue = event.target.value;

    if (currentValue.length >= this.missionstatementMaxLength) {
      event.target.value = this.updatedMissionstatement = (currentValue.substring(0, this.missionstatementMaxLength));
    }
  }
  setProfileStatusRelativeToUser() {
    this.store.select('userInfo').pipe(take(1)).subscribe((state: fromProfile.State) => {
      if (state.id) {
        forkJoin([
          this.friendsService.getCurrentFriends(),
          this.friendsService.getPendingFriendsToAccept(),
          this.friendsService.getPendingFriendsNoAction()
        ]).subscribe(([currentFriends, friendsNeededAccept, pendingFriendsNoAction]) => {
          const currentFriendsIds: any = {};
          const friendsNeededAcceptIds: any = {};
          const pendingFriendsNoActionIds: any = {};

          if (friendsNeededAccept instanceof Array) {
            friendsNeededAccept.forEach(friend => {
              friendsNeededAcceptIds[friend.id] = true;
            });
          }

          if (currentFriends instanceof Array) {
            currentFriends.forEach(friend => {
              currentFriendsIds[friend.id] = true;
            });
          }

          if (pendingFriendsNoAction instanceof Array) {
            pendingFriendsNoAction.forEach(friend => {
              pendingFriendsNoActionIds[friend.id] = true;
            });
          }

          if (currentFriendsIds[this.userInfo.id]) {
            this.viewerStatus = FriendStatus.isAccepted;
          } else if (pendingFriendsNoActionIds[this.userInfo.id]) {
            this.viewerStatus = FriendStatus.isRequested;
          } else if (friendsNeededAcceptIds[this.userInfo.id]) {
            this.viewerStatus = FriendStatus.neededAccept;
          } else {
            this.viewerStatus = FriendStatus.noAction;
          }
        });
      }
    });
  }
  addFriend() {
    this.isLoading = 'friendStatus';
    this.friendsService.sendFriendRequest(this.userInfo.id).subscribe((res) => {
      this.viewerStatus = FriendStatus.isRequested;
      this.isLoading = null;

      this.snotifyService.success(
        'Friend request sent successfully.',
        null, this.snotifyConfigService.getConfig()
      );
    }, () => {
      this.snotifyService.error(
        'There was an error attempting to get suggested friends. Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }
  cancelFriendRequest() {
    this.isLoading = 'friendStatus';
    this.friendsService.cancelFriendRequest(this.userInfo.id).subscribe((res) => {
      this.viewerStatus = FriendStatus.noAction;
      this.isLoading = null;
    }, () => {
      this.snotifyService.error(
        'Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }
  acceptFriendRequest() {
    this.isLoading = 'friendStatus';
    this.friendsService.acceptFriendRequest(this.userInfo.id).subscribe((res) => {
      this.isLoading = null;
      this.viewerStatus = FriendStatus.isAccepted;
    }, () => {
      this.snotifyService.error(
        'Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }
  removeFriend() {
    this.isLoading = 'friendStatus';
    this.friendsService.removeFriend(this.userInfo.id).subscribe((res) => {
      this.isLoading = null;
      this.viewerStatus = FriendStatus.noAction;
      this.snotifyService.success(
        'Successfully removed existing friend.',
        null, this.snotifyConfigService.getConfig()
      );
    }, () => {
      this.snotifyService.error(
        'There was an error attempting to remove an existing friend. Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }
  
  setMetaTags(userData: QuestUserInfo, imgUrl: string, imgWidth: string, imgHeight: string) {

    // create title tag
    var title = "";
    if (userData.firstName || userData.lastName) {
      if (userData.firstName && userData.lastName) {
        title += userData.firstName + " " + userData.lastName + " (@" + userData.userName + ") | DIEMlife";
      } else if (userData.firstName) {
        title += userData.firstName + " (@" + userData.userName + ") | DIEMlife";
      } else {
        title += userData.lastName + " (@" + userData.userName + ") | DIEMlife";
      }
    } else {
      title += "@" + userData.userName + " | DIEMlife";
    }

    // Create description tag
    var description = "";
    if (userData.missionStatement) {
      description += userData.missionStatement + " - Log in, complete Quests, and change your life.  Join the DIEMlife community.";
    } else {
      description += "Log in, complete Quests, and change your life.  Join the DIEMlife community.";
    }

    // Assign the tags
    this.titleService.setTitle('DIEMlife | ' + userData.userName);
    this.meta.updateTag({property: 'og:title', content: title});
    this.meta.updateTag({property: 'og:description', content: description});
    this.meta.updateTag({property: 'og:url', content: location.protocol + '//' + location.host + this.router.url});
    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({ property: 'og:image', content: imgUrl || '' });
    this.meta.updateTag({property: 'og:image:width', content: imgWidth || ''});
    this.meta.updateTag({property: 'og:image:height', content: imgHeight || ''});
  }

  clearMetaTags() {
    this.titleService.setTitle(null);
    this.meta.updateTag({property: 'og:title', content: ''});
    this.meta.updateTag({property: 'og:image', content: ''});
    this.meta.updateTag({property: 'og:description', content: ''});
    this.meta.updateTag({property: 'og:type', content: ''});
    this.meta.updateTag({property: 'og:image', content: ''});
    this.meta.updateTag({property: 'og:image:width', content: ''});
    this.meta.updateTag({property: 'og:image:height', content: ''});
  }
}
