import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as AuthActions from '../auth/store/auth.actions';
import {SelectFormMode} from '../auth/store/auth.actions';
import * as fromAuth from '../auth/store/auth.reducer';
import {FormMode, State} from '../auth/store/auth.reducer';

import * as fromApp from './../../../_store/app.reducers';
import * as Constants from './../../../app.constants';
import * as config from './../../../app.config';

@Component({
  selector: 'app-dl-auth',
  templateUrl: './dl-auth.component.html',
  styleUrls: ['./dl-auth.component.styl']
})
export class DlAuthComponent implements OnInit, OnDestroy {
  messages = Constants.VALIDATION_MESSAGES;
  authSubscribtion: Subscription;
  authState: Observable<fromAuth.State>;
  activeState: string = null;
  tabs = {
    logIn: 'Log In',
    signUp: 'Sign Up'
  };
  signinForm: FormGroup;
  signinFormSubmitted = false;
  recoveryFormSubmitted = false;
  recoveryForm: FormGroup;

  constructor(
    private store: Store<fromApp.AppState>
  ) {
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    this.signinForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl(null, [
        Validators.required,
        Validators.minLength(config.password.minLength),
        Validators.maxLength(config.password.maxLength)
      ])
    });
    this.recoveryForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ])
    });
    this.authSubscribtion = this.authState.subscribe((state: State) => {
      this.activeState = state.formMode ? FormMode[state.formMode] : 'logIn';
      this.signinForm.patchValue({email: state.email || null});
    });
    this.store.dispatch(new AuthActions.AuthSetLoadingState(false));
  }

  ngOnDestroy() {
    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
  }

  activateTab(formMode: string) {
    this.store.dispatch(new SelectFormMode(FormMode[formMode]));
  }

  showRecovery(email: string) {
    this.store.dispatch(new SelectFormMode(FormMode.recoveryPassword));
    this.recoveryForm.patchValue({'email': email});
  }

  onSignin() {
    this.signinFormSubmitted = true;
    const email = this.signinForm.value.email;
    const password = this.signinForm.value.password;
    if (this.signinForm.valid) {
      this.store.dispatch(new AuthActions.TrySignin({
        email: email,
        password: password
      }));
    }
  }

  onRecoveryPass() {
    this.recoveryFormSubmitted = true;
    const email = this.recoveryForm.value.email;
    if (this.recoveryForm.valid) {
      this.store.dispatch(new AuthActions.TryRecoveryPass({
        email: email
      }));
    }
  }
}
