<div class="c-quest-descption">
  <!-- <div class="c-quest-descption__header"> -->
    <!-- <div class="c-quest-descption__category">{{questInfo.pillar}}</div>
    <div class="c-quest-descption__date">Created {{questInfo.dateCreated | timeago}}</div> -->
    <!-- <h1 *ngIf="fundraisingLink?.campaignName"
        class="c-quest-descption__title">
      <span>{{fundraisingLink?.campaignName}}&nbsp;</span>
      <a [routerLink]="['/quest-detail', questInfo.id, questInfo.user.id]" [title]="questInfo.title">
        <svg class="icon icon-shield is-warning">
          <use xlink:href="#icon-shield"></use>
        </svg>
      </a>
    </h1>
    <h1 *ngIf="!fundraisingLink?.campaignName"
        class="c-quest-descption__title">{{questInfo.title}}</h1> -->
  <!-- </div> -->
  <div class="c-quest-descption__caption">
    <div #inner class="c-quest-descption__caption__inner">
      <div #caption *ngIf="!stringStyle" class="c-quest-descption__typo h-typography" [innerHTML]="currentText | safe: 'html'"></div>
      <div #caption *ngIf="stringStyle" class="h-typography">
        <p style="white-space: pre-line;">{{ currentText }}</p>
      </div>
    </div>
  </div>
</div>
