<form *ngIf="shouldShowSearchHeader" [formGroup]="searchForm" class="c-members__header">
  <div class="c-members__row">
    <div class="c-members__col">
      <div class="c-field__group">
        <Label class="c-field__label" for="members-filter-search">SEARCH</Label>
        <div class="c-field__control">
          <input formControlName="search" id="members-filter-search" aria-label="Search for Members" class="c-field__input"/>
        </div>
      </div>
    </div>

    <div class="c-members__col">
      <div class="c-field__group">
        <Label class="c-field__label" for="members-filter-select">FILTER</Label>
        <div class="c-field__control">
          <div class="c-field__select" dropdown>
            <button id="members-filter-select" class="c-field__select__control h-btn-reset" dropdownToggle type="button">
              <span>{{ selectTitle }}</span>
            </button>
            <div class="c-field__select__body" dropdownMenu>

              <div class="c-field__select__label" *ngFor="let status of statuses.controls; index as i" [ngSwitch]="filter.items[i].prop">
                <label [attr.for]="'member_status_' + i" class="c-field__select__checkbox" role="checkbox">
                  <input [attr.id]="'member_status_' + i" type="checkbox" [formControl]="status">
                  <span class="c-field__select__checkbox__icon">
                    <svg class="icon icon-remove"><use xlink:href="#icon-remove"></use></svg>
                  </span>
                  <span class="c-field__select__checkbox__caption">
                    <span>{{ filter.items[i].title }}</span>
                    <svg *ngSwitchCase="'Backer'" class="icon icon-balance"><use xlink:href="#icon-balance"></use></svg>
                    <svg *ngSwitchCase="'Doer'" class="icon icon-quest"><use xlink:href="#icon-Quest"></use></svg>
                    <svg *ngSwitchCase="'Achiever'" class="icon icon-saved"><use xlink:href="#icon-update"></use></svg>
                    <svg *ngSwitchCase="'Interested'" class="icon icon-saved"><use xlink:href="#icon-saved"></use></svg>
                  </span>
                </label>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


<ng-scrollbar *ngIf="filteredDoers" #scrollRef class="c-members__body" viewClass="c-workers-scroll-panel" thumbClass="custom-scrollbars" visibility="always">
  <div class="c-search__list" #searchList tabindex="0">
      <div
      infinite-scroll
      [scrollWindow]="false"
      [immediateCheck]="true"
      [infiniteScrollContainer]="'.c-workers-scroll-panel'"
      [fromRoot]="true"
      (scrolled)="loadMore()">
      <div class="c-search__item" *ngFor="let item of filteredDoers | sortMembers:{isListSort:false, mode:mode} | slice:0:limit; index as i">
        <app-friends-item
          class="c-my-friends__item"
          [ngClass]="{'has-currency': item.amountBackedSum > 0 && showBacking}"
          [friendInfo]="item"
          [point]="'member'"
          [showBacking]="showBacking"
          (closeDialog)="closeDialog()">
        </app-friends-item>
      </div>
    </div>
  </div>
  <div class="c-search__empty" *ngIf="filteredDoers.length === 0">
    <span>No results</span>
  </div>
</ng-scrollbar>
