<div class="c-donate__success c-container c-container_sm">
  <h1 class="c-donate__success__title">Thanks {{ (donateState | async).successInfo.billingInfo.personalInfo.firstName }}!</h1>
  <div class="c-donate__success__caption" *ngIf="isLoaded && questInfo">
    <strong>Your donation of {{ ((donateState | async).brutTotal || 0) / 100 | currency }} has been processed.</strong>
    <p>You will receive an email confirmation of your contribution <span *ngIf="(donateState | async).successInfo.signUp">and instructions on how to activate your new account.</span></p>
  </div>
  <div class="c-donate__success__tools">
    <div class="c-donate__success__tools__body">
      <div class="c-donate-btns">
        <div>
          <a [routerLink]="['/quest-detail/'+questId+'/'+userId]" class="c-btn c-btn_dark c-btn_md btn-margin min-width">
            <span>Back to Quest</span>
          </a>
        </div>
        <div>
          <button class="c-btn c-btn_dark c-btn_md min-width" (click)="openDialog('share-modal')">
            <svg class="icon icon-share"><use xlink:href="#icon-share"></use></svg>
            <span>Share</span>
          </button>
        </div>
      </div>
      
    </div>
  </div>
  <div class="c-donate__success__footer">
    <div class="c-donate__success__explore">
      <b>Feeling good?</b><a routerLink="/explore">Join, follow or support more Quests</a>
    </div>
  </div>
</div>


<app-modal id="share-modal" *ngIf="openedDialog === 'share-modal'" [ngStyle]="{'display': 'block'}" (closeModal)="closeModal()">
    <h3 class="modal-title">Select share method</h3>
    <app-share-widjet [questUserId]="questInfo.quest.user.id" [questId]="questInfo.quest.id" [shareUrl]="questInfo.questShareUrl" class="c-share-widjet"></app-share-widjet>
</app-modal>