import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProfileService} from './profile.service';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalService} from '../../main/modal/modal.service';
import {ExploreCardBaseType, Transaction} from 'diemlife-commons/dist/diemlife-commons-model';
import {ReferenceDataService} from 'src/app/_services/reference-data.service';

import * as fromApp from './../../../_store/app.reducers';
import * as fromProfile from './store/profile.reducer';
import * as fromProfileFriends from './../../separate/profile-friends/store/profile-friends.reducer';
import tools from './../../../_tools';
import {BankAccount} from '../../main/payments/store/payments.reducer';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import {VALIDATION_MESSAGES, ValidationMessages} from 'src/app/app.constants';
import {saveAs} from 'file-saver';
import {Meta, Title} from '@angular/platform-browser';
import { UdpCurrencyMaskPipe } from '../../../_pipes/udp-currency-mask.pipe';

interface TabItem {
  id: string;
  caption: string;
}

export interface Tabs {
  active: TabItem;
  list: TabItem[];
}

interface Balance {
  available: number;
  pending: number;
  total?: number;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.styl']
})
export class ProfileComponent implements OnInit, OnDestroy {
  // firstBankAccount = 0;
  routeParamsSubscriber: Subscription;
  balance: Balance = {
    available: null,
    pending: null,
    total: null
  };
  country: string = null;
  mode: string;
  id: number = null;
  username: string = null;
  profileInfo: fromProfile.State;
  profileLoaded = false;


  currentFriends: fromProfileFriends.State[] = [];
  friendsLoaded = false;

  userInfo: fromProfile.State = fromProfile.initialState;
  private userInfoState: Observable<fromProfile.State>;
  private userInfoSubscriber: Subscription;

  recentTransactions: Transaction[] = [];
  private recentTransactionsState: Observable<Transaction[]>;
  private recentTransactionsSubscriber: Subscription;
  limitTrans = 10;
  transactionsLoaded = false;

  quests: ExploreCardBaseType[];
  questsLimit = 6;
  questsLoaded = false;

  sendPayoutForm: FormGroup;
  sendPayoutFormsSubmitted = false;
  messages: ValidationMessages = VALIDATION_MESSAGES;
  openedDialog: string;
  bankAccount: BankAccount;

  isLoading = '';
  tabs: Tabs = {
    active: null,
    list: [
      {
        id: 'quests',
        caption: 'Quests'
      },
      {
        id: 'personal',
        caption: 'Personal'
      }
    ]
  };
  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(
    private referenceDataService: ReferenceDataService,
    private profileService: ProfileService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private fb: FormBuilder,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private meta: Meta,
    private titleService: Title,
    public currencyMask: UdpCurrencyMaskPipe,
  ) {
    this.routeParamsSubscriber = route.params.subscribe(() => {
      this.destroyPage();
      this.resetVariables();
      setTimeout(() => {
        this.initPage();
      }, 0);
    });
  }

  ngOnInit() {
    this.initPage();
    this.initPayoutsLogic();

    // This page had been editing meta tags.  Removed that for main/profile/* to supersede it.
  }

  ngOnDestroy() {
    this.destroyPage();
    if (this.routeParamsSubscriber) { this.routeParamsSubscriber.unsubscribe(); }
  }

  resetVariables() {
    // this.firstBankAccount = 0;
    this.country = null;
    this.mode = null;
    this.id = null;
    this.username = null;
    this.profileInfo = null;
    this.profileLoaded = false;
    this.currentFriends = [];
    this.friendsLoaded = false;
    this.userInfo = fromProfile.initialState;
    this.userInfoState = null;
    this.userInfoSubscriber = null;
    this.recentTransactions = [];
    this.recentTransactionsState = null;
    this.recentTransactionsSubscriber = null;
    this.limitTrans = 10;
    this.transactionsLoaded = false;
    this.quests = [];
    this.questsLimit = 6;
    this.questsLoaded = false;
    this.sendPayoutForm = null;
    this.openedDialog = null;
    this.bankAccount = null;
    this.tabs = {
      active: null,
      list: [
        {
          id: 'quests',
          caption: 'Quests'
        },
        {
          id: 'personal',
          caption: 'Personal'
        }
      ]
    };
    this.balance = {
      available: null,
      pending: null,
      total: null
    };
  }


  openDialog(id: string) {
    this.modalService.open(id);
  }

  closeModal() {
  }

  initPayoutsLogic() {
    this.profileService.getFirstBankAccountForPayouts().subscribe((bankAccount: [BankAccount]) => {
      this.bankAccount = bankAccount[0];
    });
    this.sendPayoutForm = this.fb.group({
      'payoutAmount': ['', [Validators.required]]
    });

    this.sendPayoutForm.controls.payoutAmount.valueChanges.subscribe(val => {
      const maskedVal = this.currencyMask.transform(val);
      this.sendPayoutForm.patchValue({payoutAmount: maskedVal}, {emitEvent: false});
    });
  }

  sendPayout() {
    this.sendPayoutFormsSubmitted = true;
    if (this.sendPayoutForm.valid && !this.isLoading) {
      this.isLoading = 'transfer-money';
      const amountString = (String(this.currencyMask.formatString(this.sendPayoutForm.value.payoutAmount) || '0.0')).trim();
      if (!this.isNumber(amountString)) {
        return false;
      }
      const amountInCents = Math.round(parseFloat(amountString) * 100.0);
      if (amountInCents < 0 || amountInCents > this.balance.available) {
        this.snotifyService.warning('Transfer amount cannot exceed available balance!', null, this.snotifyConfigService.getConfig());
        return false;
      }
      this.profileService.requestPayout(amountInCents).subscribe((res) => {
        this.modalService.close('send-payout-modal');
        // tslint:disable-next-line:max-line-length
        this.snotifyService.success('Money transfer to your bank account successfully requested.', null, this.snotifyConfigService.getConfig());
        this.reloadBalance();
        this.sendPayoutForm.reset();
        this.isLoading = ''; // reset
      }, () => {
        this.snotifyService.error('Oops, something went wrong', null, this.snotifyConfigService.getConfig());
        this.isLoading = ''; // reset
      });
    }
  }

  isNumber(n: string) {
    return !isNaN(parseFloat(n)) && isFinite(Number(n));
  }
  initPage() {

    // Extract the id as a string username but attempt a conversion to an integer id
    this.username = this.route.snapshot.paramMap.get('userId');
    this.id = ((this.username == null) ? null : parseInt(this.username, 10));
    this.id = (isNaN(this.id) ? null : this.id);

    this.onTabChange(this.tabs.list[0]);
    this.getProfileInfo(() => {
      this.referenceDataService.getSupportedCountries().subscribe((res: {[key: string]: string}) => {
        this.country = res[this.profileInfo.country];
      });
      this.profileService.getUserCurrentFriends(this.profileInfo.id)
        .subscribe((currentFriends: fromProfileFriends.State[]) => {
          this.currentFriends = currentFriends ? currentFriends : [];
          this.friendsLoaded = true;
        });
      this.profileService.getQuestsRorUser(this.profileInfo.id)
        .subscribe((quests: ExploreCardBaseType[]) => {
          this.quests = quests ? quests : [];
          this.questsLoaded = true;
        });
      if (this.mode === 'private') {
        this.profileService.getRecentTransactions();
        this.recentTransactionsState = this.store.select('recentTransactions');
        this.recentTransactionsSubscriber = this.recentTransactionsState.subscribe(
          tools.debounce((recentTransactions: Transaction[]) => {
            this.transactionsLoaded = true;
            if (recentTransactions) {
              this.recentTransactions = recentTransactions;
            }
          }, 0)
        );
        this.reloadBalance();
      }
      this.profileLoaded = true;
    });
  }

  destroyPage() {
    if (this.userInfoSubscriber) { this.userInfoSubscriber.unsubscribe(); }
    if (this.recentTransactionsSubscriber) { this.recentTransactionsSubscriber.unsubscribe(); }
    this.modalService.remove('subscription_modal');
    this.modalService.remove('send-payout-modal');
  }

  getProfileInfo(callback: Function) {
    this.userInfoState = this.store.select('userInfo');
    this.userInfoSubscriber = this.userInfoState.subscribe(
      tools.debounce((userInfo: fromProfile.State) => {
        this.userInfo = userInfo;
        this.checkProfileMode(this.userInfo.id, this.userInfo.userName);
        if (this.id == null) {
          this.profileService.getUserInfoByName(this.username)
            .subscribe((profileInfo: fromProfile.State) => {
              this.profileInfo = profileInfo;
              callback();
            });
        } else {
          this.profileService.getUserInfoById(this.id)
            .subscribe((profileInfo: fromProfile.State) => {
              this.profileInfo = profileInfo;
              callback();
            });
        }
      }, 0)
    );
  }

  goToQuestlink(res: {questId: number; userId: number}) {
    this.router.navigate(['/quest-detail', res.questId, res.userId]);
  }

  goToProfileLink(res: {userId: number}) {
    this.router.navigate(['/profile', res.userId]);
  }

  checkProfileMode(loggedUserId: number, loggedUserName: string) {
    if ((this.id == null) && (this.username == null)) {
      // don't have either, use the current user's stuff, favoring name
      this.username = loggedUserName;
      this.mode = "private";
    } else if (this.id != null) {
      // have id, so we can check if target user is same as logged in user
      this.mode = ((this.id !== loggedUserId) ? "public" : "private");
    } else {
      // have username, so we can check if target user is same as logged in user
      this.mode = ((this.username !== loggedUserName) ? "public" : "private");
    }
  }

  loadMoreTransactions() {
    this.limitTrans += 10;
  }

  loadMoreQuests() {
    this.questsLimit += 6;
  }

  onTabChange(tab) {
    this.tabs.active = { ...tab };
  }

  reloadBalance() {
    this.profileService.getAvailableBalance().subscribe((balance: Balance) => {
      const available = balance.available || 0;
      const pending = balance.pending || 0;
      const total = available + pending;
      this.balance.available = available;
      this.balance.pending = pending;
      this.balance.total = total;
    });
  }

  downloadTransactions() {
    this.profileService.downloadTransactionsCSV().subscribe(data => {
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'DIEMlife_transactions.csv');
    });
  }
}
