// tslint:disable-next-line:max-line-length
import {QuestTask} from 'diemlife-commons/dist/diemlife-commons-model';

export enum DlMilestoneManageModes {
  QUEST_MANAGE = 'QUEST_MANAGE',
  QUEST_DETAILS = 'QUEST_DETAILS',
  NEW_QUEST = 'NEW_QUEST'
}

export interface UpdateMilestonesGroupNamePayload {
  groupId: number;
  groupName: string;
}
export interface AddMilestonesToGroupPayload {
  id: number;
  groupId: number;
}
export interface AddLinkPreviewToMilestonePayload {
  taskId: number;
  taskLink: string;
}
export interface AddLinkPreviewToMilestonePayload {
  taskId: number;
  taskLink: string;
}
export interface AddImageToMilestonePayload {
  taskId: number;
  taskImage: string;
  contentType: string;
}

export interface AddVideoUrlToMilestonePayload {
  url: string;
  provider: 'YOUTUBE' | 'VIMEO';
  thumbnails: {
    xs: string;
    sm: string;
    md: string;
  };
}
export interface AddMilestonePayload {
  task: string;
  video: AddVideoUrlToMilestonePayload;
  linkUrl: string;
  linkedQuestId: number;
  imageUrl: string;
}

export interface AddVideoToMilestonePayload {
  id: number;
  task: string;
  video: AddVideoUrlToMilestonePayload;
}

export interface TaskGroup {
  id: number;
  name: string;
  questTasks: QuestTask[];
}
export interface NewTaskGroup {
  createdDate: number;
  groupName: string;
  groupOrder: number;
  id: number;
  questId: number;
  questTasks: QuestTask[];
  userId: number;
}
