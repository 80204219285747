<form action="#" name="teamForm" class="c-team-form" [formGroup]="teamFormGroup">
  <fieldset name="teamNameFields" class="c-team-form__fields" [disabled]="progress">

    <!-- * CONTROL "teamName" START -->
    <ng-template #teamNameControlValidation>
      <div class="c-n-form__alert" role="alert" [ngClass]="{
          'is-black': teamFormGroup.get('teamName').status === 'PENDING',
          'is-green': teamFormGroup.get('teamName').status === 'VALID'
        }">
        <ng-container *ngIf="teamFormGroup.get('teamName').status === 'PENDING'">Checking...</ng-container>
        <ng-container *ngIf="teamFormGroup.get('teamName').status === 'VALID'">Looks good!</ng-container>
        <ng-container *ngIf="(teamFormGroup.get('teamName').touched || formIsSubmitted) && teamFormGroup.get('teamName').invalid">
          <ng-container *ngIf="teamFormGroup.get('teamName').status === 'INVALID'">
            <div *ngIf="teamFormGroup.get('teamName').errors['required']">Team name is required!</div>
            <div *ngIf="teamFormGroup.get('teamName').errors['maxlength']">Text is too long!</div>
            <div *ngIf="teamFormGroup.get('teamName').errors['duplicateTeamName']">This name is already taken!</div>
            <div *ngIf="teamFormGroup.get('teamName').errors['invalid']">This name is invalid!</div>
            <div *ngIf="teamFormGroup.get('teamName').errors['notAllowed']">This name is not allowed!</div>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
    <app-ui-control class="c-n-form__group"
      legend="Team name:"
      [control]="teamFormGroup.get('teamName')"
      [controlClass]="{'is-required': true}"
      [formIsSubmitted]="formIsSubmitted"
      [validationTemplate]="teamNameControlValidation">
      <input type="text"
             class="c-n-form__input"
             id="team-form-name"
             placeholder="Awesome Team"
             name="teamName"
             formControlName="teamName"
             [required]="true"/>
    </app-ui-control>
    <!-- * CONTROL "teamName" END -->

  </fieldset>
  <fieldset name="teamFundraisingFields" class="c-team-form__fields" [disabled]="progress" *ngIf="fundraisingEnabled">
    <app-ui-control class="c-n-form__group"
      legend="Fundraising amount:"
      [control]="teamFormGroup.get('targetAmount')"
      [controlClass]="{'is-required': true}"
      [formIsSubmitted]="formIsSubmitted">
      <input type="text"
             class="c-n-form__input"
             id="team-form-amount"
             placeholder="$0"
             prefix="$"
             mask="0000000"
             [validation]="false"
             name="targetAmount"
             formControlName="targetAmount"/>
    </app-ui-control>
  </fieldset>
  <fieldset name="teamLogoFields" class="c-team-form__fields" [disabled]="progress">
    <div class="c-forms__control">
      <app-dropzone-upload formControlName="teamLogoUrl"
                           placeholder="Your team logo"
                           [large]="true"
                           (uploadProgress)="onLogoUploadProgress($event)"></app-dropzone-upload>
    </div>
  </fieldset>
  <div class="c-team-form__footer">
    <button class="c-btn c-btn_md c-btn_dark c-btn_full"
            type="button"
            (click)="onSubmitTeamForm()"
            [disabled]="progress || uploadProgress || checkProgress || teamFormGroup.invalid">
      <span>Create team</span>
    </button>
  </div>
</form>
