<div *ngIf="favoritesLoaded">
  <div class="c-article__header is-md-centered">
    <h3 class="c-article__title">Interests</h3>
    <div class="c-article__tools" *ngIf="mode === 'private'">
      <button class="c-article__tool h-btn-reset" *ngIf="!isEditActive" (click)="isEditActive = true">
        <svg class="icon icon-pencil"><use xlink:href="#icon-pencil"></use></svg>
        <span>Edit</span>
      </button>
      <button class="c-article__tool h-btn-reset" *ngIf="isEditActive" (click)="isEditActive = false">
        <svg class="icon icon-checked">
          <use xlink:href="#icon-checked"></use>
        </svg>
        <span>Done</span>
      </button>
    </div>
  </div>
  <div class="c-article__body">
    <div class="c-interests" *ngIf="!userFavoritesIsEmpty || isEditActive">
      <div class="c-interests__inner">
        <button class="c-interest h-xs-loader h-btn-reset"
          *ngFor="let item of interests | keys"
          [ngSwitch]="item.value.icon"
          [ngClass]="{'is-active': item?.value?.isActive, 'is-loading': isLoading === item.key, 'is-editable': isEditActive}"
          (click)="updateInterest(item.key)"
          type="button">
          <span class="c-interest__caption h-xs-loader__caption">
            <svg *ngSwitchCase="'physical'" class="c-interest__icon icon icon-physical"><use xlink:href="#icon-physical"></use></svg>
            <svg *ngSwitchCase="'environmental'" class="c-interest__icon icon icon-environmental"><use xlink:href="#icon-environmental"></use></svg>
            <svg *ngSwitchCase="'occupational'" class="c-interest__icon icon icon-occupational"><use xlink:href="#icon-occupational"></use></svg>
            <svg *ngSwitchCase="'mental'" class="c-interest__icon icon icon-mental"><use xlink:href="#icon-mental"></use></svg>
            <svg *ngSwitchCase="'friends'" class="c-interest__icon icon icon-friends"><use xlink:href="#icon-friends"></use></svg>
            <svg *ngSwitchCase="'piggybank'" class="c-interest__icon icon icon-piggybank"><use xlink:href="#icon-piggybank"></use></svg>
            <svg class="icon icon-check"><use xlink:href="#icon-check"></use></svg>
            <span>{{ item.key }}</span>
          </span>
          <span class="c-interest__control h-xs-loader__control"></span>
        </button>
      </div>
    </div>
    <div *ngIf="userFavoritesIsEmpty && mode === 'private' && !isEditActive">
      <div class="c-my-friends__profile-empty">
        <svg class="icon icon-sad h-sd-min-hidden"><use xlink:href="#icon-sad"></use></svg>
        <span>No interests selected</span>
      </div>
    </div>
    <div *ngIf="userFavoritesIsEmpty && mode === 'public' && !isEditActive">
      <div class="c-my-friends__profile-empty">
        <svg class="icon icon-sad h-sd-min-hidden"><use xlink:href="#icon-sad"></use></svg>
        <span>{{userInfo.firstName}} has no interests yet</span>
      </div>
    </div>
  </div>
</div>