<form
  autocomplete="new-password"
  [formGroup]="recoveryForm" 
  #ngForm="ngForm"
  (ngSubmit)="onRecoveryPass()"
  class="c-n-modal__body"
  [ngClass]="{'form-is-submitted': recoveryFormSubmitted}">
  <h5 class="c-n-modal__title t-typo__h3">Log In</h5>
  <p class="c-n-modal__sub-title-switch t-typo__h6">Enter your email and we'll send a recovery link.</p>
  <div class="c-n-form__group">
    <div class="c-n-form__legend">Email:</div>
    <div class="c-n-form__control is-required">
      <input 
        class="c-n-form__input" 
        type="email"
        formControlName="email"
        name="email"
        autocomplete="new-password"
        placeholder="janedoe@diemlife.com"
        aria-label="Enter email to recover password"
        appInputFocusTrigger>
      <span class="c-n-form__border"></span>
    </div>
  </div>
  <div 
    *ngIf="recoveryForm.get('email').invalid && (recoveryForm.get('email').touched || ngForm.submitted)"
    class="c-n-form__message is-red" 
    role="alert">
    <div *ngIf="recoveryForm.get('email').errors.required">{{ messages.required }}</div>
    <div *ngIf="recoveryForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
  </div>
  <div class="c-n-modal__footer is-space-between">
    <a class="t-typo__link is-flexed" (click)="changeAuthTab('logIn')" href="javascript:void(null)">
      <svg class="icon icon-arrow-left"><use xlink:href="#icon-arrow_left"></use></svg>
      <span>Back to the Login</span>
    </a>
    <button
      [hidden]="recoveryForm.invalid"
      [attr.disabled]="(authState | async)?.isLoading ? true : null"
      type="submit"
      class="e-btn e-btn_dark"
      [ngClass]="{'is-loading': (authState | async).isLoading}"
      aria-label="Submit">
      <span>Submit</span>
    </button>
  </div>
</form>