<div class="c-mega-quest-link">
  <p>This Quest is linked to:</p>
  <div class="c-mega-quest-link__linked-quest" *ngFor="let megaQuest of megaQuests">
    <a class="c-mega-quest-link__linked-quest-link"
       [href]="megaQuest.seoSlugs.seoFriendlyUrl"
       [title]="megaQuest.title">
      <img [src]="wrapPhoto(megaQuest).jpg" [alt]="megaQuest.title"/>
    </a>
    <span class="c-mega-quest-link__linked-quest-title">{{megaQuest.title}}</span>
  </div>
</div>
