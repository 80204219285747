<ngx-slick
  id="galleryKey"
  class="c-gallery my-gallery carousel"
  #slickModal="slick-modal"
  [config]="slideConfig"
  (afterChange)="afterChange($event)"
  (init)="onInitCarousel($event)"
  itemscope
  (click)="openGallery()"
  itemtype="http://schema.org/ImageGallery"
  *ngIf="isLoaded">
  <div class="c-gallery__item">
  <figure *ngFor="let slide of images; index as i" class="c-gallery__figure" class="slide"  itemprop="associatedMedia" itemscope
          itemtype="http://schema.org/ImageObject"
          ngxSlickItem>
    <a
      [attr.creatoravatarUrl]="slide.creator.avatarUrl ? slide.creator.avatarUrl : ''"
      [attr.creatorfirstName]="slide.creator.firstName ? slide.creator.firstName : ''"
      [attr.creatorlastName]="slide.creator.lastName ? slide.creator.lastName : ''"
      [attr.href]="slide.questImageUrl"
      [href]="slide.questImageUrl"
      class="c-gallery__item"
      [attr.data-size]="slide.questImageDimensions"
      itemprop="contentUrl">
      <div class="c-gallery__item__thumb">
      <img #img (load)="getPhotoDimensions(slide, img)" [alt]="getAltText(slide.creator.name)" itemprop="thumbnail" src="{{ slide.questImageUrl }}"/>
      </div>
    </a>
    <figcaption *ngIf="slide.caption" itemprop="caption description">{{ slide.caption }}</figcaption>
    <figcaption *ngIf="!slide.caption" itemprop="caption description"> </figcaption> <!--Just hiding the trash can on an open carousel for now -->
    <button (click)="confirmRemoveQuestPhoto($event, slide.id, i)" *ngIf="(userInfoState | async).id === slide.creator.id"
            class="c-gallery__item__delete h-btn-reset"
            type="button">
      <svg class="icon icon-trash">
        <use xlink:href="#icon-trash"></use>
      </svg>
    </button>
  </figure>
  </div>
</ngx-slick>
<div class="c-gallery__tools" #toolsContainer>
  <div class="c-gallery__tools__caption">{{ images.length }} photo{{ images.length > 1 ? 's' : ''}} </div>
</div>
