<ng-container *ngIf="teamsList.length > 0">
  <select
    title="Select your team..."
    bs-select-directive
    [events]="eventsSubject.asObservable()"
    [size]="5"
    data-live-search="true"
    data-style="c-dl-team-control__header c-n-form__input"
    (close)="regTouch()"
    (change)="selectTeam($event.target.value)">

    <option value="" [attr.data-content]="buildEmptyTeamOption()">No team</option>

    <option
      *ngFor="let team of teamsList; index as i"
      [attr.data-content]="buildTeamOption(i)"
      [attr.data-tokens]="team.teamName"
      [attr.selected]="team.teamId === selectedId ? true : null"
      [attr.value]="team.teamId">{{ team.teamName }}</option>

  </select>
</ng-container>
