import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { API_URL, reCaptchaToken } from '../../../app.config';
import * as Constants from '../../../app.constants';
import { ReferenceDataService } from '../../../_services/reference-data.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent implements OnInit {
  form: FormGroup;
  sent: boolean = false;
  captchaToken: string = reCaptchaToken;
  messages = Constants.VALIDATION_MESSAGES;
  captchaFailed = false;

  constructor(private http: HttpClient,
    private referenceDataService: ReferenceDataService,
    ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
      ])),
      message: new FormControl('', Validators.required),
      recaptcha: new FormControl('', Validators.required)
    });
  }

  onSubmit(contactForm) {
    console.log("Submitted Contact Us form")
    const contents = Object.entries(contactForm)
    for (const content of contents) {
      console.log(content[0] + ": " + content[1]);
    }
    const link = API_URL + `contact?name=${contactForm.name}&email=${contactForm.email}&desc=${contactForm.message}`;
    console.log(link);
    this.form.reset();
    this.sent = true;
    return this.http.post(link, null)
      .subscribe((result) => {
        console.log(result);
        this.sent = true;
      });
  }

  checkBlankFields() {
    return this.form.get('name').hasError('required') ||
      this.form.get('email').hasError('required') ||
      this.form.get('message').hasError('required');
  }

  handleSuccess($event: string) {
    this.referenceDataService.verifyCapcha($event).subscribe((res: any) => {
      this.captchaFailed = res !== 'status = 200';
    });
  }

}
