<div class="c-article__header">
  <h4 class="c-article__title">Monthly Subscriptions&nbsp;<span *ngIf="subscriptions.length">{{ subscriptions.length }}</span></h4>
  <!-- <div class="c-article__tools" *ngIf="users.length">
    <a href="#" class="c-article__tool" (click)="openDialog($event)">See all friends</a>
  </div> -->
</div>
<div class="c-article__body" *ngIf="subscriptions.length > 0">
  <div class="c-subscription--wrap" *ngFor="let subscription of subscriptions; index as i">
      <a href="javascript:void(null)" (click)="openDialog(i)" class="c-subscription" [ngClass]="{'is-cancelled': subscription.cancelled}">
          <app-profile-avatar
            class="c-subscription__view c-avatar"
            [avatar]="subscription.quest.user.avatarUrl"
            [firstName]="subscription.quest.user.firstName"
            [isBrand]="false"
            [lastName]="subscription.quest.user.lastName"
            [userName]="subscription.quest.user.userName">
          </app-profile-avatar>
          <div class="c-subscription__caption">
            <strong class="c-subscription__title">
                <span>{{subscription.quest.title}}</span>
            </strong>
          </div>
          <div class="c-subscription__tools" *ngIf="!subscription.cancelled">
              <div class="c-subscription__next-date">
                  <span>next billing: </span><b>{{subscription.nextDueOn | date: 'MM/dd/yyyy' }}</b>
              </div>
              <div class="c-subscription__start-date">
                  <span>started on: </span> <b>{{subscription.createdOn | date: 'MM/dd/yyyy' }}</b>
              </div>
          </div>
          <div class="c-subscription__tools" *ngIf="subscription.cancelled">
              <div class="c-subscription__status">
                  <span>Cancelled</span>
              </div>
          </div>
      </a>
  </div>
</div>
<div class="c-article__body" *ngIf="subscriptions.length === 0">
  <div class="c-my-friends__profile-empty">
    <svg class="icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
    <span>You do not have any subscriptions yet</span>
  </div>
</div>

<app-modal
  *ngIf="openedDialog === 'subscription_modal' && selectedSubscrition >= 0"
  [ngStyle]="{'display': 'block'}"
  [className]="'c-dialog'"
  (closeModal)="closeDialog()" id="subscription_modal">
  <div class="c-dialog__header">
    <h3 class="c-dialog__title is-align-left">
      <span>Subscription details</span>
    </h3>
  </div>

  <div class="c-dialog__bottom-tools" *ngIf="!subscriptions[selectedSubscrition].cancelled">
    <a href="javascript:void(null)" (click)="confirmingTheCancellation()" class="c-dialog__tool is-red">
      <span>Delete Subscription</span>
    </a>
  </div>
  <div class="c-dialog__body h-loader" [ngClass]="{'is-loading': isLoading}">
    <div class="c-dialog__inner">
      <div class="c-subscription__detail h-loader__caption">
        <div class="c-subscription__detail__header">
          <span>Quest title:</span>
          <h3 class="c-subscription__detail__title">{{ subscriptions[selectedSubscrition].quest.title }}</h3>
        </div>
        <div class="c-article">
          <div class="c-article__header">
            <h4 class="c-article__title">transactions:</h4>
          </div>
          <div class="c-article__body">
            <div class="c-subscription__detail__body">
              <div class="c-subscription__detail__group">
                <app-profile-transaction 
                  (emitGoToQuestlink)="goToQuestlink($event)"
                  (emitGoToProfileLink)="goToProfileLink($event)"
                  *ngFor="let trans of recentTransactions"
                  [data]="trans"
                  class="c-transaction">
                </app-profile-transaction>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-loader__control"></div>
  </div>
</app-modal>