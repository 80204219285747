import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/components/pages/profile/profile.service';
import { ProfileSubscription } from './profile-subscriptions.type';
import { ModalService } from '../../modal/modal.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyConfigService } from 'src/app/_services/snotify-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-subscriptions',
  templateUrl: './profile-subscriptions.component.html',
  styleUrls: ['./profile-subscriptions.component.styl']
})
export class ProfileSubscriptionsComponent implements OnInit {
  subscriptions: ProfileSubscription[] = [];
  openedDialog: string;
  selectedSubscrition: number;
  recentTransactions: any;
  isLoading = false;
  constructor(
    private profileService: ProfileService,
    private modalService: ModalService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private router: Router
  ) { }
  // | slice:0:limit;
  ngOnInit() {
    this.profileService.getSubscriptions().subscribe((subscriptions: ProfileSubscription[]) => {
      this.subscriptions = subscriptions;
    });
  }
  openDialog(index: number) {
    this.isLoading = true;
    this.selectedSubscrition = index;
    this.openedDialog = 'subscription_modal';
    this.profileService.getSubscriptionTransactions(this.subscriptions[index].id).subscribe(res => {
      this.isLoading = false;
      this.recentTransactions = res;
    });
    setTimeout(() => {
      this.modalService.open('subscription_modal');
    });
  }
  closeDialog() {
    setTimeout(() => {
      this.openedDialog = '';
    });
  }
  confirmingTheCancellation() {
    // tslint:disable-next-line:max-line-length
    const tmp = `<p>Are you sure you want to delete your subscription to <b>${this.subscriptions[this.selectedSubscrition].quest.title}</b>?</p><p><b>${this.subscriptions[this.selectedSubscrition].doer.name} will lose your valuable support.</b></p>`;
    this.snotifyService.confirm(
      `Delete Subscription?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        html: tmp,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.cancelSubscription();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'Cancel',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );

  }
  cancelSubscription() {
    this.profileService.cancelSubscription(this.subscriptions[this.selectedSubscrition].id).subscribe(() => {
      this.subscriptions[this.selectedSubscrition].cancelled = true;
      this.selectedSubscrition = undefined;
      this.modalService.close('subscription_modal');
      this.snotifyService.success(
        'Successfully cancelled subscription.',
        null,
        this.snotifyConfigService.getConfig()
      );
    });
  }
  goToQuestlink(res: {questId: number; userId: number}) {
    setTimeout(() => {
      this.modalService.close('subscription_modal');
      this.router.navigate(['/quest-detail', res.questId, res.userId]);
    });
  }
  goToProfileLink(res: {userId: number}) {
    setTimeout(() => {
      this.modalService.close('subscription_modal');
      this.router.navigate(['/profile', res.userId]);
    });
  }
}
