import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ReaquestHeadersService} from '../../../_services/requestHeaders.service';
import {forkJoin, Observable} from 'rxjs';
import {ReferenceDataService} from '../../../_services/reference-data.service';
import {PaymentsService} from '../../main/payments/payments.service';


import {BankAccount, CreditCard} from '../../main/payments/store/payments.reducer';
import {QuestUserInfo} from 'diemlife-commons/dist/diemlife-commons-model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private referenceDataService: ReferenceDataService,
    private paymentsService: PaymentsService
  ) { }
  getSupportedCountries () {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/ref/supported-countries',
      {
        headers: headers
      }
    );
  }
  getUserInfo (): Observable<QuestUserInfo> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestUserInfo>(
      environment.target + environment.context + '/getuserinfo',
      {
        headers: headers,
        withCredentials: true
      }
    );
  }
  getUserInfoById (id) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      userId: id
    };
    return this.http.post(
      environment.target + environment.context + '/getuserinfobyid',
      payload,
      { headers: headers }
    );
  }
  getBankAccount (purpose: string): Observable<BankAccount[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<BankAccount[]>(
      environment.target + environment.context + `/accounts/bank-accounts/list/${purpose}`,
      { headers: headers }
    );
  }

  getBankAccounts(): Observable<BankAccount[][]> {
    return forkJoin([this.getBankAccount('payments'), this.getBankAccount('payouts')]);
  }

  getCreditCards(): Observable<CreditCard[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<CreditCard[]>(
      environment.target + environment.context + '/accounts/listcreditcards',
      { headers: headers }
    );
  }
  getFullyPaymentsInfo(): Observable<[BankAccount[][], CreditCard[]]> {
    return forkJoin([this.getBankAccounts(), this.getCreditCards()]);
  }
  getAccountInfo () {
    return forkJoin([this.getUserInfo(), this.getSupportedCountries()]);
  }
  getFullyAccountInfo () {
    return forkJoin([this.getUserInfo(), this.getCreditCards()]);
  }
  editProfile (payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/editprofile',
      payload,
      { headers: headers }
    );
  }
  editPassword (password: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      password: password
    };
    return this.http.post(
      environment.target + environment.context + '/changepassword',
      payload,
      { headers: headers }
    );
  }
}
