<form [formGroup]="questForm" (ngSubmit)="questEditInfo ? confirmEditQuest() : createQuest()" class="c-container c-container_md" [ngClass]="{'form-is-submitted': formSubmitted}">
  <div class="c-quest__inner">
    <div class="c-quest__row">
      <div class="c-quest-header">
        <div class="c-quest-header__photo">
          <div class="c-quest-header__photo__bg">
            <picture class="h-object-fit" *ngIf="photo">
              <source *ngIf="photo.webp" [srcset]="photo.webp" type="image/webp">
              <img *ngIf="photo.jpg" [src]="photo.jpg" alt="">
            </picture>
          </div>
        </div>
        <label for="upload" class="c-quest-header__photo__bg__upload">
          <input (change)="onSelectFile($event)" aria-label="Add Quest photo" class="hidden" id="upload" type="file">
          <span><svg class="icon icon-camera"><use xlink:href="#icon-camera"></use></svg></span>
        </label>
        <div class="c-quest-header__caption">
          <app-quest-profile class="c-quest-profile" [doerInfo]="userInfo" ></app-quest-profile>
        </div>
      </div>
      <div class="c-quest-tabs">
        <nav class="c-quest-tabs__header">
          <a class="c-quest-tabs__item is-full" *ngFor="let tab of tabs.items" [ngClass]="{'is-active': tab.key === tabs.selected}" (click)="tabs.selected = tab.key">
            <svg class="icon icon-pencil" *ngIf="tab.key === 'caption'"><use xlink:href="#icon-pencil"></use></svg>
            <svg class="icon icon-settings" *ngIf="tab.key === 'settings'"><use xlink:href="#icon-settings"></use></svg>
            <span>{{ tab.value }}</span>
          </a>
        </nav>
      </div>
      <div class="c-quest-tabs__content">
        <div class="c-quest-tabs__tab" [ngStyle]="{'display': tabs.selected !== 'caption' ? 'none' : 'block'}">
          <div class="c-quest__article">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Quest Details:</h3>
            </div>
            <div class="c-quest__article__body">
              <div class="c-forms__group" *ngIf="categories.length > 0">
                <div class="c-forms__control is-required">
                  <select
                    #categorySelect
                    bs-select-directive
                    [events]="eventsSubject.asObservable()"
                    [options]="categories"
                    data-style="c-forms__input"
                    formControlName="pillar"
                    title="Select a category"
                    (change)="changePillar($event)"
                    (close)="markPillarAsTouched()"
                    name="category">
                    <!-- [attr.data-content]="category.content" -->
                    <option
                      *ngFor="let category of categories; index as i"
                      [attr.selected]="category.value === questForm.get('pillar')?.value ? true : null"
                      [value]="category.value">{{category.label}}</option>
                  </select>
                </div>
            </div>
              <div class="c-forms__group">
                <div class="c-forms__control is-required">
                  <input aria-label="Enter Quest title" class="c-forms__input" formControlName="questName" placeholder="Enter Quest title" type="text">
                </div>
              </div>
              <div class="c-forms__group">
                <div class="c-forms__control is-editor is-required">
                  <quill-editor
                    class="c-forms__input h-typography"
                    theme="bubble"
                    format="html"
                    [modules]="ngxConfig"
                    [maxLength]="30000"
                    [minLength]="3"
                    [required]="true"
                    placeholder="Enter description"
                    [formControl]="questForm.controls['questDescription']"
                    [readOnly]="isLoading">
                  </quill-editor>
                </div>
                <div class="ql-formats">
                  <span id="counter" style="display: block;text-align: right;font-size: 10pt;line-height: 20pt;"></span>
                </div>
                <div class="c-forms__message is-red is-under text-right" *ngIf="questForm.get('questDescription').invalid">
                  <span *ngIf="questForm.get('questDescription').errors['maxLengthError']">{{ validationMessages.maxlength('Description', questForm.get('questDescription').errors['maxLengthError'].maxLength) }}</span>
                </div>
              </div>
              <div class="c-forms__group">
                <div class="c-forms__control">
                  <input aria-label="Add YouTube video URL (optional)" class="c-forms__input" formControlName="questVideoUrl" placeholder="Add YouTube video URL (optional)" type="text">
                </div>
                <div class="c-forms__message is-red is-under text-right" [ngClass]="{'is-block': questForm.get('questVideoUrl').invalid}" *ngIf="questForm.get('questVideoUrl').invalid">
                  <span *ngIf="questForm.get('questVideoUrl').errors['youtubeUrlError']">Youtube url is invalid!</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Milestones -->
          <app-dl-milestones
            [mode]="questEditInfo ? milestoneModes.QUEST_MANAGE : milestoneModes.NEW_QUEST"
            [canBeEdit]="true"
            [allowCheck]="true"
            [questId]="questEditInfo ? questEditInfo.id : null"
            class="c-quest__article c-dl-milestones">
          </app-dl-milestones>
          <div class="c-forms__group mt-40">
            <button class="c-btn c-btn_md c-btn_dark c-btn_full" [ngClass]="{'is-loading': isLoading}" [disabled]="isLoading" type="submit">
              <span *ngIf="!questEditInfo">Start New Quest</span>
              <span *ngIf="questEditInfo">Save Changes</span>
            </button>
          </div>
        </div>
        <div class="c-quest-tabs__tab" [ngStyle]="{'display': tabs.selected !== 'settings' ? 'none' : 'block'}">

          <div class="c-quest__article">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2" *ngIf="userInfo?.userNonProfit">Allow users to donate to this Quest?</h3>
              <h3 class="c-quest__article__title h2" *ngIf="!userInfo?.userNonProfit">Allow users to back this Quest?</h3>
            </div>
            <div class="c-quest__article__body">
              <div class="c-forms__group is-flexed">
                <div class="e-toggle__wrap no-marg-bottom">
                  <label class="e-toggle">
                    <input aria-label="Is backing allowed?" class="e-toggle__input" formControlName="backBtnEnabled" type="checkbox" name="backBtnEnabled">
                    <span class="e-toggle__label"></span>
                  </label>
                  <span class="e-toggle__caption">{{questForm.get('backBtnEnabled').value ? 'Enabled' : 'Disabled'}}</span>
                </div>
                <ng-container *ngIf="!hasValidStripeAccount">
                  <i class="e-question" placement="top-center" [ngbTooltip]="payoutsValidationMassage" tooltipClass="c-dl-tooltip"></i>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="c-quest__article">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Allow users to fundraise for this Quest?</h3>
            </div>
            <div class="c-quest__article__body">
              <app-fundraise-switch
                [class.fundraise-switch__disabled]="hideFundraisingSwitch"
                [hasValidStripeAccount]="hasValidStripeAccount"
                [fundraisingInput]="fundraisingConfig"
                (fundraisingOutput)="onFundraisingChange($event)"></app-fundraise-switch>
            </div>
          </div>

          <div class="c-quest__article">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Selected quest mode:</h3>
            </div>
            <div class="c-quest__article__body">
              <div class="c-quest__mode-select">
                <span class="c-quest__mode-select__value" [ngSwitch]="questMode">
                  <ng-container *ngSwitchCase="'viewOnlyMode'">Support Only Mode</ng-container>
                  <ng-container *ngSwitchCase="'paceYourselfMode'">Pace Yourself Mode</ng-container>
                  <ng-container *ngSwitchCase="'diyMode'">Team Mode</ng-container>
                </span>
                <button *ngIf="!questEditInfo" class="e-btn e-btn_dark" (click)="openModal('quest-mode-dialog')" type="button">
                  <span>Change</span>
                </button>
              </div>


            </div>
          </div>

          <div class="c-quest__article">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Allow users to copy this Quest?</h3>
            </div>
            <div class="c-quest__article__body">
              <div class="e-toggle__wrap no-marg-bottom">
                <label class="e-toggle">
                  <input aria-label="Is copy allowed?" class="e-toggle__input" formControlName="copyAllowed" type="checkbox">
                  <span class="e-toggle__label"></span>
                </label>
                <span class="e-toggle__caption">{{questForm.get('copyAllowed').value ? 'Enabled' : 'Disabled'}}</span>
              </div>
            </div>
          </div>

          <div class="c-quest__article">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Allow doers to edit milestones?</h3>
            </div>
            <div class="c-quest__article__body">
              <div class="e-toggle__wrap no-marg-bottom">
                <label class="e-toggle">
                  <input type="checkbox"
                         [disabled]="questMode === 'viewOnlyMode'"
                         formControlName="editableMilestones"
                         aria-label="Are milestones editable?"
                         class="e-toggle__input">
                  <span class="e-toggle__label"></span>
                </label>
                <span class="e-toggle__caption">{{questForm.get('editableMilestones').value ? 'Enabled' : 'Disabled'}}</span>
              </div>
            </div>
          </div>

          <div class="c-quest__article" *ngIf="hasEvent">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Activate leaderboards?</h3>
            </div>
            <div class="c-quest__article__body">
              <div class="e-toggle__wrap">
                <label class="e-toggle">
                  <input type="checkbox"
                         formControlName="leaderboardEnabled"
                         aria-label="Activate leaderboards?"
                         class="e-toggle__input">
                  <span class="e-toggle__label"></span>
                </label>
                <span class="e-toggle__caption">{{questForm.get('leaderboardEnabled')?.value ? 'On' : 'Off'}}</span>
              </div>
              <button class="c-btn c-btn_sm c-btn_full c-btn_dark-revert"
                      *ngIf="questEditInfo?.leaderboardEnabled"
                      [disabled]="leaderboardUpdateProgress"
                      (click)="updateLeaderboardMembers()">Update members</button>
            </div>
          </div>

          <div class="c-quest__article">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Who Should See This?</h3>
            </div>
            <div class="c-quest__article__body">
              <label class="e-radio">
                <input (change)="onPrivacyChange()" aria-label="public Quest setting" formControlName="privacy" name="privacy" type="radio" value="public">
                <span class="e-radio__icon"></span>
                <span class="e-radio__caption">
                  <span class="e-radio__title">
                    <span>Public</span>
                    <svg class="icon icon-globe"><use xlink:href="#icon-globe"></use></svg>
                  </span>
                  <span class="e-radio__desc">Anyone and everyone can see this Quest</span>
                </span>
              </label>
              <label class="e-radio">
                <input (change)="onPrivacyChange()" aria-label="private Quest setting" formControlName="privacy" name="privacy" type="radio" value="private">
                <span class="e-radio__icon"></span>
                <span class="e-radio__caption">
                  <span class="e-radio__title">
                    <span>Private</span>
                    <svg class="icon icon-lock"><use xlink:href="#icon-lock"></use></svg>
                  </span>
                  <span class="e-radio__desc">Just me</span>
                </span>
              </label>
              <label class="e-radio">
                <input (change)="onPrivacyChange()" aria-label="friends only Quest setting" formControlName="privacy" name="privacy" type="radio" value="friends">
                <span class="e-radio__icon"></span>
                <span class="e-radio__caption">
                  <span class="e-radio__title">
                    <span>Friends Only</span>
                    <svg class="icon icon-friends"><use xlink:href="#icon-friends"></use></svg>
                  </span>
                <span class="e-radio__desc">Everyone on your friends list can see this Quest</span>
                </span>
              </label>
              <label class="e-radio">
                <input (change)="onPrivacyChange()" aria-label="invite only Quest setting" formControlName="privacy" name="privacy" type="radio" value="invite">
                <span class="e-radio__icon"></span>
                <span class="e-radio__caption">
                  <span class="e-radio__title">
                    <span>Invite</span>
                    <svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
                  </span>
                  <span class="e-radio__desc">Invite people to view this Quest</span>
                </span>
              </label>
              <tag-input *ngIf="questForm.controls['privacy'].value === 'invite'" [placeholder]="'Invite people...'" [secondaryPlaceholder]="'Invite people...'" [modelAsStrings]="true" formControlName="invites" [onlyFromAutocomplete]="true" [theme]="'dark'">
                <tag-input-dropdown [showDropdownIfEmpty]="true" [showDropdownIfEmpty]="true"
                  [autocompleteItems]="otherUsers">
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>
          <div class="c-quest__article" *ngIf="otherUsers.length > 0">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Administrators: {{questForm.get('admins').value.length}}</h3>
            </div>
            <div class="c-quest__article__body">
              <tag-input [placeholder]="'+ admin'" [secondaryPlaceholder]="'Add Quest admins...'" [modelAsStrings]="true" [onlyFromAutocomplete]="true" [identifyBy]="'email'" [displayBy]="'email'" formControlName="admins" [theme]="'dark'">
                <tag-input-dropdown [showDropdownIfEmpty]="true" [showDropdownIfEmpty]="true"
                  [autocompleteItems]="otherUsers">
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>
          <div class="c-quest__article" *ngIf="questId && (isAdmin || isCreator) && questEditInfo.surveysEnabled">
            <div class="c-quest__article__header">
              <h3 class="c-quest__article__title h2">Surveys</h3>
            </div>
            <div class="c-quest__article__body">
              <button class="c-btn c-btn_md c-btn_dark"
                type="button"
                (click)="pushSurvey(questId)"
                [class.c-btn_dark]="!singleSurveyPushedToApp"
                [class.c-btn_yellow]="singleSurveyPushedToApp">
                <span>{{singleSurveyPushedToApp ? 'Pushed survey to app' : 'Push survey to app'}}</span>
              </button>
            </div>
          </div>
          <div class="c-form__group">
            <button class="c-btn c-btn_md c-btn_full c-btn_dark" (click)="navToCaption()" type="button">
              <svg class="icon icon-arrow_left"><use xlink:href="#icon-arrow_left"></use></svg>
              <span>Back to quest editing</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


<app-modal *ngIf="openedDialog === 'quest-mode-dialog'" [ngStyle]="{'display': 'block'}" (closeModal)="closeDialog()" id="quest-mode-dialog">
  <h3 class="modal-title">Select quest mode</h3>
  <div class="modal-body">
    <div class="c-quest-modes">
      <div class="c-quest-modes__item" [ngClass]="{'is-active': questMode === 'viewOnlyMode'}">
        <div class="c-quest-modes__item__title" (click)="selectQuestMode('viewOnlyMode')">
          <span>Support Only Mode</span>
        </div>
        <div *ngIf="questMode === 'viewOnlyMode'" class="c-quest-modes__item__body h-typography no-pad-top">
          <strong>In Support Only Mode, people can:</strong>
          <ul>
            <li>Follow and see your activity</li>
            <li>Comment on your Quest</li>
            <li>Financially Back your Quest</li>
          </ul>
        </div>
      </div>
      <div class="c-quest-modes__item" [ngClass]="{'is-active': questMode === 'paceYourselfMode'}">
        <div class="c-quest-modes__item__title" (click)="selectQuestMode('paceYourselfMode')">
          <span>Pace Yourself Mode</span>
        </div>
        <div *ngIf="questMode === 'paceYourselfMode'" class="c-quest-modes__item__body h-typography no-pad-top">
          <strong>In Pace Yourself Mode, people can:</strong>
          <ul>
            <li>Follow and see your activity</li>
            <li>Comment on your Quest</li>
            <li>Financially Back your Quest</li>
            <li>Do your Quest with you</li>
            <li>Complete Milestones at their own pace</li>
          </ul>
          <p>Great if you`re leading participants who operate at different speeds through a journey.</p>
        </div>
      </div>
      <div class="c-quest-modes__item" [ngClass]="{'is-active': questMode === 'diyMode'}">
        <div class="c-quest-modes__item__title" (click)="selectQuestMode('diyMode')">
          <span>Team Mode</span>
        </div>
        <div *ngIf="questMode === 'diyMode'" class="c-quest-modes__item__body h-typography no-pad-top">
          <strong>In Team Mode, people can:</strong>
          <ul>
              <li>Follow and see your activity</li>
              <li>Comment on your Quest</li>
              <li>Financially Back your Quest</li>
              <li>Do your Quest with you</li>
              <li>Complete one set of Milestones together</li>
          </ul>
          <p>Great if you`re leading participants who want flexbillity to edit and personalize their journey.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="c-btn c-btn_md c-btn_dark c-btn_full" (click)="modalService.close('quest-mode-dialog'); closeDialog()" type="button">
      <span>Submit</span>
    </button>
  </div>
</app-modal>
