<div class="c-leaderboard-podium__header">
  <button type="button"
          style="display: none"
          class="c-leaderboard-podium__filters-button"
          [title]="'Filters'"
          (click)="toggleFilters()"></button>
</div>
<div class="c-leaderboard-podium__placement">
  
  <app-leaderboard-podium-place class="c-leaderboard-podium__place"
                                (updateMember)="onUpdateMember($event)"
                                [allowEdit]="allowEdit"
                                [place]="3"
                                [questId]="questId"
                                [selectedAttributeId]="selectedAttributeId"
                                [member]="members?.length > 2 ? members[2] : emptyMember"
                                [mode]="podiumMode"
                                [teams]="teams">
  </app-leaderboard-podium-place>
  <app-leaderboard-podium-place class="c-leaderboard-podium__place"
                                (updateMember)="onUpdateMember($event)"
                                [allowEdit]="allowEdit"
                                [place]="1"
                                [questId]="questId"
                                [selectedAttributeId]="selectedAttributeId"
                                [member]="members?.length > 0 ? members[0] : emptyMember"
                                [mode]="podiumMode"
                                [teams]="teams">
  </app-leaderboard-podium-place>
  <app-leaderboard-podium-place class="c-leaderboard-podium__place"
                                (updateMember)="onUpdateMember($event)"
                                [allowEdit]="allowEdit"
                                [place]="2"
                                [questId]="questId"
                                [selectedAttributeId]="selectedAttributeId"
                                [member]="members?.length > 1 ? members[1] : emptyMember"
                                [mode]="podiumMode"
                                [teams]="teams">
  </app-leaderboard-podium-place>
</div>
