import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {QuestTask, QuestTeam} from 'diemlife-commons/dist/diemlife-commons-model';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Store} from '@ngrx/store';
import {
  ClearMilestoneForPreview,
  MoveMilestone,
  SetMilestones,
  UpdateMilestoneGroupName
} from './store/dl-milestones.actions';
import {
  DlMilestoneManageModes,
  NewTaskGroup,
  TaskGroup,
  UpdateMilestonesGroupNamePayload
} from './dl-milestones.typings';
import {QuestService} from 'src/app/_services/quest.service';
import {Observable, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import {DlMilestonesService} from './dl-milestones.service';
import {MILESTONE_TITLE_HINTS} from 'src/app/app.config';
import * as fromDlMilestones from './store/dl-milestones.reducer';
import {AddCommentToList, AnimateItems, AnimateToggleList} from 'src/app/animations/comments.animations';
import {AppState} from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-dl-milestones',
  templateUrl: './dl-milestones.component.html',
  styleUrls: ['./dl-milestones.component.styl'],
  animations: [AnimateToggleList, AnimateItems, AddCommentToList],
})
export class DlMilestonesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('milestonesTitle', {static: false}) milestonesTitle: ElementRef;
  @Input() canBeEdit: boolean;
  @Input() allowCheck: boolean;
  @Input() showControls: boolean;
  @Input() questId: number;
  @Input() userId: number;
  @Input() questTitle: string;
  @Input() locationName: string;
  @Input() selectedValue: string;
  @Input() mode: DlMilestoneManageModes;
  @Input() directionsType?: string;
  @Input() taskViewDisabled: boolean;
  @Input() participantUserIds: any[] = [];
  @Input() teams: QuestTeam[] = [];

  milestoneModes = DlMilestoneManageModes;
  dlMilestonesState: Observable<fromDlMilestones.State>;
  dlMilestonesSubscription: Subscription;
  isEditMode = false;
  isLoading: string;
  titleHints = MILESTONE_TITLE_HINTS;
  showHints = false;
  showMap = false;

  constructor(
    private store: Store<AppState>,
    private questService: QuestService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private dlMilestonesService: DlMilestonesService,
    private cdr: ChangeDetectorRef
  ) {
    this.dlMilestonesState = this.store.select('dlMilestones');
  }

  ngOnInit() {
    this.dlMilestonesSubscription = this.dlMilestonesState.pipe(
      filter(state => !!state.milestones && !!state.milestones.length),
      map(state => state.milestones[0].questTasks),
    ).subscribe((milestones: QuestTask[]) => {
      const geoLocatedMilestone = milestones.find(milestone => !!milestone.geoPoint);
      this.showMap = !!geoLocatedMilestone;
    });
  }

  ngOnChanges() {
    if (this.mode === DlMilestoneManageModes.NEW_QUEST || this.mode === DlMilestoneManageModes.QUEST_MANAGE) {
      this.isEditMode = true;
    }
  }

  ngOnDestroy(): void {
    if (this.dlMilestonesSubscription) {
      this.dlMilestonesSubscription.unsubscribe();
    }
  }

  drop(event: CdkDragDrop<QuestTask[]>) {
    this.isLoading = 'REORDER';

    this.store.dispatch(new MoveMilestone({previousIndex: event.previousIndex, currentIndex: event.currentIndex}));

    this.dlMilestonesState.pipe(take(1)).subscribe((state: fromDlMilestones.State) => {
      const newArray = state.milestones[0].questTasks.map((milestone: QuestTask) => {
        return milestone.id;
      });
      this.questService.reorderQuestMilestones(newArray).subscribe(() => {
        this.isLoading = null;
      }, () => {
        this.isLoading = null;
        this.store.dispatch(new MoveMilestone({previousIndex: event.currentIndex, currentIndex: event.previousIndex}));
        this.snotifyService.error(
          'There was a problem reordering task information. Please try again!',
          null,
          this.snotifyConfigService.getConfig()
        );
      });
    });
  }

  changeGroupTitle(value: string) {
    this.isLoading = 'UPDATE_GROUP_NAME';
    this.detectChanges();
    if (this.mode !== DlMilestoneManageModes.NEW_QUEST) {
      this.dlMilestonesState.pipe(take(1)).subscribe((state: fromDlMilestones.State) => {
        if (state.milestones[0].id) {
          const payload: UpdateMilestonesGroupNamePayload = {
            groupId: state.milestones[0].id,
            groupName: value
          };
          this.dlMilestonesService.updateMilestonesGroupName(payload).subscribe(() => {
            this.store.dispatch(new UpdateMilestoneGroupName({value: value}));
            this.isLoading = null;
          }, () => {
            this.isLoading = null;
            this.snotifyService.error(
              'Oops, something went wrong, please try again',
              null,
              this.snotifyConfigService.getConfig()
            );
          });
        } else {
          this.dlMilestonesService.createDefaultGroupWithTasks(this.questId, value).subscribe((newTaskGroup: NewTaskGroup) => {
            const payload: TaskGroup = {
              id: newTaskGroup.id,
              name: newTaskGroup.groupName,
              questTasks: newTaskGroup.questTasks.map(task => new QuestTask(task))
            };
            this.store.dispatch(new SetMilestones([payload]));
            this.isLoading = null;
          }, () => {
            this.isLoading = null;
            this.snotifyService.error(
              'Oops, something went wrong, please try again',
              null,
              this.snotifyConfigService.getConfig()
            );
          });
        }
      });
    } else {
      this.store.dispatch(new UpdateMilestoneGroupName({value: value}));
    }
  }

  setGroupTitle(index: number) {
    this.milestonesTitle.nativeElement.textContent = this.titleHints[index];
    this.changeGroupTitle(this.titleHints[index]);
  }

  onCloseMilestoneDetailsDialog() {
    this.store.dispatch(new ClearMilestoneForPreview());
  }

  onFocusIn() {
    this.showHints = true;
  }

  onFocusOut() {
    setTimeout(() => {
      this.showHints = false;
    });
  }

  goToLinkedQuest(milestoneId: number) {
    // console.log('DlMilestonesComponent :: Captured milestone selection on map', milestoneId);
  }

  private detectChanges() {
    // tslint:disable-next-line:no-string-literal
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

}
