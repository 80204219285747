<div class="c-n-modal__body" [ngSwitch]="step">

  <h5 class="c-n-modal__title t-typo__h3">Sign Up</h5>
  <p class="c-n-modal__sub-title-switch t-typo__h6">Have an account? <a class="t-typo__link-inverted" href="javascript:void(null)" (click)="changeAuthTab('logIn')">Log in</a></p>

  <form *ngSwitchCase="'signUp'" [formGroup]="signUpForm" (ngSubmit)="signUp()" [ngClass]="{'form-is-submitted': submitted}">
    <div class="c-n-form__row">
      <div class="c-n-form__col">
        <div class="c-n-form__group">
          <div class="c-n-form__legend">First name:</div>
          <div class="c-n-form__control is-required">
            <input class="c-n-form__input"
                   formControlName="firstName"
                   placeholder="JANE"
                   type="text"
                   [attr.disabled]="activityStatus"
                   aria-label="First name"
                   autocomplete="given-name"
                   appInputFocusTrigger>
            <span class="c-n-form__border"></span>
                   <!-- name="name" -->
          </div>
        </div>
        <div *ngIf="signUpForm.get('firstName').invalid && (signUpForm.get('firstName').touched || submitted)"
          class="c-n-form__message is-red"
          role="alert">
          <div *ngIf="signUpForm.get('firstName').errors.required">{{ messages.required }}</div>
        </div>
      </div>
      <div class="c-n-form__col">
        <div class="c-n-form__group">
          <div class="c-n-form__legend">Last Name:</div>
          <div class="c-n-form__control is-required">
            <input [attr.disabled]="activityStatus" aria-label="Last Name" class="c-n-form__input" formControlName="lastName" placeholder="DOE" type="text">
            <span class="c-n-form__border"></span>
          </div>
        </div>
        <div *ngIf="signUpForm.get('lastName').invalid && (signUpForm.get('lastName').touched || submitted)"
          class="c-n-form__message is-red"
          role="alert">
          <div *ngIf="signUpForm.get('lastName').errors.required">{{ messages.required }}</div>
        </div>
      </div>
    </div>
    <div class="c-n-form__row">
      <div class="c-n-form__col">
        <div class="c-n-form__group">
          <div class="c-n-form__legend">Country:</div>
          <div class="c-n-form__control is-required" [attr.disabled]="activityStatus">
            <select
              formControlName="country"
              bs-select-directive
              [events]="eventsSubject.asObservable()"
              data-live-search="true"
              data-style="c-n-form__input"
              title="United States"
              (close)="markAsTouched()"
              autocomplete="on">
              <!-- name="country" -->
              <option
                *ngFor="let country of countries | keys"
                attr.data-content="{{buildOption(country.key, country.value)}}"
                [value]="country.key">{{country.value}}</option>
            </select>
            <span class="c-n-form__border"></span>
          </div>
        </div>
        <div *ngIf="(signUpForm.get('country').touched || submitted) && signUpForm.get('country').invalid"
          class="c-n-form__message is-red"
          role="alert">
            <div *ngIf="signUpForm.get('country').errors.required">{{ messages.required }}</div>
        </div>
      </div>
      <div class="c-n-form__col">
        <div class="c-n-form__group">
          <div class="c-n-form__legend">Zip code:</div>
          <div class="c-n-form__control is-required">
            <input [attr.disabled]="activityStatus" aria-label="Zip/Postal Code" class="c-n-form__input" formControlName="zip"  placeholder="Zip/Postal code" type="text">
            <span class="c-n-form__border"></span>
            <!-- name="postal-code" -->
          </div>
        </div>
        <div *ngIf="signUpForm.get('zip').invalid && (signUpForm.get('zip').touched || submitted)"
          class="c-n-form__message is-red"
          role="alert">
          <div *ngIf="signUpForm.get('zip').errors.required">{{ messages.required }}</div>
          <div *ngIf="signUpForm.get('zip').errors.pattern">{{ messages.invalidZipcode }}</div>
        </div>
      </div>
    </div>
    <div class="c-n-form__group">
      <div class="c-n-form__legend">Email:</div>
      <div class="c-n-form__control is-required">
        <input [attr.disabled]="activityStatus" aria-label="Email" class="c-n-form__input" formControlName="email" placeholder="JaneDoe@DIEMlife.com"  type="email">
        <span class="c-n-form__border"></span>
        <!-- name="email" -->
      </div>
    </div>
    <div *ngIf="signUpForm.get('email').invalid && (signUpForm.get('email').touched || submitted)"
      class="c-n-form__message is-red"
      role="alert">
      <div *ngIf="signUpForm.get('email').errors.required">{{ messages.required }}</div>
      <div *ngIf="signUpForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
      <div *ngIf="signUpForm.get('email').errors.emailTaken">This email is already taken</div>
    </div>
    <div class="c-n-form__row" [formGroup]="passwordGroup">

      <div class="c-n-form__col">
        <div class="c-n-form__group">
          <div class="c-n-form__legend">Password:</div>
          <div class="c-n-form__control is-required">
            <input [attr.disabled]="activityStatus" aria-label="Password" class="c-n-form__input" formControlName="password1"  placeholder="19203" type="password"  autocomplete="new-password">
            <span class="c-n-form__border"></span>
          </div>
        </div>
        <div *ngIf="passwordGroup.get('password1').invalid && (passwordGroup.get('password1').touched || submitted)"
          class="c-n-form__message is-red"
          role="alert">
          <div *ngIf="passwordGroup.get('password1').errors.required">{{ messages.required }}</div>
          <div *ngIf="passwordGroup.get('password1').errors.minlength">{{ messages.password.minlength(passwordGroup.get('password1').errors.minlength.requiredLength) }}</div>
          <div *ngIf="passwordGroup.get('password1').errors.maxlength">{{ messages.password.minlength(passwordGroup.get('password1').errors.maxlength.requiredLength) }}</div>
        </div>
      </div>
      <div class="c-n-form__col">
        <div class="c-n-form__group">
          <div class="c-n-form__legend">Confirm password:</div>
          <div class="c-n-form__control is-required">
            <input [attr.disabled]="activityStatus" aria-label="Password Confirm" class="c-n-form__input" formControlName="password2" placeholder="19203" type="password"  autocomplete="new-password">
            <span class="c-n-form__border"></span>
          </div>
        </div>
        <div *ngIf="passwordGroup.get('password2').invalid && (passwordGroup.get('password2').touched || submitted)"
          class="c-n-form__message is-red"
          role="alert">
          <div *ngIf="passwordGroup.get('password2').errors.required">{{ messages.required }}</div>
        </div>
        <div *ngIf="passwordGroup.errors" class="c-n-form__message is-red" role="alert">Password does not match</div>
      </div>
    </div>
    <div class="c-n-form__group">
      <ngx-recaptcha2
        formControlName="recaptcha"
        theme="light"
        type="normal"
        [siteKey]="reCaptchaToken"
        (success)="handleSuccess($event)">
      </ngx-recaptcha2>
    </div>
    <div *ngIf="signUpForm.get('recaptcha').invalid && submitted"
      class="c-n-form__message is-red is-bottom"
      role="alert">
      <div *ngIf="signUpForm.get('recaptcha').errors.required">{{ messages.required }}</div>
    </div>
    <div class="c-n-form__group">
      <label role="checkbox" class="e-checkbox" [attr.disabled]="activityStatus">
          <input aria-label="Receive Email?" formControlName="receiveEmail" type="checkbox"/>
          <span class="e-checkbox__icon">
              <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
          </span>
          <span class="e-checkbox__caption">I love what you are doing. Keep me inspired!</span>
      </label>
    </div>
    <div class="c-n-modal__footer">
      <button [attr.disabled]="activityStatus" [disabled]="isLoading" [ngClass]="{'is-loading': isLoading}" aria-label="Submit" class="e-btn e-btn_dark" type="submit">
        <span>Submit</span>
      </button>
    </div>
  </form>
  <div *ngSwitchCase="'pinControls'">
    <p class="c-sign-up__subttl">We have sent a verification code to your email</p>
    <div class="c-n-form__group">
      <app-dl-pincode></app-dl-pincode>
    </div>
    <div class="c-n-modal__footer">
      <a class="c-sign-up__subLink t-typo__link" (click)="requestNewPin()" aria-label="Request new PIN" href="javascript:void(null)">Request new PIN</a>
      <button (click)="confirmPin()" [disabled]="!pincodeValidation.validation || isLoading || activityStatus" [ngClass]="{'is-loading': isLoading}" aria-label="Submit" class="e-btn e-btn_dark" type="button">
        <span>Sign Up</span>
      </button>
    </div>
  </div>

</div>
