import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ExploreCard} from './explore-card.type';
import {QuestService} from '../../../../_services/quest.service';
import {QuestPreparedPhoto} from '../../../main/quest/quest.type';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';
import {QuestDropdownContext} from '../../../main/quest/quest-dropdown/quest-dropdown.component';
import { AppState } from 'src/app/_store/app.reducers';


@Component({
  selector: 'app-explore-card',
  templateUrl: './explore-card.component.html',
  styleUrls: ['./explore-card.component.styl']
})
export class ExploreCardComponent implements OnInit {
  @Input() quest: ExploreCard;
  @Input() doerInfo: fromProfile.State;
  @Input() index: number;
  profileState: Observable<fromProfile.State>;
  photo: QuestPreparedPhoto;
  dropdownContext: QuestDropdownContext;
  viewerId?: number;
  text = '';
  stringStyle = false;
  constructor(
    private questService: QuestService,
    private store: Store<AppState>,
    private router: Router,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
  ) {
    this.profileState = this.store.select('userInfo');
  }

  ngOnInit() {
    this.photo = this.questService.prepareCoverPhoto(this.quest.photo, this.quest.category);
    if (this.quest.activityMode === 'viewOnlyMode' && this.quest.isUserDoing) {
      this.profileState.pipe(take(1)).subscribe((state: fromProfile.State) => {
        this.viewerId = state.id;
      });
    }
    this.dropdownContext = {
      id: this.quest.id,
      title: this.quest.title,
      shareUrl: this.quest.seoSlugs == null ? null : this.quest.seoSlugs.seoFriendlyUrl,
      copyable: this.quest.copyAllowed,
      followed: this.quest.followed,
      saved: this.quest.saved,
      active: !!this.quest.status,
      editable: false,
      starred: this.quest.starred,
      mode: this.quest.mode
    };
    this.getDescription();
  }
  private getDescription() {
    if (this.quest.questFeed ? this.quest.questFeed.length > 0 : false) {
      this.text = this.quest.questFeed;
    } else {
      this.stringStyle = true;
      this.text = this.quest.shortDescription;
    }
  }
  navigateToTicketFlow() {
    this.router.navigate(['/get-tickets', this.quest.id, this.quest.createdBy]);
  }
  getLinkToQuestDetail() {
    let doerId: any;

    if (this.quest.activityMode === 'viewOnlyMode' && this.viewerId && !this.doerInfo) {
      doerId = this.viewerId;
    } else if ((this.quest.activityMode === 'viewOnlyMode' || this.quest.activityMode === 'diyMode') && this.doerInfo) {
      doerId = this.quest.user.id;
    } else if (this.doerInfo) {
      doerId = this.doerInfo.id;
    } else {
      doerId = this.quest.user.id;
    }
    return ['/', this.quest.seoSlugs.userNameSlug || '', this.quest.seoSlugs.questTitleSlug || '', this.quest.id, doerId];
  }
  confirmDoQuest(questMode: string) {
    const context = questMode === 'diyMode' ? 'join' : 'start';
    this.snotifyService.confirm(
      `Ready to ${context} ${this.quest.title}?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.doQuest();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'Cancel',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  confirmSupportQuest() {
    this.snotifyService.confirm(
      `Start supporting ${this.quest.title}?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.supportQuest();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'Cancel',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  doQuest() {
    this.questService.doQuest(this.quest.id, this.quest.createdBy, null, this.quest.geoTriggerEnabled).subscribe(() => {
      this.profileState.pipe(take(1)).subscribe((state: fromProfile.State) => {
        let notificationMessage;
        if (this.quest.mode === 'diyMode') {
          notificationMessage = `You just joined ${this.quest.title}!`;
        } else {
          notificationMessage = `You just started ${this.quest.title}!`;
        }
        this.snotifyService.success(notificationMessage, null, this.snotifyConfigService.getConfig());
        this.router.navigate(['/quest-detail', this.quest.id, state.id]);
      });
    }, err => {
      this.snotifyService.error('There was an error starting Quest. Please try again', null, this.snotifyConfigService.getConfig());
    });
  }

  supportQuest() {
    this.questService.doQuest(this.quest.id, null, 'viewOnlyMode', this.quest.geoTriggerEnabled).subscribe(() => {
      this.profileState.pipe(take(1)).subscribe((state: fromProfile.State) => {
        this.snotifyService.success(`You started supporting ${this.quest.title}`, null, this.snotifyConfigService.getConfig());
        this.router.navigate(['/quest-detail', this.quest.id, state.id]);
      });
    }, err => {
      this.snotifyService.error('There was an error starting Quest. Please try again', null, this.snotifyConfigService.getConfig());
    });
  }

}
