<div *ngIf="!placeholderMode; then targetBlock else placeholder"></div>

<!-- target block shows after loading data -->
<ng-template #targetBlock>
  <div [@openClose]="currentState">
    <a class="c-card__image" [routerLink]="getLinkToQuestDetail()">
      <picture class="h-object-fit">
        <source *ngIf="photo.webp" [srcset]="photo.webp" type="image/webp">
        <img [alt]="quest.title" [src]="photo.jpg">
      </picture>
    </a>
    <div class="c-card__inner">
      <div class="c-card__header" [ngStyle]="{'background-color': getColor()}">
        <strong class="c-card__category" [ngSwitch]="quest.category">
          <svg *ngSwitchCase="'PHYSICAL'" class="icon icon-physical"><use xlink:href="#icon-physical"></use></svg>
          <svg *ngSwitchCase="'ENVIRONMENTAL'" class="icon icon-environmental"><use xlink:href="#icon-environmental"></use></svg>
          <svg *ngSwitchCase="'OCCUPATIONAL'" class="icon icon-occupational"><use xlink:href="#icon-occupational"></use></svg>
          <svg *ngSwitchCase="'MENTAL'" class="icon icon-mental"><use xlink:href="#icon-mental"></use></svg>
          <svg *ngSwitchCase="'SOCIAL'" class="icon icon-friends"><use xlink:href="#icon-friends"></use></svg>
          <svg *ngSwitchCase="'FINANCIAL'" class="icon icon-piggybank"><use xlink:href="#icon-piggybank"></use></svg>
          <span>{{ quest.category }}</span>
        </strong>
        <div class="c-card__header__tools">
          <button class="c-card__header__tool h-btn-reset" (appAuthGuard)="openModal('share-modal')" ><svg class="icon icon-nl-share"><use xlink:href="#icon-nl-share"></use></svg></button>
          <button class="c-card__header__tool h-btn-reset" (appAuthGuard)="saveQuest()"><svg class="icon icon-save"><use xlink:href="#icon-save"></use></svg></button>
        </div>
      </div>
      <div class="c-card__body">
        <ul class="c-card__statistic h-list-reset">
          <li *ngIf="quest.dateCreated">
            <svg class="icon icon-eye"><use xlink:href="#icon-eye"></use></svg>
            <span>{{ getAbbrNum(quest.views, 2) }}<br>views</span>
          </li>
          <li>
            <svg class="icon icon-nl-clock"><use xlink:href="#icon-nl-clock"></use></svg>
            <span [innerHTML]="getTimeAgo()"></span>
          </li>
        </ul>
        <div class="c-card__caption">
          <h3 class="c-card__title h-heading-reset"><a [routerLink]="getLinkToQuestDetail()" class="js-three-dots-container">{{ quest.title }}</a></h3>
          <div class="c-card__description">
            <div class="c-card__description__inner h-typography">
              <p class="c-card__description__paragraph js-three-dots-container" style="white-space: pre-line;">{{ text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- placeholder block shows before load -->
<ng-template #placeholder>
  <div class="c-card c-p-card">
    <div class="c-card__image c-p-card__image">
      <svg class="icon icon-photo-of-a-landscape"><use xlink:href="#icon-photo-of-a-landscape"></use></svg>
    </div>
    <div class="c-card__inner">
      <div class="c-card__header c-p-card__header">
        <strong class="c-card__category c-p-card__category"></strong>
        <!-- <div class="c-card__header__tools">
          <div class="c-card__header__tool c-p-card__header__tool"></div>
          <div class="c-card__header__tool c-p-card__header__tool"></div>
        </div> -->
      </div>
      <div class="c-card__body">
        <ul class="c-card__statistic c-p-card__statistic h-list-reset">
          <li></li>
          <li></li>
        </ul>
        <div class="c-card__caption">
          <h3 class="c-card__title h-heading-reset c-p-card__title"></h3>
          <div class="c-card__description c-p-card__description">
            <span></span><span></span><span></span><span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-modal id="share-modal" *ngIf="openedModal === 'share-modal'" [ngStyle]="{'display': 'block'}" (closeModal)="closeModal()">
  <h5 class="c-n-modal__title t-typo__h3 has-no-text-tranform">Share this Quest with Friends!</h5>
  <app-share-widjet [questUserId]="quest.user.id" [questId]="quest.id" [shareUrl]="quest.seoSlugs.seoFriendlyUrl" class="c-share-widjet c-n-modal__body"></app-share-widjet>
</app-modal>