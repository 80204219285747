import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
// import {QuestDoer} from '../quest/quest.type';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ModalService} from '../modal/modal.service';
import {NgScrollbar} from 'ngx-scrollbar';
import * as createFocusTrap from 'focus-trap/dist/focus-trap';
import { QuestDoer } from 'diemlife-commons/dist/diemlife-commons-model';

declare var $: any;

interface Filter {
  isActive: boolean;
  items: FilterItem[];
}
interface FilterItem {
  title: string;
  prop: string;
  value: boolean;
}

@Component({
  selector: 'app-members-dialog',
  templateUrl: './members-dialog.component.html',
  styleUrls: ['./members-dialog.component.styl']
})
export class MembersDialogComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(NgScrollbar, {static: false}) scrollRef: NgScrollbar;
  @ViewChild('searchList', {static: false}) searchList: ElementRef;
  @Input() doers: QuestDoer[] = [];
  @Input() mode: string;
  @Input() showBacking = false;
  filteredDoers: QuestDoer[] = [];
  // result: QuestDoer[] = [];
  searchForm: FormGroup;
  searchFormSubScr: Subscription;
  limit = 20;
  // loadmoreEnabled = true;
  // newContentLoaded = false;
  filter: Filter = {
    isActive: null,
    items: []
  };
  shouldShowSearchHeader = true;
  selectTitle = '';
  private focusTrap: any;
  focusTrapOptions = {
    escapeDeactivates: false,
    clickOutsideDeactivates: true
  };

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService
  ) {
    this.filter.items = [
      {
        title: 'Donors',
        prop: 'Backer',
        value: false
      },
      {
        title: 'Active',
        prop: 'Doer',
        value: false
      },
      {
        title: 'Achieved',
        prop: 'Achiever',
        value: false
      },
      {
        title: 'Interested',
        prop: 'Interested',
        value: false
      }
    ];
    this.searchForm = this.fb.group({
      'search': [''],
      'statuses': this.buildStatuses()
    });
  }

  get statuses() {
    return <FormArray>this.searchForm.get('statuses');
  }


  ngOnInit() {
    this.searchFormSubScr = this.searchForm.valueChanges.subscribe((value) => {
      this.filterResult();
    });
    this.shouldShowSearchHeader = this.mode === 'doers';
    setTimeout(() => {
      this.searchList.nativeElement.focus();
      this.scrollRef.update();
    });
  }
  ngOnChanges() {
    this.filterResult();
  }
  ngOnDestroy() {
    if (this.searchFormSubScr) {
      this.searchFormSubScr.unsubscribe();
    }
    if (this.focusTrap) {
      this.focusTrap.deactivate('#membersModal');
    }
  }
  buildStatuses() {
    const arr = this.filter.items.map(status => {
      return this.fb.control(status.value);
    });
    return this.fb.array(arr);
  }
  checkIfFormIsDirty() {
    if (this.searchForm.value.search.length > 0) {
      return true;
    }
    if (this.checkIfStasusSelected()) {
      return true;
    }
    return false;
  }
  checkIfStasusSelected() {
    let statuses = false;
    let selectTitle = '';
    this.searchForm.value.statuses.forEach((item, i) => {
      if (item) {
        selectTitle += selectTitle.length > 0 ? (', ' + this.filter.items[i].title) : this.filter.items[i].title;
        statuses = true;
      }
    });
    if (statuses) {
      this.selectTitle = selectTitle;
      return true;
    } else {
      this.selectTitle = '';
    }
    return statuses;
  }
  filterResult() {
    this.filter.items.forEach((i) => {
      i.value = false;
    });
    if (this.checkIfFormIsDirty()) {
      const rules = this.searchForm.value;
      let res;
      if (this.checkIfStasusSelected()) {
        res = this.doers.filter((item) => {
          let approve = false;
          rules.statuses.forEach((elem, indx) => {
            if (elem && item.memberStatusObj[this.filter.items[indx].prop]) {
              approve = this.filter.items[indx].value = true;
            }
          });
          return approve;
        });
      } else {
        res = this.doers;
      }
      if (rules.search.length > 0) {
        res = res.filter(item => item.userFullName.search(new RegExp(rules.search, 'i')) > -1);
      }
      // let filteredTimes = 0;
      // let filteredByBacker = false;
      // this.filter.items.forEach(i => {
      //   if (i.value) {
      //     filteredTimes++;
      //     if (i.prop === 'Backer') {
      //       filteredByBacker = true;
      //     }
      //   }
      // });
      // if (filteredTimes === 1 && filteredByBacker) {
      //   res.sort((a, b) => {
      //     return b.amountBackedSum - a.amountBackedSum;
      //   });
      // }
      this.filteredDoers = res;
      this.resetScrollEvents();
    } else {
      this.filteredDoers = this.doers;
      this.resetScrollEvents();
    }
  }

  loadMore() {
    this.limit += 10;
  }
  closeDialog() {
    this.modalService.close('membersModal');
  }
  resetScrollEvents() {
    if (this.scrollRef) {
      setTimeout(() => {
        this.limit = 10;
        this.scrollRef.scrollTo({top: 0});
      });
    }
  }
}
