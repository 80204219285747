import {Component, OnInit, Input} from '@angular/core';
import {ShareWidjetService} from '../share-widjet.service';
import {SnotifyService} from 'ng-snotify';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';
import {QuestDetailState} from 'diemlife-commons/dist/diemlife-commons-model';
import * as fromApp from '../../../../_store/app.reducers';
import {FormArray, FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import * as AppConfig from '../../../../app.config';
import * as Constants from '../../../../app.constants';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '../../modal/modal.service';

@Component({
  selector: 'app-share-email',
  templateUrl: './share-email.component.html',
  styleUrls: ['./share-email.component.styl']
})
export class ShareEmailComponent implements OnInit {
  @Input() questId: number;
  questState: Observable<QuestDetailState>;
  shareForm: FormGroup;
  messages = Constants.VALIDATION_MESSAGES;
  formSubmitted = false;
  emailsLimit = 10;
  shareIsloading = false;
  constructor(
    private shareWidjetService: ShareWidjetService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private store: Store<fromApp.AppState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {
    this.questState = this.store.select('quest');
  }

  ngOnInit() {
    this.shareForm = this.fb.group({
      emails: this.fb.array([ this.fb.control('', [Validators.required, Validators.email]) ]),
      message: this.fb.control('', [
        Validators.required,
        Validators.minLength(AppConfig.shareMessage.minLength),
        Validators.maxLength(AppConfig.shareMessage.maxLength)
      ])
    });
  }

  get emails(): FormArray {
    return this.shareForm.get('emails') as FormArray;
  }
  get messageControl(): AbstractControl {
    return this.shareForm.get('message');
  }

  addEmail() {
    if (this.emails.length < this.emailsLimit) {
      this.emails.push(this.fb.control('', [Validators.email]));
    }
  }
  hasEmptyFields() {
    let isEmpty = false;
    for (const val of this.emails.value) {
      if (val.length === 0) {
        isEmpty = true;
        break;
      }
    }
    return isEmpty;
  }
  autoControlFields() {
    if (!this.hasEmptyFields() && this.emails.valid) {
      this.addEmail();
    }
  }
  removeEmail(index: number) {
    this.emails.removeAt(index);
  }


  onSubmit() {
    if (this.shareIsloading) {
      return false;
    }
    this.formSubmitted = true;
    this.shareIsloading = true;
    if (this.shareForm.value.message.length === 0) {
      this.snotifyService.error('Please add message!', null, this.snotifyConfigService.getConfig());
      this.shareIsloading = false;
      return false;
    }
    const validEmails: string[] = this.emails.value.filter((val, index) => {
      return val.length > 4 && this.emails.controls[index].valid;
    });
    this.shareWidjetService.shareViaEmail(this.questId, validEmails, this.shareForm.value.message).subscribe(() => {
      this.shareIsloading = false;
      this.snotifyService.success('Successfully shared quest!', null, this.snotifyConfigService.getConfig());
      this.modalService.close('share-email-modal');
    }, err => {
      this.shareIsloading = false;
      this.modalService.close('share-email-modal');
      this.snotifyService.error('Oops, something went wrong, please try again', null, this.snotifyConfigService.getConfig());
    });
  }
}
