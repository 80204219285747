import { Action } from '@ngrx/store';
import * as fromUserFriends from './profile-friends.reducer';

export const TRY_GET_USER_FRIENDS = 'TRY_GET_USER_FRIENDS';
export const GET_USER_FRIENDS = 'GET_USER_FRIENDS';
export const CLEAR_USER_FRIENDS = 'CLEAR_USER_FRIENDS';

export class TryGetUserFriends implements Action {
  readonly type = TRY_GET_USER_FRIENDS;
}

export class GetUserFriends implements Action {
  readonly type = GET_USER_FRIENDS;

  constructor(public payload: fromUserFriends.State[]) {}
}

export class ClearUserFriends implements Action {
  readonly type = CLEAR_USER_FRIENDS;
}

export type UserFriendsActions =  TryGetUserFriends |
                                  GetUserFriends |
                                  ClearUserFriends;
