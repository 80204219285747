<div *ngIf="pageIsLoaded" class="c-page c-profile-new__page">
  <div class="c-container xm-no-pad">
    <div class="c-profile-new__container">
      <!-- <app-profile-header-new
        *ngIf="userInfo"
        [country]="country"
        [mode]="'edit'"
        [userInfo]="userInfo"
        class="c-profile-new__header">
      </app-profile-header-new> -->
      <!-- 'is-thin' - add this class if tabs will be visible -->
      <div class="c-page__header c-container c-container_sm is-bold">
        <!-- <div class="c-quest-tabs">
            <nav class="c-quest-tabs__header has-align-center">
            <a routerLink="/account" [routerLinkActive]="['is-active']" class="c-quest-tabs__item">
                <svg class="icon icon-dl-account is-lg"><use xlink:href="#icon-dl-account"></use></svg>
                <span>Account</span>
            </a>
            <a routerLink="/profile" [routerLinkActive]="['is-active']" class="c-quest-tabs__item">
                <svg class="icon icon-dl-profile is-lg"><use xlink:href="#icon-dl-profile"></use></svg>
                <span>Profile</span>
            </a>
            </nav>
        </div> -->
        <h1 class="c-page__title">
          <svg class="icon icon-dl-account">
            <use xlink:href="#icon-dl-account"></use>
          </svg>
          <span>Account settings</span>
        </h1>
        <p class="c-page__sub-title">Manage your personal information and payments.</p>
      </div>
      <div class="c-container is-bold">
        <div class="c-account__row">
          <div class="c-account__row__header">
            <h4 class="c-account__row__title">
              <svg class="icon icon-user">
                <use xlink:href="#icon-user"></use>
              </svg>
              <span>Personal Information</span>
            </h4>
          </div>
          <form (ngSubmit)="onSubmitPersonalInfo()" [formGroup]="personalInfoForm" [ngClass]="{'form-is-submitted': formSubmitted}"
                class="c-account__row__body">
            <div class="c-forms__group" id="skip-nav-target">
              <strong class="c-forms__legend">First Name:</strong>
              <div class="c-forms__control is-required">
                <input
                  class="c-forms__input"
                  formControlName="firstName"
                  placeholder="Enter First Name"
                  aria-label="First name"
                  type="text">
              </div>
              <div
                *ngIf="(personalInfoForm.get('firstName').touched || formSubmitted) && personalInfoForm.get('firstName').invalid"
                class="c-forms__message is-bottom is-red">
                <div *ngIf="personalInfoForm.get('firstName').errors.required">{{ messages.required }}</div>
                <div
                  *ngIf="personalInfoForm.get('firstName').errors.minlength">{{ messages.minlength('First Name', personalInfoForm.get('firstName').errors.minlength.requiredLength) }}</div>
                <div
                  *ngIf="personalInfoForm.get('firstName').errors.maxlength">{{ messages.maxlength('First Name', personalInfoForm.get('firstName').errors.maxlength.requiredLength) }}</div>
              </div>
            </div>
            <div class="c-forms__group">
              <strong class="c-forms__legend">Last Name:</strong>
              <div class="c-forms__control is-required">
                <input
                  class="c-forms__input"
                  formControlName="lastName"
                  placeholder="Enter Last Name"
                  aria-label="Last name"
                  type="text">
              </div>
              <div
                *ngIf="(personalInfoForm.get('lastName').touched || formSubmitted) && personalInfoForm.get('lastName').invalid"
                class="c-forms__message is-bottom is-red">
                <div *ngIf="personalInfoForm.get('lastName').errors.required">{{ messages.required }}</div>
                <div
                  *ngIf="personalInfoForm.get('lastName').errors.minlength">{{ messages.minlength('Last Name', personalInfoForm.get('lastName').errors.minlength.requiredLength) }}</div>
                <div
                  *ngIf="personalInfoForm.get('lastName').errors.maxlength">{{ messages.maxlength('Last Name', personalInfoForm.get('lastName').errors.maxlength.requiredLength) }}</div>
              </div>
            </div>
            <div class="c-forms__group">
              <strong class="c-forms__legend">Email Address:</strong>
              <div class="c-forms__control is-required">
                <input
                  class="c-forms__input"
                  formControlName="email"
                  placeholder="Enter email"
                  aria-label="Email"
                  type="text">
              </div>
              <div
                *ngIf="(personalInfoForm.get('email').touched || formSubmitted) && personalInfoForm.get('email').invalid"
                class="c-forms__message is-bottom is-red">
                <div *ngIf="personalInfoForm.get('email').errors.required">{{ messages.required }}</div>
                <div *ngIf="personalInfoForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
              </div>
            </div>
            <div class="c-forms__group">
              <strong class="c-forms__legend">User Name:</strong>
              <div class="c-forms__control is-required">
                <input aria-label="User name" class="c-forms__input" formControlName="username" placeholder="Enter user name" type="text">
              </div>
              <div
                *ngIf="(personalInfoForm.get('username').touched || formSubmitted) && personalInfoForm.get('username').invalid"
                class="c-forms__message is-bottom is-red">
                <div *ngIf="personalInfoForm.get('username').errors.required">{{ messages.required }}</div>
              </div>
            </div>
            <div class="c-forms__group">
              <strong class="c-forms__legend">Zip Code:</strong>
              <div class="c-forms__control is-required">
                <input aria-label="Zip Code" class="c-forms__input" formControlName="zip" placeholder="Enter Zip Code" type="text">
              </div>
              <div *ngIf="(personalInfoForm.get('zip').touched || formSubmitted) && personalInfoForm.get('zip').invalid"
                   class="c-forms__message is-bottom is-red">
                <div *ngIf="personalInfoForm.get('zip').errors.required">{{ messages.required }}</div>
                <div *ngIf="personalInfoForm.get('zip').errors.pattern">{{ messages.invalidZipcode }}</div>
              </div>
            </div>
            <strong class="c-forms__legend">Update Password:</strong>
            <div class="c-forms__group">
              <div class="c-forms__control">
                <input aria-label="New Password" class="c-forms__input" formControlName="newpassword" placeholder="New password" type="password">
              </div>
              <div
                *ngIf="(personalInfoForm.get('newpassword').touched || formSubmitted) && personalInfoForm.get('newpassword').invalid"
                class="c-forms__message is-bottom is-red">
                <div
                  *ngIf="personalInfoForm.get('newpassword').errors.minlength">{{ messages.password.minlength(personalInfoForm.get('newpassword').errors.minlength.requiredLength) }}</div>
                <div
                  *ngIf="personalInfoForm.get('newpassword').errors.maxlength">{{ messages.password.minlength(personalInfoForm.get('newpassword').errors.maxlength.requiredLength) }}</div>
              </div>
            </div>
            <strong class="c-forms__legend h-d-none-up-xm">Notifications by e-mail:</strong>
            <div class="c-account__group">
              <div class="c-account__col">
                <div class="c-forms__group mb-20">
                  <label class="c-checkbox" role="checkbox">
                    <input (change)="personalInfoForm.get('receiveEmail').markAsTouched()" aria-label="Receive Email checkbox" formControlName="receiveEmail" type="checkbox"/>
                    <span class="c-checkbox__icon">
                      <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
                    </span>
                    <span class="c-checkbox__caption"><b>I love what you are doing.<br>Keep me inspired!</b></span>
                  </label>
                </div>
              </div>
              <div class="c-account__col">
                <div class="c-forms__group c-account__row__submit">
                  <button [disabled]="personalInfoRequestIsLoading"
                          [ngClass]="{'is-loading': personalInfoRequestIsLoading}"
                          class="c-btn c-btn_md c-btn_dark"
                          aria-label="Save information"
                          type="submit">
                    <span>Save information</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="c-account__row" *ngIf="userInfo">
          <div class="c-account__row__header">
            <h4 class="c-account__row__title">
              <svg class="icon icon-credit-card">
                <use xlink:href="#icon-credit-card"></use>
              </svg>
              <span>Payment Information</span>
            </h4>
          </div>
          <div>
            <span class="c-account__row__secured">Secured&nbsp;<svg class="icon icon-lock"><use xlink:href="#icon-lock"></use></svg></span>
            <img [src]="stripeLogo" alt="Stripe Powered" class="c-account__row__secured__stripe-powered"/>
          </div>
          <div class="c-account__row__body">
            <app-payments [userInfo]="userInfo"></app-payments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
