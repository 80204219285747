<div *ngIf="!simpleMode" class="c-quest-progress__inner" toggle-directive (toggle-directive-event)="removeClass()" [ngStyle]="{'box-shadow': isHuge ? '0 1px 14px -10px #000' : null}">
  <svg class="c-quest-progress__progress" width="120" height="120" viewBox="0 0 120 120">
    <circle class="c-quest-progress__progress__meter" cx="60" cy="60" [attr.r]="isHuge ? 54 : 56" [attr.stroke-width]="isHuge ? 12 : 6" [ngStyle]="{'stroke': isHuge ? '#dedede' : null}"/>
    <circle class="c-quest-progress__progress__value" [ngStyle]="{'stroke-dasharray': circumference, 'stroke-dashoffset': dashoffset}" cx="60" cy="60" [attr.r]="isHuge ? 54 : 56" [attr.stroke-width]="isHuge ? 12 : 6" />
  </svg>
  <div class="c-quest-progress__caption">
    <b>{{ value }}%</b>
    <span>Progress</span>
  </div>
</div>

<svg *ngIf="simpleMode" class="c-quest-progress__progress" width="120" height="120" viewBox="0 0 120 120">
  <circle class="c-quest-progress__progress__meter" cx="60" cy="60" [attr.r]="56" [attr.stroke-width]="6" [ngStyle]="{'stroke': '#ffffff', fill: '#57a8bb'}"/>
  <circle class="c-quest-progress__progress__value" [ngStyle]="{'stroke-dasharray': circumference, 'stroke-dashoffset': dashoffset}" cx="60" cy="60" [attr.r]="56" [attr.stroke-width]="6" />
</svg>