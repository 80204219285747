import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountService } from 'src/app/components/pages/account/account.service';
import { QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivityCard } from '../activity-feed.component';


@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.styl']
})
export class ActivityFormComponent implements OnInit {
  @Output() newPost = new EventEmitter();
  form: FormGroup;
  userInfo: QuestUserInfo;
  timeString: string = this.getTimeString();

  constructor(
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      message: new FormControl('', Validators.required),
      hashtags: new FormControl('')
    });
    this.accountService.getUserInfo().subscribe((userInfo: QuestUserInfo) => {
      this.userInfo = userInfo;
    });
  }

  ngOnChanges() {
  }

  getTimeString() {
    const now = Date();
    return now.substring(0, now.length - "GMT-0400 (Eastern Daylight Time)".length);
  }

  onSubmit(activityForm) {
    const postText = activityForm.message;
    const hashtags = activityForm.hashtags.split(' ');
    const newCard: ActivityCard = {
      "hasAnnouncement" : false,
      "cheers" : 0,
      "postText" : postText,
      "hashtags" : hashtags
    };
    this.newPost.emit(newCard);
    console.log("Submitted activityForm");
    console.log(postText);
    console.log(hashtags);
    this.form.reset();
  }

}
