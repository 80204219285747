import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.styl']
})
export class PrivacyComponent implements OnInit {
  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(private meta: Meta, private titleService: Title) { }

  ngOnInit() {
    this.setMetaTags();
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | Privacy Policy');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | Privacy Policy'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'DIEMlifes Privacy Policy information'});
  }

}
