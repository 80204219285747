<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="c-dl-newsfeed-ctrl__body" [ngClass]="{'has-preview': hasPreview.value, 'is-disabled': isLoading}">
        <div class="c-dl-newsfeed-ctrl__view">
            <div class="c-dl-newsfeed-ctrl__view__circle" #circleEl>
                <div class="c-dl-newsfeed-ctrl__photo">
                    <input class="c-dl-newsfeed-ctrl__photo__input" #fileInput type="file" (change)="fileChangeEvent($event)" />
                    <div class="c-dl-newsfeed-ctrl__header" *ngIf="hasPreview.value">
                        <button class="h-btn-reset e-dl-btn" (click)="confirmRemove()" type="button">
                            <svg class="icon icon-cancel"><use xlink:href="#icon-cancel"></use></svg>
                        </button>
                        <button class="h-btn-reset e-dl-btn" (click)="triggerFileInput()" type="button">
                            <svg class="icon icon-pencil"><use xlink:href="#icon-pencil"></use></svg>
                        </button>
                    </div>
                    <button *ngIf="!hasPreview.value" class="c-dl-newsfeed-ctrl__trigger h-loader h-btn-reset" [ngClass]="{'is-loading': isLoading === 'LOAD_PHOTO'}" (click)="triggerFileInput()" type="button">
                        <div class="h-loader__control is-currentColor"></div>
                    </button>
                    <div class="c-dl-newsfeed-ctrl__photo__inner h-object-fit h-loader" [ngClass]="{'is-loading': isLoading === 'LOAD_PHOTO'}" *ngIf="hasPreview.value">
                        <div class="h-loader__control is-currentColor"></div>
                        <ng-container *ngIf="isLoading !== 'LOAD_PHOTO'">
                            <img *ngIf="hasPreview.key === 'BASE_64'" [src]="'data:image/jpeg;base64,' + base64" alt="new image">
                            <img *ngIf="hasPreview.key === 'IMAGE_URL'" [src]="comment?.image?.questImageUrl" alt="test">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-dl-newsfeed-ctrl__caption">
            <div class="c-dl-newsfeed-ctrl__text" [ngClass]="{'h-is-in-focus': isInFocus}">
                <label class="c-dl-newsfeed-ctrl__text__inner">
                    <textarea
                        #textEl
                        formControlName="editor"
                        class="c-dl-newsfeed-ctrl__text__ctrl"
                        placeholder="Share something"
                        [ngxTribute]="tributeOptions"
                        (focus)="focusIn()"
                        (focusout)="focusOut()"
                        autosize>
                    </textarea>
                </label>
            </div>
        </div>
    </div>
    <div class="c-dl-newsfeed-ctrl__footer" *ngIf="isValueChanged">
        <button class="e-btn e-btn_dark h-btn-reset" [ngClass]="{'is-loading': isLoading === 'COMMENT'}" [disabled]="isLoading || form.invalid" type="submit">
            <span>{{ comment ? 'Done' : 'Post' }}</span>
        </button>
    </div>
</form>
