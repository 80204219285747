import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {StartSoloQuestPayload} from '../team-quest-starter/team-quest-starter.component';
import {AppState, teamModalOpenState, teamModalProgressState} from '../../../../_store/app.reducers';

@Component({
  selector: 'app-individual-form',
  templateUrl: './individual-form.component.html',
  styleUrls: ['./individual-form.component.styl']
})
export class IndividualFormComponent implements OnInit {

  @Input() fundraisingEnabled: boolean = false;
  @Output() onSoloFundraiser: EventEmitter<StartSoloQuestPayload> = new EventEmitter<StartSoloQuestPayload>();

  soloFormGroup: FormGroup;
  progress: boolean;

  soloModalOpenSubscription: Subscription;
  soloModalProgressSubscription: Subscription;

  formIsSubmitted = false;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.soloModalOpenSubscription = this.store.pipe(select(teamModalOpenState)).subscribe(state => {
      if (this.soloFormGroup && state.open) {
        this.soloFormGroup.reset();
      }
    });
    this.soloModalProgressSubscription = this.store.pipe(select(teamModalProgressState)).subscribe(state => {
      this.progress = state.questProgress || state.fundProgress;
    });

    this.soloFormGroup = new FormGroup({});
    if (this.fundraisingEnabled) {
      this.soloFormGroup.addControl('campaignName', new FormControl(null, [
        Validators.maxLength(250)
      ]));
      this.soloFormGroup.addControl('targetAmount', new FormControl(null, [
        Validators.required
      ]));
    }
  }

  onSubmitSoloForm() {
    this.formIsSubmitted = true;
    if (this.fundraisingEnabled) {
      this.onSoloFundraiser.emit({
        campaignName: this.soloFormGroup.controls['campaignName'].value,
        targetAmount: Number(this.soloFormGroup.controls['targetAmount'].value || 0) * 100
      });
    } else {
      this.onSoloFundraiser.emit({});
    }
  }

}
