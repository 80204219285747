<div class="c-transaction__view" [ngSwitch]="data.type">
    <!-- not checked START -->
    <span class="c-transaction__view__link" *ngSwitchCase="'PAYOUT'">
        <app-profile-avatar
          class="c-transaction__view__avatar c-avatar"
          [avatar]="data.to.profilePhotoURL"
          [firstName]="data.to.firstName"
          [isBrand]="false"
          [lastName]="data.to.lastName"
          [userName]="data.to.userName">
        </app-profile-avatar>
    </span>
    <!-- not checked END -->
    <a (click)="goToProfileLink()"
        href="javascript:void(null)"
        class="c-transaction__view__link"
        *ngSwitchDefault>
        <app-profile-avatar
          class="c-transaction__view__avatar c-avatar"
          [avatar]="data[path].profilePhotoURL"
          [firstName]="data[path].firstName"
          [isBrand]="false"
          [lastName]="data[path].lastName"
          [userName]="data[path].userName">
        </app-profile-avatar>
    </a>
</div>
<div class="c-transaction__caption">
    <!-- not checked START -->
    <div class="c-transaction__inner" *ngIf="data.type === 'PAYOUT'">
        <div class="c-transaction__status"> Bank transfer initiated to account - ...{{ data.lastFour }}</div>
    </div>
    <!-- not checked END -->
    <div class="c-transaction__inner" *ngIf="data.incoming && data.type !== 'PAYOUT' && (data.type === 'BACKING' || data.type === 'ORDER' || data.type === 'FUNDRAISING')">

        <div class="c-transaction__status" *ngIf="!data.isAnonymous">
            <a (click)="goToProfileLink()" href="javascript:void(null)">{{ data.from.firstName }}</a> backed your
        </div>
        <div class="c-transaction__status" *ngIf="data.isAnonymous">You were backed anonymously</div>
        <div class="c-transaction__description">
            <a (click)="goToQuestLink()" href="javascript:void(null)">{{ data.description }}</a>
        </div>
        <div class="c-transaction__date">{{ data.created | timeAgo }}</div>
    </div>
    <div class="c-transaction__inner" *ngIf="data.outgoing && data.type !== 'PAYOUT' && (data.type === 'BACKING' || data.type === 'ORDER' || data.type === 'FUNDRAISING' || data.type === 'SUBSCRIPTION')">
      <div class="c-transaction__row" *ngIf="(data.type === 'BACKING' || data.type === 'FUNDRAISING' || data.type === 'SUBSCRIPTION')">
          <div class="c-transaction__status">
              You backed <a (click)="goToProfileLink()" href="javascript:void(null)">{{ data.to.firstName }}</a>'s
          </div>
          <div class="c-transaction__description">
              <a (click)="goToQuestLink()" href="javascript:void(null)">{{ data.description }}</a>
          </div>
          <div class="c-transaction__date">{{ data.created | timeAgo }}</div>
      </div>
      <div class="c-transaction__row" *ngIf="data.type === 'ORDER'">
          <div class="c-transaction__status">
              You purchased tickets for <a (click)="goToProfileLink()" href="javascript:void(null)">{{ data.to.firstName }}</a>'s
          </div>
          <div class="c-transaction__description">
              <a (click)="goToQuestLink()" href="javascript:void(null)">{{ data.description }}</a>
          </div>
          <div class="c-transaction__date">{{ data.created | timeAgo }}</div>
      </div>
    </div>
    <div class="c-transaction__currency" [ngSwitch]="getPaymentDirection()">
        <b class="is-green" *ngSwitchCase="'IN'">{{ data.amount / 100.0 | currency }}</b>
        <b class="is-red" *ngSwitchCase="'OUT'">{{ data.amount / 100.0 | currency }}</b>
        <span *ngIf="currencyHasFees && !hideFees">Fees: {{ fee / 100.0 | currency }}</span>
    </div>
</div>
