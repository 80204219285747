import {Component, Input, OnDestroy, OnInit, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {QuestService} from '../../../../_services/quest.service';
import {Store} from '@ngrx/store';
import {Observable, Subscribable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {QuestComment} from 'diemlife-commons/dist/diemlife-commons-model';
import {PreparedQuestComment} from './comment/comment.type';
import {WebviewNavigationDataType} from 'src/app/components/pages/quest-detail-comments/quest-detail-comments.component';
import {AnimateItems, AnimateList, AnimateItemsUp} from 'src/app/animations/comments.animations';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import * as fromQuestComponents from './store/quest-components.reducer';
import * as fromApp from '../../../../_store/app.reducers';
import * as CommentsActions from './store/quest-components.actions';
import * as fromAuthState from '../../../main/auth/store/auth.reducer';

export interface Mention {
  key: string;
  profilePictureURL: string;
  value: string;
}
export interface MentionListItem {
  index: number;
  score: number;
  string: string;
  original: {
    key: string;
    profilePictureURL: string;
    value: string;
  };
}

@Component({
  selector: 'app-quest-comments',
  templateUrl: './quest-comments.component.html',
  styleUrls: ['./quest-comments.component.styl'],
  animations: [AnimateList, AnimateItems, AnimateItemsUp],
})
export class QuestCommentsComponent implements OnInit, OnDestroy {
  @Output() navigateToEmit: EventEmitter<WebviewNavigationDataType> = new EventEmitter<WebviewNavigationDataType>();
  @Input('webviewMode') webviewMode: boolean;
  @Input('questUserId') questUserId?: number;
  @Input('questId') questId: number;
  userInfoState: Observable<fromProfile.State>;
  commentsState: Observable<fromQuestComponents.State>;
  authState: Observable<fromAuthState.State>;
  commentsStateSubscription: Subscription;
  viewerId: number;
  isLoaded = false;
  comments: QuestComment[] = [];
  closeReplyForm: boolean = null;
  limit = 10;
  limitStep = 10;
  constructor(
    private questService: QuestService,
    private store: Store<fromApp.AppState>,
    private cdr: ChangeDetectorRef
  ) {
    // this.comments = [];
    this.userInfoState = this.store.select('userInfo');
    this.commentsState = this.store.select('questComponents');
    this.authState = this.store.select('auth');
  }

  loadMore() {
    this.limit += this.limitStep;
  }
  ngOnInit() {
    this.commentsStateSubscription = this.commentsState.subscribe((state: fromQuestComponents.State) => {
      this.comments = state.comments;
    });
    this.userInfoState.pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.viewerId = state.id;
      this.questService.getQuestComments(this.questId, this.questUserId, state.id).subscribe((res: PreparedQuestComment[]) => {
        this.store.dispatch(new CommentsActions.SetCommentsList(res));
        this.isLoaded = true;
      });
    });
  }

  ngOnDestroy() {
    if (this.commentsStateSubscription) {
      this.commentsStateSubscription.unsubscribe();
    }
    this.store.dispatch(new CommentsActions.ClearCommentsList());
  }

  onNavigate(navigateData: WebviewNavigationDataType) {
    this.navigateToEmit.emit(navigateData);
  }

  onCloseAnotherReplyForms() {
    this.closeReplyForm = false;
    setTimeout(() => {
      this.closeReplyForm = null;
    }, 0);
  }
}
