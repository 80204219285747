<button class="c-btn c-btn_md c-btn_dark" (click)="openModal('pay-modal')" type="button">
  <span>Add Credit Card</span>
  <svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
</button>

<app-modal id="pay-modal">
  <h3 class="modal-title">Add payment method</h3>
  <form #checkout="ngForm" (ngSubmit)="onSubmit()" class="modal-body c-payments c-container">
    <div class="c-payments__group">
      <div class="c-payments__group__body">
        <div class="c-payments__tab">
          <ul class="c-payments__list">
            <li class="c-payments__list__item">
              <div class="c-forms__group">
                <div class="c-forms__control">
                  <div class="c-forms__input" id="card-info" #cardInfo [ngClass]="{'is-focus': cardInFocus}"></div>
                </div>
              </div>
              <div class="c-forms__message is-red" id="card-errors" role="alert" *ngIf="cardValidation.error">{{ cardValidation.error }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="c-forms__group">
      <button class="c-btn c-btn_full c-btn_md c-btn_dark-yellow"
        [disabled]="isLoading"
        [ngClass]="{'is-loading': isLoading}"
        aria-label="Submit"
        type="submit">Submit</button>
    </div>
  </form>
</app-modal>
