import {Action, ActionReducer, ActionReducerMap, createSelector, MetaReducer} from '@ngrx/store';
import * as fromAuth from '../components/main/auth/store/auth.reducer';
import * as fromProfile from '../components/pages/profile/store/profile.reducer';
import * as fromUserFavorites from '../components/pages/profile/store/user-favorites.reducer';
import * as fromFriendsFriends from '../components/separate/profile-friends/store/profile-friends.reducer';
import * as fromRecentTransactions from '../components/pages/profile/store/recent-transactions.reducer';
import * as fromPaymentsReducer from '../components/main/payments/store/payments.reducer';
import * as fromExploreReducer from '../components/pages/explore/store/explore.reducer';
import * as fromDlExploreReducer from '../modules/explore/store/explore.reducer';
import * as fromDonateReducer from '../components/pages/donate/store/donate.reducer';
import {
  QuestDetailState,
  QuestReducer,
  QuestTeamModalReducer,
  TeamModalState,
  Transaction,
  myQuestsReducer,
  getMyQuestsStats,
  getIsLoadingStatus,
  MyQuestsState
} from 'diemlife-commons/dist/diemlife-commons-model';
import * as fromQuestComponentsReducer from '../components/main/quest/quest-comments/store/quest-components.reducer';
import * as fromMyQuestsReducer from '../components/pages/my-quests/store/my-quests.reducer';
import * as fromNotifications from '../components/main/notifications/store/notifications.reducer';
import * as fromSearch from '../components/main/search/store/search.reducer';
import * as fromTicketFlow from '../modules/ticket-flow/pages/ticket-flow/store/ticket-flow.reducer';
import * as fromDlMilestones from '../components/main/dl-milestones/store/dl-milestones.reducer';
import * as AuthActions from '../components/main/auth/store/auth.actions';

export type anyState = fromAuth.State |
  fromProfile.State |
  fromUserFavorites.State[] |
  fromFriendsFriends.State[] |
  Transaction[] |
  fromPaymentsReducer.State |
  fromExploreReducer.State |
  fromDlExploreReducer.State |
  fromDonateReducer.State |
  QuestDetailState |
  TeamModalState |
  fromQuestComponentsReducer.State |
  fromMyQuestsReducer.State |
  fromNotifications.State |
  fromSearch.State |
  fromTicketFlow.State |
  fromDlMilestones.State |
  MyQuestsState;

export interface AppState {
  auth: fromAuth.State;
  userInfo: fromProfile.State;
  userFavorites: fromUserFavorites.State[];
  userFriends: fromFriendsFriends.State[];
  recentTransactions: Transaction[];
  paymentsInfo: fromPaymentsReducer.State;
  explore: fromExploreReducer.State;
  dlExplore: fromDlExploreReducer.State;
  donate: fromDonateReducer.State;
  quest: QuestDetailState;
  team: TeamModalState;
  questComponents: fromQuestComponentsReducer.State;
  myQuests: fromMyQuestsReducer.State;
  notifications: fromNotifications.State;
  search: fromSearch.State;
  ticketFlow: fromTicketFlow.State;
  dlMilestones: fromDlMilestones.State;
  dlMyQuests: MyQuestsState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  userInfo: fromProfile.ProfileReducer,
  userFavorites: fromUserFavorites.UserFavoritesReducer,
  userFriends: fromFriendsFriends.UserFriendsReducer,
  recentTransactions: fromRecentTransactions.RecentTransactionsReducer,
  paymentsInfo: fromPaymentsReducer.paymentsReducer,
  explore: fromExploreReducer.ExploreReducer,
  dlExplore: fromDlExploreReducer.DlExploreReducer,
  donate: fromDonateReducer.DonateReducer,
  team: QuestTeamModalReducer,
  quest: QuestReducer,
  questComponents: fromQuestComponentsReducer.commentsReducer,
  myQuests: fromMyQuestsReducer.MyQuestsReducer,
  notifications: fromNotifications.NotificationsReducer,
  search: fromSearch.searchReducer,
  ticketFlow: fromTicketFlow.TicketFlowReducer,
  dlMilestones: fromDlMilestones.dlMilestonesReducer,
  dlMyQuests: myQuestsReducer,
};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: Action): AppState {
    if (action.type === AuthActions.LOGOUT) {
      console.info('Clearing global application state on logout');
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];

export const teamStateSelect = (state: AppState) => state.team;
export const questStateSelect = (state: AppState) => state.quest;
export const dlMyQuestsSelect = (state: AppState) => state.dlMyQuests;

export const teamModalOpenState = createSelector(teamStateSelect, (state: TeamModalState) => state.openState);
export const teamModalProgressState = createSelector(teamStateSelect, (state: TeamModalState) => state.progressState);
export const questDetailState = createSelector(questStateSelect, (state) => state);

export const myQuestsListGetStats = createSelector(dlMyQuestsSelect, getMyQuestsStats);
export const myQuestsIsLoadingStatus = createSelector(dlMyQuestsSelect, getIsLoadingStatus);
