export interface NewQuestGalleryPhoto {
  questId: number;
  questImage: any;
  caption: string | null;
  contentType: any;
}

export interface QuestCategory {
  value: string;
  label: string;
  input: string;
  content?: string;
}

export interface QuestPreparedPhoto {
  jpg: string;
  webp: string;
}

export interface QuestAdmin {
  email: string;
  name: string;
}

export enum QuestDetailStatus {
  Public = 'public',
  Saved = 'saved',
  Active = 'active',
  Completed = 'completed'
}

export enum QuestDisplayRole {
  Doer = 'doer',
  Viewer = 'viewer'
}

export enum UploadTeamImageModalMode {
  TeamLogo,
  CoverPicture
}
