<div class="terms--top-mod"><h1 class="terms--top-mod-title">Intellectual Property
  Policy</h1></div>
<div class="terms--top-mod"><h3 class="terms--mod-title">Copyright, Trademark and
  Patents</h3></div>
<div class="terms--bottom">
  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <!--<h3 class="terms&#45;&#45;mod-title">Terms Of Service</h3>-->
      <p>
        DIEMlife respects the intellectual property rights of others and expects our users to do the same. We may, if
        we deem appropriate, disable or terminate accounts of users who infringe the intellectual property of others.
      </p>
      <p>
        This Intellectual Property Policy does not constitute legal advice. You should consult with an attorney before
        reporting an infringement notice or counter-notice.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Copyright</h3>
      <p>
        DIEMlife has adopted this policy in accordance with the <a *ngIf="!webViewMode"
        href="https://www.copyright.gov/legislation/dmca.pdf" target="_blank">Digital Millennium Copyright Act of
        1998.</a><a *ngIf="webViewMode" href="javascript:void(0)" (click)="goToPage('https://www.copyright.gov/legislation/dmca.pdf', 'external')">Digital Millennium Copyright Act of
          1998.</a>
      </p>
      <p>
        If you believe that your copyright is being infringed, you can report the alleged violation by completing the
        DMCA Notice of Alleged Infringement as outlined in this Policy. When we receive a DMCA notice, we may take
        whatever action deemed necessary, including removing the reported content. If we remove such content, the
        person responsible for posting such content will be afforded an opportunity to submit a counter-notice.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Reporting a copyright violation</h3>
      <p>
        To submit a DMCA notice, please mail or send an email to:
      </p>
      <dl>
        <dt>DIEMlife, Inc.</dt>
        <dt>85 East 10th St Ste. M</dt>
        <dt>New York, NY 10003</dt>
        <dt>United States</dt>
        <dt>Attn: Copyright Agent</dt>
        <dt>Email: copyright@diemlife.com</dt>
      </dl>
      <p>
        Your DMCA notice must contain the following information:
      </p>
      <dl>
        <dt>• Description of the copyrighted work you believe has been infringed.</dt>
        <dt>• Description and location of the content on DIEMlife you claim to be infringing (URL).</dt>
        <dt>• Your address, telephone number and email address.</dt>
        <dt>• The statement, “I understand that under 17 U.S.C § 512(f), I may be liable for any damages, including
          costs and attorneys' fees, if I knowingly and materially misrepresent that reported material or activity is
          infringing.”
        </dt>
        <dt>• The statement, “I have a good faith belief that the disputed use is not authorized by the copyright
          owner, its agent, or the law.”
        </dt>
        <dt>• The statement, “The information in this notice is accurate and, under penalty of perjury, I am the
          owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the
          copyright that is allegedly infringed.”
        </dt>
        <dt>• Your full legal name and your electronic or physical signature.</dt>
      </dl>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">What happens if I receive a DMCA notification?</h3>
      <p>
        If DIEMlife receives a DMCA notice about your content, we will remove the infringing material or disable
        access to your content until the dispute is resolved or the DMCA process is complete. If you want to see the
        complaint, email us at copyright@diemlife.com and we will forward the copyright complaint notice.
      </p>
      <p>
        If you receive a DMCA notice and believe that your work has been removed or disabled by mistake or because of
        misidentification, you can submit a counter-notice to copyright@diemlife.com and must include all of the
        following information:
      </p>
      <dl>
        <dt>• Your name, address and phone number.</dt>
        <dt>• Description and location of the content that we removed or to which access has been disabled.</dt>
        <dt>• The statement, “The information in this notice is accurate and, under penalty of perjury, I have a good
          faith belief that the content was removed or disabled in error.”
        </dt>
        <dt>• The statement, “I consent to the jurisdiction of the Federal District Court for the judicial district in
          which my address is located, or if my address is outside of the United States, for any judicial district in
          which DIEMlife may be found, and that I will accept service of process from the person who provided notice
          under 17 U.S.C. § 512(c)(3), or an agent of such person.”
        </dt>
        <dt>• A physical or electronic signature.</dt>
      </dl>
      <p>
        Under the Copyright Act, any person who knowingly materially misrepresents that content was removed or
        disabled by mistake or misidentification may be subject to liability.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">What happens next?</h3>
      <p>
        After we receive your counter-notice, we will forward it to the complainant. If we do not receive a written
        notice within 10 business days after forwarding the counter-notice to the complainant that the complainant has
        filed an action seeking a court order in regard to the unauthorized use of the copyrighted material, we may
        decide, in our sole discretion, to provide access to the removed or disabled content.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Trademark</h3>
      <p>
        If you believe that your trademark is being infringed, you can report the alleged violation by emailing us at
        trademark@diemlife.com and including the following information:
      </p>
      <dl>
        <dt>• Your name, address, telephone number and email address.</dt>
        <dt>• Description of your trademark.</dt>
        <dt>• Jurisdiction(s) in which your trademark is registered and, if applicable, the corresponding registration
          number(s).
        </dt>
        <dt>• Description and location of the infringing material(URL).</dt>
        <dt>• A statement that you have a good faith belief that use of the material described above infringes on a
          trademark you own or whose owner you represent.
        </dt>
        <dt>• A statement that the information in your notice is accurate and that you own the trademark or are
          authorized to act on behalf of the trademark owner.
        </dt>
        <dt>• A statement that you understand that a copy of your notice may be sent to the account holder allegedly
          infringing on your trademark.
        </dt>
        <dt>• A physical or electronic signature.</dt>
      </dl>
      <p>
        We will review your submission and take whatever action we deem appropriate, including removing the infringing
        material and forwarding your notice to the account holder of the material at issue.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Patent</h3>
      <p>
        If you believe that a project is offering an item or service that infringes on a valid and enforceable patent,
        you can report the alleged violation by emailing us at patent@diemlife.com and including the following
        information:
      </p>
      <dl>
        <dt>• Your name, address, telephone number and email address.</dt>
        <dt>• Your patent registration number.</dt>
        <dt>• A copy of the court order finding infringement of the patent.</dt>
        <dt>• Description and location of infringing material or service (URL).</dt>
        <dt>• A statement that you understand that we may provide third parties, including the affected user, with a
          copy of your complaint.
        </dt>
        <dt>• A statement under penalty of perjury that the information in your complaint is true and correct and that
          you are the patent holder or are authorized to act on the patent holder's behalf.
        </dt>
      </dl>
      <p>
        We will review your submission and will remove any material or service that is the subject of a court order
        finding infringement of a valid and enforceable patent.
      </p>
    </div>
  </div>
</div>