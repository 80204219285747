<div class="c-notifications__body h-loader" [ngClass]="{'is-loading': !isLoaded}">
    <div class="c-notifications__list h-loader__caption">
        <a class="c-notifications__item"
            (click)="goToThatPage(item)"
            href="javascript:void(0)"
            *ngFor="let item of notifications; let i = index"
            [class.is-new]="!item.isRead">
            <div class="c-notifications__item__avatar">
                <app-profile-avatar
                    class="c-avatar"
                    [avatar]="item.user.avatarUrl"
                    [firstName]="item.user.firstName"
                    [lastName]="item.user.lastName"
                    [isBrand]="item.user.isUserBrand"
                    [userName]="item.user.userName">
                </app-profile-avatar>
                <div class="c-notifications__item__status" *ngIf="item.isRead">Seen</div>
            </div>
            <div class="c-notifications__item__body">
                <div class="c-notifications__item__ttl">
                    <span>{{ item.user.firstName + ' ' + item.user.lastName }}</span>
                </div>
                <p class="c-notifications__item__desc">{{ item.message | htmlToPlaintext }}</p>
                <span class="c-notifications__item__time">{{ item.createdDate | timeAgo }}</span>
            </div>
            <div class="c-notifications__item__icon" [ngSwitch]="item.type">
                <svg *ngSwitchCase="'QUEST_SAVED'" class="icon icon-save"><use xlink:href="#icon-save"></use></svg>
                <svg *ngSwitchCase="'COMMENT'" class="icon icon-comment"><use xlink:href="#icon-comment"></use></svg>
                <svg *ngSwitchCase="'COMMENT_REPLIED'" class="icon icon-comment"><use xlink:href="#icon-comment"></use></svg>
                <svg *ngSwitchCase="'QUEST_STARTED'" class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
                <svg *ngSwitchCase="'COMMENT_LIKED'" class="icon icon-like"><use xlink:href="#icon-like"></use></svg>
                <svg *ngSwitchCase="'FRIEND_REQUEST'" class="icon icon-add-friend"><use xlink:href="#icon-add-friend"></use></svg>
                <svg *ngSwitchCase="'MILESTONE_ACHIEVED'" class="icon icon-list"><use xlink:href="#icon-list"></use></svg>
                <svg *ngSwitchCase="'PHOTO_VIDEO_ADDED'" class="icon icon-media"><use xlink:href="#icon-media"></use></svg>
                <svg *ngSwitchCase="'QUEST_BACKED'" class="icon icon-piggybank"><use xlink:href="#icon-piggybank"></use></svg>
                <svg *ngSwitchCase="'PROFILE_BACKED'" class="icon icon-piggybank"><use xlink:href="#icon-piggybank"></use></svg>
                <svg *ngSwitchCase="'EVENT_STARTED'" class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
                <svg *ngSwitchCase="'FOLLOWED'" class="icon icon-add-friend"><use xlink:href="#icon-add-friend"></use></svg>
                <svg *ngSwitchCase="'FUNDRAISER_STARTED'" class="icon icon-piggybank"><use xlink:href="#icon-piggybank"></use></svg>
                <svg *ngSwitchCase="'QUEST_ACHIEVED'" class="icon icon-update"><use xlink:href="#icon-update"></use></svg>
            </div>
        </a>
        <button
            *ngIf="!reachedEnd && isLoaded"
            class="c-btn c-btn_md c-btn_dark"
            [attr.disabled]="activityStatus"
            style="width: 100%"
            [ngClass]="{'is-loading': !isLoaded}"
            (click)="loadMore()">
            Click to load more...
        </button>
    </div>
    <div class="h-loader__control"></div>
</div>
