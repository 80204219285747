<app-skip-nav target="skip-nav-target"></app-skip-nav>
<header id="header" class="c-header" *ngIf="!hideHeader"></header>
<app-menu class="c-menu grade1" *ngIf="menuService.isOpened"></app-menu>

<div class="l-content" [ngClass]="{'no-top-pad': hideHeader}">
  <router-outlet></router-outlet>
</div>
<ng-snotify></ng-snotify>
<app-photo-swipe></app-photo-swipe><!-- photoswipe template -->
<app-modal id="auth-modal"
           (onOpen)="setupFormOptions($event)"
           (closeModal)="onCloseModal()"
           [disableBgClose]="true">
           <!-- <app-auth></app-auth> -->
           <app-dl-auth></app-dl-auth>
</app-modal>

<!-- * main navigation -->
<app-modal
  id="main-nav-modal"
  [className]="'c-dl-main-nav'"
  class="c-dl-main-nav__inner"
  [disableBgClose]="true"
  [removeCloseLogic]="true"
  [customMode]="true">
  <app-main-dl-nav></app-main-dl-nav>
</app-modal>

<app-modal id="notifications-modal" [className]="'c-n-modal_sm'">
  <app-notifications
    class="c-notifications"
    *ngIf="showNotifications">
  </app-notifications>
</app-modal>

<app-modal id="contact-modal"
  (closeModal)="closeContactModal()">
  <app-contact-us></app-contact-us>
</app-modal>

