import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LeaderboardAttribute} from 'diemlife-commons/dist/diemlife-commons-model';

@Component({
  selector: 'app-leaderboard-attribute-choice',
  templateUrl: './leaderboard-attribute-choice.component.html',
  styleUrls: ['./leaderboard-attribute-choice.component.styl']
})
export class LeaderboardAttributeChoiceComponent implements OnInit {

  @Input()
  attributes: LeaderboardAttribute[];
  @Output()
  selectedIdChanged: EventEmitter<string> = new EventEmitter<string>();

  selectedId: string;

  constructor() {
  }

  ngOnInit() {
    this.selectAttribute(this.attributes && this.attributes.length
      ? this.attributes[0].id
      : null);
  }

  selectAttribute(id: string) {
    if (this.selectedId !== id) {
      this.selectedId = id;
      this.selectedIdChanged.emit(this.selectedId);
    }
  }

}
