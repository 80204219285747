import { Injectable } from '@angular/core';
import { WindowRef } from '../../../_global/window-ref.module';
import { ReaquestHeadersService } from '../../../_services/requestHeaders.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShareWidjetService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private winRef: WindowRef) { }

  getShareUrl(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/url/${questId}`,
      { headers: headers }
    );
  }
  shareViaFacebook(url: string) {
    const FB = this.winRef.nativeWindow.FB || undefined;
    if (!FB) {
      return;
    }
    FB.ui({
      app_id: '1354334688023058',
      method: 'share',
      href: url,
      redirect_uri: url,
      display: 'touch',
      mobile_iframe: true
    }, (response)  => {
      console.log('response = ' + response);
    });
  }
  shareViaEmail(questId: number, emails: string[], message: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId,
      emails: emails,
      message: message
    };
    return this.http.post(
      environment.target + environment.context + `/index/shareQuest`,
      payload,
      { headers: headers }
    );
  }
}
