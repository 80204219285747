import { Component, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { PaymentsService } from './payments.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as fromApp from './../../../_store/app.reducers';
import * as fromPayments from './store/payments.reducer';
import * as PaymentsActions from './store/payments.actions';
// import { QuestUserInfo } from '../quest/quest.type';
import { ModalService } from '../modal/modal.service';
import { QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.styl']
})
export class PaymentsComponent implements OnInit {
  @Input('userInfo') userInfo: QuestUserInfo;
  paymentsState: Observable<fromPayments.State>;
  accountList: any;
  bankAccountsList: any;
  userCountrySupportsPayments = false;
  userCountrySupportsPayouts = false;
  hasValidStripeCustomer = false;
  hasValidStripeAccount = false;
  needAccountValidation = false;
  existingAccounts: any = null;
  // fields
  addressLine1Needed = false;
  dobNeeded = false;
  last4Needed = false;
  personalIdNumberNeeded = false;

  isloading = {
    payment: 'creditCard',
    index: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store<fromApp.AppState>,
    private paymentsService: PaymentsService,
    private modalService: ModalService
  ) {
    this.paymentsState = this.store.select('paymentsInfo');
  }
  ngOnInit() {
    this.checkPayments();
  }
  checkPayments() {
    this.userCountrySupportsPayments = this.userInfo.paymentsSupported;
    this.userCountrySupportsPayouts = this.userInfo.payoutsSupported;
    this.hasValidStripeCustomer = this.userInfo.billingEnabled;

    this.paymentsService.testStripeAccount(this.userInfo.id).subscribe(() => {
      this.hasValidStripeAccount = true;
    }, () => {
      this.hasValidStripeAccount = false;
    });
    this.paymentsService.checkAccountValidation().subscribe((neededFields: any) => {
      if (neededFields && neededFields.length > 0) {
        this.needAccountValidation = true;
        neededFields.forEach((element) => {
          switch (String(element.stripeRequiredFields)) {
            case 'ADDRESS_LINE_1':
              this.addressLine1Needed = true;
              break;
            case 'DOB_DAY':
              this.dobNeeded = true;
              break;
            case 'DOB_MONTH':
              this.dobNeeded = true;
              break;
            case 'DOB_YEAR':
              this.dobNeeded = true;
              break;
            case 'LAST_4':
              this.last4Needed = true;
              break;
            case 'PERSONAL_ID_NUMBER':
              this.personalIdNumberNeeded = true;
              break;
            default:
              break;
          }
        });
      }
    }, () => {
    });
  }
  vefifyBankInfo(index: number) {
    this.modalService.open('verify-bank-account-dialog');
  }
  removeCreditCard(index: number) {
    this.isloading.payment = 'creditCard';
    this.isloading.index = index;

    this.paymentsState.pipe(take(1)).subscribe((paymentsState: fromPayments.State) => {
      const lastFourDigits: string = paymentsState.creditCards[index].lastFourDigits;

      this.paymentsService.removeCreditCard(lastFourDigits).subscribe(res => {
        this.store.dispatch(new PaymentsActions.RemoveCreditCard(index));

        this.isloading.index = null;
      });
    });
  }
  removeBankAccount(index: number) {
    this.isloading.payment = 'bankAccount';
    this.isloading.index = index;

    this.paymentsService.removeBankAccount().subscribe(res => {
      this.store.dispatch(new PaymentsActions.RemoveBankAccount(index));

      this.isloading.index = null;
    });
  }
  activatePayouts() {
    this.paymentsService.activatePayments().subscribe(res => {
      this.checkPayments();
    }, err => {
      console.log(err);
    });
  }
}
