<div class="c-modal-tabs__body" [ngSwitch]="step">
  <form *ngSwitchCase="'signUp'" [formGroup]="signUpForm" (ngSubmit)="signUp()" [ngClass]="{'form-is-submitted': submitted}">
    <div class="c-forms__row">
      <div class="c-forms__col">
        <div class="c-forms__group">
          <div class="c-forms__control is-required">
            <input class="c-forms__input"
                   formControlName="firstName"
                   placeholder="First name"
                   type="text"
                   [attr.disabled]="activityStatus"
                   aria-label="First name"
                   autocomplete="given-name"
                   appInputFocusTrigger>
                   <!-- name="name" -->
          </div>
        </div>
        <div *ngIf="signUpForm.get('firstName').invalid && (signUpForm.get('firstName').touched || submitted)"
          class="c-forms__message is-red"
          role="alert">
          <div *ngIf="signUpForm.get('firstName').errors.required">{{ messages.required }}</div>
        </div>
      </div>
      <div class="c-forms__col">
        <div class="c-forms__group">
          <div class="c-forms__control is-required">
            <input [attr.disabled]="activityStatus" aria-label="Last Name" class="c-forms__input" formControlName="lastName" placeholder="Last name" type="text">
          </div>
        </div>
        <div *ngIf="signUpForm.get('lastName').invalid && (signUpForm.get('lastName').touched || submitted)"
          class="c-forms__message is-red"
          role="alert">
          <div *ngIf="signUpForm.get('lastName').errors.required">{{ messages.required }}</div>
        </div>
      </div>
    </div>
    <div class="c-forms__group">
      <div class="c-forms__control is-required">
        <input [attr.disabled]="activityStatus" aria-label="Email" class="c-forms__input" formControlName="email" placeholder="Email"  type="email">
        <!-- name="email" -->
      </div>
    </div>
    <div *ngIf="signUpForm.get('email').invalid && (signUpForm.get('email').touched || submitted)"
      class="c-forms__message is-red"
      role="alert">
      <div *ngIf="signUpForm.get('email').errors.required">{{ messages.required }}</div>
      <div *ngIf="signUpForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
      <div *ngIf="signUpForm.get('email').errors.emailTaken">This email is already taken</div>
    </div>
    <div class="c-forms__group">
      <div class="c-forms__control is-required" [attr.disabled]="activityStatus">
        <select
          formControlName="country"
          bs-select-directive
          [events]="eventsSubject.asObservable()"
          data-live-search="true"
          data-style="c-forms__input"
          title="Please select country..."
          (close)="markAsTouched()"
          autocomplete="on">
          <!-- name="country" -->
          <option
            *ngFor="let country of countries | keys"
            attr.data-content="{{buildOption(country.key, country.value)}}"
            [value]="country.key">{{country.value}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="(signUpForm.get('country').touched || submitted) && signUpForm.get('country').invalid"
      class="c-forms__message is-red"
      role="alert">
        <div *ngIf="signUpForm.get('country').errors.required">{{ messages.required }}</div>
    </div>
    <div class="c-forms__group">
      <div class="c-forms__control is-required">
        <input [attr.disabled]="activityStatus" aria-label="Zip/Postal Code" class="c-forms__input" formControlName="zip"  placeholder="Zip/Postal code" type="text">
        <!-- name="postal-code" -->
      </div>
    </div>
    <div *ngIf="signUpForm.get('zip').invalid && (signUpForm.get('zip').touched || submitted)"
      class="c-forms__message is-red"
      role="alert">
      <div *ngIf="signUpForm.get('zip').errors.required">{{ messages.required }}</div>
      <div *ngIf="signUpForm.get('zip').errors.pattern">{{ messages.invalidZipcode }}</div>
    </div>
    <div [formGroup]="passwordGroup">
      <div class="c-forms__group">
        <div class="c-forms__control is-required">
          <input [attr.disabled]="activityStatus" aria-label="Password" class="c-forms__input" formControlName="password1"  placeholder="Password" type="password"  autocomplete="new-password">
          <!-- name="new-password" -->
        </div>
      </div>
      <div *ngIf="passwordGroup.get('password1').invalid && (passwordGroup.get('password1').touched || submitted)"
        class="c-forms__message is-red"
        role="alert">
        <div *ngIf="passwordGroup.get('password1').errors.required">{{ messages.required }}</div>
        <div *ngIf="passwordGroup.get('password1').errors.minlength">{{ messages.password.minlength(passwordGroup.get('password1').errors.minlength.requiredLength) }}</div>
        <div *ngIf="passwordGroup.get('password1').errors.maxlength">{{ messages.password.minlength(passwordGroup.get('password1').errors.maxlength.requiredLength) }}</div>
      </div>
      <div class="c-forms__group">
        <div class="c-forms__control is-required">
          <input [attr.disabled]="activityStatus" aria-label="Password Confirm" class="c-forms__input" formControlName="password2" placeholder="Confirm Password" type="password"  autocomplete="new-password">
          <!-- name="new-password" -->
        </div>
      </div>
      <div *ngIf="passwordGroup.get('password2').invalid && (passwordGroup.get('password2').touched || submitted)"
        class="c-forms__message is-red"
        role="alert">
        <div *ngIf="passwordGroup.get('password2').errors.required">{{ messages.required }}</div>
      </div>
      <div *ngIf="passwordGroup.errors"
        class="c-forms__message is-red"
        role="alert">
        <div>Password does not match</div>
      </div>
    </div>
    <div class="c-forms__group">
      <ngx-recaptcha2
        formControlName="recaptcha"
        theme="light"
        type="normal"
        [siteKey]="reCaptchaToken"
        (success)="handleSuccess($event)">
      </ngx-recaptcha2>
      <div *ngIf="signUpForm.get('recaptcha').invalid && submitted"
        class="c-forms__message is-red is-bottom"
        role="alert">
        <div *ngIf="signUpForm.get('recaptcha').errors.required">{{ messages.required }}</div>
      </div>
    </div>
    <div class="c-forms__group">
      <label role="checkbox" class="c-checkbox" [attr.disabled]="activityStatus">
          <input aria-label="Receive Email?" formControlName="receiveEmail" type="checkbox"/>
          <span class="c-checkbox__icon">
              <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
          </span>
          <span class="c-checkbox__caption"><b>I love what you are doing.<br>Keep me inspired!</b></span>
      </label>
    </div>
    <div class="c-forms__group c-sign-up__submit">
      <button [attr.disabled]="activityStatus" [disabled]="isLoading" [ngClass]="{'is-loading': isLoading}" aria-label="Submit" class="c-btn c-btn_md c-btn_dark" type="submit">
        <span>Submit</span>
      </button>
    </div>
  </form>
  <div *ngSwitchCase="'pinControls'">
    <p class="c-sign-up__subttl">We have sent a verification code to your email</p>
    <div class="c-forms__group">
      <app-pincode></app-pincode>
    </div>
    <div class="c-forms__group c-sign-up__submit">
      <button (click)="confirmPin()" [disabled]="!pincodeValidation.validation || isLoading || activityStatus" [ngClass]="{'is-loading': isLoading}" aria-label="Submit" class="c-btn c-btn_md c-btn_dark" type="button">
        <span>Submit</span>
      </button>
    </div>
    <div class="c-sign-up__subLink">
      <a (click)="requestNewPin()" aria-label="Request new PIN" href="javascript:void(null)">Request new PIN</a>
    </div>
  </div>

</div>
