import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {QuestService} from '../../../_services/quest.service';

import {MembersService} from './members.service';
import {ModalService} from '../modal/modal.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromAuth from '../auth/store/auth.reducer';
import {QUILL_CONFIG} from '../../../app.config';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as Constants from '../../../app.constants';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {QuestDoer, QuestUserInfo, QuestLogic} from 'diemlife-commons/dist/diemlife-commons-model';
import {AppState} from 'src/app/_store/app.reducers';
import {QuestTeam} from 'diemlife-commons/dist/diemlife-commons-model';


@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.styl']
})
export class MembersComponent implements OnDestroy, OnChanges {
  @Input() mode: string;
  @Input() firstName: string;
  @Input() doers: QuestDoer[];
  @Input() status: string;
  @Input() userId: number;  // currentUserId
  @Input() questId: number;  // currentUserId
  @Input() allowEditQuest: boolean;
  @Input() showBacking: boolean;
  @Input() isCurrentUserQuestDoer: boolean = false;
  @Input() questTitle: string;
  @Input() teams: QuestTeam[];

  authState: Observable<fromAuth.State>;
  authStateSubscription: Subscription;
  userInfoState: Observable<fromProfile.State>;
  userInfoStateSubscription: Subscription;
  viewListDoers: QuestDoer[] = [];
  viewListDoersCutoff: QuestDoer[] = [];
  fullDoers: QuestDoer[] = [];
  doersCount: number;
  savedCount?: number;
  currentUser: QuestUserInfo;
  openedDialog: string;
  viewLimit = 10;
  moreBtnIsVisible = false;
  limit = 15;
  quillConfig = {...QUILL_CONFIG};
  messages = Constants.VALIDATION_MESSAGES;
  messageLimit = 3000;
  dialogInProgress = false;
  canSendBulkMessage = false;
  isLoading = false;
  messageAllForm: FormGroup;


  constructor(
    private modalService: ModalService,
    private questService: QuestService,
    private membersService: MembersService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private store: Store<AppState>
  ) {
    this.authState = this.store.select('auth');
    this.userInfoState = this.store.select('userInfo');
  }

  ngOnChanges() {
    this.getMembers();

    this.messageAllForm = new FormGroup({
      'subject': new FormControl(null, []),
      'messageToAll': new FormControl(null, [
        Validators.required,
        Validators.max(this.messageLimit)
      ]),
      'brandedMessage': new FormControl({value: true, disabled: false}, [])
    });
    this.destroySubscriptions();

    this.userInfoStateSubscription = this.userInfoState.pipe(take(1)).subscribe((currentUser: fromProfile.State) => {
      this.currentUser = currentUser;
      this.canSendBulkMessage = this.mode === 'doers' && this.currentUser.isUserBrand === 'Y' && this.allowEditQuest;
    });

  }
  ngOnDestroy() {
    this.modalService.close('membersModal');
    this.modalService.close('messageAllModal');
    this.modalService.remove('membersModal');
    this.modalService.remove('messageAllModal');
    this.destroySubscriptions();
  }
  private destroySubscriptions() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    if (this.userInfoStateSubscription) {
      this.userInfoStateSubscription.unsubscribe();
    }
  }

  private prepareListDoers(doers: QuestDoer[]): void {
    if (this.mode === 'doers') {
      this.viewListDoers = [...doers].filter((member: QuestDoer) => {
        if (member.memberStatus) {
          return member.memberStatus.length === 1 && member.memberStatus[0] === 'Interested' ? false : true;
        }
        return false;
      });
      this.doersCount = this.viewListDoers.length;
      this.savedCount = this.doersCount < this.doers.length ? this.doers.length - this.doersCount : 0;
      const end = this.viewListDoers.length > 11 ? 11 : this.viewListDoers.length;
      this.viewListDoersCutoff = this.viewListDoers.slice(0, end);
    } else {
      this.viewListDoers = doers;
      this.doersCount = doers.length;
      const end = this.viewListDoers.length > 11 ? 11 : this.viewListDoers.length;
      this.viewListDoersCutoff = this.viewListDoers.slice(0, end);
    }
  }
  private prepareDoers(doers: QuestDoer[]): QuestDoer[] {
    if (this.mode === 'doers') {
      doers.forEach((item: QuestDoer, index: number) => {
        item.memberStatus = item.memberStatus.sort((a, b) => {
          return Constants.MEMBER_STATUS_PRIORITY[a] - Constants.MEMBER_STATUS_PRIORITY[b];
        });
        doers[index].percentage = item.totalTasksCount ? item.completeTasksCount / item.totalTasksCount * 100 : 0;
      });
    }
    return doers;
  }

  onChangeViewLimit($event: { viewLimit: number, moreBtnIsVisible: boolean }) {
    this.viewLimit = $event.viewLimit;
    this.moreBtnIsVisible = $event.moreBtnIsVisible;
  }

  openMembersDialog() {
    this.isLoading = true;
    this.openedDialog = 'membersModal';
    setTimeout(() => {
      this.modalService.open('membersModal');
      this.isLoading = false;
    });
  }

  openMessageAllDialog() {
    this.isLoading = true;
    this.messageAllForm.patchValue({
      subject: null,
      messageToAll: null,
      brandedMessage: true
    });
    this.openedDialog = 'messageAllModal';
    setTimeout(() => {
      this.modalService.open('messageAllModal');
      this.isLoading = false;
    });
  }

  closeDialog() {
    this.openedDialog = null;
  }

  loadMore() {
    this.limit += 15;
  }

  sendMessageForDoers() {
    if (this.dialogInProgress || this.messageAllForm.invalid) {
      return;
    }
    this.dialogInProgress = true;

    const message = this.messageAllForm.get('messageToAll').value.trim();
    const subject = (this.messageAllForm.get('subject').value || '').trim() || null;
    const plain = !this.messageAllForm.get('brandedMessage').value;

    this.membersService.sendMessageAll(this.questId, message, subject, plain).subscribe(() => {
      this.dialogInProgress = false;
      this.snotifyService.success(
        'You have successfully sent a message to the members of this Quest.',
        null,
        this.snotifyConfigService.getConfig()
      );
      this.closeDialog();
    }, () => {
      this.dialogInProgress = false;
      this.snotifyService.error(
        'Oops, something went wrong, please try again',
        null,
        this.snotifyConfigService.getConfig()
      );
    });
  }

  private getMembers() {
    if (!this.userId || !this.questId && this.doers) {
      this.authThenPrepareDoers();
    } else {
      this.doers.length = 0;
      this.questService.getQuestMembers(this.questId, this.userId).subscribe((members: QuestDoer[]) => {
        this.doers.push(...members);

        if (this.currentUser && this.currentUser.id) {
          this.isCurrentUserQuestDoer = QuestLogic.checkIfCurrentUserQuestDoer(this.doers, this.currentUser.id);
        }
        this.authThenPrepareDoers();
      });
    }
  }

  authThenPrepareDoers() {
    this.authStateSubscription = this.authState.pipe(
      map((state: fromAuth.State) => state.authenticated),
      distinctUntilChanged()
    ).subscribe((authenticated: boolean) => {
      if (authenticated) {
        this.membersService.setDoersStatusRelativeToUser(this.doers).subscribe((doers: QuestDoer[]) => {
          this.fullDoers = this.prepareDoers([...doers]);
          this.prepareListDoers([...doers]);
        });
      } else {
        this.fullDoers = this.prepareDoers([...this.doers]);
        this.prepareListDoers([...this.doers]);
      }
    });
  }

  makeInt(n) {
    return parseInt(n);
  }
  getDoers() {
    return this.viewListDoers;
  }
}
