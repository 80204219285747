import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {ModalService} from '../modal/modal.service';
import {CropperService} from './cropper-dilog.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../_services/snotify-config.service';
import {ProfileService} from '../../pages/profile/profile.service';
// import {QuestGalleryImage, NewQuestGalleryPhoto} from '../quest/quest.type';
import {QuestService} from 'src/app/_services/quest.service';
// import { NgProgress } from 'ngx-progressbar';
import { QuestGalleryImage, QuestTask } from 'diemlife-commons/dist/diemlife-commons-model';
import { DlMilestonesService } from '../dl-milestones/dl-milestones.service';
import { AddImageToMilestonePayload } from '../dl-milestones/dl-milestones.typings';

@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.styl']
})
export class CropperDialogComponent implements OnInit {
  @Input() modalName?: string;
  @Input() questId?: number;
  @Input() profileEmail?: string;
  @Input() hideCaption?: boolean;
  @Input() selectedArea: string;
  @Input() payloadForSelectedArea: AddImageToMilestonePayload | any;
  @Output() imageAdded: EventEmitter<any> = new EventEmitter<any>();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  contentType: any = ''; 
  caption = '';
  isLoading = false;
  isUploading = false;
  imagePreview: any = '';
  MAX_FILE_SIZE = 15000000;

  constructor(
    private modalService: ModalService,
    private cropperService: CropperService,
    private notificationService: SnotifyService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private questService: QuestService,
    private profileService: ProfileService,
    private dlMilestonesService: DlMilestonesService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = true;
    this.isLoading = true;
    setTimeout(() => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (file.size > this.MAX_FILE_SIZE) {
          this.notificationService.warning('Image is too large, please try a smaller file', null, this.snotifyConfigService.getConfig());
          return;
        }

        this.cropperService.getImagePreview(file).subscribe(photo => {
          // TODO: why is this assuming a jpeg
          this.imagePreview = 'data:image/jpeg;base64,' + photo;
          this.croppedImage = photo;
          this.contentType = file.type;
          this.isLoading = false;
        }, (err) => {
          this.imageChangedEvent = false;
          this.isLoading = false;
          this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
        });
      }
    }, 1000);
  }

  // TODO we temporarily disabled the cropper for performance reasons
  /*
  imageCropped(event) {
    const partSeparator = ',';
    if (event.base64.includes(partSeparator)) {
      this.croppedImage = event.base64.split(partSeparator)[1];
    } else {
      this.croppedImage = event.base64;
    }
  }
  */

  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }
  closeDialog() {
    if (typeof this.modalName === 'string' && this.modalName !== 'cropper-modal') {
      this.modalService.close(this.modalName);
    } else {
      this.modalService.close('cropper-modal');
    }
  }
  submit() {
    if (this.croppedImage) {
      this.saveProfilePhoto({
        croppedImage: this.croppedImage,
        caption: this.caption,
        contentType: this.contentType
      });
    } else {
      this.snotifyService.error(
        'Oops, the image is not uploaded, please try again.',
        null,
        this.snotifyConfigService.getConfig()
      );
    }
  }
  saveProfilePhoto(newImage) {
    // this.ngProgress.start();
    this.isUploading = true;

    let payload: any;
    switch (this.selectedArea) {
      case 'profilePictureURL': // update profile avatar
        payload = {
          email: this.profileEmail,
          profPic: newImage.croppedImage,
          contentType: newImage.contentType
        };
        this.profileService.uploadProfilePictures(payload).subscribe((res: {profilePictureURL: string, coverPictureURL: string}) => {
          this.imageAdded.emit({
            croppedImage: res.profilePictureURL,
            selectedArea: 'profilePictureURL'
          });
          this.finishingUpload();
        }, () => {
          this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
        });
        break;
      case 'coverPictureURL': // update profile cover photo
        payload = {
          email: this.profileEmail,
          coverPic: newImage.croppedImage,
          contentType: newImage.contentType
        };
        this.profileService.uploadProfilePictures(payload).subscribe((res: {profilePictureURL: string, coverPictureURL: string}) => {
          this.imageAdded.emit({
            croppedImage: res.coverPictureURL,
            selectedArea: 'coverPictureURL'
          });
          this.finishingUpload();
        }, () => {
          this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
        });
        break;
      case 'questGalleryPhoto': // add photo to the quest gallery component
        payload = {
          questId: this.questId,
          questImage: newImage.croppedImage,
          caption: newImage.caption,
          contentType: newImage.contentType,
        };
        this.questService.addQuestGalleryPhoto(payload).subscribe((newImg: QuestGalleryImage) => {
          this.imageAdded.emit({
            croppedImage: newImg,
            selectedArea: 'questGalleryPhoto'
          });
          this.finishingUpload();
        }, () => {
          this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
        });
        break;
      case 'milestoneImage': // add photo to the milestone component
        if (this.payloadForSelectedArea.id) {
          payload = {
            taskId: this.payloadForSelectedArea.id,
            taskImage: newImage.croppedImage,
            contentType: newImage.contentType
          };
          this.dlMilestonesService.addImageToMilestone(payload).subscribe((imageUrl: string) => {

            this.imageAdded.emit(imageUrl);
            this.finishingUpload();
          }, (err) => {
            this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
          });
        } else {
          this.imageAdded.emit(newImage.croppedImage);
          this.finishingUpload();
        }
        break;
      case 'newsfeedImage': // add photo to the newsfeed component
        this.imageAdded.emit(newImage.croppedImage);
        this.finishingUpload(true);
        break;
    }
  }
  finishingUpload(hideNotify?: boolean) {
    setTimeout(() => {
      this.isUploading = false;
      this.closeDialog();
      if (!hideNotify) {
        this.snotifyService.success(`Photo successfully changed.`, null, this.snotifyConfigService.getConfig());
      }
    }, 300);
  }
  private detectChanges() {
    // tslint:disable-next-line:no-string-literal
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

}
