<div class="c-dl-milestone-preview__container"  #previewDropdown=dropdown dropdown>
	<button class="c-dl-milestone-preview__header h-btn-reset" type="button" (click)="openMenu()">
		<span class="c-dl-milestone-preview__oval e-oval">
			<svg class="icon icon-dl-upload"><use xlink:href="#icon-dl-upload"></use></svg>
		</span>
	</button>
	<div class="c-dl-milestone-preview__body" dropdownMenu>
		<button class="c-dl-milestone-preview__close h-btn-reset" (click)="close()" type="button">
			<svg class="icon icon-dl-close"><use xlink:href="#icon-dl-close"></use></svg>
		</button>
		<div class="c-dl-milestone-preview__inner">
			<div class="c-n-form__group" [formGroup]="linkForm">
				<div class="c-dl-milestone-preview__legend c-n-form__legend">
					<svg class="icon icon-link"><use xlink:href="#icon-link"></use></svg>
					<span>Add link:</span>
				</div>
				<div class="c-dl-milestone-preview__control c-n-form__control">
					<input class="c-dl-milestone-preview__input c-n-form__input" formControlName="url" type="text" name="url" placeholder="https://example.com" aria-label="Enter url">
					<button *ngIf="linkForm.valid" class="c-dl-milestone-preview__control__trigger e-oval h-btn-reset" type="button" (click)="addLinkToMilestone()">
						<svg class="icon icon-arrow_right"><use xlink:href="#icon-arrow_right"></use></svg>
					</button>
				</div>
			</div>
			<div class="c-n-form__group">
				<div class="c-dl-milestone-preview__legend c-n-form__legend">Add Image:</div>
				<button class="c-dl-milestone-preview__icon-control e-btn e-btn_sm" type="button" (click)="openImageDialog()">
					<svg class="icon icon-camera"><use xlink:href="#icon-camera"></use></svg>
				</button>
			</div>
			<div class="c-n-form__group">
				<div class="c-dl-milestone-preview__legend c-n-form__legend">Add Quest Link:</div>
				<button class="c-dl-milestone-preview__icon-control e-btn e-btn_sm" type="button" (click)="openLinkQuestDialog()">
					<svg class="icon icon-search"><use xlink:href="#icon-search"></use></svg>
				</button>
			</div>
			<div class="c-n-form__group" [formGroup]="videoForm">
				<div class="c-dl-milestone-preview__legend c-n-form__legend">
					<svg class="icon icon-youtube"><use xlink:href="#icon-youtube"></use></svg>
					<span>Add Youtube link:</span>
				</div>
				<div class="c-dl-milestone-preview__control c-n-form__control">
					<input class="c-dl-milestone-preview__input c-n-form__input" formControlName="videoUrl" type="text" name="videoUrl" placeholder="https://youtu.be/example" aria-label="Enter video url">
					<button *ngIf="videoForm.valid" class="c-dl-milestone-preview__control__trigger e-oval h-btn-reset" type="button" (click)="addVideoToMilestone()">
						<svg class="icon icon-arrow_right"><use xlink:href="#icon-arrow_right"></use></svg>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal id="cropper-milestone-dialog" *ngIf="openedModal === 'cropper-milestone-dialog'">
	<h3 class="modal-title">Upload a Photo:</h3>
	<app-cropper-dialog
		[selectedArea]="'milestoneImage'"
		[payloadForSelectedArea]="{index: index, id: id}"
		[hideCaption]="true"
		(imageAdded)="addPhotoToMilestone($event)">
	</app-cropper-dialog>
</app-modal>

<app-modal id="link-quest-dialog"
           class="c-dl-milestone-preview__link-quest-dialog"
           *ngIf="openedModal === 'link-quest-dialog'">
	<h3 class="modal-title">Link a Quest:</h3>
  <app-dl-search class="c-dl-search"
                 [searchFor]="'Quests'"
                 [isOpened]="true"
                 [focusOnInit]="true"
                 (resultSelected)="onLinkedQuestSelected($event)"></app-dl-search>
</app-modal>
