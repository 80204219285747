import {Injectable} from '@angular/core';
import {SnotifyPosition, SnotifyService, SnotifyToastConfig} from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class SnotifyConfigService {

  constructor(private snotifyService: SnotifyService) {
  }

  public getConfig(args?, globalArgs?): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: true,
        maxAtPosition: 6,
        maxOnScreen: 3,
        ...globalArgs
      }
    });
    return {
      backdrop: -1,
      position: SnotifyPosition.centerTop,
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      bodyMaxLength: 500,
      titleMaxLength: 500,
      ...args
    };
  }

}
