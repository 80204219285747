<div class="c-team-list" [ngClass]="{'c-team-list--one-liner': oneLiner}">
  <app-team-list-item class="c-team-list__item-container"
                      *ngFor="let team of teams"
                      (click)="setSelectedTeam(team)"
                      [ngClass]="
                          {
                            'c-team-list__item-container--selected': selectionAllowed && selectedTeam?.teamId === team.teamId,
                            'c-team-list__item-container--clickable': selectionAllowed && selectedTeam?.teamId !== team.teamId
                          }"
                      [team]="team"
                      [selectable]="!oneLiner"
                      [showMembersCount]="showMembersCount"
                      [showBackedAmount]="showBackedAmount"></app-team-list-item>
</div>
<div class="c-team-list__name-hint" *ngIf="!oneLiner">
  <span>{{selectedTeam?.teamName || 'Please select a team'}}</span>
</div>
<button class="c-btn c-btn_md c-btn_dark c-btn_full"
        type="button"
        *ngIf="selectionAllowed"
        (click)="confirmSelectedTeam()"
        [disabled]="progress || !selectedTeam">
  <span>Select team</span>
</button>
