import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {mergeMap, switchMap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import * as RecentTransactionsActions from './recent-transactions.actions';
import {Transaction} from 'diemlife-commons/dist/diemlife-commons-model';
import {ReaquestHeadersService} from '../../../../_services/requestHeaders.service';

@Injectable()
export class RecentTransactionsEffects {

  @Effect()
  profileRecentTransactions = this.actions$
    .pipe(
      ofType(RecentTransactionsActions.TRY_GET_RECENT_TRANSACTIONS),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.post(
          environment.target + environment.context + '/accounts/recenttransactions',
          { all: true },
          { headers: headers, withCredentials: true }
        );
      }),
      mergeMap((data: Transaction[]) => {
        return [
          {
            type: RecentTransactionsActions.GET_RECENT_TRANSACTIONS,
            payload: data
          }
        ];
      })
    );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}
}
