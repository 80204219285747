import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromAuth from '../../store/auth.reducer';
import * as AuthActions from '../../store/auth.actions';
import { AppState } from 'src/app/_store/app.reducers';

export interface PincodeValidation {
  validation: boolean;
  formValue: string;
}

@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.styl']
})
export class PincodeComponent implements OnInit {
  @ViewChild('control1', {static: false}) control1: ElementRef;
  @ViewChild('control2', {static: false}) control2: ElementRef;
  @ViewChild('control3', {static: false}) control3: ElementRef;
  @ViewChild('control4', {static: false}) control4: ElementRef;

  focusInput = 1;
  pincodeForm: FormGroup;
  keyPressed = false;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {
    this.pincodeForm = this.fb.group({
      '1': [null, [Validators.required, Validators.minLength(0), Validators.maxLength(9)]],
      '2': [null, [Validators.required, Validators.minLength(0), Validators.maxLength(9)]],
      '3': [null, [Validators.required, Validators.minLength(0), Validators.maxLength(9)]],
      '4': [null, [Validators.required, Validators.minLength(0), Validators.maxLength(9)]],
    });
  }
  onKeyUp(event, count) {
    if (!this.keyPressed) {
      this.keyPressed = true;
      // console.log('onKeyUp', count);
      this.focusInput = count;
      // const next = this[`control${count + 1}`];
      let currentValue = event.target.value;

      if (currentValue.length > 0) {
        currentValue = event.target.value = currentValue.substr(currentValue.length - 1);
      }
      if (event.keyCode > 47 && event.keyCode < 58) { // 0,1,2,3,4,5,6,7,8,9
        if (this.focusInput < 4) {
          this.focusInput += 1;
          this.addFocus();
        } else {
          const next = this[`control${1}`];
          if (next.nativeElement.value.length === 0) {
            this.focusInput = 1;
            this.addFocus();
          }
        }
      }
      if (event.keyCode === 8) { // backspace
        if (currentValue.length === 0 && this.focusInput > 1) {
          this.focusInput -= 1;
          this.addFocus();
        }
      }
      // tslint:disable-next-line:max-line-length
      const numbers = '' + this.pincodeForm.value['1'] + this.pincodeForm.value['2'] + this.pincodeForm.value['3'] + this.pincodeForm.value['4'];
      this.store.dispatch(new AuthActions.SetPinInfo({
        validation: this.pincodeForm.valid,
        formValue: numbers
      }));
    }
  }
  onKeyDown() {
    this.keyPressed = false;
  }
  onInput(event) {
    let currentValue = event.target.value;
    if (currentValue.length > 0) {
      currentValue = event.target.value = currentValue.substr(currentValue.length - 1);
    }
  }
  addFocus() {
    const control = this[`control${this.focusInput}`];
    if (control) {
      this[`control${this.focusInput}`].nativeElement.focus();
    }
  }
  ngOnInit() {
  }
  onSubmit() {
  }

}
