import {Component, OnDestroy, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {WindowRef} from 'src/app/_global/window-ref.module';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.styl']
})
export class FaqComponent implements OnInit, OnDestroy {
  stickyNav = false;
  webViewMode = false;
  navPlaceholderHeight;
  routerSubscription: Subscription;
  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(
    private winRef: WindowRef,
    private meta: Meta,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.webViewMode = this.route.snapshot.queryParams['_web-view'] === 'true';
      }
    });
  }

  ngOnInit() {
    this.setMetaTags();
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  goToPage(link, mode) {
    this.winRef.nativeWindow.nsWebViewInterface.emit('openLinkFromWebView', {link, mode});
  }

  onStickyChange($event) {
    this.stickyNav = $event.state;
    this.navPlaceholderHeight = $event.height;
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | FAQ');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | FAQ'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({
      name: 'og:description',
      content: 'Frequently asked Questions to help you solve any issues you might have.'
    });
  }

  scrollTop(scrollDuration) {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval;
    // console.log(window.scrollY);
    scrollInterval = setInterval(function () {
      if (window.scrollY !== 0) {
        scrollCount = scrollCount + 1;
        scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
        window.scrollTo(0, (scrollHeight - scrollMargin));
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  }
}
