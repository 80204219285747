import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import 'rxjs-compat/add/observable/of';
import {ReaquestHeadersService} from '../../../_services/requestHeaders.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CropperService {

  constructor(private http: HttpClient, private requestHeadersService: ReaquestHeadersService) {
  }


  readURL(event, maxWidth, maxHeight): Observable<number> {
    if (event.target.files && event.target.files.length) {
      return new Observable(observer => {
        const reader = new FileReader();
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = (e: any) => {
          this.getImageQuality(reader.result, maxWidth, maxHeight).pipe(take(1)).subscribe((quality: number) => {
            observer.next(quality);
          });
        };
      });
    }
  }

  getImageQuality(imgUrl, maxWidth, maxHeight): Observable<number> {
    return new Observable(observer => {
      const img = document.createElement('img');
      img.setAttribute('src', '');
      img.setAttribute('alt', '');
      const canvas = document.createElement('canvas');

      // create an off-screen canvas
      img.setAttribute('src', imgUrl);
      if (imgUrl) {
        img.onload = () => {
          const originWidth = img.width;
          const originHeight = img.height;

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          if (originWidth !== width) {
            observer.next(Math.floor(width / (originWidth / 100)));
          } else if (originHeight !== height) {
            observer.next(Math.floor(height / (originHeight / 100)));
          } else {
            observer.next(1);
          }
          observer.complete();
        };
      }
    });
  }

  getImagePreview(photo: File) {
    if (photo) {
      const formData: FormData = new FormData();
      formData.append('questImage', photo, photo.name);
      const headers = this.requestHeadersService.getHeaders();
      return this.http.post(
        environment.target + environment.context + '/image/preview',
        formData,
        {headers: headers}
      );
    }
  }

  // resizeImage (imgUrl, maxWidth, maxHeight) {
  //   return new Observable(observer => {
  //     const img = document.createElement('img'); img.setAttribute('src', ''); img.setAttribute('alt', '');
  //     const canvas = document.createElement('canvas');

  //     // create an off-screen canvas
  //     img.setAttribute('src', imgUrl);
  //     if (imgUrl) {
  //       img.onload = () => {
  //         let width = img.width;
  //         let height = img.height;
  //         console.log(width);
  //         console.log(height);
  //         if (width > height) {
  //           if (width > maxWidth) {
  //             height *= maxWidth / width;
  //             width = maxWidth;
  //           }
  //         } else if (height > maxHeight) {
  //           width *= maxHeight / height;
  //           height = maxHeight;
  //         }

  //         console.log('after:');
  //         console.log(width);
  //         console.log(height);
  //         canvas.width = width;
  //         canvas.height = height;

  //         const context = canvas.getContext('2d');
  //         context.drawImage(img, 0, 0, width, height);
  //         observer.next(canvas.toDataURL());
  //         observer.complete();
  //       };
  //     }
  //   });
  // }
}
