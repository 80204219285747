<div class="c-container c-container_md">

  <br>
  <h1>DIEMlife Privacy Policy</h1>

  <div id='ppBody'>
    <!--<div class='ppConsistencies'>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Information Collection</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Information Usage</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Information Protection</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Cookie Usage</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">3rd Party Disclosure</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">3rd Party Links</div>-->
    <!--</div>-->
    <!--<div class='col-2'></div>-->
    <!--</div>-->
    <!--<div style='clear:both;height:10px;'></div>-->
    <!--<div class='ppConsistencies'>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 gen-text-center">Google AdSense</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 gen-text-center">-->
    <!--Fair Information Practices-->
    <!--<div class="col-8 gen-text-left gen-xs-text-center"-->
    <!--style="font-size:12px;position:relative;left:20px;">Fair information<br> Practices-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 gen-text-center coppa-pad">-->
    <!--COPPA-->

    <!--</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 quick4 gen-text-center caloppa-pad">-->
    <!--CalOPPA-->

    <!--</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links2 gen-text-center">Our Contact Information<br></div>-->
    <!--</div>-->
    <!--</div>-->
    <div style='clear:both;height:10px;'></div>
    <div class='innerText'>Welcome to the web site or mobile application (as the case may be) (collectively, the
      "Services") of DIEMlife, Inc. (including its subsidiaries, affiliates, agents, and service providers,
      collectively, "DIEMlife", "we", "us" and/or "our"). Through the Services, DIEMlife provides a simple and
      quick means for registered users who are event organizers, planners and charitable organizations
      ("Organizers") to create event registration, speaker profile, organizer profile, fundraising and other
      webpages related to their events, to promote those pages and events to visitors or browsers of the Services
      and to sell tickets and registrations (or issue free registrations), solicit donations and sell merchandise
      related to those events to users who wish to make purchases, registrations or donations from or to such
      events (including to free events) ("Attendees"). We refer to Organizers, Attendees and other visitors and
      browsers of the Services collectively as "Users" or "you". This privacy policy has been compiled to better
      serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used
      online. PII, as described in US privacy law and information security, is information that can be used on its
      own or with other information to identify, contact, or locate a single person, or to identify an individual
      in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use,
      protect or otherwise handle your Personally Identifiable Information in accordance with our website.<br>
    </div>
    <span id='infoCo'></span><br>
    <div class='grayText'><strong>What personal information do we collect from the people that visit our blog,
      website or app?</strong></div>
    <br/>
    <div class='innerText'>These days, whenever you use a website, mobile application, or other internet service,
      there is certain information that almost always gets created and recorded automatically. The same is true when
      you use our products. Here are some of the types of information we collect:
    </div>
    <br>
    <div class='innerText'><strong>Personal Information.</strong> When ordering or registering on our
      site, as appropriate, you may be asked to enter your name, email address, mailing address, credit card information,
      general interests or other details to help you with your experience.
    </div>
    <br>
    <div class='innerText'><strong>Log data.</strong> When you use DIEMlife, our servers automatically record information ("log data"), including information that your browser sends whenever you visit a website or your mobile app sends when you are using it. This log data may include your Internet Protocol address, the address of the web pages you visited that had DIEMlife features, browser type and settings, the date and time of your request, how you used DIEMlife, and cookie data.
    </div>
    <br>
    <div class='innerText'><strong>Cookie data.</strong> Depending on how you're accessing our products, we may use "cookies" (small text files sent by your computer each time you visit our website, unique to your DIEMlife account or your browser) or similar technologies to record log data. When we use cookies, we may use "session" cookies (that last until you close your browser) or "persistent" cookies (that last until you or your browser delete them). For example, we may use cookies to store your language preferences or other DIEMlife settings so you don't have to set them up every time you visit DIEMlife. Some of the cookies we use are associated with your DIEMlife account (including personal information about you, such as the email address you gave us), and other cookies are not.
    </div>
    <br>
    <div class='innerText'><strong>Device information.</strong> In addition to log data, we may also collect information about the device you're using DIEMlife on, including what type of device it is, what operating system you're using, device settings, unique device identifiers, and crash data. Whether we collect some or all of this information often depends on what type of device you're using and its settings. For example, different types of information are available depending on whether you're using a Mac or a PC, or an iPhone or an Android phone. To learn more about what information your device makes available to us, please also check the policies of your device manufacturer or software provider.
    </div>
    <br>
    <div class='grayText'><strong>When do we collect information?</strong></div>
    <br/>
    <div class='innerText'>We collect information from you when you register on our site, place an order, subscribe
      to a newsletter, fill out a form or enter information on our site.
    </div>
    <span id='infoUs'></span><br>
    <div class='grayText'><strong>How do we use your information? </strong></div>
    <br/>
    <div class='innerText'> We may use the information we collect from you when you register, make a purchase, sign
      up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain
      other site features in the following ways:<br><br></div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> To personalize your experience and
      to allow us to deliver the type of content and product offerings in which you are most interested.
    </div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> To improve our website in order to
      better serve you.
    </div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> To quickly process your
      transactions.
    </div>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> To send periodic emails regarding
      your order or other products and services.
    </div>
    <span id='infoPro'></span><br>
    <div class='grayText'><strong>How do we protect your information?</strong></div>
    <br/>
    <div class='innerText'>Our website is scanned on a regular basis for security holes and known vulnerabilities in
      order to make your visit to our site as safe as possible.<br><br></div>
    <div class='innerText'>We use regular Malware Scanning.<br><br></div>
    <div class='innerText'>Your personal information is contained behind secured networks and is only accessible by
      a limited number of persons who have special access rights to such systems, and are required to keep the
      information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure
      Socket Layer (SSL) technology.
    </div>
    <br>
    <div class='innerText'>We implement a variety of security measures when a user places an order enters, submits,
      or accesses their information to maintain the safety of your personal information.
    </div>
    <br>
    <div class='innerText'>All transactions are processed through a gateway provider and are not stored or processed
      on our servers.
    </div>
    <br><span id='trDi'></span><br>
    <div class='grayText'><strong>Third-party disclosure</strong></div>
    <br/>
    <div class='innerText'>We do not sell, trade, or otherwise transfer to outside parties your Personally
      Identifiable Information unless we provide users with advance notice. This does not include website hosting
      partners and other parties who assist us in operating our website, conducting our business, or serving our
      users, so long as those parties agree to keep this information confidential. We may also release information
      when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or
      others' rights, property or safety. <br><br> However, non-personally identifiable visitor information may be
      provided to other parties for marketing, advertising, or other uses.
    </div>
    <span id='trLi'></span><br>
    <div class='grayText'><strong>Third-party links</strong></div>
    <br/>
    <div class='innerText'>Occasionally, at our discretion, we may include or offer third-party products or services
      on our website. These third-party sites have separate and independent privacy policies. We therefore have no
      responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to
      protect the integrity of our site and welcome any feedback about these sites.
    </div>
    <span id='gooAd'></span><br>
    <div class='blueText'><strong>Google</strong></div>
    <br/>
    <div class='innerText'>Google's advertising requirements can be summed up by Google's Advertising Principles.
      They are put in place to provide a positive experience for users.
      https://support.google.com/adwordspolicy/answer/1316548?hl=en <br><br></div>
    <div class='innerText'>We use Google AdSense Advertising on our website.</div>
    <div class='innerText'><br>Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use
      of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites
      on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content
      Network privacy policy.<br></div>
    <div class='innerText'><br><strong>We have implemented the following:</strong></div>
    <br/>
    <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Demographics and Interests
      Reporting
    </div>
    <br>
    <div class='innerText'>We, along with third-party vendors such as Google use first-party cookies (such as the
      Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party
      identifiers together
      <div class='innerText'>compile data regarding user interactions as they relate to our website</div>
      <div class='innerText'><br><strong>Opting out:</strong><br><br/>
        Users can set preferences for how Google advertises to you using the Google Ad Settings page.
        Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using
        the Google Analytics Opt Out Browser add on.
      </div>
      <span id='calOppa'></span><br>
      <div class='blueText'><strong>California Online Privacy Protection Act</strong></div>
      <br/>
      <div class='innerText'>CalOPPA is the first state law in the nation to require commercial websites and
        online services to post a privacy policy. The law's reach stretches well beyond California to require
        any person or company in the United States (and conceivably the world) that operates websites collecting
        Personally Identifiable Information from California consumers to post a conspicuous privacy policy on
        its website stating exactly the information being collected and those individuals or companies with whom
        it is being shared. - See more at:
        http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf<br></div>
      <div class='innerText'><br><strong>According to CalOPPA, we agree to the following:</strong><br></div>
      <br/>
      <div class='innerText'>Users can visit our site anonymously.</div>
      <div class='innerText'>Once this privacy policy is created, we will add a link to it on our home page or as
        a minimum, on the first significant page after entering our website.<br></div>
      <div class='innerText'>Our Privacy Policy link includes the word 'Privacy' and can easily be found on the
        page specified above.
      </div>
      <div class='innerText'><br>You will be notified of any Privacy Policy changes:</div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> On our Privacy Policy Page<br>
      </div>
      <div class='innerText'>Can change your personal information:</div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> By logging in to your account
      </div>
      <div class='innerText'><br><strong>How does our site handle Do Not Track signals?</strong><br></div>
      <div class='innerText'>We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising
        when a Do Not Track (DNT) browser mechanism is in place.
      </div>
      <span id='coppAct'></span><br>
      <div class='blueText'><strong>COPPA (Children Online Privacy Protection Act)</strong></div>
      <br/>
      <div class='innerText'>When it comes to the collection of personal information from children under the age
        of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The
        Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which
        spells out what operators of websites and online services must do to protect children's privacy and
        safety online.<br><br></div>
      <div class='innerText'>We do not specifically market to children under the age of 13 years old.</div>
      <span id='ftcFip'></span><br>
      <div class='blueText'><strong>Fair Information Practices</strong></div>
      <br/>
      <div class='innerText'>The Fair Information Practices Principles form the backbone of privacy law in the
        United States and the concepts they include have played a significant role in the development of data
        protection laws around the globe. Understanding the Fair Information Practice Principles and how they
        should be implemented is critical to comply with the various privacy laws that protect personal
        information.<br><br></div>
      <div class='innerText'><strong>In order to be in line with Fair Information Practices we will take the
        following responsive action, should a data breach occur:</strong></div>
      <br/>
      <div class='innerText'>We will notify you via email</div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Within 1 business day</div>
      <div class='innerText'>We will notify the users via in-site notification</div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Within 1 business day</div>
      <div class='innerText'><br>We also agree to the Individual Redress Principle which requires that individuals
        have the right to legally pursue enforceable rights against data collectors and processors who fail to
        adhere to the law. This principle requires not only that individuals have enforceable rights against
        data users, but also that individuals have recourse to courts or government agencies to investigate
        and/or prosecute non-compliance by data processors.
      </div>
      <span id='canSpam'></span><br>
      <div class='blueText'><strong>CAN SPAM Act</strong></div>
      <br/>
      <div class='innerText'>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes
        requirements for commercial messages, gives recipients the right to have emails stopped from being sent
        to them, and spells out tough penalties for violations.<br><br></div>
      <div class='innerText'><strong>We collect your email address in order to:</strong></div>
      <br/>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Send information, respond to
        inquiries, and/or other requests or questions
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Process orders and to send
        information and updates pertaining to orders.
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Send you additional
        information related to your product and/or service
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Market to our mailing list or
        continue to send emails to our clients after the original transaction has occurred.
      </div>
      <div class='innerText'><br><strong>To be in accordance with CANSPAM, we agree to the following:</strong>
      </div>
      <br/>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Not use false or misleading
        subjects or email addresses.
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Identify the message as an
        advertisement in some reasonable way.
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Include the physical address
        of our business or site headquarters.
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Monitor third-party email
        marketing services for compliance, if one is used.
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Honor opt-out/unsubscribe
        requests quickly.
      </div>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Allow users to unsubscribe by
        using the link at the bottom of each email.
      </div>
      <div class='innerText'><strong><br>If at any time you would like to unsubscribe from receiving future
        emails, you can:</strong></div>
      <br/>
      <div class='innerText'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>&bull;</strong> Follow the instructions at the
        bottom of each email.
      </div>
      and we will promptly remove you from <strong>ALL</strong> correspondence.
    </div>
    <br><span id='ourCon'></span><br>
    <div class='blueText'><strong>Contacting Us</strong></div>
    <br/>
    <div class='innerText'>If there are any questions regarding this privacy policy, you may contact us using the
      information below.<br><br></div>
    <div class='innerText'>DIEMlife, Inc.</div>
    <div class='innerText'>85 East 10th Street Ste. M</div>
    New York, NY 10003
    <div class='innerText'>United States</div>
    <div class='innerText'>diemlife@diemlife.com</div>
    <div class='innerText'><br>Last Edited on 2017-11-12</div>
  </div>


</div>
