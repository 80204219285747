<form name="soloForm" class="c-individual-form" [formGroup]="soloFormGroup" (ngSubmit)="onSubmitSoloForm()">
  <fieldset name="campaignNameFields"
            class="c-individual-form__fields c-forms__group"
            [disabled]="progress"
            *ngIf="fundraisingEnabled">

    <app-ui-control class="c-n-form__group"
      legend="Campaign name:"
      [control]="soloFormGroup.get('campaignName')"
      [formIsSubmitted]="formIsSubmitted">
      <input type="text"
             class="c-n-form__input"
             id="solo-campaign-name"
             placeholder="Heroic campaign"
             name="campaignName"
             formControlName="campaignName"/>
    </app-ui-control>

  </fieldset>
  <fieldset name="soloFundraisingFields"
            class="c-individual-form__fields c-forms__group"
            [disabled]="progress"
            *ngIf="fundraisingEnabled">

    <app-ui-control class="c-n-form__group"
      legend="Fundraising amount:"
      [control]="soloFormGroup.get('targetAmount')"
      [controlClass]="{'is-required': true}"
      [formIsSubmitted]="formIsSubmitted">
      <input type="text"
             class="c-n-form__input"
             id="solo-form-amount"
             placeholder="$0"
             prefix="$"
             mask="0000000"
             [validation]="false"
             name="targetAmount"
             formControlName="targetAmount"/>
    </app-ui-control>

  </fieldset>
  <button class="c-btn c-btn_md c-btn_dark c-btn_full"
          type="submit"
          [disabled]="progress || soloFormGroup.invalid">
    <span *ngIf="fundraisingEnabled">Start fundraiser</span>
    <span *ngIf="!fundraisingEnabled">Start Quest</span>
  </button>
</form>
