<div class="c-share-widjet__tabs">
  <button class="c-share-widjet__tabs__item h-btn-reset" (click)="shareViaFacebook()" type="button">
    <div class="c-share-widjet__icon e-oval">
      <svg class="icon icon-facebook"><use xlink:href="#icon-facebook"></use></svg>
    </div>
    <span class="c-share-widjet__tabs__item__ttl">Facebook</span>
  </button>
  <a class="c-share-widjet__tabs__item twitter-share-button" attr.href="{{  'https://twitter.com/intent/tweet?&url=' + shareUrl}}">
    <div class="c-share-widjet__icon e-oval">
      <svg class="icon icon-twitter"><use xlink:href="#icon-twitter"></use></svg>
    </div>
    <span class="c-share-widjet__tabs__item__ttl">Twitter</span>
  </a>
  <button class="c-share-widjet__tabs__item h-btn-reset" [ngClass]="{'is-active': openedTab === 'email'}" (click)="openTab('email')">
    <div class="c-share-widjet__icon e-oval">
      <svg class="icon icon-envelope_alt"><use xlink:href="#icon-envelope_alt"></use></svg>
    </div>
    <span class="c-share-widjet__tabs__item__ttl">Email</span>
  </button>
  <button class="c-share-widjet__tabs__item h-btn-reset" [ngClass]="{'is-active': openedTab === 'copy'}" (click)="openTab('copy')">
    <div class="c-share-widjet__icon e-oval">
      <svg class="icon icon-link"><use xlink:href="#icon-link"></use></svg>
    </div>
    <span class="c-share-widjet__tabs__item__ttl">Copy</span>
  </button>
</div>
<div class="c-share-widjet__content">
  <div class="c-share-widjet__inner" [ngClass]="{'is-active': openedTab === 'copy'}">
    <div class="c-share-widjet__link c-n-form__input is-focus">
      <input class="c-share-widjet__link__input" type="text" id="shareUrl" [attr.value]="shareUrl">
      <button class="c-share-widjet__link__trigger h-btn-reset" #clipboardTrigger data-clipboard-target="#shareUrl">
        <div class="c-share-widjet__link__icon e-oval">
          <svg class="icon icon-copy"><use xlink:href="#icon-copy"></use></svg>
        </div>
        <span class="c-share-widjet__link__ttl">copy</span>
      </button>
    </div>
  </div>
  <div class="c-share-widjet__inner" [ngClass]="{'is-active': openedTab === 'email'}">
    <app-share-email [questId]="questId"></app-share-email>
  </div>
  
</div>