import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { WindowRef } from 'src/app/_global/window-ref.module';

@Component({
  selector: 'app-legal-community',
  templateUrl: './legal-community.component.html',
  styleUrls: ['./legal-community.component.styl']
})
export class LegalCommunityComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription;
  webViewMode = false;

  constructor(
    public winRef: WindowRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.webViewMode = this.route.snapshot.queryParams['_web-view'] === 'true';
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  goToPage(link, mode) {
    this.winRef.nativeWindow.nsWebViewInterface.emit('openLinkFromWebView', {link, mode});
  }

}
