import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  Injector,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {LngLatBounds, Map, Marker, MarkerOptions, NavigationControl, Popup, PopupOptions} from 'mapbox-gl';
import * as fromDlMilestones from '../../dl-milestones/store/dl-milestones.reducer';
import {environment} from '../../../../../environments/environment';
import {
  DirectionsType,
  MapBounds,
  MapboxService,
  MapData,
  MapLocation,
  MapView,
  MapZoom,
  QuestTask,
  QuestMapData, 
  QuestTeam,
  QuestDoer
} from 'diemlife-commons/dist/diemlife-commons-model';
import {MapPopoverComponent} from '../../map-popover/map-popover.component';
import {QuestService} from '../../../../_services/quest.service';
import { resolve } from 'url';

const DONATE_PIN_URL = "https://diemlife-assets.s3.amazonaws.com/map-pins/pin-donation-green.png";

@Component({
  selector: 'app-quest-map',
  templateUrl: './quest-map.component.html',
  styleUrls: ['./quest-map.component.styl']
})
export class QuestMapComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  data: fromDlMilestones.State;
  @Input()
  participantUserIds: any[] = [];
  @Input()
  directionsType?: string;
  @Input() questId: number;
  @Input() userId: number;
  @Input() questTitle: string;
  @Input() locationName: string;
  @Input() teams: QuestTeam[] = [];
  doers: QuestDoer[] = [];
  viewListDoers: QuestDoer[] = [];

  participantMaps: any[] = [];


  @Output()
  private onMilestoneSelected: EventEmitter<number>;
  @ViewChildren('markerElement')
  private markerElements: QueryList<ElementRef>;

  questMapData: QuestMapData[] = [];
  participantMapsLoaded = false;

  private map: Map;
  private mapData: MilestonesMapData;
  private mapLoaded: boolean;
  private mapPopupOpenCount: number;

  constructor(
      private mapboxService: MapboxService,
      private componentFactoryResolver: ComponentFactoryResolver,
      private injector: Injector,
      private viewContainerRef: ViewContainerRef,
      private questService: QuestService
  ) {
    this.mapData = new MilestonesMapData();
    this.mapLoaded = false;
    this.mapPopupOpenCount = 0;
    this.onMilestoneSelected = new EventEmitter<number>();
  }

  async ngOnInit() {
    this.getMembers();
    this.reloadMilestonesData();
    this.map = new Map({
      accessToken: environment.mapBoxKey,
      antialias: true,
      container: 'milestones-map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 13,
      center: [this.mapData.center.longitude, this.mapData.center.latitude],
    });
    this.map.addControl(new NavigationControl({showCompass: false}));
    this.map.on('load', () => {
      let previousMarker = null;
      this.mapData.markers.forEach(marker => {
        marker.addTo(this.map);
        if (this.directionsType && previousMarker) {
          this.mapData.map.connect({
              longitude: previousMarker.getLngLat().lng,
              latitude: previousMarker.getLngLat().lat
            },
            {
              longitude: marker.getLngLat().lng,
              latitude: marker.getLngLat().lat
            });
        }
        previousMarker = marker;
      });
      this.mapData.centerMap().then(() => {
        console.log('QuestMapComponent :: finish OnInit', this.mapData.markers);
      });
      this.mapLoaded = true;
    });
    this.mapData.map = new MilestonesMapView(this.map, this.mapboxService, this.directionsType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("QuestMapComponent :: ngOnChanges");
    if (!this.participantMaps || !this.participantMaps.length && !this.participantMapsLoaded) {
      this.questService.getQuestMapsByUserIds(this.participantUserIds, this.questId).subscribe((maps: any[]) => {
        this.participantMaps = maps;
        this.participantMapsLoaded = true;
        if (this.markerElements) {
          this.checkDoersBeforeReload();
        } else {
          this.reloadMilestonesData();
        }
      });
    }
    else {
      if (this.markerElements) {
        this.checkDoersBeforeReload();
      } else {
        this.reloadMilestonesData();
      }
    }
  }

  ngAfterViewInit(): void {
    console.log('QuestMapComponent :: AfterViewInit');
    if (!this.participantMaps || !this.participantMaps.length && !this.participantMapsLoaded) {
      this.questService.getQuestMapsByUserIds(this.participantUserIds, this.questId).subscribe((maps: any[]) => {
        this.participantMaps = maps;
        this.participantMapsLoaded = true;
        this.checkDoersBeforeReload();
      });
    } else {
      this.checkDoersBeforeReload();
    }
  }

  getQuestMapData() {
    if (this.participantMaps.length) {
      this.questMapData = this.participantMaps.map(item => new QuestMapData({
          id: item.id,
          geoPoint: {
            latitude: item.geoPoint.x,
            longitude: item.geoPoint.y,
          },
          geoMarker: item.geoMarker,
          pinText: item.pinText,
          completedGeoMarker: item.completedGeoMarker ? item.completedGeoMarker : item.geoMarker,
          location: item.location
        }));
    }
    const milestones: QuestMapData[] = this.data.milestones && this.data.milestones.length
        ? this.data.milestones[0].questTasks.map(task => new QuestMapData({
          id: task.id,
          geoPoint: task.geoPoint,
          geoMarker: task.geoMarker,
          pinText: task.task,
          isCompleted: task.isTaskCompleted,
          completedGeoMarker: task.completedGeoMarker ? task.completedGeoMarker : task.geoMarker,
          location: ''
        }))
        : [];
    milestones.forEach((milestone) => {
      this.questMapData.push(milestone);
    });
  }

  private reloadMilestonesData(): void {
    console.log('QuestMapComponent :: reloadMilestonesData');
    this.getQuestMapData();
    this.mapData.updateMapBounds(this.questMapData.filter(milestone => !!milestone.geoPoint).map(milestone => milestone.geoPoint));
    if (this.mapLoaded) {
      this.mapData.centerMap().then(() => console.log('QuestMapComponent :: finish reloadMilestonesData'));
    }
  }

  private checkDoersBeforeReload() {
    console.log("QuestMap :: Ensuring doers is populated");
    if (!this.doers || !this.viewListDoers) {
      this.questService.getQuestMembers(this.questId, this.userId).subscribe((members: QuestDoer[]) => {
        this.doers.push(...members);
        this.viewListDoers = [...this.doers].filter((member: QuestDoer) => {
          if (member.memberStatus) {
            return member.memberStatus.length === 1 && member.memberStatus[0] === 'Interested' ? false : true;
          }
          return false;
        });
        this.reloadMilestones();
      });
    } else {
      this.reloadMilestones();
    }
  }

  private reloadMilestones(): void {
    console.log('QuestMapComponent :: reloadMilestones');

    this.mapData.markers.forEach(marker => marker.remove());
    this.mapData.markers.length = 0;


    this.getQuestMapData();
    this.questMapData.forEach(milestone => {
      let userPin = this.viewListDoers ? this.viewListDoers.find((obj) => obj.userId == milestone.id) : null;

      if (milestone && milestone.geoPoint) {
        const markerElement = !!milestone.geoMarker && this.markerElements && this.markerElements.length
          ? this.markerElements.find(element => element.nativeElement.id === 'marker-' + milestone.id)
          : null;
        const isCustomPin: boolean = !!markerElement && !!markerElement.nativeElement;
        if (isCustomPin) {
          if (milestone.isCompleted && milestone.completedGeoMarker) {
            markerElement.nativeElement.style.backgroundImage = `url("${milestone.completedGeoMarker}")`;
          } else if (userPin && userPin.amountBackedSum) {
            markerElement.nativeElement.style.backgroundImage = `url("${DONATE_PIN_URL}")`;
          } else {
            markerElement.nativeElement.style.backgroundImage = `url("${milestone.geoMarker}")`;
          }
        }

        const popupOptions: PopupOptions = {
          closeOnClick: true,
          closeButton: false,
          closeOnMove: false,
          className: "popup-div", 
          maxWidth: "none"
        };
        if (isCustomPin) {
          popupOptions.anchor = 'bottom';
          popupOptions.offset = [0, -32];
        }
        const popup: Popup = new Popup(popupOptions);
        let mapPopover = null;
        
        if (userPin) {
          let mapPopoverFactory = this.componentFactoryResolver.resolveComponentFactory(MapPopoverComponent);
          let mapPopoverRef = this.viewContainerRef.createComponent(mapPopoverFactory); //mapPopoverFactory.create(this.injector);
          mapPopover = (<MapPopoverComponent>mapPopoverRef.instance);
          mapPopover.setInputs(this.questId, this.userId, userPin.firstName + " " + userPin.lastName, milestone.location ? milestone.location : "", !userPin, [userPin], this.teams);
          // mapPopoverRef.changeDetectorRef.markForCheck();
          popup.setDOMContent(mapPopoverRef.location.nativeElement);
        } else {
          popup.setText(milestone.pinText);
        }
        
        popup.on('open', () => {
          this.mapPopupOpenCount++;
          this.onMilestoneSelected.emit(milestone.id);
          this.mapData.zoomLocation(milestone.geoPoint).then(() => {
            console.log('QuestMapComponent :: Map pin touched', milestone.id, this.mapPopupOpenCount);
            console.log(userPin);
            if (userPin) {
              console.log("Milestone ", milestone);
              console.log("UserPin ", userPin);
              mapPopover.debug();
            }
          });
        });
        
        popup.on('close', () => {
          if (this.mapPopupOpenCount > 1) {
            this.mapPopupOpenCount = 1;
            return;
          }
          this.onMilestoneSelected.emit(null);
          this.mapData.centerMap().then(() => {
            console.log('QuestMapComponent :: Map pin selection cleared');

            this.mapPopupOpenCount = 0;
          });
        });

        const markerOptions: MarkerOptions = {
          draggable: false
        };
        if (isCustomPin) {
          markerOptions.anchor = 'bottom';
        } else {
          markerOptions.color = milestone.isCompleted ? '#168cb2' : '#648288';
        }
        const marker: Marker = new Marker(isCustomPin ? markerElement.nativeElement : undefined, markerOptions);
        marker.setLngLat({
          lat: milestone.geoPoint.latitude,
          lng: milestone.geoPoint.longitude
        });
        marker.setPopup(popup);

        this.mapData.markers.push(marker);

        if (this.mapLoaded) {
          marker.addTo(this.map);
        }
      }
    });
    this.mapData.updateMapBounds(this.questMapData.filter(milestone => !!milestone.geoPoint).map(milestone => milestone.geoPoint));
    if (this.mapLoaded) {
      this.mapData.centerMap().then(() => console.log('QuestMapComponent :: finish reloadMilestones'));
    }
  }

  private getMembers() {
    console.log("MapPopover :: getMembers");
    this.doers.length = 0;
    this.questService.getQuestMembers(this.questId, this.userId).subscribe((members: QuestDoer[]) => {
      this.doers.push(...members);
      this.viewListDoers = [...this.doers].filter((member: QuestDoer) => {
        if (member.memberStatus) {
          return member.memberStatus.length === 1 && member.memberStatus[0] === 'Interested' ? false : true;
        }
        return false;
      });
    });
  }

}

class MilestonesMapData extends MapData {

  markers: Marker[];

  constructor() {
    super();
    this.markers = [];
  }

}

class MilestonesMapView implements MapView {

  constructor(private map: Map, private mapboxService: MapboxService, private directionsType?: string) {
  }

  connect(from: MapLocation, to: MapLocation): Promise<string> {
    if (!this.directionsType) {
      return Promise.reject();
    }
    return this.mapboxService.getDirections(from, to, DirectionsType[this.directionsType], environment.mapBoxKey).pipe(
      take(1),
      map(route => {
        const id = uuid();
        this.map.addLayer({
          id: id,
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: route.coordinates.map(location => [location.longitude, location.latitude])
              }
            }
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#006a86',
            'line-width': 5,
            'line-opacity': 0.67
          }
        });
        return id;
      })
    )
    .toPromise();
  }

  center(location: MapLocation): Promise<any> {
    return Promise.resolve(this.map.panTo({
      lng: location.longitude,
      lat: location.latitude
    }, {
      animate: true
    }));
  }

  viewport(bounds: MapBounds): Promise<any> {
    const lngLatBounds: LngLatBounds = new LngLatBounds();
    lngLatBounds.setNorthEast({
      lat: bounds.north,
      lng: bounds.east
    });
    lngLatBounds.setSouthWest({
      lat: bounds.south,
      lng: bounds.west
    });
    return Promise.resolve(this.map.fitBounds(lngLatBounds, {
      animate: true
    }));
  }

  zoom(level: MapZoom): Promise<any> {
    return Promise.resolve(this.map.zoomTo(level.level, {
      animate: true
    }));
  }

}
