import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {environment} from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import {QuestTask} from 'diemlife-commons/dist/diemlife-commons-model';
import {map, switchMap} from 'rxjs/operators';
import {
  AddImageToMilestonePayload,
  AddLinkPreviewToMilestonePayload,
  AddMilestonePayload,
  AddMilestonesToGroupPayload,
  AddVideoToMilestonePayload,
  NewTaskGroup,
  TaskGroup,
  UpdateMilestonesGroupNamePayload
} from './dl-milestones.typings';


@Injectable({
  providedIn: 'root',
})
export class DlMilestonesService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
  ) { }

  updateMilestonesGroupName(payload: UpdateMilestonesGroupNamePayload) {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.post(
      environment.target + environment.context + '/quest-tasks-group/update',
      payload,
      {headers: headers}
    );
  }
  createDefaultGroupWithTasks(questId: number, title: string): Observable<NewTaskGroup> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.post<NewTaskGroup>(
      environment.target + environment.context + `/quest-tasks-group/create-default/${questId}`,
      {groupName: title},
      {headers: headers}
    );
  }
  getQuestGroupTasks(questId: number, userId: number): Observable<TaskGroup[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<TaskGroup[]>(
      environment.target + environment.context + `/quest-tasks-group/${questId}/${userId}`,
      {headers: headers}
    ).pipe(
      map((taskGroups: TaskGroup[]) => {
        taskGroups.map((group: TaskGroup) => {
          if (group.questTasks) {
            return group.questTasks.map(task => new QuestTask(task));
          }
        });
        return taskGroups;
      })
    );
  }
  addMilestone(questId: number, payload: AddMilestonePayload): Observable<QuestTask> {
    const headers = this.reaquestHeadersService.getHeaders();
    const filteredPayload = {
      task: payload.task
    } as AddMilestonePayload;
    if (payload.video && payload.video.url) {
      filteredPayload.video = payload.video;
    } else if (payload.linkUrl) {
      filteredPayload.linkUrl = payload.linkUrl;
    } else if (payload.linkedQuestId) {
      filteredPayload.linkedQuestId = payload.linkedQuestId;
    } else if (payload.imageUrl) {
      filteredPayload.imageUrl = payload.imageUrl;
    }
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/add-milestone`,
      filteredPayload,
      {headers: headers}
    ).pipe(
      switchMap((milestone: QuestTask) => {
        if (payload.video) {
          // * [#1 VARIANT] after creation we can video url if it exist in payload
          const addVideoToMilestonePayload: AddVideoToMilestonePayload = {
            id: milestone.id,
            task: milestone.task,
            video: payload.video
          };
          return this.addVideoToMilestone(addVideoToMilestonePayload).pipe(
            switchMap(() => {
              return of({
                ...milestone,
                embeddedVideo: {
                  videoId: null,
                  videoUrl: payload.video.url,
                  thumbnailUrl: payload.video.thumbnails.sm
                }
              });
            })
          );
        } else if (payload.linkUrl) {
          // * [#2 VARIANT] after creation we can add url if it exist in payload
          const addLinkToMilestonePayload: AddLinkPreviewToMilestonePayload = {
            taskId: milestone.id,
            taskLink: payload.linkUrl
          };
          return this.addLinkToMilestone(addLinkToMilestonePayload);
        } else if (payload.imageUrl) {
          // * [#3 VARIANT] after creation we can add image if it exist in payload
          const addImageToMilestonePayload: AddImageToMilestonePayload = {
            taskId: milestone.id,
            taskImage: payload.imageUrl,
            contentType: ''
          };
          return this.addImageToMilestone(addImageToMilestonePayload).pipe(
            map((imageUrl: string) => {
              return {
                ...milestone,
                imageUrl: imageUrl
              };
            }));
        } else {
          // * [#4 VARIANT] return simple milestone if preview not exist in payload
          return of(milestone);
        }
      }),
      map((milestone: QuestTask) => {
        return new QuestTask(milestone);
      })
    );
  }
  addMilestonesToGroup(questId: number, payload: AddMilestonesToGroupPayload) {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.post(
      environment.target + environment.context + `/quest-tasks-group/add-task-to-group/${questId}`,
      payload,
      {headers: headers}
    );
  }
  addLinkToMilestone(payload: AddLinkPreviewToMilestonePayload): Observable<QuestTask> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quest-milestones/add-link`,
      payload,
      {headers: headers}
    ).pipe(
      map((newTask: any) => {
        return new QuestTask(newTask);
      })
    );
  }
  addVideoToMilestone(payload: AddVideoToMilestonePayload) {
    return this.editMilestone(payload);
  }

  addImageToMilestone(payload: AddImageToMilestonePayload): Observable<string> {
    const headers = this.reaquestHeadersService.getHeaders();

    const params = new HttpParams().set('format', 'base64');
    params.append('responseType', 'text' as 'json');

    const data = new FormData();
    data.append('taskId', payload.taskId.toString());
    data.append('taskImage', payload.taskImage);
    data.append('contentType', payload.contentType);

    return this.http.post<string>(
      environment.target + environment.context + `/quest-milestones/add-image`,
      data,
      {
        headers: headers,
        params: params,
        responseType: 'text' as 'json'
      }
    );
  }

  editMilestone(payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/edit',
      payload,
      {headers: headers}
    );
  }
  removePreview(taskId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete(
      environment.target + environment.context + `/quest-milestones/remove-media/${taskId}`,
      {headers: headers}
    );
  }
}
