import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {WindowRef} from 'src/app/_global/window-ref.module';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';

interface TabItem {
  id: string;
  caption: string;
}

interface Tabs {
  active: TabItem;
  list: TabItem[];
}

@Component({
  selector: 'app-anewtest',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.styl']
})
export class LegalComponent implements OnInit, OnDestroy {
  webViewMode = false;
  routerSubscription: Subscription;
  metaImage = '../../../../assets/images/logo-black-white.png';
  stickyNav = false;
  navPlaceholderHeight;

  tabs: Tabs = {
    active: null,
    list: [
      {
        id: 'terms',
        caption: 'Terms of Service'
      },
      {
        id: 'privacy',
        caption: 'Privacy Policy'
      },
      {
        id: 'community',
        caption: 'Community Guidelines'
      },
      {
        id: 'policy',
        caption: 'IP Policy'
      },
    ]
  };

  constructor(
    public winRef: WindowRef,
    private route: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    private titleService: Title
  ) {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.webViewMode = this.route.snapshot.queryParams['_web-view'] === 'true';
      }
    });
  }

  ngOnInit() {
    this.onTabChange(this.tabs.list[0]);
    this.setMetaTags();
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  onTabChange(tab) {
    this.tabs.active = { ...tab };
  }

  goToPage(link, mode) {
    this.winRef.nativeWindow.nsWebViewInterface.emit('openLinkFromWebView', {link, mode});
  }

  scrollTop(scrollDuration) {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / ( scrollDuration / 15 );
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval;
    // console.log(window.scrollY);
    scrollInterval = setInterval( function() {
      if ( window.scrollY !== 0 ) {
        scrollCount = scrollCount + 1;
        scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
        window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
      } else {
        clearInterval(scrollInterval);
      }
    }, 15 );
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | Terms of Service');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | Terms of Service'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'Terms of Service documentation on the DIEMlife platform'});
  }

  onStickyChange($event) {
    this.stickyNav = $event.state;
    this.navPlaceholderHeight = $event.height;
  }

}
