<div class="c-quest-detail-new" *ngIf="isLoaded">
  <div class="c-container" *ngIf="data">
    <app-quest-header
      class="c-quest-header row-xm"
      [questData]="data"
      [status]="status"
      [ngSwitch]="status"
      [fundraisingLink]="fundraisingLink"
      [allowEditCoverImage]="isCurrentUserHasFundraisingLink"
      [showProgress]="!data.quest.milestoneControlsDisabled"
      [completionPercentage]="data.questTasks.length > 0 && status === 'completed' ? 100 : data.completionPercentage"
      [statusText]=""
      [showStartTrigger]="(status === 'saved' || status === 'public') && !isStatusInProgress() && !data.quest.startBtnDisabled ? status : ''"
      (changeCoverImage)="openUploadTeamImageModal(uploadTeamImageModalMode.CoverPicture)">
      <div
        class="c-quest-detail__header__progress__status"
        [ngSwitch]="status"
        *ngIf="status !== 'public'">
        <span *ngSwitchCase="'saved'">
          <span *ngIf="data.userHasSaved">Saved</span>
        </span>
        <span *ngSwitchCase="'completed'">
          <span class="c-quest-detail__header__progress__status"
                *ngIf="hasCurrentUserQuestActivity && data.viewerActivityInfo.repeatCount > 1">
            Done x{{ data.viewerActivityInfo.repeatCount }}
          </span>
          <span *ngIf="hasCurrentUserQuestActivity && data.viewerActivityInfo.repeatCount === 1">Done</span>
        </span>
        <span *ngSwitchCase="'active'">
          <span *ngIf="hasCurrentUserQuestActivity && isModeSupportOnly() && !isCurrentUserHasFundraisingLink">Supporting</span>
          <span
            *ngIf="hasCurrentUserQuestActivity && isModePaceYourself() && !isCurrentUserHasFundraisingLink">Doing</span>
          <span *ngIf="hasCurrentUserQuestActivity && isModeTeam() && !isCurrentUserHasFundraisingLink">In a team</span>
          <span *ngIf="isCurrentUserHasFundraisingLink && !isModeTeam() && !iAmCreator()">Fundraising</span>
          <span *ngIf="isCurrentUserHasFundraisingLink && isModeTeam() && !iAmCreator()">Team<br/>fundraiser</span>
        </span>
      </div>
    </app-quest-header>

    <app-mega-quest-link class="c-quest-top-message row-xm"
                         *ngIf="data.megaQuests?.length"
                         [megaQuests]="data.megaQuests"></app-mega-quest-link>

    <div class="c-quest-tabs-nav row-xm">
      <ul class="c-quest-tabs-nav__list">
        <li class="c-quest-tabs-nav__list-item"
            (click)="tabs.selected = tab.key"
            *ngFor="let tab of tabs.items"
            [ngStyle]="{ 'display': tab.available ? 'flex' : 'none' }"
            [ngClass]="{ 'active': tab.key === tabs.selected }">
          <a class="c-quest-tabs-nav__link">
            <span class="tab-name">{{ tab.value }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="c-quest-tabs__content mt-0" [ngSwitch]="tabs.selected">
      <div class="c-quest-tabs__tab" *ngSwitchCase="'details'">

        <div class="c-quest-detail__layout">
          <div class="c-quest-detail__layout__sidebar">
            <div class="c-quest-detail__main">
              <div class="c-quest-detail__main__avatars">
                <app-profile-avatar
                  class="c-avatar is-creator"
                  (click)="firstAvatarClick()"
                  [avatar]="data.quest.user.profilePictureURL"
                  [firstName]="data.quest.user.firstName"
                  [isBrand]="data.quest.user.isUserBrand === 'Y'"
                  [lastName]="data.quest.user.lastName"
                  [userName]="data.quest.user.firstName + ' ' + data.quest.user.lastName">
                </app-profile-avatar>
                <app-profile-avatar
                  [routerLink]="team?.teamPageUrl"
                  *ngIf="data.quest.user.id !== data.user.id && teamCreator"
                  class="c-avatar is-doer"
                  [avatar]="team?.teamLogoUrl || teamCreator.profilePictureURL"
                  [firstName]="teamCreator.firstName"
                  [isBrand]="true"
                  [lastName]="teamCreator.lastName"
                  [userName]="teamCreator.userName">
                </app-profile-avatar>
                <app-profile-avatar
                  [routerLink]="['/profile', data.user.userName]"
                  *ngIf="data.quest.user.id !== data.user.id && !teamCreator"
                  class="c-avatar is-doer"
                  [avatar]="data.user.profilePictureURL"
                  [firstName]="data.user.firstName"
                  [isBrand]="data.user.isUserBrand === 'Y'"
                  [lastName]="data.user.lastName"
                  [userName]="data.user.firstName + ' ' + data.user.lastName">
                </app-profile-avatar>
                <i class="c-profile-new__header__photo__image-upload"
                   *ngIf="isCurrentUserIsTeamCreator()"
                   (click)="openUploadTeamImageModal(uploadTeamImageModalMode.TeamLogo)">
                  <svg class="icon icon-camera">
                    <use xlink:href="#icon-camera"></use>
                  </svg>
                </i>
              </div>
              <div class="c-quest-detail__main__user-info">
                <span class="c-quest-detail__main__user-name"
                      [routerLink]="data.quest.user.id !== data.user.id && teamCreator ? team.teamPageUrl : ['/profile', data.user.userName]">{{
                  (data.isTeamPage && team?.teamName && !isDefaultTeamOnlyPresent) ? team.teamName : (data.user.firstName + ' ' + data.user.lastName)
                  }}</span>
                <!-- TODO - new feature -->
                <span class="c-quest-detail__main__user-group temp-hidden">&#x25B2; Group*</span>
                <!-- TODO - new feature -->
                <span class="c-quest-detail__main__user-address temp-hidden">&#x25B2; New York, NY*</span>
              </div>
              <div class="c-quest-detail__main__tools">

                <!-- * START Logic of quest registration button START -->
                <ng-container *ngIf="hasEvent">
                  <ng-template #registerTriggerLogicTemplate let-isEventValid="isEventValid">
                    <a *ngIf="!isCurrentUserQuestDoer && !isCurrentUserInQuestTeam()"
                      class="c-btn c-btn_sm c-btn-full c-btn_yellow"
                      [routerLink]="isEventValid ? ['/get-tickets', data.quest.id, data.quest.createdBy] : null"
                      [queryParams]="{teamId: team?.teamId}"
                      [ngClass]="{'is-disabled': !isEventValid}">
                      <span>{{isEventValid ? 'REGISTER' : (!isEventActive() ? 'REGISTRATION CLOSED' : 'EVENT ENDED')}}</span>
                      <small class="temp-hidden">from $30 to $3000</small>
                    </a>

                    <ng-container *ngIf="isCurrentUserQuestDoer || isCurrentUserInQuestTeam()">
                      <a class="c-btn c-btn_sm c-btn-full c-btn_yellow is-disabled" href="javascript:void(null)" disabled="true">
                        <span>{{isEventValid ? 'REGISTERED' : (!isEventActive() ? 'REGISTRATION CLOSED' : 'EVENT ENDED')}}</span>
                      </a>
                      <a *ngIf="isEventValid"
                        class="c-quest-detail__main__siblings-link t-typo__link"
                        [routerLink]="['/get-tickets', data.quest.id, data.quest.createdBy]">Register more people</a>
                    </ng-container>
                  </ng-template>

                  <!-- ? Calls previous template with passed variables -->
                  <ng-template
                    [ngTemplateOutlet]="registerTriggerLogicTemplate"
                    [ngTemplateOutletContext]="{ isEventValid: isEventActive() && isEventDateInFuture() }">
                  </ng-template>
                </ng-container>
                <!-- * END Logic of quest registration button END -->


                <button
                  class="c-btn c-btn_sm c-btn-full c-btn_yellow"
                  [disabled]="isProgress"
                  (appAuthGuard)="isQuestInTeamMode() ? doJoinTeam() : doQuest()"
                  *ngIf="(status === 'saved' || status === 'public')
                    && !isStatusInProgress()
                    && !data.quest.startBtnDisabled
                    && !(isQuestInTeamMode() && teams?.length && isDoerIsCreator())
                    && !(hasEvent  && isDoerIsCreator())"
                  [ngSwitch]="data.quest.mode">
                  <span *ngSwitchCase="'viewOnlyMode'">Support</span>
                  <span *ngSwitchCase="'diyMode'">Join</span>
                  <span *ngSwitchDefault>Start</span>
                </button>
                <button
                  class="c-btn c-btn_sm c-btn-full c-btn_dark"
                  (click)="confirmRepeatQuest()"
                  *ngIf="status === 'completed' && data.viewerActivityInfo.repeatable && isStatusComplete()"
                  [disabled]="isProgress">
                  <span>Repeat</span>
                </button>
                <button class="c-btn c-btn_sm c-btn-full c-btn_dark"
                        (click)="completeQuest()"
                        *ngIf="status === 'active' && isCurrentUsersRepresentation">
                  <span>Complete</span>
                </button>
                <button class="c-btn c-btn_sm c-btn-full c-btn_dark"
                  [routerLink]="['/donate', questId, userId]"
                  *ngIf="data.backingAllowed
                    && !data.quest.backBtnDisabled
                    && !isCurrentUsersRepresentation
                    && !isDoerHasFundraisingLink">
                  <span>{{ data.user.userNonProfit ? 'Donate' : 'Back me' }}</span>
                </button>
                <a href="javascript:void(0)"
                   class="c-quest-detail__main__edit-quest"
                   *ngIf="editableQuest"
                   [routerLink]="['/edit', data.quest.id]">
                  <svg class="icon icon-pencil">
                    <use xlink:href="#icon-pencil"></use>
                  </svg>
                  <span>EDIT QUEST</span>
                </a>
              </div>
            </div>
          </div>
          <div class="c-quest-detail__layout__content">
            <!-- TODO - new feature -->
            <div class="c-breadcrumbs temp-hidden">
              <ul>
                <li><a href="javascript:void(0)">Mental*</a></li>
                <li><a href="javascript:void(0)">Challenges*</a></li>
                <li><a href="javascript:void(0)">Hunting*</a></li>
                <li><a href="javascript:void(0)">Operation SPARK 2019*</a></li>
              </ul>
            </div>
            <!-- TODO - new feature -->
            <div class="c-quest__shedule temp-hidden">
              <b>Quest Schedule: </b> <span>March 25 - May 15*</span>
            </div>
            <app-quest-description [questInfo]="data.quest" [fundraisingLink]="fundraisingLink" [mode]="'wrapped'"
                                   id="skip-nav-target"></app-quest-description>
            <div class="c-quest__article" *ngIf="youtubeUrl">
              <div class="c-quest__article__header">
                <h3 class="c-quest__article__title h2">Video</h3>
              </div>
              <div class="c-quest__article__body">
                <div class="c-quest__video">
                  <iframe
                    [cachedSrc]="prepareQuestVideoId(data.questVideoUrl)"
                    width="100%"
                    height="315"
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Milestones -->
        <app-dl-milestones
          class="c-dl-milestones"
          [taskViewDisabled]="data.quest.taskViewDisabled"
          [directionsType]="data.quest.directionsType"
          [mode]="milestoneModes.QUEST_DETAILS"
          [canBeEdit]="canCurrentUserEditMilestones()"
          [allowCheck]="allowCheckMilestones"
          [showControls]="!data.quest.milestoneControlsDisabled"
          [questId]="data.quest.id"
          [userId]="data.user.id"
          [questTitle]="data.quest.title"
          [locationName]="'Quest Location'"
          [teams]="teams"
          [participantUserIds]="participantUserIds">
        </app-dl-milestones>

        <div class="c-quest__article" *ngIf="hasEvent && (iAmCreator() || allowEditQuest)">
          <div class="c-quest__article__header">
            <h3 class="c-quest__article__title h2">Reports</h3>
            <a class="c-quest__article__tool"
               href="javascript:void(null)"
               (click)="saveTransactions()">
              <svg class="icon icon-save">
                <use xlink:href="#icon-save"></use>
              </svg>
              <span>{{downloadProgress ? 'Downloading' : 'Download'}}</span>
            </a>
          </div>
        </div>
        <div class="c-quest__article c-quest__article__donate row"
             *ngIf="data.quest.fundraising && !data.quest.fundraisingBarHidden && fundraisingInited && !isDoerIsSimpleDoer()">
          <div class="c-quest__article__header row__inner">
            <h3 class="c-quest__article__title h2">FUNDRAISING</h3>
          </div>
          <app-quest-donate class="c-quest__row c-quest-donate row__inner"
                            (showEditFundraisingModal)="openEditFundraiserModal()"
                            (showStartFundraisingModal)="openStartFundraiserModal($event)"
                            [data]="fundraisingLink"
                            [doers]="members"
                            [enableBackingButton]="data.backingAllowed && !data.quest.backBtnDisabled"
                            [enableDetailedTotals]="isDoerIsCreator() && data.quest.mode === 'diyMode'"
                            [includeFundraiseButton]="shouldShowFundraisingButton()"
                            [disableFundraiseButton]="shouldDisableFundraisingButton()"
                            [enableFundraiseEditLink]="isCurrentUserHasFundraisingLink"
                            [enableJoinTeamButton]="(status === 'saved' || status === 'public') && !isStatusInProgress() && !data.quest.startBtnDisabled && data.quest.mode === 'diyMode'"
                            [isProgress]="isProgress"
                            [questId]="data.quest.id"
                            [requiresBackingConfirmation]="false"
                            [teams]="teams"
                            [userId]="data.user.id"
                            [userNonProfit]="data.user.userNonProfit"
                            [isMainQuest]="isQuestInTeamMode() && !data.isTeamPage"></app-quest-donate>
        </div>
        <app-members
          #appMembers
          *ngIf="isLoaded"
          [mode]="'doers'"
          [userId]="userId"
          [questId]="questId"
          [status]="status"
          [allowEditQuest]="allowEditQuest"
          [showBacking]="data.quest.showBackingAmount"
          [doers]="[]"
          [isCurrentUserQuestDoer]="isCurrentUserQuestDoer"
          [questTitle]="data.quest.title"
          [teams]="teams"
          >
        </app-members>
        <div class="c-quest__article" *ngIf="isQuestInTeamMode() && !isDefaultTeamOnlyPresent && isDoerIsCreator() && teams.length > 0">
          <div class="c-article__header">
            <h3 class="c-article__title h2"><span class="e-thin">{{teams.length}}</span>&nbsp;teams</h3>
          </div>
          <div class="c-article__body">
            <perfect-scrollbar [scrollIndicators]="false"
                               [autoPropagation]="true"
                               [config]="{suppressScrollX: false, suppressScrollY: true}">
              <app-team-list [teams]="teams"
                             [oneLiner]="true"
                             [selectionAllowed]="false"
                             [showMembersCount]="false"
                             [showBackedAmount]="true"
                             [sort]="'BackedAmount'">
              </app-team-list>
            </perfect-scrollbar>
          </div>
        </div>
        <!-- Gallery -->
        <div class="c-quest__article">
          <div class="c-quest__article__header">
            <h3 class="c-quest__article__title h2">Gallery</h3>
            <a *ngIf="
                allowEditQuest ||
                (viewerActivityStatus &&
                  (isStatusInProgress() || isStatusComplete()))"
               class="c-quest__article__tool"
               (click)="openModal('cropper-modal')"
               href="javascript:void(null)">{{
              galleryPhotoIsLoading ? "Photo is loading..." : "+ Add Photo"
              }}</a>
          </div>
          <div class="c-quest__article__body">
            <app-quest-carousel
              *ngIf="data.questImages.length > 0"
              [images]="data.questImages">
            </app-quest-carousel>
            <div
              class="c-gallery__empty"
              *ngIf="data.questImages.length === 0">
              <svg class="icon icon-picture">
                <use xlink:href="#icon-picture"></use>
              </svg>
              <span>No photos here yet...</span>
              <div class="c-gallery__empty__overwrap"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-quest-tabs__tab" *ngSwitchCase="'leaderboard'">
        <app-leaderboard-main [quest]="data.quest" [allowEdit]="allowEditQuest" [teams]="teams"></app-leaderboard-main>
      </div>
      <app-quest-comments
        [questUserId]="userId"
        [questId]="questId"
        class="c-quest-tabs__tab"
        *ngSwitchCase="'comments'">
      </app-quest-comments>
    </div>
  </div>
</div>

<div class="c-quest__ban" *ngIf="viewStatus === 403">
  <div class="c-quest__ban__inner">
    <svg class="c-quest__ban__icon icon icon-error-403">
      <use xlink:href="#icon-error-403"></use>
    </svg>
    <h2 class="c-quest__ban__title">You do not have permission to access this page.</h2>
    <div class="c-quest__ban__desc" *ngIf="currentUser?.id"><a [routerLink]="['/explore']">Explore</a> or <a
      [routerLink]="['/create']">Create</a> a Quest®
    </div>
    <div class="c-quest__ban__help" *ngIf="!currentUser?.id">
      <p><a [routerLink]="['/explore']">Explore</a> a Quest</p>
      <span class="c-quest__ban__help__divider">or</span>
      <app-auth></app-auth>
    </div>
  </div>
</div>
<div class="c-quest__ban" *ngIf="viewStatus === 401">
  <div class="c-quest__ban__inner">
    <svg class="c-quest__ban__icon icon icon-error-401">
      <use xlink:href="#icon-error-401"></use>
    </svg>
    <app-auth></app-auth>
  </div>
</div>

<app-modal id="cropper-modal" (closeModal)="closeModal()">
  <h3 class="modal-title">Upload a Photo:</h3>
  <app-cropper-dialog
    *ngIf="openedModal === 'cropper-modal'"
    [questId]="questId"
    [selectedArea]="'questGalleryPhoto'"
    (imageAdded)="addGalleryPhoto($event)"
  ></app-cropper-dialog>
</app-modal>

<app-modal id="fundraise-modal" *ngIf="data?.quest.fundraising && isLoaded && fundraisingInited && currentUser?.id">
  <app-funds-modal
    [questId]="questId"
    [doerId]="currentUser.id"
    [fundraisingLink]="fundraisingLink"
    [mode]="'fundraise'"
    (close)="closeModal()"
    (fundSubmit)="startFundraiser($event)">
  </app-funds-modal>
</app-modal>

<app-modal id="back-fundraiser-modal" *ngIf="data?.quest.fundraising && isLoaded && fundraisingInited">
  <app-funds-modal
    [questId]="questId"
    [doerId]="userId"
    [mode]="'backme'"
    (close)="closeModal()"
    (fundSubmit)="backFundraiser()">
  </app-funds-modal>
</app-modal>

<app-modal id="start-quest-in-team-modal"
           *ngIf="isQuestInTeamMode() && fundraisingInited && currentUser?.id"
           (closeModal)="closeTeamModal()">
  <app-team-quest-starter [questTitle]="data.quest.title"
                          [questId]="questId"
                          [doerId]="userId"
                          [viewerId]="currentUser.id"
                          [fundraising]="isDoerHasFundraisingLink"
                          (onQuestStart)="afterDoQuest($event)">
  </app-team-quest-starter>
</app-modal>

<app-modal id="upload-team-image-modal"
           *ngIf="isCurrentUserIsTeamCreator()"
           (closeModal)="closeUploadTeamImageModal()">
  <app-team-upload-image-form
    [questId]="questId"
    [doerId]="userId"
    [team]="team"
    [fundraisingLink]="isCurrentUserHasFundraisingLink ? fundraisingLink : null"
    [mode]="selectedUploadTeamImageModalMode"
    [viewerId]="currentUser.id"
    (updateQuestDetailInfo)="loadPageData()"
    (close)="closeUploadTeamImageModal()">
  </app-team-upload-image-form>
</app-modal>
