import {Action} from '@ngrx/store';
import {MilestoneVideo, QuestTask} from 'diemlife-commons/dist/diemlife-commons-model';
import {TaskGroup} from '../dl-milestones.typings';

export const SET_MILESTONES = 'SET_MILESTONES';
export const MOVE_MILESTONE = 'MOVE_MILESTONE';
export const ADD_MILESTONE = 'ADD_MILESTONE';
export const REMOVE_MILESTONE = 'REMOVE_MILESTONE';
export const UPDATE_MILESTONE_GROUP_NAME = 'UPDATE_MILESTONE_GROUP_NAME';
export const UPDATE_MILESTONE_TASK = 'UPDATE_MILESTONE_TASK';
export const UPDATE_MILESTONE_TASK_COMPLETION = 'UPDATE_MILESTONE_TASK_COMPLETION';
export const DL_MILESTONES_CLEAR_DATA = 'DL_MILESTONES_CLEAR_DATA';
export const ADD_QUEST_LINK = 'ADD_QUEST_LINK';
export const QUEST_LINK_ADDED = 'QUEST_LINK_ADDED';
export const ADD_MILESTONE_IMAGE_URL = 'ADD_MILESTONE_IMAGE_URL';
export const REMOVE_MILESTONE_IMAGE_URL = 'REMOVE_MILESTONE_IMAGE_URL';
export const ADD_MILESTONE_VIDEO_URL = 'ADD_MILESTONE_VIDEO_URL';
export const REMOVE_MILESTONE_VIDEO_URL = 'REMOVE_MILESTONE_VIDEO_URL';
export const PATCH_MILESTONE = 'PATCH_MILESTONE';
export const SET_MILESTONE_FOR_PREVIEW = 'SET_MILESTONE_FOR_PREVIEW';
export const CLEAR_MILESTONE_FOR_PREVIEW = 'CLEAR_MILESTONE_FOR_PREVIEW';
export const SET_NEW_MILESTONE = 'SET_NEW_MILESTONE';
export const PATCH_NEW_MILESTONE = 'PATCH_NEW_MILESTONE';
export const REMOVE_NEW_MILESTONE = 'REMOVE_NEW_MILESTONE';

export class SetMilestones implements Action {
  readonly type = SET_MILESTONES;
  constructor(public payload: TaskGroup[]) {}
}
export class MoveMilestone implements Action {
  readonly type = MOVE_MILESTONE;
  constructor(public payload: { previousIndex: number; currentIndex: number; }) {}
}
export class AddMilestone implements Action {
  readonly type = ADD_MILESTONE;
  constructor(public payload: QuestTask) {}
}
export class RemoveMilestone implements Action {
  readonly type = REMOVE_MILESTONE;
  constructor(public payload: number) {}
}
export class UpdateMilestoneTask implements Action {
  readonly type = UPDATE_MILESTONE_TASK;
  constructor(public payload: {index: number, task: string}) {}
}
export class UpdateMilestoneTaskCompletion implements Action {
  readonly type = UPDATE_MILESTONE_TASK_COMPLETION;
  constructor(public payload: {index: number, value: boolean}) {
    console.log('DlMilestoneActions::UpdateMilestoneTaskCompletion', payload);
  }
}
export class DlMilestonesClearData implements Action {
  readonly type = DL_MILESTONES_CLEAR_DATA;
}

export class AddQuestLink implements Action {
  readonly type = ADD_QUEST_LINK;
  constructor(public taskIndex: number, public taskId: number, public linkedQuestId: number) {
  }
}
export class QuestLinkAdded implements Action {
  readonly type = QUEST_LINK_ADDED;
  constructor(public task: QuestTask) {
  }
}

export class AddMilestoneImageUrl implements Action {
  readonly type = ADD_MILESTONE_IMAGE_URL;
  constructor(public payload: {index: number, value: string}) {}
}
export class RemoveMilestoneImageUrl implements Action {
  readonly type = REMOVE_MILESTONE_IMAGE_URL;
  constructor(public payload: {index: number}) {}
}
export class AddMilestoneVideoUrl implements Action {
  readonly type = ADD_MILESTONE_VIDEO_URL;
  constructor(public payload: {index: number, value: MilestoneVideo}) {}
}
export class RemoveMilestoneVideoUrl implements Action {
  readonly type = REMOVE_MILESTONE_VIDEO_URL;
  constructor(public payload: {index: number}) {}
}
export class UpdateMilestoneGroupName implements Action {
  readonly type = UPDATE_MILESTONE_GROUP_NAME;
  constructor(public payload: {value: string}) {}
}
export class PatchMilestone implements Action {
  readonly type = PATCH_MILESTONE;
  constructor(public payload: {index: number, value: QuestTask}) {}
}
export class SetMilestoneForPreview implements Action {
  readonly type = SET_MILESTONE_FOR_PREVIEW;
  constructor(public payload: {index: number, isEditMode: boolean}) {}
}
export class ClearMilestoneForPreview implements Action {
  readonly type = CLEAR_MILESTONE_FOR_PREVIEW;
}
export class SetNewMilestone implements Action {
  readonly type = SET_NEW_MILESTONE;
  constructor(public payload: QuestTask) {}
}
export class PatchNewMilestone implements Action {
  readonly type = PATCH_NEW_MILESTONE;
  constructor(public payload: QuestTask) {}
}
export class RemoveNewMilestone implements Action {
  readonly type = REMOVE_NEW_MILESTONE;
}

export type DlMilestoneActions = SetMilestones |
                                 MoveMilestone |
                                 AddMilestone |
                                 UpdateMilestoneTask |
                                 UpdateMilestoneTaskCompletion |
                                 DlMilestonesClearData |
                                 RemoveMilestone |
                                 AddQuestLink |
                                 QuestLinkAdded |
                                 AddMilestoneImageUrl |
                                 RemoveMilestoneImageUrl |
                                 AddMilestoneVideoUrl |
                                 RemoveMilestoneVideoUrl |
                                 UpdateMilestoneGroupName |
                                 PatchMilestone |
                                 SetMilestoneForPreview |
                                 ClearMilestoneForPreview |
                                 SetNewMilestone |
                                 PatchNewMilestone |
                                 RemoveNewMilestone;
