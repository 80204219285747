import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {QuestTeam} from 'diemlife-commons/dist/diemlife-commons-model';
import {select, Store} from '@ngrx/store';
import {AppState, teamModalOpenState, teamModalProgressState} from '../../../../_store/app.reducers';
import {Subscription} from 'rxjs';
import {StartTeamQuestPayload} from '../team-quest-starter/team-quest-starter.component';

export enum TeamListOrder {
  MembersCount = 'MembersCount',
  BackedAmount = 'BackedAmount'
}

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.styl']
})
export class TeamListComponent implements OnInit, OnDestroy {

  @Input() teams: QuestTeam[];
  @Input() oneLiner: boolean;
  @Input() selectionAllowed: boolean;
  @Input() showMembersCount: boolean;
  @Input() showBackedAmount: boolean;
  @Input() sort?: String;

  @Output() onSelectTeam: EventEmitter<StartTeamQuestPayload> = new EventEmitter<StartTeamQuestPayload>();

  selectedTeam: QuestTeam = null;
  progress = false;
  teamModalOpenSubscription: Subscription;
  teamModalProgressSubscription: Subscription;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    if (this.sort === TeamListOrder.MembersCount) {
      this.teams.sort((left, right) => right.memberIds.length - left.memberIds.length);
    } else if (this.sort === TeamListOrder.BackedAmount) {
      this.teams.sort((left, right) => (right.amountBacked || 0) - (left.amountBacked || 0));
    } else {
      this.teams.sort((left, right) => left.teamName.localeCompare(right.teamName));
    }
    this.teamModalOpenSubscription = this.store.pipe(select(teamModalOpenState)).subscribe(state => {
      if (state.open) {
        this.selectedTeam = this.teams.find(team => team.teamId === state.selectedId);
      }
    });
    this.teamModalProgressSubscription = this.store.pipe(select(teamModalProgressState)).subscribe(state => {
      this.progress = state.questProgress;
    });
  }

  ngOnDestroy(): void {
    this.teamModalOpenSubscription.unsubscribe();
    this.teamModalProgressSubscription.unsubscribe();
  }

  setSelectedTeam(team: QuestTeam) {
    if (this.selectionAllowed) {
      this.selectedTeam = team;
    }
  }

  confirmSelectedTeam() {
    if (this.progress) {
      return;
    }
    if (this.selectionAllowed && this.selectedTeam && this.selectedTeam.teamId) {
      this.onSelectTeam.emit({
        teamId: this.selectedTeam.teamId,
        teamName: this.selectedTeam.teamName,
        teamLogoUrl: this.selectedTeam.teamLogoUrl
      });
    }
  }

}
