<ng-template #defaultTemplate>
  <div class="c-leaderboard-podium-place__avatar"
    [ngClass]="
      {
        'c-leaderboard-podium-place--first': place === 1,
        'c-leaderboard-podium-place--second': place === 2,
        'c-leaderboard-podium-place--third': place === 3,
        'is-team': mode === availableModes.TEAM
      }">
    <a class="c-leaderboard-podium-place__profile-link"
    href="javascript:void(0)"
    [routerLink]="member.userName ? ['/profile', member.userName] : null">
    <img class="c-leaderboard-podium-place__photo"
        *ngIf="member.avatarUrl"
        [src]="member.avatarUrl"
        [alt]="member.firstName + (member.lastName ? ' ' + member.lastName : '')"
        [title]="member.firstName + (member.lastName ? ' ' + member.lastName : '')"/>
    <span class="c-leaderboard-podium-place__initials"
        *ngIf="member.firstName && member.lastName && !member.avatarUrl">{{member.firstName.charAt(0) + member.lastName.charAt(0)}}</span>
    <span class="c-leaderboard-podium-place__initials"
        *ngIf="!member.firstName || !member.lastName">{{place}}</span>
    </a>
    <span class="c-leaderboard-podium-place__number">{{member.place}}</span>
  </div>
  <div class="c-leaderboard-podium-place__caption"
       [ngClass]="{'c-leaderboard-podium-place--first': place === 1}">
    <span class="c-leaderboard__name" *ngIf="!isMemberInfoPresent()">&mdash;</span>
    <span class="c-leaderboard-podium-place__name c-leaderboard__name" *ngIf="isMemberInfoPresent()">
      <span>{{member.firstName + (member.lastName ? ' ' + member.lastName : '')}}</span>
    </span>
    <span class="c-leaderboard-podium-place__info c-leaderboard__info" *ngIf="isMemberInfoPresent()">
      <span *ngIf="member.gender">{{member.gender === 'M' ? 'Male' : 'Female'}}</span>
      <span *ngIf="member.gender && member.age">&nbsp;-&nbsp;</span>
      <span *ngIf="member.age">{{member.age}}</span>
      <span *ngIf="!member.gender && !member.age">&bull;</span>
    </span>
    <span class="c-leaderboard-podium-place__info c-leaderboard__info" *ngIf="isMemberInfoPresent()">
      <span *ngIf="member.city">{{member.city}},&nbsp;</span>
      <span *ngIf="member.state">{{member.state}},&nbsp;</span>
      <span *ngIf="member.country">{{member.country}}</span>
      <span *ngIf="!member.city && !member.city && !member.country">&bull;</span>
    </span>
    <div class="c-leaderboard__score__wrap">
      <span class="c-leaderboard-podium-place__score c-leaderboard__score"
            *ngIf="!showForm"
            [ngClass]="{'c-leaderboard__score--disabled': member.status === 'DidNotStart' || member.status === 'DidNotFinish'}">{{scoreText}}</span>
      <span class="c-leaderboard-podium-place__score c-leaderboard__score c-leaderboard__score--disabled"
            *ngIf="isMemberInfoPresent() && !showScore && !showForm">&mdash;</span>
      <app-leaderboard-score-editor
        *ngIf="allowEdit && member"
        class="c-leaderboard-score-editor"
        [member]="member"
        [questId]="questId"
        [selectedAttributeId]="selectedAttributeId"
        (changeVisibilityForm)="onChangeVisibilityEditForm($event)"
        (updateMember)="onUpdateMember($event)">
      </app-leaderboard-score-editor>
    </div>
    <span class="c-leaderboard-podium-place__unit c-leaderboard__unit" *ngIf="showUnit">{{unitText}}</span>
  </div>
</ng-template>

<ng-container *ngIf="mode === availableModes.TEAM && team; else defaultTemplate">
  <div class="c-leaderboard-podium-place__avatar is-team"
    [ngClass]="
      {
        'c-leaderboard-podium-place--first': place === 1,
        'c-leaderboard-podium-place--second': place === 2,
        'c-leaderboard-podium-place--third': place === 3
      }">
    <a class="c-leaderboard-podium-place__profile-link" [routerLink]="team.teamPageUrl ? [team.teamPageUrl] : undefined">
    <img class="c-leaderboard-podium-place__photo" *ngIf="team.teamLogoUrl" [src]="team.teamLogoUrl" [alt]="team.teamName" [title]="team.teamName"/>
    <img class="c-leaderboard-podium-place__photo" *ngIf="!team.teamLogoUrl" [src]="'/assets/images/logo-black-white.png'" [alt]="team.teamName" [title]="team.teamName"/>
    </a>
    <span class="c-leaderboard-podium-place__number">{{place}}</span>
  </div>
  <div class="c-leaderboard-podium-place__caption"
       [ngClass]="{'c-leaderboard-podium-place--first': place === 1}">
    <span class="c-leaderboard-podium-place__name c-leaderboard__name">
      <span>{{team.teamName ? team.teamName : '&mdash;'}}</span>
    </span>
    <span class="c-leaderboard-podium-place__info c-leaderboard__info">
      <span>&bull;</span>
    </span>
    <div class="c-leaderboard__score__wrap">
      <span *ngIf="showScore" class="c-leaderboard-podium-place__score c-leaderboard__score"
            [ngClass]="{'c-leaderboard__score--disabled': member.status === 'DidNotStart' || member.status === 'DidNotFinish'}">{{scoreText}}</span>
      <span *ngIf="!showScore" class="c-leaderboard-podium-place__score c-leaderboard__score c-leaderboard__score--disabled">&mdash;</span>
    </div>
    <span class="c-leaderboard-podium-place__unit c-leaderboard__unit" *ngIf="showUnit">{{unitText}}</span>
  </div>
</ng-container>
