import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { CommentService } from '../comment/comment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestService } from 'src/app/_services/quest.service';
import { Store } from '@ngrx/store';
import {
  AddComment,
  UpdateComment,
  ToggleEditCommentState,
  ToggleEditComment,
} from '../store/quest-components.actions';
import { PreparedQuestComment } from '../comment/comment.type';
import { SnotifyService } from 'ng-snotify';
import { SnotifyConfigService } from 'src/app/_services/snotify-config.service';
import { EMPTY, of, Observable, Subscription } from 'rxjs';
import { mergeMap, catchError, debounceTime, map } from 'rxjs/operators';
import * as fromApp from '../../../../../_store/app.reducers';
import { CropperService } from '../../../cropper-dialog/cropper-dilog.service';
import { NewQuestGalleryPhoto } from '../../quest.type';
import { QuestGalleryImage } from 'diemlife-commons/dist/diemlife-commons-model/public_api';

@Component({
  selector: 'app-newsfeed-ctrl',
  templateUrl: './newsfeed-ctrl.component.html',
  styleUrls: ['./newsfeed-ctrl.component.styl']
})
export class NewsfeedCtrlComponent implements OnInit, OnDestroy {
  @ViewChild('circleEl', { static: true }) readonly circleEl: ElementRef;
  @ViewChild('textEl', { static: true }) readonly textEl: ElementRef;
  @ViewChild('fileInput', { static: true }) readonly fileInput: ElementRef;

  @Input() readonly viewerId: number;
  @Input() readonly questId: number;
  @Input() readonly comment?: PreparedQuestComment;
  tributeOptions: any;
  form: FormGroup;
  base64: string;
  contentType: string;
  hasPreview = {
    key: null,
    value: false
  };
  isLoading: string;
  minTextHeight = '300px';
  isInFocus = false;
  MAX_FILE_SIZE = 15000000;
  isEditMode: boolean;
  subscription: Subscription;
  isValueChanged = false;
  closeEditModeSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private commentService: CommentService,
    private questService: QuestService,
    private fb: FormBuilder,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private cropperService: CropperService
  ) {
    this.tributeOptions = this.commentService.getTributeOptions();
  }

  ngOnInit() {
    // * set component mode
    this.isEditMode = Boolean(this.comment);

    this.form = this.fb.group({
      editor: [null, [Validators.required]]
    });

    if (this.isEditMode) {
      this.form.setValue({
        editor: this.comment.comment
      });
      this.subscription = this.form.valueChanges
        .pipe(debounceTime(300))
        .subscribe((value) => {
          this.isValueChanged = this.comment.comment !== value.editor;
        });
      this.closeEditModeSubscription = this.commentService.closeEditModeEventsSubject.asObservable().subscribe(() => {
        this.toggleEditComment();
      });
    } else {
      this.isValueChanged = true;
    }

    this.hasPreview = this.checkPreview();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.closeEditModeSubscription) {
      this.closeEditModeSubscription.unsubscribe();
    }
  }
  submit(toggleEditMode?: boolean) {
    if (this.form.invalid || this.isLoading) { return; }

    this.isLoading = 'COMMENT';

    this.postImage()
    .pipe(
      mergeMap((response) => {
        if (this.isEditMode) {
          return this.editComment(this.comment.image ? this.comment.image.id : null);
        } else {
          return this.addComment(response ? response.id : null);
        }
      })
    )
    .subscribe((res: PreparedQuestComment) => {
      this.isLoading = null;

      if (this.isEditMode) {
        this.store.dispatch(new UpdateComment(res));
        setTimeout(() => {
          this.isValueChanged = false;
          this.hasPreview = this.checkPreview();
          if (toggleEditMode) {
            const payload: ToggleEditCommentState = {
              commentId: this.comment.id,
              isEditable: !this.comment.editable
            };
            this.store.dispatch(new ToggleEditComment(payload));
          }
        });
        this.snotifyService.success('Changes saved', null, this.snotifyConfigService.getConfig());
      } else {
        // reset form
        this.form.get('editor').patchValue('');
        this.base64 = null;
        this.contentType = null;
        this.hasPreview = this.checkPreview();
        this.store.dispatch(new AddComment(res));
      }

      // update gallery component for quest detail page
      if (res.image ? res.image.questImageUrl : false) {
        this.questService.callUpdateForQuest('gallery');
      }

    }, err => {
      this.isLoading = null;
      if (err.status ? err.status === 413 : false) {
        this.snotifyService.error('Image is too large', null, this.snotifyConfigService.getConfig());
      } else {
        this.snotifyService.error('Oops, something went wrong.', null, this.snotifyConfigService.getConfig());
      }
    });
  }
  confirmRemove() {
    if (this.isLoading) { return; }

    if (this.isEditMode) {
      this.snotifyService.confirm(
        'Do you wish to remove this photo?',
        null,
        this.snotifyConfigService.getConfig({
          closeOnClick: false,
          timeout: 0,
          buttons: [
            {
              text: 'Yes',
              bold: true,
              action: (toast) => {
                this.removePreview();
                this.snotifyService.remove(toast.id);
              }
            },
            {
              text: 'Cancel',
              bold: false,
              action: (toast) => {
                this.snotifyService.remove(toast.id);
              }
            }
          ]
        })
      );
    } else {
      this.removePreview();
    }
  }
  removePreview() {
    if (this.isEditMode) {
      this.isLoading = 'LOAD_PHOTO';

      this.editComment(null).subscribe((res: PreparedQuestComment) => {
        this.store.dispatch(new UpdateComment(res));
        this.hasPreview = this.checkPreview();
        this.isLoading = null;
        this.snotifyService.success('Changes saved', null, this.snotifyConfigService.getConfig());
      }, err => {
        this.isLoading = null;
        this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
      });
    } else {
      this.base64 = null;
      this.contentType = null;
      this.hasPreview = this.checkPreview();
    }
  }
  focusIn() {
    this.isInFocus = true;
  }
  focusOut() {
    this.isInFocus = false;
  }
  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.isLoading = 'LOAD_PHOTO';

      setTimeout(() => {
        const file = event.target.files[0];
        if (file.size > this.MAX_FILE_SIZE) {
          this.snotifyService.warning('Image is too large, please try a smaller file', null, this.snotifyConfigService.getConfig());
          return;
        }

        this.updatePhoto(file, file.type).subscribe(response => {
          if (typeof response === 'object') {
            // Update comment info
            this.store.dispatch(new UpdateComment(response));
            setTimeout(() => {
              this.hasPreview = this.checkPreview();
            });
            this.snotifyService.success('Changes saved', null, this.snotifyConfigService.getConfig());
            // Changes saved
          } else if (typeof response === 'string') {
            // Update comment info
            this.base64 = response;
            this.contentType = file.type;
            this.hasPreview = this.checkPreview();
          } else {
            this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
            this.isLoading = null;
          }

          // resets logic
          this.fileInput.nativeElement.value = '';
          this.isLoading = null;
        }, () => {
          this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
          this.isLoading = null;
        });

      }, 1000);
    }
  }
  private updatePhoto(file: any, fileType: any): Observable<PreparedQuestComment | string | undefined> {
    return this.cropperService.getImagePreview(file)
    .pipe(
      mergeMap((imgBase64: string) => {
        if (this.isEditMode) {
          const payload = {
            questId: this.questId,
            questImage: imgBase64,
            caption: this.comment.comment,
            contentType: fileType
          };
          return this.questService.addQuestGalleryPhoto(payload)
            .pipe(
              mergeMap((galleryImg: QuestGalleryImage): Observable<PreparedQuestComment> => {
                return this.questService
                  .editQuestComment(this.comment.id, this.form.value.editor, galleryImg.id)
                  .pipe(
                    map((res) => {
                      this.questService.callUpdateForQuest('gallery');
                      return res;
                    }),
                    catchError(() => {
                      this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
                      this.isLoading = null;
                      return of(undefined);
                    })
                  );
              }),
              catchError(() => {
                this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
                this.isLoading = null;
                return of(undefined);
              })
            );
        } else {
          return of(imgBase64);
        }
      }),
      catchError((err) => {
        this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
        this.isLoading = null;
        return err;
      })
    );
  }
  private checkPreview(): {key: string, value: boolean} {
    if (this.base64) {
      return { key: 'BASE_64', value: true };
    }
    if (!this.isEditMode) {
      return { key: null, value: false };
    }
    if (!this.comment.image ? true : typeof this.comment.image === null) {
      return { key: null, value: false };
    }
    if (!this.comment.image.hasOwnProperty('questImageUrl')) {
      return { key: null, value: false };
    }
    return { key: 'IMAGE_URL', value: true };
  }
  private postImage(): Observable<PreparedQuestComment | undefined> {
    if (this.base64) {
      const payload = {
        questId: this.questId,
        questImage: this.base64,
        caption: this.form.value.editor,
        contentType: this.contentType
      };
      return this.questService.addQuestGalleryPhoto(payload)
        .pipe(catchError(() => {
          this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
          return of(undefined);
        }));
    } else {
      return of(undefined);
    }
  }
  private addComment(imageId?: number): Observable<PreparedQuestComment> {
    return this.questService
      .addNewQuestComment(this.questId, this.viewerId, this.form.value.editor, null, imageId);
  }
  private editComment(imageId?: number): Observable<PreparedQuestComment> {
    return this.questService
      .editQuestComment(this.comment.id, this.form.value.editor, imageId);
  }
  private toggleEditComment() {
    if (this.isValueChanged) {
      this.submit(true);
    } else {
      const payload: ToggleEditCommentState = {
        commentId: this.comment.id,
        isEditable: !this.comment.editable
      };
      this.store.dispatch(new ToggleEditComment(payload));
    }
  }
}
