import {ReferenceDataService} from "../../../../_services/reference-data.service";
import {Subject} from "rxjs";
import {KeyValuePair, TicketFlowService} from "./ticket-flow.service";

export class TicketFlowFormTemplate {
  countries: KeyValuePair[] = [];
  states: KeyValuePair[] = [];
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    protected referenceDataService: ReferenceDataService,
    protected ticketFlowService: TicketFlowService
  ) {
  }

  buildOption = (key: string, name: string) => {
    const country = key.toLowerCase();
    return `
      <i class='flag flag-${country}'></i>
      <span>${name}</span>
    `;
  };

  loadCountries() {
    this.referenceDataService.getSupportedCountries().subscribe((res: { [key: string]: string }) => {
      this.countries = this.ticketFlowService.prepareKeys(res);
      setTimeout(() => {
        this.eventsSubject.next();
      });
    });
  }

  selectCountry(countryCode: string) {
    const country = (countryCode + '').toLowerCase();
    if (['us', 'ca', 'ch'].includes(country)) {
      this.loadStates(country);
    } else {
      this.states = [];
      setTimeout(() => {
        this.eventsSubject.next();
      });
    }
  }
  loadStates(country: string) {
    this.referenceDataService.getRegions(country).subscribe((res: { [key: string]: string }) => {
      this.states = this.ticketFlowService.prepareKeys(res);
      setTimeout(() => {
        this.eventsSubject.next();
      });
    });
  }

}
