<form class="c-dl-search__header" [formGroup]="searchForm" >
    <input
      class="c-dl-search__control"
      formControlName="search"
      placeholder="Search"
      #searchField
      (focus)="onFocus()"
      [ngClass]="{'is-active': isOpened}"/>

    <div class="c-dl-search__tools">
        <button class="c-dl-search__tool c-dl-search__submit h-btn-reset" type="button">
            <svg class="icon icon-search"><use xlink:href="#icon-search"></use></svg>
        </button>
        <button *ngIf="isOpened" class="c-dl-search__tool c-dl-search__done h-btn-reset" type="button" (click)="close()">
            <span>Done</span>
        </button>
    </div>
</form>
<div class="c-dl-search__inner" *ngIf="isOpened">
  <div class="c-dl-search__body h-loader"
    appLoadmoreTrigger
    [ngClass]="{'is-loading': isLoading}"
    [innerEl]="inner"
    [newContentLoaded]="newContentLoaded"
    (triggerLoadMore)="onTriggerLoadMore($event)">
    <div class="c-dl-search__list h-loader__caption">
      <div
        #inner
        infiniteScroll
        [infiniteScrollDistance]="0"
        [infiniteScrollThrottle]="50"
        [immediateCheck]="true"
        [alwaysCallback]="true"
        [infiniteScrollContainer]="'.c-dl-search__body'"
        [fromRoot]="true"
        (scrolled)="loadMore(false, false, true)">
        <div class="c-dl-search__item"
          *ngFor="let item of results; index as i">

          <app-friends-item *ngIf="item.type === 'user'"
            class="c-dl-search__item__friend c-my-friends__item"
            [friendInfo]="item"
            [point]="'globalSearch'"
            [isManualClickEvent]="true"
            (manualClick)="selectItem(i)">
          </app-friends-item>
          <!-- [routerLink]="['/quest-detail', item.questId, (usersQuests.hasOwnProperty(item.questId) ? usersQuests[item.questId].seoSlugs.userId : item.userId)]" -->
          <a class="c-dl-search__item__quest"
             *ngIf="item.type === 'quest'"
             (click)="selectItem(i)"
             href="javascript:void(null)">
            <div class="c-dl-search__item__quest__img h-object-fit">
              <img [src]="prepareCoverPhoto(item.questImageURL, item.pillar).jpg" alt="">
            </div>
            <div class="c-dl-search__item__quest__title">
              <span>{{ item.questName }}</span>
            </div>
          </a>

        </div>
        <div class="c-search__more h-xs-loader"
             *ngIf="loadmoreEnabled"
             [ngClass]="{'is-loading': results.length > 0 && isLoadingMore}">
          <i class="h-xs-loader__control"></i>
        </div>
      </div>
    </div>
    <div class="c-search__empty" *ngIf="results.length === 0 && !isLoading">
      <span>No results</span>
    </div>
    <div class="h-loader__control is-currentColor"></div>
  </div>
</div>
