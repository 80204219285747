import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QuestService} from 'src/app/_services/quest.service';
import {QuestTeam} from 'diemlife-commons/dist/diemlife-commons-model';

@Injectable({
  providedIn: 'root'
})
export class UiSelectTeamService {

  constructor(private questService: QuestService) {
  }

  loadQuestTeams(questId: number): Observable<QuestTeam[]> {
    return this.questService.getQuestTeams(questId)
    .pipe(map(teams => teams || []));
  }
}
