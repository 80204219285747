<a class="c-my-friends__item__img"
   href="javascript:void(0)"
   [routerLink]="isManualClickEvent ? null : (friendInfo.userId > 0 && !friendInfo.anonymous ? ['/profile', friendInfo.userName] : null)"
   [ngSwitch]="point"
   (click)="onManualClick()"
   aria-label="Go to friends profile">
  <app-profile-avatar
    class="c-my-friends__item__img__inner"
    [avatar]="friendInfo.avatarUrl || friendInfo.profilePictureURL"
    [firstName]="friendInfo.firstName || friendInfo.userFullName.split(' ')[0]"
    [isBrand]="false"
    [lastName]="friendInfo.lastName || friendInfo.userFullName.split(' ')[1]"
    [userName]="friendInfo.userName">
  </app-profile-avatar>
  <app-quest-progress *ngIf="point === 'member'" class="c-my-friends__item__progress" [value]="friendInfo.percentage"></app-quest-progress>
  <div class="c-my-friends__item__icon" *ngSwitchCase="'friendsNeededAccept'">
    <svg class="icon icon-arrow_left"><use xlink:href="#icon-arrow_left"></use></svg>
  </div>
  <div class="c-my-friends__item__icon" *ngSwitchCase="'friendsNoAction'">
    <svg class="icon icon-arrow_right"><use xlink:href="#icon-arrow_right"></use></svg>
  </div>
  <span class="c-my-friends__item__img__price"
        *ngIf="point === 'member' && friendInfo.amountBackedSum && showBacking">{{ (friendInfo.amountBackedSum ? friendInfo.amountBackedSum / 100.0 : 0) | currency: 'USD' }}</span>
</a>
<div class="c-my-friends__item__caption" [ngSwitch]="point">
  <a class="c-my-friends__item__title"
     [routerLink]="isManualClickEvent ? null : (friendInfo.userId > 0 && !friendInfo.anonymous ? ['/profile', friendInfo.userName] : null)"
     href="javascript:void(0)"
     (click)="onManualClick()">
     <ng-container *ngTemplateOutlet="point === 'globalSearch' ? defaultTitleFirst : defaultTitleSecond"></ng-container>
     <ng-template #defaultTitleFirst>
       <span *ngIf="friendInfo.userFullName">{{ friendInfo.userFullName }}</span>
       <span *ngIf="!friendInfo.userFullName">{{ friendInfo.firstName }}</span>
       <span *ngIf="!friendInfo.userFullName">{{ friendInfo.lastName }}</span>
     </ng-template>
     <ng-template #defaultTitleSecond>
       <span>{{ friendInfo.firstName || friendInfo.userFullName.split(' ')[0] }}</span>
       <span>{{ friendInfo.lastName || friendInfo.userFullName.split(' ')[1] }}</span>
     </ng-template>
  </a>
  <div class="c-my-friends__item__tools" *ngSwitchCase="'globalSearch'">
    <span class="c-my-friends__item__tool is-gray" *ngIf="friendInfo.status === 'approved'">
      <div class="e-oval is-orient" [class.is-loading]="isLoading">
        <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
      </div>
      <span>Accepted</span>
    </span>
    <span class="c-my-friends__item__tool is-gray" *ngIf="friendInfo.status === 'sent'">
      <div class="e-oval is-orient" [class.is-loading]="isLoading">
        <svg class="icon icon-plane"><use xlink:href="#icon-plane"></use></svg>
      </div>
      <span>Requested</span>
    </span>
    <span class="c-my-friends__item__tool" *ngIf="friendInfo.status === 'received'" (click)="acceptFriendRequest()">
      <div class="e-oval is-green" [class.is-loading]="isLoading">
        <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
      </div>
      <span>Accept</span>
    </span>
    <span class="c-my-friends__item__tool" *ngIf="!friendInfo.status" (click)="addFriend()">
      <div class="e-oval is-green" [class.is-loading]="isLoading">
        <svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
      </div>
      <span>Add Friend</span>
    </span>
  </div>
  <!-- <div class="c-my-friends__item__tools" *ngSwitchCase="'search'">
    <span class="c-my-friends__item__tool is-gray" *ngIf="isRequested">
      <div class="e-oval is-orient" [class.is-loading]="isLoading">
        <svg class="icon icon-plane"><use xlink:href="#icon-plane"></use></svg>
      </div>
      <span>Requested</span>
    </span>
    <span class="c-my-friends__item__tool is-gray" *ngIf="isAccepted">
      <div class="e-oval is-orient" [class.is-loading]="isLoading">
        <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
      </div>
      <span>Accepted</span>
    </span>
    <span class="c-my-friends__item__tool" *ngIf="!isRequested && !isAccepted" (click)="addFriend()">
      <div class="e-oval is-green" [class.is-loading]="isLoading">
        <svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg>
      </div>
      <span>Add Friend</span>
    </span>
  </div> -->
  <div class="c-my-friends__item__tools" *ngSwitchCase="'friendsNeededAccept'">
    <span class="c-my-friends__item__tool is-huge" *ngIf="!isAccepted"  (click)="acceptFriendRequest()">
      <div class="e-oval is-green" [class.is-loading]="isLoading">
        <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
      </div>
      <span>Accept</span>
    </span>
    <span class="c-my-friends__item__tool" *ngIf="!isAccepted" (click)="cancelFriendRequest()">
      <div class="e-oval is-transparent" [class.is-loading]="isLoading">
        <svg class="icon icon-cancel"><use xlink:href="#icon-cancel"></use></svg>
      </div>
      <span>Decline</span>
    </span>
    <span class="c-my-friends__item__tool is-gray" *ngIf="isAccepted">
      <div class="e-oval is-orient" [class.is-loading]="isLoading">
        <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
      </div>
      <span>Accepted</span>
    </span>
  </div>
  <div class="c-my-friends__item__tools" *ngSwitchCase="'friendsNoAction'">
    <span class="c-my-friends__item__tool" (click)="cancelSentFriendRequest()">
      <div class="e-oval is-transparent" [class.is-loading]="isLoading">
        <svg class="icon icon-cancel"><use xlink:href="#icon-cancel"></use></svg>
      </div>
      <span>Cancel</span>
    </span>
  </div>
  <div class="c-my-friends__item__tools" *ngSwitchCase="'accepted'">
    <span class="c-my-friends__item__tool" (click)="confirmRemoveFriend(friendInfo.firstName)">
      <div class="e-oval is-transparent" [class.is-loading]="isLoading">
        <svg class="icon icon-cancel"><use xlink:href="#icon-cancel"></use></svg>
      </div>
      <span>Remove</span>
    </span>
  </div>
  <div class="c-my-friends__item__statuses" *ngSwitchCase="'member'">
    <div class="c-my-friends__item__statuses__item" *ngIf="memberStatuses.Admin || memberStatuses.Creator" [ngClass]="{'is-silver': memberStatuses.Admin, 'is-gold': memberStatuses.Creator}">
      <svg class="icon icon-shield"><use xlink:href="#icon-shield"></use></svg>
    </div>
    <!-- <div class="c-my-friends__item__statuses__item" *ngIf="memberStatuses.Backer">
      <svg class="icon icon-balance"><use xlink:href="#icon-balance"></use></svg>
    </div>
    <div class="c-my-friends__item__statuses__item" *ngIf="memberStatuses.Supporter">
      <svg class="icon icon-help"><use xlink:href="#icon-help"></use></svg>
    </div> -->
    <!-- <div class="c-my-friends__item__statuses__item" *ngIf="memberStatuses.Doer">
      <svg class="icon icon-Quest"><use xlink:href="#icon-Quest"></use></svg>
    </div> -->
    <!-- <div class="c-my-friends__item__statuses__item" *ngIf="memberStatuses.Achiever">
      <svg class="icon icon-update"><use xlink:href="#icon-update"></use></svg>
    </div> -->
    <!-- <div class="c-my-friends__item__statuses__item" *ngIf="memberStatuses.Interested">
      <svg class="icon icon-saved"><use xlink:href="#icon-saved"></use></svg>
    </div> -->
  </div>
</div>
