<main class="c-my-friends h-header-pad-top">
  <div class="c-my-quests__header">
    <nav class="c-my-quests__nav">
      <ul class="c-my-quests__nav__list c-container c-container_md sd-no-pad">
        <li [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a routerLink='/friends'>
            <svg class="icon icon-friends"><use xlink:href="#icon-friends"></use></svg>
            <span>My friends</span>
          </a>
        </li>
        <li [routerLinkActive]="['is-active']">
          <a routerLink="requests">
            <svg class="icon icon-add-friend"><use xlink:href="#icon-add-friend"></use></svg>
            <span>REQUESTS</span>
          </a>
        </li>
        <li [routerLinkActive]="['is-active']">
          <a routerLink="search">
            <svg class="icon icon-loop"><use xlink:href="#icon-loop"></use></svg>
            <span>Find Friends</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="c-my-friends__body">
    <router-outlet></router-outlet>
  </div>
</main>