import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageService } from '../../../_services/image.service';
import {QuestService} from '../../../_services/quest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {
  CloseTeamModal,
  DiemlifeCommonsModelService,
  FundraisingLinkType,
  JoinTeam,
  MilestonesLogic,
  OpenTeamModal,
  Quest,
  QuestActivityTracker,
  QuestDoer,
  QuestGalleryImage,
  QuestLogic,
  QuestTask,
  QuestTeam,
  QuestUserInfo,
  TeamJoined
} from 'diemlife-commons/dist/diemlife-commons-model';
import {forkJoin, Observable, ObservableInput, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {ModalService} from '../../main/modal/modal.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../_services/snotify-config.service';
import {MyQuestsSevice} from '../my-quests/my-quests.service';
import {DomSanitizer, Meta, SafeResourceUrl, Title} from '@angular/platform-browser';
import {saveAs} from 'file-saver';
import {ProfileService} from '../profile/profile.service';
import {getYoutubeId} from 'src/app/directives/youtube-validation/youtube-validation.directive';
import {AppState, questDetailState} from '../../../_store/app.reducers';
import {SaveFundraiserPayload} from '../../main/funds-modal/funds-modal.component';
import {QuestDetailStatus, UploadTeamImageModalMode} from '../../main/quest/quest.type';
import {DlMilestonesClearData, SetMilestones} from '../../main/dl-milestones/store/dl-milestones.actions';
import {DlMilestoneManageModes, TaskGroup} from '../../main/dl-milestones/dl-milestones.typings';
import {DlMilestonesService} from '../../main/dl-milestones/dl-milestones.service';
import * as fromProfile from '../profile/store/profile.reducer';
import * as fromAuth from '../../main/auth/store/auth.reducer';
import * as fromDlMilestones from '../../main/dl-milestones/store/dl-milestones.reducer';
import {MembersComponent} from '../../main/members/members.component';



@Component({
  selector: 'app-quest-detail',
  templateUrl: './quest-detail.component.html',
  styleUrls: ['./quest-detail.component.styl'],
})
export class QuestDetailComponent implements OnInit, OnDestroy {
  userInfo: QuestUserInfo;
  currentUser: QuestUserInfo;
  status: QuestDetailStatus;
  questId: number;
  userId: number;
  userInfoState: Observable<fromProfile.State>;
  dlMilestoneState: Observable<fromDlMilestones.State>;
  milestoneSubscription: Subscription;
  authState: Observable<fromAuth.State>;
  private authSubscription: Subscription;
  private routeParamsSubscription: Subscription;
  private questSubscription: Subscription;
  private questTeamsSubscription: Subscription;
  private joinedTeamSubscription: Subscription;
  private questDetailSubscription: Subscription;

  private viewerActivityTracker: QuestActivityTracker;

  allowEditQuest = false;
  viewerActivityStatus: string;
  hasUrlUserQuestActivity = false;
  hasCurrentUserQuestActivity = false;
  isCurrentUsersRepresentation = false;
  isCurrentUserHasFundraisingLink = false;
  isCurrentUserQuestDoer = false;
  isDoerHasFundraisingLink = false;
  fundraiseModalMode: string;
  fundraisingLink: FundraisingLinkType = null;
  fundraisingInited = false;
  editableQuest: boolean;

  fundraisingButtonPresent = false;
  fundraisingButtonForcedHidden = false;

  isLoaded = false;
  isProgress = false;
  data: Quest;
  openedModal: string;
  hasEvent: boolean;
  tabs = {
    items: [
      {key: 'details', value: 'Details', available: true},
      {key: 'leaderboard', value: 'Leaderboard', available: false},
      {key: 'comments', value: 'NEWSFEED', available: true}
    ],
    selected: 'details'
  };
  galleryPhotoIsLoading: boolean;
  youtubeUrl: SafeResourceUrl;
  viewStatus: number;
  downloadProgress = false;
  members: QuestDoer[] = [];
  participantUserIds: any[] = [];
  teams: QuestTeam[] = [];
  team: QuestTeam = null;
  teamCreator: fromProfile.State = null;
  allowCheckMilestones = false;
  uploadTeamImageModalMode = UploadTeamImageModalMode;
  selectedUploadTeamImageModalMode: UploadTeamImageModalMode = null;
  milestoneModes = DlMilestoneManageModes;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private questService: QuestService,
    private profileService: ProfileService,
    private rootStore: Store<AppState>,
    private store: Store<AppState>,
    private modalService: ModalService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private myQuestsSevice: MyQuestsSevice,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private milestonesService: DlMilestonesService,
    private imageService: ImageService
  ) {
    this.userInfoState = this.store.select('userInfo');
    this.authState = this.store.select('auth');
    this.dlMilestoneState = this.store.select('dlMilestones');

    this.meta.addTag({property: 'og:title', content: ''});
    this.meta.addTag({property: 'og:description', content: ''});
    this.meta.addTag({property: 'og:url', content: this.router.url});
    this.meta.addTag({property: 'og:type', content: 'website'});
    this.meta.addTag({property: 'og:image', content: ''});
    this.meta.addTag({property: 'og:image:width', content: ''});
    this.meta.addTag({property: 'og:image:height', content: ''});
  }

  ngOnInit() {
    this.isLoaded = false;

    this.routeParamsSubscription = this.route.params.subscribe(params => {
      this.questId = Number(params.questId);
      this.userId = Number(params.userId);
      this.getMembers();

      this.loadPageData();
    });

    this.userInfoState.pipe(take(1)).subscribe(currentUser => {
      this.currentUser = currentUser;

      this.authSubscription = this.store.pipe(
        select('auth'),
        map(state => state.authenticated),
        distinctUntilChanged(),
      ).subscribe(authenticated => {

        if (authenticated) {
          this.viewStatus = null;
        }

        const userChangeSubscription = this.userInfoState.subscribe((newCurrentUser: fromProfile.State) => {
          const isDifferentUser = (previousUser: fromProfile.State, nextUser: fromProfile.State): boolean => {
            if (previousUser && nextUser) {
              return previousUser.id !== nextUser.id;
            } else {
              return !(!previousUser && !nextUser);
            }
          };

          let currentUserChanged = false;
          if (authenticated) {
            if (newCurrentUser && newCurrentUser.id && isDifferentUser(this.currentUser, newCurrentUser)) {
              this.currentUser = newCurrentUser;

              currentUserChanged = true;
            } else {
              return;
            }
          } else {
            if (this.currentUser && this.currentUser.id) {
              this.currentUser = null;

              currentUserChanged = true;
            }
          }

          if (userChangeSubscription) {
            userChangeSubscription.unsubscribe();
          }

          if (currentUserChanged) {
            console.log('Current user changed - reloading', this.currentUser);

            this.loadPageData();
          }
        });
      });
    });

    this.milestoneSubscription = this.dlMilestoneState.subscribe(() => {
      if (this.data && this.data.quest) {
        this.updateQuestCompletionPercent(this.data.quest.id, this.getQuestCompletionUser());
      }
    });

    this.joinedTeamSubscription = this.rootStore.pipe(
      select(questDetailState),
      map(state => state.joinedTeam)
    ).subscribe(team => {
      if (team) {
        this.isProgress = false;
        this.rootStore.dispatch(new TeamJoined(null));
        setTimeout(() => {
          this.router.navigate(['my-quests']);
        });
      }
    });
  }

  ngOnDestroy() {
    this.modalService.remove('cropper-modal');
    this.modalService.remove('fundraise-modal');
    this.modalService.remove('back-fundraiser-modal');
    this.modalService.remove('start-quest-in-team-modal');
    this.modalService.remove('upload-team-image-modal');

    if (this.questSubscription) {
      this.questSubscription.unsubscribe();
    }
    if (this.questTeamsSubscription) {
      this.questTeamsSubscription.unsubscribe();
    }
    if (this.joinedTeamSubscription) {
      this.joinedTeamSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
    if (this.questDetailSubscription) {
      this.questDetailSubscription.unsubscribe();
    }
    if (this.milestoneSubscription) {
      this.milestoneSubscription.unsubscribe();
    }

    this.clearMetaTags();

    this.store.dispatch(new DlMilestonesClearData());
  }

  ngAfterViewInit() {
    this.questSubscription = this.questService.getUpdateForQuest().subscribe((status: string) => {
      switch (status) {
        case 'gallery':
          this.updateGallery();
          break;
        case 'saved':
          this.getMembers();
          break;
        case 'doQuest':
          this.doQuest();
          break;
        default:
          break;
      }
    });
  }


  loadPageData(): void {
    this.isLoaded = false;
    this.data = null;
    this.teams.length = 0;
    this.team = null;
    this.teamCreator = null;

    this.isDoerHasFundraisingLink = false;
    this.isCurrentUserHasFundraisingLink = false;

    this.fundraisingButtonPresent = false;
    this.fundraisingButtonForcedHidden = false;
    this.fundraisingInited = false;

    this.clearMetaTags();

    this.questService.getQuestDetail(this.questId, this.userId).pipe(take(1)).subscribe((questData) => {
      this.data = questData;

      const imgUrl = this.questService.prepareCoverPhoto(questData.quest.photo, questData.quest.pillar).jpg;
      const imageResult = this.imageService.getImageDimensions(imgUrl).subscribe((response) => {
        this.setMetaTags(this.data, imgUrl, response.imageWidth, response.imageHeight);
        imageResult.unsubscribe();
      });

      this.milestonesService.getQuestGroupTasks(this.questId, this.userId).subscribe((taskGroups: TaskGroup[]) => {
        this.store.dispatch(new SetMilestones(taskGroups));
      });

      const sources: [ObservableInput<QuestTeam[]>, ObservableInput<FundraisingLinkType>, ObservableInput<FundraisingLinkType>] = [

        this.isQuestInTeamMode()
          ? this.questService.getQuestTeams(this.questId).pipe(take(1))
          : Observable.of([]).pipe(),

        this.data.quest.fundraising
          ? this.questService.getFundraisingLink(this.questId, this.userId).pipe(take(1))
          : Observable.of(null).pipe(),

        this.data.quest.fundraising && this.currentUser && this.currentUser.id
          ? this.questService.getFundraisingLink(this.questId, this.currentUser.id).pipe(take(1))
          : Observable.of(null).pipe()

      ];

      forkJoin(sources).subscribe(([questTeams, doersLink, currentUsersLink]) => {
        this.teams.push(...questTeams);
        this.team = questTeams.filter(team => team.teamId === this.data.doerTeamId).pop();
        if (this.team) {
          this.profileService.getUserInfoById(this.team.creatorId).pipe(take(1)).subscribe((profile: fromProfile.State) => {
            this.teamCreator = profile;
          });
          console.log('Team', this.team);
        }

        this.fundraisingLink = doersLink;
        this.isDoerHasFundraisingLink = !!doersLink;
        this.fundraisingButtonPresent = !!doersLink;
        this.fundraisingButtonForcedHidden = !!doersLink && !doersLink.displayBtn;
        this.isCurrentUserHasFundraisingLink = !!currentUsersLink;
        this.fundraisingInited = true;

        this.getQuestInfo();
      });
    });
  }

  getQuestInfo() {
    this.allowCheckMilestones = false;
    this.isLoaded = false;
    this.editableQuest = false;
    this.fundraiseModalMode = null;

    this.tabs.items.find(item => item.key === 'leaderboard').available = this.data.quest.leaderboardEnabled
      && !!this.data.quest.leaderboardAttributes.length;

    this.hasEvent = this.data.hasEvent;

    if (this.data.questVideoUrl) {
      this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.questVideoUrl);
    }

    this.status = QuestDetailStatus.Public;

    this.allowEditQuest = QuestService.isEditableByUser(this.data.quest, this.currentUser);
    this.viewerActivityTracker = DiemlifeCommonsModelService.buildQuestActivityTracker(this.data.viewerActivityInfo, this.currentUser);
    this.hasUrlUserQuestActivity = QuestService.hasUserActivity(this.data.activityInfo, this.userId);
    this.hasCurrentUserQuestActivity = this.viewerActivityTracker && this.viewerActivityTracker.hasUserActivity();
    this.isCurrentUsersRepresentation = this.currentUser && this.currentUser.id === this.userId;
    this.status = this.buildViewerDetailsStatus();
    if (this.data.viewerActivityInfo && this.data.viewerActivityInfo.status) {
      this.viewerActivityStatus = this.data.viewerActivityInfo.status;
    }

    this.getQuestCompletionPercent(this.questId, this.getQuestCompletionUser()).subscribe(() => {
      this.allowCheckMilestones = MilestonesLogic.milestonesCheckingAllowed(this.data, this.currentUser);
    });

    this.editableQuest = QuestService.isEditableByUser(this.data.quest, this.currentUser);

    this.isLoaded = true;
  }

  private getMembers() {
    this.members.length = 0;
    this.questService.getQuestMembers(this.questId, this.userId).subscribe((members: QuestDoer[]) => {
      this.members.push(...members);
      // if memberStatus/QuestMemberStatus is Backer or Doer
      this.getQuestParticipantMaps();

      if (this.currentUser && this.currentUser.id) {
        this.isCurrentUserQuestDoer = QuestLogic.checkIfCurrentUserQuestDoer(this.members, this.currentUser.id);
      }
    });
  }

  private getQuestParticipantMaps() {
    const participants = this.members.filter(item => item.memberStatus.includes('Doer') || item.memberStatus.includes('Backer'));
    this.participantUserIds = participants.map(item => item.userId);
    
  }

  get isDefaultTeamOnlyPresent(): boolean {
    return this.teams.length === 1 && this.teams[0].creatorId === this.userId;
  }

  setMetaTags(questData: Quest, questImgUrl: string, questImgWidth: string, questImgHeight: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = questData.quest.questFeed;
    const description = tmp.innerText.replace(/&nbsp;/g, ' ');
    tmp.remove();

    this.titleService.setTitle('DIEMlife | ' + questData.quest.title);
    this.meta.updateTag({property: 'og:title', content: questData.quest.title});
    this.meta.updateTag({property: 'og:description', content: description || ''});
    this.meta.updateTag({property: 'og:url', content: questData.questShareUrl});
    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({property: 'og:image', content: questImgUrl});
    this.meta.updateTag({property: 'og:image:width', content: questImgWidth || ''});
    this.meta.updateTag({property: 'og:image:height', content: questImgHeight || ''});
  }

  clearMetaTags() {
    this.titleService.setTitle(null);
    this.meta.updateTag({property: 'og:title', content: ''});
    this.meta.updateTag({property: 'og:image', content: ''});
    this.meta.updateTag({property: 'og:description', content: ''});
    this.meta.updateTag({property: 'og:type', content: ''});
    this.meta.updateTag({property: 'og:image', content: ''});
    this.meta.updateTag({property: 'og:image:width', content: ''});
    this.meta.updateTag({property: 'og:image:height', content: ''});
  }

  getQuestCompletionUser(): number {
    if (this.hasCurrentUserQuestActivity && this.isModePaceYourself()) {
      return this.currentUser.id;
    } else if (this.hasCurrentUserQuestActivity && this.isModeTeam() && this.team) {
      return this.team ? this.team.creatorId : this.userId;
    } else {
      if (this.hasUrlUserQuestActivity) {
        return this.userId;
      } else {
        return this.data.quest.createdBy;
      }
    }
  }

  getQuestCompletionPercent(questId: number, userId: number) {
    return new Observable((observer) => {
      this.questService.getQuestCompletionPercent(questId, userId).subscribe((completion: any) => {
        this.data = {...this.data, ...completion};
        observer.next();
        observer.complete();
      }, () => {
        observer.complete();
      });
    });
  }

  private updateQuestCompletionPercent(questId: number, userId: number) {
    this.getQuestCompletionPercent(this.questId, userId).subscribe(() => {
    });
  }

  openModal(id: string, mode?: string) {
    this.openedModal = id;
    const options = mode ? {mode} : null;
    this.modalService.open(id, options);
  }

  closeModal() {
    this.modalService.close(this.openedModal);
    setTimeout(() => {
      this.openedModal = '';
    });
  }

  completeQuest() {
    let uncompletedTasks = false;
    this.data.questTasks.forEach((task: QuestTask) => {
      if (!task.isTaskCompleted) {
        uncompletedTasks = true;
      }
    });
    if (uncompletedTasks && this.isModePaceYourself()) {
      this.snotifyService.confirm(
        `There are unchecked milestones, do you still want to complete "${this.data.quest.title}" ?`,
        null,
        this.snotifyConfigService.getConfig({
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            {
              text: 'Yes',
              bold: true,
              action: (toast) => {
                this.confirmMilestonesAutoComplete();
                this.snotifyService.remove(toast.id);
              }
            },
            {
              text: 'No',
              bold: true,
              action: (toast) => {
                this.snotifyService.remove(toast.id);
              }
            }
          ]
        })
      );
    } else {
      this.snotifyService.confirm(
        `You did it! Want to complete "${this.data.quest.title}" ?`,
        null,
        this.snotifyConfigService.getConfig({
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          buttons: [
            {
              text: 'Yes',
              bold: true,
              action: (toast) => {
                this.doCompleteQuest(false);
                this.snotifyService.remove(toast.id);
              }
            },
            {
              text: 'No',
              bold: true,
              action: (toast) => {
                this.snotifyService.remove(toast.id);
              }
            }
          ]
        })
      );
    }
  }

  confirmMilestonesAutoComplete() {
    this.snotifyService.confirm(
      `Do you want to complete all milestones of "${this.data.quest.title}"?`,
      null,
      this.snotifyConfigService.getConfig({
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.doCompleteQuest(true);
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.doCompleteQuest(false);
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  doCompleteQuest(completeMilestones: boolean): void {
    this.isProgress = true;
    this.questService.completeQuest(this.questId, completeMilestones, this.data.quest.geoTriggerEnabled).subscribe(() => {
      this.isProgress = false;
      this.snotifyService.success(`Successfully completed "${this.data.quest.title}"!`, null, this.snotifyConfigService.getConfig());
      this.router.navigate(['/my-quests', 'completed']);
    });
  }

  doQuest(): void {
    if (this.isProgress || this.hasCurrentUserQuestActivity) {
      return;
    }
    this.isProgress = true;
    this.questService.doQuest(this.questId, this.userId, null, this.data.quest.geoTriggerEnabled).subscribe(started => {
      this.isProgress = false;
      this.afterDoQuest(started);
    }, () => {
      this.isProgress = false;
      this.snotifyService.error('There was an error starting Quest. Please try again', null, this.snotifyConfigService.getConfig());
    });
  }

  afterDoQuest(started) {
    this.closeModal();
    if (started) {
      let notificationWording;
      if (this.isQuestInTeamMode()) {
        notificationWording = `Successfully joined "${this.data.quest.title}"!`;
      } else {
        notificationWording = `Successfully started "${this.data.quest.title}"!`;
      }
      this.snotifyService.success(notificationWording, null, this.snotifyConfigService.getConfig());
      this.router.navigate(['/my-quests', 'active']);
    } else {
      this.snotifyService.warning('Looks like you are already doing this Quest', null, this.snotifyConfigService.getConfig());
    }
  }

  isQuestInTeamMode() {
    return this.data && this.data.quest && this.data.quest.mode === 'diyMode';
  }

  isQuestInSupportMode() {
    return this.data && this.data.quest && this.data.quest.mode === 'viewOnlyMode';
  }

  private getCurrentUserTeam(): QuestTeam {
    if (this.currentUser && this.teams && this.teams.length) {
      return this.teams.find(team => team.memberIds.includes(this.currentUser.id));
    } else {
      return null;
    }
  }

  confirmRepeatQuest() {
    this.snotifyService.confirm(
      `Want to do "${this.data.quest.title}" again?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.repeatQuest();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  repeatQuest() {
    if (this.data.viewerActivityInfo.repeatable && this.isStatusComplete() && !this.data.quest.fundraising) {
      this.isProgress = true;
      this.myQuestsSevice.repeatQuest(this.data.quest.id, this.data.quest.geoTriggerEnabled).subscribe(() => {
        this.isProgress = false;
        this.snotifyService.success(`Quest "${this.data.quest.title}" successfully restarted`, null, this.snotifyConfigService.getConfig());
        this.router.navigate(['/my-quests', 'active']);
      }, () => {
        this.isProgress = false;
        this.snotifyService.error('There was an error restarting Quest. Please try again', null, this.snotifyConfigService.getConfig());
      });
    } else {
      this.snotifyService.error('This Quest cannot be repeated', null, this.snotifyConfigService.getConfig());
    }
  }

  startFundraiser(payload: SaveFundraiserPayload) {
    this.questService.startFundraising(
      this.questId,
      this.currentUser.id,
      payload.targetAmount,
      'usd',
      payload.campaignName,
      payload.coverImageUrl
    ).subscribe((data) => {
      this.fundraisingLink = data;
      if (!this.hasCurrentUserQuestActivity) {
        this.doQuest();
      }
      this.modalService.close('fundraise-modal');
    }, (error) => {
      console.log('Fundraising saving failed', error);
      this.modalService.close('fundraise-modal');
      this.snotifyService.error('Fundraising saving failed', null, this.snotifyConfigService.getConfig());
    });
  }

  backFundraiser() {
    this.modalService.close('back-fundraiser-modal');
    this.router.navigate(['/donate', this.fundraisingLink.quest.id, this.fundraisingLink.doer.id]);
  }

  isStatusInProgress(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isStatusInProgress();
  }

  isStatusComplete(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isStatusComplete();
  }

  isModeSupportOnly(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isModeSupportOnly();
  }

  isModePaceYourself(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isModePaceYourself();
  }

  isModeTeam(): boolean {
    return this.viewerActivityTracker && this.viewerActivityTracker.isModeTeam();
  }

  iAmCreator() {
    return this.currentUser && this.currentUser.id === this.data.quest.user.id;
  }

  iAmDoer() {
    return this.currentUser && this.currentUser.id === this.userId;
  }

  iAmAnonymous() {
    return !this.currentUser || this.currentUser.id === null;
  }

  isDoerIsSimpleDoer() {
    return this.data.quest.fundraising
      ? !this.iAmCreator() && !this.isDoerIsCreator() && !this.isDoerHasFundraisingLink
      : false;
  }

  isDoerIsCreator() {
    return this.userId === this.data.quest.user.id;
  }

  isCurrentUserIsTeamCreator() {
    return this.currentUser && this.team ? this.team.creatorId === this.currentUser.id : false;
  }

  private isDoersTeam() {
    return this.isQuestInTeamMode()
      && this.data.activityInfo.mode === 'diyMode'
      && this.team
      && this.team.creatorId === this.data.activityInfo.userId;
  }

  private isCreatorsTeam() {
    return this.isQuestInTeamMode() && this.isDoerIsCreator() && this.isDoerHasFundraisingLink;
  }

  addGalleryPhoto(res: { croppedImage: QuestGalleryImage, selectedArea: string }) {
    if (res.selectedArea === 'questGalleryPhoto') {
      this.addImageToCarousel(res.croppedImage);
    }
  }

  addImageToCarousel(image: QuestGalleryImage) {
    const questImages: QuestGalleryImage[] = [image, ...this.data.questImages];
    this.data.questImages = [];
    setTimeout(() => {
      this.data.questImages = questImages;
      this.galleryPhotoIsLoading = false;
    });
  }

  canCurrentUserEditMilestones() {
    return (this.allowEditQuest || (this.data.quest.editableMilestones && this.isStatusInProgress())) && !this.isModeSupportOnly();
  }

  updateMilestones(milestones: QuestTask[]) {
    const progressChanged = this.data.questTasks.length !== milestones.length;
    this.data.questTasks = milestones;
    if (progressChanged) {
      this.getQuestCompletionPercent(this.data.quest.id, this.getQuestCompletionUser());
    }
  }

  openStartFundraiserModal(mode: string) {
    if (mode === 'fundraise' && this.isQuestInTeamMode() && this.isDoerHasFundraisingLink) {
      this.openModal('start-quest-in-team-modal');
      this.rootStore.dispatch(new OpenTeamModal(this.team ? this.team.teamId : null));
    } else {
      this.fundraiseModalMode = mode;
      this.openModal(mode === 'fundraise' ? 'fundraise-modal' : 'back-fundraiser-modal');
    }
  }

  openEditFundraiserModal() {
    this.fundraiseModalMode = 'fundraise';
    this.openModal('fundraise-modal');
  }

  doJoinTeam() {
    if (this.data.isTeamPage) {
      this.isProgress = true;
      this.rootStore.dispatch(new JoinTeam(this.questId, this.data.doerTeamId));
    } else {
      this.openModal('start-quest-in-team-modal');
      this.rootStore.dispatch(new OpenTeamModal(this.team ? this.team.teamId : null));
    }
  }

  closeTeamModal() {
    this.closeModal();
    this.rootStore.dispatch(new CloseTeamModal());
  }

  prepareQuestVideoId(questVideoUrl: string): SafeResourceUrl | null {
    const res = getYoutubeId(questVideoUrl);
    return Boolean(res) ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${res}`) : null;
  }

  saveTransactions() {
    if (this.downloadProgress) {
      return;
    }
    this.downloadProgress = true;
    this.profileService.downloadQuestTransactions(this.questId).subscribe(data => {
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      saveAs(blob, 'event-participants.xlsx');
      this.downloadProgress = false;
    });
  }

  firstAvatarClick() {
    if (this.data.quest.user.id === this.data.user.id) {
      this.router.navigate(['profile', this.data.user.userName]);
    } else {
      this.router.navigate(['quest-detail', this.data.quest.id, this.data.quest.user.id]);
    }
  }

  private buildViewerDetailsStatus(): QuestDetailStatus {
    const isQuestTeamOrSupport = this.isQuestInSupportMode() || this.isQuestInTeamMode();

    if (!this.isCurrentUsersRepresentation && !isQuestTeamOrSupport) {
      return QuestDetailStatus.Public;
    }
    if (this.data.userHasSaved && !this.hasCurrentUserQuestActivity) {
      return QuestDetailStatus.Saved;
    } else if (this.hasCurrentUserQuestActivity) {
      if (this.isStatusInProgress()) {
        return QuestDetailStatus.Active;
      } else if (this.isStatusComplete()) {
        return QuestDetailStatus.Completed;
      } else {
        return QuestDetailStatus.Public;
      }
    } else {
      return QuestDetailStatus.Public;
    }
  }

  shouldShowFundraisingButton() {
    let shouldShow = false;

    if (this.fundraisingButtonPresent && !this.fundraisingButtonForcedHidden) {
      shouldShow = true;
    }
    if (this.iAmAnonymous() && !this.fundraisingButtonForcedHidden) {
      shouldShow = true;
    }

    return shouldShow;
  }

  shouldDisableFundraisingButton(): boolean {
    return this.isCurrentUserHasFundraisingLink
      || (this.isCurrentUserInQuestTeam() && this.isDoersTeam() && this.isDoerHasFundraisingLink)
      || (this.isCurrentUserInQuestTeam() && this.isCreatorsTeam());
  }

  isCurrentUserInQuestTeam(): boolean {
    return this.isQuestInTeamMode() && !!this.getCurrentUserTeam() && this.hasCurrentUserQuestActivity;
  }

  openUploadTeamImageModal(mode: UploadTeamImageModalMode) {
    this.selectedUploadTeamImageModalMode = mode;
    this.openModal('upload-team-image-modal');
  }

  closeUploadTeamImageModal() {
    this.selectedUploadTeamImageModalMode = null;
    this.closeModal();
  }

  isEventActive(): boolean {
    return this.data.event && this.data.event.happening && !!this.data.event.happening.active;
  }

  isEventDateInFuture(): boolean {
    if (this.data.event && this.data.event.happening && this.data.event.happening.happeningDate == null) {
      return true;
    }
    return this.data.event && this.data.event.happening && this.data.event.happening.happeningDate > Date.now();
  }

  private updateGallery() {
    this.questService.getQuestDetail(this.questId, this.userId).subscribe((quest: Quest) => {
      this.data.questImages = quest.questImages;
    });
  }

}
