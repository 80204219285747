
<div class="c-menu__inner" role="menubar">
  <div class="c-menu__overflow">
    <div class="c-menu__body">
      <button class="c-menu__close" (click)="onCloseMenu()">
        <svg class="icon icon-remove">
          <use xlink:href="#icon-remove"></use>
        </svg>
      </button>
      <ul class="c-menu__list">
        <li
          *ngFor="let menuItem of menuItems"
          [hidden]="!(authState | async).authenticated && menuItem.requireAuth"
          [routerLinkActive]="['is-active']"
          [ngSwitch]="menuItem"
        >
          <a
            role="menuitem"
            [class]="menuItem.icon"
            [routerLink]="menuItem.url"
            (click)="onCloseMenu()"
          >
            <span>{{ menuItem.title }}</span>
            <svg class="c-menu__icon icon" [ngClass]="[menuItem.icon]">
              <use attr.xlink:href="#{{menuItem.icon}}"></use>
            </svg>
            <svg class="icon icon-arrow-right">
              <use xlink:href="#icon-arrow-right"></use>
            </svg>
          </a>
        </li>
        <li>
          <a
            class="ham-log-out"
            *ngIf="(authState | async).authenticated"
            (click)="onLogout()"
          >
            <span>LOG OUT</span>
            <svg class="c-menu__icon icon-log-out icon">
              <use xlink:href="#icon-log-out"></use>
            </svg>
          </a>
        </li>
        <li>
          <a
            class="ham-log-out"
            *ngIf="!(authState | async).authenticated"
            (click)="openModal('auth-modal')"
          >
            <span>SIGN IN</span>
            <svg class="c-menu__icon icon-log-out icon">
              <use xlink:href="#icon-log-out"></use>
            </svg>
          </a>
        </li>
      </ul>
    </div>
    <div class="c-menu__footer">
      <a href="https://blog.diem.life/" target="_blank">BLOG</a>
      <a (click)="openModal('contact-modal')" role="menuitem">CONTACT</a>
      <a (click)="onCloseMenu()" [routerLink]="['/help']" role="menuitem">HELP</a>
      <a (click)="onCloseMenu()" [routerLink]="['/legal']" role="menuitem">LEGAL</a>
    </div>
  </div>
</div>
