import { Action } from '@ngrx/store';
import { TicketSteps } from './ticket-flow.reducer';
import { Participant } from '../../../ticket-flow.typings';

export const TICKET_FLOW_UPDATE_STEPS = 'TICKET_FLOW_UPDATE_STEPS';
export const TICKET_FLOW_ADD_INFO = 'TICKET_FLOW_ADD_INFO';
export const TICKET_RESET_INFO = 'TICKET_RESET_INFO';

export class TicketFlowUpdateSteps implements Action {
  readonly type = TICKET_FLOW_UPDATE_STEPS;

  constructor(public payload: TicketSteps) {}
}
export class TicketFlowAddInfo implements Action {
  readonly type = TICKET_FLOW_ADD_INFO;

  constructor(public payload: {index: number; value: Participant}) {}
}
export class TicketResetInfo implements Action {
  readonly type = TICKET_RESET_INFO;
}

export type Actions =  TicketFlowUpdateSteps | TicketFlowAddInfo | TicketResetInfo;
