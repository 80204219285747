<h3 class="modal-title">Let's do this</h3>
<div class="modal-body">
  <div class="c-quest-modes">
    <div class="c-quest-modes__item"
         *ngIf="fundraising && teams?.length"
         [ngClass]="{'is-active': teamModalChoice === 'JoinTeam'}">
      <div class="c-quest-modes__item__title" (click)="selectOption('JoinTeam')">
        <span>Join an existing team</span>
      </div>
      <div *ngIf="teamModalChoice === 'JoinTeam'" class="c-quest-modes__item__body">
        <div class="c-n-form__group">
          <div class="c-n-form__control is-required">
            <app-ui-select-team
              class="c-dl-team-control"
              [teamsList]="teams"
              [(ngModel)]="selectedTeamId">
            </app-ui-select-team>
            <span class="c-n-form__border"></span>
          </div>
        </div>

        <button class="c-btn c-btn_md c-btn_dark c-btn_full"
                type="button"
                [disabled]="progress || !selectedTeamId"
                (click)="startQuestInSelectedTeam(selectedTeamId)">
          <span>Select team</span>
        </button>
      </div>
    </div>
    <div class="c-quest-modes__item"
         *ngIf="fundraising"
         [ngClass]="{'is-active': teamModalChoice === 'CreateTeam'}">
      <div class="c-quest-modes__item__title" (click)="selectOption('CreateTeam')">
        <span>Create your own team</span>
      </div>
      <div *ngIf="teamModalChoice === 'CreateTeam'" class="c-quest-modes__item__body">
        <app-team-form [fundraisingEnabled]="fundraising"
                       [questId]="questId"
                       (onCreateTeam)="startQuestInTeam($event)"></app-team-form>
      </div>
    </div>
    <div class="c-quest-modes__item" [ngClass]="{'is-active': teamModalChoice === 'StartSolo'}">
      <div class="c-quest-modes__item__title" (click)="selectOption('StartSolo')">
        <span *ngIf="fundraising">Fundraise individually</span>
        <span *ngIf="!fundraising">Join {{questTitle}}</span>
      </div>
      <div *ngIf="teamModalChoice === 'StartSolo'" class="c-quest-modes__item__body">
        <app-individual-form [fundraisingEnabled]="fundraising"
                             (onSoloFundraiser)="startQuestSolo($event)"></app-individual-form>
      </div>
    </div>
  </div>
</div>
