<form
      [formGroup]="form"
      (ngSubmit)="onSubmit(form.value)"
      class="c-n-modal__body ng-untouched ng-pristine ng-invalid ng-star-inserted">
      <h5 _ngcontent-kmk-c8="" class="c-n-modal__title t-typo__h3">Contact Us</h5>

      <p *ngIf="sent" _ngcontent-ujl-c8="" class="c-n-modal__sub-title-switch t-typo__h6" style="color: #168cb2">Thanks for contacting us. We will get back to you as soon as possible.</p>
      <p *ngIf="!sent" _ngcontent-ujl-c8="" class="c-n-modal__sub-title-switch t-typo__h6">We'd love to hear from you.</p>

      <div class="c-forms__group">
        <div _ngcontent-ugv-c8="" class="c-n-form__legend">Name:</div>
        <div class="c-forms__control is-required">
          <input
          class="c-forms__input"
          type="name"
          autocomplete="new-password"
          formControlName="name"
          name="name"
          aria-label="Enter name"
          appInputFocusTrigger>
        </div>
      </div>

      <div class="c-forms__group">
        <div _ngcontent-ugv-c8="" class="c-n-form__legend">Email Address:</div>
        <div class="c-forms__control is-required">
          <input
          class="c-forms__input"
          type="email"
          autocomplete="new-password"
          formControlName="email"
          name="email"
          aria-label="Enter Email for login"
          appInputFocusTrigger>
        </div>
        <div
          *ngIf="form.get('email').hasError('pattern')"
          class="error"
          role="alert">
          Please enter a valid email address.
        </div>
      </div>

      <div class="c-forms__group">
        <div _ngcontent-ugv-c8="" class="c-n-form__legend">Message:</div>
        <textarea
          class="c-forms__input"
          rows="4"
          maxlength="2000"
          name="message"
          id="message"
          formControlName="message"
          style="resize: none">
        </textarea>
      </div>

      <div class="c-forms__group">
        <ngx-recaptcha2
          formControlName="recaptcha"
          theme="light"
          type="normal"
          [siteKey]="captchaToken"
          (success)="handleSuccess($event)">
        </ngx-recaptcha2>
        <div *ngIf="form.get('recaptcha').invalid && sent || captchaFailed"
          class="c-forms__message is-red is-bottom"
          role="alert">
          <div *ngIf="form.get('recaptcha').errors.required">{{ messages.required }}</div>
          <div *ngIf="captchaFailed">reCaptcha failed. Please try again.</div>
        </div>
      </div>

      <div class="c-forms__group">
        <button
          type="submit"
          class="c-btn c-btn_md c-btn-dark c-btn_full"
          style="background-color: #168cb2; color: white"
          [disabled]="!form.valid || captchaFailed">
          Send!
        </button>
      </div>
  </form>
