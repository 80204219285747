import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account/account.service';
import { Observable, Subscription } from 'rxjs';
import * as fromDonate from '../store/donate.reducer';
import { SnotifyService } from 'ng-snotify';
import { SnotifyConfigService } from '../../../../_services/snotify-config.service';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../main/modal/modal.service';
import { QuestService } from '../../../../_services/quest.service';
// import { Quest } from '../../../main/quest/quest.type';
import { SetQuestId, Quest } from 'diemlife-commons/dist/diemlife-commons-model';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-donate-success',
  templateUrl: './donate-success.component.html',
  styleUrls: ['./donate-success.component.styl']
})
export class DonateSuccessComponent implements OnInit {
  donateState: Observable<fromDonate.State>;
  donateStateSubscriber: Subscription;
  userInfo: any;
  questId: number;
  userId: number;
  isLoaded = false;
  openedDialog = '';
  questInfo: Quest;
  constructor(
    public router: Router,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private accountService: AccountService,
    private modalService: ModalService,
    private questService: QuestService
  ) {
    this.donateState = this.store.select('donate');
  }

  ngOnInit() {
    this.donateState.pipe(take(1)).subscribe((state: fromDonate.State) => {
      if (!state.steps.confirm) {
          this.snotifyService.warning('Please, complete previous step!', null, this.snotifyConfigService.getConfig(null, {maxOnScreen: 1}));
          let backTo: string;
          if (!state.steps.confirm && !state.steps.amount) {
            backTo = 'enter-amount';
          } else {
            backTo = 'confirm';
          }
          this.route.parent.params.pipe(take(1)).subscribe( params => {
            this.questId = params.questId;
            this.userId = params.userId;
            this.router.navigate([`/donate/${params.questId}/${params.userId}/${backTo}`]);
          });
      } else {
        this.route.parent.params.pipe(take(1)).subscribe( params => {
          this.questId = params.questId;
          this.userId = params.userId;
          this.accountService.getUserInfoById(params.userId).subscribe(result => {
            this.userInfo = result;
            this.isLoaded = true;
          });
          this.questService.getQuestDetail(params.questId, params.userId).subscribe((res: Quest) => {
            this.questInfo = res;
            this.store.dispatch(new SetQuestId(this.questInfo.quest.id));
          });
        });
      }
    });

    this.route.parent.params.pipe(take(1)).subscribe( params => {
    });
  }
  openDialog(id: string) {
    this.openedDialog = id;
    setTimeout(() => {
      this.modalService.open(id);
    });
  }
  closeModal() {
    setTimeout(() => {
      this.openedDialog = '';
    });
  }

}
