<div class="c-dl-milestones-dialog">
    <div class="c-dl-milestones-dialog__header">
        <div class="c-dl-milestones-dialog__title">
            <span class="c-dl-milestones-dialog__title__count e-oval">
                <i>{{ selectedIndex }}</i>
            </span>
            <span *ngIf="milestoneInfo?.embeddedVideo" class="c-dl-milestones-dialog__title__cnt">Video Player</span>
            <span *ngIf="milestoneInfo?.linkUrl" class="c-dl-milestones-dialog__title__cnt">Link Preview</span>
            <span *ngIf="milestoneInfo?.imageUrl" class="c-dl-milestones-dialog__title__cnt">Image Preview</span>
            <span *ngIf="milestoneInfo?.linkedQuest" class="c-dl-milestones-dialog__title__cnt">Quest Link Preview</span>
        </div>
    </div>
    <div class="c-dl-milestones-dialog__video-preview" *ngIf="milestoneInfo?.embeddedVideo">
        <iframe
            [cachedSrc]="prepareQuestVideoId(milestoneInfo?.embeddedVideo.videoUrl)"
            width="100%"
            height="396"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen">
        </iframe>
    </div>
    <div class="c-dl-milestones-dialog__link-preview" *ngIf="milestoneInfo?.linkPreview?.url || milestoneInfo?.linkUrl">
        <a class="c-dl-milestones-dialog__link-preview__view h-object-fit" [attr.href]="milestoneInfo?.linkPreview?.url ? milestoneInfo.linkPreview.url : milestoneInfo?.linkUrl" target="_blank">
            <img *ngIf="milestoneInfo?.linkPreview?.image" [src]="milestoneInfo.linkPreview.image" alt="">
            <span class="c-dl-milestones-dialog__link-preview__btn h-btn-reset">
                <span>Open link</span>
                <svg class="icon icon-external_link"><use xlink:href="#icon-external_link"></use></svg>
            </span>
        </a>
        <div *ngIf="milestoneInfo?.linkPreview?.title || milestoneInfo?.linkPreview?.description || milestoneInfo.linkUrl" class="c-dl-milestones-dialog__link-preview__caption">
            <strong *ngIf="milestoneInfo?.linkPreview?.title || milestoneInfo.linkUrl" class="c-dl-milestones-dialog__link-preview__title">{{ milestoneInfo?.linkPreview?.title ? milestoneInfo.linkPreview.title : milestoneInfo.linkUrl }}</strong>
            <p *ngIf="milestoneInfo?.linkPreview?.description" class="c-dl-milestones-dialog__link-preview__desc">{{ milestoneInfo.linkPreview.description }}</p>
        </div>
    </div>
    <div class="c-dl-milestones-dialog__preview h-object-fit" *ngIf="milestoneInfo?.imageUrl">
        <img [src]="milestoneInfo.id ? milestoneInfo.imageUrl : 'data:image/jpeg;base64,' + milestoneInfo.imageUrl" />
    </div>
    <div class="c-dl-milestones-dialog__link-preview" *ngIf="milestoneInfo?.linkedQuest">
      <a class="c-dl-milestones-dialog__link-preview__view h-object-fit"
         [routerLink]="milestoneInfo.linkedQuest.seoSlugs.shortUrl">
        <img [src]="prepareCoverPhoto(milestoneInfo.linkedQuest.photo, milestoneInfo.linkedQuest.pillar).jpg"
             alt=""/>
        <span class="c-dl-milestones-dialog__link-preview__btn h-btn-reset">
          <span>Open Quest</span>
        </span>
      </a>
      <div class="c-dl-milestones-dialog__link-preview__caption">
        <strong class="c-dl-milestones-dialog__link-preview__title">DIEMlife</strong>
        <p class="c-dl-milestones-dialog__link-preview__desc">{{ milestoneInfo.linkedQuest.title }}</p>
      </div>
    </div>
    <div class="c-dl-milestones-dialog__footer" *ngIf="isEditMode">
        <button class="e-btn e-btn_red h-xs-loader" [ngClass]="{'is-loading': isLoading === 'REMOVE'}" (click)="removePreview()" type="button">
            <span class="h-xs-loader__caption">Remove</span>
            <i class="h-xs-loader__control"></i>
        </button>
    </div>
</div>
