<h3 class="modal-title">{{mode === 'fundraise' ? 'Start fundraising' : 'Back this Quest' }}</h3>
<form action="#"
      *ngIf="isLoaded"
      [formGroup]="fundsForm"
      class="c-modal-inner modal-container"
      (submit)="startFundraising()">

  <div class="c-forms__group">
    <app-funds-info [questId]="questId"
                    [mode]="mode"
                    [doerId]="doerId"
                    *ngIf="!fundraisingLink?.coverImageUrl"
                    class="c-quest-group c-quest-group_row"></app-funds-info>
    <img [src]="fundraisingLink?.coverImageUrl"
         [alt]="fundraisingLink?.campaignName"
         *ngIf="fundraisingLink?.coverImageUrl"
         class="c-fund-modal__cover-image"/>
  </div>

  <div class="c-forms__group" *ngIf="mode === 'fundraise'">
    <label for="targetAmountControl" class="c-forms__legend">Target amount</label>
    <div class="c-forms__control is-required">
      <input id="targetAmountControl"
             formControlName="fundsFormAmount"
             type="text"
             prefix="$"
             placeholder="$0"
             mask="0000000"
             [validation]="false"
             class="c-forms__input"
             autocomplete="off">
    </div>
    <div
      *ngIf="!!fundsForm.get('fundsFormAmount').errors && formSubmitted"
      class="c-forms__message is-red"
      role="alert">
      <div *ngIf="fundsForm.get('fundsFormAmount').errors.required">{{ messages.required }}</div>
      <div *ngIf="!!fundsForm.get('fundsFormAmount').errors.pattern || !!fundsForm.get('fundsFormAmount').errors.min">
        <span>Target amount must be at least $1.00</span>
      </div>
    </div>
  </div>

  <div class="c-forms__group" *ngIf="mode === 'fundraise'">
    <label for="campaignNameControl" class="c-forms__legend">Campaign name</label>
    <div class="c-forms__control is-required">
      <input id="campaignNameControl"
             formControlName="fundsCampaignName"
             type="text"
             placeholder="Awesome fundraiser"
             class="c-forms__input"
             autocomplete="off">
    </div>
    <div class="c-forms__message" role="alert" [ngClass]="{
        'is-red': fundsForm.get('fundsCampaignName').invalid,
        'is-block': fundsForm.get('fundsCampaignName').dirty
      }">
      <div *ngIf="fundsForm.get('fundsCampaignName').status === 'PENDING'">Checking...</div>
      <div *ngIf="fundsForm.get('fundsCampaignName').status === 'VALID'">Looks good!</div>
      <div *ngIf="fundsForm.get('fundsCampaignName').status === 'INVALID'">
        <div *ngIf="fundsForm.get('fundsCampaignName').errors['maxlength']">Text is too long!</div>
        <div *ngIf="fundsForm.get('fundsCampaignName').errors['duplicateCampaignName']">This name is already taken!</div>
      </div>
    </div>
  </div>

  <div class="c-forms__group" *ngIf="mode === 'fundraise'">
    <div class="c-forms__control">
        <app-dropzone-upload formControlName="fundsCoverImageUrl"
                           placeholder="New cover image"
                           [large]="true"
                           (uploadProgress)="onCoverUploadProgress($event)"></app-dropzone-upload>
    </div>
  </div>

  <div class="c-forms__group">
    <label for="disclaimer-control" class="c-forms__legend">Disclaimer</label>
    <div class="c-forms__control is-textarea">
      <textarea class="c-forms__input"
                readonly
                id="disclaimer-control"
                cols="30"
                rows="5">{{ disclaimer }}</textarea>
    </div>
  </div>

  <div class="c-forms__group">
    <label role="checkbox" class="c-checkbox is-middle is-align-center">
      <input type="checkbox" formControlName="fundsFormConfirm"/>
      <span class="c-checkbox__icon">
        <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
      </span>
      <span class="c-checkbox__caption"><b>I agree with the above disclaimer</b></span>
    </label>
    <div
      *ngIf="fundsForm.get('fundsFormConfirm').errors && formSubmitted"
      class="c-forms__message is-red"
      role="alert">
      <div *ngIf="!!fundsForm.get('fundsFormConfirm').errors.required">
        <span>You have to agree with the disclaimer</span>
      </div>
    </div>
  </div>

  <div class="modal__confirm">
    <div class="modal__confirm__group">
      <div class="modal__confirm__col">
        <button type="button" (click)="closeThisDialog()" class="c-btn c-btn_md c-btn_full">
          <span>Cancel</span>
        </button>
      </div>
      <div class="modal__confirm__col">
        <button type="submit"
                [disabled]="fundsForm.invalid || progress || uploadProgress || checkProgress"
                class="c-btn c-btn_md c-btn_yellow c-btn_full">
          <span>{{mode === 'fundraise' ? 'Fundraise' : 'Back Me'}}</span>
        </button>
      </div>
    </div>
  </div>
</form>
