<!-- <div style="background-color: #168cb2; color: white; font-size: 15px; text-align: center;">

</div> -->

<div style="padding: 18px;
  background-color: #16a5b1;
  color: white;
  font-size: 15px;
  text-align: center;">
  Buy a small part of DIEMlife. If we do well, so will you.
  <a href="https://wefunder.com/diemlife/join" target="_blank" style="text-decoration: underline; font-weight: bold; color: white">
    LEARN MORE
  </a>
</div>
