import {LocalStorageService} from '../../../../_services/localstorage.service';
import * as ProfileActions from './profile.actions';
import { QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';
// import {QuestUserInfo} from '../../../main/quest/quest.type';

export interface State extends QuestUserInfo {
}

export const initialState: State = {
  active: false,
  country: null,
  coverPictureURL: null,
  createdOn: null,
  email: null,
  emailValidated: false,
  firstName: null,
  id: null,
  identifier: null,
  isUserBrand: null,
  lastLogin: null,
  lastName: null,
  missionStatement: null,
  name: null,
  profilePictureURL: null,
  receiveEmail: null,
  stripeEntityId: null,
  updatedOn: null,
  userName: null,
  userNonProfit: false,
  zip: null,
  absorbFees: false,
  realtimeQuestId: null
};

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateUserInfo';

export function ProfileReducer(
  state = localStorageService.getInitialState(LOCAL_STATE_KEY, initialState),
  action: ProfileActions.ProfileActions
) {
  switch (action.type) {
    case (ProfileActions.GET_USER_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        ...action.payload
      });
    case (ProfileActions.UPDATE_USER_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        ...action.payload
      });
    case (ProfileActions.CLEAR_USER_INFO):
      localStorageService.clear(LOCAL_STATE_KEY);
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
