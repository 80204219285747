<div class="c-quest-group__col" *ngIf="data">
  <div class="c-quest-administration">
    <div class="c-quest-administration__col" [title]="data.doer.firstName + ' ' + data.doer.lastName">
      <app-profile-avatar
        class="c-quest-administration__view c-avatar"
        [avatar]="data.doer.avatarUrl"
        [firstName]="data.doer.firstName"
        [isBrand]="data.doer.isUserBrand"
        [lastName]="data.doer.lastName"
        [userName]="data.doer.userName">
      </app-profile-avatar>
      <div class="c-quest-administration__title"></div>
      <svg class="c-quest-administration__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="52.5px" height="142px" viewBox="0 0 52.5 142" enable-background="new 0 0 52.5 142" xml:space="preserve">
        <path fill="none" stroke="#FFFFFF" stroke-miterlimit="10" d="M0.4,141.7L51.9,71L0.4,0.3l0,0"/>
      </svg>
      <span class="c-quest-administration__ttl">{{mode === 'fundraise' ? 'You' : data.doer.firstName}}</span>
    </div>
    <div class="c-quest-administration__col" [title]="data.creator.firstName + ' ' + data.creator.lastName">
      <app-profile-avatar
        class="c-quest-administration__view c-avatar"
        [avatar]="data.creator.avatarUrl"
        [firstName]="data.creator.firstName"
        [isBrand]="data.creator.isUserBrand"
        [lastName]="data.creator.lastName"
        [userName]="data.creator.userName">
      </app-profile-avatar>
      <svg class="c-quest-administration__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="52.5px" height="142px" viewBox="0 0 52.5 142" enable-background="new 0 0 52.5 142" xml:space="preserve">
        <path fill="none" stroke="#FFFFFF" stroke-miterlimit="10" d="M0.4,141.7L51.9,71L0.4,0.3l0,0"/>
      </svg>
      <span class="c-quest-administration__ttl">{{data.creator.firstName}}</span>
    </div>
    <div class="c-quest-administration__col" [title]="data.quest.title">
      <div class="c-quest-administration__view">
      <img [src]="data.quest.imageUrl ? data.quest.imageUrl : 'assets/static/tree-back.png'" alt="">
      </div>
      <svg class="c-quest-administration__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="52.5px" height="142px" viewBox="0 0 52.5 142" enable-background="new 0 0 52.5 142" xml:space="preserve">
        <path fill="none" stroke="#FFFFFF" stroke-miterlimit="10" d="M0.4,141.7L51.9,71L0.4,0.3l0,0"/>
      </svg>
      <span class="c-quest-administration__ttl">{{data.quest.title}}</span>
    </div>
  </div>
</div>
