import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HTTPStatus} from 'src/app/interceptors/loader.interceptor';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {SurveyType} from 'diemlife-commons/dist/diemlife-commons-model';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor (
    private httpStatus: HTTPStatus,
    private reaquestHeadersService: ReaquestHeadersService,
    private http: HttpClient
  ) {}

  // for test surveys with backend
  pushSurvey(questId: number, type?: SurveyType) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/surveys/push`,
      {questId},
      {headers}
    );
  }
}
