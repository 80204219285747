export interface ValidationMessages {
  required: string;
  invalid: string;
  invalidEmail: string;
  duplicateEmail: string;
  invalidZipcode: string;
  password: {
    compareTo: string;
    minlength: (count) => {};
    maxlength: (count) => {};
  };
  minlength: (count, fieldName: string) => {};
  maxlength: (count, fieldName: string) => {};
}
export const VALIDATION_MESSAGES: ValidationMessages = {
  required: 'This field is required.',
  invalid: 'This field is invalid.',
  invalidEmail: 'Please enter a valid email address.',
  duplicateEmail: 'Email is already in use. Please login or try another.',
  invalidZipcode: 'Please enter a valid zipcode.',
  password: {
    compareTo: 'Passwords do not match',
    minlength: (count) => {
      return `Password must be over ${count} characters`;
    },
    maxlength: (count) => {
      return `Password must not exceed ${count} characters`;
    }
  },
  minlength: (fieldName, count) => {
    return `${fieldName} must be over ${count} characters`;
  },
  maxlength: (fieldName, count) => {
    return `${fieldName} must not exceed ${count} characters`;
  }
};

export const MEMBER_STATUS_PRIORITY = {
  'Creator'   : 1,
  'Admin'     : 2,
  'Backer'    : 3,
  'Donor'     : 4,
  'Supporter' : 5,
  'Doer'      : 6,
  'Achiever'  : 7,
  'Interested': 8
};

export const MEDIA_BREAKPOINTS = {
  xl: 1420,
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 600
};

export const MILESTONE_TASK_MAX_LENGTH = 140;

export const SHARE_DATE_FORMAT = 'MMMM D, YYYY | h:mm a';

export const LEADERBOARD_TEAM_PREFIX = 'team-';
