<ng-template #defaultTemplate>
  <div class="c-leaderboard-list-item__avatar" [ngClass]="{'is-team': mode === availableModes.TEAM}">
    <a class="c-leaderboard-list-item__profile-link"
       href="javascript:void(0)"
       [routerLink]="member.userName ? ['/profile', member.userName] : null">
      <img class="c-leaderboard-list-item__photo"
           *ngIf="member.avatarUrl"
           [src]="member.avatarUrl"
           [alt]="member.firstName + ' ' + member.lastName"
           [title]="member.firstName + ' ' + member.lastName"/>
      <span class="c-leaderboard-list-item__initials"
            *ngIf="!member.avatarUrl">{{member.firstName.charAt(0) + member.lastName.charAt(0)}}</span>
    </a>
    <span class="c-leaderboard-list-item__number">{{member.place}}</span>
  </div>
  <div class="c-leaderboard-list-item__caption">
    <div class="c-leaderboard-list-item__caption--left" *ngIf="member.firstName && member.lastName">
      <span class="c-leaderboard-list-item__name c-leaderboard__name pr-10">{{member.firstName}}&nbsp;{{member.lastName}}</span>
      <span class="c-leaderboard-list-item__info c-leaderboard__info">
        <span *ngIf="member.gender">{{member.gender === 'M' ? 'Male' : 'Female'}}</span>
        <span *ngIf="member.gender && member.age">&nbsp;-&nbsp;</span>
        <span *ngIf="member.age">{{member.age}}</span>
        <span *ngIf="!member.gender && !member.age">&bull;</span>
      </span>
      <span class="c-leaderboard-list-item__info c-leaderboard__info">
        <span *ngIf="member.city">{{member.city}},&nbsp;</span>
        <span *ngIf="member.state">{{member.state}},&nbsp;</span>
        <span *ngIf="member.country">{{member.country}}</span>
        <span *ngIf="!member.city && !member.city && !member.country">&bull;</span>
      </span>
    </div>
    <div class="c-leaderboard-list-item__caption--right">
      <div class="c-leaderboard__score__wrap">
        <span class="c-leaderboard-list-item__score c-leaderboard__score"
              *ngIf="!showForm && showScore"
              [ngClass]="{'c-leaderboard__score--disabled': member.status === 'DidNotStart' || member.status === 'DidNotFinish'}">{{scoreText}}</span>
        <span class="c-leaderboard-list-item__score c-leaderboard__score c-leaderboard__score--disabled"
              *ngIf="!showForm && !showScore">&mdash;</span>
        <app-leaderboard-score-editor
          *ngIf="allowEdit && member"
          class="c-leaderboard-score-editor"
          [member]="member"
          [questId]="questId"
          [selectedAttributeId]="selectedAttributeId"
          (changeVisibilityForm)="onChangeVisibilityEditForm($event)"
          (updateMember)="onUpdateMember($event)">
        </app-leaderboard-score-editor>
      </div>
      <span class="c-leaderboard-list-item__unit c-leaderboard__unit" *ngIf="showUnit">{{unitText}}</span>
    </div>
  </div>

</ng-template>

<ng-container *ngIf="mode === availableModes.TEAM && team; else defaultTemplate">

  <div class="c-leaderboard-list-item__avatar is-team">
    <a class="c-leaderboard-list-item__profile-link"
       href="javascript:void(0)"
       [routerLink]="team.teamPageUrl ? [team.teamPageUrl] : undefined">
      <img class="c-leaderboard-list-item__photo" *ngIf="team.teamLogoUrl" [src]="team.teamLogoUrl" [alt]="team.teamName" [title]="team.teamName"/>
      <img class="c-leaderboard-list-item__photo" *ngIf="!team.teamLogoUrl" [src]="'/assets/images/logo-black-white.png'" [alt]="team.teamName" [title]="team.teamName"/>
    </a>
    <span class="c-leaderboard-list-item__number">{{member.place}}</span>
  </div>
  <div class="c-leaderboard-list-item__caption">
    <div class="c-leaderboard-list-item__caption--left" *ngIf="team.teamName">
      <span class="c-leaderboard-list-item__name c-leaderboard__name pr-10">{{team.teamName}}</span>
    </div>
    <div class="c-leaderboard-list-item__caption--right">
      <div class="c-leaderboard__score__wrap">
        <span class="c-leaderboard-list-item__score c-leaderboard__score"
              *ngIf="showScore"
              [ngClass]="{'c-leaderboard__score--disabled': member.status === 'DidNotStart' || member.status === 'DidNotFinish'}">{{scoreText}}</span>
        <span *ngIf="!showScore" class="c-leaderboard-list-item__score c-leaderboard__score c-leaderboard__score--disabled">&mdash;</span>
      </div>
      <span class="c-leaderboard-list-item__unit c-leaderboard__unit" *ngIf="showUnit">{{unitText}}</span>
    </div>
  </div>

</ng-container>
