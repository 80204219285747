import {Component, OnInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import {QuestService} from 'src/app/_services/quest.service';
import {QuestTeam, FundraisingLinkType} from 'diemlife-commons/dist/diemlife-commons-model';
import {UploadTeamImageModalMode} from '../../quest/quest.type';

@Component({
  selector: 'app-team-upload-image-form',
  templateUrl: './team-upload-image-form.component.html',
  styleUrls: ['./team-upload-image-form.component.styl']
})
export class TeamUploadImageFormComponent implements OnInit, OnChanges {
  @Output() updateQuestDetailInfo: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Input() mode: UploadTeamImageModalMode;
  @Input() questId: number;
  @Input() doerId: number;
  @Input() viewerId: number;
  @Input() team: QuestTeam;
  @Input() fundraisingLink: FundraisingLinkType;
  uploadTeamImageModalMode = UploadTeamImageModalMode;
  uploadProgress = false;
  progress = false;
  uploadImageFormGroup: FormGroup;
  imageLarge: boolean;

  constructor(
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private questService: QuestService
  ) {}

  ngOnInit() {
    this.uploadImageFormGroup = new FormGroup({
      'imageUrl': new FormControl(null, [
        Validators.maxLength(2000)
      ])
    });
  }

  ngOnChanges() {
    if (this.uploadImageFormGroup && !this.mode) {
      this.uploadImageFormGroup.patchValue({
        imageUrl: null
      });
    }
  }

  onImageUploadProgress(progress: boolean) {
    this.uploadProgress = progress;
    if (this.mode === this.uploadTeamImageModalMode.TeamLogo) {
      this.imageLarge = false;
    } else {
      this.imageLarge = true;
    } 
  }

  onSubmit() {
    if (this.uploadImageFormGroup.invalid || this.uploadProgress) {
      return false;
    }
    this.progress = true;
    if (this.mode === this.uploadTeamImageModalMode.TeamLogo) {
      const payload = {...this.team};
      payload.teamLogoUrl = this.uploadImageFormGroup.controls['imageUrl'].value;
      this.questService.updateTeamInfo(payload).subscribe(
        () => {
          this.updateQuestDetailInfo.emit();
          this.close.emit();
          this.progress = false;
        },
        error => {
          console.log('Team logo saving failed', error);
          this.snotifyService.error('Team logo saving failed', null, this.snotifyConfigService.getConfig());
          this.progress = false;
        }
      );
    } else if (this.mode === this.uploadTeamImageModalMode.CoverPicture) {
      this.questService.startFundraising(
        this.questId,
        this.viewerId,
        this.fundraisingLink.targetAmount,
        'usd',
        this.fundraisingLink.campaignName,
        this.uploadImageFormGroup.controls['imageUrl'].value
      ).subscribe(
        () => {
          this.updateQuestDetailInfo.emit();
          this.close.emit();
          this.progress = false;
        },
        error => {
          console.log('Cover image saving failed', error);
          this.snotifyService.error('Cover image saving failed', null, this.snotifyConfigService.getConfig());
          this.progress = false;
        }
      );
    }
  }
}
