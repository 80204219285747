import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../_store/app.reducers';
import {
  QuestTeam,
  FundraisingLinkType,
  CREATE_TEAM,
  CreateTeam,
  FundraiserStarted,
  JOIN_TEAM,
  JoinTeam,
  LOAD_TEAMS,
  LoadTeams,
  QuestStarted,
  START_FUNDRAISER,
  START_QUEST,
  StartFundraiser,
  StartQuest,
  TeamCreated,
  TeamJoined,
  TeamsLoaded
} from 'diemlife-commons/dist/diemlife-commons-model';
import {QuestService} from '../../../../_services/quest.service';
import {of} from 'rxjs';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';

@Injectable()
export class QuestTeamModalEffects {

  constructor(private actions: Actions,
              private store: Store<AppState>,
              private questService: QuestService,
              private notifyService: SnotifyService,
              private notifyConfig: SnotifyConfigService) {
  }

  @Effect()
  doLoadTeams = this.actions.pipe(
    ofType<LoadTeams>(LOAD_TEAMS),
    switchMap((action) => this.questService.getQuestTeams(action.questId).pipe(
      map((teams) => teams || []),
      catchError(() => {
        this.notifyService.error('Error creating a team!', null, this.notifyConfig.getConfig());
        return of<QuestTeam[]>([]);
      })
    )),
    switchMap((teams) => [new TeamsLoaded(teams)])
  );

  @Effect()
  doCreateNewTeam = this.actions.pipe(
    ofType<CreateTeam>(CREATE_TEAM),
    switchMap((action) => this.questService.startQuest({
      questMode: 'diyMode',
      questId: action.questId,
      questTeamAction: 'Create',
      questTeamName: action.teamName,
      questTeamLogoUrl: action.teamLogoUrl
    }).pipe(
      map((started) => {
        if (started) {
          this.notifyService.success(`New team "${action.teamName}" created`, null, this.notifyConfig.getConfig());
        } else {
          this.notifyService.warning(`Team '${action.teamName}' not created`, null, this.notifyConfig.getConfig());
        }
        return {action, started};
      }),
      catchError(() => {
        this.notifyService.error('Error creating a team!', null, this.notifyConfig.getConfig());
        return of({action, started: false});
      })
    )),
    switchMap((result) => result.started ? this.questService.findTeam(result.action.questId, result.action.teamName).pipe(
      map((team) => team),
      catchError(() => {
        this.notifyService.error('Error loading team!', null, this.notifyConfig.getConfig());
        return of(null);
      })
    ) : of(null)),
    switchMap((team) => [new TeamCreated(team)])
  );

  @Effect()
  doJoinTeam = this.actions.pipe(
    ofType<JoinTeam>(JOIN_TEAM),
    switchMap((action) => this.questService.startQuest({
      questMode: 'diyMode',
      questId: action.questId,
      questTeamAction: 'Join',
      questTeamId: action.teamId
    }).pipe(
      map((started) => {
        if (started) {
          this.notifyService.success('Successfully joined the team', null, this.notifyConfig.getConfig());
        } else {
          this.notifyService.warning('Could not join the team', null, this.notifyConfig.getConfig());
        }
        return {action, started};
      }),
      catchError(() => {
        this.notifyService.error('Error joining a team!', null, this.notifyConfig.getConfig());
        return of({action, started: false});
      })
    )),
    switchMap((result) => result.started ? this.questService.getTeam(result.action.teamId).pipe(
      map((team) => team),
      catchError(() => {
        this.notifyService.error('Error loading team!', null, this.notifyConfig.getConfig());
        return of(null);
      })
    ) : of(null)),
    switchMap((team) => [new TeamJoined(team)])
  );

  @Effect()
  doStartQuest = this.actions.pipe(
    ofType<StartQuest>(START_QUEST),
    switchMap((action) => this.questService.startQuest({
      questMode: action.questMode,
      questTeamName: action.fundraiserTitle,
      questTeamAction: action.referrerId ? 'Join' : null,
      referrerId: action.referrerId,
      questId: action.questId
    }).pipe(
      map((started) => {
        if (started) {
          this.notifyService.success(`Quest started`, null, this.notifyConfig.getConfig());
        } else {
          this.notifyService.warning(`Quest not started`, null, this.notifyConfig.getConfig());
        }
        return started;
      }),
      catchError(() => {
        this.notifyService.error('Error starting Quest!', null, this.notifyConfig.getConfig());
        return of(false);
      })
    )),
    switchMap((started) => [new QuestStarted(started)])
  );

  @Effect()
  doStartFundraiser = this.actions.pipe(
    ofType<StartFundraiser>(START_FUNDRAISER),
    switchMap((action) => this.questService.startFundraising(
      action.questId,
      action.userId,
      action.targetAmount,
      action.currency,
      action.campaignName,
      null
    ).pipe(
      map(link => {
        this.notifyService.success('Fundraiser started', null, this.notifyConfig.getConfig());
        return link;
      }),
      catchError(() => {
        this.notifyService.error('Error starting fundraiser!', null, this.notifyConfig.getConfig());
        return of<FundraisingLinkType>(null);
      })
    )),
    switchMap((link) => link ? [new FundraiserStarted(link)] : [])
  );

}
