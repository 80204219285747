import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {HTTPStatus} from '../../../../interceptors/loader.interceptor';
import {ModalService} from '../../modal/modal.service';
import {ReaquestHeadersService} from '../../../../_services/requestHeaders.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';

import * as ProfileActions from './../../../pages/profile/store/profile.actions';
import * as UserFavoritesActions from './../../../pages/profile/store/user-favorites.actions';
import * as RecentTransactionsActions from './../../../pages/profile/store/recent-transactions.actions';
import * as ProfileFriendsActions from './../../../separate/profile-friends/store/profile-friends.actions';
import * as AuthActions from './auth.actions';
import {PasswordRecoveryData} from './auth.actions';
import {EMPTY, of} from 'rxjs';

@Injectable()
export class AuthEffects {

  constructor(
    private httpStatus: HTTPStatus,
    private actions$: Actions,
    private router: Router,
    private http: HttpClient,
    private modalService: ModalService,
    private reaquestHeadersService: ReaquestHeadersService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService
  ) {}

  @Effect()
  authSignin = this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNIN),
      map((action: AuthActions.TrySignin) => {
        return action.payload;
      }),
      switchMap((authData: { email: string, password: string }) => {
        this.httpStatus.setHttpStatus(true);
        return this.http.post(
          environment.target + environment.context + '/login/token',
          authData,
          {
            withCredentials: true
          }
        ).pipe(
          mergeMap((data: { token: string, expires: number }) => {
            if (this.modalService.modalIsOpened()) {
              this.modalService.close('auth-modal');
            }
            sessionStorage.removeItem('NEW_ACCOUNT_INFO');
            const now = new Date();
            return [
              {
                type: AuthActions.SET_TOKEN,
                payload: { token: data.token, tokenUpdateTime: now.getTime() }
              },
              {
                type: AuthActions.SET_EXPIRES,
                payload: data.expires
              },
              {
                type: AuthActions.SIGNIN
              },
              {
                type: ProfileActions.TRY_GET_USER_INFO
              },
              {
                type: AuthActions.AUTH_SET_LOADING_STATE,
                payload: false
              }
            ];
          }),
          catchError((err) => {
            let message: string;
            switch (err.status) {
              case 401:
                message = 'Invalid email and/or password. Please ensure the information is correct and try again.';
                break;
              default:
                // console.log(JSON.stringify(err));
                message = 'Oops, something went wrong, please try again.';
            }
            this.snotifyService.error(
              message,
              null,
              this.snotifyConfigService.getConfig()
            );
            return of(new AuthActions.AuthSetLoadingState(false));
          })
        );
      })
    );

  @Effect()
    authTryLogout = this.actions$
      .pipe(
        ofType(AuthActions.TRY_LOGOUT),
        switchMap(() => {
          const headers = this.reaquestHeadersService.getHeaders();
          return this.http.post(
            environment.target + environment.context + '/logout/session',
            null,
            {
              headers: headers,
              withCredentials: true
            }
          );
        }),
        mergeMap(() => {
          return [
            {
              type: AuthActions.LOGOUT
            },
            {
              type: ProfileActions.CLEAR_USER_INFO
            },
            {
              type: UserFavoritesActions.CLEAR_USER_FAVORITES
            },
            {
              type: RecentTransactionsActions.CLEAR_RECENT_TRANSACTIONS
            },
            {
              type: ProfileFriendsActions.CLEAR_USER_FRIENDS
            }
          ];
        })
      );

  @Effect()
  authTryRefreshToken = this.actions$
    .pipe(
      ofType(AuthActions.TRY_REFRESH_TOKEN),
      switchMap(() => {
        this.httpStatus.setHttpStatus(true);
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + '/refresh/token',
          {
            headers: headers,
            withCredentials: true
          }
        );
      }),
      mergeMap((data: { token: string, expires: number }) => {
        const now = new Date();
        return [
          {
            type: AuthActions.REFRESH_TOKEN,
            payload: { token: data.token, tokenUpdateTime: now.getTime() }
          },
          {
            type: AuthActions.SET_EXPIRES,
            payload: data.expires
          }
        ];
      })
    );

  @Effect({dispatch: false})
  authLogout = this.actions$
    .pipe(
      ofType(AuthActions.LOGOUT),
      tap(() => {
        this.router.navigate(['/']);
      })
    );

  @Effect()
  requestPassRecovery = this.actions$.pipe(
    ofType(AuthActions.TRY_RECOVERY_PASS),
    map((action: AuthActions.TryRecoveryPass) => {
      return action.payload;
    }),
    switchMap((recoveryData: { email: string }) => {
      return this.http.post(environment.target + environment.context + '/forgotpassword', recoveryData).pipe(
        map((res: {msg: string}) => {
          this.snotifyService.info(res.msg, null, this.snotifyConfigService.getConfig());
          return of(EMPTY);
        }),
        catchError((error) => {
          // tslint:disable-next-line:max-line-length
          const message = 'If an account with that email address exists, we will send instruction to you. If it does not exist, you will receive nothing';
          this.snotifyService.info(message, null, this.snotifyConfigService.getConfig());
          return of(EMPTY);
        })
      );
    }),
    mergeMap(() => {
      return of(new AuthActions.AuthSetLoadingState(false));
    })
  );

  @Effect({ dispatch: false })
  recoveryPass = this.actions$.pipe(
    ofType(AuthActions.RECOVERY_PASS),
    map((action: AuthActions.RecoveryPass) => {
      return action.payload;
    }),
    switchMap((recoveryData: PasswordRecoveryData) => {
      return this.http.post(environment.target + environment.context + '/accounts/password/reset', recoveryData, {
        withCredentials: true
      }).pipe(
        map(() => {
          this.snotifyService.info('Successfully reset password. Please log in.', null, this.snotifyConfigService.getConfig());
          this.router.navigate(['/landing']);
        }),
        catchError((error) => {
          this.snotifyService.error('There was an issue resetting your password. Please try again', null,
            this.snotifyConfigService.getConfig());
          return of(error);
        })
      );
    })
  );
}
