import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FriendsService} from '../friends.service';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from '../../../../_services/snotify-config.service';
import {Store} from '@ngrx/store';
import * as SearchActions from '../../../main/search/store/search.actions';
import * as fromProfileFriends from './../../../separate/profile-friends/store/profile-friends.reducer';
import {MEMBER_STATUS_PRIORITY} from 'src/app/app.constants';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-friends-item',
  templateUrl: './friends-item.component.html',
  styleUrls: ['./friends-item.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FriendsItemComponent implements OnInit {
  @Output() closeDialog: any = new EventEmitter<void>();
  @Output() removeItem: any = new EventEmitter<void>();
  @Output() manualClick: any = new EventEmitter<void>();
  @Input() isManualClickEvent: boolean;
  @Input() friendInfo: fromProfileFriends.State;
  @Input() point: string;
  @Input() showBacking: boolean = false;
  isLoading = false;
  isAccepted: boolean;
  isRequested: boolean;
  memberStatuses: {[key: string]: boolean} = {};

  constructor(
    private friendsService: FriendsService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {
    Object.keys(MEMBER_STATUS_PRIORITY).forEach(key => {
      this.memberStatuses[key] = false;
    });
  }

  ngOnInit() {
    if (this.point === 'member' && this.friendInfo.memberStatus && this.friendInfo.memberStatus.length > 0) {
      const statusByPriority = this.friendInfo.memberStatus.sort((a, b) => {
        return MEMBER_STATUS_PRIORITY[a] - MEMBER_STATUS_PRIORITY[b];
      });
      statusByPriority.forEach((item) => {
        this.memberStatuses[item] = true;
      });
    }
    if (this.friendInfo.status) {
      switch (this.friendInfo.status) {
        case 'isAccepted':
          this.isAccepted = true;
          break;
        case 'isRequested':
          this.isRequested = true;
          break;
        default:
          break;
      }
      this.cdr.detectChanges();
    }
  }

  addFriend() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.friendsService.sendFriendRequest(this.friendInfo.id ? this.friendInfo.id : this.friendInfo.userId).subscribe((res) => {
      this.isLoading = false;
      this.isRequested = true;
      this.friendInfo.status = 'sent';
      this.cdr.detectChanges();
      this.snotifyService.success(
        'Friend request sent successfully.',
        null, this.snotifyConfigService.getConfig()
      );
    }, () => {
      this.snotifyService.error(
        'There was an error attempting to get suggested friends. Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }

  acceptFriendRequest() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.friendsService.acceptFriendRequest(this.friendInfo.id).subscribe((res) => {
      this.isLoading = false;
      this.isAccepted = true;
      this.friendInfo.status = 'approved';
      this.cdr.detectChanges();
      this.removeItem.emit();
    }, () => {
      this.snotifyService.error(
        'Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }

  cancelFriendRequest() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.friendsService.cancelFriendRequest(this.friendInfo.id).subscribe((res) => {
      this.isLoading = false;
      this.cdr.detectChanges();
      this.removeItem.emit();
    }, () => {
      this.snotifyService.error(
        'Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }

  cancelSentFriendRequest() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.friendsService.cancelFriendRequest(this.friendInfo.id).subscribe((res) => {
      this.isLoading = false;
      this.cdr.detectChanges();

      this.removeItem.emit();
    }, () => {
      this.snotifyService.error(
        'Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }

  confirmRemoveFriend(friendName) {
    const tmp = `<p>Are you sure you want to remove <b>${friendName}</b> as a friend?</p>`;
    this.snotifyService.confirm(
      `Remove Friend?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        html: tmp,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.removeFriend();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'Cancel',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }

  removeFriend() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.friendsService.removeFriend(this.friendInfo.id).subscribe((res) => {
      this.isLoading = false;
      this.cdr.detectChanges();

      this.removeItem.emit();
      this.snotifyService.success(
        'Successfully removed existing friend.',
        null, this.snotifyConfigService.getConfig()
      );
    }, () => {
      this.snotifyService.error(
        'There was an error attempting to remove an existing friend. Please try again.',
        null, this.snotifyConfigService.getConfig()
      );
    });
  }

  onCloseDialog() {
    this.store.dispatch(new SearchActions.HideSearch());
    this.closeDialog.emit();
  }
  onManualClick() {
    this.manualClick.emit();
    if (this.friendInfo.userId > 0 && !this.friendInfo.anonymous) {
      this.onCloseDialog();
    }
  }

}
