import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './_guards/auth-guard.service';
import {NewLandingComponent} from './components/pages/new-landing/new-landing.component';
import {FriendsComponent} from './components/pages/friends/friends.component';
import {AccountComponent} from './components/pages/account/account.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {QuestDetailComponent} from './components/pages/quest-detail/quest-detail.component';
import {DonateComponent} from './components/pages/donate/donate.component';
import {DonateAmountComponent} from './components/pages/donate/donate-amount/donate-amount.component';
import {DonateConfirmComponent} from './components/pages/donate/donate-confirm/donate-confirm.component';
import {DonateSuccessComponent} from './components/pages/donate/donate-success/donate-success.component';
import {QuestManageComponent} from './components/pages/quest-manage/quest-manage.component';
import {TempprofileComponent} from './components/pages/tempprofile/tempprofile.component';
import {FriendsAcceptedComponent} from './components/pages/friends/friends-accepted/friends-accepted.component';
import {FriendsRequestsComponent} from './components/pages/friends/friends-requests/friends-requests.component';
import {FriendsSearchComponent} from './components/pages/friends/friends-search/friends-search.component';
import {HelpComponent} from './components/pages/help/help.component';
import {PrivacyComponent} from './components/pages/privacy/privacy.component';
import {TermsComponent} from './components/pages/terms/terms.component';
import {PasswordResetComponent} from './components/main/auth/password-reset/password-reset.component';

import {LegalComponent} from './components/pages/legal/legal.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {UserGuideComponent} from './components/pages/user-guide/user-guide.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {LegalPolicyComponent} from './components/pages/legal/legal-policy/legal-policy.component';
import {LegalCommunityComponent} from './components/pages/legal/legal-community/legal-community.component';
import {LegalPrivacyComponent} from './components/pages/legal/legal-privacy/legal-privacy.component';
import {LegalTermsComponent} from './components/pages/legal/legal-terms/legal-terms.component';
import {QuestDetailDescriptionComponent} from './components/pages/quest-detail-description/quest-detail-description.component';
import {QuestDetailCommentsComponent} from './components/pages/quest-detail-comments/quest-detail-comments.component';
import { ActivityFeedComponent } from './components/main/activity-feed/activity-feed.component';

const appRoutes: Routes = [
  {
    path: '',
    component: NewLandingComponent,
    data: [{ hideHeader: true }]
  },
  // {
  //   path: 'landing',
  //   component: LandingComponent,
  //   data: [{ hideHeader: true }]
  // },
  { path: 'explore', loadChildren: () => import('./modules/explore/explore.module').then(m => m.ExploreModule) },
  // { path: 'explore', component: ExploreComponent },
  {
    path: 'friends',
    component: FriendsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: FriendsAcceptedComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'requests',
        component: FriendsRequestsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'search',
        component: FriendsSearchComponent,
        canActivate: [AuthGuard]
      },
      { path: '**', redirectTo: '' }
    ]
  },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'activity', component: ActivityFeedComponent, canActivate: [AuthGuard] },
  { path: 'contact', component:NewLandingComponent, data: [{ hideHeader: true, openContact: true }]},
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  { path: 'profile/:userId', component: ProfileComponent, pathMatch: 'prefix' },
  // { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  {
    path: 'profileTemp',
    component: TempprofileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-quests',
    loadChildren: () => import('./modules/my-quests/my-quests.module').then(m => m.MyQuestsModule),
    canActivate: [AuthGuard]
  },
  // quests

  {path: 'create', component: QuestManageComponent, canActivate: [AuthGuard]},
  { path: 'edit/:questId', component: QuestManageComponent, canActivate: [AuthGuard] },

  // terms
  {
    path: 'legal',
    component: LegalComponent,
    children: [
      {
        path: 'terms',
        component: LegalTermsComponent
      },
      {
        path: 'privacy',
        component: LegalPrivacyComponent
      },
      {
        path: 'community',
        component: LegalCommunityComponent
      },
      {
        path: 'policy',
        component: LegalPolicyComponent
      },
      {
        path: '**',
        redirectTo: 'terms'
      }
    ]
  },

  // faq
  { path: 'faq', component: FaqComponent },

  // faq
  { path: 'contact', component: ContactComponent },

  // user-guide
  { path: 'product', component: UserGuideComponent },

  // payments
  {
    path: 'donate/:questId/:userId',
    component: DonateComponent,
    children: [
      { path: 'enter-amount', component: DonateAmountComponent },
      { path: 'confirm', component: DonateConfirmComponent },
      { path: 'success', component: DonateSuccessComponent },
      { path: '**', redirectTo: 'enter-amount' }
    ]
  },
  {
    path: 'get-tickets',
    loadChildren: () => import('./modules/ticket-flow/ticket-flow.module').then(m => m.TicketFlowModule)
  },
  // landing page
  { path: 'passwordReset/:token', component: PasswordResetComponent },
  { path: 'reset-password/:token', component: PasswordResetComponent },
  { path: 'help', component: HelpComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  // quest details must go last
  {
    path: ':userSlug/:questSlug/:questId/:userId',
    component: QuestDetailComponent
  },
  { path: 'quest-detail-description/:questId/:userId', component: QuestDetailDescriptionComponent },
  { path: 'quest-detail-comments/:questId/:userId', component: QuestDetailCommentsComponent },
  { path: 'quest-detail/:questId/:userId', component: QuestDetailComponent },

  { path: 'ui', loadChildren: () => import('./modules/user-interface/user-interface.module').then(m => m.UserInterfaceModule) },

  // fallback
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
