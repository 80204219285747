import {LocalStorageService} from '../../../../_services/localstorage.service';
import * as AuthActions from './auth.actions';

export interface PincodeValidation {
  validation: boolean;
  formValue: string;
}

export enum FormMode {
  logIn = 'logIn',
  signUp = 'signUp',
  recoveryPassword = 'recoveryPassword'
}

export interface State {
  isLoading: boolean;
  token: string;
  email: string;
  authenticated: boolean;
  expires: number;
  tokenUpdateTime: number;
  pincodeValidation: PincodeValidation;
  formIsActive: boolean;
  formMode: FormMode;
}

const initialState: State = {
  isLoading: false,
  token: null,
  email: null,
  authenticated: false,
  expires: null,
  tokenUpdateTime: null,
  pincodeValidation: {
    validation: false,
    formValue: ''
  },
  formIsActive: false,
  formMode: null
};

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateAuth';

export function authReducer(state = {
  ...localStorageService.getInitialState(LOCAL_STATE_KEY, initialState),
  formIsActive: initialState.formIsActive,
  formMode: initialState.formMode
}, action: AuthActions.AuthActions) {
  switch (action.type) {
    case (AuthActions.SET_PIN_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        pincodeValidation: {
          ...action.payload
        }
      });
    case (AuthActions.TRY_SIGNIN):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        isLoading: true
      });
    case (AuthActions.TRY_RECOVERY_PASS):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        isLoading: true
      });
    case (AuthActions.AUTH_SET_LOADING_STATE):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        isLoading: action.payload
      });
    case (AuthActions.SIGNIN):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        authenticated: true
      });
    case (AuthActions.LOGOUT):
      localStorageService.clear(LOCAL_STATE_KEY);
      return {
        ...state,
        token: null,
        expires: null,
        authenticated: false,
        tokenUpdateTime: null
      };
    case (AuthActions.REFRESH_TOKEN):
    case (AuthActions.SET_TOKEN):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        token: action.payload.token,
        tokenUpdateTime: action.payload.tokenUpdateTime
      });
    case (AuthActions.SET_EXPIRES):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        expires: action.payload
      });
    case (AuthActions.AUTH_UPDATE_FORM_STATE):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        formIsActive: action.payload
      });
    case (AuthActions.SELECT_FORM_MODE):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        formMode: action.mode
      });
    case (AuthActions.SET_DEFAULT_EMAIL):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        email: action.email
      });
    default:
      return state;
  }
}
