<div class="c-dl-milestone" *ngIf="newMilestoneInfo">
	<div class="c-dl-milestone__inner">
		<div class="c-dl-milestone__count"><span>{{ index + 1 < 10 ? '0' + (index + 1) : index + 1 }}</span></div>
		<div class="c-dl-milestone__view">
			<div class="c-dl-milestone__view__empty"
           *ngIf="!newMilestoneInfo?.imageUrl && !newMilestoneInfo?.linkUrl && !newMilestoneInfo?.embeddedVideo && !newMilestoneInfo.linkedQuest"></div>
			<div class="c-dl-milestone__view__picture h-object-fit" *ngIf="newMilestoneInfo.embeddedVideo" (click)="openDialog()">
				<img [src]="newMilestoneInfo.embeddedVideo.thumbnailUrl" alt="">
			</div>
			<div class="c-dl-milestone__view__picture h-object-fit" *ngIf="newMilestoneInfo.imageUrl" (click)="openDialog()">
				<img [src]="'data:image/jpeg;base64,' + newMilestoneInfo.imageUrl" alt="">
			</div>
			<div class="c-dl-milestone__view__link" *ngIf="newMilestoneInfo.linkedQuest" (click)="openDialog()">
				<div class="c-dl-milestone__view__icon e-oval">
					<svg class="icon icon-link"><use xlink:href="#icon-link"></use></svg>
				</div>
				<div class="c-dl-milestone__view__link__picture h-object-fit">
          <img [src]="prepareCoverPhoto(newMilestoneInfo.linkedQuest.photo, newMilestoneInfo.linkedQuest.pillar).jpg" alt=""/>
				</div>
        <div class="c-dl-milestone__view__link__caption">
          <b class="c-dl-milestone__view__link__title">DIEMlife</b>
          <span class="c-dl-milestone__view__link__desc">{{ newMilestoneInfo.linkedQuest.title }}</span>
        </div>
			</div>
			<div class="c-dl-milestone__view__link" *ngIf="newMilestoneInfo.linkUrl" (click)="openDialog()">
				<div class="c-dl-milestone__view__icon e-oval">
					<svg class="icon icon-link"><use xlink:href="#icon-link"></use></svg>
				</div>
				<div class="c-dl-milestone__view__link__picture h-object-fit">
					<img *ngIf="newMilestoneInfo?.linkPreview?.image" [src]="newMilestoneInfo.linkPreview.image" alt="">
				</div>
				<div class="c-dl-milestone__view__link__caption">
					<b *ngIf="newMilestoneInfo?.linkPreview?.title || newMilestoneInfo.linkUrl" class="c-dl-milestone__view__link__title">{{ newMilestoneInfo?.linkPreview?.title ? newMilestoneInfo.linkPreview.title : newMilestoneInfo.linkUrl }}</b>
					<span *ngIf="newMilestoneInfo?.linkPreview?.description" class="c-dl-milestone__view__link__desc">{{ newMilestoneInfo.linkPreview.description }}</span>
				</div>
			</div>
			<app-dl-milestone-preview
				*ngIf="!newMilestoneInfo.imageUrl && !newMilestoneInfo.linkUrl && !newMilestoneInfo.linkedQuest && !newMilestoneInfo.video && !newMilestoneInfo.embeddedVideo"
				class="c-dl-milestone-preview"
				[index]="index"
				[id]="null">
			</app-dl-milestone-preview>
		</div>
		<div class="c-dl-milestone__body">
			<div class="c-dl-milestone__body__inner">
				<textarea
					class="c-dl-milestone__control c-dl-edit__input c-dl-milestone__desc"
					[ngClass]="{'is-invalid': this.submitted && newMilestoneInfo.task?.length === 0}"
					placeholder="Write a short description or instructions, no more than 2-3 lines long."
					(input)="setMilestoneTask($event)">{{ newMilestoneInfo.task }}</textarea>
				<div class="c-dl-milestone__tools">
					<button class="c-dl-milestone__tool" ngbTooltip="Remove" tooltipClass="c-dl-tooltip" (click)="removeNewMilestone()">
						<svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>
					</button>
					<button class="c-dl-milestone__tool is-disabled" ngbTooltip="Coming soon" tooltipClass="c-dl-tooltip">
						<svg class="icon icon-calendar"><use xlink:href="#icon-calendar"></use></svg>
					</button>
					<button class="c-dl-milestone__tool is-disabled" ngbTooltip="Coming soon" tooltipClass="c-dl-tooltip">
						<svg class="icon icon-dl-skip"><use xlink:href="#icon-dl-skip"></use></svg>
					</button>
					<button class="c-dl-milestone__tool is-disabled" ngbTooltip="Coming soon" tooltipClass="c-dl-tooltip">
						<svg class="icon icon-dl-noun-redo"><use xlink:href="#icon-dl-noun-redo"></use></svg>
					</button>
					<button class="c-dl-milestone__tool is-disabled" ngbTooltip="Complete" tooltipClass="c-dl-tooltip">
						<svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="c-dl-milestone__new__footer">
	<button *ngIf="!(dlMilestonesState | async).newMilestoneForPreview" class="e-btn" (click)="addNewMilestone()" type="button">
		<span>Add +</span>
	</button>
	<button *ngIf="(dlMilestonesState | async).newMilestoneForPreview" class="e-btn" [ngClass]="{'is-disabled': isLoading}" (click)="removeNewMilestone()" type="button">
		<span>Cancel</span>
	</button>
	<button *ngIf="(dlMilestonesState | async).newMilestoneForPreview" [ngClass]="{'is-loading': isLoading}" class="e-btn" (click)="submit()" type="button">
		<span>Submit</span>
	</button>
</div>
