import { Directive, OnInit, OnDestroy, HostListener, OnChanges, EventEmitter, Output } from '@angular/core';
import { WindowRef } from '../../../_global/window-ref.module';

@Directive({
  selector: '[appFixedNav]'
})
export class UserGuideDirective implements OnInit, OnDestroy, OnChanges {
  @Output() stickyChange: EventEmitter<{state: boolean, height: string}> = new EventEmitter<{state: boolean, height: string}>();
  fixedNav: any;
  fixedNavInner: any;

  constructor(private winRef: WindowRef) {}

  ngOnInit () {
    setTimeout(() => {
      this.fixedNav = document.getElementsByClassName('d-fixed-nav')[0];
      this.fixedNavInner = document.getElementsByClassName('d-fixed-nav-inner')[0];
    });
    this.updateFixedState();
  }
  ngOnChanges () {
    this.updateFixedState();
  }
  updateFixedState() {
    setTimeout(() => {
      if (this.fixedNav) {
        this.stickyChange.emit({
          state: this.fixedNav.getBoundingClientRect().top < 40,
          height: this.winRef.nativeWindow.getComputedStyle(this.fixedNavInner).height
        });
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  scrolling(event) {
    this.updateFixedState();
  }

  ngOnDestroy () {
    // console.log('ngOnDestroy');
  }

}
