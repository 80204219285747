import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as AuthActions from '../../auth/store/auth.actions';
import * as fromAuth from '../../auth/store/auth.reducer';
import * as Constants from '../../../../app.constants';
import * as config from '../../../../app.config';
import { Store, State } from '@ngrx/store';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-dl-log-in',
  templateUrl: './dl-log-in.component.html',
  styleUrls: ['./dl-log-in.component.styl']
})
export class DlLogInComponent implements OnInit, OnDestroy {
  messages = Constants.VALIDATION_MESSAGES;
  authSubscribtion: Subscription;
  authState: Observable<fromAuth.State>;
  activeState: string = null;
  signinForm: FormGroup;
  signinFormSubmitted = false;

  constructor(
    private store: Store<AppState>
  ) {
    this.authState = this.store.select('auth');
  }

  ngOnInit() {
    this.signinForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl(null, [
        Validators.required,
        Validators.minLength(config.password.minLength),
        Validators.maxLength(config.password.maxLength)
      ])
    });
    this.authSubscribtion = this.authState.subscribe((state: fromAuth.State) => {
      if (!this.signinFormSubmitted) {
        this.activeState = state.formMode ? fromAuth.FormMode[state.formMode] : 'logIn';
        this.signinForm.patchValue({email: state.email || null});
      }
    });
  }
  ngOnDestroy() {
    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
  }
  changeAuthTab(value: string) {
    this.store.dispatch(new AuthActions.SelectFormMode(fromAuth.FormMode[value]));
  }
  onSignin() {
    this.signinFormSubmitted = true;
    const email = this.signinForm.value.email;
    const password = this.signinForm.value.password;
    if (this.signinForm.valid) {
      this.store.dispatch(new AuthActions.TrySignin({
        email: email,
        password: password
      }));
    }
  }

}
