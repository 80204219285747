<perfect-scrollbar [scrollIndicators]="false"
                   [autoPropagation]="true"
                   [config]="{suppressScrollX: false, suppressScrollY: true}">
  <ul class="c-leaderboard-attribute__list">
    <li class="c-leaderboard-attribute__list-item"
        [ngClass]="{'c-leaderboard-attribute__list-item--selected': selectedId === attribute.id}"
        *ngFor="let attribute of attributes">
      <button class="c-btn c-btn_md c-btn_transparent c-leaderboard-attribute__selector"
              (click)="selectAttribute(attribute.id)">{{attribute.name}}</button>
    </li>
  </ul>
</perfect-scrollbar>
