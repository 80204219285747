<div class="terms--top-mod"><h1 class="terms--top-mod-title">Community Guidelines</h1>
</div>

<div class="terms--bottom">
  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <!--<h3 class="terms&#45;&#45;mod-title">Terms Of Service</h3>-->
      <p>
        DIEMlife is committed to providing a safe and trusted platform and creating a vibrant community where users
        are respectful of one another and free to be open about their projects. Toward that end, we will proactively
        monitor our Services for potential problems and offensive or harmful content and investigate any issues
        brought to our attention.
      </p>
      <p>
        These Community Guidelines are incorporated by reference into our Terms of Use and apply to the use of the
        Services.
      </p>
      <p>
        DIEMlife has the right at its sole discretion to remove any content, events or projects that do not comply
        with our Terms of Use, our policies and these Community Guidelines. You are responsible for complying with
        these policies and all applicable laws.
      </p>
      <p>
        You may not use the Services for activities, events or projects or post content or material that:
      </p>
      <p>
        1. Violate any laws or regulations.
      </p>
      <p>
        2. Involve illegal or inappropriate activities or products of any kind, including but not limited to the
        following:
      </p>
      <dl>
        <dt>• gambling,</dt>
        <dt>• lotteries, sweepstakes or raffles,</dt>
        <dt>• pyramid or ponzi schemes,</dt>
        <dt>• pornography,</dt>
        <dt>• sexual harassment,</dt>
        <dt>• organized crime,</dt>
        <dt>• unsolicited or unauthorized advertising,</dt>
        <dt>• investment opportunity or profit sharing,</dt>
        <dt>• payment processing, credit repair or money businesses,</dt>
        <dt>• money laundering, bribery or corruption,</dt>
        <dt>• political campaigns,</dt>
        <dt>• terrorism,</dt>
        <dt>• promoting hate, violence or racial intolerance,</dt>
        <dt>• promoting self-harm, or</dt>
        <dt>• regulated goods such as drugs, alcohol, tobacco, firearms, weapons and other hazardous materials.</dt>
      </dl>
      <p>
        3. Involve content that is harmful, offensive, dangerous, illegal, threatening, defamatory, abusive,
        harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, discriminatory, or contains any
        type of suggestive, inappropriate, or explicit language.
      </p>
      <p>
        4. Involve impersonation of any person or entity, including any DIEMlife employees or representatives.
      </p>
      <p>
        5. Share the personal data of others.
      </p>
      <p>
        6. Infringe on the intellectual property, privacy or rights of others.
      </p>
      <p>
        7. Interfere with the security, integrity and operation of our Services in any manner whatsoever. Do not:
      </p>
      <dl>
        <dt>• Illegally access, use or tamper with our Services, systems, networks or servers;</dt>
        <dt>• Disrupt, circumvent, or test our security systems or networks;</dt>
        <dt>• Reverse engineer our software;</dt>
        <dt>• Post, transmit or release a virus, worm, trojan horse, file, program or code that may harm or damage the
          operation and use of the Services and any software, hardware or equipment; or
        </dt>
        <dt>• Collect or store personally identifiable information of others without DIEMlife’s permission.</dt>
      </dl>
      <p>
        If you become aware of any content or activity that violates these Community Guidelines or our Terms of
        Service, please contact us immediately at help@diemlife.com.
      </p>
    </div>
  </div>
</div>