<div class="c-crop-area">
    <div class="c-crop-area__col" *ngIf="!imageChangedEvent">
        <div class="c-crop-area__body h-loader" [ngClass]="{'is-loading': isLoading}">
            <label class="c-crop-area__dropzone h-loader__caption">
                <input type="file" (change)="fileChangeEvent($event)" />
                <div class="c-crop-area__dropzone__title">
                    <svg class="icon icon-cloud_upload"><use xlink:href="#icon-cloud_upload"></use></svg>
                    <span>Choose Photo</span>
                </div>
            </label>
            <div class="h-loader__control is-currentColor"></div>
        </div>
    </div>
    <div *ngIf="imageChangedEvent" class="c-crop-area__col ">
        <div class="h-loader" [ngClass]="{'is-loading': isLoading}">
            <div class="h-loader__caption" [ngClass]="{'is-loading': isLoading}">
                <div class="c-crop-area__preview" [ngClass]="{'is-uploading': isUploading}">
                    <!-- <ng-progress class="ng-progress-inline" [showSpinner]="false" [color]="'#2ecc71'"></ng-progress> -->
                    <div class="c-crop-area__progress-bar"><i class="c-crop-area__progress-bar__inner"></i></div>
                    <img [src]="imagePreview" id="quest-img"/>
                </div>
            <!-- TODO temporarily disabled the cropper for performance reasons -->
                <!--<div class="c-crop-area__body">-->
                    <!--<div class="c-crop-area__preview">-->
                        <!--<image-cropper-->
                            <!--[imageChangedEvent]="imageChangedEvent"-->
                            <!--[maintainAspectRatio]="true"-->
                            <!--[imageQuality]="90"-->
                            <!--[onlyScaleDown]="true"-->
                            <!--[aspectRatio]="1"-->
                            <!--[resizeToWidth]="600"-->
                            <!--format="jpeg"-->
                            <!--outputType="base64"-->
                            <!--(imageCropped)="imageCropped($event)"-->
                            <!--(imageLoaded)="imageLoaded()"-->
                            <!--(loadImageFailed)="loadImageFailed()"-->
                        <!--&gt;</image-cropper>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
            <div class="h-loader__control is-currentColor"></div>
        </div>
        <div class="c-crop-area__caption" *ngIf="!hideCaption">
            <div class="c-forms__control is-textarea">
                <input name="control" class="c-forms__input" [(ngModel)]="caption" placeholder="Enter caption here..." required>
            </div>
        </div>
        <div class="c-crop-area__footer c-crop-area__footer__group">
            <div class="c-crop-area__footer__col">
                <label class="c-btn c-btn_md c-btn_label c-btn_full c-btn_dark-revert">
                    <svg class="icon icon-retweet"><use xlink:href="#icon-retweet"></use></svg>
                    <input ng-disabled="isUploading" type='file' (change)="fileChangeEvent($event)">
                    <span>Change</span>
                </label>
            </div>
            <div class="c-crop-area__footer__col">
                <button class="c-btn c-btn_md c-btn_full c-btn_dark" *ngIf="imageChangedEvent" ng-class="{'is-loading': isUploading}" (click)="submit()" type="button">
                    <svg *ngIf="!isUploading" class="icon icon-check"><use xlink:href="#icon-check"></use></svg>
                    <span>{{isUploading ? 'Uploading...' : 'Submit'}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
 