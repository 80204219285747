import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  private static readonly IMAGE_WIDTH = 'x-amz-meta-img-width';
  private static readonly IMAGE_HEIGHT = 'x-amz-meta-img-height';

  constructor(private http: HttpClient) {

  }

  private static parseWidth(response: HttpResponse<Blob>) {
    return response.headers.get(ImageService.IMAGE_WIDTH);
  }

  private static parseHeight(response: HttpResponse<Blob>) {
    return response.headers.get(ImageService.IMAGE_HEIGHT);
  }

  getImageDimensions(imageUrl: string): Observable<{ imageWidth: string, imageHeight: string }> {
    const headers = new HttpHeaders();
    headers.set('Option', environment.target);
    headers.set('Access-Control-Request-Headers', ImageService.IMAGE_WIDTH + ', ' + ImageService.IMAGE_HEIGHT);

    // Is it a full or relative url?
    let urlString;
    if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
      // Insert the CDN url directly in replace of anything going to cloudflare, which was stripping of Access-Control headers
      const url = new URL(imageUrl);
      urlString = environment.cdn + url.pathname;
    } else {
      // Could be an asset file included in web code payload
      urlString = imageUrl;
    }

    return this.http.head(urlString, {headers: headers, observe: 'response', responseType: 'blob'}).pipe(
      take(1),
      map((response: HttpResponse<Blob>) => {
        return {
          imageWidth: ImageService.parseWidth(response),
          imageHeight: ImageService.parseHeight(response)
        };
      })
    );
  }
}
