<div class="c-dl-milestone__inner">
	<div class="c-dl-milestone__count" [ngClass]="{'is-active': milestone.isTaskCompleted}"><span>{{ index + 1 < 10 ? '0' + (index + 1) : index + 1 }}</span></div>
	<div class="c-dl-milestone__view">
		<div class="c-dl-milestone__view__empty" *ngIf="!milestone.imageUrl && !milestone.linkUrl && !milestone.embeddedVideo && !milestone.linkedQuest"></div>
		<div class="c-dl-milestone__view__picture h-object-fit"
         *ngIf="milestone.embeddedVideo && !milestone.linkedQuest"
         (click)="openDialog()">
			<img [src]="milestone.embeddedVideo.thumbnailUrl" alt=""/>
			<div class="c-dl-milestone__view__icon e-oval">
				<svg class="icon icon-youtube"><use xlink:href="#icon-youtube"></use></svg>
			</div>
		</div>
		<picture class="c-dl-milestone__view__picture h-object-fit"
             *ngIf="milestone.imageUrl && !milestone.linkedQuest"
             (click)="openDialog()">
			<!-- TODO: it should be added after the backend will return all image resolutions -->
			<!-- <source media="(min-width: 767px)" [srcset]="milestone.imageUrl"> -->
			<!-- <source [srcset]="milestone.imageUrl | optiImage:'medium'"> -->
			<img [src]="milestone.id ? milestone.imageUrl : 'data:image/jpeg;base64,' + milestone.imageUrl" alt=""/>
		</picture>
		<div class="c-dl-milestone__view__link" *ngIf="milestone.linkedQuest" (click)="openDialog()">
			<div class="c-dl-milestone__view__icon e-oval">
				<svg class="icon icon-link"><use xlink:href="#icon-link"></use></svg>
			</div>
			<div class="c-dl-milestone__view__link__picture h-object-fit">
				<img [src]="prepareCoverPhoto(milestone.linkedQuest.photo, milestone.linkedQuest.pillar).jpg" alt=""/>
			</div>
			<div class="c-dl-milestone__view__link__caption">
				<b class="c-dl-milestone__view__link__title">DIEMlife</b>
				<span class="c-dl-milestone__view__link__desc">{{ milestone.linkedQuest.title }}</span>
			</div>
		</div>
		<div class="c-dl-milestone__view__link" *ngIf="milestone.linkUrl" (click)="openDialog()">
			<div class="c-dl-milestone__view__icon e-oval">
				<svg class="icon icon-link"><use xlink:href="#icon-link"></use></svg>
			</div>
			<div class="c-dl-milestone__view__link__picture h-object-fit">
				<img *ngIf="milestone?.linkPreview?.image" [src]="milestone.linkPreview.image" alt="">
			</div>
			<div class="c-dl-milestone__view__link__caption" *ngIf="milestone?.linkPreview?.title || milestone?.linkPreview?.description || milestone.linkUrl">
				<b *ngIf="milestone?.linkPreview?.title || milestone.linkUrl" class="c-dl-milestone__view__link__title">{{ milestone?.linkPreview?.title ? milestone.linkPreview.title : milestone.linkUrl }}</b>
				<span *ngIf="milestone?.linkPreview?.description" class="c-dl-milestone__view__link__desc">{{ milestone.linkPreview.description }}</span>
			</div>
		</div>
		<app-dl-milestone-preview
			*ngIf="isEditMode && canBeEdit && !milestone.imageUrl && !milestone.linkUrl && !milestone.linkedQuest && !milestone.embeddedVideo"
			class="c-dl-milestone-preview"
			[index]="index"
			[id]="milestone.id">
		</app-dl-milestone-preview>
	</div>
	<div class="c-dl-milestone__body">
		<div class="c-dl-milestone__body__inner">
			<p *ngIf="!isEditMode" class="c-dl-milestone__desc c-dl-edit__input with-field-styles">{{ milestone.task }}</p>
			<div *ngIf="isEditMode && isLoading === 'UPDATE_MILESTONE'" class="c-dl-milestone__body__loader h-xs-loader is-loading"><i class="h-xs-loader__control"></i></div>
			<textarea
				class="c-dl-milestone__control c-dl-edit__input c-dl-milestone__desc"
				placeholder="Write a short description or instructions, no more than 2-3 lines long."
				*ngIf="isEditMode"
				[ngClass]="{'is-invalid': isInvalid}"
				[attr.name]="'milestone-' + index"
				[ngModel]="milestone.task"
				[maxlength]="MAX_LENGTH"
				(input)="saveNewTask($event)"
				(focus)="onFocusIn()"
				(focusout)="onFocusOut()">
			</textarea>
			<div class="c-dl-milestone__tools" *ngIf="showControls || isEditMode">
				<span *ngIf="isEditMode" class="c-dl-milestone__tools__left" [ngClass]="{'is-red': maxLengthCount === 0}">{{ maxLengthCount }} characters left</span>
				<button class="c-dl-milestone__tool" *ngIf="isEditMode" ngbTooltip="Remove" tooltipClass="c-dl-tooltip" (click)="remove()" type="button">
					<svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>
				</button>
				<button class="c-dl-milestone__tool is-disabled" ngbTooltip="Coming soon" tooltipClass="c-dl-tooltip" type="button">
					<svg class="icon icon-calendar"><use xlink:href="#icon-calendar"></use></svg>
				</button>
				<button class="c-dl-milestone__tool is-disabled" ngbTooltip="Coming soon" tooltipClass="c-dl-tooltip" type="button">
					<svg class="icon icon-dl-skip"><use xlink:href="#icon-dl-skip"></use></svg>
				</button>
				<button class="c-dl-milestone__tool is-disabled" ngbTooltip="Coming soon" tooltipClass="c-dl-tooltip" type="button">
					<svg class="icon icon-dl-noun-redo"><use xlink:href="#icon-dl-noun-redo"></use></svg>
				</button>
				<button
					class="c-dl-milestone__tool h-xs-loader"
					ngbTooltip="Complete"
					tooltipClass="c-dl-tooltip"
					[ngClass]="{
						'is-active': milestone.isTaskCompleted,
						'is-disabled': !allowCheck || isEditMode || milestone.isLoading,
						'is-loading': milestone.isLoading
					}"
					[disabled]="!allowCheck || isEditMode || milestone.isLoading"
					(click)="onCheckChange()"
					type="button">
					<svg class="icon icon-checked h-xs-loader__caption"><use xlink:href="#icon-checked"></use></svg>
					<i class="h-xs-loader__control"></i>
				</button>
			</div>
		</div>
	</div>
</div>
