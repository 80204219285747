<div #header class="activity-header">
    <a #postButton class="post-button"
        (click)="openModal('activity-form-modal', null)"
        href="javascript:void(0)"
        [ngStyle]="{ 'margin-left.px' : max(header.offsetWidth/2 - min(header.offsetWidth/2, 500) - 5, 10) }">
        <img src="https://cdn.discordapp.com/attachments/564478672262791168/760550813025435698/post.svg">
    </a>
    <div #activityTabs class="activity-tabs" 
        [ngStyle]="{ 'margin-left.px' : min(header.offsetWidth/2, 500) / 2 - activityTabs.offsetWidth/2 - 56}">
        <!-- if the styles change in the future, 224 is the width of activity-tabs div, 56 is width of post button -->
        <a class="tab-button-text"
        [ngClass]="{ 'active' : activeTab == 0 }"
        (click)="onTabClick(0)"
        href="javascript:void(0)">Public</a>
        <a class="tab-button-text"
            [ngClass]="{ 'active' : activeTab == 1 }"
            (click)="onTabClick(1)"
            href="javascript:void(0)">Team</a>
        <a class="tab-button-text"
            [ngClass]="{ 'active' : activeTab == 2 }"
            (click)="onTabClick(2)"
            href="javascript:void(0)">Personal</a>
    </div>        
    <div class="activity-promotion"
        [ngStyle]="{ 'padding-right' : getPostButtonMarginLeft(postButton) }">
        <a class="activity-promotion-text" href="https://youtu.be/dQw4w9WgXcQ">
            📣 Promotional Content Here
        </a>
    </div>
</div>
<div #activityBody class="activity-body">
    <div class="activity-feed-container">
        <div *ngFor="let card of visibleActivityCards" class="card-div" [ngStyle]="{ 'width.px' : header.offsetWidth/2 }">
            <app-activity-card 
                [hasAnnouncement]="card.hasAnnouncement"
                [announcementText]="card.announcementText"
                [imgUrl]="card.imgUrl"
                [postText]="card.postText"
                [hashtags]="card.hashtags"
                [cheers]="card.cheers">
            </app-activity-card>
        </div>
        <div *ngIf="visibleActivityCards.length == 0" class="card-div" [ngStyle]="{ 'width.px' : header.offsetWidth/2 }">
            <app-activity-card 
                [hasAnnouncement]="false"
                [postText]="emptyFeed"
                [cheers]="0">
            </app-activity-card>
        </div>
    </div>
    <div class="activity-right">
        <div id="activity-map" #mapContainer
            class="activity-map-container" 
            [ngStyle]="{ 'width.px' : header.offsetWidth/2 }">
        </div>  
        <div class="activity-quest-ads">
            <div *ngIf="imgUrl" class="quest-img-container h-object-fit"
                [ngStyle]="{ 'width.px' : mapContainer.offsetWidth / 2 - 5, 'height.vh' : 25 }">
                <img [src]="imgUrl">
            </div>  
            <div *ngIf="imgUrl" class="quest-img-container h-object-fit"
                [ngStyle]="{ 'width.px' : mapContainer.offsetWidth / 2 - 5, 'height.vh' : 25 }">
                <img [src]="imgUrl">
            </div>  
        </div>
        <div class="activity-quest-captions">
            <div class="caption-div" [ngStyle]="{ 'width.px' : mapContainer.offsetWidth / 2 - 5 }">
                Cute Cat Quest 1
            </div>
            <div class="caption-div" [ngStyle]="{ 'width.px' : mapContainer.offsetWidth / 2 - 5 }">
                Cute Cat Quest 2
            </div>
        </div>
    </div>
    
</div>


<app-modal id="activity-form-modal">
    <app-activity-form (newPost)="addPersonalActivityCard($event)"></app-activity-form>
</app-modal>


