import {MapLocation} from 'diemlife-commons/dist/diemlife-commons-model';
import {Observable} from 'rxjs';

export class Utils {

  public static getLocation(): Observable<MapLocation> {
    return new Observable<MapLocation>(observer => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: Position) => {
            if (position) {
              observer.next({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              });
            } else {
              observer.next(null);
            }
            observer.complete();
          },
          (error: PositionError) => {
            console.error('Error getting geolocation', error);
            observer.next(null);
            observer.complete();
          });
      } else {
        console.log('Browser does not support geolocation');
        observer.next(null);
        observer.complete();
      }
      return observer;
    });
  }

}
