<div class="c-page c-profile-new__page" *ngIf="profileLoaded">
  <div class="c-container xm-no-pad">
    <div class="c-profile-new__container">
      <app-profile-header-new
        class="c-profile-new__header"
        [userInfo]="profileInfo"
        [country]="country"
        [mode]="mode"
        *ngIf="profileInfo && mode">
      </app-profile-header-new>
      <app-profile-interests class="c-article has-margin-top c-profile-new__section" *ngIf="profileLoaded && mode" [profileInfoId]="profileInfo.id" [mode]="mode"></app-profile-interests>
      <!-- <app-profile-header class="c-quest-header" [userInfo]="profileInfo">
        <button 
          *ngIf="mode === 'private'"
          type="button" 
          class="e-label is-huge" 
          (click)="requestPayout(availableBalance, firstBankAccount)" 
          [class.clickable]="!!availableBalance && !!firstBankAccount">
          <span>$&nbsp;{{totalBalance | number:'1.2-2'}}</span>
          <svg class="icon icon-circle-heart">
            <use xlink:href="#icon-circle-heart"></use>
          </svg>
        </button>
      </app-profile-header> -->
      <!-- 'is-thin' - add this class if tabs will be visible -->
      <!-- <div class="c-page__header c-container c-container_sm">
        <h1 class="c-page__title">
          <svg class="icon icon-dl-profile"><use xlink:href="#icon-dl-profile"></use></svg>
          <span>Profile</span>
        </h1>
        <p class="c-page__sub-title">Manage your information and your friends.</p>
      </div> -->
    <!-- </div>
    
    <div class="c-container c-container_sm hasHead lilHead profHead c-profile-new__container"> -->
      <div class="c-profile-new__tabs" *ngIf="tabs.active">
          <ul class="c-profile-new__tabs__nav">
            <li class="c-profile-new__tabs__nav__item" *ngFor="let tab of tabs.list" [class.is-active]="tab.id === tabs.active.id">
              <a href="javascript:void(0)" (click)="onTabChange(tab)">{{tab.caption}}</a>
            </li>
          </ul>
          <div class="c-profile-new__tabs__content" [ngSwitch]="tabs.active.id">
  
            <div class="c-profile-new__tabs__pane" *ngSwitchCase="'quests'">
  
              <!-- <div class="c-profile-new__quests__icons c-profile-new__section">
                Quests Icons
              </div> -->
  
              <div class="c-profile-new__quests c-profile-new__section" *ngIf="questsLoaded && quests.length > 0">
                <div class="c-profile-new__quests__list">
                  <div *ngFor="let quest of quests | slice:0:questsLimit" class="c-profile-new__quests__item" id="skip-nav-target">
                    <app-explore-card [doerInfo]="profileInfo" [quest]="quest" class="c-explore__item"></app-explore-card>
                  </div>
                  <a
                    href="javascript:void(null)" 
                    style="text-align: center; display: block" 
                    class="prof-list-more"
                    (click)="loadMoreQuests()"
                    *ngIf="quests.length > questsLimit">
                    Click to Load More...
                  </a>
                </div>
              </div>
              <div *ngIf="!quests.length && mode === 'private' && questsLoaded">
                <div class="c-my-friends__profile-empty">
                  <svg class="icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
                  <span>You do not have any quests yet</span>
                </div>
              </div>
              <div *ngIf="!quests.length && mode === 'public' && questsLoaded">
                <div class="c-my-friends__profile-empty">
                  <svg class="icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
                  <span>{{profileInfo.firstName}} has no quests yet</span>
                </div>
              </div>
            </div>
  
            <div class="c-profile-new__tabs__pane" *ngSwitchCase="'personal'">

              <app-members *ngIf="friendsLoaded && currentFriends"
                class="c-article is-isolated profile-friends c-profile-new__section"
                [firstName]="profileInfo.firstName"
                [mode]="mode === 'private' ? 'privateFriends' : 'publicFriends'"
                [doers]="currentFriends">
              </app-members>

              <div class="c-profile-new__balance c-profile-new__section" *ngIf="mode === 'private'">
                <div class="c-profile-new__balance__group">
                  <div class="c-profile-new__balance__row">
                    <b>Available Balance:</b>&nbsp;<span>{{(balance.available / 100.0) | currency: 'USD' }}</span>
                  </div>
                  <div class="c-profile-new__balance__row" *ngIf="balance.pending">
                    <b>Pending Balance:</b>&nbsp;<span>{{(balance.pending / 100.0) | currency: 'USD'}}</span>
                  </div>
                </div>
                <div class="c-profile-new__balance__tools">
                  <button
                    (click)="openDialog('send-payout-modal')"
                    class="c-btn c-btn_ms c-btn_dark-yellow"
                    [disabled]="!bankAccount || !balance.available">
                    <span>Transfer</span>
                  </button>
                </div>
              </div>

              <app-profile-subscriptions *ngIf="mode === 'private'" class="c-article is-isolated c-profile-new__section"></app-profile-subscriptions>
  
              <div class="c-profile-new__transactions c-profile-new__section" *ngIf="transactionsLoaded && profileLoaded && mode === 'private'">
                <section
                  class="diem-listing profile-section quests-mod-sec"
                  style="margin-bottom: 0px;">
                  <div class="clearfix">
                    <div class="ps-info rando-h3" style="text-transform: uppercase;">
                      Recent Transactions&nbsp;
                      <span *ngIf="recentTransactions">{{recentTransactions.length}}</span>&nbsp;
                      <a 
                        *ngIf="profileInfo.isUserBrand === 'Y'"
                        (click)="downloadTransactions()"
                        style="float: right;">Download</a>
                    </div>
                  </div>
                </section>
                <section class="profile-section profile-listing" *ngIf="recentTransactions.length > 0">
                  <app-profile-transaction
                    (emitGoToQuestlink)="goToQuestlink($event)"
                    (emitGoToProfileLink)="goToProfileLink($event)"
                    *ngFor="let trans of recentTransactions | slice:0:limitTrans as transResults; let i = index"
                    [data]="trans"
                    class="c-transaction"
                    [class.c-transaction__first-child]="i === 0">
                  </app-profile-transaction>
                  <br>
                  <a 
                    href="javascript:void(null)" 
                    style="text-align: center; display: block" 
                    class="prof-list-more"
                    (click)="loadMoreTransactions()"
                    *ngIf="recentTransactions.length > limitTrans">
                    Click to Load More...
                  </a>
                </section>
                <div *ngIf="recentTransactions.length === 0">
                  <div class="c-my-friends__profile-empty">
                    <svg class="icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
                    <span>You do not have any transactions yet</span>
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
      </div>
    </div>
  </div>
</div>
<app-modal id="send-payout-modal" (closeModal)="closeModal()">
  <h3 class="modal-title">Transfer money</h3>
  <form #ngForm class="modal-body" *ngIf="bankAccount" [formGroup]="sendPayoutForm" (ngSubmit)="sendPayout()">
    <div class="c-forms__group">
      <div class="c-forms__label">Amount ({{ (balance.available / 100.0)  | currency: 'USD' }} available)</div>
      <div class="c-forms__control">
        <input class="c-forms__input" formControlName="payoutAmount" aria-label="Input amount to payout">
      </div>
    </div>
    <div *ngIf="sendPayoutForm.get('payoutAmount').invalid && (sendPayoutForm.get('payoutAmount').touched || sendPayoutFormsSubmitted)" class="c-forms__message is-red" role="alert">
      <div *ngIf="sendPayoutForm.get('payoutAmount').errors.required">{{ messages.required }}</div>
    </div>
    <div class="c-forms__group">
      <div class="c-forms__label">Bank account</div>
      <div class="c-forms__control has-icon">
        <div class="c-forms__control__icon">
          <svg class="icon icon-bank"><use xlink:href="#icon-bank"></use></svg>
        </div>
        <div class="c-forms__input is-disabled">{{ bankAccount.name + ' …' + bankAccount.id }}</div>
      </div>
    </div>
    <div class="c-forms__group">
      <button class="c-btn c-btn_md c-btn_dark-yellow c-btn_full"
        [disabled]="sendPayoutForm.invalid && isLoading === 'transfer-money'"
        [ngClass]="{'is-loading': isLoading === 'transfer-money'}"
        type="submit">
        <span>Transfer {{ sendPayoutForm.get('payoutAmount').value }}</span>
      </button>
    </div>
  </form>
</app-modal>
