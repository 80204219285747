import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
// import {QuestGalleryImage} from '../quest.type';
import {QuestCarouselService} from './quest-carousel.service';
import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import {QuestService} from 'src/app/_services/quest.service';
import { QuestGalleryImage } from 'diemlife-commons/dist/diemlife-commons-model';
import {queriesFromFields} from "@angular/compiler-cli/src/ngtsc/annotations/src/directive";
import { AppState } from 'src/app/_store/app.reducers';

const slidesToShow = 3;
declare var $: any;

@Component({
  selector: 'app-quest-carousel',
  templateUrl: './quest-carousel.component.html',
  styleUrls: ['./quest-carousel.component.styl']
})
export class QuestCarouselComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() images: QuestGalleryImage[];
  @ViewChild('toolsContainer', { static: true }) toolsContainer: ElementRef;
  userInfoState: Observable<fromProfile.State>;
  isLoaded = false;
  slideConfig: any;
  altImageText: string;
  photoDimensions = '2048x2048';

  constructor(
    private questCarouselService: QuestCarouselService,
    private store: Store<AppState>,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private questService: QuestService,
    private cd: ChangeDetectorRef
  ) {
    this.userInfoState = this.store.select('userInfo');
  }

  ngOnInit() {
    this.slideConfig = {
      slidesToShow: slidesToShow,
      infinite: false,
      dots: false,
      arrows: true,
      prevArrow: '<button class="c-gallery__tools__arrow-left h-btn-reset" type="button" aria-label="Previous"><svg class="icon icon-arrow_left"><use xlink:href="#icon-arrow_left"></use></svg></button>',
      nextArrow: '<button class="c-gallery__tools__arrow-right h-btn-reset" type="button" aria-label="Next"><svg class="icon icon-arrow_right"><use xlink:href="#icon-arrow_right"></use></svg></button>',
      appendArrows: this.toolsContainer.nativeElement,
      centerMode: false,
      swipeToSlide: true,
      draggable: false,
      touchThreshold: 10,
      customPaging : function(slider, index) {
        return `<span>${slider.$slides.length} ${((slider.$slides.length === 1) ? 'photo' : 'photos')}</span>`;
      }
    };
  }
  ngAfterViewChecked() {
    this.isLoaded = true;
    this.cd.detectChanges();
  }

  ngOnChanges() {
  }
  afterChange(e) {
  }
  openGallery() {
  }
  onInitCarousel(event: any) {
    this.questCarouselService.initPhotoSwipeFromDOM('.my-gallery');
  }
  imagesLoaded(event: any): void {}
  getAltText(creatorName: string) {
    return this.altImageText = creatorName + 's photo';
  }
  confirmRemoveQuestPhoto(event, photoId, index) {
    event.stopPropagation();
    this.snotifyService.confirm(
      `Are you sure you would like to remove this Quest Photo?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        html: null,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.removePhoto(photoId, index);
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'Cancel',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }
  removePhoto(photoId, index) {
    this.questService.removeQuestPhoto(photoId).subscribe(() => {

      this.images.splice(index, 1);

      this.snotifyService.success(
        'Successfully removed Quest Photo.',
        null,
        this.snotifyConfigService.getConfig()
      );
    }, () => {
      this.snotifyService.error(
        'There was a problem removing Quest Photo. Please try again',
        null,
        this.snotifyConfigService.getConfig()
      );
    });
  }

  getPhotoDimensions(slide: QuestGalleryImage, image) {
    this.photoDimensions = image.naturalWidth + 'x' + image.naturalHeight;
    slide.questImageDimensions = this.photoDimensions;
  }
  // vm.confirmRemoveQuestPhoto = function (photo, index) {
  //   vm.removeQuestImage = {
  //     id: photo.id,
  //     index: index
  //   };
  //   Notification.info({templateUrl: 'confirm_remove_photo.html', scope: $scope, delay: 20000});
  // };

}
