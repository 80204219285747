import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {DROP_ZONE_IMAGE_CONFIG} from '../../../app.config';
import {ReaquestHeadersService} from '../../../_services/requestHeaders.service';
import {SnotifyConfigService} from '../../../_services/snotify-config.service';

@Component({
  selector: 'app-dropzone-upload',
  templateUrl: './dropzone-upload.component.html',
  styleUrls: ['./dropzone-upload.component.styl'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropzoneUploadComponent),
      multi: true
    }
  ]
})
export class DropzoneUploadComponent implements ControlValueAccessor, OnInit {

  @Input() placeholder?: string;
  @Input() large?: boolean = false;
  @Output() uploadProgress?: EventEmitter<boolean> = new EventEmitter<boolean>();

  dropZoneConfig: DropzoneConfigInterface = DROP_ZONE_IMAGE_CONFIG;
  dropZone: any = null;

  private value: string = null;
  private disabled: boolean = false;
  private notifyChange = (_: any) => {
    console.log('Change notification not bound!', _);
  };
  private notifyTouch = () => {
    console.log('Touch notification not bound!');
  };

  constructor(private requestHeadersService: ReaquestHeadersService,
              private notifyService: SnotifyService,
              private notifyConfig: SnotifyConfigService) {
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.notifyChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.notifyTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    const headers = {'Image-Type': this.large ? 'AVATAR' : 'COVER_PHOTO'};
    const auth = this.requestHeadersService.getHeaders().get('Authorization');
    if (auth) {
      headers['Authorization'] = auth;
    }
    this.dropZoneConfig = {
      ...DROP_ZONE_IMAGE_CONFIG,
      maxFiles: 1,
      thumbnailWidth: this.large ? 144 : 72,
      thumbnailHeight: 72,
      headers: headers
    };
    if (obj) {
      this.value = String(obj);
    } else {
      this.value = null;
      if (this.dropZone) {
        this.dropZone.removeAllFiles();
      }
    }
  }

  onUploadInit($event) {
    this.uploadProgress.emit(false);
    this.dropZone = $event;
  }

  onUploadReplace($event) {
    this.uploadProgress.emit(false);
    this.dropZone.removeAllFiles();
    this.dropZone.addFile($event);
  }

  onUploadStart() {
    if (this.disabled) {
      return;
    }
    this.uploadProgress.emit(true);
    this.notifyTouch();
  }

  onUploadSuccess($event) {
    this.uploadProgress.emit(false);
    if ($event && $event.length > 1) {
      this.value = $event[1];
      this.notifyChange(this.value);
    }
  }

  onUploadError($event) {
    this.uploadProgress.emit(false);
    this.notifyService.error('Image upload failed!', null, this.notifyConfig.getConfig());
    console.log('Upload failed', $event);
  }

}
