import * as config from './app.config';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {QuillModule} from 'ngx-quill';
import {NgxCaptchaModule} from 'ngx-captcha';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {ImageCropperModule} from 'ngx-image-cropper';
import {TagInputModule} from 'ngx-chips';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ClipboardModule} from 'ngx-clipboard';
import {NgxTributeModule} from 'ngx-tribute';

import {WindowRef} from './_global/window-ref.module';

import {KeysPipe} from './_pipes/keys.pipe';
import {HtmlToPlaintextPipe} from './_pipes/html-to-plain-text.pipe';
import {SafePipe} from './_pipes/safe-pipe';
import {GrdFilterPipe} from './_pipes/grd-filter.pipe';
import {UrlifyPipe} from './_pipes/urlify.pipe';
import {ReversePipe} from './_pipes/reverse.pipe';
import {TimeAgoPipe} from './_pipes/time-ago.pipe';
import {UdpCurrencyMaskPipe } from './_pipes/udp-currency-mask.pipe';
import {UdpCurrencyMaskV2Pipe } from './_pipes/udp-currency-mask-v2.pipe';
// import {TimeAgoPipe} from 'time-ago-pipe';
// import {TimeagoModule} from 'ngx-timeago';
import {ParseLinksPipe} from './_pipes/parse-links.pipe';
import {metaReducers, reducers} from './_store/app.reducers';
import {AuthEffects} from './components/main/auth/store/auth.effects';
import {ProfileEffects} from './components/pages/profile/store/profile.effects';
import {RecentTransactionsEffects} from './components/pages/profile/store/recent-transactions.effects';
import {UserFavoritesEffects} from './components/pages/profile/store/user-favorites.effects';
import {UserFriendsEffects} from './components/separate/profile-friends/store/profile-friends.effects';
import {AuthGuard} from './_guards/auth-guard.service';
import {ModalService} from './components/main/modal/modal.service';
import {MenuService} from './components/main/menu/menu.service';
import {ProfileService} from './components/pages/profile/profile.service';
import {ProfileAvatarService} from './components/separate/profile-avatar/profile-avatar.service';
import {AuthService} from './components/main/auth/auth.service';
import {DonateService} from './components/pages/donate/donate.service';
import {ShareWidjetService} from './components/main/share-widjet/share-widjet.service';
import {QuestCarouselService} from './components/main/quest/quest-carousel/quest-carousel.service';
import {LocalStorageService} from './_services/localstorage.service';
import {NotificationsService} from './components/main/notifications/notifications.service';
import {FriendsService} from './components/pages/friends/friends.service';
import {MembersService} from './components/main/members/members.service';
import {CropperService} from './components/main/cropper-dialog/cropper-dilog.service';
import {CommentService} from './components/main/quest/quest-comments/comment/comment.service';
import {HTTPListener, HTTPStatus} from './interceptors/loader.interceptor';

import {AppComponent} from './app.component';
import {HeaderComponent} from './components/main/header/header.component';
// import {ExploreComponent} from './components/pages/explore/explore.component';
import {LandingComponent} from './components/pages/landing/landing.component';
import {AuthComponent} from './components/main/auth/auth.component';
import {MenuComponent} from './components/main/menu/menu.component';
import {MyQuestsComponent} from './components/pages/my-quests/my-quests.component';
import {FriendsComponent} from './components/pages/friends/friends.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {AccountComponent} from './components/pages/account/account.component';
import {NotificationsComponent} from './components/main/notifications/notifications.component';
import {ProfileFriendsComponent} from './components/separate/profile-friends/profile-friends.component';
import {PaymentsComponent} from './components/main/payments/payments.component';
import {CardControlDialogComponent} from './components/pages/account/card-control-dialog/card-control-dialog.component';
import {HelpComponent} from './components/pages/help/help.component';
// tslint:disable-next-line:max-line-length
import {BankAccountControlDialogComponent} from './components/pages/account/bank-account-control-dialog/bank-account-control-dialog.component';
import {ExploreFilterComponent} from './components/pages/explore/explore-filter/explore-filter.component';
import {ExploreCardComponent} from './components/pages/explore/explore-card/explore-card.component';
import {QuestDetailComponent} from './components/pages/quest-detail/quest-detail.component';
import {QuestProfileComponent} from './components/main/quest/quest-profile/quest-profile.component';
import {QuestHeaderComponent} from './components/main/quest/quest-header/quest-header.component';
import {QuestInfoComponent} from './components/main/quest/quest-info/quest-info.component';
import {QuestDonateComponent} from './components/main/quest/quest-donate/quest-donate.component';
import {QuestDescriptionComponent} from './components/main/quest/quest-description/quest-description.component';
import {ToggleDirective} from './directives/toggle/toggle.directive';
import {QuestDropdownComponent} from './components/main/quest/quest-dropdown/quest-dropdown.component';
import {DonateComponent} from './components/pages/donate/donate.component';
import {DonateAmountComponent} from './components/pages/donate/donate-amount/donate-amount.component';
import {DonateConfirmComponent} from './components/pages/donate/donate-confirm/donate-confirm.component';
import {DonateSuccessComponent} from './components/pages/donate/donate-success/donate-success.component';
import {SignUpComponent} from './components/main/auth/sign-up/sign-up.component';
import {PincodeComponent} from './components/main/auth/sign-up/pincode/pincode.component';
import {MyQuestsActiveComponent} from './components/pages/my-quests/my-quests-active/my-quests-active.component';
import {MyQuestsSavedComponent} from './components/pages/my-quests/my-quests-saved/my-quests-saved.component';
import {MyQuestsCompletedComponent} from './components/pages/my-quests/my-quests-completed/my-quests-completed.component';
import {MegaQuestLinkComponent} from './components/main/quest/linking/mega-quest-link.component';
import {QuestManageComponent} from './components/pages/quest-manage/quest-manage.component';
import {QuestCommentsComponent} from './components/main/quest/quest-comments/quest-comments.component';
import {CommentComponent} from './components/main/quest/quest-comments/comment/comment.component';
import {ProfilePrivateComponent} from './components/main/profile/profile-private/profile-private.component';
import {ProfilePublicComponent} from './components/main/profile/profile-public/profile-public.component';
import {CommentDropdownComponent} from './components/main/quest/quest-comments/comment/comment-dropdown/comment-dropdown.component';
import {ProfileHeaderComponent} from './components/main/profile/profile-header/profile-header.component';
import {QuestCarouselComponent} from './components/main/quest/quest-carousel/quest-carousel.component';
import {PhotoSwipeComponent} from './components/separate/photo-swipe/photo-swipe.component';
import {VerifyBankDialogComponent} from './components/main/payments/verify-bank-dialog/verify-bank-dialog.component';
import {CropperDialogComponent} from './components/main/cropper-dialog/cropper-dialog.component';
// tslint:disable-next-line:max-line-length
import {FundraiseSwitchComponent} from './components/main/quest/manage/fundraise-switch/fundraise-switch.component';
import {FocusTriggerDirective} from './directives/focus-trigger/focus-trigger.directive';
import {ProfileSubscriptionsComponent} from './components/main/profile/profile-subscriptions/profile-subscriptions.component';
import {ProfileTransactionComponent} from './components/main/profile/profile-transaction/profile-transaction.component';
import {TempprofileComponent} from './components/pages/tempprofile/tempprofile.component';
import {FriendsAcceptedComponent} from './components/pages/friends/friends-accepted/friends-accepted.component';
import {FriendsRequestsComponent} from './components/pages/friends/friends-requests/friends-requests.component';
import {FriendsSearchComponent} from './components/pages/friends/friends-search/friends-search.component';
import {SearchComponent} from './components/main/search/search.component';
import {MembersComponent} from './components/main/members/members.component';
import {PrivacyComponent} from './components/pages/privacy/privacy.component';
import {TermsComponent} from './components/pages/terms/terms.component';
import {UserGuideDirective} from './components/pages/user-guide/user-guide.directive';
import {MembersDirective} from './components/main/members/members.directive';
import {InputFocusTriggerDirective} from './directives/focus-trigger/input-focus-trigger.directive';
import {ProfileHeaderNewComponent} from './components/main/profile/profile-header-new/profile-header-new.component';
import {PasswordResetComponent} from './components/main/auth/password-reset/password-reset.component';
import {FaqTestComponent} from './faq-test/faq-test.component';
import {LegalComponent} from './components/pages/legal/legal.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {UserGuideComponent} from './components/pages/user-guide/user-guide.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {FundsInfoComponent} from './components/main/funds-info/funds-info.component';
import {SkipNavComponent} from './components/main/skip-nav/skip-nav.component';
import {FundsModalComponent} from './components/main/funds-modal/funds-modal.component';
import {LegalPolicyComponent} from './components/pages/legal/legal-policy/legal-policy.component';
import {LegalCommunityComponent} from './components/pages/legal/legal-community/legal-community.component';
import {LegalPrivacyComponent} from './components/pages/legal/legal-privacy/legal-privacy.component';
import {LegalTermsComponent} from './components/pages/legal/legal-terms/legal-terms.component';
import {ProfileInterestsComponent} from './components/main/profile/profile-interests/profile-interests.component';
import {QuestHeaderToolsComponent} from './components/main/quest/quest-header-tools/quest-header-tools.component';
import {QuestDetailDescriptionComponent} from './components/pages/quest-detail-description/quest-detail-description.component';
import {CachedSrcDirective} from './directives/cached-src-directive/cached-src.directive';
// modules
import {SharedModule} from './modules/shared-module/shared.module';
import {MembersDialogComponent} from './components/main/members-dialog/members-dialog.component';
import {SortMembersPipe} from './components/main/members/sort-members.pipe';
import {AutosizeModule} from 'ngx-autosize';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {RouterLinkDirective} from './directives/routerLink/router-link.directive';
import {TeamListItemComponent} from './components/main/team/team-list-item/team-list-item.component';
import {TeamListComponent} from './components/main/team/team-list/team-list.component';
import {TeamFormComponent} from './components/main/team/team-form/team-form.component';
import {TeamQuestStarterComponent} from './components/main/team/team-quest-starter/team-quest-starter.component';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {QuestTeamModalEffects} from './components/pages/quest-detail/store/quest-team-modal.effects';
import {DlMilestonesEffects} from './components/main/dl-milestones/store/dl-milestones.effects';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {QuestHeaderTeamComponent} from './components/main/quest/quest-header-team/quest-header-team.component';
import {IndividualFormComponent} from './components/main/team/individual-form/individual-form.component';
import {DropzoneUploadComponent} from './components/separate/dropzone-upload/dropzone-upload.component';
import {LeaderboardMainComponent} from './components/main/leaderboard/leaderboard-main/leaderboard-main.component';
import {LeaderboardPodiumComponent} from './components/main/leaderboard/leaderboard-podium/leaderboard-podium.component';
// tslint:disable-next-line:max-line-length
import {LeaderboardAttributeChoiceComponent} from './components/main/leaderboard/leaderboard-attribute-choice/leaderboard-attribute-choice.component';
import {LeaderboardListComponent} from './components/main/leaderboard/leaderboard-list/leaderboard-list.component';
import {LeaderboardListItemComponent} from './components/main/leaderboard/leaderboard-list-item/leaderboard-list-item.component';
import {LeaderboardPodiumPlaceComponent} from './components/main/leaderboard/leaderboard-podium-place/leaderboard-podium-place.component';
import {LeaderboardScoreEditorComponent} from './components/main/leaderboard/leaderboard-score-editor/leaderboard-score-editor.component';
import {MentionDirective} from './directives/mention/mention.directive';
import {TeamUploadImageFormComponent} from './components/main/team/team-upload-image-form/team-upload-image-form.component';
import {NewLandingComponent} from './components/pages/new-landing/new-landing.component';
import {DlAuthComponent} from './components/main/dl-auth/dl-auth.component';
import {DlSignUpComponent} from './components/main/dl-auth/dl-sign-up/dl-sign-up.component';
import {DlPasswordResetComponent} from './components/main/dl-auth/dl-password-reset/dl-password-reset.component';
import {DlPincodeComponent} from './components/main/dl-auth/dl-sign-up/dl-pincode/dl-pincode.component';
import {QuestDetailCommentsComponent} from './components/pages/quest-detail-comments/quest-detail-comments.component';
import {DlLogInComponent} from './components/main/dl-auth/dl-log-in/dl-log-in.component';
import {DlForgotPasswordComponent} from './components/main/dl-auth/dl-forgot-password/dl-forgot-password.component';
import {ExploreComponent} from './components/pages/explore/explore.component';
import {DlMilestonesComponent} from './components/main/dl-milestones/dl-milestones.component';
import {DlMilestoneComponent} from './components/main/dl-milestones/dl-milestone/dl-milestone.component';
import {DlNewMilestoneComponent} from './components/main/dl-milestones/dl-new-milestone/dl-new-milestone.component';
import {DlMilestonePreviewComponent} from './components/main/dl-milestones/dl-milestone-preview/dl-milestone-preview.component';
import {DlMilestoneDialogComponent} from './components/main/dl-milestones/dl-milestone-dialog/dl-milestone-dialog.component';
import {OptiImagePipe} from './_pipes/opti-image.pipe';
import {NewsfeedCtrlComponent} from './components/main/quest/quest-comments/newsfeed-ctrl/newsfeed-ctrl.component';
import {NewsfeedPostComponent} from './components/main/quest/quest-comments/newsfeed-post/newsfeed-post.component';
import {MainDlNavComponent} from './components/main/main-dl-nav/main-dl-nav.component';
import { DonateBannerComponent } from './components/main/banner/banner.component';
import { ContactUsComponent } from './components/main/contact-us/contact-us.component';
import { ActivityFeedComponent } from './components/main/activity-feed/activity-feed.component';
import { ActivityCardComponent } from './components/main/activity-feed/activity-card/activity-card.component';
import { ActivityFormComponent } from './components/main/activity-feed/activity-form/activity-form.component';


// import {FilterPipeModule} from 'ngx-filter-pipe';


@NgModule({
  declarations: [
    AppComponent,
    // TimeAgoPipe,
    UdpCurrencyMaskPipe,
    UdpCurrencyMaskV2Pipe,
    KeysPipe,
    ParseLinksPipe,
    ReversePipe,
    HtmlToPlaintextPipe,
    TimeAgoPipe,
    SafePipe,
    // FilterPipeModule,
    GrdFilterPipe,
    UrlifyPipe,
    HeaderComponent,
    ExploreComponent,
    LandingComponent,
    AuthComponent,
    MenuComponent,
    MyQuestsComponent,
    FriendsComponent,
    ProfileComponent,
    AccountComponent,
    NotificationsComponent,
    ProfileFriendsComponent,
    PaymentsComponent,
    CardControlDialogComponent,
    BankAccountControlDialogComponent,
    ExploreFilterComponent,
    ExploreCardComponent,
    QuestDetailComponent,
    QuestProfileComponent,
    QuestHeaderComponent,
    MegaQuestLinkComponent,
    QuestInfoComponent,
    QuestDonateComponent,
    QuestDescriptionComponent,
    ToggleDirective,
    QuestDropdownComponent,
    DonateComponent,
    DonateAmountComponent,
    DonateConfirmComponent,
    DonateSuccessComponent,
    SignUpComponent,
    PincodeComponent,
    MyQuestsActiveComponent,
    MyQuestsSavedComponent,
    MyQuestsCompletedComponent,
    QuestManageComponent,
    QuestCommentsComponent,
    CommentComponent,
    ProfilePrivateComponent,
    ProfilePublicComponent,
    CommentDropdownComponent,
    ProfileHeaderComponent,
    QuestCarouselComponent,
    PhotoSwipeComponent,
    VerifyBankDialogComponent,
    CropperDialogComponent,
    FundraiseSwitchComponent,
    FocusTriggerDirective,
    InputFocusTriggerDirective,
    ProfileSubscriptionsComponent,
    ProfileTransactionComponent,
    TempprofileComponent,
    FriendsAcceptedComponent,
    FriendsRequestsComponent,
    FriendsSearchComponent,
    SearchComponent,
    MembersComponent,
    MembersDirective,
    UserGuideDirective,
    ProfileHeaderNewComponent,
    HelpComponent,
    PrivacyComponent,
    TermsComponent,
    PasswordResetComponent,
    FaqTestComponent,
    LegalComponent,
    FaqComponent,
    UserGuideComponent,
    ContactComponent,
    FundsInfoComponent,
    FundsModalComponent,
    SkipNavComponent,
    LegalPolicyComponent,
    LegalCommunityComponent,
    LegalPrivacyComponent,
    LegalTermsComponent,
    ProfileInterestsComponent,
    MembersDialogComponent,
    SortMembersPipe,
    QuestHeaderToolsComponent,
    RouterLinkDirective,
    TeamListItemComponent,
    TeamListComponent,
    TeamFormComponent,
    TeamQuestStarterComponent,
    QuestHeaderTeamComponent,
    IndividualFormComponent,
    QuestDetailDescriptionComponent,
    DropzoneUploadComponent,
    LeaderboardMainComponent,
    LeaderboardPodiumComponent,
    LeaderboardAttributeChoiceComponent,
    LeaderboardListComponent,
    LeaderboardListItemComponent,
    LeaderboardPodiumPlaceComponent,
    MentionDirective,
    TeamUploadImageFormComponent,
    NewLandingComponent,
    LeaderboardScoreEditorComponent,
    CachedSrcDirective,
    DlAuthComponent,
    DlSignUpComponent,
    DlPasswordResetComponent,
    DlPincodeComponent,
    QuestDetailCommentsComponent,
    DlLogInComponent,
    DlForgotPasswordComponent,
    DlMilestonesComponent,
    DlMilestoneComponent,
    DlNewMilestoneComponent,
    DlMilestonePreviewComponent,
    DlMilestoneDialogComponent,
    OptiImagePipe,
    NewsfeedCtrlComponent,
    NewsfeedPostComponent,
    MainDlNavComponent,
    DonateBannerComponent,
    ContactUsComponent,
    ActivityFeedComponent,
    ActivityCardComponent,
    ActivityFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      AuthEffects,
      ProfileEffects,
      RecentTransactionsEffects,
      UserFavoritesEffects,
      UserFriendsEffects,
      QuestTeamModalEffects,
      DlMilestonesEffects
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    SharedModule,
    AppRoutingModule,
    SnotifyModule,
    // TimeagoModule.forRoot(),
    QuillModule.forRoot(),
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NgxCaptchaModule,
    ImageCropperModule,
    TagInputModule,
    // NgScrollbarModule,
    // ScrollEventModule
    AutosizeModule,
    // NgProgressModule,
    ClipboardModule,
    NgScrollbarModule,
    PerfectScrollbarModule,
    DropzoneModule,
    NgxTributeModule,
    InfiniteScrollModule
  ],
  providers: [
    LocalStorageService,
    AuthGuard,
    AuthService,
    ModalService,
    MenuService,
    WindowRef,
    ProfileService,
    ProfileAvatarService,
    DonateService,
    ShareWidjetService,
    HTTPStatus,
    HTTPListener,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    },
    SnotifyService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: config.reCaptchaToken} as RecaptchaSettings,
    },
    QuestCarouselService,
    NotificationsService,
    FriendsService,
    MembersService,
    CropperService,
    CommentService,
    UdpCurrencyMaskPipe,
    UdpCurrencyMaskV2Pipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
