import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MenuItem } from './menu.model';
import { AuthService } from './../auth/auth.service';
import { MenuService } from './menu.service';
import { ModalService } from '../modal/modal.service';
import { skip } from 'rxjs/operators';
import * as fromAuth from '../auth/store/auth.reducer';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.styl']
})
export class MenuComponent implements OnInit {
  public menuItems: MenuItem[] = [];
  authState: Observable<fromAuth.State>;
  stateSubscriber: Subscription;

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private modalService: ModalService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.authState = this.store.select('auth');
    this.menuItems = this.menuService.getItems();
    this.authState.pipe(skip(1)).subscribe((state: fromAuth.State) => {
      if (state.authenticated) {
        this.onCloseMenu();
      }
    });
  }
  onLogout() {
    this.onCloseMenu();
    this.authService.logout();
  }

  onCloseMenu() {
    this.menuService.close();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
}
