<div class="c-map-popover">
    <div class="c-map-popover__header">
        <span class="bold">{{ questTitle }}</span>
        <span *ngIf="locationName" class="location-text">{{locationName}}</span>
    </div>
    <div class="c-map-popover__body" [ngClass]="{'justify-center': !(hasPrev && !hasNext), 'justify-left': hasPrev && !hasNext }">   
        <div *ngIf="hasPrev" class="btn-div-left">
            <button *ngIf="hasPrev"
                (click)="onPrevClick()"
                class="c-gallery__tools__arrow-left h-btn-reset left-btn" 
                type="button" 
                aria-label="Previous">
                <img class="arrow-btn" src="https://cdn.discordapp.com/attachments/247384470238265344/761654453333393428/Frame_16.svg">
            </button>
            <div *ngIf="!hasPrev" style="width: 24px"></div>
        </div>
        <div *ngIf="!showTeams">
            <div class="c-map-popover__avatar-div"
                *ngFor="let user of toShow">
                <a class="c-quest__doer avatar-doer"
                    href="javascript:void(0)"
                    [routerLink]='user.userId > 0 && !user.anonymous ? ["/profile", user.userName] : null'>
                    <app-profile-avatar
                        class="c-map-popover__avatar"
                        [avatar]="user.profilePictureURL"
                        [isBrand]="user.isUserBrand"
                        [firstName]="user.firstName"
                        [lastName]="user.lastName"
                        [userName]="user.userName">
                    </app-profile-avatar>  
                </a>
                <div class="c-quest__doer__amount donate-amount"
                    *ngIf="user.amountBackedSum">
                <span>{{ (user.amountBackedSum ? user.amountBackedSum / 100.0 : 0) | currency: 'USD' }}</span>
                </div>
            </div>
        </div>
        <div class="c-map-team-div" *ngIf="showTeams">
            <div class="c-map-team"
                *ngFor="let team of toShow">
                <app-map-team-list-item class="c-team-list__item-container"
                        [team]="team"
                        [selectable]="false"
                        [showMembersCount]="false"
                        [showBackedAmount]="false"></app-map-team-list-item>
            </div>
        </div>
        <div *ngIf="hasNext" class="btn-div">
            <button 
                *ngIf="hasNext"
                (click)="onNextClick()"
                class="c-gallery__tools__arrow-right h-btn-reset"
                type="button" 
                aria-label="Next">
                <img class="arrow-btn" src="https://cdn.discordapp.com/attachments/247384470238265344/761654469301239868/Frame_15.svg">
            </button>
        </div>
    </div>
</div>
