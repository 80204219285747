import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HTTPStatus } from '../../../../interceptors/loader.interceptor';
import { Subject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ReferenceDataService } from '../../../../_services/reference-data.service';
import { SnotifyConfigService } from '../../../../_services/snotify-config.service';
import { SnotifyService } from 'ng-snotify';
import { ValidateEmailNotTaken } from '../../auth/sign-up/email.validator';
import { PincodeValidation } from './dl-pincode/dl-pincode.component';
import { SignUpService, NewAccountInfo } from '../../auth/sign-up/sign-up.service';
import { RegistrationValidator } from '../../auth/sign-up/sign-up.validator';
import * as config from '../../../../app.config';
import * as Constants from '../../../../app.constants';
import * as fromAuth from '../../auth/store/auth.reducer';
import * as AuthActions from '../../auth/store/auth.actions';
import { AppState } from 'src/app/_store/app.reducers';

interface NewAccountSavedInfo {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  zip: string;
  passwordGroup: {
    password1: string;
    password2: string;
  };
  receiveEmail: boolean;
  recaptcha: string;
}

@Component({
  selector: 'app-dl-sign-up',
  templateUrl: './dl-sign-up.component.html',
  styleUrls: ['./dl-sign-up.component.styl']
})
export class DlSignUpComponent implements OnInit, OnDestroy {
  activityStatusSubscriber: Subscription;
  activityStatus = false;
  authState: Observable<fromAuth.State>;
  authSubscriber: Subscription;
  messages = Constants.VALIDATION_MESSAGES;
  signUpForm: FormGroup;
  passwordGroup: FormGroup;
  submitted = false;
  pinCode: any = null;
  window: any = null;
  isLoading: any = false;
  disabledSignup = true;
  countries: {[key: string]: string } = null;
  eventsSubject: Subject<void> = new Subject<void>();
  reCaptchaToken: string = config.reCaptchaToken;
  step = 'signUp'; // signUp
  pincodeValidation: PincodeValidation = {
    validation: false,
    formValue: ''
  };
  newAccountSavedInfo: NewAccountSavedInfo | null = null;

  constructor(
    private httpStatus: HTTPStatus,
    private referenceDataService: ReferenceDataService,
    private signUpService: SignUpService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private fb: FormBuilder,
    private store: Store<AppState>
  ) {
    this.activityStatusSubscriber = this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.activityStatus = status ? status : null;
    });
    this.authState = this.store.select('auth');
    this.passwordGroup = this.fb.group({
      'password1': [
        '',
        [
          Validators.required,
          Validators.minLength(config.password.minLength),
          Validators.maxLength(config.password.maxLength)
        ]
      ],
      'password2': ['', [Validators.required]]
    }, {
      validator: RegistrationValidator.validate.bind(this)
    });
    this.signUpForm = this.fb.group({
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.email], ValidateEmailNotTaken.createValidator(this.signUpService)],
      'country': ['', [Validators.required]],
      'zip': ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/)]],
      'passwordGroup': this.passwordGroup,
      'receiveEmail': [true],
      'recaptcha': ['', Validators.required]
    });
  }
  ngOnInit() {
    this.referenceDataService.getSupportedCountries().subscribe((res: {[key: string]: string}) => {
      this.countries = res;
      this.eventsSubject.next();
    });
    this.newAccountSavedInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_INFO'));
    if (this.newAccountSavedInfo) {
      this.step = 'pinControls';
    }
    this.authSubscriber = this.authState.subscribe((res: fromAuth.State) => {
      this.pincodeValidation = res.pincodeValidation;
    });
  }
  changeAuthTab(value: string) {
    this.store.dispatch(new AuthActions.SelectFormMode(fromAuth.FormMode[value]));
  }
  ngOnDestroy() {
    if (this.authSubscriber) {
      this.authSubscriber.unsubscribe();
    }
    if (this.activityStatusSubscriber) {
      this.activityStatusSubscriber.unsubscribe();
    }
  }
  markAsTouched() {
    this.signUpForm.get('country').markAsTouched();
  }
  buildOption(key: string, name: string) {
    const country = key.toLowerCase();
    return `
      <i class='flag flag-${country}'></i>
      <span>${name}</span>
    `;
  }
  signUp () {
    this.submitted = true;
    if (this.signUpForm.invalid || this.isLoading) {
      return false;
    }
    this.isLoading = true;
    const payload: NewAccountInfo = {
      ...this.signUpForm.value,
      ...this.signUpForm.value.passwordGroup,
      withPin: true
    };
    sessionStorage.setItem('NEW_ACCOUNT_INFO', JSON.stringify(this.signUpForm.value));
    delete payload.passwordGroup;
    this.signUpService.createAccount(payload).subscribe(() => {
      this.signUpService.requestPin(this.signUpForm.value.email).subscribe(() => {
        this.isLoading = false;
        this.step = 'pinControls';
      });
    });
  }
  handleSuccess($event: string) {
    this.referenceDataService.verifyCapcha($event).subscribe((res: any) => {
      this.disabledSignup = res !== 'status = 200';
    });
  }
  confirmPin() {
    this.isLoading = true;
    const email = this.signUpForm.valid ? this.signUpForm.value.email : this.newAccountSavedInfo.email;
    this.newAccountSavedInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_INFO'));

    if (this.pincodeValidation.validation) {
      this.signUpService.validatePin(email, this.pincodeValidation.formValue)
      .subscribe((pinValidationResult) => {
        if (this.signUpForm.invalid && !this.newAccountSavedInfo) {
          this.snotifyService.error(
            'Oops, something went wrong, please try again',
            null,
            this.snotifyConfigService.getConfig()
          );
          return false;
        } else {
          this.store.dispatch(new AuthActions.TrySignin({
            email: this.newAccountSavedInfo.email,
            password: this.newAccountSavedInfo.passwordGroup.password1
          }));
        }
      });
    }
  }
  requestNewPin() {
    const email = this.signUpForm.valid ? this.signUpForm.value.email : this.newAccountSavedInfo.email;
    this.signUpService.requestPin(email).subscribe(() => {
      this.snotifyService.success(
        'The new PIN-code has been successfully sent to your e-mail!',
        null,
        this.snotifyConfigService.getConfig()
      );
    });
  }
}
