import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ShareWidjetService} from './share-widjet.service';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {SnotifyService} from 'ng-snotify';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {SnotifyConfigService} from '../../../_services/snotify-config.service';
import * as fromAuth from '../auth/store/auth.reducer';
import ClipboardJS from 'clipboard/dist/clipboard.min';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-share-widjet',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './share-widjet.component.html',
  styleUrls: ['./share-widjet.component.styl']
})
export class ShareWidjetComponent implements AfterViewInit, OnDestroy {
  @ViewChild('clipboardTrigger', {static: false}) clipboardTrigger: ElementRef;
  @Input() questId: number;
  @Input() questUserId?: number;
  @Input() shareUrl?: string;
  authState: Observable<fromAuth.State>;
  authStateSubscription: Subscription;
  clipboard: any;
  emailControlisHidden = true;
  url: string;
  openedTab = 'copy';

  constructor(
    private shareWidjetService: ShareWidjetService,
    public location: Location,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private elRef: ElementRef,
    private store: Store<AppState>
  ) {
    this.authState = this.store.select('auth');
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.clipboard = new ClipboardJS(this.clipboardTrigger.nativeElement).on('success', () => {
        this.snotifyService.success('Successfully Copied!', null, this.snotifyConfigService.getConfig());
      });
    });
    this.shareWidjetService.getShareUrl(this.questId).subscribe((res: string) => {
      this.url = res;
    });
  }

  ngOnDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  collapseBody() {
    this.emailControlisHidden = !this.emailControlisHidden;
  }

  shareViaFacebook() {
    this.shareWidjetService.shareViaFacebook(this.url);
  }
  openTab(key: string) {
    this.openedTab = key;
  }

}
