import {Action} from '@ngrx/store';

export const SHOW_CONTACT = 'SHOW_CONTACT';
export const HIDE_CONTACT = 'HIDE_CONTACT';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_INFO = 'SET_NOTIFICATIONS_INFO';

export class ShowContact implements Action {
  readonly type = SHOW_CONTACT;
}
export class HideContact implements Action {
  readonly type = HIDE_CONTACT;
}

export type ContactActions =  ShowContact |
                              HideContact;
