<div class="c-forms__control is-select has-icon-right">
  <div (click)="openModal('pay-modal')" [ngSwitch]="submittedPayment.paymentName">
    <div class="c-payments__card" *ngSwitchCase="'newCreditCard'">
      <div class="c-payments__card__inner c-forms__control">
        <span class="c-payments__card__caption c-forms__input has-icon">
          <img *ngIf="submittedPayment.paymentData.cardType === 'Visa'" class="c-payments__card__icon" src="assets/images/useful/visa-card.svg" alt="">
          <img *ngIf="submittedPayment.paymentData.cardType === 'MasterCard'" class="c-payments__card__icon" src="assets/images/useful/master-card.svg" alt="">
          <span>XXXX XXXX XXXX {{submittedPayment.paymentData.lastFourDigits}}</span>
        </span>
      </div>
    </div>
    <div class="c-payments__card" *ngSwitchCase="'creditCard'">
      <div class="c-payments__card__inner c-forms__control">
        <span class="c-payments__card__caption c-forms__input has-icon">
          <img *ngIf="submittedPayment.paymentData.cardType === 'Visa'" class="c-payments__card__icon" src="assets/images/useful/visa-card.svg" alt="">
          <img *ngIf="submittedPayment.paymentData.cardType === 'MasterCard'" class="c-payments__card__icon" src="assets/images/useful/master-card.svg" alt="">
          <span>XXXX XXXX XXXX {{submittedPayment.paymentData.lastFourDigits}}</span>
        </span>
      </div>
    </div>
    <div class="c-payments__card" *ngSwitchCase="'bankAccount'">
      <div class="c-payments__card__inner c-forms__control">
        <span class="c-payments__card__caption c-forms__input has-icon">
            <svg class="c-payments__card__icon icon icon-bank"><use xlink:href="#icon-bank"></use></svg>
          <span>XXXX XXXX XXXX {{submittedPayment.paymentData.id}}</span>
        </span>
      </div>
    </div>
    <div class="c-forms__input" *ngSwitchDefault>
      <span>Choose payment method</span>
    </div>
  </div>
  <div class="c-forms__control__icon is-light is-right is-ghost">
    <svg class="icon icon-dots"><use xlink:href="#icon-dots"></use></svg>
  </div>
</div>

<!-- *ngIf="openedDialog === 'pay-modal'" [ngStyle]="{'display': 'block'}" -->
<app-modal id="pay-modal" (closeModal)="onCloseModal()">
  <h3 class="modal-title">Select payment method</h3>
  <form  class="modal-body c-payments c-container " #checkout="ngForm" (ngSubmit)="onSubmit()">
    <div class="c-payments__group">
      <div class="c-payments__group__body">
        <div class="c-payments__tab" *ngIf="(paymentsState | async).hasBankAccountForPayments">
          <ul class="c-payments__list">
            <li class="c-payments__list__item is-selectable">
              <span class="c-forms__legend">Bank account</span>
              <div class="c-forms__group"
                *ngFor="let bank of (paymentsState | async).bankAccounts; let i = index"
                [ngClass]="{'is-selected': payment.selected === 'bankAccount' && payment.index === i}">
                <div class="c-payments__card">
                  <div class="c-payments__card__inner c-forms__control" (click)="selectPayment('bankAccount', i)">
                    <span class="c-payments__card__caption c-forms__input has-icon">
                      <span>XXXX XXXX XXXX {{bank.id}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="c-payments__tab">
          <ul class="c-payments__list">
            <li class="c-payments__list__item is-selectable">
              <span class="c-forms__legend">Credit card{{(paymentsState | async).creditCards.length > 1 ? 's' : ''}}:</span>
              <div class="c-forms__group"
                *ngFor="let card of (paymentsState | async).creditCards; let i = index"
                [ngClass]="{'is-selected': payment.selected === 'creditCard' && payment.index === i}">
                <div class="c-payments__card">
                  <div class="c-payments__card__inner c-forms__control" (click)="selectPayment('creditCard', i)">
                    <span class="c-payments__card__caption c-forms__input has-icon">
                      <span>XXXX XXXX XXXX {{card.lastFourDigits}}</span>
                    </span>
                  </div>
                  <button class="c-payments__card__remove"
                    [ngClass]="{'is-loading': isloading.payment === 'creditCard' && isloading.index === i}"
                    (click)="removeCreditCard(i)"
                    type="button">
                    <svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>
                  </button>
                </div>
              </div>
            </li>
            <li class="c-payments__list__item is-selectable">
              <div class="c-forms__group" [ngClass]="{'is-selected': payment.selected === 'newCreditCard'}">
                <div class="c-forms__control">
                  <div class="c-payments__card__caption c-forms__input" id="card-info" #cardInfo [ngClass]="{'is-focus': cardInFocus}"></div>
                  <div class="c-forms__message is-red is-under" *ngIf="cardValidation.error && !cardValidation.complete">{{ cardValidation.error }}</div>
                </div>
              </div>
              <div class="c-forms__message is-red" id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
              <div class="c-forms__group" *ngIf="!cardValidation.error && cardValidation.complete && !isAnonymous && !!(profileState | async).stripeEntityId">
                <label role="checkbox" class="c-checkbox">
                    <input type="checkbox" name="saveCard" [checked]="saveNewCard" (change)="saveNewCard = !saveNewCard"/>
                    <span class="c-checkbox__icon">
                        <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
                    </span>
                    <span class="c-checkbox__caption"><b>Save this card</b></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="c-forms__group" *ngIf="payment.selected">
      <button [disabled]="isloadingSubmit"
        [ngClass]="{'is-loading': isloadingSubmit}"
        class="c-btn c-btn_full c-btn_md c-btn_dark-yellow"
        type="submit">Submit</button>
    </div>
  </form>
</app-modal>
