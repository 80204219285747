import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Coupon, Participant, QuestEvent} from '../../ticket-flow.typings';
import { Quest } from 'diemlife-commons/dist/diemlife-commons-model';
// import {Quest} from 'src/app/components/main/quest/quest.type';

export interface OrderedItem {
  skuId: string;
  quantity: number;
}
export interface BreakdownPayload {
  couponCode: string;
  orderItems: OrderedItem[];
  paymentMode: string;
}
export interface Breakdown {
  absorbFees: boolean;
  brutTotal: number;
  discount: number;
  netTotal: number;
  platformFee: number;
  stripeFee: number;
  tax: number;
}
export interface BillingInfo {
  personalInfo: {
    firstName: string;
    lastName: string;
    email: string;
  };
  address: {
    country: string;
    streetNo: string;
    streetNoAdditional: string;
    city: string;
    state: string;
    stateManual?: string;
    zip: string;
  };
  paymentMode: string;
  lastFour?: string;
  save?: boolean;
  token?: string;
}
export interface OrderTicketsPayload {
  password?: string;
  signUp?: string;
  couponCode: string | null;
  orderItems: OrderedItem[];
  billingInfo: BillingInfo;
  participants: Participant[];
  referredQuestToBack?: any;
}

export interface KeyValuePair {
  key: string;
  value?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TicketFlowService {
  private event: QuestEvent;
  private questId: number;

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) { }

  getQuestEvent(questId: number): Observable<QuestEvent> {
    if (this.event && this.questId === questId) {
      return Observable.create((observer) => {
        observer.next(this.event);
      });
    }
    const headers = this.reaquestHeadersService.getHeaders();
    this.questId = questId;
    return this.http.get<QuestEvent>(
      environment.target + environment.context + `/quests/${questId}/event/true`,
      { headers: headers }
    ).pipe(map((res: QuestEvent) => this.event = res));
  }

  getQuestDetail (questId: number, userId: number): Observable<Quest> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<Quest>(
      environment.target + environment.context + `/quest-page/${questId}/${userId}`,
      { headers: headers }
    );
  }
  breakdown (questId: number, payload: BreakdownPayload): Observable<Breakdown> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<Breakdown>(
      environment.target + environment.context + `/quests/${questId}/breakdown`,
      payload,
      { headers: headers }
    );
  }
  breakdownQuest (questId: number, doerId: number, payload: { amount: number, paymentMode: string }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<any>(
      environment.target + environment.context + `/quests/${questId}/breakdown-quest/${doerId}`,
      payload,
      { headers: headers }
    );
  }
  referredQuests (questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<any>(
      environment.target + environment.context + `/quests/${questId}/referred`,
      { headers: headers }
    );
  }
  getQuestRecommendations (questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<any>(
      environment.target + environment.context + `/quests/${questId}/recommendation`,
      { headers: headers }
    );
  }
  orderQuestTickets (questId: number, payload: OrderTicketsPayload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<any>(
      environment.target + environment.context + `/quests/${questId}/ordertickets`,
      payload,
      { headers: headers }
    );
  }
  orderQuestTicketsForQuest (questId: number, payload: OrderTicketsPayload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<any>(
      environment.target + environment.context + `/quests/${questId}/ordertickets-guest`,
      payload,
      { headers: headers }
    );
  }
  coupon (questId: number, couponCode?: string): Observable<Coupon> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<Coupon>(
      environment.target + environment.context + `/quests/${questId}/coupon`,
      { couponCode: couponCode },
      { headers: headers }
    );
  }
  clearEvent(): void {
    this.event = null;
  }

  prepareKeys(value: { [key: string]: string }): KeyValuePair[] {
    const keys: KeyValuePair[] = [];
    if (value) {
      Object.keys(value).forEach((key) => {
        keys.push({key: key, value: value[key]});
      });
    }
    return keys;
  }
  private static buildKey(questId: number, userId: number, key: string) {
    return `diem-life_${questId}/${userId}__${key}`;
  }
  set (questId: number, userId: number, key: string, data: any ) {
    localStorage.setItem(TicketFlowService.buildKey(questId, userId, key), JSON.stringify(data));
  }

  get (questId: number, userId: number, key: string) {
    return JSON.parse(localStorage.getItem(TicketFlowService.buildKey(questId, userId, key)));
  }

  clear (questId: number, userId: number, key: string) {
    localStorage.removeItem(TicketFlowService.buildKey(questId, userId, key));
  }
  clearAll(questId, userId) {
    ['billing-info', 'tickets-order', 'participants-info', 'tickets-discount', 'order-number'].forEach((key: string) => {
      this.clear(questId, userId, key);
    });
  }
}
