<div class="c-donate c-page">
  <div class="c-page__cover" *ngIf="questInfo && photo.jpg">
    <picture class="h-object-fit">
      <img *ngIf="!fundraisingLink || !fundraisingLink.coverImageUrl" [alt]="questInfo.quest.title" [src]="photo.jpg">
      <img *ngIf="fundraisingLink && fundraisingLink.coverImageUrl" [alt]="fundraisingLink.campaignName" [src]="fundraisingLink.coverImageUrl">
    </picture>
  </div>
  <div class="c-page__header c-container c-container_sm">
      <h1 class="c-page__title" *ngIf="questInfo">
        <a [href]="questInfo.questShareUrl">{{ fundraisingLink && fundraisingLink.campaignName ? fundraisingLink.campaignName : questInfo.quest.title }}</a>
      </h1>
      <p class="c-page__sub-title"></p>
      <nav class="c-page__steps">
          <ul class="c-page__steps__list">
                <li [routerLinkActive]="['is-active']" [ngClass]="{'is-valid': (donateState | async).steps.amount}">
                    <a routerLink="enter-amount" [ngClass]="{'is-disabled': !(donateState | async).steps.amount || ((donateState | async).steps.amount && (donateState | async).steps.confirm)}">
                        <div class="c-page__steps__icon">
                            <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
                        </div>
                        <span>Amount</span>
                    </a>
                </li>
                <li [routerLinkActive]="['is-active']" [ngClass]="{'is-valid': (donateState | async).steps.confirm}">
                    <a routerLink="confirm" [ngClass]="{'is-disabled': !(donateState | async).steps.confirm || ((donateState | async).steps.amount && (donateState | async).steps.confirm)}">
                        <div class="c-page__steps__icon">
                            <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
                        </div>
                        <span>Confirm</span>
                    </a>
                </li>
                <li [routerLinkActive]="['is-active']" [ngClass]="{'is-valid': (donateState | async).steps.confirm}">
                    <a routerLink="success" [ngClass]="{'is-disabled': !(donateState | async).steps.confirm || ((donateState | async).steps.amount && (donateState | async).steps.confirm)}">
                        <div class="c-page__steps__icon">
                            <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
                        </div>
                        <span>Success</span>
                    </a>
              </li>
          </ul>
      </nav>
  </div>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
