<div dropdown class="c-quest-detail__header__dropdown header__dropdown__left">
  <button dropdownToggle type="button" class="c-quest-detail__header__dropdown__trig btn" #shareDropdown>
    <span class="c-quest-detail__header__dropdown__trig__txt">SHARE</span>
    <span class="c-quest-detail__header__dropdown__trig__icon">
      <svg class="icon icon-dots"><use xlink:href="#icon-dots"></use></svg>
    </span>
  </button>
  <div dropdownMenu class="c-quest-detail__header__dropdown__menu">
    <ul>
      <li>
        <a href="javascript:void(0)" (click)="shareViaFacebook()">
          <svg class="icon icon-facebook"><use xlink:href="#icon-facebook"></use></svg>
          <span>Facebook</span>
        </a>
      </li>
      <li>
        <a attr.href="{{'https://twitter.com/intent/tweet?&url=' + questInfo.shareUrl}}" (click)="closeDropdown('shareDropdown')">
          <svg class="icon icon-twitter"><use xlink:href="#icon-twitter"></use></svg>
          <span>Twitter</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (appAuthGuard)="openModal('share-email-modal')">
          <svg class="icon icon-envelope_alt"><use xlink:href="#icon-envelope_alt"></use></svg>
          <span>Email</span>
        </a>
      </li>
      <!-- TODO - new feature -->
      <li class="temp-hidden">
        <a href="javascript:void(0)" (click)="closeDropdown('shareDropdown')">
          <svg class="icon icon-eye"><use xlink:href="#icon-eye"></use></svg>
          <span>Embed*</span>
        </a>
      </li>
      <!-- TODO - new feature. What should this item do? -->
      <li class="temp-hidden">
        <a href="javascript:void(0)" (click)="closeDropdown('shareDropdown')">
          <svg class="icon icon-eye"><use xlink:href="#icon-eye"></use></svg>
          <span>Send to*</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" ngxClipboard [cbContent]="questInfo.shareUrl" (cbOnSuccess)="copied($event)">
          <svg class="icon icon-link"><use xlink:href="#icon-link"></use></svg>
          <span>Copy URL</span>
        </a>
      </li>
    </ul>
  </div>
</div>

<div dropdown class="c-quest-detail__header__dropdown header__dropdown__right" #optionsDropdown>
  <button dropdownToggle type="button" class="c-quest-detail__header__dropdown__trig btn">
    <span class="c-quest-detail__header__dropdown__trig__txt">OPTIONS</span>
    <span class="c-quest-detail__header__dropdown__trig__icon">
      <svg class="icon icon-dots"><use xlink:href="#icon-dots"></use></svg>
    </span>
  </button>
  <div dropdownMenu class="c-quest-detail__header__dropdown__menu">
    <ul>
      <li *ngIf="questInfo.copyable">
        <a (appAuthGuard)="copyQuest()" href="javascript:void(0)">
          <svg class="icon icon-copy">
            <use xlink:href="#icon-copy"></use>
          </svg>
          <span>Copy</span>
        </a>
      </li>
      <li *ngIf="!questInfo.active">
        <a (appAuthGuard)="saveQuest()" *ngIf="!questInfo.saved" href="javascript:void(0)">
          <svg class="icon icon-save">
            <use xlink:href="#icon-save"></use>
          </svg>
          <span>Save</span>
        </a>
        <a *ngIf="questInfo.saved" (click)="unsaveQuest()" href="javascript:void(0)">
          <svg class="icon icon-save icon-danger">
            <use xlink:href="#icon-save"></use>
          </svg>
          <span>Unsave</span>
        </a>
      </li>
      <!-- TODO - not working. Why? -->
      <li *ngIf="showStartTrigger">
        <a [ngSwitch]="questInfo.mode" (click)="doQuest()" href="javascript:void(0)">
          <svg class="icon icon-update"><use xlink:href="#icon-update"></use></svg>
          <span *ngSwitchCase="'diyMode'">Join</span>
          <span *ngSwitchDefault>Start</span>
        </a>
      </li>
      <li *ngIf="questInfo.active && doerInfo.id === (profileState | async).id">
        <a (click)="cancelQuest()" href="javascript:void(null)">
          <svg class="icon icon-trash icon-danger">
              <use xlink:href="#icon-trash"></use>
          </svg>
          <span>Cancel</span>
        </a>
      </li>
      <li>
        <a (appAuthGuard)="followQuest()" *ngIf="!questInfo.followed" href="javascript:void(0)">
          <svg class="icon icon-double-angle">
            <use xlink:href="#icon-double-angle"></use>
          </svg>
          <span>Follow</span>
        </a>
        <a *ngIf="questInfo.followed" (click)="unFollowQuest()" href="javascript:void(0)">
          <svg class="icon icon-double-angle icon-danger">
            <use xlink:href="#icon-double-angle"></use>
          </svg>
          <span>Unfollow</span>
        </a>
      </li>
      <li *ngIf="showSupportTrigger">
        <a (click)="supportQuest()" href="javascript:void(null)">
          <svg class="icon icon-update"><use xlink:href="#icon-update"></use></svg>
          <span>Support</span>
        </a>
      </li>
    </ul>
  </div>
</div>

<app-modal id="share-email-modal" *ngIf="openedModal === 'share-email-modal'" [ngStyle]="{'display': 'block'}" (closeModal)="closeModal()">
  <h3 class="modal-title">Share email</h3>
  <app-share-email [questId]="questInfo.id"></app-share-email>
</app-modal>
