import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {DropdownDirective} from 'angular-custom-dropdown';
import {getYoutubeId, youtubeUrlValidator} from 'src/app/directives/youtube-validation/youtube-validation.directive';
import {AddMilestoneImageUrl, AddQuestLink, PatchMilestone, PatchNewMilestone} from '../store/dl-milestones.actions';
import {ModalService} from '../../modal/modal.service';
import {DlMilestonesService} from '../dl-milestones.service';
import {MilestoneVideo, QuestTask} from 'diemlife-commons/dist/diemlife-commons-model';
import {
  AddLinkPreviewToMilestonePayload,
  AddVideoToMilestonePayload,
  AddVideoUrlToMilestonePayload
} from '../dl-milestones.typings';
import * as fromMilestones from '../store/dl-milestones.reducer';
import {AppState} from 'src/app/_store/app.reducers';
import {SelectedSearchResult} from 'src/app/modules/shared-module/components/dl-search/dl-search.component';

declare var $: any;


@Component({
  selector: 'app-dl-milestone-preview',
  templateUrl: 'dl-milestone-preview.component.html'
})

export class DlMilestonePreviewComponent implements OnInit, OnDestroy {
  @ViewChild('previewDropdown', {static: true}) dropdown: DropdownDirective;
  @Input() id: number;
  @Input() index: number;
  linkForm: FormGroup;
  videoForm: FormGroup;
  openedModal: string;
  mode: 'NEW' | 'RELEASED';
  dlMilestonesState: Observable<fromMilestones.State>;
  dlMilestonesSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private dlMilestonesService: DlMilestonesService,
    private store: Store<AppState>
  ) {
    this.dlMilestonesState = this.store.select('dlMilestones');
  }

  ngOnInit() {
    this.linkForm = this.fb.group({
      url: [null, [Validators.required]]
    });
    this.videoForm = this.fb.group({
      videoUrl: [null, [Validators.required, youtubeUrlValidator]]
    });
    this.dlMilestonesSubscription = this.dlMilestonesState.subscribe((state: fromMilestones.State) => {
      // * detect witch mode should be used for this dialog
      if (state.milestones.length) {
        // tslint:disable-next-line:max-line-length
        this.mode = (state.milestones[0].questTasks.length - 1) < this.index ? 'NEW' : 'RELEASED';
      } else {
        this.mode = 'NEW';
      }
    });
  }

  ngOnDestroy() {
    if (this.dlMilestonesSubscription) {
      this.dlMilestonesSubscription.unsubscribe();
    }
  }

  openMenu() {
    $(document).trigger('click');
    setTimeout(() => {
      this.dropdown.open();
    });
  }

  close() {
    this.dropdown.close();
  }

  addLinkToMilestone() {

    const payload: AddLinkPreviewToMilestonePayload = {
      taskId: this.id,
      taskLink: this.linkForm.value.url
    };
    this.dlMilestonesService.addLinkToMilestone(payload).subscribe((newTask: QuestTask) => {
      const patchMilestonePayload = {
        index: this.index,
        value: {
          linkUrl: newTask.linkUrl,
          linkPreview: newTask.linkPreview
        } as QuestTask
      };
      if (this.mode === 'RELEASED') {
        this.store.dispatch(new PatchMilestone(patchMilestonePayload));
      } else {
        this.store.dispatch(new PatchNewMilestone({
          linkUrl: this.linkForm.value.url,
          linkPreview: newTask.linkPreview
        } as QuestTask));
      }

      this.close();
    });

  }

  addVideoToMilestone() {
    const youtubeId = getYoutubeId(this.videoForm.value.videoUrl);
    if (youtubeId) {
      const videoPayload: AddVideoUrlToMilestonePayload = {
        url: `https://www.youtube.com/embed/${youtubeId}`,
        provider: 'YOUTUBE',
        thumbnails: {
          xs: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
          sm: `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`,
          md: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
        }
      };
      const embeddedVideo: MilestoneVideo = {
        thumbnailUrl: videoPayload.thumbnails.sm,
        videoId: null,
        videoUrl: videoPayload.url
      };
      if (this.mode === 'RELEASED' && this.id) {
        // * milestone and quest is already created
        this.store.select('dlMilestones').pipe(take(1)).subscribe((state: fromMilestones.State) => {
          const milestone: AddVideoToMilestonePayload = {
            id: state.milestones[0].questTasks[this.index].id,
            task: state.milestones[0].questTasks[this.index].task,
            video: videoPayload
          };
          this.dlMilestonesService.addVideoToMilestone(milestone).subscribe(() => {
            const patchMilestonePayload = {
              index: this.index,
              value: {
                video: videoPayload,
                embeddedVideo: embeddedVideo
              } as QuestTask
            };
            this.store.dispatch(new PatchMilestone(patchMilestonePayload));
            this.close();
          });
        });
      } else if (this.mode === 'RELEASED' && !this.id) {
        // * new quest with already created milestone
        const patchMilestonePayload = {
          index: this.index,
          value: {
            video: videoPayload,
            embeddedVideo: embeddedVideo
          } as QuestTask
        };
        this.store.dispatch(new PatchMilestone(patchMilestonePayload));
        this.close();
      } else {
        // * new quest with not created milestone
        this.store.dispatch(new PatchNewMilestone({
          video: videoPayload,
          embeddedVideo: embeddedVideo
        } as QuestTask));
        this.close();
      }
    }
  }

  onLinkedQuestSelected(searchResult: SelectedSearchResult) {
    this.closeDialog('link-quest-dialog');
    if (this.mode === 'RELEASED' && this.id) {
      this.store.dispatch(new AddQuestLink(this.index, this.id, searchResult.data.questId));
    } else if (this.mode === 'RELEASED' && !this.id) {
      this.store.dispatch(new PatchMilestone({
        index: this.index, value: {linkedQuestId: searchResult.data.questId} as QuestTask
      }));
    } else {
      this.store.dispatch(new PatchNewMilestone({
        linkedQuestId: searchResult.data.questId,
        linkedQuest: {
          id: searchResult.data.questId,
          title: searchResult.data.questName,
          photo: searchResult.data.questImageURL,
          questFeed: searchResult.data.description,
          shortDescription: searchResult.data.description,
          pillar: searchResult.data.pillar,
          seoSlugs: {
            shortUrl: searchResult.data.path
          },
          user: {
            id: searchResult.data.userId,
            profilePictureURL: searchResult.data.profilePictureURL
          }
        }
      } as QuestTask));
    }
  }

  addPhotoToMilestone(imageUrl: string) {
    if (this.mode === 'RELEASED') {
      this.store.dispatch(new AddMilestoneImageUrl({index: this.index, value: imageUrl}));
    } else {
      this.store.dispatch(new PatchNewMilestone({
        imageUrl: imageUrl
      } as QuestTask));
    }
    this.closeDialog('cropper-milestone-dialog');
    this.close();
  }

  openImageDialog() {
    this.openDialog('cropper-milestone-dialog');
  }

  openLinkQuestDialog() {
    this.openDialog('link-quest-dialog');
  }

  private openDialog(dialogId: string) {
    this.openedModal = dialogId;
    setTimeout(() => {
      this.modalService.open(dialogId);
    });
  }

  private closeDialog(dialogId: string) {
    this.modalService.close(dialogId);
    setTimeout(() => {
      this.openedModal = dialogId;
    });
  }
}
