import { Injectable } from '@angular/core';
import { MenuItem } from './menu.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public isOpened = false;
  public items: MenuItem[] = [
    {
      title: 'EXPLORE',
      url: '/explore',
      icon: 'icon-featured',
      requireAuth: false
    },
    {
      title: 'MY QUESTS',
      url: '/my-quests',
      icon: 'icon-Quest',
      requireAuth: true
    },
    {
      title: 'FRIENDS',
      url: '/friends',
      icon: 'icon-oval-friends',
      requireAuth: true
    },
    {
      title: 'PROFILE',
      url: '/profile',
      icon: 'icon-dl-profile',
      requireAuth: true
    },
    {
      title: 'ACCOUNT',
      url: '/account',
      icon: 'icon-dl-account',
      requireAuth: true
    },
    // {
    //   title: 'ALERTS',
    //   url: '/notifications',
    //   icon: 'icon-alerts',
    //   requireAuth: true
    // }
  ];

  getItems() {
    return this.items;
  }

  open () {
    this.isOpened = true;
  }

  close () {
    this.isOpened = false;
  }

  constructor() { }

}
