import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from 'src/app/components/pages/profile/profile.service';
import { AccountService } from 'src/app/components/pages/account/account.service';
import { QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';
// import { QuestUserInfo } from '../../quest/quest.type';


@Component({
  selector: 'app-profile-interests',
  templateUrl: './profile-interests.component.html',
  styleUrls: ['./profile-interests.component.styl']
})
export class ProfileInterestsComponent implements OnInit {
  @Input() profileInfoId: number;
  @Input() mode: 'public' | 'private';
  userInfo: QuestUserInfo;
  interestsUntouched = true;
  userFavorites: any;
  isEditActive = false;
  isLoading: string;
  favoritesLoaded = false;
  userFavoritesIsEmpty = false;
  interests = {
    physical: {
      isActive: false,
      icon: 'physical',
      connection: 'favPhysical'
    },
    mental: {
      isActive: false,
      icon: 'mental',
      connection: 'favIntel'
    },
    social: {
      isActive: false,
      icon: 'friends',
      connection: 'favSocial'
    },
    environmental: {
      isActive: false,
      icon: 'environmental',
      connection: 'favEnv'
    },
    occupational: {
      isActive: false,
      icon: 'occupational',
      connection: 'favOcc'
    },
    financial: {
      isActive: false,
      icon: 'piggybank',
      connection: 'favFin'
    }
  };

  constructor(
    private profileService: ProfileService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    if (this.mode === 'private') {
      this.accountService.getUserInfo().subscribe((userInfo: QuestUserInfo) => {
        this.userInfo = userInfo;
        this.loadUserFavorites();
      });
    } else {
      this.accountService.getUserInfoById(this.profileInfoId).subscribe((userInfo: QuestUserInfo) => {
        this.userInfo = userInfo;
        this.loadUserFavorites();
      });
    }
  }
  updateInterest(name: string) {
    if (!this.isEditActive) { return false; }
    this.interestsUntouched = false;
    this.isLoading = name;
    this.interests[name].isActive = !this.interests[name].isActive;

    const payload = this.buildPayload();
    this.accountService.editProfile(payload).subscribe(res => {
      this.updateListStatus();
      this.isLoading = null;
    }, err => {
      this.interests[name].isActive = !this.interests[name].isActive;
      this.updateListStatus();
      this.isLoading = null;
    });
  }
  private loadUserFavorites() {
    this.profileService.getUserFavoritesForUser(this.userInfo.id)
    .subscribe((userFavorites: any) => {
      this.userFavorites = userFavorites ? userFavorites : [];
      if (userFavorites) {
        userFavorites.forEach(item => {
          if (this.interests[item.favorite.toLowerCase()]) {
            this.interests[item.favorite.toLowerCase()].isActive = true;
          }
        });
      }
      this.updateListStatus();
      this.favoritesLoaded = true;
    });
  }
  private updateListStatus() {
    let isEmpty = true;
    Object.keys(this.interests).forEach((key: string) => {
      if (this.interests[key].isActive) {
        isEmpty = false;
      }
    });
    this.userFavoritesIsEmpty = isEmpty;
  }

  private buildInterests() {
    const obj: any = {};
    Object.keys(this.interests).forEach((key: string) => {
      obj[this.interests[key].connection] = this.interests[key].isActive;
    });
    return obj;
  }
  private buildPayload() {
    return {
      ...{
        firstName: this.userInfo.firstName,
        lastName: this.userInfo.lastName,
        username: null,
        email: this.userInfo.email,
        zip: this.userInfo.zip,
        newpassword: null,
        receiveEmail: this.userInfo.receiveEmail,
      },
      ...this.buildInterests(),
      favSpirit: true
    };
  }
  canCurrentUserEdit() {
    return true;
  }
}
