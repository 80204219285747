import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {SnotifyService} from 'ng-snotify';
import * as fromPayments from '../../main/payments/store/payments.reducer';
import {BankAccount} from '../../main/payments/store/payments.reducer';
import {AccountService} from './account.service';
import * as PaymentsActions from '../../main/payments/store/payments.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SnotifyConfigService} from '../../../_services/snotify-config.service';
import * as Constants from '../../../app.constants';
import * as AppConfig from '../../../app.config';
import {AuthService} from '../../main/auth/auth.service';
// import {QuestUserInfo} from '../../main/quest/quest.type';
import {ReferenceDataService} from '../../../_services/reference-data.service';
import {Meta, Title} from '@angular/platform-browser';
import { QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';
import { AppState } from 'src/app/_store/app.reducers';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.styl']
})
export class AccountComponent implements OnInit, OnDestroy {
  messages = Constants.VALIDATION_MESSAGES;
  paymentsState: Observable<fromPayments.State>;
  userInfo: QuestUserInfo;
  pageIsLoaded = false;
  personalInfoRequestIsLoading = false;
  personalInfoForm: FormGroup;
  personalInfoFormSubscription: Subscription;
  formSubmitted = false;
  country: string = null;
  stripeLogo = '../../../../assets/static/powered_by_stripe.png';
  metaImage = '../../../../assets/images/logo-black-white.png';

  constructor(
    private accountService: AccountService,
    private store: Store<AppState>,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private referenceDataService: ReferenceDataService,
    private authService: AuthService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.paymentsState = this.store.select('paymentsInfo');
  }

  ngOnInit() {

    this.personalInfoForm = new FormGroup({
      'firstName': new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      'lastName': new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      'username': new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'zip': new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/), Validators.minLength(2)]),
      'newpassword': new FormControl(null, [
        Validators.minLength(AppConfig.password.minLength),
        Validators.maxLength(AppConfig.password.maxLength)
      ]),
      'receiveEmail': new FormControl(null),
    });

    // tslint:disable-next-line:max-line-length
    this.accountService.getAccountInfo().subscribe(([userInfo, countriesConfig]: [QuestUserInfo, any]): void => {
      const hasStripeEntity = Boolean(userInfo.stripeEntityId);
      let paymentsSupported = false;
      let billingEnabled = false;
      let payoutsSupported = false;
      if (userInfo.country) {
        paymentsSupported = countriesConfig.paymentsSupported.indexOf(userInfo.country) >= 0;
        billingEnabled = hasStripeEntity && paymentsSupported;
        payoutsSupported = hasStripeEntity && countriesConfig.payoutsSupported.indexOf(userInfo.country) >= 0;
      }
      this.userInfo = {
        ...userInfo,
        hasStripeEntity: hasStripeEntity,
        billingEnabled: billingEnabled,
        paymentsSupported: paymentsSupported,
        payoutsSupported: payoutsSupported
      };
      this.personalInfoForm.setValue({
        'firstName': this.userInfo.firstName,
        'lastName': this.userInfo.lastName,
        'email': this.userInfo.email,
        'username': this.userInfo.userName,
        'zip': this.userInfo.zip,
        'newpassword': null,
        'receiveEmail': this.userInfo.receiveEmail !== 'false'
      });
      // tslint:disable-next-line:no-shadowed-variable

      this.accountService.getCreditCards().subscribe((creditCardsInfo: any) => {
        this.accountService.getBankAccounts().subscribe(([bankInfoPayments, bankInfoPayouts]: BankAccount[][]) => {
          this.store.dispatch(new PaymentsActions.SetPaymentsInfo({
            bankAccounts: bankInfoPayments,
            creditCards: creditCardsInfo,
            hasBankAccountForPayments: !!bankInfoPayments.length,
            hasBankAccountForPayouts: !!bankInfoPayouts.length
          }));
        }, err => {
          console.error(err);
          this.store.dispatch(new PaymentsActions.SetPaymentsInfo({
            bankAccounts: [],
            creditCards: creditCardsInfo,
            hasBankAccountForPayments: false,
            hasBankAccountForPayouts: false
          }));
        });
      });

      this.referenceDataService.getSupportedCountries().subscribe((res: { [key: string]: string }) => {
        this.country = res[this.userInfo.country];
      });

      this.pageIsLoaded = true;
    }, err => {
      console.log(err);
    });
    this.personalInfoFormSubscription = this.personalInfoForm.valueChanges.subscribe((res) => {
      this.userInfo.firstName = res.firstName;
      this.userInfo.lastName = res.lastName;
    });
    this.setMetaTags();
  }

  ngOnDestroy() {
    if (this.personalInfoFormSubscription) {
      this.personalInfoFormSubscription.unsubscribe();
    }
  }

  checkIfLogoutTouched() {
    const touched = this.personalInfoForm.get('email').touched || this.personalInfoForm.get('newpassword').touched;
    if (this.personalInfoForm.get('email').touched || this.personalInfoForm.get('newpassword').touched) {
      this.authService.logout();
    }
    return touched;
  }

  getErrorStatus(err) {
    this.personalInfoRequestIsLoading = false;
    if (err.status === 403) {
      if (err.error === 'Username is already in use') {
        // tslint:disable-next-line:max-line-length
        this.snotifyService.error('There was an error updating your information. The username is already in use!', null, this.snotifyConfigService.getConfig());
      } else {
        // tslint:disable-next-line:max-line-length
        this.snotifyService.error('There was an error updating your information. The email is already in use!', null, this.snotifyConfigService.getConfig());
      }
      return false;
    }
    // tslint:disable-next-line:max-line-length
    this.snotifyService.error('There was an error updating your information. Please try again.', null, this.snotifyConfigService.getConfig());
  }

  onSubmitPersonalInfo() {
    this.formSubmitted = true;
    if (this.personalInfoForm.untouched) {
      this.snotifyService.warning(
        'You didn`t touch form, please change any information for submitting!',
        null,
        this.snotifyConfigService.getConfig()
      );
      return false;
    }
    if (this.personalInfoForm.valid) {
      this.personalInfoRequestIsLoading = true;
      const formValues = this.personalInfoForm.value;
      const newPassword = formValues.newpassword;
      const payload = {
        ...formValues,
        favSpirit: true,
      };
      // fix for error from backend, when trying to upload the same name
      if (this.personalInfoForm.value.username === this.userInfo.userName) {
        payload.username = null;
      }
      if (newPassword) {
        forkJoin([this.accountService.editProfile(payload), this.accountService.editPassword(newPassword)]).subscribe(() => {
          if (!this.checkIfLogoutTouched()) {
            this.snotifyService.success('Successfully updated your information!', null, this.snotifyConfigService.getConfig());
            this.personalInfoForm.patchValue({
              newpassword: null
            });
          } else {
            this.snotifyService.success(
              'Successfully updated your information. Please log back in!',
              null,
              this.snotifyConfigService.getConfig()
            );
          }
          this.personalInfoRequestIsLoading = false;
        }, err => this.getErrorStatus(err));
      } else {
        this.accountService.editProfile(payload).subscribe(() => {
          if (!this.checkIfLogoutTouched()) {
            this.snotifyService.success('Successfully updated your information!', null, this.snotifyConfigService.getConfig());
            this.personalInfoRequestIsLoading = false;
          } else {
            this.snotifyService.success(
              'Successfully updated your information. Please log back in!',
              null,
              this.snotifyConfigService.getConfig()
            );
          }
        }, err => this.getErrorStatus(err));
      }
    }
  }

  setMetaTags() {
    this.titleService.setTitle('DIEMlife | Account');
    this.meta.addTag({name: 'og:title', content: 'DIEMlife | Account'});
    this.meta.addTag({name: 'og:image', content: this.metaImage});
    this.meta.addTag({name: 'og:description', content: 'View and edit information about your DIEMlife account'});
  }

}
