import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {LngLatBounds, Map, Marker, MarkerOptions, NavigationControl, Popup, PopupOptions} from 'mapbox-gl';
import {
  DirectionsType,
  MapBounds,
  MapboxService,
  MapData,
  MapLocation,
  MapView,
  MapZoom,
  QuestTask
} from 'diemlife-commons/dist/diemlife-commons-model';
import {ModalService} from '../modal/modal.service';


@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.styl']
})
export class ActivityFeedComponent implements OnInit {
  activeTab: number = 0;
  private map: Map;
  imgUrl: string = `https://filmdaily.co/wp-content/uploads/2020/04/cute-cat-videos-lede-1300x882.jpg`;
  emptyFeed = "There doesn't seem to be anything in your feed right now. Check back later!";
  publicActivityCards: ActivityCard[] = [
    {
      "hasAnnouncement": false,
      "hashtags": ["modular", "angular", "webdesign"],
      "imgUrl" : this.imgUrl,
      "postText" : "This is a text post. A post full of text. That is how the text post do. Text post.",
      "cheers" : 0
    }, 
    {
      "hasAnnouncement": true,
      "announcementText" : ["Starting the Quest", "Tuesday Trampolines!"],
      "cheers" : 1
    }, 
    {
      "hasAnnouncement": true,
      "announcementText" : ["cheered for modularity!", "Andrew Ting"],
      "postText" : "This is post number 2, demonstrating the modular capabilities of my activity-card.component. Amazing, isn't it?",
      "cheers" : 1337
    }
  ];
  teamActivityCards: ActivityCard[] = [
    {
      "hasAnnouncement": true,
      "announcementText": ["cheered for getting stuff done", "Andrew and the dev team"],
      "hashtags": ["productivity", "hardworker", "thatsmebby"],
      "imgUrl" : this.imgUrl,
      "postText" : "The activity feed and cards are becoming functional.",
      "cheers" : 100
    },
    {
      "hasAnnouncement": true,
      "announcementText" : ["Starting the Quest", "Front End Web Development!"],
      "cheers" : 26
    }, 
    {
      "hasAnnouncement": true,
      "announcementText" : ["cheered for fun!", "Andrew Ting"],
      "postText" : "Yipee ya yay",
      "cheers" : 1
    }
  ];
  personalActivityCards: ActivityCard[] = [];
  visibleActivityCards: ActivityCard[];

  constructor(
    private modalService: ModalService
    ) { }

  ngOnInit() {
    this.map = new Map({
      accessToken: environment.mapBoxKey,
      antialias: true,
      container: 'activity-map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 10,
      center: [-74.0060, 40.7128],
    });
    this.map.addControl(new NavigationControl({showCompass: false}));
    this.map.on('load', () => { 
      console.log('Map loaded');
      this.map.resize();
    });
    this.onTabClick(0);
  }

  onTabClick(num) {
    this.activeTab = num;
    if (num == 0) {
      this.visibleActivityCards = [...this.publicActivityCards];
    } else if (num == 1) {
      this.visibleActivityCards = [...this.teamActivityCards];
    } else if (num == 2) {
      this.visibleActivityCards = [...this.personalActivityCards];
    }
  }

  max(i, j) {
    return Math.max(i, j);
  }

  min(i, j) {
    return Math.min(i, j);
  }

  getPostButtonMarginLeft(postBut: HTMLAnchorElement) {
    const test = postBut.style.marginLeft;
    return test;
  }

  addPersonalActivityCard(newPost: ActivityCard) {
    this.personalActivityCards = [...this.personalActivityCards, newPost];
    this.onTabClick(this.activeTab);
  }

  openModal(id: string, mode: string) {
    setTimeout(() => {
      this.modalService.open(id, {formMode: mode});
    });
  }
}

export interface ActivityCard {
  questId?: number;
  userId?: number;
  imgUrl?: string;
  hasAnnouncement: boolean;
  announcementText?: string[]; //array containing 2 strings. The second string will be bold
  postText?;
  hashtags?: string[];
  cheers: number;
}