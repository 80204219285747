import { Injectable } from '@angular/core';
import { MainDlNavStructure } from './main-dl-nav.types';
import { BehaviorSubject, Observable } from 'rxjs';
import { TRY_LOGOUT } from '../auth/store/auth.actions';

export const MAIN_NAV_ACTIONS = {
  OPEN_MODAL_AUTH: 'OPEN_MODAL_AUTH',
  OPEN_MODAL_NOTIFICATIONS: 'OPEN_MODAL_NOTIFICATIONS',
  OPEN_MODAL_CONTACT: 'OPEN_MODAL_CONTACT'
};

@Injectable({
  providedIn: 'root',
})
export class MainDlNavService {
  // tslint:disable-next-line: variable-name
  private readonly _mainDlNav = new BehaviorSubject<boolean>(false);
  public readonly structure: MainDlNavStructure = {
    body: [
      {
        title: 'Notifications',
        action: MAIN_NAV_ACTIONS.OPEN_MODAL_NOTIFICATIONS,
        requireAuth: true
      },
      {
        title: 'Explore',
        url: '/explore',
        requireAuth: null
      },
      {
        title: 'Profile',
        url: '/profile',
        requireAuth: true,
        isMobileView: true
      },
      {
        title: 'My Quests',
        url: '/my-quests',
        requireAuth: true
      },
      {
        title: 'Friends',
        url: '/friends',
        requireAuth: true
      },
      {
        title: 'Account',
        url: '/account',
        requireAuth: true
      },
      {
        title: 'Log Out',
        action: TRY_LOGOUT,
        requireAuth: true
      },
      {
        title: 'Sign In',
        action: MAIN_NAV_ACTIONS.OPEN_MODAL_AUTH,
        requireAuth: false
      }
    ],
    footer: [
      {
        title: 'Help',
        url: '/help',
        requireAuth: null
      },
      {
        title: 'Contact Us',
        action: MAIN_NAV_ACTIONS.OPEN_MODAL_CONTACT,
        requireAuth: null
      },
      {
        title: 'Legal',
        url: '/legal',
        requireAuth: null
      }
    ]
  };

  getStructure(): MainDlNavStructure {
    return this.structure;
  }
  public state(): Observable<boolean> {
    return this._mainDlNav.asObservable();
  }

  constructor() { }

}
