import {Component, Input} from '@angular/core';
import {QuestTask} from 'diemlife-commons/dist/diemlife-commons-model';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import {QuestService} from 'src/app/_services/quest.service';
import {of, Subject, Subscription} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {
  RemoveMilestone,
  SetMilestoneForPreview,
  UpdateMilestoneTask,
  UpdateMilestoneTaskCompletion
} from '../store/dl-milestones.actions';
import {DlMilestoneManageModes} from '../dl-milestones.typings';
import {DlMilestonesService} from '../dl-milestones.service';
import {ModalService} from '../../modal/modal.service';
import {AppState} from 'src/app/_store/app.reducers';
import {MILESTONE_TASK_MAX_LENGTH} from 'src/app/app.constants';
import {QuestPreparedPhoto} from '../../quest/quest.type';

@Component({
  selector: 'app-dl-milestone',
  templateUrl: './dl-milestone.component.html',
  styleUrls: ['./dl-milestone.component.styl']
})
export class DlMilestoneComponent {
  readonly MAX_LENGTH = MILESTONE_TASK_MAX_LENGTH;
  @Input() milestone: QuestTask;
  @Input() allowCheck: boolean;
  @Input() index: number;
  @Input() isEditMode: boolean;
  @Input() questId: number;
  @Input() mode: DlMilestoneManageModes;
  @Input() canBeEdit: boolean;
  @Input() showControls: boolean;
  isInvalid = false;
  isLoading: string;
  subject: Subject<string>;
  subjectSubscription: Subscription;

  constructor(
    private questService: QuestService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private store: Store<AppState>,
    private dlMilestonesService: DlMilestonesService,
    private modalService: ModalService,
  ) {
  }
  onCheckChange() {
    if (!this.allowCheck || this.isEditMode || this.milestone.isLoading) {
      return false;
    }

    this.milestone.isLoading = true;
    if (this.milestone.linkedQuest && this.milestone.linkedQuestId) {
      this.confirmCheckChangeMilestone(
        this.milestone.isTaskCompleted
          ? `This will restart ${this.milestone.linkedQuest.title}, do you want to proceed?`
          : `This will complete ${this.milestone.linkedQuest.title}, do you want to proceed?`
      );
    } else {
      this.doCheckChangeMilestone();
    }
  }

  doCheckChangeMilestone() {
    this.questService.updateQuestTask(this.milestone.id).subscribe((success) => {
      if (success) {
        this.store.dispatch(new UpdateMilestoneTaskCompletion({ index: this.index, value: !this.milestone.isTaskCompleted }));
      } else {
        this.snotifyService.warning('Cannot check this milestone', null, this.snotifyConfigService.getConfig());
      }
      this.milestone.isLoading = false;
    }, () => {
      this.milestone.isLoading = false;
      this.snotifyService.error(
        'There was a problem updating task information. Please try again!',
        null,
        this.snotifyConfigService.getConfig()
      );
    });
  }

  confirmCheckChangeMilestone(message: string) {
    this.snotifyService.confirm(
      message,
      null,
      this.snotifyConfigService.getConfig({
        closeOnClick: false,
        timeout: 0,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              this.doCheckChangeMilestone();
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'No',
            bold: false,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
              this.milestone.isLoading = false;
            }
          }
        ]
      })
    );
  }

  saveNewTask(event: any) {
    if (this.subject) {
      this.subject.next(event.target.value);
    }
  }

  onFocusIn() {
    this.subject = new Subject<string>();
    this.subjectSubscription = this.subject.pipe(
      switchMap((value: string) => {
        this.isLoading = 'UPDATE_MILESTONE';
        return of(value);
      }),
      debounceTime(1000)
    ).subscribe((value: string) => {
      if (value.length > 0) {
        this.isInvalid = false;
        if (this.mode === DlMilestoneManageModes.NEW_QUEST) {
          this.store.dispatch(new UpdateMilestoneTask({index: this.index, task: value}));
          this.isLoading = null;
        } else {
          const payload = {
            id: this.milestone.id,
            task: value,
            video: this.milestone.video
          };
          this.questService.editMilestone(payload).subscribe(() => {
            this.store.dispatch(new UpdateMilestoneTask({index: this.index, task: value}));
            this.isLoading = null;
          }, () => {
            this.isLoading = null;
            this.snotifyService.error(
              'There was a problem updating task information. Please try again!',
              null,
              this.snotifyConfigService.getConfig()
            );
          });
        }
      } else {
        this.isLoading = null;
        this.isInvalid = true;
      }
    });
  }

  onFocusOut() {
    this.subject.complete();
    this.subjectSubscription.unsubscribe();
    setTimeout(() => {
      this.subject = null;
    });
  }

  remove() {
    if (!this.isEditMode) { return false; }

    this.snotifyService.confirm(
      `Do you wish to remove this milestone?`,
      null,
      this.snotifyConfigService.getConfig({
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: 'Yes',
            bold: true,
            action: (toast) => {
              if (!this.questId) {
                this.store.dispatch(new RemoveMilestone(this.index));
              } else {
                const payload = {
                  id: this.milestone.id
                };
                this.questService.removeMilestone(payload).subscribe(() => {
                  this.store.dispatch(new RemoveMilestone(this.index));
                });
              }
              this.snotifyService.remove(toast.id);
            }
          },
          {
            text: 'Cancel',
            bold: true,
            action: (toast) => {
              this.snotifyService.remove(toast.id);
            }
          }
        ]
      })
    );
  }
  openDialog() {
    this.store.dispatch(new SetMilestoneForPreview({index: this.index, isEditMode: this.isEditMode}));
    this.modalService.open('milestone-details-dialog');
  }
  get maxLengthCount(): number {
    return MILESTONE_TASK_MAX_LENGTH - this.milestone.task.length;
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

}
