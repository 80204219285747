<button
  class="c-dl-main-nav__close h-btn-reset"
  title="Close modal"
  aria-label="Close modal"
  value="Close modal"
  (click)="closeModal()"
  type="button">
  <svg class="icon icon-cancel" aria-hidden="true"><use xlink:href="#icon-cancel"></use></svg>
</button>
<div class="c-dl-main-nav__header" [ngClass]="{'is-active': searchIsOpened}">
    <div class="c-dl-main-nav__container">
        <!-- ? use class "has-drop-absolute" for absolute dropdown implementation -->
        <app-dl-search
            class="c-dl-search"
            *ngIf="authenticated"
            (opened)="onSearchIsOpened($event)"
            [searchFor]="'All'"
            [isOpened]="searchIsOpened"
            (resultSelected)="navigateFromSearch($event)">
        </app-dl-search>
    </div>
</div>
<div class="c-dl-main-nav__body" role="menubar" *ngIf="!searchIsOpened">
    <div class="c-dl-main-nav__container">
        <div class="c-dl-main-nav__row">
            <nav class="c-dl-main-nav__nav-super">
                <ul class="h-list-reset">

                    <ng-template #mainNavItem let-item="item">
                        <a *ngIf="item.url" [routerLink]="item.url" (click)="closeModal()" role="menuitem">
                            <span>{{ item.title }}</span>
                            <span *ngIf="item.title === 'Notifications' && notificationsCount > 0" class="e-oval">{{ notificationsCount }}</span>
                        </a>
                        <a *ngIf="item.action" href="javascript:void(0)" (click)="doAction(item.action)" role="menuitem">
                            <span>{{ item.title }}</span>
                            <span *ngIf="item.title === 'Notifications' && notificationsCount > 0" class="e-oval">{{ notificationsCount }}</span>
                        </a>
                    </ng-template>

                    <li *ngFor="let item of structure.body" [ngClass]="{'c-dl-main-nav__sm-d-none': item.isMobileView}">
                        <!-- * check if user is authorized -->
                        <ng-container *ngIf="(item.requireAuth === true ? authenticated : false)">
                            <ng-template [ngTemplateOutlet]="mainNavItem" [ngTemplateOutletContext]="{ item: item }"></ng-template>
                        </ng-container>
                        <!-- * check if user is not authorized -->
                        <ng-container *ngIf="(item.requireAuth === false ? !authenticated : false)">
                            <ng-template [ngTemplateOutlet]="mainNavItem" [ngTemplateOutletContext]="{ item: item }"></ng-template>
                        </ng-container>
                        <!-- * default for all -->
                        <ng-container *ngIf="item.requireAuth === null">
                            <ng-template [ngTemplateOutlet]="mainNavItem" [ngTemplateOutletContext]="{ item: item }"></ng-template>
                        </ng-container>
                    </li>
                </ul>
            </nav>
            <div *ngIf="authenticated && userInfo && isScreenLarge" class="c-dl-main-nav__profile c-dl-profile-thumb">
                <app-profile-avatar
                  class="c-dl-profile-thumb__avatar c-avatar"
                  [avatar]="userInfo.profilePictureURL"
                  [firstName]="userInfo.firstName"
                  [isBrand]="userInfo.isUserBrand === 'Y'"
                  [lastName]="userInfo.lastName"
                  [userName]="userInfo.firstName + ' ' + userInfo.lastName">
                </app-profile-avatar>
                <span class="c-dl-profile-thumb__title">{{ userInfo.firstName + ' ' + userInfo.lastName }}</span>
                <a class="c-dl-profile-thumb__tool" [routerLink]="['/profile']" (click)="closeModal()">View/Edit Profile</a>
            </div>
        </div>
    </div>
</div>
<div class="c-dl-main-nav__footer">
    <div class="c-dl-main-nav__container">
        <nav class="c-dl-main-nav__res-super">
            <ul class="h-list-reset">
                <li *ngFor="let item of structure.footer">
                    <ng-container *ngIf="(item.requireAuth ? authenticated : false || !item.requireAuth)">
                        <a *ngIf="item.url" [routerLink]="item.url" (click)="closeModal()" role="menuitem">
                            <span>{{ item.title }}</span>
                        </a>
                        <a *ngIf="item.action" (click)="doAction(item.action)" role="menuitem">
                            <span>{{ item.title }}</span>
                        </a>
                    </ng-container>
                </li>
            </ul>
        </nav>
    </div>
</div>
