<app-leaderboard-podium *ngIf="isLoaded && members"
                        [members]="members"
                        [allowEdit]="allowEdit"
                        [questId]="quest.id"
                        [selectedAttributeId]="selectedAttributeId"
                        (updateMember)="onUpdateMember($event)"
                        [teams]="teams"></app-leaderboard-podium>
<app-leaderboard-attribute-choice class="c-leaderboard-attributes"
                                  [attributes]="quest.leaderboardAttributes"
                                  (selectedIdChanged)="onSelectedIdChanged($event)"></app-leaderboard-attribute-choice>
<app-leaderboard-list class="c-leaderboard-list"
                      *ngIf="members?.length > 3 && isLoaded"
                      (updateMember)="onUpdateMember($event)"
                      [allowEdit]="allowEdit"
                      [questId]="quest.id"
                      [selectedAttributeId]="selectedAttributeId"
                      [members]="members.slice(3)"
                      [teams]="teams"></app-leaderboard-list>

<button class="c-btn c-btn_sm c-btn-full c-btn_dark ng-star-inserted"
        *ngIf="allowEdit"
        [disabled]="updateProgress"
        (click)="updateFromDoers()"><span>Update members</span></button>
