<div class="c-dl-ns-post__header">
  <a class="c-dl-ns-post__profile" href="javascript:void(0)" (click)="onNavigate({routerLink:['/profile', comment.userName], type: 'LinkInside'})">
    <app-profile-avatar
      class="c-avatar"
      [avatar]="comment.userAvatarUrl"
      [firstName]="comment.userFirstName"
      [isBrand]="false"
      [lastName]="comment.userLastName"
      [userName]="comment.userName">
    </app-profile-avatar>
    <span>{{ comment.userFirstName }}&nbsp;{{ comment.userLastName }}</span>
  </a>
  <div class="c-dl-ns-post__tools" *ngIf="comment.userId === viewerId">
    <button class="e-dl-tool" [ngClass]="{'is-active': comment.editable}" [ngbTooltip]="comment.editable ? 'Done' : 'Edit'" tooltipClass="c-dl-tooltip" type="button" (click)="toggleEditComment()">
      <svg *ngIf="!comment.editable" class="icon icon-pencil"><use xlink:href="#icon-pencil"></use></svg>
      <svg *ngIf="comment.editable" class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
    </button>
    <button class="e-dl-tool h-xs-loader" [ngClass]="{'is-loading': isLoading === 'REMOVING'}" ngbTooltip="Remove" tooltipClass="c-dl-tooltip" (click)="removeComment()" type="button">
      <svg class="icon icon-trash h-xs-loader__caption"><use xlink:href="#icon-trash"></use></svg>
      <i class="h-xs-loader__control"></i>
    </button>
  </div>
</div>

<div class="c-dl-ns-post__body" *ngIf="!comment.editable && comment.comment">
  <div class="c-dl-ns-post__preview" *ngIf="comment.image">
    <div class="c-dl-ns-post__preview__inner h-object-fit">
      <img [src]="comment.image.questImageUrl" alt="">
    </div>
  </div>
  <div class="c-dl-ns-post__caption" [ngClass]="{'has-image': comment.image}">
    <div class="h-typography no-pad-top"
      [appMention]="comment.mentions"
      [webviewMode]="webviewMode"
      (navigateToEmit)="onNavigate($event)"
      [innerHTML]="prepareCommentCRLF(comment.formattedComment) || 'empty' | urlify | safe:'html'">
    </div>
    <div class="c-dl-ns-post__date">Shared {{ sharedDate }}</div>
  </div>
</div>
<!-- TODO: implement newsfeed-ctrl here with *ngIf="comment.editable" -->
<app-newsfeed-ctrl
  *ngIf="comment.editable"
  class="c-dl-newsfeed-ctrl is-edit-mode"
  [questId]="questId"
  [viewerId]="viewerId"
  [comment]="comment">
</app-newsfeed-ctrl>

<div class="c-dl-ns-post__footer">
  <button class="c-dl-ns-post__tool h-btn-reset" (click)="toggleLikeComment()" type="button">
    <div class="c-dl-ns-post__tool__inner h-xs-loader is-currentColor" [ngClass]="{'is-loading': isLoading === 'LIKE'}">
      <span class="c-dl-ns-post__like h-xs-loader__caption">
        <span *ngIf="!hasCurrentUserLiked" class="icon icon-heart">🙌</span>
        <span *ngIf="hasCurrentUserLiked" class="icon icon-heart-filled">🙌</span>
      </span>
      <!-- <span class="h-xs-loader__control"></span> -->
    </div>
    <span class="c-dl-ns-post__counter">{{comment.likes?.length || "Cheer!"}}</span>
    <span *ngIf="comment.likes?.length" class="c-dl-ns-post__unit">{{comment.likes?.length === 1 ? 'person cheered' : 'people cheered'}} </span>
  </button>
  <button class="c-dl-ns-post__tool h-btn-reset" *ngIf="!reply" (click)="toggleReplyComment(false)" type="button">
    <svg class="icon"><use xlink:href="#icon-dl-comment"></use></svg>
    <span class="c-dl-ns-post__counter">{{ commentsCount }}</span>
    <span class="c-dl-ns-post__unit">{{ commentsCount === 1 ? 'comment' : 'comments'}} </span>
  </button>
</div>
<div class="c-dl-ns-post__bottom" [ngClass]="{'is-collapsed': isReplyFormOpened}">
  <div class="c-dl-ns-post__bottom__inner">
    <button class="c-dl-ns-post__reply h-btn-reset" (click)="toggleReplyComment(true)" type="button">
      <span>Comment on {{ comment.userFirstName }}’s Post</span>
    </button>
  </div>
</div>

<div class="c-dl-ns-post__feedback" #feedbackEl *ngIf="isReplyFormOpened" @animateItems>
  <div class="c-dl-ns-feedback">
    <div class="c-dl-ns-feedback__inner">
      <a class="c-dl-ns-feedback__profile" href="javascript:void(0)" (click)="onNavigate({routerLink:['/profile', comment.userName], type: 'LinkInside'})">
        <app-profile-avatar
          class="c-dl-ns-feedback__avatar c-avatar"
          *ngIf="profileState | async"
          [avatar]="(profileState | async)?.profilePictureURL"
          [firstName]="(profileState | async)?.firstName"
          [isBrand]="false"
          [lastName]="(profileState | async)?.lastName"
          [userName]="(profileState | async)?.userName">
        </app-profile-avatar>
      </a>
      <form class="c-dl-ns-feedback__ctrl" [formGroup]="replyForm" (ngSubmit)="replyToComment()">
        <textarea class="c-dl-ns-feedback__input js-reply-trigger"
                  formControlName="editor"
                  placeholder="Edit your comment..."
                  [disabled]="isLoading === 'REPLY'"
                  [ngxTribute]="tributeOptions"
                  placeholder="Comment..."
                  autosize></textarea>
        <div class="c-dl-ns-feedback__submit">
          <button
            class="c-dl-ns-feedback__oval e-dl-tool"
            ngbTooltip="Сancel"
            tooltipClass="c-dl-tooltip"
            (click)="cancelReply()"
            type="button">
            <svg class="icon icon-cancel"><use xlink:href="#icon-cancel"></use></svg>
          </button>

          <button
            class="c-dl-ns-feedback__oval e-dl-tool h-xs-loader"
            *ngIf="replyForm.get('editor').valid"
            [ngClass]="{'is-loading': isLoading === 'REPLY'}"
            [disabled]="isLoading === 'REPLY'"
            ngbTooltip="Submit"
            tooltipClass="c-dl-tooltip"
            type="submit">
            <svg class="icon icon-arrow_right h-xs-loader__caption"><use xlink:href="#icon-arrow_right"></use></svg>
            <i class="h-xs-loader__control"></i>
          </button>
        </div>
      </form>
    </div>
    <div class="c-n-form__message is-red" *ngIf="replyForm.get('editor').invalid">
        <span *ngIf="replyForm.get('editor').errors['maxLengthError']">{{validationMessages.maxlength('Comment', replyForm.get('editor').errors['maxLengthError'].maxLength)}}</span>
    </div>
  </div>
</div>

<div #repliesEl class="c-dl-ns-post__replies c-comments__replies" *ngIf="comment.replies?.length && showRepliesList" @animateList>
  <div class="c-comments__replies__more" *ngIf="comment.replies?.length > comment.limit" @animateItems>
    <div class="c-comments__replies__more__inner">
      <a class="c-comments__replies__more__trigger" (click)="loadMoreReplies()" href="javascript:void(0)">
        <span>Load more</span>
      </a>
      <span class="c-comments__replies__more__pagination" (click)="loadMoreReplies()" href="javascript:void(0)">{{comment.limit <= comment.replies.length ? comment.limit : comment.replies.length}}&nbsp;/&nbsp;{{ comment.replies.length }}</span>
    </div>
  </div>
  <app-comment
    class="c-comments__item c-comment"
    *ngFor="let reply of comment.replies | slice:0:comment.limit | reverse"
    @animateItems
    [webviewMode]="webviewMode"
    (navigateToEmit)="onNavigate($event)"
    (closeAnotherReplyForms)="onCloseAnotherReplyForms()"
    (increaseRepliesLimit)="loadMoreReplies(1)"
    (updateRepliesCount)="updateRepliesCount()"
    [closeReplyForm]="closeReplyForm"
    [parentCommentId]="comment.id"
    [comment]="reply"
    [reply]="false">
  </app-comment>
</div>
