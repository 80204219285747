import { Component, OnInit, Input } from '@angular/core';
import { QuestService } from 'src/app/_services/quest.service';

@Component({
  selector: 'app-funds-info',
  templateUrl: './funds-info.component.html',
  styleUrls: ['./funds-info.component.styl']
})
export class FundsInfoComponent implements OnInit {
  @Input() questId: number;
  @Input() doerId: number;
  @Input() mode: string;
  data: any;

  constructor(private questService: QuestService) {}

  ngOnInit() {
    this.questService.getFundraisingInfo(this.questId, this.doerId).subscribe(data => {
      this.data = data;
    });
  }

}
