<div class="c-dropdown" [ngClass]="{'is-active': isOpen}" toggle-directive (toggle-directive-event)="closeDropdown()">
    <div class="c-dropdown__header" (click)="toggleDropdown()">
        <ng-content></ng-content>
    </div>
    <div class="c-dropdown__body">
        <div class="c-dropdown__header__cancel" (click)="toggleDropdown()">
          <svg class="icon icon-cancel">
              <use xlink:href="#icon-cancel"></use>
          </svg>
        </div>
        <div class="c-dropdown__body__inner">
          <div class="c-dropdown__row">
              <a class="c-dropdown__item is-flex" (click)="toggleEditComment(); toggleDropdown()" href="javascript:void(null)">
                  <svg class="icon icon-pencil">
                      <use xlink:href="#icon-pencil"></use>
                  </svg>
                  <span>{{ comment.editable ? 'DONE' : 'Edit' }}</span>
              </a>
              <a class="c-dropdown__item is-flex" (click)="removeComment()" href="javascript:void(null)">
                  <svg class="icon icon-remove">
                      <use xlink:href="#icon-remove"></use>
                  </svg>
                  <span>Remove</span>
              </a>
          </div>
        </div>
    </div>
</div>

<!-- <app-modal id="confirm-comment-remove">
    <h3 class="modal-title">Comments</h3>
</app-modal> -->
