import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { QuestTask } from 'diemlife-commons/dist/diemlife-commons-model';
import { DlMilestonesService } from '../dl-milestones.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyConfigService } from 'src/app/_services/snotify-config.service';
import * as fromMilestones from '../store/dl-milestones.reducer';
import { ModalService } from '../../modal/modal.service';
import { PatchMilestone, PatchNewMilestone } from '../store/dl-milestones.actions';
import { take } from 'rxjs/operators';
import { getYoutubeId } from 'src/app/directives/youtube-validation/youtube-validation.directive';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { AppState } from 'src/app/_store/app.reducers';
import {QuestPreparedPhoto} from '../../quest/quest.type';
import {QuestService} from '../../../../_services/quest.service';


@Component({
  selector: 'app-dl-milestone-dialog',
  templateUrl: './dl-milestone-dialog.component.html',
  styleUrls: ['./dl-milestone-dialog.component.styl']
})
export class DlMilestoneDialogComponent implements OnInit, OnDestroy {
  dlMilestonesState: Observable<fromMilestones.State>;
  dlMilestonesSubscription: Subscription;
  milestoneInfo: QuestTask;
  selectedIndex: string | number;
  isLoading: string;
  mode: 'NEW' | 'RELEASED';
  isEditMode: boolean;

  constructor(
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService,
    private store: Store<AppState>,
    private dlMilestonesService: DlMilestonesService,
    private questService: QuestService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
  ) {
    this.dlMilestonesState = this.store.select('dlMilestones');
  }

  ngOnInit() {
    this.dlMilestonesSubscription = this.dlMilestonesState.subscribe((state: fromMilestones.State) => {

      if (!state.selectedMilestoneForPreview) { return false; }

      const index = state.selectedMilestoneForPreview.index;
      this.isEditMode = state.selectedMilestoneForPreview.isEditMode;

      // * detect witch mode should be used for this dialog
      if (state.milestones.length) {
        // tslint:disable-next-line:max-line-length
        this.mode = (state.milestones[0].questTasks.length - 1) < index ? 'NEW' : 'RELEASED';
      } else {
        this.mode = 'NEW';
      }

      if (this.mode === 'RELEASED') {
        this.milestoneInfo = state.milestones[0].questTasks[index];
      } else {
        this.milestoneInfo = state.newMilestoneForPreview;
      }

      this.selectedIndex = index < 10 ? '0' + (index + 1) : (index + 1);
    });
  }
  ngOnDestroy() {
    if (this.dlMilestonesSubscription) {
      this.dlMilestonesSubscription.unsubscribe();
    }
  }
  removePreview() {
    if (this.mode === 'RELEASED' && this.milestoneInfo.id) {
      // * milestone and quest is already created
      this.isLoading = 'REMOVE';
      this.dlMilestonesService.removePreview(this.milestoneInfo.id).subscribe(() => {
        this.removePreviewAction();
      }, () => {
        this.snotifyService.error(
          'Oops, something went wrong, please try again',
          null,
          this.snotifyConfigService.getConfig()
        );
        this.isLoading = null;
      });
    } else if (this.mode === 'RELEASED' && !this.milestoneInfo.id) {
      // * new quest with already created milestone
      this.removePreviewAction();
    } else {
      // * new quest with not created milestone
      this.store.dispatch(new PatchNewMilestone({
        imageUrl: null,
        linkUrl: null,
        linkPreview: null,
        linkedQuestId: null,
        linkedQuest: null,
        video: null,
        embeddedVideo: null
      } as QuestTask));
      this.modalService.close('milestone-details-dialog');
    }
  }
  private removePreviewAction() {
    this.snotifyService.success(
      'Media successfully removed!',
      null,
      this.snotifyConfigService.getConfig()
    );
    this.dlMilestonesState.pipe(take(1)).subscribe((state: fromMilestones.State) => {
      this.store.dispatch(new PatchMilestone({
        index: state.selectedMilestoneForPreview.index,
        value: {
          ...this.milestoneInfo,
          imageUrl: null,
          linkUrl: null,
          linkPreview: null,
          linkedQuestId: null,
          linkedQuest: null,
          embeddedVideo: null,
          video: null
        }
      }));
    });
    this.modalService.close('milestone-details-dialog');
    this.isLoading = null;
  }
  prepareQuestVideoId(questVideoUrl: string): SafeResourceUrl | null {
    const res = getYoutubeId(questVideoUrl);
    return Boolean(res) ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${res}`) : null;
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

}
