<div class="c-n-form__legend" *ngIf="legend">{{ legend }}</div>

<ng-container *ngTemplateOutlet="template ? template : default"></ng-container>
<ng-container *ngTemplateOutlet="validationTemplate ? validationTemplate : defaultValidation"></ng-container>

<ng-template #defaultValidation>
  <div class="c-n-form__alert" role="alert">
    <ng-container *ngIf="(control.touched || formIsSubmitted) && control.invalid">
      <div *ngIf="control.errors['required']">{{ vldMsg.required }}</div>
      <div *ngIf="control.errors['email']">{{ vldMsg.invalidEmail }}</div>
      <div *ngIf="control.errors['duplicateTeamName']">This name is already taken!</div>
    </ng-container>
    <div *ngIf="formIsSubmitted && mustBeTouched && !touched">Must join a team or select no team.</div>
  </div>
</ng-template>

<ng-template #default>
  <div class="c-n-form__control" [ngClass]="controlClass" (click)="mustBeTouched ? onClick() : null">
    <ng-content></ng-content>
    <span class="c-n-form__border"></span>
  </div>
</ng-template>
