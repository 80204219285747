import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import * as fromProfile from '../../../pages/profile/store/profile.reducer';
import { AccountService } from '../../../pages/account/account.service';
import { QuestInfo, QuestUserInfo } from 'diemlife-commons/dist/diemlife-commons-model';
import { AppState } from 'src/app/_store/app.reducers';
// import { QuestInfo, QuestUserInfo } from '../quest.type';

export interface QuestUserInfoPrepared extends QuestUserInfo {
  avatarUrl?: string;
}

@Component({
  selector: 'app-quest-profile',
  templateUrl: './quest-profile.component.html',
  styleUrls: ['./quest-profile.component.styl']
})
export class QuestProfileComponent implements OnInit {
  @Input() questInfo?: QuestInfo;
  @Input() doerInfo: QuestUserInfoPrepared;
  viewerId: number;
  isCreatorsQuest: boolean;
  isDoersQuest: boolean;
  userInfoState: Observable<fromProfile.State>;
  creator: any;
  isActiveProfile = 'doer';
  isLoaded = false;
  constructor(
    private store: Store<AppState>,
    private accountService: AccountService
  ) {
  }

  ngOnInit() {
    this.userInfoState = this.store.select('userInfo');
    this.userInfoState.pipe(take(1)).subscribe((response: fromProfile.State) => {
      this.viewerId = response ? response.id : null; // fix for anon request

      // get creator
      if (this.questInfo) {
        if (this.questInfo.createdBy === this.questInfo.user.id) {
          this.creator = this.questInfo.user;
        } else {
          this.accountService.getUserInfoById(this.questInfo.createdBy).subscribe(user => {
            this.creator = user;
          });
        }
        this.isCreatorsQuest = this.questInfo.createdBy === this.doerInfo.id;
        this.isDoersQuest = this.doerInfo.id === this.viewerId;
      } else {
        this.isCreatorsQuest = true;
        this.isDoersQuest = false;
        this.creator = this.doerInfo;
      }

      setTimeout(() => { this.isLoaded = true; });
    });

  }
  gotoProfile(id: number, userName: string) {
    console.log(`gotoProfile: ${id}, ${userName}`);
  }
  showProfile(profile: string, userId: number, userName: string) {
    console.log(`showProfile: ${profile}, ${userId}, ${userName}`);
  }
}
