<app-donate-banner></app-donate-banner>
<div class="l-new-landing">
  <header class="l-new-landing__header c-nl-header">
    <div class="c-container-new c-nl-header__inner">
      <h1 class="c-nl-header__logo h-heading-reset">
        <svg class="icon icon-logo"><use xlink:href="#icon-logo"></use></svg>
      </h1>
      <nav class="c-nl-header__nav">
        <button class="c-nl-header__nav__burger h-btn-reset">
          <span class="e-hamburger" [ngClass]="{'is-active': menuIsOpened}" (click)="menuIsOpened = !menuIsOpened">
            <span class="e-hamburger__inner"></span>
          </span>
        </button>
        <ul class="c-nl-header__nav__body h-list-reset" [ngClass]="{'is-active': menuIsOpened}">
          <li><a href="https://portal.diem.life/about/" target="_blank">ABOUT US</a></li>
          <li><a href="https://portal.diem.life/product/" target="_blank">PRODUCT</a></li>
          <li><a href="https://diem.life/explore/" target="_blank">EXPLORE</a></li>
          <li><a href="https://portal.diem.life/pricing/" target="_blank">PRICING</a></li>
          <li><a href="javascript:void(null)" (click)="openModal('auth-modal', 'logIn')">LOGIN</a></li>
          <li><a (click)="openModal('auth-modal', 'signUp')" class="c-nl-header__nav__btn" href="javascript:void(null)">SIGN
            UP</a></li>
        </ul>
      </nav>
    </div>
  </header>
  <div class="l-new-landing__banner c-nl-banner">
    <div class="c-nl-banner__bg"></div>
    <div class="c-nl-banner__caption c-container-new">
      <h2 class="c-nl-banner__title h-heading-reset">
        <span>Community engagement, amplified.</span>
      </h2>
      <p class="c-nl-banner__paragraph">We enable organizations to effectively manage the wellness of their communities.</p>
      <a class="c-nl-banner__submit c-btn c-btn_yellow c-btn_md" href="https://portal.diem.life/demo">
        <span>Request Demo</span>
      </a>
    </div>
  </div>
  <section class="l-nl-article is-alabaster">
    <div class="l-nl-article__header c-container-new">
      <h1 class="l-nl-article__title h-heading-reset">
        <span>What is DIEMlife<svg class="icon icon-nl-trademark"><use xlink:href="#icon-nl-trademark"></use></svg>?</span>
      </h1>
      <strong class="l-nl-article__sub-ttl">A platform to create events, launch fundraising campaigns, and achieve goals all in one place.  We call each one a Quest&reg;.</strong>
    </div>
    <div class="l-nl-article__body c-container-new">
      <div class="c-nl-statistics">

        <div class="c-nl-statistics__col">
          <div class="c-nl-statistics__card">
            <div class="c-nl-statistics__card__view">
              <svg class="icon icon-add"><use xlink:href="#icon-add"></use></svg>
            </div>
            <div class="c-nl-statistics__card__caption">
              <h3 class="c-nl-statistics__card__ttl h-heading-reset">Create a Quest&reg;</h3>
              <p class="c-nl-statistics__card__content">Create and curate each one exactly the way you want for what you and your organization needs to achieve.</p>
            </div>
          </div>
        </div>
        <div class="c-nl-statistics__col">
          <div class="c-nl-statistics__card">
            <div class="c-nl-statistics__card__view">
              <svg class="icon icon-nl-progress"><use xlink:href="#icon-nl-progress"></use></svg>
            </div>
            <div class="c-nl-statistics__card__caption">
              <h3 class="c-nl-statistics__card__ttl h-heading-reset">Track Progress</h3>
              <p class="c-nl-statistics__card__content">Whether it’s breaking down a fundraising goal or team project, easily track progress with tasks and milestones.</p>
            </div>
          </div>
        </div>
        <div class="c-nl-statistics__col">
          <div class="c-nl-statistics__card">
            <div class="c-nl-statistics__card__view">
              <svg class="icon icon-circle-ticket"><use xlink:href="#icon-circle-ticket"></use></svg>
            </div>
            <div class="c-nl-statistics__card__caption">
              <h3 class="c-nl-statistics__card__ttl h-heading-reset">Manage & Monetize</h3>
              <p class="c-nl-statistics__card__content">Our powerful features can handle anything you can imagine. Check out our <a href="https://portal.diem.life/pricing/" target="_blank">Pricing</a> page for more details.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="l-nl-article">
    <div class="l-nl-article__header c-container-new">
      <h1 class="l-nl-article__title h-heading-reset">
        <span>Get Inspired</span>
      </h1>
      <strong class="l-nl-article__sub-ttl">Check out these Quests&reg; in action!</strong>
    </div>
    <div class="l-nl-article__body c-container-new">
      <div class="c-nl-quests">
        <div class="c-nl-quests__col" *ngFor="let quest of quests">
          <app-card class="c-card" [quest]="quest"></app-card>
        </div>
      </div>
    </div>
  </section>
  <section class="l-nl-article c-nl-partners">
    <div class="c-nl-partners__header l-nl-article__header c-container-new">
      <h1 class="c-nl-partners__title h-heading-reset"><span>Trusted by trailblazing organizations like yours!</span></h1>
    </div>
    <div class="c-nl-partners__body l-nl-article__body c-container-new">
      <div class="c-nl-partners__group" [ngClass]="{'is-centered': brands.length < 4}">
        <div class="c-nl-partners__col" *ngFor="let brand of brands">
          <a class="c-nl-partners__item" [routerLink]="['/', 'profile', brand.userName]">
            <img [src]="brand.avatarUrl" alt="{{ brand.firstName }} {{ brand.lastName }}">
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="l-nl-article c-nl-community temp-hidden">
    <div class="l-nl-article__header c-container-new">
      <h1 class="l-nl-article__title h-heading-reset">
        <span>Start Your Quest</span>
	      <svg class="icon icon-nl-trademark"><use xlink:href="#icon-nl-trademark"></use></svg>
      </h1>
      <strong class="l-nl-article__sub-ttl">Trusted by trailblazing organizations like yours!</strong>
    </div>
    <div class="l-nl-article__body c-container-new">
      <div class="c-nl-community__group" [ngClass]="{'is-centered': brands.length < 4}">
        <div class="c-nl-community__col" *ngFor="let brand of brands | slice:0:4">
          <div class="c-nl-community__item">
            <a class="c-nl-community__item__view" [routerLink]="['/', 'profile', brand.userName]">
              <img [src]="brand.avatarUrl" alt="{{ brand.firstName }} {{ brand.lastName }}">
            </a>
            <strong class="c-nl-community__item__ttl">
              <a [routerLink]="['/', 'profile', brand.userName]">{{ brand.firstName }}<br>{{ brand.lastName }}</a>
            </strong>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer class="c-nl-footer">
    <div class="c-nl-footer__inner c-container-new">
      <nav class="c-nl-footer__nav">
        <ul class="h-list-reset">
          <li><a href="https://blog.diem.life/" target="_blank">BLOG</a></li>
          <li><a (click)="openModal('contact-modal', null)">CONTACT</a></li>
          <li><a [routerLink]="['/help']">HELP</a></li>
          <li><a [routerLink]="['/legal']">LEGAL</a></li>
        </ul>
      </nav>
    </div>
  </footer>
</div>
