<div class="c-quest-detail__header row">
  <picture *ngIf="fundraisingLink?.coverImageUrl" class="h-object-fit c-quest-detail__header__img">
    <img [alt]="fundraisingLink?.campaignName" [src]="fundraisingLink?.coverImageUrl"/>
  </picture>
  <picture *ngIf="!fundraisingLink?.coverImageUrl" class="h-object-fit c-quest-detail__header__img">
    <source *ngIf="photo.webp" [srcset]="photo.webp" type="image/webp">
    <img [alt]="questInfo.title" [src]="photo.jpg">
  </picture>
  <div class="c-profile-new__header__photo__bg__upload" *ngIf="allowEditCoverImage" (click)="onChangeCoverImage()">
    <svg class="icon icon-camera"><use xlink:href="#icon-camera"></use></svg>
  </div>
  <app-quest-header-tools class="c-quest-detail__header__tools"
    [questInfo]="dropdownContext"
    [doerInfo]="questUserInfo"
    [showStartTrigger]="showStartTrigger === 'public'">
  </app-quest-header-tools>
  <div class="c-quest-detail__header__title">
    <div class="c-quest-detail__header__title__top">
      <span class="c-quest-detail__header__title__category">{{questInfo.pillar}}</span>
      <span class="c-quest-detail__header__title__dot">&bull;</span>
      <svg class="icon icon-eye"><use xlink:href="#icon-eye"></use></svg>
      <span class="c-quest-detail__header__title__view-count">{{questInfo.views}}</span>
    </div>
    <h1>
      <span>{{ fundraisingLink?.campaignName ? fundraisingLink.campaignName : questInfo.title }}</span>
      <a class="c-quest-detail__header__title__shield"
        *ngIf="fundraisingLink?.campaignName"
        [routerLink]="['/quest-detail', questInfo.id, questInfo.user.id]"
        [title]="questInfo.title">
        <svg class="icon icon-shield is-warning">
          <use xlink:href="#icon-shield"></use>
        </svg>
      </a>
    </h1>
    <div class="c-quest-detail__header__title__created">Created {{questInfo.dateCreated | timeAgo}}</div>
  </div>
  <div class="c-quest-detail__header__progress" *ngIf="showProgress && questData.questTasks.length > 0">
    <app-quest-progress
      class="c-quest__progress c-quest-progress"
      [simpleMode]="true"
      [value]="status === 'completed' ? 100 : questData.completionPercentage">
    </app-quest-progress>
    <!-- <span class="c-quest-detail__header__progress__status">DOING</span> -->
    <ng-content></ng-content>
    <span class="c-quest-detail__header__progress__value">{{completionPercentage}}<sub>%</sub></span>
    <span class="c-quest-detail__header__progress__title">COMPLETED</span>
  </div>
</div>






<!-- <div class="c-quest-header__photo" *ngIf="questInfo">
  <div class="c-quest-header__photo__bg">
    <picture *ngIf="fundraisingLink?.coverImageUrl" class="h-object-fit">
      <img [alt]="fundraisingLink?.campaignName" [src]="fundraisingLink?.coverImageUrl"/>
    </picture>
    <picture *ngIf="!fundraisingLink?.coverImageUrl" class="h-object-fit">
      <source *ngIf="photo.webp" [srcset]="photo.webp" type="image/webp">
      <img [alt]="questInfo.title" [src]="photo.jpg">
    </picture>
  </div>
</div> -->
<!-- <div class="c-quest-header__header">
  <div class="c-quest-header__tools is-align-to-left">
    <div class="c-quest-header__tool">
      <svg class="icon icon-eye"><use xlink:href="#icon-eye"></use></svg>
      <span>{{ questInfo.views }}</span>
    </div>
    <div class="c-quest-header__tool" *ngIf="questInfo.savedCount > 0">
      <svg class="icon icon-save"><use xlink:href="#icon-save"></use></svg>
      <span>{{ questInfo.savedCount }}</span>
    </div>
  </div>
  <div class="c-quest-header__tools">
    <app-quest-dropdown [questInfo]="dropdownContext" [doerInfo]="questUserInfo" [showStartTrigger]="showStartTrigger === 'public'">
      <span>options</span>
      <svg class="icon icon-dots"><use xlink:href="#icon-dots"></use></svg>
    </app-quest-dropdown>
  </div>
</div>
<div class="c-quest-header__caption">
  <app-quest-profile class="c-quest-profile"
                     *ngIf="(questData.activityInfo && questData.activityInfo.mode === 'paceYourselfMode') || !questData.isTeamPage"
                     [questInfo]="questInfo"
                     [doerInfo]="questUserInfo">
  </app-quest-profile>
  <app-quest-header-team class="c-quest-profile"
                         *ngIf="(questData.activityInfo && questData.activityInfo.mode !== 'paceYourselfMode') && questData.isTeamPage"
                         [teamId]="questData.doerTeamId">
  </app-quest-header-team>
  <div class="c-quest-header__tools">
    <ng-content></ng-content>
  </div>
</div> -->
