<div class="c-article__header" #headerDiv>
  <h3 class="c-article__title h2" (click)="openMembersDialog()" [ngSwitch]="mode">
    <span class="e-thin">{{ doersCount ? doersCount : 0 }}</span>&nbsp;
    <span *ngSwitchCase="'doers'">Members</span>
    <span *ngSwitchDefault>Friends</span>
  </h3>
  <!-- TODO - new feature -->
  <div class="c-members__filters temp-hidden">
    <span>View by</span>
    <button type="button" class="c-members__filters__trig">Filter*</button>
  </div>
  <div *ngIf="savedCount" class="c-article__sub-tools" (click)="openMembersDialog()">
    <span>+{{ savedCount }} interested</span>
  </div>
  <div class="c-article__tools">
    <a href="javascript:void(null)"
       *ngIf="canSendBulkMessage"
       (click)="openMessageAllDialog()"
       class="c-article__tool">Message all</a>
    <!--    <a href="javascript:void(null)"-->
    <!--       *ngIf="fullDoers.length > 0"-->
    <!--       (click)="openMembersDialog()"-->
    <!--       class="c-article__tool">See all</a>-->
  </div>
</div>
<div class="c-article__body">
  <!-- [ngStyle]="{ 'margin-left.px' : makeInt(headerDiv.offsetWidth / 100) / 2}" -->
  <div class="c-quest__doers" 
    [class.is-align-left]="true" 
    style="flex-wrap: wrap">
    <div
      class="avatarDiv"
      style="display: block"
      [ngStyle]="{ 'margin-bottom.px' : more.offsetWidth < 50 ? 50 : 0 }"
      *ngFor="let user of viewListDoersCutoff | sortMembers:{isListSort: true, mode: mode}">
      <a class="c-quest__doer"
        href="javascript:void(0)"
        style="display: block; margin-right: 36px; text-align: center"
        [routerLink]='user.userId > 0 && !user.anonymous ? ["/profile", user.userName] : null'>
        <app-profile-avatar
          class="c-quest-profile__img c-avatar thin-border"
          style="margin-top: 15px; margin-left: 9px"
          [avatar]="user.profilePictureURL"
          [isBrand]="user.isUserBrand"
          [firstName]="user.firstName"
          [lastName]="user.lastName"
          [userName]="user.userName">
        </app-profile-avatar>
        <!-- <app-quest-progress *ngIf="mode === 'doers'" class="c-quest__doer__progress" [value]="user.percentage"></app-quest-progress> -->
        <!-- <div class="c-quest__doer__status" *ngIf="mode === 'doers'" [ngSwitch]="getStatusPriority(user.memberStatusObj ? user.memberStatusObj : {})">
          <svg class="icon icon-balance" *ngSwitchCase="'Backer'"><use xlink:href="#icon-balance"></use></svg>
          <svg class="icon icon-Quest" *ngSwitchCase="'Doer'"><use xlink:href="#icon-Quest"></use></svg>
          <svg class="icon icon-update" *ngSwitchCase="'Achiever'"><use xlink:href="#icon-update"></use></svg>
        </div> -->
        <div class="c-quest__doer__amount"
              style="margin-top: 7px; text-align: center">
          <span class="c-my-friends__item__title">{{ user.firstName + ' ' + user.lastName }}</span>
        </div>
        <div class="c-quest__doer__amount"
              *ngIf="user.amountBackedSum && showBacking">
          <span>{{ (user.amountBackedSum ? user.amountBackedSum / 100.0 : 0) | currency: 'USD' }}</span>
        </div>
      </a>
    </div>

    <div #more 
      class="c-quest__doers__more thin-border"
      (click)="openMembersDialog()" 
      style="margin-top: 15px; margin-left: 9px"
      >
      <!-- [ngStyle]="{'margin-left.px': (100 - more.offsetWidth) / 2}" -->
      +{{ viewListDoers.length - viewListDoersCutoff.length}}
    </div>
  </div>
  <div *ngIf="!fullDoers.length && mode === 'privateFriends'">
    <div class="c-my-friends__profile-empty">
      <svg class="icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
      <span>You do not have any friends yet</span>
      <span>You can find your friends <a href="#">here</a></span>
    </div>
  </div>
  <div *ngIf="!fullDoers.length && mode === 'publicFriends'">
    <div class="c-my-friends__profile-empty">
      <svg class="icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
      <span>{{firstName}} has no friends yet</span>
    </div>
  </div>
</div>

<app-modal
  id="membersModal"
  [className]="['c-dialog', 'c-members']"
  (closeModal)="closeDialog()">
  <div class="c-dialog__header">
    <h3 class="c-dialog__title h2 is-align-left">
      <span>{{mode === 'doers' ? 'members' : (mode === 'privateFriends' ? 'My Friends' : firstName + '\'s Friends')}}</span>
    </h3>
  </div>
  <div class="c-dialog__body">
    <app-members-dialog
      class="c-members__inner"
      *ngIf="fullDoers.length > 0 && openedDialog === 'membersModal'"
      [mode]="mode"
      [showBacking]="showBacking"
      [doers]="fullDoers"></app-members-dialog>
    <div class="h-loader__control"></div>
  </div>
</app-modal>

<app-modal
  id="messageAllModal"
  *ngIf="openedDialog === 'messageAllModal'"
  [ngStyle]="{'display': 'block'}"
  [className]="'c-n-modal_sm'"
  (closeModal)="closeDialog()">

  <h5 class="c-n-modal__title t-typo__h4">Message to all participants</h5>
  <form class="c-n-modal__body"
        #ngForm="ngForm"
        (ngSubmit)="sendMessageForDoers()"
        [formGroup]="messageAllForm">

    <div class="c-n-form__group">
      <label for="messageAllModalSubject" class="c-n-form__legend">Subject:</label>
      <div class="c-n-form__control">
        <input
          id="messageAllModalSubject"
          class="c-n-form__input"
          type="text"
          name="subject"
          placeholder="Howdy y'all"
          maxlength="250"
          appInputFocusTrigger
          [formControlName]="'subject'"
          [readOnly]="isLoading || dialogInProgress"/>
      </div>
    </div>

    <div class="c-n-form__group">
      <div class="c-n-form__legend">Message:</div>
      <div class="c-n-form__control is-editor is-required">
        <quill-editor
          class="c-n-form__input is-editor h-typography"
          theme="bubble"
          format="html"
          placeholder="Type your message here..."
          [modules]="quillConfig"
          [formControlName]="'messageToAll'"
          [readOnly]="isLoading || dialogInProgress">
        </quill-editor>
      </div>
      <div role="alert" class="c-n-form__alert" *ngIf="ngForm.submitted && messageAllForm.invalid">
        <div>Invalid message</div>
      </div>
    </div>

    <div class="c-forms__group mb-20 temp-hidden">
      <div class="c-n-form__legend">FOOTER:</div>
      <label role="checkbox" class="e-checkbox">
        <input aria-label="Plain message"
               type="checkbox"
               [formControlName]="'brandedMessage'"/>
        <span class="e-checkbox__icon">
          <svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
        </span>
        <span class="e-checkbox__caption">Include standard DIEMlife footer</span>
      </label>
    </div>

    <div class="c-n-modal__footer">
      <button
        class="e-btn e-btn_dark"
        [disabled]="isLoading || dialogInProgress"
        [ngClass]="{'is-loading': isLoading || dialogInProgress}"
        type="submit"
        aria-label="Submit">
        <span>Send</span>
      </button>
    </div>
  </form>

</app-modal>
