<div class="c-quest__article" *ngIf="canBeEdit || (dlMilestonesState | async)?.milestones[0]?.questTasks?.length > 0">
	<div class="c-quest__article__header">
		<h3 class="c-dl-milestones__title c-quest__article__title h2" (focusout)="onFocusOut()">
			<span
				class="c-dl-milestones__title__inner h-edit"
				appSmartEdit
				[smartEditMode]="isEditMode && canBeEdit || mode === milestoneModes.NEW_QUEST"
				[smartEditManualSwitch]="true"
				[debounce]="3000"
				[ngClass]="{'is-loading': isLoading === 'UPDATE_GROUP_NAME'}"
				(valueChanged)="changeGroupTitle($event)"
				#milestonesTitle
				[textContent]="(dlMilestonesState | async)?.milestones[0]?.name ? (dlMilestonesState | async)?.milestones[0]?.name : titleHints[0]"
				(focus)="onFocusIn()"></span>
			<span class="c-dl-milestones__ttl-helpers" *ngIf="showHints && (isEditMode && canBeEdit || mode === milestoneModes.NEW_QUEST)" @animateToggleList>
				<button
					@animateItems
					class="c-dl-milestones__ttl-helpers__item e-btn e-btn_xs"
					type="button"
					*ngFor="let item of titleHints; index as i"
					[ngClass]="{'e-btn_dark-yellow': item === (dlMilestonesState | async)?.milestones[0]?.name}"
					(mousedown)="setGroupTitle(i)">
					<span>{{ item }}</span>
				</button>
			</span>
		</h3>
		<a *ngIf="!isEditMode && canBeEdit && mode === milestoneModes.QUEST_DETAILS" class="c-quest__article__tool" (click)="isEditMode = true" href="javascript:void(null)">
			<svg class="icon icon-pencil"><use xlink:href="#icon-pencil"></use></svg>
			<span>Edit</span>
		</a>
		<a *ngIf="isEditMode && canBeEdit && mode === 'QUEST_DETAILS'" class="c-quest__article__tool" (click)="isEditMode = false" href="javascript:void(null)">
			<svg class="icon icon-checked"><use xlink:href="#icon-checked"></use></svg>
			<span>Done</span>
		</a>
	</div>

  <app-quest-map *ngIf="showMap"
	[data]="dlMilestonesState | async"
	[questId]="questId"
	[userId]="userId"
	[questTitle]="questTitle"
	[locationName]="locationName"
	[participantUserIds]="participantUserIds"
	[directionsType]="directionsType"
	[teams]="teams"
	(onMilestoneSelected)="goToLinkedQuest($event)"></app-quest-map>

	<div *ngIf="!taskViewDisabled" class="c-quest__article__body c-dl-milestones__body">
		<div
			*ngIf="(dlMilestonesState | async)?.milestones[0]?.questTasks?.length > 0"
			class="c-dl-milestones__body js-boundary-container"
			cdkDropList
			[cdkDropListDisabled]="!canBeEdit"
			(cdkDropListDropped)="drop($event)">
			<app-dl-milestone
				class="c-dl-milestone"
				*ngFor="let item of (dlMilestonesState | async)?.milestones[0].questTasks; index as i"
				[milestone]="item"
				[index]="i"
				[questId]="questId"
				[mode]="mode"
				[allowCheck]="allowCheck"
				[isEditMode]="isEditMode"
				[canBeEdit]="canBeEdit"
				[showControls]="showControls"
				cdkDrag
				cdkDragLockAxis="y"
				[cdkDragStartDelay]="300"
				cdkDragBoundary=".js-boundary-container">
			</app-dl-milestone>
		</div>
		<div class="c-dl-milestones__placeholder" *ngIf="!(dlMilestonesState | async)?.milestones[0]?.questTasks?.length">
			<span>What will you be doing?</span>
		</div>
		<app-dl-new-milestone
			*ngIf="isEditMode"
			class="c-dl-milestones__new"
			[index]="(dlMilestonesState | async)?.milestones[0]?.questTasks.length > 0 ? (dlMilestonesState | async)?.milestones[0]?.questTasks.length : 0"
			[questId]="questId"
			[isEditMode]="isEditMode"
			[mode]="mode">
		</app-dl-new-milestone>
	</div>
</div>

<app-modal id="milestone-details-dialog" (closeModal)="onCloseMilestoneDetailsDialog()">
	<app-dl-milestone-dialog></app-dl-milestone-dialog>
</app-modal>
