import {Component, OnInit} from '@angular/core';
import {FriendsService} from '../friends.service';
import * as fromProfileFriends from './../../../separate/profile-friends/store/profile-friends.reducer';

@Component({
  selector: 'app-friends-accepted',
  templateUrl: './friends-accepted.component.html',
  styleUrls: ['./friends-accepted.component.styl']
})
export class FriendsAcceptedComponent implements OnInit {
  isLoading = true;
  friends: fromProfileFriends.State[] = [];

  constructor(private friendsService: FriendsService) {
  }

  ngOnInit() {
    this.friendsService.getCurrentFriends().subscribe((res) => {
      this.friends = res;
      this.isLoading = false;
    });
  }

  removeItemFromList(i: number) {
    this.friends.splice(i, 1);
  }

}
