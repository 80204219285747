<div class="c-container c-container_md">

  <br>
  <h1>We're here to help</h1>

  <div id='ppBody'>
    <!--<div class='ppConsistencies'>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Information Collection</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Information Usage</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Information Protection</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">Cookie Usage</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">3rd Party Disclosure</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links text-center">3rd Party Links</div>-->
    <!--</div>-->
    <!--<div class='col-2'></div>-->
    <!--</div>-->
    <!--<div style='clear:both;height:10px;'></div>-->
    <!--<div class='ppConsistencies'>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 gen-text-center">Google AdSense</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 gen-text-center">-->
    <!--Fair Information Practices-->
    <!--<div class="col-8 gen-text-left gen-xs-text-center"-->
    <!--style="font-size:12px;position:relative;left:20px;">Fair information<br> Practices-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 gen-text-center coppa-pad">-->
    <!--COPPA-->

    <!--</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="col-12 quick-links2 quick4 gen-text-center caloppa-pad">-->
    <!--CalOPPA-->

    <!--</div>-->
    <!--</div>-->
    <!--<div class='col-2'>-->
    <!--<div class="quick-links2 gen-text-center">Our Contact Information<br></div>-->
    <!--</div>-->
    <!--</div>-->
    <span id='infoCo'></span><br>
    <div class='grayText'><strong>What is DIEMlife?</strong></div>
    <br/>
    <div class='innerText'>DIEMlife is a social network and lifestyle platform for go-getters and goal-setters. It's a place where you can track your Quests, connect with friends for support and join a community of like-minded people who are also looking to improve their lives. <a *ngIf="!webViewMode" href="http://blog.diem.life/about">Read more about us and our story.</a><a *ngIf="webViewMode" href="javascript:void(0)" (click)="goToPage('http://blog.diem.life/about', 'external')">Read more about us and our story.</a>
    </div>
    <br>
    <div class='grayText'><strong>What is a Quest?</strong></div>
    <br/>
    <div class='innerText'>Quests are what you see in your feed. In DIEMlife, they're anything from goals to bucket list items and anything else you're working on. You can have a Quest to finish a trail race, to meditate daily, to take an online course, to talk to your family 3x a week or to go to Tomorrowland. All of these are Quests. If you want more Quest ideas, browse the Feed to see what others are working on.
    </div>
    <br>
    <div class='grayText'><strong>What do I do with Quests?</strong></div>
    <br/>
    <div class='innerText'>There's a lot you can do with Quests. Create your own Quests or search for ideas in your feed. As well as doing them, you can comment and financially back your friend's Quests and they can do the same for you.
    </div>
    <br>
    <div class='grayText'><strong>I just bought an event ticket through DIEMlife. What happens next?</strong></div>
    <br/>
    <div class='innerText'>Awesome! You should have received a ticket confirmation email from us. If you don't see it, check your spam or <a *ngIf="!webViewMode" href="mailto:admin@diemlife.com">contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">contact us</a>. If you need to make any changes to your ticket order, contact the event organizer.
    </div>
    <br>
    <div class='grayText'><strong>I'm having trouble buying an event ticket through DIEMlife. Can you help?</strong></div>
    <br/>
    <div class='innerText'>Most certainly. <a *ngIf="!webViewMode" href="mailto:admin@diemlife.com">Contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">Contact us</a> and let us know what issue you're having.
    </div>
    <br>
    <div class='grayText'><strong>I just saved a Quest. Where did it go?</strong></div>
    <br/>
    <div class='innerText'>Click "My Quests" in the menu and it will be located in the Saved section. All your Quests are separated by Active, Saved, and Done. Active Quests are the ones you've started and are currently working on. Saved Quests are the ones you've saved but not yet started. And of course, Done Quests are the ones you've started and completed.
    </div>
    <br>
    <div class='grayText'><strong>I just clicked Start on my Saved Quest. Where did it go?</strong></div>
    <br/>
    <div class='innerText'>When you click "Start" on a Quest, it's now active which means it is in the Active section.
    </div>
    <br>
    <div class='grayText'><strong>I completed all my Milestones but it didn't move to Done. How can I move it over?</strong></div>
    <br/>
    <div class='innerText'>To move any Quest to Done, you have to click the "Complete Quest" button.
    </div>
    <br>
    <div class='grayText'><strong>I tried to edit my Quest but when I'm asked to keep or remove the original one, I'm not sure what to do. What's the difference?</strong></div>
    <br/>
    <div class='innerText'>We ask if you want to keep the original Quest when there's more than one person doing it. Keeping the original means you'll still be a part of the group of people doing the original version of the Quest. So if you choose to keep it, you'll have both the original Quest AND your edited one. If you want to remove it, you'll only have the edited version and you'll no longer be in the original Quest.
    </div>
    <br>
    <div class='grayText'><strong>Can I invite friends?</strong></div>
    <br/>
    <div class='innerText'>Of course! You can either search for them in Friends or invite them in by sharing a Quest via email or social media.
    </div>
    <br>
    <div class='grayText'><strong>Can I get notifications on my or friend's Quests?</strong></div>
    <br/>
    <div class='innerText'>Yes, just check "Alerts" in the menu.
    </div>
    <br>
    <div class='grayText'><strong>How do I manage my privacy in DIEMlife?</strong></div>
    <br/>
    <div class='innerText'>All users and their Quests are public at the moment. We will be adding more privacy options. In the meantime, read our <a routerLink="/privacy">privacy policy</a>.
    </div>
    <br>
    <div class='grayText'><strong>How can I report inappropriate content?</strong></div>
    <br/>
    <div class='innerText'>If you see anything on DIEMlife that doesn't is offensive, <a *ngIf="!webViewMode" href="mailto:admin@diemlife.com">contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">contact us</a>.
    </div>
    <br>
    <div class='grayText'><strong>I have more questions but I don't see them listed here. Can you still help me?</strong></div>
    <br/>
    <div class='innerText'>Of course! <a *ngIf="!webViewMode" href="mailto:admin@diemlife.com">contact us</a><a *ngIf="webViewMode" routerLink="/contact" queryParamsHandling="merge">contact us</a>.
    </div>
    <br>
    <div class='grayText'><strong>How do I receive money for my Quests in DIEMlife?</strong></div>
    <br/>
    <div class='innerText'>You'll only be able to receive financial backing on Quests once you've added payment information in your Account. The same goes if you want to give money or make purchases.
    </div>
    <br>
    <div class='grayText'><strong>How do I give money in DIEMlife?</strong></div>
    <br/>
    <div class='innerText'>Click "Back Me" on any Quest and you'll be able to financially back anyone's Quest once you're logged into your bank account.
    </div>
    <br>
    <div class='grayText'><strong>Do I need to finish my Quest if I've received money?</strong></div>
    <br/>
    <div class='innerText'>We can't tell you what to do. In some cases, completing Quests is about accountability, integrity, and responsibility. But sometimes, a particular Quest may no longer make sense in your life. If that's the case, consider using the money to pay it forward (coming soon!) and back someone else on their Quest. Be sure to update your Quest backers so they're not left wondering what happened to their contribution.</div>
    <br/>
    <div class='grayText'><strong>What are the fees?</strong></div>
    <br/>
    <div class='innerText'>For ticket purchases, we charge a 2% platform fee + $0.99 per ticket plus a standard credit card processing fee of 2.9% + $0.30. For all financial backing transactions, we charge a standard 5% platform fee plus a standard credit card processing fee of 2.9% + $0.30.
    </div>
    <br>
    <div class='grayText'><strong>Can I make my financial backings private?</strong></div>
    <br/>
    <div class='innerText'>Eventually, yes.
    </div>
    <br>
    <div class='grayText'><strong>Can I get a refund?</strong></div>
    <br/>
    <div class='innerText'>Because we don't hold onto any money that passes between you, Quests that you've backed financially, or the event tickets you've purchased, we can't give you a refund on your money.
    </div>
    <br>
    <div class='grayText'><strong>How does this work for taxes if I give or receive money?</strong></div>
    <br/>
    <div class='innerText'>Financial backing of Quests is classified as nontaxable gifts, not income. We're not a charitable organization, so financial backings do not fall under charitable giving guidelines. It's best to ask your tax advisor or check out irs.gov.
    </div>
    <br>
    <div class='grayText'><strong>Why do people financially back each other's Quests?</strong></div>
    <br/>
    <div class='innerText'>We asked our users why they back Quests and these are just some of their reasons:
      "I wanted to encourage my friend and finally see him achieve this Quest."
      "Just because."
      "So I can make a difference and help people improve their lives."
      "I can see where my money is going and how it's making an impact."
    </div>
    <br>
    <div class='grayText'><strong>What do backers get in return?</strong></div>
    <br/>
    <div class='innerText'>People who financially back others will get updates on Quest progress and Quest completion. It goes without saying, a sense of purpose, community, and impact being part of another person's Quest.
    </div>
    <br>
    <div class='grayText'><strong>How do I know if the person and their Quest is genuine?</strong></div>
    <br/>
    <div class='innerText'>Maybe you're friends with the person or a trusted source told you about this person and their Quest. You'll have to decide for yourself what's genuine by checking out the person's profile and reading the comments. If you're still not sure and have more questions, comment on their Quest to ask them questions.
    </div>
    <br>
    <small><br>Last Edited on 2018-11-15</small>
  </div>

</div>
