import {Component, Input, OnInit} from '@angular/core';
import {QuestLite} from 'diemlife-commons/dist/diemlife-commons-model';
import {QuestService} from 'src/app/_services/quest.service';

@Component({
  selector: 'app-mega-quest-link',
  templateUrl: './mega-quest-link.component.html',
  styleUrls: ['./mega-quest-link.component.styl']
})
export class MegaQuestLinkComponent implements OnInit {

  @Input()
  megaQuests: QuestLite[];

  constructor(private questService: QuestService) {}

  ngOnInit(): void {
  }

  wrapPhoto(quest: QuestLite) {
    return this.questService.prepareCoverPhoto(quest.photo, quest.pillar);
  }

}
