<div class="terms--top-mod"><h1 class="terms--top-mod-title">Privacy Policy</h1></div>
<div class="terms--bottom">
  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <!--<h3 class="terms&#45;&#45;mod-title">Terms Of Service</h3>-->
      <p>
        This Privacy Policy describes what information we collect from you, how we use and share that information in
        connection with the Services and your rights and choices regarding such information. All capitalized words are
        defined in our Terms of Use. We use cookies and similar technologies, as described in our Cookie Policy.
      </p>
      <h3 class="terms--mod-title">Information We Collect</h3>
      <p>When you access, use or register for our Services, you provide us with certain information. </p>
      <p>
        1. Personal and Other Information. When registering for and using the Services, you may voluntarily provide
        your name, email address, mailing address, log-in details, password and any other details and information to
        help you with your experience. If necessary, we may require identity information, which involve collecting
        scanned of documents containing personal information such as your driver’s license. As part of using the
        Services, you may submit information to our partners (such as Stripe) and that information will be used by
        such partners in accordance with their respective privacy policies. From time to time, you may provide other
        communications such as questions, comments or requests for support.
      </p>
      <p>
        2. Technical and Device Information. Whenever you access our Services, we may collect certain technical and
        device information automatically. These include, among others, the type, settings and version of the device or
        web browser you use, IP address, the address of and activity on websites that you visit that use DIEMlife
        features, the date and time of your request, how you use our Services, cookie data, and website to which you
        navigate from our website.
      </p>
      <p>
        3. Cookie Data. We use "cookies" (text files inserted and stored by your web browser each time you visit our
        website) or similar technologies to record log data. When we use cookies, we may use "session" cookies (that
        last until you close your browser) or "persistent" cookies (that last until you or your browser delete them)
        to track, gather and store information about your preferences or other DIEMlife settings so you don't have to
        set them up every time you visit DIEMlife. Some of the cookies we use are associated with your DIEMlife
        account (including personal information about you, such as the email address you gave us), and other cookies
        are not. Most Internet browsers will permit you to prevent from being stored, or delete cookies stored, on
        your computer.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">How We Use Your Information</h3>
      <p>
        We use the information we collect to operate and provide the Services to you and personalize your experience,
        including to:
      </p>
      <dl>
        <dt>• Identify you, administer your account and process your transactions;</dt>
        <dt>• Keep your account secure and prevent fraud and abuse;</dt>
        <dt>• Provide important notices and alerts and communications in regard to the Services;</dt>
        <dt>• Deliver content, recommendations and product offerings that are interesting and relevant to you;</dt>
        <dt>• Aggregate data (which when accomplished renders your personal data non-personal);</dt>
        <dt>• Improve and promote our Services;</dt>
        <dt>• Use for other internal business purposes</dt>
      </dl>
      <h3 class="terms--mod-title">How We Share Information</h3>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your personal data unless we provide users
        with advance notice. This does not include website hosting partners and other parties who assist us in
        operating and providing the Services, so long as those parties agree to keep this information confidential. We
        reserve the right to disclose personal data as necessary to comply with the law and to protect our legal
        rights.
      </p>
      <h3 class="terms--mod-title">Third-Party Links </h3>
      <p>
        The Services may include links to third-party websites, products or services. These third-party sites will
        have their own privacy policies and practices that differ from ours. DIEMlife has no responsibility or
        liability for the content and activities of these linked sites.
      </p>
      <h3 class="terms--mod-title">Retention of Your Personal Data</h3>
      <p>
        We will retain your information as long as your account is active, in order to provide you with the Services
        or to otherwise achieve the purposes described in this policy. We will also retain and use your personal data
        to the extent necessary to comply with our legal obligations, resolve disputes, enforce our agreements and
        protect our legal rights. You may close your account by contacting us. Please note that we may retain your
        data for an additional period as permitted or required by applicable laws and regulations.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Transfer of Data</h3>
      <p>
        DIEMlife is a US-based company open to users globally. By accessing and using the Services, you acknowledge
        and agree that your information will be collected, processed and stored in the United States. The United
        States has its own laws governing data protection and government access to information, which laws may differ
        from those in your home country.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Your Choices and Controls</h3>
      <p>
        You have certain choices, controls and rights over your personal data, including the ability to:
      </p>
      <dl>
        <dt>• Edit, modify or delete the personal information provided to us;</dt>
        <dt>• Delete your account;</dt>
        <dt>• Request a copy of your personal data;</dt>
        <dt>• Link or unlink your account from third-party services;</dt>
        <dt>• Opt-out of receiving marketing communications such offers and promotions for product and service
          offerings from us or third-parties by clicking on the “Unsubscribe” link at the bottom of any such
          communication
        </dt>
      </dl>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Information Security</h3>
      <p>
        The security of your personal data is very important to us. We take commercially reasonable technical,
        physical and administrative security measures to help protect information from loss and unauthorized access,
        use and modification. We regularly review and update our security measures. While we take security measures
        seriously, please be aware that 100% security is not always achievable.
      </p>
      <p>
        You are responsible for maintaining your user id and password confidential and secure. If you believe that
        your access and use has been compromised in any way, please contact us immediately at help@diemlife.com.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Children’s Privacy</h3>
      <p>
        The Services are intended for those at least 13 years old or older. We do not knowingly collect information
        provided by children under the age of 13. When it comes to the collection of personal information from
        children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in
        control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule,
        which spells out what operators of websites and online services must do to protect children's privacy and
        safety online.
      </p>
      <p>
        How does our site handle Do Not Track signals?
      </p>
      <p>
        We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT)
        browser mechanism is in place.
      </p>
    </div>
  </div>

  <div class="terms--mod clearfix">
    <div class="terms--mod-txt">
      <h3 class="terms--mod-title">Contacting Us</h3>
      <p>If there are any questions regarding this Privacy Policy, you may contact us at:</p>
      <dl>
        <dt>DIEMlife, Inc.</dt>
        <dt>85 East 10th St Ste. M</dt>
        <dt>New York, NY 10003</dt>
        <dt>United States</dt>
        <dt>help@diemlife.com</dt>
      </dl>
      <p>Effective December 18, 2018</p>
    </div>
  </div>
</div>