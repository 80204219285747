import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export const youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

export const getYoutubeId = (value: string): string | boolean => {
  const match = value ? value.match(youtubeRegExp) : '';
  return (match && match[7].length === 11) ? match[7] : false;
};

/** A hero's name can't match the given regular expression */
export const youtubeUrlValidator: ValidatorFn = (control: AbstractControl):  ValidationErrors | null => {
  if (control.value) {
    const res = control.value ? getYoutubeId(control.value) : false;
    return Boolean(res) ? null : {'youtubeUrlError': control.value};
  } else {
    return null;
  }
};
