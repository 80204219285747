<form
  title="Login form"
  name="loginForm"
  autocomplete="new-password"
  class="c-n-modal__body"
  [formGroup]="signinForm"
  #ngForm="ngForm"
  (ngSubmit)="onSignin()"
  *ngIf="activeState === 'logIn'"
  [ngClass]="{'form-is-submitted': signinFormSubmitted}">

  <h5 class="c-n-modal__title t-typo__h3">Log In</h5>
  <p class="c-n-modal__sub-title-switch t-typo__h6">New to DIEMlife? <a class="t-typo__link-inverted" href="javascript:void(null)" (click)="changeAuthTab('signUp')">Sign up</a></p>

  <div class="c-n-form__group">
    <div class="c-n-form__legend">Email address:</div>
    <div class="c-n-form__control is-required">
      <input
      class="c-n-form__input"
      type="email"
      autocomplete="new-password"
      formControlName="email"
      name="email"
      placeholder="janedoe@diemlife.com"
      aria-label="Enter Email for login"
      appInputFocusTrigger>
      <span class="c-n-form__border"></span>
    </div>
  </div>
  <div
    *ngIf="signinForm.get('email').invalid && (signinForm.get('email').touched || ngForm.submitted)"
    class="c-n-form__message is-red"
    role="alert">
    <div *ngIf="signinForm.get('email').errors.required">{{ messages.required }}</div>
    <div *ngIf="signinForm.get('email').errors.email">{{ messages.invalidEmail }}</div>
  </div>
  <div class="c-n-form__group">
    <div class="c-n-form__legend">Password:</div>
    <div class="c-n-form__control is-required">
      <input
        class="c-n-form__input"
        type="password"
        formControlName="password"
        autocomplete="new-password"
        name="password"
        aria-label="Enter Password for login"
        autocomplete="current-password"
        placeholder="PasswordPassword123">
      <span class="c-n-form__border"></span>
    </div>
  </div>
  <div
    *ngIf="signinForm.get('password').invalid && (signinForm.get('password').touched || ngForm.submitted)"
    class="c-n-form__message is-red"
    role="alert">
    <div *ngIf="signinForm.get('password').errors.required">{{ messages.required }}</div>
    <div *ngIf="signinForm.get('password').errors.minlength">{{ messages.password.minlength(signinForm.get('password').errors.minlength.requiredLength) }}</div>
    <div *ngIf="signinForm.get('password').errors.maxlength">{{ messages.password.maxlength(signinForm.get('password').errors.maxlength.requiredLength) }}</div>
  </div>
  <div class="c-n-form__under-control">
    <a class="t-typo__link" href="javascript:void(0)" (click)="changeAuthTab('recoveryPassword')">Forgot password?</a>
  </div>
  <div class="c-n-modal__footer">
    <button
      class="e-btn e-btn_dark"
      [attr.disabled]="(authState | async)?.isLoading ? true : null"
      [ngClass]="{'is-loading': (authState | async)?.isLoading}"
      type="submit"
      aria-label="Submit">
      <span>Submit</span>
    </button>
  </div>
</form>
