import * as DonateActions from './donate.actions';

export interface Steps {
  amount: boolean;
  confirm: boolean;
}
export interface State {
  amount: number;
  message: string;
  steps: Steps;
  billing: {
    recurrent: boolean,
    anonymous: boolean
  };
  successInfo: any;
  brutTotal: any;
}
export const initialState: State = {
  amount: 0,
  message: '',
  steps: {
    amount: false,
    confirm: false
  },
  billing: {
    recurrent: false,
    anonymous: false
  },
  successInfo: {},
  brutTotal: 0
};

export function DonateReducer (state = initialState, action: DonateActions.DonateActions) {
  switch (action.type) {
    case (DonateActions.SET_AMOUNT):
      return {
        ...state,
        amount: action.payload
      };
    case (DonateActions.SET_MESSAGE):
      return {
        ...state,
        message: action.payload
      };
    case (DonateActions.SET_RECURRENT_CHECK):
      const billing = {...state.billing};
      billing.recurrent = action.payload;
      return {
        ...state,
        billing: billing
      };
    case (DonateActions.SET_ANON_CHECK):
      const oldBilling = {...state.billing};
      oldBilling.anonymous = action.payload;
      return {
        ...state,
        billing: oldBilling
      };
    case (DonateActions.SET_NAVIGATION_STEP):
      return {
        ...state,
        steps: action.payload
      };
    case (DonateActions.DONATE_SET_SUCCESS_INFO):
      return {
        ...state,
        successInfo: action.payload
      };
    case (DonateActions.SET_BRUT_TOTAL):
      return {
        ...state,
        brutTotal: action.payload
      };
    default:
      return state;
  }
}
