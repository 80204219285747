<div class="c-my-friends__inner h-loader" [class.is-loading]="isLoading">
  <div class="c-my-friends__content c-container c-container_md h-loader__caption" id="skip-nav-target">
    <app-friends-item
      class="c-my-friends__item"
      *ngFor="let item of friends; let i = index"
      (removeItem)="removeItemFromList(i)"
      [friendInfo]="item"
      [point]="'accepted'">
    </app-friends-item>
    <div class="c-my-quests__empty c-my-friends__empty" *ngIf="friends.length === 0 && !isLoading">
      <svg class="c-my-quests__empty__icon-friends-list icon icon-sad"><use xlink:href="#icon-sad"></use></svg>
      <div class="c-my-quests__empty__caption">
        <span>Find your friends <a routerLink="search">here</a></span>
      </div>
    </div>
  </div>
  <div class="h-loader__control"></div>
</div>
