<form [formGroup]="shareForm" (ngSubmit)="onSubmit()">
  <div class="c-n-form__group" formGroupName="emails">
    <div class="c-n-form__legend">Email Addresses:</div>
    <div class="c-share-widjet__emails">
      <ng-template ngFor let-email [ngForOf]="emails.controls" let-i="index">
        <div class="c-share-widjet__email c-n-form__control has-icon-right" [ngClass]="{'has-icon-right': emails.controls.length > 1 && i < emails.controls.length - 1, 'is-required': emails.controls.length === 1}">
            <input
                class="c-n-form__input"
                type="text"
                [formControlName]="i"
                placeholder="Enter email"
                (keypress)="autoControlFields()"
                (keydown)="autoControlFields()">
            <div class="c-share-widjet__email__delete c-n-form__control__icon" *ngIf="emails.controls.length > 1 && i < emails.controls.length - 1" (click)="removeEmail(i)">
              <svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>
            </div>
        </div>
        <div *ngIf="(emails.controls[i].touched || formSubmitted) && emails.controls[i].invalid" class="c-forms__message is-bottom is-red">
            <div *ngIf="emails.controls[i].errors.required">{{ messages.required }}</div>
            <div *ngIf="emails.controls[i].errors.email">{{ messages.invalidEmail }}</div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="c-forms__group">
    <strong class="c-forms__legend">message:</strong>
    <div class="c-forms__control is-required">
        <input
            class="c-forms__input"
            type="text"
            formControlName="message"
            placeholder="Enter message">
    </div>
    <div *ngIf="(messageControl.touched || formSubmitted) && messageControl.invalid" class="c-forms__message is-bottom is-red">
        <div *ngIf="messageControl.errors.required">{{ messages.required }}</div>
    </div>
  </div>
  <div *ngIf="(shareForm.get('message').touched || formSubmitted) && shareForm.get('message').invalid" class="c-n-form__message is-block is-red" role="alert">
    <div *ngIf="shareForm.get('message').errors.required">{{ messages.required }}</div>
  </div>
  <div class="c-n-modal__footer">
    <button class="e-btn e-btn_dark" [disabled]="shareIsloading" [ngClass]="{'is-loading': shareIsloading}" type="submit">
      <span>Submit</span>
    </button>
  </div>
</form>
