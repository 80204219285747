<div class="card-container">
    <div *ngIf="hasAnnouncement && announcementText && postText" class="card-announcement">
        <a class="bolded" [routerLink]='["/profile", "andrewting"]'>
            {{ announcementText[1] }}
        </a> 
         {{ announcementText[0] }}
    </div>
    <div class="card-header" #headerDiv>
        <div class="card-avatar-div">
            <a class="c-quest__doer"
                class="c-quest__doer card-avatar"
                href="javascript:void(0)"
                [routerLink]='["/profile", "andrewting"]'>
                <app-profile-avatar
                    class="avatar"
                    [avatar]="userInfo.profilePictureURL"
                    [isBrand]="userInfo.isUserBrand"
                    [firstName]="userInfo.firstName"
                    [lastName]="userInfo.lastName"
                    [userName]="userInfo.userName">
                </app-profile-avatar>   
            </a>
        </div>
        <div class="avatar-text">
            <div class="name">{{userInfo.firstName + ' ' + userInfo.lastName}}</div>
            <div class="small-bold">{{location}}</div>
            <div class="small-light">{{ timeString }}</div>
        </div>
        <div class="card-hashtag" *ngIf="hashtags">
            <span class="hashtag"><span *ngFor="let hashtag of hashtags">#{{ hashtag }} </span></span>
        </div>
    </div>
    <div *ngIf="!postText && hasAnnouncement && announcementText" 
            [ngStyle]="{ 'border-top' : getBorderTop(), 'margin' : '10px 10px 0 10px'}"></div>
    <div class="card-body">
        <div *ngIf="imgUrl" class="card-img-container h-object-fit"
            [ngStyle]="{ 'width.px' : headerDiv.offsetWidth / 2, 'height.px' : headerDiv.offsetWidth/2}">
            <img #image
            class="card-img"
            [src]="imgUrl">
        </div>
        <div *ngIf="postText || (hasAnnouncement && announcementText)" 
            class="card-text" 
            [ngStyle]="{ 'width' : imgUrl ? headerDiv.offsetWidth / 2 + 'px' : 'auto' }">
            <p *ngIf="postText">{{ postText }}</p>
            <p *ngIf="!postText && hasAnnouncement && announcementText">
                {{ announcementText[0] }} <span class="bolded">{{ announcementText[1] }}</span>
            </p>
        </div>
    </div>
    <div class="card-cheers">
        <span *ngIf="cheers != 0"><span class="cheer">🙌</span> {{ getCheers() }} cheered!</span>
        <span *ngIf="cheers == 0">No one cheered yet...</span>
    </div>
    <div>
        <ul class="card-footer">
            <li class="footer-button"><img class="card-bottom-icon" src="https://cdn.discordapp.com/attachments/564478672262791168/760549992603189298/bx_bx-comment-detail.svg">Comment</li>
            <li class="footer-button"><img class="card-bottom-icon" src="https://cdn.discordapp.com/attachments/564478672262791168/760549995996250172/fa-regular_share-square.svg">Share</li>
            <li class="footer-button"><img class="card-bottom-icon" src="https://cdn.discordapp.com/attachments/564478672262791168/760549998600388688/bx_bx-calendar-star.svg">Add to My Quests</li>
        </ul>
    </div>
</div>
