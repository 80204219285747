import {
  AddMilestone,
  PatchNewMilestone,
  RemoveNewMilestone,
  SetMilestoneForPreview,
  SetNewMilestone
} from '../store/dl-milestones.actions';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {QuestTask} from 'diemlife-commons/dist/diemlife-commons-model';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {DlMilestonesService} from '../dl-milestones.service';
import {ModalService} from '../../modal/modal.service';
import {AddMilestonePayload, AddMilestonesToGroupPayload, DlMilestoneManageModes} from '../dl-milestones.typings';
import {SnotifyService} from 'ng-snotify';
import {SnotifyConfigService} from 'src/app/_services/snotify-config.service';
import * as fromDlMilestones from '../store/dl-milestones.reducer';
import {AppState} from 'src/app/_store/app.reducers';
import {QuestPreparedPhoto} from '../../quest/quest.type';
import {QuestService} from '../../../../_services/quest.service';

@Component({
  selector: 'app-dl-new-milestone',
  templateUrl: 'dl-new-milestone.component.html'
})

export class DlNewMilestoneComponent implements OnInit, OnDestroy {
  @Input() index: number;
  @Input() questId: number;
  @Input() isEditMode: boolean;
  @Input() mode: DlMilestoneManageModes;
  dlMilestonesState: Observable<fromDlMilestones.State>;
  dlMilestonesStateSubscription: Subscription;
  isLoading = false;
  newMilestoneInfo: QuestTask;
  submitted = false;


  constructor(
    private store: Store<AppState>,
    private dlMilestonesService: DlMilestonesService,
    private questService: QuestService,
    private modalService: ModalService,
    private snotifyService: SnotifyService,
    private snotifyConfigService: SnotifyConfigService
  ) {}
  ngOnInit() {
    this.dlMilestonesState = this.store.select('dlMilestones');
    this.dlMilestonesStateSubscription = this.dlMilestonesState.subscribe((state: fromDlMilestones.State) => {
      this.newMilestoneInfo = state.newMilestoneForPreview;
    });
  }
  ngOnDestroy() {
    if (this.dlMilestonesStateSubscription) {
      this.dlMilestonesStateSubscription.unsubscribe();
    }
  }
  submit() {
    if (this.isLoading) { return false; }

    this.submitted = true;

    if (!this.newMilestoneInfo.task) {
      this.snotifyService.warning('Please fill all required fields', null, this.snotifyConfigService.getConfig());
      return false;
    }
    if (this.mode === 'QUEST_DETAILS' || this.mode === 'QUEST_MANAGE') {
      this.isLoading = true;
      this.dlMilestonesState.pipe(take(1)).subscribe((state: fromDlMilestones.State) => {
        const payload = {
          ...state.newMilestoneForPreview
        } as AddMilestonePayload;
        this.dlMilestonesService.addMilestone(this.questId, payload).subscribe((milestone: QuestTask) => {
          if (state.milestones[0].id) {
            this.addMilestonesToGroup(milestone);
          } else {
            this.store.dispatch(new RemoveNewMilestone());
            this.store.dispatch(new AddMilestone(milestone));
            this.isLoading = false;
          }
        }, () => {
          this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
        });
      });
    } else if (this.mode === 'NEW_QUEST') {
      this.dlMilestonesState.pipe(take(1)).subscribe((state: fromDlMilestones.State) => {
        const milestone = new QuestTask(state.newMilestoneForPreview);
        this.store.dispatch(new RemoveNewMilestone());
        this.store.dispatch(new AddMilestone(milestone));
      });
    }
  }
  addMilestonesToGroup(milestone: QuestTask) {
    this.dlMilestonesState.pipe(take(1)).subscribe((state: fromDlMilestones.State) => {
      const payload: AddMilestonesToGroupPayload = {
        groupId: state.milestones[0].id,
        id: milestone.id
      };
      this.dlMilestonesService.addMilestonesToGroup(this.questId, payload).subscribe(() => {
        this.store.dispatch(new RemoveNewMilestone());
        this.store.dispatch(new AddMilestone(milestone));
        this.isLoading = false;
      }, () => {
        this.snotifyService.error(`Oops, something went wrong.`, null, this.snotifyConfigService.getConfig());
      });
    });
  }
  openDialog() {
    this.store.dispatch(new SetMilestoneForPreview({index: this.index, isEditMode: true}));
    this.modalService.open('milestone-details-dialog');
  }
  addNewMilestone() {
    const newQuestTask = new QuestTask({
      task: null,
      imageUrl: null,
      video: null,
      embeddedVideo: null,
      linkUrl: null,
      linkPreview: null,
      linkedQuest: null,
      linkedQuestId: null
    });
    this.store.dispatch(new SetNewMilestone(newQuestTask));
  }
  removeNewMilestone() {
    this.store.dispatch(new RemoveNewMilestone());
  }
  setMilestoneTask(event) {
    this.store.dispatch(new PatchNewMilestone({
      task: event.target.value
    } as QuestTask));
  }

  prepareCoverPhoto(photo, category): QuestPreparedPhoto {
    return this.questService.prepareCoverPhoto(photo, category);
  }

}
