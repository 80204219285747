<h3 class="modal-title">
  <span>Verify {{ bankAccount.name }}</span>
  <br>
  <span>XXXX XXXX XXXX {{ bankAccount.lastFourDigits }}</span>
</h3>
<form [formGroup]="bankVerify" (ngSubmit)="verifyBankAccount()" [ngClass]="{'form-is-submitted': submitted}">
  <span class="c-forms__legend">First debit amount: (cents)</span>
  <div class="c-forms__group">
    <div class="c-forms__control is-required">
      <input type="text" prefix="¢" mask="0000" [validation]="false" formControlName="firstDebit" placeholder="123456789" class="c-forms__input">
    </div>
  </div>
  <div *ngIf="(bankVerify.get('firstDebit').touched || submitted) && bankVerify.get('firstDebit').invalid"
    class="c-forms__message is-red"
    role="alert">
      <div *ngIf="bankVerify.get('firstDebit').errors.required">{{ messages.required }}</div>
  </div>
  <span class="c-forms__legend">Second debit amount: (cents)</span>
  <div class="c-forms__group">
    <div class="c-forms__control is-required">
      <input type="text" prefix="¢" mask="0000" [validation]="false" formControlName="secondDebit" placeholder="123456789" class="c-forms__input">
    </div>
  </div>
  <div *ngIf="(bankVerify.get('secondDebit').touched || submitted) && bankVerify.get('secondDebit').invalid"
    class="c-forms__message is-red"
    role="alert">
      <div *ngIf="bankVerify.get('secondDebit').errors.required">{{ messages.required }}</div>
  </div>
  <div class="c-forms__group">
    <button class="c-btn c-btn_dark c-btn_md c-btn_full" [disabled]="isLoading" [ngClass]="{'is-loading': isLoading}" type="submit">
      <span>Verify account</span>
    </button>
  </div>
</form>
